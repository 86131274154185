import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class DeviceRIEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "Device";
        this.defaultCacheSize = 1000;

        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false,  // NoCopy
            true,  // NoUpdate
            true,   // Required
            false,  // RequiredForQuery
            null,   // Picklist
            null,   // Scale
            null,   // Precision
            36,     // length
            null,   // minLength
            null,   // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DeviceProfileUSID"] = new EnterpriseComponentAttributeMetadata(
            "DeviceProfileUSID", // name
            false,  // NoCopy
            false,  // NoUpdate
            true,  // Required
            false,  // RequiredForQuery
            null,   // Picklist
            null,   // Scale
            null,   // Precision
            36,     // length
            null,   // minLength
            null,   // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DeviceProfileName"] = new EnterpriseComponentAttributeMetadata(
            "DeviceProfileName", // name
            false,  // NoCopy
            false,  // NoUpdate
            false,  // Required
            false,  // RequiredForQuery
            null,   // Picklist
            null,   // Scale
            null,   // Precision
            100,     // length
            null,   // minLength
            null,   // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicUSID"] = new EnterpriseComponentAttributeMetadata(
            "ClinicUSID", // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            36,      // length
            null,    // minLength
            null,    // pattern
            "UUID",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["ClinicIdentifier"] = new EnterpriseComponentAttributeMetadata(
            "ClinicIdentifier", // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            100,     // length
            null,    // minLength
            null,    // pattern
            "String",// Type
            null,    // default value
            false,    // computed
        );
        this.attributes["SerialNumber"] = new EnterpriseComponentAttributeMetadata(
            "SerialNumber", // name
            false,   // NoCopy
            true,    // NoUpdate
            true,    // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            100,     // length
            null,    // minLength
            null,    // pattern
            "String", // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["Claim"] = new EnterpriseComponentAttributeMetadata(
            "Claim", // name
            false,   // NoCopy
            true,    // NoUpdate
            true,    // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            100,     // length
            null,    // minLength
            null,    // pattern
            "String",// Type
            null,    // default value
            false,    // computed
        );
        this.attributes["Name"] = new EnterpriseComponentAttributeMetadata(
            "Name",  // name
            false,   // NoCopy
            true,    // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            100,     // length
            null,    // minLength
            null,    // pattern
            "String",// Type
            null,    // default value
            false,    // computed
        );
        this.attributes["ARN"] = new EnterpriseComponentAttributeMetadata(
            "ARN",   // name
            false,   // NoCopy
            true,    // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            2000,    // length
            null,    // minLength
            null,    // pattern
            "String",// Type
            null,    // default value
            false,    // computed
        );
        this.attributes["HardwareVersion"] = new EnterpriseComponentAttributeMetadata(
            "HardwareVersion", // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            100,     // length
            null,    // minLength
            null,    // pattern
            "String",// Type
            null,    // default value
            false,    // computed
        );
        this.attributes["Configuration"] = new EnterpriseComponentAttributeMetadata(
            "Configuration", // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            100,     // length
            null,    // minLength
            null,    // pattern
            "String",// Type
            3,       // default value
            false,    // computed
        );
        this.attributes["ICCID"] = new EnterpriseComponentAttributeMetadata(
            "ICCID", // name
            false,   // NoCopy
            true,    // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            32,      // length
            null,    // minLength
            null,    // pattern
            "String",// Type
            null,    // default value
            false,    // computed
        );
        this.attributes["IMEI"] = new EnterpriseComponentAttributeMetadata(
            "IMEI",  // name
            false,   // NoCopy
            true,    // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            32,      // length
            null,    // minLength
            null,    // pattern
            "String",// Type
            null,    // default value
            false,    // computed
        );
        this.attributes["IMSI"] = new EnterpriseComponentAttributeMetadata(
            "IMSI",  // name
            false,   // NoCopy
            true,    // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            32,      // length
            null,    // minLength
            null,    // pattern
            "String",// Type
            null,    // default value
            false,    // computed
        );
        this.attributes["A850"] = new EnterpriseComponentAttributeMetadata(
            "A850",  // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            4,       // Scale
            4,       // Precision
            null,    // length
            null,    // minLength
            /^[-+]?0(\.\d{4})?$/,    // pattern
            "Number",// Type
            null,    // default value
            false,    // computed
        );
        this.attributes["B850"] = new EnterpriseComponentAttributeMetadata(
            "B850",  // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            4,       // Scale
            6,       // Precision
            null,    // length
            null,    // minLength
            /^-?(\d{6}|\d{2}(?=\d*\.\d*)[\d.]{4}\d)$/,    // pattern
            "Number",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["C850"] = new EnterpriseComponentAttributeMetadata(
            "C850",  // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            0,       // Scale
            5,       // Precision
            null,    // length
            null,    // minLength
            /\b\d{5}\b/,    // pattern
            "Number",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["A525"] = new EnterpriseComponentAttributeMetadata(
            "A525",  // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            4,       // Scale
            4,       // Precision
            null,    // length
            null,    // minLength
            /^[-+]?0(\.\d{4})?$/,    // pattern
            "Number",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["B525"] = new EnterpriseComponentAttributeMetadata(
            "B525",  // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            4,       // Scale
            6,       // Precision
            null,    // length
            null,    // minLength
            /^-?(\d{6}|\d{2}(?=\d*\.\d*)[\d.]{4}\d)$/,    // pattern
            "Number",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["C525"] = new EnterpriseComponentAttributeMetadata(
            "C525",  // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            0,       // Scale
            5,       // Precision
            null,    // length
            null,    // minLength
            /\b\d{5}\b/,    // pattern
            "Number",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["A405"] = new EnterpriseComponentAttributeMetadata(
            "A405",  // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            4,       // Scale
            4,       // Precision
            null,    // length
            null,    // minLength
            /^[-+]?0(\.\d{4})?$/,    // pattern
            "Number",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["B405"] = new EnterpriseComponentAttributeMetadata(
            "B405",  // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            4,       // Scale
            6,       // Precision
            null,    // length
            null,    // minLength
            /^-?(\d{6}|\d{2}(?=\d*\.\d*)[\d.]{4}\d)$/,    // pattern
            "Number",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["C405"] = new EnterpriseComponentAttributeMetadata(
            "C405",  // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            0,       // Scale
            5,       // Precision
            null,    // length
            null,    // minLength
            /\b\d{5}\b/,    // pattern
            "Number",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["Temperature"] = new EnterpriseComponentAttributeMetadata(
            "Temperature", // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            2,       // Scale
            4,       // Precision
            null,    // length
            null,    // minLength
            /^-?(\d{4}|\d{2}(?=\d*\.\d*)[\d.]{2}\d)$/,    // pattern
            "Number",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["LatestFirmwareVersion"] = new EnterpriseComponentAttributeMetadata(
            "LatestFirmwareVersion", // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            20,      // length
            null,    // minLength
            null,    // pattern
            "String",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["DeviceStatus"] = new EnterpriseComponentAttributeMetadata(
            "DeviceStatus", // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            20,      // length
            null,    // minLength
            null,    // pattern
            "String",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["ReturnInitiated"] = new EnterpriseComponentAttributeMetadata(
            "ReturnInitiated", // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            20,      // length
            null,    // minLength
            null,    // pattern
            "String",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["PatientUSID"] = new EnterpriseComponentAttributeMetadata(
            "PatientUSID", // name
            false,   // NoCopy
            true,    // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            36,      // length
            null,    // minLength
            null,    // pattern
            "UUID",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["PatientIdentifier"] = new EnterpriseComponentAttributeMetadata(
            "PatientIdentifier", // name
            false,   // NoCopy
            true,    // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            100,     // length
            null,    // minLength
            null,    // pattern
            "String",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["CloudCathID"] = new EnterpriseComponentAttributeMetadata(
            "CloudCathID", // name
            false,   // NoCopy
            true,    // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            100,     // length
            null,    // minLength
            null,    // pattern
            "String",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["FirstName"] = new EnterpriseComponentAttributeMetadata(
            "FirstName", // name
            false, // NoCopy
            false, // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["MiddleInitial"] = new EnterpriseComponentAttributeMetadata(
            "MiddleInitial", // name
            false, // NoCopy
            false, // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["LastName"] = new EnterpriseComponentAttributeMetadata(
            "LastName", // name
            false, // NoCopy
            false, // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Reprovision"] = new EnterpriseComponentAttributeMetadata(
            "Reprovision", // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            null,    // length
            null,    // minLength
            null,    // pattern
            "Boolean",  // Type
            null,    // default value
            false,    // computed
        );
        this.attributes["LastTurbidityValueDatetime"] = new EnterpriseComponentAttributeMetadata(
            "LastTurbidityValueDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false,   // NoCopy
            false,   // NoUpdate
            false,   // Required
            false,   // RequiredForQuery
            null,    // Picklist
            null,    // Scale
            null,    // Precision
            null,    // length
            null,    // minLength
            null,    // pattern
            "Date",  // Type
            null,    // default value
            false,    // computed
        );
    }
}


export default DeviceRIEnterpriseComponentMetadata;