import {Button, Grid, IconButton, Menu, MenuItem, Typography, withStyles} from "@material-ui/core";
import {Edit, ExpandLess, ExpandMore} from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {withAppContext} from "../../../App";
import CCButton from "../../../components/common/CCButton";
import TurbidityGraph from "../../../components/turbidity/TurbidityGraph";
import styles from "../../../theme/styles";
import memoryContext from "../../../util/MemoryContext";

import * as userAgent from "../../../util/UserAgent";
import AssignedNurseApplet from "./AssignedNurseApplet";
import AssignedPhysicianApplet from "./AssignedPhysicianApplet";
import DeviceSerialNumberApplet from "./DeviceSerialNumberApplet";
import DeviceStatusApplet from "./DeviceStatusApplet";
import PatientAttachmentApplet from "./PatientAttachmentApplet";
import PatientCyclerApplet from "./PatientCyclerApplet";
import PatientDetailMenuApplet from "./PatientDetailMenuApplet";
import PatientEscalationCommunicationListApplet from "./PatientEscalationCommunicationListApplet";
import PatientEscalationDetailApplet from "./PatientEscalationDetailApplet";
import PatientEscalationLabListApplet from "./PatientEscalationLabListApplet";
import PatientEscalationListApplet from "./PatientEscalationListApplet";
import PatientEscalationNoteListApplet from "./PatientEscalationNoteListApplet";
import PatientEscalationStatusApplet from "./PatientEscalationStatusApplet";
import PatientEscalationSymptomListApplet from "./PatientEscalationSymptomListApplet";
import PatientEscalationTreatmentListApplet from "./PatientEscalationTreatmentListApplet";
import PatientIdentifierApplet from "./PatientIdentifierApplet";
import PatientNotificationAppletA from "./PatientNotificationAppletA";
import PatientNotificationAppletB from "./PatientNotificationAppletB";
import SecondaryNurseNameApplet from "./SecondaryNurseNameApplet";
import SecondaryPhysicianNameApplet from "./SecondaryPhysicianNameApplet";


class PatientApplet extends Component {
    state = {
        patientOptions: false,
        patientMenu: null,
        patientSubmenu: null,
        openXSDetails: false,
        showEscalationDetail: false,
        activeEpisode: true,
        menuTarget: null,
        menuTargetRow: null,
        episodeLoadStatus: null,
        receivedSecondaryNurseContent: false,
        receivedSecondaryPhysicianContent: false,
    };

    componentDidMount() {
        let patientEscalationEnterpriseComponent = this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation");

        patientEscalationEnterpriseComponent.addLoadStartedListener(this);
        patientEscalationEnterpriseComponent.addLoadCompletedListener(this);
        patientEscalationEnterpriseComponent.addValueChangedListener(this);

        this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("SecondaryNurse").addLoadCompletedListener(this);
        this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("SecondaryPhysician").addLoadCompletedListener(this);

        this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").invalidate();
    }

    componentWillUnmount() {
        this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").removeLoadStartedListener(this);
        this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").removeLoadCompletedListener(this);
        this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").removeValueChangedListener(this);
    }

    enterpriseComponentValueChanged = (buffer) => {
        let activeEpisode = !!buffer.find((o, i) => !buffer[i].EndDatetime);
        this.setState({activeEpisode: activeEpisode});
    };

    enterpriseComponentLoadStarted = () => {
        this.setState({episodeLoadStatus: "starting"});
    };

    enterpriseComponentLoadCompleted = (buffer, ec) => {
        if (ec.getName() === "SecondaryNurse") {
            this.setState({receivedSecondaryNurseContent: true});
        } else if (ec.getName() === "SecondaryPhysician") {
            this.setState({receivedSecondaryPhysicianContent: true});
        } else {
            let activeEpisode = !!buffer.find((o, i) => !buffer[i].EndDatetime);
            this.setState({activeEpisode: activeEpisode, episodeLoadStatus: "completed"});
        }
    };

    backHandler = () => {
        this.props.history.push({pathname: "/"});
    };

    getName = () => {
        return "PatientApplet";
    };

    handleAddEpisode = () => {
        this.props.view.initiateAction("AddEpisode");
    };

    handleAssignDevice = () => {
        this.props.view.initiateAction("AssignDevice");
    };

    handleUnassignDevice = () => {
        this.props.view.initiateAction("UnassignDevice");
    };

    handleEditReducedTurbidityNotification = (event) => {
        event.preventDefault();
        this.props.view.initiateAction("Edit turbidity notification reduced");
    };

    handleEditFullTurbidityNotification = (event) => {
        event.preventDefault();
        this.props.view.initiateAction("Edit turbidity notification full");
    };

    handleEditPatientIdentifier = (event) => {
        event.preventDefault();
        this.props.view.initiateAction("Edit identifier");
    };

    handleEditCareTeam = (event) => {
        event.preventDefault();
        this.props.view.initiateAction("Edit care team");
    };

    handleEditCycler = (event) => {
        event.preventDefault();
        this.props.view.initiateAction("Edit cycler");
    };

    handleEditAttachment = (event) => {
        event.preventDefault();
        this.props.view.initiateAction("EditPatientAttachment");
    };

    handleInfectionConfirmed = (event) => {
        event.preventDefault();
        this.props.view.initiateAction("InfectionConfirmed");
    };

    handleResolveInfection = (event) => {
        event.preventDefault();
        this.props.view.initiateAction("ResolveInfection");
    };

    handleNonInfectionDiagnosed = (event) => {
        event.preventDefault();
        this.props.view.initiateAction("NonInfectionDiagnosed");
    };

    handleEscalateMenuClick = (event, visible) => {
        this.setState({menuTarget: event.currentTarget, escalateMenuVisible: visible});
    };

    handleEscalateMenuClose = (event) => {
        event.preventDefault();
        this.setState({escalateMenuVisible: false});
    };

    openXSDetails = (event) => {
        event.preventDefault();
        this.setState({openXSDetails: !this.state.openXSDetails});
    };

    patientEscalationBackHandler = () => {
        this.setState({showEscalationDetail: !this.state.showEscalationDetail});
    };

    render() {

        let deviceContent = (
            <Grid key="2" container item direction="row" style={{minHeight: "40px"}}>
                <DeviceStatusApplet key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
                <DeviceSerialNumberApplet key={2} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
            </Grid>
        );

        let deviceButtonHandler = this.props.enterpriseComponent.getAttributeValue("DeviceSerialNumber") === null
            ? this.handleAssignDevice
            : this.handleUnassignDevice;

        let deviceButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={deviceButtonHandler} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );

        let careTeamButton = null;
        if (this.state.receivedSecondaryNurseContent && this.state.receivedSecondaryPhysicianContent) {
            careTeamButton = (
                <div id="hover-edit-button">
                    <IconButton aria-label="Options" size="small" onClick={this.handleEditCareTeam} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                        <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                    </IconButton>
                </div>
            );
        }

        let careTeamContent = (
            <Grid key="grid" container item direction="row">
                <Grid key="1" container item direction="row" style={{minHeight: "50px"}}>
                    <AssignedNurseApplet key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
                    <AssignedPhysicianApplet key={2} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
                </Grid>
                <Grid key="2" container item direction="row" style={{minHeight: "40px"}}>
                    <Grid key="1" item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography className={this.props.classes.patientDetailLabel} style={{marginBottom: 3}}>Secondary Nurse</Typography>
                        <SecondaryNurseNameApplet column="FullName" className={this.props.classes.patientDetailData} enterpriseComponent={this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("SecondaryNurse")} view={this.props.view} />
                    </Grid>
                    <Grid key="2" item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography className={this.props.classes.patientDetailLabel} style={{marginBottom: 3}}>Secondary Physician</Typography>
                        <SecondaryPhysicianNameApplet column="FullName" className={this.props.classes.patientDetailData} enterpriseComponent={this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("SecondaryPhysician")} view={this.props.view} />
                    </Grid>
                </Grid>
            </Grid>
        );

        let notificationButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={this.handleEditReducedTurbidityNotification} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );
        let notificationContentA = (
            <Grid key="grid" container item direction="row">
                <PatientNotificationAppletA key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
            </Grid>
        );
        let notificationContentB = (
            <Grid key="grid" container item direction="row">
                <PatientNotificationAppletB key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
            </Grid>
        );

        let cyclerButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={this.handleEditCycler} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );
        let cyclerContent = (
            <Grid key={5} container item direction="row">
                <PatientCyclerApplet key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
            </Grid>
        );

        let attachmentButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={this.handleEditAttachment} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );
        let attachmentContent = (
            <Grid key={5} container item direction="row">
                <PatientAttachmentApplet key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
            </Grid>
        );
        let patientDetails;

        if (userAgent.isMobile()) {
            if (this.state.openXSDetails || memoryContext.breakpoint !== "xs") {
                patientDetails = (
                    <React.Fragment>
                        <div key="3" className={this.props.classes.patientEditDivXS}>
                            {deviceContent}
                        </div>
                        <div key="4" className={this.props.classes.patientEditDivXS}>
                            {careTeamContent}
                        </div>
                        <div key="5" className={this.props.classes.patientEditDivXS}>
                            {notificationContentA}
                        </div>
                        <div key="6" className={this.props.classes.patientEditDivXS}>
                            {cyclerContent}
                        </div>
                        <div key="7" className={this.props.classes.patientEditDivXS}>
                            {attachmentContent}
                        </div>
                    </React.Fragment>
                );
            }
        } else if (this.props.appContext.sessionContext.optionalCharacteristics === "View only") {
            patientDetails = (
                <React.Fragment>
                    <div key="3" className={this.props.classes.patientEditDivXS}>
                        {deviceContent}
                    </div>
                    <div key="4" className={this.props.classes.patientEditDivXS}>
                        {careTeamContent}
                    </div>
                    <div key="5" className={this.props.classes.patientEditDivXS}>
                        {notificationContentA}
                    </div>
                    <div key="6" className={this.props.classes.patientEditDivXS}>
                        {notificationContentB}
                    </div>
                    <div key="7" className={this.props.classes.patientEditDivXS}>
                        {cyclerContent}
                    </div>
                    <div key="8" className={this.props.classes.patientEditDivXS}>
                        {attachmentContent}
                    </div>
                </React.Fragment>
            );
        } else {
            patientDetails = (
                <React.Fragment>
                    <div key="3" className={this.props.classes.patientEditDiv}>
                        {deviceButton}
                        {deviceContent}
                    </div>
                    <div key="4" className={this.props.classes.patientEditDiv}>
                        {careTeamButton}
                        {careTeamContent}
                    </div>
                    <div key="5" className={this.props.classes.patientEditDivNonEdit}>
                        {notificationContentA}
                    </div>
                    <div key="6" className={this.props.classes.patientEditDiv} style={{padding: "7px", margin: "0 0 0 0"}}>
                        {notificationButton}
                        {notificationContentB}
                    </div>
                    <div key="7" className={this.props.classes.patientEditDiv}>
                        {cyclerButton}
                        {cyclerContent}
                    </div>
                    <div key="8" className={this.props.classes.patientEditDiv}>
                        {attachmentButton}
                        {attachmentContent}
                    </div>
                </React.Fragment>
            );
        }

        let addEscalationButton = null;
        if (this.state.activeEpisode === false && userAgent.isMobile() === false && this.props.appContext.sessionContext.optionalCharacteristics === null) {
            addEscalationButton = (
                <CCButton
                    style={{width: "200px"}}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => this.handleAddEpisode()}>
                    Add Episode
                </CCButton>
            );
        }
        let escalation = (
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                <div style={{display: "flex", direction: "row", alignContent: "flex-start"}}>
                    {addEscalationButton}
                </div>
                <PatientEscalationListApplet patientDetail={this} key={1} enterpriseComponent={this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation")} view={this.props.view} />
            </div>
        );
        if (this.state.showEscalationDetail === true) {
            let cta = (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Typography className={this.props.classes.episodeTitle}>{this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("EscalationStatus")}</Typography>
                        </div>
                    </div>
                </div>
            );

            if (this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("EscalationStatus") === "Awaiting infection diagnosis") {
                let escalationOptions = null;
                if (userAgent.isMobile() === false && process.env.REACT_APP_VARIANT === "cds" && this.props.appContext.sessionContext.optionalCharacteristics === null) {
                    escalationOptions = (
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                            <CCButton
                                variant="red-outline"
                                style={{width: "210px"}}
                                endIcon={<ExpandMore />}
                                onClick={(event) => this.handleEscalateMenuClick(event, true)}>
                                Escalate patient
                            </CCButton>
                            {true === this.state.escalateMenuVisible ? (
                                <Menu anchorEl={this.state.menuTarget} open={true} onClose={this.handleEscalateMenuClose} elevation={2}>
                                    <MenuItem key="hospitalized" onClick={() => {
                                        this.props.view.initiateAction("HospitalizePatient");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Patient is hospitalized
                                    </MenuItem>
                                    <MenuItem key="hemodialysis" onClick={() => {
                                        this.props.view.initiateAction("HemodialysisPatient");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Convert to hemodialysis
                                    </MenuItem>
                                    <MenuItem key="transplant" onClick={() => {
                                        this.props.view.initiateAction("EscalationTransplant");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Transplant received
                                    </MenuItem>
                                    <MenuItem key="deactivate" onClick={() => {
                                        this.props.view.initiateAction("EscalationDeactivate");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Deactivate patient
                                    </MenuItem>
                                    <MenuItem key="expired" onClick={() => {
                                        this.props.view.initiateAction("EscalationExpire");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Patient has expired
                                    </MenuItem>
                                </Menu>
                            ) : undefined}
                        </div>
                    );
                }
                let ctaMenus = (
                    <div style={{width: "430px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                            <CCButton
                                style={{width: "180px"}}
                                onClick={(event) => this.handleInfectionConfirmed(event)}>
                                Infection Confirmed
                            </CCButton>
                            <CCButton
                                variant="secondary"
                                style={{width: "210px"}}
                                onClick={(event) => this.handleNonInfectionDiagnosed(event)}>
                                Non-infection Diagnosed
                            </CCButton>
                        </div>
                        {escalationOptions}
                    </div>
                );

                if (userAgent.isMobile() === true || this.props.appContext.sessionContext.optionalCharacteristics !== null) {
                    ctaMenus = null;
                }

                cta = (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Typography className={this.props.classes.episodeTitle}>{this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("EscalationStatus")}</Typography>
                            {ctaMenus}
                        </div>
                    </div>
                );
            } else if (this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("EscalationStatus") === "Infection treatment monitoring") {
                let escalationOptions = null;

                if (userAgent.isMobile() === false && process.env.REACT_APP_VARIANT === "cds" && this.props.appContext.sessionContext.optionalCharacteristics === null) {
                    escalationOptions = (
                        <React.Fragment>
                            <CCButton
                                variant="red-outline"
                                style={{width: "210px"}}
                                endIcon={<ExpandMore />}
                                onClick={(event) => this.handleEscalateMenuClick(event, true)}>
                                Escalate patient
                            </CCButton>
                            {true === this.state.escalateMenuVisible ? (
                                <Menu anchorEl={this.state.menuTarget} open={true} onClose={this.handleEscalateMenuClose} elevation={2}>
                                    <MenuItem key="hospitalized" onClick={() => {
                                        this.props.view.initiateAction("HospitalizePatient");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Patient is hospitalized
                                    </MenuItem>
                                    <MenuItem key="hemodialysis" onClick={() => {
                                        this.props.view.initiateAction("HemodialysisPatient");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Convert to hemodialysis
                                    </MenuItem>
                                    <MenuItem key="transplant" onClick={() => {
                                        this.props.view.initiateAction("EscalationTransplant");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Transplant received
                                    </MenuItem>
                                    <MenuItem key="expired" onClick={() => {
                                        this.props.view.initiateAction("EscalationExpire");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Patient has expired
                                    </MenuItem>
                                    <MenuItem key="deactivate" onClick={() => {
                                        this.props.view.initiateAction("EscalationDeactivate");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Deactivate patient
                                    </MenuItem>
                                </Menu>
                            ) : undefined}
                        </React.Fragment>
                    );
                }

                let ctaMenus = (
                    <div style={{width: "430px"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                            <CCButton
                                variant="secondary"
                                style={{width: "210px"}}
                                onClick={(event) => this.handleResolveInfection(event)}>
                                Resolve Infection
                            </CCButton>
                            {escalationOptions}
                        </div>
                    </div>
                );

                if (userAgent.isMobile() === true || this.props.appContext.sessionContext.optionalCharacteristics !== null) {
                    ctaMenus = null;
                }

                cta = (
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <Typography className={this.props.classes.episodeTitle}>{this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("EscalationStatus")}</Typography>
                            {ctaMenus}
                        </div>
                    </div>
                );
            } else if (this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("EscalationStatus") === "Hospitalized") {
                let ctaMenus = (
                    <div key="2" style={{width: "430px"}}>
                        <div key="1" style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                            <CCButton
                                key="1"
                                variant="secondary"
                                style={{width: "230px"}}
                                onClick={() => {
                                    this.props.view.initiateAction("DischargeHospital");
                                }}>
                                Discharge from Hospital
                            </CCButton>
                            <CCButton
                                key="2"
                                variant="red-outline"
                                style={{width: "210px"}}
                                endIcon={<ExpandMore />}
                                onClick={(event) => this.handleEscalateMenuClick(event, true)}>
                                Escalate patient
                            </CCButton>
                            {true === this.state.escalateMenuVisible ? (
                                <Menu anchorEl={this.state.menuTarget} open={true} onClose={this.handleEscalateMenuClose} elevation={2}>
                                    <MenuItem key="hemodialysis" onClick={(event) => {
                                        this.props.view.initiateAction("HemodialysisPatient");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Convert to hemodialysis
                                    </MenuItem>
                                    <MenuItem key="transplant" onClick={() => {
                                        this.props.view.initiateAction("EscalationTransplant");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Transplant received
                                    </MenuItem>
                                    <MenuItem key="deactivated" onClick={() => {
                                        this.props.view.initiateAction("EscalationDeactivate");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Deactivate patient
                                    </MenuItem>
                                    <MenuItem key="expired" onClick={(event) => {
                                        this.props.view.initiateAction("EscalationExpire");
                                        this.setState({escalateMenuVisible: false});
                                    }}>
                                        Patient has expired
                                    </MenuItem>
                                </Menu>
                            ) : undefined}
                        </div>
                    </div>
                );
                if (userAgent.isMobile() === true || this.props.appContext.sessionContext.optionalCharacteristics !== null) {
                    ctaMenus = null;
                }
                cta = (
                    <div key="1" style={{display: "flex", flexDirection: "column"}}>
                        <div key="1" style={{height: "60px"}}>
                            <div key="1" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <div key="1" style={{width: "330px"}}>
                                    <Typography className={this.props.classes.episodeTitle}>{this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("EscalationStatus")}</Typography>
                                </div>
                                {ctaMenus}
                            </div>
                        </div>
                    </div>
                );
            }

            let cdsContent = null;
            if (process.env.REACT_APP_VARIANT === "cds") {
                cdsContent = (
                    <React.Fragment key="cdsContent">
                        <div style={{marginTop: "10px"}}>
                            <PatientEscalationSymptomListApplet patientDetail={this} key={3} enterpriseComponent={this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("EscalationSymptom")} view={this.props.view} />
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <PatientEscalationTreatmentListApplet patientDetail={this} key={2} enterpriseComponent={this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("EscalationTreatment")} view={this.props.view} />
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <PatientEscalationLabListApplet patientDetail={this} key={4} enterpriseComponent={this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("EscalationLab")} view={this.props.view} />
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <PatientEscalationNoteListApplet patientDetail={this} key={5} enterpriseComponent={this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("EscalationNote")} view={this.props.view} />
                        </div>
                        {!userAgent.isMobile() && ["master", "sp2"].includes(process.env.REACT_APP_CLOUDCATH_ENV) ? (
                            <div style={{marginTop: "10px"}}>
                                <PatientEscalationCommunicationListApplet patientDetail={this} key={5} enterpriseComponent={this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("EscalationCommunication")} view={this.props.view} />
                            </div>
                        ) : null}
                    </React.Fragment>
                );
            }

            escalation = (
                <div key="escalation" style={{paddingLeft: 20, paddingRight: 20}}>
                    <Button
                        color="primary"
                        size="small"
                        style={{fontSize: "16px", minWidth: "70px", marginLeft: "-10px", marginBottom: "10px"}}
                        onClick={() => this.patientEscalationBackHandler()}>
                        &lt; Episode List
                    </Button>
                    {cta}
                    <PatientEscalationDetailApplet enterpriseComponent={this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation")} view={this.props.view} availableModes={["View"]} defaultMode="View" />
                    {cdsContent}
                </div>
            );
        }

        let patientDetailMenu = null;
        if (this.state.episodeLoadStatus === "completed" && userAgent.isMobile() === false) {
            patientDetailMenu = <PatientDetailMenuApplet enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />;
            if (this.props.appContext.sessionContext.optionalCharacteristics !== null) {
                patientDetailMenu = null;
            }
        }

        const lastTurbidityValue = this.props.enterpriseComponent.getAttributeValue("LastTurbidityValue") ?? "--";
        const turbidityThresholdHit = this.props.enterpriseComponent.getAttributeValue("EnableTurbidityNotifications") === true &&
            this.props.enterpriseComponent.getAttributeValue("LastTurbidityValueNumeric") !== undefined &&
            this.props.enterpriseComponent.getAttributeValue("TurbidityNotificationLevelA") !== null &&
            this.props.enterpriseComponent.getAttributeValue("LastTurbidityValueNumeric") >= this.props.enterpriseComponent.getAttributeValue("TurbidityNotificationLevelA");

        let turbidityStyle = {
            color: turbidityThresholdHit ? "#e74c3c" : "inherit",
            fontWeight: turbidityThresholdHit ? 600 : "inherit",
        };

        let patientIdentifier;
        if (userAgent.isMobile() === true) {
            patientIdentifier = (
                <div>
                    <Grid key={1} container item direction="row" justifyContent="space-between">
                        <PatientIdentifierApplet key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
                        {memoryContext.breakpoint === "xs" ? (
                            <IconButton style={{marginRight: "20px"}} aria-label="expand" size="small" onClick={(event) => this.openXSDetails(event)}>
                                {this.state.openXSDetails === true ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        ) : null}
                    </Grid>
                    <div style={{paddingLeft: "7px", marginTop: "2px"}}>
                        <PatientEscalationStatusApplet key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
                    </div>
                    <div key="2" style={{marginTop: "10px"}}>
                        <Grid key="1" container item direction="row">
                            <Grid style={{paddingLeft: "7px"}} key="1" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography component="span" className={this.props.classes.patientDetailLabel}>Current turbidity level:&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                                <Typography component="span" className={this.props.classes.patientDetailData}><span style={turbidityStyle}>{lastTurbidityValue}</span></Typography>
                            </Grid>
                        </Grid>
                    </div>
                    {patientDetails}
                </div>
            );
        } else {
            let patientIdentifierEditor;
            if (this.props.appContext.sessionContext.optionalCharacteristics !== null) {
                patientIdentifierEditor = (
                    <div key="1" className={this.props.classes.patientIdentifierNonEditDiv}>
                        <PatientIdentifierApplet key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
                    </div>
                );
            } else {
                patientIdentifierEditor = (
                    <div key="1" className={this.props.classes.patientIdentifierEditDiv}>
                        <div id="hover-edit-button">
                            <IconButton aria-label="Options" size="small" onClick={this.handleEditPatientIdentifier} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                                <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                            </IconButton>
                        </div>
                        <PatientIdentifierApplet key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
                    </div>
                );
            }
            patientIdentifier = (
                <div>
                    <div style={{display: "flex"}}>
                        {patientIdentifierEditor}
                        <span style={{flexShrink: 0, minWidth: 45, textAlign: "right"}}>{patientDetailMenu}</span>
                    </div>
                    <div key="2" style={{paddingLeft: "7px", marginTop: "8px"}}>
                        <PatientEscalationStatusApplet key={1} enterpriseComponent={this.props.enterpriseComponent} view={this.props.view} />
                    </div>
                    <div key="3" style={{marginTop: "20px"}}>
                        <Grid key="1" container item direction="row">
                            <Grid style={{paddingLeft: "7px"}} key="1" item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography key={1} component="span" className={this.props.classes.patientDetailLabel}>Current turbidity level:&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                                <Typography key={2} component="span" className={this.props.classes.patientDetailData}><span style={turbidityStyle}>{lastTurbidityValue}</span></Typography>
                            </Grid>
                        </Grid>
                    </div>
                    {patientDetails}
                </div>
            );
        }

        return (
            <React.Fragment key="PatientApplet">
                <Grid container direction="column" spacing={2}>
                    <Grid key="0" item xs={12} sm={12} md={12} lg={12} xl={12} style={{height: "24px"}}>
                        &nbsp;
                    </Grid>
                    <Grid key="1" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                            color="primary"
                            size="small"
                            style={{fontSize: "18px", marginLeft: "5px", marginBottom: "5px"}}
                            onClick={() => this.backHandler()}>
                            &lt; Patient List
                        </Button>
                    </Grid>
                    <Grid key="2" container item direction="row" spacing={1}>
                        <Grid key={1} container item direction="column" xs={12} sm={4} md={3} lg={3} xl={3} style={{minWidth: "346px", paddingLeft: "7px", borderRight: "0px solid #000000"}}>
                            {patientIdentifier}
                        </Grid>
                        <Grid key="2" container item direction="column" xs={12} sm={8} md={9} lg={9} xl={9} spacing={8} style={{paddingLeft: "15px"}}>
                            <Grid item style={{paddingTop: 22, paddingBottom: 0}}>
                                {userAgent.isMobile() === false && (
                                    <Typography variant="h2">Turbidity Graph</Typography>
                                )}
                            </Grid>
                            <Grid item>
                                <TurbidityGraph
                                    // keep the key prop below to trigger a re-render when switching between episode and main detail page
                                    key={this.state.showEscalationDetail
                                        ? `turbidity-graph-with-escalation-${this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("USID")}`
                                        : `turbidity-graph-${this.props.enterpriseComponent.getAttributeValue("USID")}`}
                                    patientUSID={this.props.enterpriseComponent.getAttributeValue("USID")}
                                    escalation={this.state.showEscalationDetail ? this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation") : undefined}
                                    variant={userAgent.isMobile() ? "mobile" : undefined}
                                />
                            </Grid>
                            <Grid item>
                                {escalation}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    showEscalationDetail = (event) => {
        this.setState({showEscalationDetail: true});
    };
}

export default withRouter(withAppContext(withStyles(styles, {withTheme: true})(PatientApplet)));