import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, withStyles} from "@material-ui/core";
import {Error} from "@material-ui/icons";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import {convertToUTCDatePlusCurrentTime} from "../util/utility";
import CCButton from "./common/CCButton";
import CCDatePicker from "./common/CCDatePicker";

const Outcome1 = "Converted to hemodialysis";
const Outcome2 = "Patient is deactivated";
const Outcome3 = "Patient has expired";
const Outcome4 = "Patient is returning to the current PD clinic";

class PatientEscalationDischarged extends Component {
    state = {
        updateBackgroundOperation: false,
        Note: "",
        DateOfDischarge: "",
        DateOfDischargeError: false,
        DateOfDischargeHelper: null,

        DateOfDiagnosis: "",
        DateOfDiagnosisError: false,
        DateOfDiagnosisHelper: null,

        HospitalizationOutcome: "",
        HospitalizationOutcomeError: null,

        HospitalizationDiagnosis: "",
        HospitalizationDiagnosisError: null,

        CatheterRemoved: "N/A",
        CatheterRemovedError: null,
        CatheterReplaced: "N/A",
        CatheterReplacedError: null,

        BacterialInfectionConfirmed: false,
        FungalInfectionConfirmed: false,
        ConfirmedInfectionError: null,

        TypeOfInfectionExitSite: false,
        TypeOfInfectionTunnel: false,
        TypeOfInfectionPeritonitis: false,
        TypeOfInfectionError: null,

        generalAlert: true,
        convertedToHemoAlert: false,
        convertedToDeactivatedAlert: false,
        convertedToExpiredAlert: false,
        expiredAlert: false,
        showConfirmation: false,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        lengthWarningText: null,
        editMode: false, // We set this to true as part of the rollback of editability. Will be restored in the future
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;

        // if (this.applet.getEnterpriseComponent().getAttributeValue("DischargeDate") !== null) this.state.editMode = true;

        // this.state.DateOfDischarge = this.applet.getEnterpriseComponent().getAttributeValue("DischargeDate");
        // this.state.DateOfDiagnosis = this.applet.getEnterpriseComponent().getAttributeValue("InfectionDiagnosisDate");
        // this.state.CatheterRemoved = this.applet.getEnterpriseComponent().getAttributeValue("CatheterRemoved");
        // this.state.CatheterReplaced = this.applet.getEnterpriseComponent().getAttributeValue("CatheterReplaced");
    };

    componentDidMount() {
        this.applet.setMode("Edit");

        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.state.DateOfDischarge === null || this.state.DateOfDischarge === "") {
            this.setState({DateOfDischargeHelper: "Field must contain a value.", DateOfDischargeError: true});
        } else if (this.state.DateOfDischargeError === true) {
            this.setState({DateOfDischargeHelper: "Date improperly formatted."});
        } else if ((this.state.DateOfDiagnosis === "" || this.state.DateOfDiagnosis === null) && (this.state.HospitalizationDiagnosis === "Infection - unresolved" || this.state.HospitalizationDiagnosis === "Infection - resolved")) {
            this.setState({DateOfDiagnosisHelper: "Field must contain a value.", DateOfDiagnosisError: true});
        } else if (this.state.DateOfDiagnosisError === true) {
            this.setState({DateOfDiagnosisHelper: "Date improperly formatted."});
        } else if (this.state.HospitalizationOutcome === "") {
            this.setState({HospitalizationOutcomeError: true});
        } else if (this.state.HospitalizationDiagnosis === "") {
            this.setState({HospitalizationDiagnosisError: true});
        } else if (this.state.HospitalizationOutcome === "Converted to hemodialysis" && this.state.CatheterRemoved === "N/A") {
            this.setState({CatheterRemovedError: true});
        } else if (this.state.HospitalizationOutcome === "Patient is returning to the current PD clinic" && this.state.CatheterReplaced === "N/A") {
            this.setState({CatheterReplacedError: true});
        } else if (this.state.BacterialInfectionConfirmed === "" && this.state.FungalInfectionConfirmed === "") {
            this.setState({ConfirmedInfectionError: true});
        } else if (this.state.TypeOfInfectionExitSite === "" && this.state.TypeOfInfectionTunnel === "" && this.state.TypeOfInfectionPeritonitis === "") {
            this.setState({TypeOfInfectionError: true});
        } else {
            this.applet.getEnterpriseComponent().setAttributeValue("EscalationNote", this.state.Note);
            this.applet.getEnterpriseComponent().setAttributeValue("EscalationNoteType", "Hospital Discharge");
            this.applet.getEnterpriseComponent().setAttributeValue("DischargeDate", this.state.DateOfDischarge);
            this.applet.getEnterpriseComponent().setAttributeValue("HospitalizationOutcome", this.state.HospitalizationOutcome);
            this.applet.getEnterpriseComponent().setAttributeValue("HospitalizationDiagnosis", this.state.HospitalizationDiagnosis);

            if (this.state.HospitalizationDiagnosis === "Infection - unresolved" || this.state.HospitalizationDiagnosis === "Infection - resolved") {
                this.applet.getEnterpriseComponent().setAttributeValue("InfectionDiagnosisDate", this.state.DateOfDiagnosis);
            }

            if (this.state.CatheterRemoved === "N/A") {
                this.applet.getEnterpriseComponent().setAttributeValue("CatheterRemoved", null);
            } else {
                this.applet.getEnterpriseComponent().setAttributeValue("CatheterRemoved", this.state.CatheterRemoved);
            }
            if (this.state.CatheterReplaced === "N/A") {
                this.applet.getEnterpriseComponent().setAttributeValue("CatheterReplaced", null);
            } else {
                this.applet.getEnterpriseComponent().setAttributeValue("CatheterReplaced", this.state.CatheterReplaced);
            }
            this.applet.getEnterpriseComponent().setAttributeValue("BacterialInfectionConfirmed", this.state.BacterialInfectionConfirmed);
            this.applet.getEnterpriseComponent().setAttributeValue("FungalInfectionConfirmed", this.state.FungalInfectionConfirmed);
            this.applet.getEnterpriseComponent().setAttributeValue("TypeOfInfectionExitSite", this.state.TypeOfInfectionExitSite);
            this.applet.getEnterpriseComponent().setAttributeValue("TypeOfInfectionTunnel", this.state.TypeOfInfectionTunnel);
            this.applet.getEnterpriseComponent().setAttributeValue("TypeOfInfectionPeritonitis", this.state.TypeOfInfectionPeritonitis);

            let moment = require("moment");

            let patientEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Patient");

            let endDate = convertToUTCDatePlusCurrentTime(moment(this.state.DateOfDischarge));
            //if (this.state.editMode) {
            //    this.applet.getEnterpriseComponent().setAttributeValue("EndDatetime", endDate);
            //} else {
            if (this.state.HospitalizationOutcome === Outcome1) {
                patientEC.forceAttributeValue("PatientEscalationStatus", "Converted to hemodialysis");
                patientEC.forceAttributeValue("PatientState", "Inactive");

                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Converted to hemodialysis");
                this.applet.getEnterpriseComponent().setAttributeValue("HemodialysisDate", this.state.DateOfDischarge);
                this.applet.getEnterpriseComponent().setAttributeValue("EndDatetime", endDate);
            } else if (this.state.HospitalizationOutcome === Outcome2) {
                patientEC.forceAttributeValue("PatientEscalationStatus", "Patient is deactivated");
                patientEC.forceAttributeValue("PatientState", "Inactive");

                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Patient is deactivated");
                this.applet.getEnterpriseComponent().setAttributeValue("DeactivationDate", this.state.DateOfDischarge);
                this.applet.getEnterpriseComponent().setAttributeValue("EndDatetime", endDate);
            } else if (this.state.HospitalizationOutcome === Outcome3) {
                patientEC.forceAttributeValue("PatientEscalationStatus", "Patient has expired");
                patientEC.forceAttributeValue("PatientState", "Archived");

                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Patient has expired");
                this.applet.getEnterpriseComponent().setAttributeValue("ExpirationDate", this.state.DateOfDischarge);
                this.applet.getEnterpriseComponent().setAttributeValue("EndDatetime", endDate);
            } else if (this.state.HospitalizationOutcome === Outcome4 && this.state.HospitalizationDiagnosis === "Infection - unresolved") {
                patientEC.forceAttributeValue("PatientEscalationStatus", "Infection treatment monitoring");
                patientEC.forceAttributeValue("PatientState", "Under watch");

                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Infection treatment monitoring");
            } else if (this.state.HospitalizationOutcome === Outcome4 && this.state.HospitalizationDiagnosis === "Infection - resolved") {
                patientEC.forceAttributeValue("PatientEscalationStatus", "Resolved infection");
                patientEC.forceAttributeValue("PatientState", "Healthy");

                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Resolved infection");
                this.applet.getEnterpriseComponent().setAttributeValue("InfectionResolutionDate", this.state.DateOfDischarge);
                this.applet.getEnterpriseComponent().setAttributeValue("EndDatetime", endDate);
            } else if (this.state.HospitalizationOutcome === Outcome4 && this.state.HospitalizationDiagnosis === "Non-infection related") {
                patientEC.forceAttributeValue("PatientEscalationStatus", "Not an infection");
                patientEC.forceAttributeValue("PatientState", "Healthy");

                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Not an infection");
                this.applet.getEnterpriseComponent().setAttributeValue("NonInfectionDiagnosisDate", this.state.DateOfDischarge);
                this.applet.getEnterpriseComponent().setAttributeValue("EndDatetime", endDate);
            }
            //}
            this.applet.save();

            this.applet.getView().setRefresh(true);
        }
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = () => {
        this.setState({updateBackgroundOperation: false});

        this.applet.setMode("View");

        this.applet.getView().initiateAction(null);
    };

    getName = () => {
        return "Hospitalized";
    };

    handleCathetherRemoved = (event) => {
        let radioValue = null;

        if (event.target.value === "true") {
            radioValue = true;
        } else if (event.target.value === "false") {
            radioValue = false;
        } else if (event.target.value === "N/A") {
            radioValue = "N/A";
        }
        this.setState({CatheterRemoved: radioValue, CatheterRemovedError: null});
    };

    handleCathetherReplaced = (event) => {
        let radioValue = null;

        if (event.target.value === "true") {
            radioValue = true;
        } else if (event.target.value === "false") {
            radioValue = false;
        } else if (event.target.value === "N/A") {
            radioValue = "N/A";
        }
        this.setState({CatheterReplaced: radioValue, CatheterReplacedError: null});
    };

    handleHospitalizationOutcome = (event) => {
        if (event.target.value === "Converted to hemodialysis") {
            this.setState({HospitalizationOutcome: event.target.value, convertedToHemoAlert: true, convertedToDeactivatedAlert: false, expiredAlert: false, HospitalizationOutcomeError: null});
        } else if (event.target.value === "Patient is deactivated") {
            this.setState({HospitalizationOutcome: event.target.value, convertedToHemoAlert: false, convertedToDeactivatedAlert: true, expiredAlert: false, CatheterRemovedError: null, CatheterReplaceError: null, HospitalizationOutcomeError: null});
        } else if (event.target.value === "Patient has expired") {
            this.setState({HospitalizationOutcome: event.target.value, convertedToHemoAlert: false, convertedToDeactivatedAlert: false, expiredAlert: true, CatheterRemovedError: null, CatheterReplaceError: null, HospitalizationOutcomeError: null});
        } else {
            this.setState({HospitalizationOutcome: event.target.value, convertedToHemoAlert: false, convertedToDeactivatedAlert: false, expiredAlert: false, CatheterRemovedError: null, CatheterReplaceError: null, HospitalizationOutcomeError: null});
        }
    };

    handleHospitalizationDiagnosis = (event) => {
        let dateOfDiagnosisError = this.state.DateOfDiagnosisError;
        if (event.target.value === "Non-infection related") {
            dateOfDiagnosisError = false;
        }
        this.setState({HospitalizationDiagnosis: event.target.value, HospitalizationDiagnosisError: null, DateOfDiagnosisError: dateOfDiagnosisError});
    };

    inputChangedHandler = (event, formElementId) => {
        if (formElementId === "DateOfDischarge") {
            let isMatch = true;

            if (event?.length > 0) {
                isMatch = !!event.match("^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$");
            }

            if (!event) {
                this.setState({DateOfDischarge: event, DateOfDischargeHelper: "Field must contain a value.", DateOfDischargeError: true});
            } else if (isMatch === false) {
                this.setState({DateOfDischarge: event, DateOfDischargeHelper: "Date improperly formatted.", DateOfDischargeError: true});
            } else {
                this.setState({DateOfDischarge: event, DateOfDischargeHelper: null, DateOfDischargeError: false});
            }
        } else if (formElementId === "Note") {
            let lengthWarningText = null;
            if (event.target.value.length === 1000) {
                lengthWarningText = "1000 characters allowed";
            }

            this.setState({Note: event.target.value, lengthWarningText: lengthWarningText});
        } else if (formElementId === "BacterialInfectionConfirmed") {
            this.setState({BacterialInfectionConfirmed: !this.state.BacterialInfectionConfirmed});
        } else if (formElementId === "FungalInfectionConfirmed") {
            this.setState({FungalInfectionConfirmed: !this.state.FungalInfectionConfirmed});
        } else if (formElementId === "TypeOfInfectionExitSite") {
            this.setState({TypeOfInfectionExitSite: !this.state.TypeOfInfectionExitSite});
        } else if (formElementId === "TypeOfInfectionTunnel") {
            this.setState({TypeOfInfectionTunnel: !this.state.TypeOfInfectionTunnel});
        } else if (formElementId === "TypeOfInfectionPeritonitis") {
            this.setState({TypeOfInfectionPeritonitis: !this.state.TypeOfInfectionPeritonitis});
        } else if (formElementId === "DateOfDiagnosis") {
            let isMatch = true;

            if (event?.length > 0) {
                isMatch = !!event.match("^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$");
            }

            if (!event) {
                this.setState({DateOfDiagnosis: event, DateOfDiagnosisHelper: "Field must contain a value.", DateOfDiagnosisError: true});
            } else if (isMatch === false) {
                this.setState({DateOfDiagnosis: event, DateOfDiagnosisHelper: "Date improperly formatted.", DateOfDiagnosisError: true});
            } else {
                this.setState({DateOfDiagnosis: event, DateOfDiagnosisHelper: null, DateOfDiagnosisError: false});
            }
        }
    };

    cancel1Handler = (event, reason) => {
        event.preventDefault();

        if (this.state.disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (this.state.disableEscapeKeyDown && reason === "escapeKeyDown") {
            return false;
        }

        this.setState({showConfirmation: true});
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    render() {
        const generalAlert = (<Typography className={this.props.classes.alertText}>Upon completion, please fill out any symptoms, medication, lab orders that may apply to this patient.</Typography>);
        const convertedToHemoAlert = (<Typography className={this.props.classes.alertText}>By indicating <b>converted to hemodialysis</b>, the patient will be moved to the inactive section.</Typography>);
        const convertedToDeactivatedAlert = (<Typography className={this.props.classes.alertText}>By indicating <b>patient is deactivated</b>, the patient will be moved to the inactive section.</Typography>);
        const expiredAlert = (<Typography className={this.props.classes.alertText}>By indicating <b>patient has expired</b>, the patient will be moved to the archived section.</Typography>);

        let alerts = [];

        if (this.state.generalAlert === true) {
            alerts.push(
                <Grid key="aa" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <div>
                            <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                        </div>
                        <div style={{marginTop: "0px"}}>
                            {generalAlert}
                        </div>
                    </div>
                </Grid>,
            );
        }
        if (this.state.convertedToHemoAlert === true) {
            alerts.push(
                <Grid key="bb" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <div>
                            <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                        </div>
                        <div style={{marginTop: "0px"}}>
                            {convertedToHemoAlert}
                        </div>
                    </div>
                </Grid>,
            );
        }
        if (this.state.convertedToDeactivatedAlert === true) {
            alerts.push(
                <Grid key="cc" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <div>
                            <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                        </div>
                        <div style={{marginTop: "0px"}}>
                            {convertedToDeactivatedAlert}
                        </div>
                    </div>
                </Grid>,
            );
        }
        if (this.state.expiredAlert === true) {
            alerts.push(
                <Grid key="dd" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <div>
                            <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                        </div>
                        <div style={{marginTop: "0px"}}>
                            {expiredAlert}
                        </div>
                    </div>
                </Grid>,
            );
        }

        let hospitalizationOutcomeError = null;
        if (this.state.HospitalizationOutcomeError !== null) {
            hospitalizationOutcomeError = (
                <Grid key={101} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "0px"}}>
                    <Typography className={this.props.classes.errorText}>A response is required.</Typography>
                </Grid>
            );
        }

        let diagnosisError = null;
        if (this.state.HospitalizationDiagnosisError !== null) {
            diagnosisError = <Typography className={this.props.classes.errorText}>A response is required.</Typography>;
        }

        let diagnosis = (
            <React.Fragment key={30}>
                <Grid key={30} item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl component="fieldset" margin="dense">
                        <FormLabel component="legend">What was the diagnosis from the hospital? *</FormLabel>
                        <RadioGroup aria-label="acknowledge1" name="ack1" value={this.state.HospitalizationDiagnosis} onChange={this.handleHospitalizationDiagnosis}>
                            <FormControlLabel value="Infection - resolved" control={<Radio />} label="Infection - resolved" />
                            <FormControlLabel value="Infection - unresolved" control={<Radio />} label="Infection - unresolved" />
                            <FormControlLabel value="Non-infection related" control={<Radio />} label="Non-infection related" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {diagnosisError}
            </React.Fragment>
        );

        let infectionContent = null;
        if (this.state.HospitalizationDiagnosis === "Infection - resolved" || this.state.HospitalizationDiagnosis === "Infection - unresolved") {
            let confirmedInfectionError = null;
            if (this.state.ConfirmedInfectionError !== null) {
                confirmedInfectionError = <Typography className={this.props.classes.errorText}>A response is required.</Typography>;
            }

            let typeOfInfectionError = null;
            if (this.state.TypeOfInfectionError !== null) {
                typeOfInfectionError = <Typography className={this.props.classes.errorText}>A response is required.</Typography>;
            }

            infectionContent = (
                <React.Fragment key={100}>
                    <Grid key={19} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                        <CCDatePicker
                            minDate={this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("StartDatetime")}
                            required={true}
                            helperText={this.state.DateOfDiagnosisHelper}
                            error={this.state.DateOfDiagnosisError}
                            key="DateOfDiagnosis"
                            label="Date of diagnosis (MM/DD/20YY)"
                            id="DateOfDiagnosis"
                            onChange={(event) => this.inputChangedHandler(event, "DateOfDiagnosis")}
                            value={this.state.DateOfDiagnosis}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid key={20} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Does this patient have a confirmed infection?</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.BacterialInfectionConfirmed} onChange={(event) => this.inputChangedHandler(event, "BacterialInfectionConfirmed")} name="bacterialInfectionConfirmed" />}
                                    label="Yes - Bacterial"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.FungalInfectionConfirmed} onChange={(event) => this.inputChangedHandler(event, "FungalInfectionConfirmed")} name="fungalInfectionConfirmed" />}
                                    label="Yes - Fungal"
                                />
                            </FormGroup>
                        </FormControl>
                        {confirmedInfectionError}
                    </Grid>
                    <Grid key={21} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Type of infection?</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.TypeOfInfectionExitSite} onChange={(event) => this.inputChangedHandler(event, "TypeOfInfectionExitSite")} name="bacterialInfectionConfirmed" />}
                                    label="Exit site infection"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.TypeOfInfectionTunnel} onChange={(event) => this.inputChangedHandler(event, "TypeOfInfectionTunnel")} name="typeOfInfectionTunnel" />}
                                    label="Tunnel infection"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.TypeOfInfectionPeritonitis} onChange={(event) => this.inputChangedHandler(event, "TypeOfInfectionPeritonitis")} name="typeOfInfectionPeritonitis" />}
                                    label="Peritonitis"
                                />
                            </FormGroup>
                        </FormControl>
                        {typeOfInfectionError}
                    </Grid>
                </React.Fragment>
            );
        }

        let catheterRemovedError = null;
        if (this.state.CatheterRemovedError !== null) {
            catheterRemovedError = (
                <Grid key={101} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "0px"}}>
                    <Typography className={this.props.classes.errorText}>A Yes/No response is required.</Typography>
                </Grid>
            );
        }

        let catheterReplacedError = null;
        if (this.state.CatheterReplacedError !== null) {
            catheterReplacedError = (
                <Grid key={99} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "0px"}}>
                    <Typography className={this.props.classes.errorText}>A Yes/No response is required.</Typography>
                </Grid>
            );
        }

        let catheterRemovedContent = (
            <React.Fragment key={41}>
                <FormControl component="fieldset" margin="dense">
                    <FormLabel component="legend">Was the catheter removed?</FormLabel>
                    <RadioGroup aria-label="acknowledge2" name="ack2" value={this.state.CatheterRemoved} onChange={this.handleCathetherRemoved}>
                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                        <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
                    </RadioGroup>
                </FormControl>
                <br />
            </React.Fragment>
        );

        let catheterReplacedContent = (
            <React.Fragment key={40}>
                <FormControl component="fieldset" margin="dense">
                    <FormLabel component="legend">Was the catheter replaced?</FormLabel>
                    <RadioGroup aria-label="acknowledge1" name="ack1" value={this.state.CatheterReplaced} onChange={this.handleCathetherReplaced}>
                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                        <FormControlLabel value="N/A" control={<Radio />} label="N/A" />
                    </RadioGroup>
                </FormControl>
            </React.Fragment>
        );

        if (this.state.HospitalizationOutcome === Outcome1) {
            catheterRemovedContent = (
                <React.Fragment key={51}>
                    <FormControl component="fieldset" margin="dense">
                        <FormLabel component="legend">Was the catheter removed?</FormLabel>
                        <RadioGroup aria-label="acknowledge2" name="ack2" value={this.state.CatheterRemoved} onChange={this.handleCathetherRemoved}>
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </React.Fragment>
            );

            catheterReplacedContent = (
                <React.Fragment key={50}>
                    <FormControl component="fieldset" margin="dense">
                        <FormLabel component="legend">Was the catheter replaced?</FormLabel>
                        <RadioGroup aria-label="acknowledge1" name="ack1" value={this.state.CatheterReplaced} onChange={this.handleCathetherReplaced}>
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </React.Fragment>
            );
        }

        let assistiveText = null;
        if (this.state.lengthWarningText !== null) {
            assistiveText = <Typography className={this.props.classes.assistiveText}>{this.state.lengthWarningText}</Typography>;
        }

        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form>
                    <Grid key="id6" item container spacing={2}>
                        <Grid key={1} item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={this.props.classes.addEpisodeText}>By answering and clicking the button below, you are acknowledging that the patient is discharged from the hospital.</Typography>
                            <br />
                        </Grid>
                        <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CCDatePicker
                                minDate={this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("StartDatetime")}
                                required={true}
                                helperText={this.state.DateOfDischargeHelper}
                                error={this.state.DateOfDischargeError}
                                key="DateOfDischarge"
                                label="Date of discharge (MM/DD/20YY)"
                                id="DateOfDischarge"
                                onChange={(event) => this.inputChangedHandler(event, "DateOfDischarge")}
                                value={this.state.DateOfDischarge}
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        <Grid key={3} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl component="fieldset" margin="dense">
                                <FormLabel component="legend">What was the outcome of the hospitalization? *</FormLabel>
                                <RadioGroup aria-label="acknowledge1" name="ack1" value={this.state.HospitalizationOutcome} onChange={this.handleHospitalizationOutcome}>
                                    <FormControlLabel value={Outcome1} control={<Radio />} label={Outcome1} />
                                    <FormControlLabel value={Outcome2} control={<Radio />} label={Outcome2} />
                                    <FormControlLabel value={Outcome3} control={<Radio />} label={Outcome3} />
                                    <FormControlLabel value={Outcome4} control={<Radio />} label={Outcome4} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {hospitalizationOutcomeError}
                        {diagnosis}
                        {infectionContent}
                        <Grid key={10} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                            {catheterRemovedContent}
                        </Grid>
                        {catheterRemovedError}
                        <Grid key={11} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                            {catheterReplacedContent}
                        </Grid>
                        {catheterReplacedError}
                        <Grid key={12} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                key="Note"
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                                fullWidth={true}
                                id="Note"
                                label="Notes"
                                onChange={(event) => this.inputChangedHandler(event, "Note")}
                                value={this.state.Note}
                                variant="outlined"
                                multiline={true}
                                rows={5}
                                inputProps={{maxLength: 1000}}
                            />
                            {assistiveText}
                        </Grid>
                        {alerts}
                    </Grid>
                </form>
            </Grid>
        );

        let content = (
            <React.Fragment>
                {modalContent}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Discharged from hospital</DialogTitle>
                    <DialogContent>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        <CCButton
                            variant="green"
                            animate={this.state.updateBackgroundOperation === true}
                            onClick={this.submitHandler}>
                            Save
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(PatientEscalationDischarged),
);