import DeviceRIEnterpriseComponentMetadata from "./DeviceRIEnterpriseComponentMetadata";

class DeviceNRIEnterpriseComponentMetadata extends DeviceRIEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "DeviceNRI";
    }
}


export default DeviceNRIEnterpriseComponentMetadata;