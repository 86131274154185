import {StylesProvider, ThemeProvider} from "@material-ui/core";
import React, {createContext, Suspense, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {SystemError} from "./components/error/SystemError";
import {AppUnloadLogout} from "./components/logout/AppUnloadLogout";
import {InactiveLogout} from "./components/logout/InactiveLogout";
import {MqttLogout} from "./components/logout/MqttLogout";
import MobileNavBar from "./components/navigation/MobileNavBar";
import NavBar from "./components/navigation/NavBar";
import Routes from "./Routes";
import {createDefaultTheme} from "./theme";
import {useAnalyticsClient} from "./util/Analytics";
import memoryContext from "./util/MemoryContext";
import {useMqttClient} from "./util/MqttClient";
import {useSessionContext} from "./util/SessionContext";
import {useBreakpoint} from "./util/useBreakpoint";
import * as userAgent from "./util/UserAgent";

export const AppContext = createContext(null);

export default function App() {

    const [dialogComponent, setDialogComponent] = useState(undefined);

    const location = useLocation();

    const sessionContext = useSessionContext();
    const mqttClient = useMqttClient(sessionContext);

    // eslint-disable-next-line no-unused-vars
    const analyticsClient = useAnalyticsClient();

    const theme = createDefaultTheme();

    const loggedIn = sessionContext.valid() && location.pathname !== "/logout";

    const breakpoint = useBreakpoint();
    memoryContext.breakpoint = breakpoint; // used below, so set it now

    useEffect(() => {
        memoryContext.breakpoint = breakpoint;
    }, [breakpoint]);

    useEffect(() => {
        if (!sessionContext.valid()) {
            if (sessionContext.size() > 0) {
                console.log(`session is not valid but has content... resetting. ${JSON.stringify(sessionContext.dump())}`);
                sessionContext.reset();
            }
            setDialogComponent(undefined);
        }
    }, [sessionContext]);

    function showDialogComponent(Component, props) {
        if (Component) {
            props = props ? {...props} : {};
            props.TransitionProps = props.TransitionProps ?? {};
            const onExited = props.TransitionProps.onExited;
            props.TransitionProps.onExited = (e) => {
                if (onExited) onExited(e);
                setDialogComponent(undefined);
            };
        }

        setDialogComponent(Component ? <Component {...props} /> : undefined);
    }

    function showViewComponent(Component, props) {
        if (Component) {
            props = props ? {...props} : {};
            props.onInitiateAction = (action) => {
                if (action === null) {
                    setDialogComponent(undefined);
                }
            };
        }

        setDialogComponent(Component ? <Component {...props} /> : undefined);
    }

    const AppContextExports = {
        sessionContext,
        mqttClient,
        showDialogComponent,
        showViewComponent,
    };

    const useNavBar = loggedIn && ![
        "/turbidityDataReport",
    ].includes(location.pathname);

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Suspense fallback={<div>Loading...</div>}>
                    <AppContext.Provider value={AppContextExports}>
                        {useNavBar ? userAgent.isMobile() ? <MobileNavBar /> : <NavBar /> : undefined}
                        <Routes />
                        {loggedIn ? (
                            <>
                                <MqttLogout />
                                <AppUnloadLogout />
                                <InactiveLogout />
                                <SystemError />
                            </>
                        ) : undefined}
                        {dialogComponent}
                    </AppContext.Provider>
                </Suspense>
            </ThemeProvider>
        </StylesProvider>
    );
}

export function useAppContext() {
    return useContext(AppContext);
}

export function withAppContext(Component) {
    return (props) => {
        const appContext = useAppContext();
        return <Component appContext={appContext} {...props} />;
    };
}
