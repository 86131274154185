import UserEnterpriseComponentMetadata from "./UserEnterpriseComponentMetadata";

class PrimaryNurseEnterpriseComponentMetadata extends UserEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "PrimaryNurse";
        this.defaultCacheSize = 1000;
    }
}

export default PrimaryNurseEnterpriseComponentMetadata;