import PatientAREnterpriseComponentMetadata from "./PatientAREnterpriseComponentMetadata";

class PatientINEnterpriseComponentMetadata extends PatientAREnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "PatientIN";
    }
}

export default PatientINEnterpriseComponentMetadata;