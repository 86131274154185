import {Typography} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import spinner from "../assets/Spinner-1s-200px.svg";

class CloudcathName extends Component {
    state = {
        fetchBackgroundOperation: false,
        buffer: [],
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;
    }

    componentDidMount() {
        this.applet.getEnterpriseComponent().addRecordChangedListener(this);
        this.applet.getEnterpriseComponent().addLoadStartedListener(this);
        this.applet.getEnterpriseComponent().addLoadCompletedListener(this);
        this.applet.getEnterpriseComponent().addInvalidListener(this);
        this.applet.getEnterpriseComponent().addInsertCompletedListener(this);

        this.applet.getEnterpriseComponent().executeQuery(0, 200);
    }

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    }

    enterpriseComponentValueChanged = (buffer) => {
        let localBuffer = buffer.slice(0, 1000);

        this.setState({buffer: localBuffer, fetchBackgroundOperation: false});
    };

    enterpriseComponentLoadStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentInsertCompleted = (insertedRow) => {
        let row = {};

        let newBuffer = [...this.state.buffer];

        for (let i = 0; i < newBuffer.length; i++) {
            if (newBuffer[i]["USID"] === insertedRow["USID"]) {
                row = newBuffer[i];
                break;
            }
        }

        let keys = Object.keys(this.applet.getEnterpriseComponent().getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.applet.getEnterpriseComponent().getAttributes()[keys[i]];
            row[attribute.getName()] = insertedRow[attribute.getName()];
        }
    };

    enterpriseComponentInvalidated = (buffer) => {
        this.setState({buffer: buffer});
        this.applet.getEnterpriseComponent().executeQuery(0, 1000);
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        let localBuffer = buffer.slice(0, 1000);

        this.setState({buffer: localBuffer, fetchBackgroundOperation: false});
    };

    getName = () => {
        return this.applet.getName();
    };

    render() {
        return this.state.fetchBackgroundOperation === false && this.applet.getEnterpriseComponent().isValid() === true ? (
            <>
                {this.state.buffer.length ? this.state.buffer.map((o, i) => {
                    return <Typography key={i} className={this.props.className}>{this.state.buffer[i][this.props.column]}</Typography>;
                }) : (
                    <Typography className={this.props.className}>--</Typography>
                )}
            </>
        ) : (
            <img src={spinner} alt="spinner" width="35" height="35" />
        );
    }
}

export default withAppContext(CloudcathName);