import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";

class UserReactivate extends Component {
    state = {
        updateBackgroundOperations: [],
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;
    };

    componentDidMount() {
        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    enterpriseComponentUpdateStarted = (buffer, ec) => {
        let updateBackgroundOperations = [...this.state.updateBackgroundOperations];
        updateBackgroundOperations.push(ec.getName());
        this.setState({"updateBackgroundOperations": updateBackgroundOperations});
    };

    enterpriseComponentUpdateCompleted = (buffer, ec) => {
        this.applet.cancel();
    };

    getName = () => {
        return "UserReactivate";
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.applet.setMode("Edit");
        this.applet.getEnterpriseComponent().setAttributeValue("UserStatus", "Active");
        this.applet.getEnterpriseComponent().executeUpdate();
    };

    cancel1Handler = (event, reason) => {
        event.preventDefault();
        this.applet.cancel();
    };

    render() {

        const userFullName = this.applet.getEnterpriseComponent().getAttributeValue("FullName");

        let disableAllButtons = Boolean(this.state.updateBackgroundOperations.length);

        let modalContent = (
            <Grid container justifyContent="center">
                <form onSubmit={e => e.preventDefault()}>
                    <Grid item container style={{width: "32em"}} spacing={4}>
                        <Grid item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={this.props.classes.addEpisodeText}>Are you sure you want to <b>reactivate</b> {userFullName}?</Typography>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        );

        return (
            <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                <DialogTitle>Reactivate user</DialogTitle>
                <DialogContent style={{padding: "8px 16px"}}>
                    {modalContent}
                </DialogContent>
                <DialogActions>
                    <CCButton variant="secondary" onClick={this.cancel1Handler} disabled={Boolean(this.state.updateBackgroundOperations.length)}>
                        Cancel
                    </CCButton>
                    <CCButton
                        variant="green"
                        animate={Boolean(this.state.updateBackgroundOperations.length)}
                        onClick={this.submitHandler}
                        disabled={disableAllButtons && this.state.updateBackgroundOperations.length === 0}>
                        Save
                    </CCButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(UserReactivate),
);