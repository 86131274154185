import {TextField} from "@material-ui/core";
import React, {useState} from "react";
import "./css/CCTextField.scss";

export default function CCTextField({
    fullWidth, // true | false
    size, // small | medium
    margin, // none | dense | normal
    variant, // standard | outlined | filled
    onChange,
    onBlur,
    onValidate,
    InputProps,
    inputProps,
    children,
    ...props
}) {

    const [blurred, setBlurred] = useState(false);

    size = size !== undefined ? size : props.label ? "medium" : "small";
    margin = margin !== undefined ? margin : props.label ? "normal" : "none";

    if (InputProps === undefined) InputProps = {};
    if (InputProps.autoComplete === undefined) InputProps.autoComplete = "off";

    InputProps.inputProps = inputProps = {
        ...(InputProps.inputProps ?? {}),
        ...(inputProps ?? {}),
    };

    if (inputProps.autoCapitalize === undefined && inputProps.autocapitalize === undefined) inputProps.autoCapitalize = "none";
    if (inputProps.autoComplete === undefined && inputProps.autocomplete === undefined) inputProps.autoComplete = "off";
    if (inputProps.spellCheck === undefined && inputProps.spellcheck === undefined) inputProps.spellCheck = "false";

    return (
        <TextField
            {...{"data-cc-component": "CCTextField"}}
            {...{"data-size": size}}
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            size={size}
            margin={margin}
            variant={variant !== undefined ? variant : "outlined"}
            InputProps={InputProps}
            inputProps={inputProps} // same as InputProps['inputProps']
            onChange={e => {
                if (onChange) onChange(e);
                if (onValidate) {
                    const value = e.target.value;
                    const shouldValidate = blurred;
                    if (value.length === 0) setBlurred(false);
                    if (shouldValidate) onValidate(e);
                }
            }}
            onBlur={e => {
                if (onBlur) onBlur(e);
                if (onValidate) {
                    const value = e.target.value;
                    const validated = blurred;
                    if (value.length > 0) setBlurred(true);
                    if (!validated) onValidate(e);
                }
            }}
            {...props}
        >
            {children}
        </TextField>
    );
}
