import RelationshipMetadata from "../RelationshipMetadata";

class EscalationCommunicationRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "EscalationCommunication";
        this.parentEnterpriseComponent = "Escalation";
        this.childEnterpriseComponent = "EscalationCommunication";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "EscalationUSID";
    }
}

export default EscalationCommunicationRelationshipMetadata;