import {withRouter} from "react-router-dom";
import {withAppContext} from "../../../App";
import PatientListBaseApplet from "./PatientListBaseApplet";

class PatientListInactiveApplet extends PatientListBaseApplet {
    constructor(props) {
        super(props, {
            name: "PatientListInactiveApplet",
            title: "Deactivated",
            autoUnfold: false,
        });
    }
}

export default withRouter(withAppContext(PatientListInactiveApplet));
