import {withAppContext} from "../../../App";
import PatientEscalationNotAnInfection from "../../../components/PatientEscalationNotAnInfection";

import Applet from "../Applet";

class PatientEscalationNotAnInfectionApplet extends Applet {
    state = {
        Name: "PatientEscalationNotAnInfectionApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <PatientEscalationNotAnInfection applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientEscalationNotAnInfectionApplet);
