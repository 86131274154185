import RelationshipMetadata from "../RelationshipMetadata";

class EscalationLabAttachmentRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "EscalationLabAttachment";
        this.parentEnterpriseComponent = "EscalationLab";
        this.childEnterpriseComponent = "LabAttachment";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "ParentUSID";
        this.invalidateChildren = false;
    }
}

export default EscalationLabAttachmentRelationshipMetadata;