const typography = {
    fontFamily: [
        "Poppins",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
    ].join(","),
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontSize: "22px",
        fontWeight: 600,
    },
    h2: {
        fontSize: "18px",
        fontWeight: 600,
    },
    h3: {
        fontSize: "16px",
        fontWeight: 600,
    },
    h4: {
        fontSize: "14px",
        fontWeight: 400,
    },
    h5: {
        fontSize: "14px",
        fontWeight: 400,
    },
    h6: {
        fontSize: "14px",
        fontWeight: 400,
    },
    body1: {
        fontSize: 16,
    },
    body2: {
        fontSize: 14,
    },
    button: {
        textTransform: "none",
    },
    subtitle1: {
        fontSize: "22px",
        fontWeight: 600,
    },
    subtitle2: {
        fontSize: "18px",
        fontWeight: 600,
    },
};

export default typography;
