import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class EscalationNoteEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "EscalationNote";
        this.defaultCacheSize = 50;

        this.attributes["EscalationUSID"] = new EnterpriseComponentAttributeMetadata(
            "EscalationUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CrossReferenceUSID"] = new EnterpriseComponentAttributeMetadata(
            "CrossReferenceUSID", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CrossReferenceType"] = new EnterpriseComponentAttributeMetadata(
            "CrossReferenceType", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            20,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnteredByUSID"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByUSID", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            "AllActiveUsers",  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnteredByFirstName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByFirstName", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnteredByLastName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByLastName", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnteredByFullName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByFullName", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            200,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Note"] = new EnterpriseComponentAttributeMetadata(
            "Note", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1000,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EventDate"] = new EnterpriseComponentAttributeMetadata(
            "EventDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EventDateDisplay"] = new EnterpriseComponentAttributeMetadata(
            "EventDateDisplay", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EventDatetime"] = new EnterpriseComponentAttributeMetadata(
            "EventDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            "^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])[T](0?[0-9]|1[0-9]|2[0123]):([0-5]?[0-9]):([0-5]?[0-9])[Z]$",  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EventDatetimeDisplay"] = new EnterpriseComponentAttributeMetadata(
            "EventDatetimeDisplay", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            "^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])[T](0?[0-9]|1[0-9]|2[0123]):([0-5]?[0-9]):([0-5]?[0-9])[Z]$",  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CreatedDatetimeDisplay"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetimeDisplay", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
    }
}

export default EscalationNoteEnterpriseComponentMetadata;