import CloudcathTable from "../../../../src/components/CloudcathTable";
import {withAppContext} from "../../../App";
import Applet from "../Applet";

class PatientEscalationLabListApplet extends Applet {

    createLab = (type) => {
        this.getView().initiateAction(type);
    };

    render() {
        return <CloudcathTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Lab Orders"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               tableHeaderType="Type2"
                               selectors={this.state.Selectors}
                               headerRenderer={this.state.HeaderRenderer}
                               sort={this.state.Sort} />;
    }

    selectLabHandler = (index) => {
        this.selectHandler(index);

        let name = this.getEnterpriseComponent().getBuffer()[this.getEnterpriseComponent().getRecordPointer()]["Name"];

        if (name === "Cell Count") {
            this.getView().initiateAction("ViewCellCountLab");
        } else if (name === "Culture") {
            this.getView().initiateAction("ViewCultureLab");
        } else if (name === "Other") {
            this.getView().initiateAction("ViewOtherLab");
        }
    };

    editLabHandler = (event, index) => {
        event.stopPropagation();

        this.selectHandler(index);

        let name = this.getEnterpriseComponent().getBuffer()[this.getEnterpriseComponent().getRecordPointer()]["Name"];

        if (name === "Cell Count") {
            this.getView().initiateAction("EditCellCountLab");
        } else if (name === "Culture") {
            this.getView().initiateAction("EditCultureLab");
        } else if (name === "Other") {
            this.getView().initiateAction("EditOtherLab");
        }
    };

    state = {
        Name: "PatientEscalationLabListApplet",
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        HeaderRenderer: "Lab Header Renderer",
        Selectors: {
            "AddLab": this.createLab,
            "Refresh": this.refreshHandler,
            "Select": this.selectLabHandler,
            "Edit": this.editLabHandler,
        },
        Placement: {
            "xs": [
                {
                    id: "Name",
                },
                {
                    id: "Result",
                },
            ],
            "xl": [
                {
                    id: "Name",
                },
                {
                    id: "SampleCollectionDateDisplay",
                },
                {
                    id: "Result",
                },
                {
                    id: "Attachment",
                },
                {
                    id: "Edit",
                },
            ],
        },
        Columns: {
            "Name": {
                id: "Name",
                width: "80px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Type",
                align: "left",
            },
            "SampleCollectionDateDisplay": {
                id: "SampleCollectionDateDisplay",
                width: "180px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Sample collection date",
                align: "left",
            },
            "Result": {
                id: "Result",
                width: "310px",
                cellRenderer: "Lab Result Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Result",
                align: "left",
            },
            "Attachment": {
                id: "Attachment",
                width: "100px",
                cellRenderer: "Attachment Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Attachment(s)",
                align: "center",
            },
            "Edit": {
                id: "Edit",
                width: "20px",
                cellRenderer: "Edit Cell",
                header: "enabled",
                headerRenderer: "Blank Header",
                headerTitle: "",
                align: "left",
            },
        },
        Sort: [
            {
                "identifier": 1,
                "default": true,
                "visible": false,
                "options": {
                    "RowIdentifier": "DESC",
                },
            },
        ],
    };

}

export default withAppContext(PatientEscalationLabListApplet);
