export function Condition() {

    let notify;
    let cancel;

    const lock = new Promise((resolve, reject) => {
        notify = resolve;
        cancel = reject;
    });

    return {
        notify: (value) => notify(value),
        cancel: (reason) => cancel(reason),
        lock: lock,
    };
}
