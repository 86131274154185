import {Container, Grid, Typography, withStyles} from "@material-ui/core";
import {Refresh} from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React from "react";
import {withAppContext} from "../../../App";
import CCButton from "../../../components/common/CCButton";
import styles from "../../../theme/styles";
import * as userAgent from "../../../util/UserAgent";
import PatientAlertArchivedApplet from "../../applet/impl/PatientAlertArchivedApplet";
import PatientAlertInactiveApplet from "../../applet/impl/PatientAlertInactiveApplet";
import PatientDetailApplet from "../../applet/impl/PatientDetailApplet";
import PatientListActionRequiredApplet from "../../applet/impl/PatientListActionRequiredApplet";
import PatientListArchiveApplet from "../../applet/impl/PatientListArchiveApplet";
import PatientListHealthyApplet from "../../applet/impl/PatientListHealthyApplet";
import PatientListInactiveApplet from "../../applet/impl/PatientListInactiveApplet";
import PatientListUnderWatchApplet from "../../applet/impl/PatientListUnderWatchApplet";
import eoecClassFactory from "../../EOECClassFactory";

import View from "../View";

class PatientView extends View {
    state = {
        showAction: null,
        controls: {
            AssignedNurse: {
                value: "30d8ff0c-6780-4502-8cc0-8bc8e5c4a120",
            },
            AssignedPhysician: {
                value: "40d8ff0c-6780-4502-8cc0-8bc8e5c4a120",
            },
            DeviceLastUsed: {
                value: "1",
            },
            InactivePatients: {
                value: "2",
            },
        },
        perspectives: {
            default: {},
        },
        activePerspective: "default",
        showInactivePatients: "1",
        showArchivePatients: "1",
        createObjectEnabled: false,
    };

    constructor(props) {
        super(props);
        this.state.enterpriseObjectAR = eoecClassFactory("eo", "PatientAR");
        this.state.enterpriseObjectUW = eoecClassFactory("eo", "PatientUW");
        this.state.enterpriseObjectS0 = eoecClassFactory("eo", "PatientS0");
        this.state.enterpriseObjectIN = eoecClassFactory("eo", "PatientIN");
        this.state.enterpriseObjectArchive = eoecClassFactory("eo", "PatientArchive");

        let patientS0enterpriseComponent = this.state.enterpriseObjectS0.getEnterpriseComponent("Patient");
        patientS0enterpriseComponent.addInvalidListener(this);
        patientS0enterpriseComponent.addLoadCompletedListener(this);
        patientS0enterpriseComponent.addInsertCompletedListener(this);

        /*
        this.primaryNursePicklist = new Picklist("PrimaryNurse")
        this.primaryPhysicianPicklist = new Picklist("PrimaryPhysician")
        this.deviceLastUsed = new Picklist("DeviceLastUsed")
        this.inactivePatients = new Picklist("InactivePatients")
         */
    };

    componentDidMount() {
        if (this.props.appContext.sessionContext.patientAlerts !== null) {
            this.initiateAction("PatientAlerts");
        }
    }

    enterpriseComponentInvalidated = (buffer) => {
        this.setState({createObjectEnabled: false});
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        this.setState({createObjectEnabled: true});
    };

    enterpriseComponentInsertCompleted = (buffer) => {
        this.props.history.push({
            pathname: "/detail",
            search: "?patientUSID=" + this.state.enterpriseObjectS0.getEnterpriseComponent("Patient").getAttributeValue("USID"),
        });
    };

    getName = () => {
        return "PatientView";
    };

    initiateAction = (action) => {
        if (action === "RefreshPatient") {
            this.state.enterpriseObjectAR.getEnterpriseComponent("Patient").invalidate();
            this.state.enterpriseObjectUW.getEnterpriseComponent("Patient").invalidate();
            this.state.enterpriseObjectS0.getEnterpriseComponent("Patient").invalidate();
            this.state.enterpriseObjectIN.getEnterpriseComponent("Patient").invalidate();
            this.state.enterpriseObjectArchive.getEnterpriseComponent("Patient").invalidate();
        }
        this.setState({showAction: action});
    };

    render() {
        let content = null;

        if (this.getActivePerspective() === "default") {
            content = (
                <React.Fragment>
                    {this.renderHeader()}
                    <Grid container direction="column" spacing={10}>
                        {this.renderDefaultPerspective()}
                    </Grid>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Container className={this.props.classes.containerMargin} maxWidth="lg">
                    {this.renderCreatePatient()}
                    {content}
                </Container>
                {this.renderPatientAlert()}
            </React.Fragment>
        );
    }

    renderCreatePatient = () => {
        if (this.state.showAction === "Add") {
            return <PatientDetailApplet enterpriseComponent={this.state.enterpriseObjectS0.getEnterpriseComponent("Patient")} view={this} availableModes={["Edit", "New", "View"]} defaultMode="New" />;
        }
        return null;
    };

    renderPatientAlert = () => {
        if (this.props.appContext.sessionContext.patientAlerts.length > 0) {
            if (this.props.appContext.sessionContext.patientAlerts[0].PatientState === "Inactive") {
                return <PatientAlertInactiveApplet enterpriseComponent={this.state.enterpriseObjectIN.getEnterpriseComponent("Patient")} view={this} />;
            } else {
                return <PatientAlertArchivedApplet enterpriseComponent={this.state.enterpriseObjectArchive.getEnterpriseComponent("Patient")} view={this} />;
            }
        }
        return null;
    };

    renderDefaultPerspective = () => {
        if (this.state.enterpriseObject !== null) {
            return [
                <div key={1} style={{backgroundColor: "#2CA4DF", opacity: 0.3, height: "3px"}} />,
                <PatientListActionRequiredApplet key={2} enterpriseComponent={this.state.enterpriseObjectAR.getEnterpriseComponent("Patient")} view={this} />,
                <div key={3} style={{backgroundColor: "#2CA4DF", opacity: 0.3, height: "3px", marginTop: "20px"}} />,
                <PatientListUnderWatchApplet key={4} enterpriseComponent={this.state.enterpriseObjectUW.getEnterpriseComponent("Patient")} view={this} />,
                <div key={5} style={{backgroundColor: "#2CA4DF", opacity: 0.3, height: "3px", marginTop: "20px"}} />,
                <PatientListHealthyApplet key={6} enterpriseComponent={this.state.enterpriseObjectS0.getEnterpriseComponent("Patient")} view={this} />,
                ...(this.state.showInactivePatients === "1" ? [
                    <div key={7} style={{backgroundColor: "#2CA4DF", opacity: 0.3, height: "3px"}} />,
                    <PatientListInactiveApplet key={8} enterpriseComponent={this.state.enterpriseObjectIN.getEnterpriseComponent("Patient")} view={this} />,
                ] : []),
                ...(this.state.showArchivePatients === "1" ? [
                    <div key={9} style={{backgroundColor: "#2CA4DF", opacity: 0.3, height: "3px"}} />,
                    <PatientListArchiveApplet key={10} enterpriseComponent={this.state.enterpriseObjectArchive.getEnterpriseComponent("Patient")} view={this} />,
                ] : []),
            ];
        }
        return [];
    };

    renderHeader = () => {
        return (
            <Grid container direction="column" style={{margin: "16px 0 33px 0"}}>
                <Grid container item direction="row" justifyContent="space-between">
                    <Typography data-test-id="PatientView/text:header" variant="h1" style={{marginTop: "15px"}}>{this.props.appContext.sessionContext.providerName}/{this.props.appContext.sessionContext.clinicName}</Typography>
                    <div>
                        {!userAgent.isMobile() && this.props.appContext.sessionContext.optionalCharacteristics !== "View only" ? (
                            <CCButton
                                style={{marginTop: "15px", marginRight: "0", width: "170px"}}
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => this.initiateAction("Add")}
                                disabled={!this.state.createObjectEnabled}>
                                Add Patient
                            </CCButton>
                        ) : null}
                        {!userAgent.isMobile() ? (
                            <CCButton
                                variant="secondary"
                                style={{marginTop: "15px", marginRight: "0", width: "170px"}}
                                startIcon={<Refresh />}
                                onClick={() => this.initiateAction("RefreshPatient")}
                                disabled={!this.state.createObjectEnabled}>
                                Refresh
                            </CCButton>
                        ) : null}
                    </div>
                </Grid>
            </Grid>
        );
    };

    setRefresh = (value) => {
        this.refresh = value;
    };
}

export default withAppContext(withStyles(styles, {withTheme: true})(PatientView));