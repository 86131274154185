import EnterpriseComponentMetadata from "../../metadata/ec/EnterpriseComponentMetadata";
import eoecClassFactory from "../EOECClassFactory";

class EnterpriseComponentAttribute extends EnterpriseComponentMetadata {
    constructor(parent, enterpriseComponentAttributeMetadata) {
        super();

        this.fEnterpriseComponent = parent;
        this.name = enterpriseComponentAttributeMetadata.getName();
        this.fNoCopy = enterpriseComponentAttributeMetadata.getNoCopy();
        this.fNoUpdate = enterpriseComponentAttributeMetadata.getNoUpdate();
        this.fRequired = enterpriseComponentAttributeMetadata.getRequired();
        this.fRequiredForQuery = enterpriseComponentAttributeMetadata.getRequiredForQuery();
        this.fPicklistName = enterpriseComponentAttributeMetadata.getPicklist();
        this.fPicklist = null;
        this.fScale = enterpriseComponentAttributeMetadata.getScale();
        this.fPrecision = enterpriseComponentAttributeMetadata.getPrecision();
        this.fLength = enterpriseComponentAttributeMetadata.getLength();
        this.fMinLength = enterpriseComponentAttributeMetadata.getMinLength();
        this.fPattern = enterpriseComponentAttributeMetadata.getPattern();
        this.fType = enterpriseComponentAttributeMetadata.getType();
        this.fDefaultValue = enterpriseComponentAttributeMetadata.getDefaultValue();
        this.fComputed = enterpriseComponentAttributeMetadata.getComputed();
        this.fPickMaps = enterpriseComponentAttributeMetadata.getPickMaps();

        if (this.fPicklistName !== null) {
            this.fPicklist = eoecClassFactory("pl", this.fPicklistName);
        }
    }

    getAttributeValue = (type) => {
        return this.fEnterpriseComponent.getAttributeValue(this.name, type);
    };

    getComputed = () => {
        return this.fComputed;
    };

    getDefaultValue = () => {
        return this.fDefaultValue;
    };

    getEnterpriseComponent = () => {
        return this.fEnterpriseComponent;
    };

    getInboundPickMaps = () => {
        let out = [];
        for (let i = 0; i < this.fPickMaps.length; i++) {
            if (this.fPickMaps[i].getType() === "Inbound") {
                out.push(this.fPickMaps[i]);
            }
        }
        return out;
    };

    getLength = () => {
        return this.fLength;
    };

    getMinLength = () => {
        return this.fMinLength;
    };

    getNoCopy = () => {
        return this.fNoCopy;
    };

    getNoUpdate = () => {
        return this.fNoUpdate;
    };

    getOutboundPickMaps = () => {
        let out = [];
        for (let i = 0; i < this.fPickMaps.length; i++) {
            if (this.fPickMaps[i].getType() === "Outbound") {
                out.push(this.fPickMaps[i]);
            }
        }
        return out;
    };

    getPattern = () => {
        return this.fPattern;
    };

    getPicklist = () => {
        return this.fPicklist;
    };

    getPickMaps = () => {
        return this.fPickMaps;
    };

    getPrecision = () => {
        return this.fPrecision;
    };

    getRequired = () => {
        return this.fRequired;
    };

    getRequiredForQuery = () => {
        return this.fRequiredForQuery;
    };

    getScale = () => {
        return this.fScale;
    };

    getType = () => {
        return this.fType;
    };

    isModified = () => {
        let originalValue = this.getEnterpriseComponent().getBuffer()[this.getEnterpriseComponent().getRecordPointer()][this.getName() + "_original"];
        return originalValue !== undefined && this.getName() !== "USID";
    };

    setPicklist = (picklist) => {
        if (this.picklist !== null) {
            this.fPicklist = eoecClassFactory("pl", picklist);
        }
    };

    setRequired = (required) => {
        this.fRequired = required;
    };
}

export default EnterpriseComponentAttribute;


