import {Grid, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import spinner from "../assets/Spinner-1s-200px.svg";
import styles from "../theme/styles";

class PatientNotificationA extends Component {
    state = {
        fetchBackgroundOperation: false,
        buffer: {},
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;
    }

    componentDidMount() {
        this.applet.getEnterpriseComponent().addValueChangedListener(this);
        this.applet.getEnterpriseComponent().addLoadStartedListener(this);
        this.applet.getEnterpriseComponent().addLoadCompletedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);

        this.enterpriseComponentLoadCompleted(this.applet.getEnterpriseComponent().getBuffer());
    }

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    }

    enterpriseComponentValueChanged = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    enterpriseComponentLoadStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    getName = () => {
        return this.applet.getName();
    };

    render() {
        const actStudy = this.props.appContext.sessionContext.actStudy;

        let turbidityNotificationLevel = this.state.buffer["TurbidityNotificationLevelA"];
        if (this.state.buffer["EnableTurbidityNotifications"] === false) turbidityNotificationLevel = "--";

        let notifyAfterInstances = this.state.buffer["NotifyAfterInstancesA"];
        if (this.state.buffer["EnableTurbidityNotifications"] === false) notifyAfterInstances = "--";

        return this.state.fetchBackgroundOperation === false && this.applet.getEnterpriseComponent().isValid() === true ? (
            <>
                {!actStudy && (
                    <>
                        <Grid key="1" item xs={9} sm={9} md={9} lg={9} xl={9}>
                            <Typography className={this.props.classes.patientDetailLabel}>Turbidity notification level:</Typography>
                        </Grid>
                        <Grid key="2" item xs={3} sm={3} md={3} lg={3} xl={3} align="center">
                            <Typography className={this.props.classes.patientDetailData}>{turbidityNotificationLevel ?? "--"}</Typography>
                        </Grid>
                        <Grid key="3" item xs={12} sm={12} md={12} lg={12} xl={12} style={{height: "12px"}} />
                        <Grid key="4" item xs={9} sm={9} md={9} lg={9} xl={9}>
                            <Typography className={this.props.classes.patientDetailLabel}>Notify after X instances:</Typography>
                        </Grid>
                        <Grid key="5" item xs={3} sm={3} md={3} lg={3} xl={3} align="center">
                            <Typography className={this.props.classes.patientDetailData}>{notifyAfterInstances ?? "--"}</Typography>
                        </Grid>
                        <Grid key="6" item xs={12} sm={12} md={12} lg={12} xl={12} style={{height: "12px"}} />
                    </>
                )}
            </>
        ) : (
            <img src={spinner} alt="spinner" width="35" height="35" />
        );
    }
}

export default withAppContext(withStyles(styles, {withTheme: true})(PatientNotificationA));