import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";

class ResendInvitation extends Component {
    state = {
        updateBackgroundOperation: false,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        confirmResendInitiation: false,
        resendInitiated: false,
        resendCompleted: false,
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;
    };

    componentDidMount() {
        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
    }

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = () => {
        this.setState({updateBackgroundOperation: false, resendCompleted: true});
    };

    cancel1Handler = (event, _) => {
        this.applet.cancel();
    };

    resendHandler = (event) => {
        event.preventDefault();

        this.setState({confirmResendInitiation: true});
    };

    confirmHandler = (event) => {
        event.preventDefault();

        let moment = require("moment");

        this.applet.setMode("Edit");

        this.applet.getEnterpriseComponent().setAttributeValue("InvitationDatetime", moment().utc().format("YYYY-MM-DD HH:mm:ss"));
        this.applet.getEnterpriseComponent().executeUpdate();
    };

    getName = () => {
        return "ResendInvitation";
    };

    render() {
        let status = null;

        let invitationDatetime = this.applet.getEnterpriseComponent().getAttributeValue("InvitationDatetime");

        let assistiveText = (
            <FormControl component="fieldset">
                <FormLabel component="legend">Invitation previously sent on <b>{invitationDatetime}</b></FormLabel>
            </FormControl>
        );
        if (this.state.confirmResendInitiation) {
            assistiveText = (
                <FormControl component="fieldset">
                    <FormLabel component="legend">A new invitation will be sent to <b>{this.applet.getEnterpriseComponent().getAttributeValue("FirstName")} {this.applet.getEnterpriseComponent().getAttributeValue("LastName")}</b>. Confirm?</FormLabel>
                </FormControl>
            );
        }
        if (this.state.resendCompleted) {
            assistiveText = (
                <FormControl component="fieldset">
                    <FormLabel component="legend">Invitation sent.</FormLabel>
                </FormControl>
            );
        }
        if (this.state.updateBackgroundOperation) {
            assistiveText = (
                <FormControl component="fieldset">
                    <FormLabel component="legend">Sending invitation.</FormLabel>
                </FormControl>
            );
        }

        status = (
            <Grid key="id6" item container style={{width: "36em"}}>
                {assistiveText}
            </Grid>
        );

        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form onSubmit={e => e.preventDefault()}>
                    <Grid key="1" item container style={{width: "36em"}}>
                        {status}
                    </Grid>
                </form>
            </Grid>
        );

        let closeButton = (
            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                Close
            </CCButton>
        );

        let mainButton = (
            <CCButton variant="primary" onClick={this.resendHandler}>
                Resend
            </CCButton>
        );

        if (this.state.confirmResendInitiation) {
            mainButton = (
                <CCButton variant="primary" onClick={this.confirmHandler} animate={this.state.updateBackgroundOperation}>
                    Confirm
                </CCButton>
            );
        }

        if (this.state.updateBackgroundOperation) {
            closeButton = null;
        }

        if (this.state.resendCompleted) {
            mainButton = (
                <CCButton animate={false} variant="primary" onClick={this.cancel1Handler}>
                    Done
                </CCButton>
            );
            closeButton = null;
        }

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Resend Invitation</DialogTitle>
                    <DialogContent style={{padding: "8px 16px"}}>
                        {modalContent}
                    </DialogContent>
                    <DialogActions>
                        {closeButton}
                        {mainButton}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(ResendInvitation),
);