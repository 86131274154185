import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import React, {useEffect, useRef, useState} from "react";
import {useAppContext} from "../../App";
import * as Analytics from "../../util/Analytics";
import {makeCloudcathAxios} from "../../util/CloudcathAxios";
import {Condition} from "../../util/Condition";
import {sleep} from "../../util/Sleep";
import CCButton from "../common/CCButton";
import css_self from "./css/VerifyCommsDialog.module.scss";
import VerifyCommsBox from "./VerifyCommsBox";

export default function VerifyCommsDialog({
    enterpriseComponent: ec,
    view,
    ...props
}) {

    const [errorText, setErrorText] = useState(undefined);
    const [refreshing, setRefreshing] = useState(false);
    const [sending, setSending] = useState(false);

    const [userRecord, setUserRecord] = useState(ec.getBuffer()[ec.getRecordPointer()]);

    const {sessionContext} = useAppContext();
    const loggedInUser = sessionContext.userUSID === userRecord.USID;

    const isMounted = useRef(true);

    const CloudcathAxios = makeCloudcathAxios(sessionContext);

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);

    useEffect(() => {
        Analytics.record("User Verify Comms Viewed", {
            "Logged In User": loggedInUser,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        userRefresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function userRefresh() {

        const startAt = Date.now();

        setRefreshing(true);

        await performRefresh();

        const waitDiff = 2000 - (Date.now() - startAt);
        waitDiff > 0 && (await sleep(waitDiff));

        if (isMounted.current) {
            setRefreshing(false);
        }
    }

    async function performRefresh() {

        const condition = Condition();

        const listener = {
            getName: () => "VerifyCommsDialog",
            enterpriseComponentSingleRecordRefreshCompleted: buffer => {
                try {
                    condition.notify(buffer[ec.getRecordPointer()]);
                } catch (e) {
                    condition.cancel(e);
                }
            },
        };

        try {
            ec.addSingleRecordRefreshCompletedListener(listener);
            ec.performSingleRecordRefresh(userRecord.USID);
            const record = await condition.lock;
            if (isMounted.current) setUserRecord(record);
            return record;
        } finally {
            ec.removeSingleRecordRefreshCompletedListener(listener);
        }
    }

    async function resend(type, main) {

        const startAt = Date.now();

        setSending(true);

        Analytics.record("User Verify Comms Sending", {
            "Logged In User": loggedInUser,
            Type: type,
            Main: main,
        });

        const id = `${main ? "Main" : "Additional"}${type}`;

        const user = {};
        user[`${id}VerificationRequest`] = true;
        user[`${id}VerifiedState`] = "VERIFYING";

        const response = await CloudcathAxios({
            method: "PUT",
            url: "cloudcath/v1/users",
            json: true,
            data: {
                CloudcathMessage: {
                    User: {
                        USID: userRecord.USID,
                        ...user,
                    },
                },
            },
        }).catch(e => e.response || e);

        if (response instanceof Error && response.message === "Network Error") {
            setSending(false);
            setErrorText("Connection not available");
            return;
        }

        if (response.status === 204) {

            Analytics.record("User Verify Comms Sent", {
                "Logged In User": loggedInUser,
                Type: type,
                Main: main,
            });

            await performRefresh();
        }

        const waitDiff = 2000 - (Date.now() - startAt);
        waitDiff > 0 && (await sleep(waitDiff));

        setSending(false);

        if (response.status !== 204) {

            setErrorText("Service temporarily unavailable");

            Analytics.record("User Verify Comms Failed", {
                "Logged In User": loggedInUser,
                Type: type,
                Main: main,
                "HTTP Code": response.status,
            });
        }
    }

    function verifyCommsBox(id) {

        const type = id.endsWith("Phone") ? "Phone" : "Email";
        const main = id.startsWith("Main");

        return (
            <VerifyCommsBox
                className={css_self.VerifyCommsBox}
                type={type}
                main={main}
                value={userRecord[id]}
                status={userRecord[`${id}VerifiedState`]}
                timestamp={userRecord[`${id}VerifiedDatetime`]}
                disabled={refreshing || sending}
                onVerificationSend={() => resend(type, main)}
            />
        );
    }

    return (
        <Dialog
            open={true}
            scroll="body"
            onClose={() => view.initiateAction(null)}
            {...props}
        >
            <DialogTitle>Verify communications</DialogTitle>
            <DialogContent style={{padding: "0 16px"}}>
                <>
                    <div className={css_self.UserNameRow}>
                        <Typography className={css_self.UserNameText}>
                            {userRecord.FirstName} {userRecord.LastName}
                        </Typography>
                        <div style={{flexGrow: 1}} />
                        <IconButton
                            className={[
                                css_self.RefreshIconButton,
                                ...(refreshing ? [css_self.RefreshIconSpin] : []),
                            ].join(" ")}
                            disabled={refreshing || sending}
                            onClick={async () => {
                                Analytics.record("User Verify Comms Reload", {
                                    "Logged In User": loggedInUser,
                                });
                                userRefresh();
                            }}
                        >
                            <CachedIcon />
                        </IconButton>
                    </div>
                    {verifyCommsBox("MainEmail")}
                    {verifyCommsBox("MainPhone")}
                    {verifyCommsBox("AdditionalEmail")}
                    {verifyCommsBox("AdditionalPhone")}
                    <Typography className={css_self.ErrorText} style={{visibility: errorText ? undefined : "hidden"}}>
                        {errorText}
                    </Typography>
                </>
            </DialogContent>
            <DialogActions>
                <CCButton variant="secondary" onClick={(e) => {
                    e.preventDefault();
                    Analytics.record("User Verify Comms Close", {
                        "Logged In User": loggedInUser,
                    });
                    view.initiateAction(null);
                }}>
                    Close
                </CCButton>
            </DialogActions>
        </Dialog>
    );
}
