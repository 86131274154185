import RelationshipMetadata from "../RelationshipMetadata";

class EscalationNoteAttachmentRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "EscalationNoteAttachment";
        this.parentEnterpriseComponent = "EscalationNote";
        this.childEnterpriseComponent = "NoteAttachment";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "ParentUSID";
        this.invalidateChildren = false;
    }
}

export default EscalationNoteAttachmentRelationshipMetadata;