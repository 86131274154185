import EnterpriseObjectMemberMetadata from "../../../metadata/eo/EnterpriseObjectMemberMetadata";
import EnterpriseObjectMetadata from "../../../metadata/eo/EnterpriseObjectMetadata";

class DeviceRIEnterpriseObjectMetadata extends EnterpriseObjectMetadata {
    constructor() {
        super();

        this.name = "DeviceRI";
        this.primaryEnterpriseComponent = "Device";

        let eom1 = new EnterpriseObjectMemberMetadata();
        eom1.name = "Device";
        eom1.enterpriseComponent = "DeviceRI";
        eom1.relationship = null;
        this.enterpriseComponentMembers[eom1.name] = eom1;
    }
}

export default DeviceRIEnterpriseObjectMetadata;