import {withAppContext} from "../../../App";
import PatientTransplant from "../../../components/PatientTransplant";

import Applet from "../Applet";

class PatientTransplantApplet extends Applet {
    state = {
        Name: "PatientTransplantApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <PatientTransplant applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientTransplantApplet);
