import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1ClinicIDOCToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class ClinicEnterpriseComponent extends EnterpriseComponent {
    performQuery = (start, pageSize, foreignKey) => {
        let params = {
            "Limit": pageSize,
            "Offset": start,
        };

        params["Sort"] = this.getFormattedSortSpecification();

        let keys = Object.keys(this.getSearchSpecification());
        for (let i = 0; i < keys.length; i++) {
            params[keys[i]] = this.getSearchSpecification()[keys[i]];
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/clinics",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.Clinic",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1ClinicIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.Clinic";
        payload.CloudcathMessage.Clinic = this.preparePUTPayload();

        return payload;
    };

    performUpdate = () => {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "cloudcath/v1/clinics",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.Clinic",
            },
        };
        this.notifyUpdateStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(response.data);
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };
}

export default ClinicEnterpriseComponent;