import {withAppContext} from "../../../App";

import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class UserDetailApplet extends Applet {
    state = {
        Name: "UserDetailApplet",
        Modes: {
            View: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Username",
                            },
                            {
                                id: "FirstName",
                            },
                            {
                                id: "LastName",
                            },
                            {
                                id: "UserStatus",
                            },
                            {
                                id: "UserRole",
                            },
                            {
                                id: "OptionalCharacteristics",
                            },
                            {
                                id: "MainEmail",
                            },
                            {
                                id: "AdditionalEmail",
                            },
                            {
                                id: "MainPhone",
                            },
                            {
                                id: "AdditionalPhone",
                            },
                            {
                                id: "Salutation",
                            },
                            {
                                id: "Suffix",
                            },
                        ],
                    },
                ],
            },
            New: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Username",
                            },
                            {
                                id: "FirstName",
                            },
                            {
                                id: "LastName",
                            },
                            {
                                id: "UserRole",
                            },
                            {
                                id: "OptionalCharacteristics",
                            },
                            {
                                id: "Password",
                            },
                            {
                                id: "ConfirmPassword",
                            },
                            {
                                id: "MainEmail",
                            },
                            {
                                id: "AdditionalEmail",
                            },
                            {
                                id: "MainPhone",
                            },
                            {
                                id: "AdditionalPhone",
                            },
                            {
                                id: "Salutation",
                            },
                            {
                                id: "Suffix",
                            },
                        ],
                    },
                ],
            },
            Edit: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Username",
                            },
                            {
                                id: "FirstName",
                            },
                            {
                                id: "LastName",
                            },
                            {
                                id: "UserRole",
                            },
                            {
                                id: "OptionalCharacteristics",
                            },
                            {
                                id: "MainEmail",
                            },
                            {
                                id: "AdditionalEmail",
                            },
                            {
                                id: "MainPhone",
                            },
                            {
                                id: "AdditionalPhone",
                            },
                            {
                                id: "Salutation",
                            },
                            {
                                id: "Suffix",
                            },
                            {
                                id: "UserStatus",
                            },
                        ],
                    },
                ],
            },
        },
        Layout: {
            totalWidth: "36em",
        },
        Controls: {
            Username: {
                id: "Username",
                enterpriseComponentAttribute: "Username",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                errors: [
                    {
                        validator: "Unique",
                        value: null,
                        errorText: "Field must be unique.",
                    },
                    {
                        validator: "Minimum",
                        value: 8,
                        errorText: "Field must have at least 8 characters.",
                    },
                    {
                        validator: "No Special Characters",
                        value: null,
                        errorText: "Field must not have special characters.",
                    },
                    {
                        validator: "No Spaces",
                        value: null,
                        errorText: "Field must have no spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Username",
                    margin: "dense",
                    disabled: true,
                },
                ip: {
                    readOnly: true,
                },
                type: "TextField",
                default: {
                    type: "Literal",
                    value: this.props.Username,
                },
                requiresConfirmation: false,
            },
            FirstName: {
                id: "FirstName",
                enterpriseComponentAttribute: "FirstName",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "First Name",
                    margin: "dense",
                },
                ip: {},
            },
            LastName: {
                id: "LastName",
                enterpriseComponentAttribute: "LastName",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Last Name",
                    margin: "dense",
                },
                ip: {},
            },
            UserRole: {
                id: "UserRole",
                enterpriseComponentAttribute: "UserRole",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "User Role",
                    margin: "dense",
                },
                ip: {},
            },
            OptionalCharacteristics: {
                id: "OptionalCharacteristics",
                enterpriseComponentAttribute: "OptionalCharacteristics",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "OptionalCharacteristics",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Optional Characteristics",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            Password: {
                id: "Password",
                enterpriseComponentAttribute: "Password",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Password",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Match Values",
                        value: null,
                        errorText: "Password must match Confirm Password.",
                        field: "ConfirmPassword",
                    },
                    {
                        validator: "Minimum",
                        value: 8,
                        errorText: "Password must have at least 8 characters.",
                    },
                    {
                        validator: "No Spaces",
                        value: null,
                        errorText: "Password must have no spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Password",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            ConfirmPassword: {
                id: "ConfirmPassword",
                enterpriseComponentAttribute: "ConfirmPassword",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Password",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Match Values",
                        value: null,
                        errorText: "Password must match Confirm Password.",
                        field: "Password",
                    },
                    {
                        validator: "Minimum",
                        value: 8,
                        errorText: "Password must have at least 8 characters.",
                    },
                    {
                        validator: "No Spaces",
                        value: null,
                        errorText: "Password must have no spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Confirm Password",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            MainEmail: {
                id: "MainEmail",
                enterpriseComponentAttribute: "MainEmail",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: true,
                errors: [
                    {
                        validator: "Cannot Match Values - Case Insensitive",
                        value: null,
                        errorText: "Main Email and Additional Email must have different values.",
                        field: "AdditionalEmail",
                    },
                    {
                        validator: "Unique",
                        value: null,
                        errorText: "Field must be unique.",
                    },
                    {
                        validator: "Required Pattern",
                        value: null,
                        errorText: "Invalid email address.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Main Email",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },

            },
            AdditionalEmail: {
                id: "AdditionalEmail",
                enterpriseComponentAttribute: "AdditionalEmail",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Cannot Match Values - Case Insensitive",
                        value: null,
                        errorText: "Main Email and Additional Email must have different values.",
                        field: "MainEmail",
                    },
                    {
                        validator: "Optional Pattern",
                        value: null,
                        errorText: "Invalid Additional Email address.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Additional Email",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },

            },
            MainPhone: {
                id: "MainPhone",
                enterpriseComponentAttribute: "MainPhone",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Phone",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Cannot Match Values",
                        value: null,
                        errorText: "Main Phone and Additional Phone must have different values.",
                        field: "AdditionalPhone",
                    },
                    {
                        validator: "Optional Pattern",
                        value: null,
                        errorText: "Invalid Main Phone number.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Main Phone",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            AdditionalPhone: {
                id: "AdditionalPhone",
                enterpriseComponentAttribute: "AdditionalPhone",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Phone",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Cannot Match Values",
                        value: null,
                        errorText: "Main Phone and Additional Phone must have different values.",
                        field: "MainPhone",
                    },
                    {
                        validator: "Optional Pattern",
                        value: null,
                        errorText: "Invalid Additional Phone number.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Additional Phone",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            Salutation: {
                id: "Salutation",
                enterpriseComponentAttribute: "Salutation",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Salutation",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            Suffix: {
                id: "Suffix",
                enterpriseComponentAttribute: "Suffix",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Suffix",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            UserStatus: {
                id: "UserStatus",
                enterpriseComponentAttribute: "UserStatus",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "User Status",
                    margin: "dense",
                    disabled: true,
                    readOnly: true,
                },
                ip: {},
            },
            Placeholder1: {
                id: "Placeholder1",
                placement: {
                    "View": {
                        xs: 6,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 6,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 6,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Placeholder",
            },
        },
    };

    render() {
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="User"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(UserDetailApplet);
