import {Link, Tooltip, Typography, useMediaQuery} from "@material-ui/core";
import axios from "axios";
import React, {useEffect, useState} from "react";
import Countdown, {zeroPad} from "react-countdown-now";
import {Link as RouterLink} from "react-router-dom";
import * as formatters from "../../util/Formatters";
import {sleep} from "../../util/Sleep";
import CCButton from "../common/CCButton";
import CCInputLabel from "../common/CCInputLabel";
import CCTextField from "../common/CCTextField";
import css_shared from "./css/LoginCommon.module.scss";
import css_self from "./css/LoginHelp2.module.scss";
import {useLoginHelpContext} from "./LoginHelp";

export default function LoginHelp2() {

    const [resentAt, setResentAt] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const [errorText, setErrorText] = useState(undefined);
    const [verificationCode, setVerificationCode] = useState("");

    const {helpState, updateHelpState} = useLoginHelpContext();

    const smallerWidth = useMediaQuery("(max-width:320px)");

    const email = helpState.email;

    if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            sessionStorage.setItem("LoginHelp2/input:verificationKey", helpState.key);
            return () => sessionStorage.removeItem("LoginHelp2/input:verificationKey");
        }, [helpState]);
    }

    async function submit() {

        const startAt = Date.now();

        setSubmitting(true);

        const response = await axios({
            method: "GET",
            baseURL: process.env.REACT_APP_API_URL,
            url: "cloudcath/v1/forgot_password_verify_code",
            params: {
                Code: verificationCode,
                Key: helpState.key,
            },
        }).catch(e => e.response || e);

        if (response instanceof Error && response.message === "Network Error") {
            setSubmitting(false);
            setErrorText("Connection not available");
            return;
        }

        const waitDiff = 2000 - (Date.now() - startAt);
        waitDiff > 0 && (await sleep(waitDiff));

        setSubmitting(false);

        if (response.status === 204) {
            updateHelpState({code: verificationCode});
            return;
        }

        if (response.data.Code === "CC-C91C-61B3") {
            setErrorText("Expired verification code");
            return;
        }

        if (response.data.Code === "CC-A46A-453D") {
            setErrorText("Incorrect verification code");
            return;
        }

        setErrorText("Service temporarily unavailable");
    }

    const resend = () =>
        axios({
            method: "POST",
            baseURL: process.env.REACT_APP_API_URL,
            url: "cloudcath/v1/forgot_password_send_code",
            json: true,
            data: {
                Email: email,
            },
        }).then(response => updateHelpState({key: response.data.Key}));

    const emailMaxLength = smallerWidth ? 12 : 25;
    const displayValue = formatters.EmailWithEllipsis(email, emailMaxLength);

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Typography data-test-id="LoginHelp2/text:instructions" className={css_self.InstructionText}>
                We have sent the verification code to{" "}
                {displayValue !== email ? (
                    <Tooltip arrow title={email}>
                        <Typography data-test-id="LoginHelp2/text:email" data-test-value={email} component="span" className={css_self.Bolder}>{displayValue}</Typography>
                    </Tooltip>
                ) : (
                    <Typography data-test-id="LoginHelp2/text:email" data-test-value={email} component="span" className={css_self.Bolder}>{displayValue}</Typography>
                )}
                . Please check your email and enter the code below.
            </Typography>
            <Typography className={css_self.RedoText}>
                <Link data-test-id="LoginHelp2/link:redo" variant="inherit" color="inherit" href="#" onClick={e => {
                    e.preventDefault();
                    updateHelpState({
                        key: undefined,
                    });
                }}>Edit the email address</Link>
            </Typography>
            {resentAt ? (
                <Typography data-test-id="LoginHelp2/link:resendDisabled" className={css_self.RedoDisabledText}>
                    Resend the code
                    <Countdown
                        date={resentAt + 60000}
                        onComplete={() => setResentAt(undefined)}
                        renderer={({hours, minutes, seconds, completed}) => {
                            return ` (${zeroPad(minutes * 60 + seconds)}s)`;
                        }}
                    />
                </Typography>
            ) : (
                <Typography className={css_self.RedoText}>
                    <Link data-test-id="LoginHelp2/link:resend" variant="inherit" color="inherit" href="#" onClick={async e => {
                        e.preventDefault();
                        resend();
                        setResentAt(Date.now());
                    }}>Resend the code</Link>
                </Typography>
            )}
            <CCInputLabel className={css_self.MuiInputLabel}>Verification code</CCInputLabel>
            <CCTextField
                placeholder="Enter your verification code"
                value={verificationCode}
                onChange={async e => {
                    const pattern = /^([a-zA-Z]){0,8}$/i;
                    if (e.target.value === "" || pattern.test(e.target.value)) {
                        setVerificationCode(e.target.value.toUpperCase());
                    }
                }}
                inputProps={{
                    "data-test-id": "LoginHelp2/input:verificationCode",
                    className: css_self["MuiInputBase-input"],
                    maxLength: 8,
                }}
                helperText={errorText ? <span data-test-id="LoginHelp2/text:error" className="Mui-error">{errorText}</span> : undefined}
            />
            <Typography className={css_self.VerificationNoticeText}>Your verification code may take a few moments to arrive. Do not share this code with anyone.</Typography>
            <CCButton
                data-test-id="LoginHelp2/button:continue"
                className={errorText ? css_self.ButtonWithErrorShown : css_self.Button}
                margin="none"
                animate={submitting}
                disabled={submitting || verificationCode.length !== 8}
                onClick={() => {
                    setErrorText(undefined);
                    submit();
                }}
            >
                Continue
            </CCButton>
            <Typography className={css_shared.LoginInsteadText}>
                Already have an account?{" "}
                <Link data-test-id="LoginHelp1/link:login" component={RouterLink} to="/" replace={true}>Login</Link>
            </Typography>
        </form>
    );
}
