import {Container, withStyles} from "@material-ui/core";
import React from "react";
import {withAppContext} from "../../../App";
import styles from "../../../theme/styles";
import ClinicDetailApplet from "../../applet/impl/ClinicDetailApplet";
import eoecClassFactory from "../../EOECClassFactory";

import View from "../View";

class ClinicView extends View {
    state = {
        showAction: null,
        perspectives: {
            default: {},
        },
        activePerspective: "default",
        viewActionInProgress: null,
        orientation: "other",
    };

    constructor(props) {
        super(props);
        this.state.enterpriseObject = eoecClassFactory("eo", "Clinic");
        this.defaultPageSize = 100;
        this.onInitiateAction = props.onInitiateAction;
        this.enterpriseComponent = this.state.enterpriseObject.getEnterpriseComponent("Clinic");
    };

    componentDidMount() {
        this.enterpriseComponent.addLoadCompletedListener(this);
        this.enterpriseComponent.setSearchSpecification({"USID": this.props.appContext.sessionContext.clinicUSID});
        this.enterpriseComponent.executeQuery(0, 1);
    }

    componentWillUnmount() {
        this.enterpriseComponent.removeLoadCompletedListener(this);
    }

    getName = () => {
        return "ClinicView";
    };

    initiateAction = (action) => {
        this.setState({showAction: action});
        if (this.onInitiateAction) {
            this.onInitiateAction(action);
        }
    };

    enterpriseComponentLoadCompleted = (buffer) => {};

    render() {
        return (
            <Container className={this.props.classes.containerMargin} maxWidth="lg">
                <React.Fragment>
                    <ClinicDetailApplet enterpriseComponent={this.enterpriseComponent} view={this} availableModes={["Edit"]} defaultMode="Edit" />
                </React.Fragment>
            </Container>
        );
    }

}

export default withAppContext(withStyles(styles, {withTheme: true})(ClinicView));