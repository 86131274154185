import moment from "moment";

const dateTimeFormat = "HH:mm MMM DD, YYYY";
//const dateTimeFormatForEdit = "YYYY-MM-DDTHH:mm";
const dateFormat = "MMM DD, YYYY";
//const dateFormatForEdit = "YYYY-MM-DD";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};

export const convertToUTC = (dateToConvert) => {
    if (dateToConvert === null || dateToConvert === "") return null;

    let moment = require("moment");

    return moment(dateToConvert).utc().format("YYYY-MM-DD HH:mm:ss");
};

export const convertToUTCDate = (dateToConvert) => {
    if (dateToConvert === null || dateToConvert === "") return null;

    let moment = require("moment");

    return moment(dateToConvert).utc().format("YYYY-MM-DD");
};

export const convertToUTCDatePlusCurrentTime = (dateToConvert) => {
    if (dateToConvert === null || dateToConvert === "") return null;

    let moment = require("moment");

    let currentDateTime = moment();

    dateToConvert.set({
        hour: currentDateTime.get("hour"),
        minute: currentDateTime.get("minute"),
        second: currentDateTime.get("second"),
    });

    return moment(dateToConvert).utc().format("YYYY-MM-DD HH:mm:ss");
};

export const getUTCDate = () => {
    let moment = require("moment");

    let currentDateTime = moment();

    return moment(currentDateTime).utc().format("YYYY-MM-DD");
};

export const getUTCDatePlusCurrentTime = () => {
    let moment = require("moment");

    let currentDateTime = moment();

    return moment(currentDateTime).utc().format("YYYY-MM-DD HH:mm:ss");
};

export const V1GenericExistenceToArray = (input) => {
    let output = {};
    let row = {};
    let results = [];

    row.Username = "User";
    if (input["Username"] !== undefined && input["Username"] !== 0) {
        results.push(row);
        output["Count"] = input["Username"];
    }
    if (input["MainEmail"] !== undefined && input["MainEmail"] !== 0) {
        results.push(row);
        output["Count"] = input["MainEmail"];
    }
    if (input["AdditionalEmail"] !== undefined && input["AdditionalEmail"] !== 0) {
        results.push(row);
        output["Count"] = input["AdditionalEmail"];
    }
    if (input["MainPhone"] !== undefined && input["MainPhone"] !== 0) {
        results.push(row);
        output["Count"] = input["MainPhone"];
    }
    if (input["AdditionalPhone"] !== undefined && input["AdditionalPhone"] !== 0) {
        results.push(row);
        output["Count"] = input["AdditionalPhone"];
    }
    if (input["PatientIdentifier"] !== undefined && input["PatientIdentifier"] !== 0) {
        results.push(row);
        output["Count"] = input["PatientIdentifier"];
    }
    output["Results"] = results;

    return output;
};

export const V1ProviderUserToArray = (input) => {
    let output = {};
    let row = {};

    row["LastName"] = input["LastName"];
    row["FirstName"] = input["FirstName"];
    row["MemberOfProvider"] = input["MemberOfProvider"];
    row["MemberOfClinic"] = input["MemberOfClinic"];
    row["InvitationInProgress"] = input["InvitationInProgress"];
    row["ProviderUserUSID"] = input["ProviderUserUSID"];
    row["MainEmailVerifiedState"] = input["MainEmailVerifiedState"];

    output["Results"] = row;

    return output;
};

export const V1PatientIDOCToArray = (input) => {
    let output = {};
    let results = [];

    let moment = require("moment");

    if (input.CloudcathMessage["ListOfPatient"] !== undefined) {
        if (input.CloudcathMessage["ListOfPatient"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.CloudcathMessage["ListOfPatient"].Count;
        }

        if ("Patient" in input.CloudcathMessage["ListOfPatient"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfPatient"]["Patient"].length; i++) {
                let patient = input.CloudcathMessage["ListOfPatient"]["Patient"][i];
                let row = {};

                row.RowNum = i;
                row.RowSelected = false;
                row.USID = patient.USID;
                row.PatientIdentifier = patient.PatientIdentifier;
                row.CloudCathID = patient.CloudCathID;
                row.FirstName = patient.FirstName;
                row.MiddleInitial = patient.MiddleInitial;
                row.LastName = patient.LastName;
                row.DateOfBirth = null;
                if (patient.DateOfBirth !== null) {
                    row.DateOfBirth = moment(patient.DateOfBirth).format(dateFormat);
                }
                row.PatientEscalationStatus = patient.PatientEscalationStatus;
                row.BillingAccountUSID = patient.BillingAccountUSID;
                row.BillingAccountCustomer = patient.BillingAccountCustomer;
                row.BillingAccountCustomerNo = patient.BillingAccountCustomerNo;
                row.BillingAccountSubCustomer = patient.BillingAccountSubCustomer;
                row.BillingAccountSubCustomerNo = patient.BillingAccountSubCustomerNo;
                row.ClinicIdentifier = patient.ClinicIdentifier;
                row.CreatedDatetime = patient.CreatedDatetime;
                row.LastModifiedDatetime = patient.LastModifiedDatetime;
                row.CyclerID = patient.CyclerID;
                row.CyclerName = patient.CyclerName;
                row.DeviceUSID = patient.DeviceUSID;
                row.ClinicUSID = patient.ClinicUSID;
                row.LastTurbidityValue = patient.LastTurbidityValue;
                if (row.LastTurbidityValue !== null) {
                    row.LastTurbidityValueNumeric = parseFloat(parseFloat(row.LastTurbidityValue).toFixed(1));
                    if (row.LastTurbidityValueNumeric <= 0.549999) {
                        row.LastTurbidityValue = "\u2264 0.5";
                    } else if (row.LastTurbidityValueNumeric >= 100.05000) {
                        row.LastTurbidityValue = "100+";
                    } else {
                        row.LastTurbidityValue = row.LastTurbidityValueNumeric.toFixed(1);
                    }
                }
                row.LastTurbidityValueDatetime = patient.LastTurbidityValueDatetime;
                if (patient.LastTurbidityValueDatetime !== null) {
                    let stillUtc = moment.utc(patient.LastTurbidityValueDatetime);
                    row.LastTurbidityValueDatetime = moment(stillUtc).local().format(dateTimeFormat);
                }

                row.DeviceSerialNumber = patient.DeviceSerialNumber;
                row.DeviceAssignmentStatus = patient.DeviceAssignmentStatus;
                row.DeviceAssignmentDatetime = patient.DeviceAssignmentDatetime;

                if (patient.DeviceSerialNumber !== null) {
                    let stillUtc = moment.utc(patient.DeviceAssignmentDatetime);
                    row.DeviceAssignmentDatetime = moment(stillUtc).local().format(dateTimeFormat);
                } else {
                    row.DeviceAssignmentDatetime = null;
                }

                row.Realm = patient.Realm;

                let lastConnectedDatetimeGap = null;
                row.DeviceLastConnectedDatetime = patient.DeviceLastConnectedDatetime;
                if (patient.DeviceLastConnectedDatetime !== null) {
                    let stillUtc = moment.utc(patient.DeviceLastConnectedDatetime);
                    row.DeviceLastConnectedDatetime = moment(stillUtc).local().format(dateTimeFormat);
                }

                row.lastConnectedDatetimeGap = lastConnectedDatetimeGap;
                row.EnableTurbidityNotifications = patient.EnableTurbidityNotifications;
                if (patient.TurbidityNotificationLevelA === null) {
                    row.TurbidityNotificationLevelA = "";
                } else {
                    row.TurbidityNotificationLevelA = parseFloat(patient.TurbidityNotificationLevelA).toFixed(1);
                }
                if (patient.TurbidityNotificationLevelB === null) {
                    row.TurbidityNotificationLevelB = "";
                } else {
                    row.TurbidityNotificationLevelB = parseFloat(patient.TurbidityNotificationLevelB).toFixed(1);
                }
                row.NotifyAfterInstancesA = patient.NotifyAfterInstancesA;
                row.NotifyAfterInstancesB = patient.NotifyAfterInstancesB;
                row.SendReminderAfterInstances = patient.SendReminderAfterInstances;
                row.PatientState = patient.PatientState;
                row.PrimaryDoctorUSID = patient.PrimaryDoctorUSID;
                row.PrimaryDoctorFirstName = patient.PrimaryDoctorFirstName;
                row.PrimaryDoctorLastName = patient.PrimaryDoctorLastName;
                if (!row.PrimaryDoctorUSID) {
                    row.PrimaryDoctorFullName = null;
                } else {
                    row.PrimaryDoctorFullName = patient.PrimaryDoctorFirstName + " " + patient.PrimaryDoctorLastName;
                }
                row.PrimaryNurseUSID = patient.PrimaryNurseUSID;
                row.PrimaryNurseFirstName = patient.PrimaryNurseFirstName;
                row.PrimaryNurseLastName = patient.PrimaryNurseLastName;
                if (!row.PrimaryNurseUSID) {
                    row.PrimaryNurseFullName = null;
                } else {
                    row.PrimaryNurseFullName = patient.PrimaryNurseFirstName + " " + patient.PrimaryNurseLastName;
                }

                row["__operation"] = "query results";

                let turbidityValues = null;
                if (patient["ListOfPatientData"] !== undefined) {
                    if (patient["ListOfPatientData"]["PatientData"]) {
                        for (let j = 0; j < patient["ListOfPatientData"]["PatientData"].length; j++) {
                            let data = patient["ListOfPatientData"]["PatientData"][j];

                            if (j !== 0) {
                                turbidityValues += ", " + data.Range;
                            } else {
                                turbidityValues = data.Range;
                            }

                            if (j === 4) break;
                        }
                    }
                }
                row.Turbidity = turbidityValues;

                let assignedDoctors = "";
                row.AssignedDoctorsCount = 0;
                row.OriginalAssignedDoctorsArray = [];
                row.AssignedDoctorsArray = [];
                if (patient["ListOfUser"] !== undefined) {
                    if (patient["ListOfUser"].User) {
                        let j;
                        for (j = 0; j < patient["ListOfUser"].User.length; j++) {
                            let user = patient["ListOfUser"].User[j];

                            if (user.UserRole === "Physician") {
                                if (user.USID !== patient.PrimaryDoctorUSID) {
                                    row.OriginalAssignedDoctorsArray.push(user);
                                    row.AssignedDoctorsArray.push(user);
                                    row.FullName = user.FullName;

                                    if (row.AssignedDoctorsCount === 0) {
                                        assignedDoctors += user.FullName;
                                    } else {
                                        assignedDoctors += "|" + user.FullName;
                                    }
                                    row.AssignedDoctorsCount += 1;
                                }
                            }
                        }
                    }
                }
                row.AssignedDoctors = assignedDoctors;

                let assignedNurses = "";
                row.AssignedNursesCount = 0;
                row.OriginalAssignedNursesArray = [];
                row.AssignedNursesArray = [];
                if (patient["ListOfUser"] !== undefined) {
                    if (patient["ListOfUser"].User) {
                        let j;
                        for (j = 0; j < patient["ListOfUser"].User.length; j++) {
                            let user = patient["ListOfUser"].User[j];

                            if (user.UserRole === "Nurse") {
                                if (user.USID !== patient.PrimaryNurseUSID) {
                                    row.OriginalAssignedNursesArray.push(user);
                                    row.AssignedNursesArray.push(user);
                                    row.FullName = user.FullName;

                                    if (row.AssignedNursesCount === 0) {
                                        assignedNurses += user.FullName;
                                    } else {
                                        assignedNurses += "|" + user.FullName;
                                    }
                                    row.AssignedNursesCount += 1;
                                }
                            }
                        }
                    }
                }
                row.AssignedNurses = assignedNurses;

                row.ListOfPatientAttachment = {
                    "Results": [],
                };

                if (patient["ListOfAttachment"] !== undefined) {
                    if (patient["ListOfAttachment"].Attachment) {
                        for (let j = 0; j < patient["ListOfAttachment"].Attachment.length; j++) {
                            let attachment = patient["ListOfAttachment"].Attachment[j];

                            let attachmentRecord = {};
                            attachmentRecord.USID = attachment.USID;
                            attachmentRecord.FileName = attachment.FileName;
                            attachmentRecord.FileType = attachment.FileType;
                            attachmentRecord.FileSize = attachment.FileSize;
                            attachmentRecord.ParentUSID = attachment.ParentUSID;
                            attachmentRecord.ParentType = attachment.ParentType;

                            row.ListOfPatientAttachment["Results"].push(attachmentRecord);
                        }
                    }
                }

                row["__operation"] = "query results";

                results.push(row);
            }
        }
    }

    output["Results"] = results;

    return output;
};

export const V1PatientEscalationIDOCToArray = (input) => {
    let output = {};
    let results = [];
    let moment = require("moment");

    if (input.CloudcathMessage["ListOfPatientEscalation"] !== undefined) {
        if (input.CloudcathMessage["ListOfPatientEscalation"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.CloudcathMessage["ListOfPatientEscalation"].Count;
        }

        if ("PatientEscalation" in input.CloudcathMessage["ListOfPatientEscalation"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfPatientEscalation"]["PatientEscalation"].length; i++) {
                let patientEscalation = input.CloudcathMessage["ListOfPatientEscalation"]["PatientEscalation"][i];
                let row = {};

                row.RowNum = i;
                row.RowSelected = false;
                row.USID = patientEscalation.USID;
                row.PatientUSID = patientEscalation.PatientUSID;
                row.EscalationStatus = patientEscalation.EscalationStatus;
                row.PreviousEscalationStatus = patientEscalation.PreviousEscalationStatus;
                row.EscalationOrigin = patientEscalation.EscalationOrigin;
                row.TurbidityNotificationLevel = patientEscalation.TurbidityNotificationLevel;
                row.HighestTurbidityScore = patientEscalation.HighestTurbidityScore;
                if (row.HighestTurbidityScore !== null) {
                    row.HighestTurbidityScoreNumeric = parseFloat(parseFloat(row.HighestTurbidityScore).toFixed(1));
                    if (row.HighestTurbidityScoreNumeric <= 0.549999) {
                        row.HighestTurbidityScore = "\u2264 0.5";
                    } else if (row.HighestTurbidityScoreNumeric >= 100.05000) {
                        row.HighestTurbidityScore = "100+";
                    } else {
                        row.HighestTurbidityScore = row.HighestTurbidityScoreNumeric.toFixed(1);
                    }
                }
                row.HighestTurbidityScoreDatetime = null;
                if (patientEscalation.HighestTurbidityScoreDatetime !== null) {
                    let stillUtc = moment.utc(patientEscalation.HighestTurbidityScoreDatetime);
                    row.HighestTurbidityScoreDatetime = moment(stillUtc).local().format(dateTimeFormat);
                }
                row.RowSelected = false;

                row.DiagnosedByUSID = patientEscalation.DiagnosedByUSID;
                row.AcknowledgedByUSID = patientEscalation.AcknowledgedByUSID;
                row.AcknowledgedDatetime = patientEscalation.AcknowledgedDatetime;
                if (patientEscalation.AcknowledgedDatetime !== null) {
                    let stillUtc = moment.utc(patientEscalation.AcknowledgedDatetime);
                    let acknowledgedDatetime = moment(stillUtc).local();
                    row.AcknowledgedDatetime = acknowledgedDatetime.format(dateTimeFormat);
                }

                row.InfectionDiagnosisDate = null;
                if (patientEscalation.InfectionDiagnosisDate !== null) {
                    row.InfectionDiagnosisDate = moment(patientEscalation.InfectionDiagnosisDate).format(dateFormat);
                }

                row.NonInfectionDiagnosisDate = null;
                if (patientEscalation.NonInfectionDiagnosisDate !== null) {
                    row.NonInfectionDiagnosisDate = moment(patientEscalation.NonInfectionDiagnosisDate).format(dateFormat);
                }

                row.InfectionResolutionDate = null;
                if (patientEscalation.InfectionResolutionDate !== null) {
                    row.InfectionResolutionDate = moment(patientEscalation.InfectionResolutionDate).format(dateFormat);
                }

                row.EscalationNote = patientEscalation.EscalationNote;
                row.EscalationNoteType = patientEscalation.EscalationNoteType;
                row.PatientHospitalized = patientEscalation.PatientHospitalized;
                row.SpokenWithPatient = patientEscalation.SpokenWithPatient;
                row.ScheduledClinicVisit = patientEscalation.ScheduledClinicVisit;
                row.CatheterRemoved = patientEscalation.CatheterRemoved;
                row.CatheterReplaced = patientEscalation.CatheterReplaced;

                row.CDSE4731 = patientEscalation.CDSE4731;
                row.CDSE4732 = patientEscalation.CDSE4732;
                row.CDSE4733 = patientEscalation.CDSE4733;
                row.CDSE4734 = patientEscalation.CDSE4734;
                row.CDSE4735 = patientEscalation.CDSE4735;
                row.CDSE4736 = patientEscalation.CDSE4736;
                row.CDSE4737 = patientEscalation.CDSE4737;
                row.CDSE4738 = patientEscalation.CDSE4738;
                row.CDSE4739a = patientEscalation.CDSE4739a;
                row.CDSE4739b = patientEscalation.CDSE4739b;
                row.CDSE47310 = patientEscalation.CDSE47310;
                row.CDSE47311 = patientEscalation.CDSE47311;

                row.PatientHospitalizedReadOnly = null;
                row.SpokenWithPatientReadOnly = null;
                row.ScheduledClinicVisitReadOnly = null;

                if (patientEscalation.ScheduledClinicVisit !== null) {
                    if (patientEscalation.ScheduledClinicVisit === true) {
                        row.ScheduledClinicVisitReadOnly = "Yes";
                    } else {
                        row.ScheduledClinicVisitReadOnly = "No";
                    }
                }
                if (patientEscalation.SpokenWithPatient !== null) {
                    if (patientEscalation.SpokenWithPatient === true) {
                        row.SpokenWithPatientReadOnly = "Yes";
                    } else {
                        row.SpokenWithPatientReadOnly = "No";
                    }
                }
                if (patientEscalation.PatientHospitalized !== null) {
                    if (patientEscalation.PatientHospitalized === true) {
                        row.PatientHospitalizedReadOnly = "Yes";
                    } else {
                        row.PatientHospitalizedReadOnly = "No";
                    }
                }

                row.CatheterRemovedReadOnly = null;
                if (patientEscalation.CatheterRemoved !== null) {
                    if (patientEscalation.CatheterRemoved === true) {
                        row.CatheterRemovedReadOnly = "Yes";
                    } else {
                        row.CatheterRemovedReadOnly = "No";
                    }
                }

                row.CatheterReplacedReadOnly = null;
                if (patientEscalation.CatheterReplaced !== null) {
                    if (patientEscalation.CatheterReplaced === true) {
                        row.CatheterReplacedReadOnly = "Yes";
                    } else {
                        row.CatheterReplacedReadOnly = "No";
                    }
                }

                row.DeactivationDate = null;
                if (patientEscalation.DeactivationDate !== null) {
                    row.DeactivationDate = moment(patientEscalation.DeactivationDate).format(dateFormat);
                }
                row.ExpirationDate = null;
                if (patientEscalation.ExpirationDate !== null) {
                    row.ExpirationDate = moment(patientEscalation.ExpirationDate).format(dateFormat);
                }
                row.HemodialysisDate = null;
                if (patientEscalation.HemodialysisDate !== null) {
                    row.HemodialysisDate = moment(patientEscalation.HemodialysisDate).format(dateFormat);
                }
                row.HospitalizationDate = null;
                if (patientEscalation.HospitalizationDate !== null) {
                    row.HospitalizationDate = moment(patientEscalation.HospitalizationDate).format(dateFormat);
                }
                row.TransplantDate = null;
                if (patientEscalation.TransplantDate !== null) {
                    row.TransplantDate = moment(patientEscalation.TransplantDate).format(dateFormat);
                }
                row.DischargeDate = null;
                if (patientEscalation.DischargeDate !== null) {
                    row.DischargeDate = moment(patientEscalation.DischargeDate).format(dateFormat);
                }

                row.BacterialInfectionConfirmed = patientEscalation.BacterialInfectionConfirmed;
                row.FungalInfectionConfirmed = patientEscalation.FungalInfectionConfirmed;
                row.TypeOfInfectionExitSite = patientEscalation.TypeOfInfectionExitSite;
                row.TypeOfInfectionTunnel = patientEscalation.TypeOfInfectionTunnel;
                row.TypeOfInfectionPeritonitis = patientEscalation.TypeOfInfectionPeritonitis;

                row.BacterialInfectionConfirmedReadOnly = null;
                row.FungalInfectionConfirmedReadOnly = null;

                row.TypeOfInfectionExitSiteReadOnly = null;
                row.TypeOfInfectionTunnelReadOnly = null;
                row.TypeOfInfectionPeritonitisReadOnly = null;

                if (patientEscalation.BacterialInfectionConfirmed !== null) {
                    if (patientEscalation.BacterialInfectionConfirmed === true) {
                        row.BacterialInfectionConfirmedReadOnly = "Bacterial";
                    } else {
                        row.BacterialInfectionConfirmedReadOnly = null;
                    }
                }
                if (patientEscalation.FungalInfectionConfirmed !== null) {
                    if (patientEscalation.FungalInfectionConfirmed === true) {
                        row.FungalInfectionConfirmedReadOnly = "Fungal";
                    } else {
                        row.FungalInfectionConfirmedReadOnly = null;
                    }
                }

                if (patientEscalation.TypeOfInfectionExitSite !== null) {
                    if (patientEscalation.TypeOfInfectionExitSite === true) {
                        row.TypeOfInfectionExitSiteReadOnly = "Exit site";
                    } else {
                        row.TypeOfInfectionExitSiteReadOnly = null;
                    }
                }
                if (patientEscalation.TypeOfInfectionTunnel !== null) {
                    if (patientEscalation.TypeOfInfectionTunnel === true) {
                        row.TypeOfInfectionTunnelReadOnly = "Tunnel";
                    } else {
                        row.TypeOfInfectionTunnelReadOnly = null;
                    }
                }
                if (patientEscalation.TypeOfInfectionPeritonitis !== null) {
                    if (patientEscalation.TypeOfInfectionPeritonitis === true) {
                        row.TypeOfInfectionPeritonitisReadOnly = "Peritonitis";
                    } else {
                        row.TypeOfInfectionPeritonitisReadOnly = null;
                    }
                }

                row.HospitalizationOutcome = patientEscalation.HospitalizationOutcome;
                row.HospitalizationDiagnosis = patientEscalation.HospitalizationDiagnosis;
                row.CultureStatus = patientEscalation.CultureStatus;
                row.CellCountStatus = patientEscalation.CellCountStatus;
                row.DeviceUSID = patientEscalation.DeviceUSID;
                row.DeviceSerialNumber = patientEscalation.DeviceSerialNumber;
                row.UnassignDevice = null;

                row.StartDatetime = null;
                row.Duration = null;
                if (patientEscalation.StartDatetime !== null) {
                    let stillUtc = moment.utc(patientEscalation.StartDatetime);
                    row.StartDatetime = moment(stillUtc).local().format(dateTimeFormat);
                }

                row.EndDatetime = null;
                row.Locked = false;
                if (patientEscalation.EndDatetime !== null) {
                    let stillUtc = moment.utc(patientEscalation.EndDatetime);
                    let endDateTime = moment(stillUtc).local();
                    row.EndDatetime = endDateTime.format(dateFormat);
                    if (moment().diff(endDateTime, "days") >= 15) {
                        row.Locked = true;
                    }
                }

                let startDateTime = moment.utc(patientEscalation.StartDatetime).local();
                let endDateTime = patientEscalation.InfectionResolutionDate ? moment.utc(patientEscalation.InfectionResolutionDate).local() : null;
                endDateTime = endDateTime === null && patientEscalation.NonInfectionDiagnosisDate ? moment.utc(patientEscalation.NonInfectionDiagnosisDate).local() : endDateTime;
                endDateTime = endDateTime === null && patientEscalation.ExpirationDate ? moment.utc(patientEscalation.ExpirationDate).local() : endDateTime;
                endDateTime = endDateTime === null && patientEscalation.HemodialysisDate ? moment.utc(patientEscalation.HemodialysisDate).local() : endDateTime;
                endDateTime = endDateTime === null && patientEscalation.TransplantDate ? moment.utc(patientEscalation.TransplantDate).local() : endDateTime;
                endDateTime = endDateTime === null && patientEscalation.DeactivationDate ? moment.utc(patientEscalation.DeactivationDate).local() : endDateTime;

                if (endDateTime !== null) {
                    let duration = endDateTime.diff(startDateTime, "days");
                    if (duration < 0) duration = 0;
                    row.Duration = `${duration} day${duration !== 1 ? "s" : ""}`;
                } else {
                    let duration = moment().diff(startDateTime, "days");
                    if (duration < 0) duration = 0;
                    row.Duration = `${duration} day${duration !== 1 ? "s" : ""}`;
                }

                row["__operation"] = "query results";

                results.push(row);
            }
        }
    }

    output["Results"] = results;

    return output;
};

export const V1SecondaryNurseIDOCToArray = (input) => {
    let output = {};
    let results = [];

    if (input.CloudcathMessage["ListOfPatient"] !== undefined) {
        if ("Patient" in input.CloudcathMessage["ListOfPatient"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfPatient"]["Patient"].length; i++) {
                let patient = input.CloudcathMessage["ListOfPatient"]["Patient"][i];

                if (patient.ListOfUser !== undefined) {
                    if (patient.ListOfUser.User) {
                        let j;
                        for (j = 0; j < patient.ListOfUser.User.length; j++) {
                            let row = {};

                            let user = patient.ListOfUser.User[j];

                            if (user.UserRole === "Nurse") {
                                if (user.USID !== patient.PrimaryNurseUSID) {
                                    row.USID = user.USID;
                                    row.FullName = user.FullName;
                                    row["__operation"] = "query results";
                                    results.push(row);
                                }
                            }
                        }
                    }
                }
            }
            output["Count"] = results.length;
        }
    }

    output["Results"] = results;

    return output;
};

export const V1SecondaryPhysicianIDOCToArray = (input) => {
    let output = {};
    let results = [];

    if (input.CloudcathMessage["ListOfPatient"] !== undefined) {
        if ("Patient" in input.CloudcathMessage["ListOfPatient"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfPatient"]["Patient"].length; i++) {
                let patient = input.CloudcathMessage["ListOfPatient"]["Patient"][i];

                if (patient.ListOfUser !== undefined) {
                    if (patient.ListOfUser.User) {
                        let j;
                        for (j = 0; j < patient.ListOfUser.User.length; j++) {
                            let row = {};

                            let user = patient.ListOfUser.User[j];

                            if (user.UserRole === "Physician") {
                                if (user.USID !== patient.PrimaryDoctorUSID) {
                                    row.USID = user.USID;
                                    row.FullName = user.FullName;
                                    row["__operation"] = "query results";
                                    results.push(row);
                                }
                            }
                        }
                    }
                }
            }
            output["Count"] = results.length;
        }
    }

    output["Results"] = results;

    return output;
};

export const V1PatientEscalationCommunicationIDOCToArray = (input) => {
    let output = {};
    let results = [];
    let moment = require("moment");

    if (input.CloudcathMessage["ListOfPatientEscalationCommunication"] !== undefined) {
        if (input.CloudcathMessage["ListOfPatientEscalationCommunication"].Count !== undefined) {
            output["Count"] = input.CloudcathMessage["ListOfPatientEscalationCommunication"].Count;

            if ("PatientEscalationCommunication" in input.CloudcathMessage["ListOfPatientEscalationCommunication"]) {
                for (let i = 0; i < input.CloudcathMessage["ListOfPatientEscalationCommunication"]["PatientEscalationCommunication"].length; i++) {
                    let patientEscalationCommunication = input.CloudcathMessage["ListOfPatientEscalationCommunication"]["PatientEscalationCommunication"][i];
                    let row = {};

                    row.RowNum = i;
                    row.RowSelected = false;
                    row.USID = patientEscalationCommunication.USID;
                    row.PatientEscalationUSID = patientEscalationCommunication.PatientEscalationUSID;

                    row.CommunicationPurpose = patientEscalationCommunication.CommunicationPurpose;
                    row.CommunicationType = patientEscalationCommunication.CommunicationType;
                    row.Destination = patientEscalationCommunication.Destination;
                    row.UserUSID = patientEscalationCommunication.UserUSID;
                    row.RecipientFirstName = patientEscalationCommunication.UserFirstName;
                    row.RecipientLastName = patientEscalationCommunication.UserLastName;
                    row.Acknowledged = patientEscalationCommunication.Acknowledged;
                    row.MessageID = patientEscalationCommunication.MessageID;
                    row.MessageURL = patientEscalationCommunication.MessageURL;

                    row.CommunicationDatetime = null;
                    if (patientEscalationCommunication.CommunicationDatetime != null) {
                        let stillUtc = moment.utc(patientEscalationCommunication.CommunicationDatetime);
                        row.CommunicationDatetime = moment(stillUtc).local().format(dateTimeFormat);
                    }

                    row.AcknowledgedDatetime = null;
                    if (patientEscalationCommunication.AcknowledgedDatetime != null) {
                        let stillUtc = moment.utc(patientEscalationCommunication.AcknowledgedDatetime);
                        row.AcknowledgedDatetime = moment(stillUtc).local().format(dateTimeFormat);
                    }

                    row.RecipientFullName = "";
                    if (patientEscalationCommunication.UserFirstName != null) {
                        row.RecipientFullName = patientEscalationCommunication["UserFirstName"] + " " + patientEscalationCommunication["UserLastName"];
                    }

                    row["__operation"] = "query results";

                    results.push(row);
                }
            }
        } else {
            output["Count"] = 0;
        }
    }

    output["Results"] = results;

    return output;
};

export const V1PatientEscalationEventIDOCToArray = (input) => {
    let output = {};
    let results = [];
    let moment = require("moment");

    if (input.CloudcathMessage["ListOfPatientEscalationEvent"] !== undefined) {
        if (input.CloudcathMessage["ListOfPatientEscalationEvent"].Count !== undefined) {
            output["Count"] = input.CloudcathMessage["ListOfPatientEscalationEvent"].Count;

            if ("PatientEscalationEvent" in input.CloudcathMessage["ListOfPatientEscalationEvent"]) {
                for (let i = 0; i < input.CloudcathMessage["ListOfPatientEscalationEvent"]["PatientEscalationEvent"].length; i++) {
                    let patientEscalationEvent = input.CloudcathMessage["ListOfPatientEscalationEvent"]["PatientEscalationEvent"][i];
                    let row = {};

                    row.RowNum = i;
                    row.RowSelected = false;
                    row.USID = patientEscalationEvent.USID;
                    row.PatientEscalationUSID = patientEscalationEvent.PatientEscalationUSID;
                    row.Event = patientEscalationEvent.Event;
                    row.EventDescription = patientEscalationEvent.EventDescription;
                    row.PerformedByUserUSID = patientEscalationEvent.PerformedByUserUSID;
                    row.PerformedByFirstName = patientEscalationEvent.PerformedByFirstName;
                    row.PerformedByLastName = patientEscalationEvent.PerformedByLastName;

                    row.CreatedDatetime = null;
                    if (patientEscalationEvent.CreatedDatetime != null) {
                        let stillUtc = moment.utc(patientEscalationEvent.CreatedDatetime);
                        row.CreatedDatetime = moment(stillUtc).local().format(dateFormat);
                    }

                    row.PerformedByFullName = "";
                    if (patientEscalationEvent.PerformedByFirstName != null) {
                        row.PerformedByFullName = patientEscalationEvent["PerformedByFirstName"] + " " + patientEscalationEvent["PerformedByLastName"];
                    }

                    row["__operation"] = "query results";

                    results.push(row);
                }
            }
        } else {
            output["Count"] = 0;
        }
    }

    output["Results"] = results;

    return output;
};

export const V1PatientEscalationTreatmentIDOCToArray = (input) => {
    let output = {};
    let results = [];
    let moment = require("moment");

    if (input.CloudcathMessage["ListOfPatientEscalationTreatment"] !== undefined) {
        if (input.CloudcathMessage["ListOfPatientEscalationTreatment"].Count !== undefined) {
            output["Count"] = input.CloudcathMessage["ListOfPatientEscalationTreatment"].Count;

            if ("PatientEscalationTreatment" in input.CloudcathMessage["ListOfPatientEscalationTreatment"]) {
                for (let i = 0; i < input.CloudcathMessage["ListOfPatientEscalationTreatment"]["PatientEscalationTreatment"].length; i++) {
                    let patientEscalationTreatment = input.CloudcathMessage["ListOfPatientEscalationTreatment"]["PatientEscalationTreatment"][i];
                    let row = {};

                    row.RowNum = i;
                    row.RowSelected = false;
                    row.USID = patientEscalationTreatment.USID;
                    row.PatientEscalationUSID = patientEscalationTreatment.PatientEscalationUSID;
                    row.StartDate = patientEscalationTreatment.StartDate;
                    row.EndDate = patientEscalationTreatment.EndDate;

                    row.StartDateDisplay = null;
                    if (patientEscalationTreatment.StartDate !== null) {
                        row.StartDateDisplay = moment(patientEscalationTreatment.StartDate).format(dateFormat);
                    }

                    row.EndDateDisplay = null;
                    if (patientEscalationTreatment.EndDate !== null) {
                        row.EndDateDisplay = moment(patientEscalationTreatment.EndDate).format(dateFormat);
                    }

                    row.Medication = patientEscalationTreatment.Medication;
                    row.AssociatedNote = patientEscalationTreatment.AssociatedNote;
                    row.Dose = patientEscalationTreatment.Dose;
                    row.Unit = patientEscalationTreatment.Unit;
                    row.Frequency = patientEscalationTreatment.Frequency;
                    row.IsSelfAdministered = patientEscalationTreatment.IsSelfAdministered;
                    row.Formulation = patientEscalationTreatment.Formulation;
                    row.Route = patientEscalationTreatment.Route;

                    row.IsSelfAdministeredReadOnly = "";
                    row.IsSelfAdministered = patientEscalationTreatment.IsSelfAdministered;

                    if (patientEscalationTreatment.IsSelfAdministered !== null) {
                        if (patientEscalationTreatment.IsSelfAdministered === true) {
                            row.IsSelfAdministeredReadOnly = "Yes";
                        } else {
                            row.IsSelfAdministeredReadOnly = "No";
                        }
                    }

                    row.EnteredByUSID = patientEscalationTreatment.EnteredByUSID;
                    row.EnteredByFirstName = patientEscalationTreatment.EnteredByFirstName;
                    row.EnteredByLastName = patientEscalationTreatment.EnteredByLastName;
                    row.EnteredByFullName = "";
                    if (patientEscalationTreatment.EnteredByFirstName != null && patientEscalationTreatment.EnteredByLastName != null) {
                        row.EnteredByFullName = patientEscalationTreatment["EnteredByFirstName"] + " " + patientEscalationTreatment["EnteredByLastName"];
                    }

                    row.CreatedDatetime = null;
                    if (patientEscalationTreatment.CreatedDatetime != null) {
                        let stillUtc = moment.utc(patientEscalationTreatment.CreatedDatetime);
                        row.CreatedDatetime = moment(stillUtc).local().format(dateFormat);
                    }

                    row.ListOfTreatmentAttachment = {
                        "Results": [],
                    };

                    if (patientEscalationTreatment["ListOfAttachment"] !== undefined) {
                        if (patientEscalationTreatment["ListOfAttachment"].Attachment) {
                            for (let j = 0; j < patientEscalationTreatment["ListOfAttachment"].Attachment.length; j++) {
                                let attachment = patientEscalationTreatment["ListOfAttachment"].Attachment[j];

                                let attachmentRecord = {};
                                attachmentRecord.USID = attachment.USID;
                                attachmentRecord.FileName = attachment.FileName;
                                attachmentRecord.FileType = attachment.FileType;
                                attachmentRecord.FileSize = attachment.FileSize;
                                attachmentRecord.ParentUSID = attachment.ParentUSID;
                                attachmentRecord.ParentType = attachment.ParentType;

                                row.ListOfTreatmentAttachment["Results"].push(attachmentRecord);
                            }
                        }
                    }

                    row["__operation"] = "query results";

                    results.push(row);
                }
            }
        } else {
            output["Count"] = 0;
        }

        output["Results"] = results;

        return output;
    }
};

export const V1PatientEscalationSymptomIDOCToArray = (input) => {
    let output = {};
    let results = [];
    let moment = require("moment");

    if (input.CloudcathMessage["ListOfPatientEscalationSymptom"] !== undefined) {
        if (input.CloudcathMessage["ListOfPatientEscalationSymptom"].Count !== undefined) {
            output["Count"] = input.CloudcathMessage["ListOfPatientEscalationSymptom"].Count;

            if ("PatientEscalationSymptom" in input.CloudcathMessage["ListOfPatientEscalationSymptom"]) {
                for (let i = 0; i < input.CloudcathMessage["ListOfPatientEscalationSymptom"]["PatientEscalationSymptom"].length; i++) {
                    let patientEscalationSymptom = input.CloudcathMessage["ListOfPatientEscalationSymptom"]["PatientEscalationSymptom"][i];
                    let row = {};

                    row.RowNum = i;
                    row.RowSelected = false;
                    row.USID = patientEscalationSymptom.USID;
                    row.PatientEscalationUSID = patientEscalationSymptom.PatientEscalationUSID;

                    row.EnteredDate = patientEscalationSymptom.EnteredDate;
                    row.EnteredDateDisplay = null;
                    if (patientEscalationSymptom.EnteredDate !== null) {
                        row.EnteredDateDisplay = moment(patientEscalationSymptom.EnteredDate).format(dateFormat);
                    }

                    row.AssociatedNote = patientEscalationSymptom.AssociatedNote;
                    row.AbdominalPain = patientEscalationSymptom.AbdominalPain;
                    row.Fever = patientEscalationSymptom.Fever;
                    row.Nausea = patientEscalationSymptom.Nausea;
                    row.CloudyFluid = patientEscalationSymptom.CloudyFluid;
                    row.Vomiting = patientEscalationSymptom.Vomiting;
                    row.Other1 = patientEscalationSymptom.Other1;
                    row.Other2 = patientEscalationSymptom.Other2;
                    row.Other3 = patientEscalationSymptom.Other3;
                    row.Other4 = patientEscalationSymptom.Other4;
                    row.Other5 = patientEscalationSymptom.Other5;

                    row.EnteredByUSID = patientEscalationSymptom.EnteredByUSID;
                    row.EnteredByFirstName = patientEscalationSymptom.EnteredByFirstName;
                    row.EnteredByLastName = patientEscalationSymptom.EnteredByLastName;
                    row.EnteredByFullName = "";
                    if (patientEscalationSymptom.EnteredByFirstName != null && patientEscalationSymptom.EnteredByLastName != null) {
                        row.EnteredByFullName = patientEscalationSymptom["EnteredByFirstName"] + " " + patientEscalationSymptom["EnteredByLastName"];
                    }

                    row.CreatedDatetime = null;
                    if (patientEscalationSymptom.CreatedDatetime != null) {
                        let stillUtc = moment.utc(patientEscalationSymptom.CreatedDatetime);
                        row.CreatedDatetime = moment(stillUtc).local().format(dateFormat);
                    }

                    row.ListOfSymptomAttachment = {
                        "Results": [],
                    };

                    if (patientEscalationSymptom["ListOfAttachment"] !== undefined) {
                        if (patientEscalationSymptom["ListOfAttachment"].Attachment) {
                            for (let j = 0; j < patientEscalationSymptom["ListOfAttachment"].Attachment.length; j++) {
                                let attachment = patientEscalationSymptom["ListOfAttachment"].Attachment[j];

                                let attachmentRecord = {};
                                attachmentRecord.USID = attachment.USID;
                                attachmentRecord.FileName = attachment.FileName;
                                attachmentRecord.FileType = attachment.FileType;
                                attachmentRecord.FileSize = attachment.FileSize;
                                attachmentRecord.ParentUSID = attachment.ParentUSID;
                                attachmentRecord.ParentType = attachment.ParentType;

                                row.ListOfSymptomAttachment["Results"].push(attachmentRecord);
                            }
                        }
                    }

                    row["__operation"] = "query results";

                    results.push(row);
                }
            }
        } else {
            output["Count"] = 0;
        }
    }

    output["Results"] = results;

    return output;
};

export const V1PatientEscalationLabIDOCToArray = (input) => {
    let output = {};
    let results = [];
    let moment = require("moment");

    if (input.CloudcathMessage["ListOfPatientEscalationLab"] !== undefined) {
        if (input.CloudcathMessage["ListOfPatientEscalationLab"].Count !== undefined) {
            output["Count"] = input.CloudcathMessage["ListOfPatientEscalationLab"].Count;

            if ("PatientEscalationLab" in input.CloudcathMessage["ListOfPatientEscalationLab"]) {
                for (let i = 0; i < input.CloudcathMessage["ListOfPatientEscalationLab"]["PatientEscalationLab"].length; i++) {
                    let patientEscalationLab = input.CloudcathMessage["ListOfPatientEscalationLab"]["PatientEscalationLab"][i];
                    let row = {};

                    row.RowNum = i;
                    row.RowSelected = false;
                    row.USID = patientEscalationLab.USID;
                    row.PatientEscalationUSID = patientEscalationLab.PatientEscalationUSID;
                    row.Name = patientEscalationLab.Name;
                    row.Result = patientEscalationLab.Result;
                    row.Type = patientEscalationLab.Type;
                    row.AssociatedNote = patientEscalationLab.AssociatedNote;
                    row.Leukocytes = patientEscalationLab.Leukocytes;
                    row.Neutrophilis = patientEscalationLab.Neutrophilis;
                    row.Monocytes = patientEscalationLab.Monocytes;
                    row.Lymphocytes = patientEscalationLab.Lymphocytes;
                    row.Eosinophils = patientEscalationLab.Eosinophils;
                    row.RBC = patientEscalationLab.RBC;
                    row.ColorAppearance = patientEscalationLab.ColorAppearance;
                    row.DwellVolume = patientEscalationLab.DwellVolume;
                    row.DwellTime = patientEscalationLab.DwellTime;
                    row.EffluentCollectedBy = patientEscalationLab.EffluentCollectedBy;
                    row.GramStain = patientEscalationLab.GramStain;
                    row.CultureResult = patientEscalationLab.CultureResult;
                    row.CulturePathogen = patientEscalationLab.CulturePathogen;
                    row.CultureSource = patientEscalationLab.CultureSource;
                    row.CultureSourceOther = patientEscalationLab.CultureSourceOther;
                    row.SusceptibilityAntimicrobial1 = patientEscalationLab.SusceptibilityAntimicrobial1;
                    row.SusceptibilityPathogen1 = patientEscalationLab.SusceptibilityPathogen1;
                    row.SusceptibilityAntimicrobial2 = patientEscalationLab.SusceptibilityAntimicrobial2;
                    row.SusceptibilityPathogen2 = patientEscalationLab.SusceptibilityPathogen2;
                    row.SusceptibilityAntimicrobial3 = patientEscalationLab.SusceptibilityAntimicrobial3;
                    row.SusceptibilityPathogen3 = patientEscalationLab.SusceptibilityPathogen3;
                    row.SusceptibilityAntimicrobial4 = patientEscalationLab.SusceptibilityAntimicrobial4;
                    row.SusceptibilityPathogen4 = patientEscalationLab.SusceptibilityPathogen4;
                    row.SusceptibilityAntimicrobial5 = patientEscalationLab.SusceptibilityAntimicrobial5;
                    row.SusceptibilityPathogen5 = patientEscalationLab.SusceptibilityPathogen5;
                    row.SusceptibilityAntimicrobial6 = patientEscalationLab.SusceptibilityAntimicrobial6;
                    row.SusceptibilityPathogen6 = patientEscalationLab.SusceptibilityPathogen6;
                    row.SusceptibilityAntimicrobial7 = patientEscalationLab.SusceptibilityAntimicrobial7;
                    row.SusceptibilityPathogen7 = patientEscalationLab.SusceptibilityPathogen7;
                    row.SusceptibilityAntimicrobial8 = patientEscalationLab.SusceptibilityAntimicrobial8;
                    row.SusceptibilityPathogen8 = patientEscalationLab.SusceptibilityPathogen8;
                    row.SusceptibilityAntimicrobial9 = patientEscalationLab.SusceptibilityAntimicrobial9;
                    row.SusceptibilityPathogen9 = patientEscalationLab.SusceptibilityPathogen9;
                    row.SusceptibilityAntimicrobial10 = patientEscalationLab.SusceptibilityAntimicrobial10;
                    row.SusceptibilityPathogen10 = patientEscalationLab.SusceptibilityPathogen10;
                    row.EnteredByUSID = patientEscalationLab.EnteredByUSID;
                    row.EnteredByFirstName = patientEscalationLab.EnteredByFirstName;
                    row.EnteredByLastName = patientEscalationLab.EnteredByLastName;
                    row.SampleCollectionDate = patientEscalationLab.SampleCollectionDate;

                    row.SampleCollectionDateDisplay = null;
                    if (patientEscalationLab.SampleCollectionDate !== null) {
                        row.SampleCollectionDateDisplay = moment(patientEscalationLab.SampleCollectionDate).format(dateFormat);
                    }

                    row.CreatedDatetime = null;
                    if (patientEscalationLab.CreatedDatetime !== null) {
                        let stillUtc = moment.utc(patientEscalationLab.CreatedDatetime);
                        row.CreatedDatetime = moment(stillUtc).local().format(dateFormat);
                    }

                    row.EnteredByFullName = "";
                    if (patientEscalationLab.EnteredByFirstName !== null && patientEscalationLab.EnteredByLastName !== null) {
                        row.EnteredByFullName = patientEscalationLab["EnteredByFirstName"] + " " + patientEscalationLab["EnteredByLastName"];
                    }

                    row.ListOfLabAttachment = {
                        "Results": [],
                    };

                    if (patientEscalationLab["ListOfAttachment"] !== undefined) {
                        if (patientEscalationLab["ListOfAttachment"].Attachment) {
                            for (let j = 0; j < patientEscalationLab["ListOfAttachment"].Attachment.length; j++) {
                                let attachment = patientEscalationLab["ListOfAttachment"].Attachment[j];

                                let attachmentRecord = {};
                                attachmentRecord.USID = attachment.USID;
                                attachmentRecord.FileName = attachment.FileName;
                                attachmentRecord.FileType = attachment.FileType;
                                attachmentRecord.FileSize = attachment.FileSize;
                                attachmentRecord.ParentUSID = attachment.ParentUSID;
                                attachmentRecord.ParentType = attachment.ParentType;
                                attachmentRecord.PutURL = attachment.PutURL;

                                row.ListOfLabAttachment["Results"].push(attachmentRecord);
                            }
                        }
                    }

                    row["__operation"] = "query results";

                    results.push(row);
                }
            }
        } else {
            output["Count"] = 0;
        }

        output["Results"] = results;

        return output;
    }
};

export const V1PatientEscalationNoteIDOCToArray = (input) => {
    let output = {};
    let results = [];
    let moment = require("moment");

    if (input.CloudcathMessage["ListOfPatientEscalationNote"] !== undefined) {
        if (input.CloudcathMessage["ListOfPatientEscalationNote"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.CloudcathMessage["ListOfPatientEscalationNote"].Count;
        }
    }

    if ("PatientEscalationNote" in input.CloudcathMessage["ListOfPatientEscalationNote"]) {
        for (let i = 0; i < input.CloudcathMessage["ListOfPatientEscalationNote"]["PatientEscalationNote"].length; i++) {
            let patientEscalationNote = input.CloudcathMessage["ListOfPatientEscalationNote"]["PatientEscalationNote"][i];
            let row = {};

            row.RowNum = i;
            row.RowSelected = false;
            row.USID = patientEscalationNote.USID;
            row.PatientEscalationUSID = patientEscalationNote.PatientEscalationUSID;
            row.Note = patientEscalationNote.Note;
            row.CrossReferenceUSID = patientEscalationNote.CrossReferenceUSID;
            row.CrossReferenceType = patientEscalationNote.CrossReferenceType;
            row.EnteredByUSID = patientEscalationNote.EnteredByUSID;
            row.EnteredByFirstName = patientEscalationNote.EnteredByFirstName;
            row.EnteredByLastName = patientEscalationNote.EnteredByLastName;
            row.EventDate = patientEscalationNote.EventDate;
            row.EventDateDisplay = null;
            if (patientEscalationNote.EventDate !== null) {
                row.EventDateDisplay = moment(patientEscalationNote.EventDate).format(dateFormat);
            }

            row.EventDatetime = patientEscalationNote.EventDatetime;
            row.EventDatetimeDisplay = null;
            if (patientEscalationNote.EventDatetime !== null) {
                let stillUtc = moment.utc(patientEscalationNote.EventDatetime);
                row.EventDatetime = moment(stillUtc).local().format(dateTimeFormat);
                row.EventDatetimeDisplay = moment(stillUtc).local().format(dateTimeFormat);
            }

            row.CreatedDatetime = null;
            row.CreatedDatetimeDisplay = null;
            if (patientEscalationNote.CreatedDatetime !== null) {
                let stillUtc = moment.utc(patientEscalationNote.CreatedDatetime);
                row.CreatedDatetime = moment(stillUtc).local().format(dateTimeFormat);
                row.CreatedDatetimeDisplay = moment(stillUtc).local().format(dateTimeFormat);
            }

            row.EnteredByFullName = "";
            if (patientEscalationNote.EnteredByFirstName !== null && patientEscalationNote.EnteredByLastName !== null) {
                row.EnteredByFullName = patientEscalationNote["EnteredByFirstName"] + " " + patientEscalationNote["EnteredByLastName"];
            }

            row.ListOfNoteAttachment = {
                "Results": [],
            };

            if (patientEscalationNote["ListOfAttachment"] !== undefined) {
                if (patientEscalationNote["ListOfAttachment"].Attachment) {
                    for (let j = 0; j < patientEscalationNote["ListOfAttachment"].Attachment.length; j++) {
                        let attachment = patientEscalationNote["ListOfAttachment"].Attachment[j];

                        let attachmentRecord = {};
                        attachmentRecord.USID = attachment.USID;
                        attachmentRecord.FileName = attachment.FileName;
                        attachmentRecord.FileType = attachment.FileType;
                        attachmentRecord.FileSize = attachment.FileSize;
                        attachmentRecord.ParentUSID = attachment.ParentUSID;
                        attachmentRecord.ParentType = attachment.ParentType;

                        row.ListOfNoteAttachment["Results"].push(attachmentRecord);
                    }
                }
            }

            row["__operation"] = "query results";

            results.push(row);
        }
    }

    output["Results"] = results;

    return output;
};

export const V1BillingAccountIDOCToArray = (input) => {
    let output = [];

    if (input.CloudcathMessage["ListOfBillingAccount"] !== undefined) {
        if (input.CloudcathMessage["ListOfBillingAccount"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.CloudcathMessage["ListOfBillingAccount"].Count;
        }

        let results = [];

        if ("BillingAccount" in input.CloudcathMessage["ListOfBillingAccount"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfBillingAccount"]["BillingAccount"].length; i++) {
                let billingAccount = input.CloudcathMessage["ListOfBillingAccount"].BillingAccount[i];
                let row = {};

                row.RowSelected = false;
                row.USID = billingAccount.USID;
                row.Customer = billingAccount.Customer;
                row.CustomerNo = billingAccount.CustomerNo;
                row.SubCustomer = billingAccount.SubCustomer;
                row.SubCustomerNo = billingAccount.SubCustomerNo;

                row.DisplayName = `${row.CustomerNo}-${row.SubCustomerNo} / ${row.Customer}`;
                if (row.SubCustomer) row.DisplayName = `${row.DisplayName} / ${row.SubCustomer}`;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1ClinicIDOCToArray = (input) => {
    let output = [];

    if (input.CloudcathMessage["ListOfClinic"] !== undefined) {
        if (input.CloudcathMessage["ListOfClinic"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.CloudcathMessage["ListOfClinic"].Count;
        }

        let results = [];

        if ("Clinic" in input.CloudcathMessage["ListOfClinic"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfClinic"]["Clinic"].length; i++) {
                let clinic = input.CloudcathMessage["ListOfClinic"].Clinic[i];
                let row = {};

                row.RowSelected = false;
                row.USID = clinic.USID;
                row.Identifier = clinic.Identifier;
                row.Name = clinic.Name;
                row.ClinicAddress1 = clinic.ClinicAddress1;
                row.ClinicAddress2 = clinic.ClinicAddress2;
                row.ClinicCity = clinic.ClinicCity;
                row.ClinicState = clinic.ClinicState;
                row.ClinicPostalCode = clinic.ClinicPostalCode;
                row.ClinicCountry = clinic.ClinicCountry;
                row.MainPhone = clinic.MainPhone;
                row.MainEmail = clinic.MainEmail;
                row.BackupPhone = clinic.BackupPhone;
                row.BackupEmail = clinic.BackupEmail;
                row.SendReminderAfterInstances = clinic.SendReminderAfterInstances;
                row.TurbidityNotificationLevel = clinic.TurbidityNotificationLevel;
                row.NotifyAfterInstances = clinic.NotifyAfterInstances;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1PatientAlertIDOCToArray = (input) => {
    let output = [];

    if (input.CloudcathMessage["ListOfPatientAlert"] !== undefined) {
        if (input.CloudcathMessage["ListOfPatientAlert"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.CloudcathMessage["ListOfPatientAlert"].Count;
        }

        let results = [];

        if ("PatientAlert" in input.CloudcathMessage["ListOfPatientAlert"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfPatientAlert"]["PatientAlert"].length; i++) {
                let patientAlert = input.CloudcathMessage["ListOfPatientAlert"].PatientAlert[i];
                let row = {};

                row.RowSelected = false;
                row.USID = patientAlert.USID;
                row.ClinicUSID = patientAlert.ClinicUSID;
                row.PatientIdentifier = patientAlert.PatientIdentifier;
                row.PatientUSID = patientAlert.PatientUSID;
                row.AlertType = patientAlert.AlertType;
                row.AlertStatus = patientAlert.AlertStatus;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1UserSelectIDOCToArray = (input, onlyVerifiedUsers) => {
    let output = [];

    if (input.CloudcathMessage["ListOfUser"] !== undefined) {
        if (input.CloudcathMessage["ListOfUser"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.CloudcathMessage["ListOfUser"].Count;
        }

        let results = [];

        if ("User" in input.CloudcathMessage["ListOfUser"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfUser"]["User"].length; i++) {
                let user = input.CloudcathMessage["ListOfUser"]["User"][i];

                let row = {};

                if (onlyVerifiedUsers !== undefined && onlyVerifiedUsers === true) {
                    if (user.MainEmailVerifiedState === "VERIFIED" || user.MainPhoneVerifiedState === "VERIFIED" || user.AdditionalEmailVerifiedState === "VERIFIED" || user.AdditionalPhoneVerifiedState === "VERIFIED") {
                    } else {
                        continue;
                    }
                }

                row.RowSelected = false;
                row.USID = user.USID;
                row.FullName = user.FullName;
                row.UserRole = user.UserRole;
                row.UserStatus = user.UserStatus;
                row.MainEmailVerifiedState = user.MainEmailVerifiedState;
                row.MainPhoneVerifiedState = user.MainPhoneVerifiedState;
                row.AdditionalEmailVerifiedState = user.AdditionalEmailVerifiedState;
                row.AdditionalPhoneVerifiedState = user.AdditionalPhoneVerifiedState;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1InvitationIDOCToArray = (input, onlyVerifiedUser) => {
    let output = {};

    if (input.CloudcathMessage["ListOfInvitation"] !== undefined) {
        if (input.CloudcathMessage["ListOfInvitation"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.CloudcathMessage["ListOfInvitation"].Count;
        }

        let results = [];
        output["Results"] = results;

        if ("Invitation" in input.CloudcathMessage["ListOfInvitation"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfInvitation"]["Invitation"].length; i++) {
                let invitation = input.CloudcathMessage["ListOfInvitation"]["Invitation"][i];
                let row = {};

                let moment = require("moment");

                row.USID = invitation.USID;
                row.ClinicUSID = invitation.ClinicUSID;
                row.ClinicIdentifier = invitation.ClinicIdentifier;
                row.InvitationStatus = invitation.InvitationStatus;
                row.InvitationDatetime = invitation.InvitationDatetime;
                if (invitation.InvitationDatetime !== null) {
                    let stillUtc = moment.utc(invitation.InvitationDatetime);
                    row.InvitationDatetime = moment(stillUtc).local().format(dateFormat);
                }
                if (invitation.MainEmailVerifiedDatetime !== null) {
                    let stillUtc = moment.utc(invitation.MainEmailVerifiedDatetime);
                    row.MainEmailVerifiedDatetime = moment(stillUtc).local().format(dateFormat);
                }
                if (invitation.MainPhoneVerifiedDatetime !== null) {
                    let stillUtc = moment.utc(invitation.MainPhoneVerifiedDatetime);
                    row.MainPhoneVerifiedDatetime = moment(stillUtc).local().format(dateFormat);
                }
                row.UserRole = invitation.UserRole;
                row.FirstName = invitation.FirstName;
                row.LastName = invitation.LastName;
                row.Username = invitation.Username;
                row.MainEmail = invitation.MainEmail;
                row.MainEmailVerifiedState = invitation.MainEmailVerifiedState;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1UserIDOCToArray = (input, onlyVerifiedUsers) => {
    let output = {};

    if (input.CloudcathMessage["ListOfUser"] !== undefined) {
        if (input.CloudcathMessage["ListOfUser"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.CloudcathMessage["ListOfUser"].Count;
        }

        let results = [];
        output["Results"] = results;

        if ("User" in input.CloudcathMessage["ListOfUser"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfUser"]["User"].length; i++) {
                let user = input.CloudcathMessage["ListOfUser"]["User"][i];
                let row = {};

                if (onlyVerifiedUsers !== undefined && onlyVerifiedUsers === true) {
                    if (user.MainEmailVerifiedState !== "UNVERIFIED" && user.MainPhoneVerifiedState !== "UNVERIFIED" && user.AdditionalEmailVerifiedState !== "UNVERIFIED" && user.AdditionalPhoneVerifiedState !== "UNVERIFIED") {
                        continue;
                    }
                }

                let moment = require("moment");
                row.MainEmailVerifiedDatetime = null;
                row.MainPhoneVerifiedDatetime = null;
                row.AdditionalEmailVerifiedDatetime = null;
                row.AdditionalPhoneVerifiedDatetime = null;

                if (user.MainEmailVerifiedDatetime !== null) {
                    let stillUtc = moment.utc(user.MainEmailVerifiedDatetime);
                    row.MainEmailVerifiedDatetime = moment(stillUtc).local().format(dateFormat);
                }
                if (user.MainPhoneVerifiedDatetime !== null) {
                    let stillUtc = moment.utc(user.MainPhoneVerifiedDatetime);
                    row.MainPhoneVerifiedDatetime = moment(stillUtc).local().format(dateFormat);
                }
                if (user.AdditionalEmailVerifiedDatetime !== null) {
                    let stillUtc = moment.utc(user.AdditionalEmailVerifiedDatetime);
                    row.AdditionalEmailVerifiedDatetime = moment(stillUtc).local().format(dateFormat);
                }
                if (user.AdditionalPhoneVerifiedDatetime !== null) {
                    let stillUtc = moment.utc(user.AdditionalPhoneVerifiedDatetime);
                    row.AdditionalPhoneVerifiedDatetime = moment(stillUtc).local().format(dateFormat);
                }

                row.USID = user.USID;
                row.UserRole = user.UserRole;
                row.FullName = user.FullName;
                row.FirstName = user.FirstName;
                row.LastName = user.LastName;
                row.Salutation = user.Salutation;
                row.Suffix = user.Suffix;
                row.UserStatus = user.UserStatus;
                row.UserStatusDatetime = user.UserStatusDatetime;
                if (user.UserStatusDatetime !== null) {
                    let stillUtc = moment.utc(user.UserStatusDatetime);
                    row.UserStatusDatetime = moment(stillUtc).local().format(dateFormat);
                }
                row.Username = user.Username;
                row.MainEmail = user.MainEmail;
                row.OptionalCharacteristics = user.OptionalCharacteristics;
                row.MainEmailVerifiedState = user.MainEmailVerifiedState;
                row.MainPhone = user.MainPhone;
                row.MainPhoneVerifiedState = user.MainPhoneVerifiedState;
                row.AdditionalEmail = user.AdditionalEmail;
                row.AdditionalEmailVerifiedState = user.AdditionalEmailVerifiedState;
                row.AdditionalPhone = user.AdditionalPhone;
                row.AdditionalPhoneVerifiedState = user.AdditionalPhoneVerifiedState;

                let assignedPatients = "";
                row.AssignedPatientsCount = 0;
                if (user["ListOfPatient"] !== undefined) {
                    if (user["ListOfPatient"].Patient) {
                        for (let j = 0; j < user["ListOfPatient"].Patient.length; j++) {
                            let patient = user["ListOfPatient"].Patient[j];

                            if (row.AssignedPatientsCount === 0) {
                                assignedPatients += patient.PatientIdentifier;
                            } else {
                                assignedPatients += "|" + patient.PatientIdentifier;
                            }
                            row.AssignedPatientsCount += 1;
                        }
                    }
                }
                row.AssignedPatients = assignedPatients;

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }
    return output;
};

export const V1DeviceIDOCToArray = (input) => {
    let output = [];

    if (input.CloudcathMessage["ListOfDevice"] !== undefined) {
        if (input.CloudcathMessage["ListOfDevice"].Count === undefined) {
            output["Count"] = 0;
        } else {
            output["Count"] = input.CloudcathMessage["ListOfDevice"].Count;
        }

        let results = [];

        if ("Device" in input.CloudcathMessage["ListOfDevice"]) {
            for (let i = 0; i < input.CloudcathMessage["ListOfDevice"]["Device"].length; i++) {
                let device = input.CloudcathMessage["ListOfDevice"]["Device"][i];
                let row = {};

                row.USID = device.USID;
                row.SerialNumber = device.SerialNumber;
                row.Make = device.Make;
                row.Configuration = device.Configuration;
                row.ICCID = device.ICCID;
                row.Firmware = device.Firmware;
                row.LatestFirmwareVersion = device.LatestFirmwareVersion;
                row.DeviceStatus = device.DeviceStatus;
                row.ReturnInitiated = device.ReturnInitiated;
                row.PatientIdentifier = device.PatientIdentifier;
                row.CloudCathID = device.CloudCathID;
                row.FirstName = device.FirstName;
                row.MiddleInitial = device.MiddleInitial;
                row.LastName = device.LastName;
                row.LastTurbidityValueDatetime = null;

                if (device.LastTurbidityValueDatetime !== null) {
                    let stillUtc = moment.utc(device.LastTurbidityValueDatetime);
                    row.LastTurbidityValueDatetime = moment(stillUtc).local().format(dateTimeFormat);
                }

                row["__operation"] = "query results";

                results.push(row);
            }
        }

        output["Results"] = results;
    }

    return output;
};

