import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useAppContext} from "../../App";
import CloudcathAxios from "../../util/CloudcathAxios";
import {sleep} from "../../util/Sleep";
import CCButton from "../common/CCButton";
import CCTextField from "../common/CCTextField";
import css_self from "./css/ExtraSettingsDialog.scss";

const INACTIVE_TIMEOUT = "55";

export default function ExtraSettingsDialog(props) {

    const [open, setOpen] = useState(true);

    const {sessionContext} = useAppContext();

    const [inactiveTimeout, setInactiveTimeout] = useState(
        localStorage.getItem("inactive-timeout") || INACTIVE_TIMEOUT,
    );

    const [inactiveTimeoutLogging, setInactiveTimeoutLogging] = useState(
        localStorage.getItem("inactive-timeout-logging") === "true",
    );

    const [turbidityNotificationsSending, setTurbidityNotificationsSending] = useState(false);
    const [turbidityNotificationsEnabled, setTurbidityNotificationsEnabled] = useState(
        sessionContext.userConfig?.episode_notifications_enabled === "true",
    );


    return (
        <Dialog
            open={open}
            scroll="body"
            onClose={async () => setOpen(false)}
            {...props}
        >
            <DialogTitle>Extra Settings</DialogTitle>
            <DialogContent style={{padding: "0 16px"}}>
                <Typography className={css_self.Title}>Turbidity Notifications</Typography>
                <div style={{height: 8}} />
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            color="primary"
                            checked={turbidityNotificationsEnabled}
                            disabled={turbidityNotificationsSending}
                            onChange={async e => {
                                const startAt = Date.now();
                                setTurbidityNotificationsSending(true);
                                const checked = e.target.checked;
                                setTurbidityNotificationsEnabled(checked);
                                CloudcathAxios({
                                    method: "POST",
                                    url: "cloudcath/v1/user_config",
                                    json: true,
                                    data: {
                                        Key: "episode_notifications_enabled",
                                        Value: checked.toString(),
                                    },
                                }).then(async response => {
                                    const waitDiff = 1000 - (Date.now() - startAt);
                                    waitDiff > 0 && (await sleep(waitDiff));
                                    setTurbidityNotificationsSending(false);
                                    sessionContext.userConfig = {
                                        ...(sessionContext.userConfig ?? {}),
                                        episode_notifications_enabled: checked.toString(),
                                    };
                                }).catch(async e => {
                                    const waitDiff = 1000 - (Date.now() - startAt);
                                    waitDiff > 0 && (await sleep(waitDiff));
                                    setTurbidityNotificationsSending(false);
                                    setTurbidityNotificationsEnabled(sessionContext.userConfig?.episode_notifications_enabled === "true");
                                });
                            }}
                        />
                    }
                    label={<Typography className={css_self.Text}>Enable turbidity notifications</Typography>}
                />
                <Typography className={css_self.HelperText}>Opt-in to turbidity episode notifications. By default notifications are disabled in non-production environmens.</Typography>
                <div style={{height: 25}} />
                <Typography className={css_self.Title}>Inactive Timeout</Typography>
                <CCTextField
                    value={inactiveTimeout}
                    label="Inactive Timeout (minutes)"
                    onChange={async e => {
                        const value = e.target.value;
                        if (/^$|^[1-9]+\d*$/.test(value)) {
                            setInactiveTimeout(value);
                            if (value.length === 0 || value === INACTIVE_TIMEOUT) {
                                localStorage.removeItem("inactive-timeout");
                            } else {
                                localStorage.setItem("inactive-timeout", parseInt(value));
                            }
                        }
                    }}
                    onBlur={async e => {
                        const value = e.target.value;
                        if (value.length === 0) {
                            setInactiveTimeout(INACTIVE_TIMEOUT);
                        }
                    }}
                />
                <Typography className={css_self.HelperText}>Set the inactive timeout used for auto-logout. Inactivity is based on window events: mousemove, scroll, keydown, resize</Typography>
                <div style={{height: 25}} />
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            color="primary"
                            checked={inactiveTimeoutLogging}
                            onChange={async e => {
                                const checked = e.target.checked;
                                setInactiveTimeoutLogging(checked);
                                if (checked) {
                                    localStorage.setItem("inactive-timeout-logging", "true");
                                } else localStorage.removeItem("inactive-timeout-logging");
                            }}
                        />
                    }
                    label={<Typography className={css_self.Text}>Enable inactive timeout logging</Typography>}
                />
            </DialogContent>
            <DialogActions>
                <CCButton onClick={async () => setOpen(false)}>Close</CCButton>
            </DialogActions>
        </Dialog>
    );
}
