import {Typography} from "@material-ui/core";
import moment from "moment";
import React, {useEffect, useState} from "react";
import Countdown, {zeroPad} from "react-countdown-now";
import {useHistory} from "react-router-dom";
import {useAppContext} from "../../App";
import * as Analytics from "../../util/Analytics";
import {useStatic} from "../../util/useStatic";
import "./css/MaintenanceBar.scss";

// aws iot-data publish \
//   --topic "cloudcath_web_s1/v1/global" \
//   --payload '{ "type": "Maintenance", "message": "System is going offline for maintenance", "eventDatetime": "2022-01-01T00:00:00Z" }' \
//   --cli-binary-format raw-in-base64-out
export default function MaintenanceBar() {

    const {mqttClient} = useAppContext();

    const [maintenance, setMaintenance] = useState(
        undefined, /*{
      message: "System is going offline for maintenance",
      eventDatetime: moment.utc().add(5, "minutes").format(),
    }*/
    );

    const history = useHistory();

    const callback = useStatic((topic, payload, packet) => {
        if (topic === "cloudcath_web_s1/v1/global" && payload?.length > 0) {

            payload = JSON.parse(payload.toString());

            if (payload.type === "Maintenance") {

                if (payload.eventDatetime === null) {

                    if (maintenance) {
                        Analytics.record("Maintenance Cancelled");
                        setMaintenance(undefined);
                    }

                } else {

                    Analytics.record("Maintenance Notification", {
                        Message: payload.message,
                        "Start Date": payload.eventDatetime,
                        "Time Till Start": (moment.utc(payload.eventDatetime).local().toDate().getTime() - Date.now()) / 1000,
                    });

                    setMaintenance({
                        message: payload.message,
                        eventDatetime: payload.eventDatetime,
                    });
                }
            }
        }
    });

    useEffect(() => {
        if (mqttClient) {
            mqttClient.on("message", callback);
            return () => mqttClient.off("message", callback);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mqttClient]);

    return maintenance ? (
        <div {...{"data-cc-component": "MaintenanceBar"}}>
            <Typography variant="inherit">
                {maintenance.message}
                <Countdown
                    date={moment.utc(maintenance.eventDatetime).local().toDate()}
                    renderer={({hours, minutes, seconds, completed}) => {
                        if (completed) return ""; // hide countdown until remote logout is sent
                        hours = zeroPad(hours);
                        minutes = zeroPad(minutes);
                        seconds = zeroPad(seconds);
                        return ` (${hours}:${minutes}:${seconds})`;
                    }}
                />
            </Typography>
        </div>
    ) : null;
}
