import {Button, Checkbox, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, Popper, TextField, Typography, withStyles} from "@material-ui/core";
import {AddCircleOutline, Attachment, CheckCircle, Clear, Error, Help, HighlightOff, Publish, Visibility, VisibilityOff} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";
import {DropzoneArea} from "material-ui-dropzone";
import React, {Component} from "react";
import {v4 as uuidv4} from "uuid";
import {withAppContext} from "../App";
import LeftArrow from "../assets/leftarrow.png";
import spinner from "../assets/Spinner-1s-200px.svg";
import DynamicLookupDefaultValue from "../eoec/util/DynamicLookupDefaultValue";
import UniqueValidator from "../eoec/util/UniqueValidator";
import styles from "../theme/styles";
import {updateObject} from "../util/utility";
import * as validators from "../util/Validators";
import CloudcathSelect from "./CloudcathSelect";
import CCButton from "./common/CCButton";
import CCDatePicker from "./common/CCDatePicker";
import CCDateTimePicker from "./common/CCDateTimePicker";
import CCInputFloat from "./common/CCInputFloat";
import CCInputInteger from "./common/CCInputInteger";
import CCInputPhone from "./common/CCInputPhone";

class CloudcathDetail extends Component {
    state = {
        fetchBackgroundOperation: false,
        buffer: {},
        filtering: false,
        controls: null,
        errors: {},
        saveAndAddNew: false,
        previousValues: {},
        updateBackgroundOperation: false,
        insertBackgroundOperation: false,
        errorReportingMethod: "RealTime",
        showErrors: true,
        showPassword: false,
        groupCounter: 1,
        showAddAnother: false,
        patientNotificationOptionalChecked: true,
        initialModification: false,
        showInitialModificationWarning: false,
        helpAnchor: null,
        helpFormElementID: null,
        files: [],
        showAddAttachments: false,
        attachmentToDownload: null,
        attachmentsTouched: false,
        duplicateFileName: null,
        multipleAttachments: false,
    };

    addAttachmentsHandler = (files) => {
        let attachment_ec;
        if (this.applet.getEnterpriseComponent().getName() === "EscalationLab") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("LabAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "EscalationTreatment") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("TreatmentAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "EscalationSymptom") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("SymptomAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "EscalationNote") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("NoteAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "PatientS0" || this.applet.getEnterpriseComponent().getName() === "Patient") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("PatientAttachment");
        }
        if (attachment_ec === null) return;

        let multipleAttachments = false;
        if (files.length > 1) {
            multipleAttachments = true;
        }

        let duplicateFileName = null;
        files.forEach((file) => {
            let hitCounter = 0;
            for (let j = 0; j < attachment_ec.getBuffer().length; j++) {
                if (attachment_ec.getBuffer()[j]["FileName"] === file.name) {
                    hitCounter++;
                }
            }
            if (hitCounter >= 1) {
                duplicateFileName = file.name;
            }
        });

        let newBuffer = [];
        for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
            let attachment = attachment_ec.getBuffer()[i];
            if (attachment["__operation"] !== "new") {
                newBuffer.push(attachment);
            }
        }
        attachment_ec.setBuffer(newBuffer);

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;

                attachment_ec.newRecord();
                attachment_ec.setAttributeValue("USID", uuidv4());
                attachment_ec.setAttributeValue("ParentUSID", this.applet.getEnterpriseComponent().getAttributeValue("USID"));
                attachment_ec.setAttributeValue("ParentType", this.applet.getEnterpriseComponent().getName());
                attachment_ec.setAttributeValue("FileName", this.scrubFileName(file.name));
                attachment_ec.setAttributeValue("FileType", file.type);
                attachment_ec.setAttributeValue("FileSize", file.size);
                attachment_ec.setAttributeValue("Payload", base64String);
            };
            reader.readAsDataURL(file);
        });

        this.setState({files: files, initialModification: true, attachmentsTouched: true, duplicateFileName: duplicateFileName, multipleAttachments: multipleAttachments});
    };

    blurHandler = (event, formElementId) => {
        if (this.state.controls[formElementId].requiresConfirmation !== true) return;
        if (this.state.controls[formElementId].valid === false) return;
        if (this.state.controls[formElementId].touched === false) return;
        if (this.state.controls[formElementId].confirmedValue === this.state.controls[formElementId].value) return;

        let original_value = this.applet.getEnterpriseComponent().getAttributeValue(this.props.controls[formElementId].enterpriseComponentAttribute, "original");

        let field = this.state.controls[formElementId];

        let err = field["errors"];

        let cumulativeErrors = {...this.state.errors};

        for (let i = 0; i < err.length; i++) {
            let testCase = err[i];

            if (testCase.validator === "Unique") {
                if (this.state.controls[formElementId].value !== "" && this.state.controls[formElementId].confirmedValue !== event.target.value && original_value !== this.state.controls[formElementId].value) {
                    this.validatorUnique(event.target.value, formElementId);
                }
                delete cumulativeErrors[field.id + "_" + testCase.validator];
            }
        }

        this.setState({errors: cumulativeErrors});

        return true;
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;
    }

    componentDidMount() {
        this.applet.getEnterpriseComponent().addRecordChangedListener(this);
        this.applet.getEnterpriseComponent().addLoadStartedListener(this);
        this.applet.getEnterpriseComponent().addLoadCompletedListener(this);
        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
        this.applet.getEnterpriseComponent().addInsertStartedListener(this);
        this.applet.getEnterpriseComponent().addInsertCompletedListener(this);
        this.applet.getEnterpriseComponent().addInsertRollbackCompletedListener(this);
        this.applet.getEnterpriseComponent().addUpdateRollbackCompletedListener(this);
        this.applet.getEnterpriseComponent().addStartNewRecordModeCompletedListener(this);
        this.applet.getEnterpriseComponent().addStartEditRecordModeCompletedListener(this);

        this.applet.setMode(this.props.mode);

        let patientNotificationOptionalChecked = true;
        if (this.applet.getEnterpriseComponent().getName() === "Patient") {
            patientNotificationOptionalChecked = this.applet.getEnterpriseComponent().getAttributeValue("EnableTurbidityNotifications");
        }
        if (this.props.errorReportingMethod !== undefined) {
            this.setState({errorReportingMethod: this.props.errorReportingMethod, showErrors: this.props.errorReportingMethod === "RealTime", patientNotificationOptionalChecked: patientNotificationOptionalChecked});
        } else {
            this.setState({patientNotificationOptionalChecked: patientNotificationOptionalChecked});
        }

        this.initialize();

    }

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    }

    cancel1Handler = (event, _) => {
        if (this.applet.getMode() === "View") {
            this.applet.cancel();
        } else if (this.isFormTouched()) {
            event.preventDefault();
            this.setState({showConfirmation: true});
        } else {
            this.applet.cancel();
        }
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    closeHandler = (event) => {
        event.preventDefault();

        this.setState({showConfirmation: false});
        this.applet.cancel();
    };

    checkValidity = (formElementId, value) => {
        let check = {};

        let values = {};
        let keys = Object.keys(this.state.controls);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === formElementId) {
                values[keys[i]] = value;
            } else {
                values[keys[i]] = this.state.controls[keys[i]].value;
            }
        }
        let cumulativeErrors = {...this.state.errors};

        for (let t = 0; t < keys.length; t++) {
            let formElementToTestId = keys[t];

            let field = this.state.controls[formElementToTestId];
            let err = field["errors"];
            if (err === undefined) {
                err = {};
            }
            let attribute = this.applet.getEnterpriseComponent().getAttribute(field.enterpriseComponentAttribute);

            if (formElementId === formElementToTestId) {
                check[formElementToTestId] = {
                    ...field,
                    touched: true,
                    value: values[formElementToTestId],
                    valid: true,
                };
            } else {
                check[formElementToTestId] = {
                    ...field,
                    touched: this.state.controls[formElementToTestId].touched,
                    value: values[formElementToTestId],
                    valid: true,
                };
            }
            for (let i = 0; i < err.length; i++) {
                let testCase = err[i];
                let localCheck = true;

                if (testCase.validator === "Required") {
                    localCheck = validators.Required(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "No Leading Zeros") {
                    localCheck = validators.NoLeadingZeros(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "No Leading Spaces") {
                    localCheck = validators.NoLeadingSpaces(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "No Trailing Spaces") {
                    localCheck = validators.NoTrailingSpaces(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Minimum") {
                    localCheck = validators.Minimum(values[formElementToTestId], attribute.getMinLength());
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Maximum") {
                    localCheck = validators.Maximum(values[formElementToTestId], attribute.getLength());
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "No Special Characters") {
                    localCheck = validators.NoSpecialCharacters(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "No Spaces") {
                    localCheck = validators.NoSpaces(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Maximum Value") {
                    localCheck = validators.MaximumValue(values[formElementToTestId], testCase.value);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Minimum Value") {
                    localCheck = validators.MinimumValue(values[formElementToTestId], testCase.value);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Required Integer") {
                    localCheck = validators.RequiredInteger(values[formElementToTestId]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Required Pattern") {
                    if (values[formElementToTestId] === null) {
                        localCheck = false;
                    } else {
                        localCheck = validators.RequiredPattern(values[formElementToTestId], attribute.getPattern());
                    }
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Optional Pattern") {
                    localCheck = validators.OptionalPattern(values[formElementToTestId], attribute.getPattern());
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Float") {
                    localCheck = isNaN(Number(values[formElementToTestId])) !== true;
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Numbers Only") {
                    localCheck = validators.NumbersOnlyPattern(values[formElementToTestId], attribute.getPattern());
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Match Values") {
                    localCheck = validators.MatchValues(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "All Or None") {
                    localCheck = validators.AllOrNone(values[formElementToTestId], testCase.fields.map(x => values[x]));
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Either Values") {
                    localCheck = validators.EitherValues(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Either Values Not NULL") {
                    localCheck = validators.EitherValuesNotNULL(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "One Field Must Be Provided") {
                    localCheck = validators.OneFieldMustBeProvided(testCase.field, values, this.state.controls);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "One Checkbox Must Be True") {
                    localCheck = validators.OneCheckboxMustBeTrue(testCase.field, values);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Is Same Or Before Day") {
                    localCheck = validators.IsSameOrBeforeDay(values[formElementToTestId], testCase.value ?? values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Is Same Or After Day") {
                    localCheck = validators.IsSameOrAfterDay(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Cannot Match Values") {
                    localCheck = validators.CannotMatchValues(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Cannot Match Values - Case Insensitive") {
                    localCheck = validators.CannotMatchValuesCaseInsensitive(values[formElementToTestId], values[testCase.field]);
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Not All Spaces") {
                    if (values[formElementToTestId].length === 0) {
                        localCheck = true;
                    } else if (values[formElementToTestId].trim().length === 0) {
                        localCheck = false;
                    } else {
                        localCheck = true;
                    }
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                } else if (testCase.validator === "Unique") {
                    check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                }

                check[formElementToTestId].hasBeenConfirmed = this.state.controls[formElementToTestId].hasBeenConfirmed;
                if (this.state.controls[formElementToTestId].requiresConfirmation === true) {
                    if (this.state.controls[formElementToTestId].confirmedValue !== values[formElementToTestId]) {
                        check[formElementToTestId].hasBeenConfirmed = false;
                        check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                    } else if (this.state.controls[formElementToTestId].hasBeenConfirmed === false && values[formElementToTestId] !== "") {
                        check[formElementToTestId].valid = false;
                    }
                }

                if (this.state.controls[formElementToTestId].requiresConfirmation) {
                    if (values[formElementToTestId] === "") {
                        check[formElementToTestId].hasBeenConfirmed = false;
                        check[formElementToTestId].valid = check[formElementToTestId].valid && localCheck;
                    }
                }

                if (localCheck === false) {
                    if (this.props.errorReportingMethod === "SubmitTime") {
                        cumulativeErrors[field.id + "_" + testCase.validator] = testCase.errorText;
                    } else {
                        if (check[formElementToTestId].touched === true) {
                            cumulativeErrors[field.id + "_" + testCase.validator] = testCase.errorText;
                        }
                    }
                } else {
                    if (testCase.validator !== "Unique") {
                        delete cumulativeErrors[field.id + "_" + testCase.validator];
                    }
                }
            }
        }
        this.setState({errors: cumulativeErrors});

        return check;
    };

    confirmDownload = (attachmentUSID) => {
        this.setState({attachmentToDownloadUSID: attachmentUSID});
    };

    deleteHandler = () => {
        this.applet.setMode("Delete");
    };

    editHandler = () => {
        this.applet.setMode("Edit");
    };

    enterpriseComponentRecordChanged = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b});
        } else {
            this.setState({buffer: {}});
        }
        this.prepareLocalState(b);
    };

    enterpriseComponentLoadStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }

        if (this.applet.getMode() === "Edit") {
            this.applet.getEnterpriseComponent().setUpdateMode();
        }
        this.prepareLocalState(b);
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = (buffer) => {
        let updatedRow = buffer[this.applet.getEnterpriseComponent().getRecordPointer()];

        let controls = this.makeControls().controls;

        let keys = Object.keys(controls);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            controls[key]["value"] = updatedRow[key] === null ? "" : updatedRow[key];
            controls[key]["valid"] = true;
            controls[key]["touched"] = false;
            controls[key]["showSpinner"] = false;
        }

        this.setState({controls: controls, errors: {}, updateBackgroundOperation: false});

        if (this.state.saveAndAddNew) {
            this.applet.getView().initiateAction(this.props.saveAndNewAction);
        } else {
            this.applet.getView().initiateAction(null);
        }
    };

    enterpriseComponentInsertStarted = () => {
        this.setState({insertBackgroundOperation: true});
    };

    enterpriseComponentInsertCompleted = (buffer) => {
        let controls = this.makeControls().controls;

        let keys = Object.keys(controls);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            controls[key]["value"] = buffer[0][key] === null ? "" : buffer[0][key];
            controls[key]["valid"] = true;
            controls[key]["touched"] = false;
            controls[key]["showSpinner"] = false;
        }

        this.setState({controls: controls, errors: {}, insertBackgroundOperation: false});

        if (this.state.saveAndAddNew) {
            this.applet.setMode("New");
            this.prepareLocalState(this.applet.getEnterpriseComponent().getBuffer[0]);
        } else {
            this.applet.getView().initiateAction(null);
        }
    };

    enterpriseComponentInsertRollbackCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }

        this.prepareLocalState(b);
    };

    enterpriseComponentStartNewRecordModeCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        this.prepareLocalState(localBuffer[0]);
    };

    enterpriseComponentStartEditRecordModeCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        if (localBuffer.length > 0) {
            this.prepareLocalState(localBuffer[0]);
        }
    };

    enterpriseComponentUpdateRollbackCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let controls = this.makeControls().controls;

        let keys = Object.keys(controls);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            controls[key]["value"] = localBuffer[key] === null ? "" : localBuffer[key];
            controls[key]["valid"] = true;
            controls[key]["touched"] = false;
            controls[key]["showSpinner"] = false;
        }

        this.setState({controls: controls, errors: {}, updateBackgroundOperation: false});

        if (this.state.saveAndAddNew) {
            this.applet.getView().initiateAction(this.props.saveAndNewAction);
        } else {
            this.applet.getView().initiateAction(null);
        }
    };

    evaluateSaveDisabledStatus = () => {
        if (this.useRealTimeReportingMethod()) {
            return this.isFormValid() === false || this.isFormTouched() === false;
        } else {
            return false;
        }
    };

    executeQueryHandler = () => {
    };

    getField = (formElement, renderGroupRemoveButton, group) => {
        /*
        formElement.ip (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes)
        formElement.inputLabelProps (https://material-ui.com/api/input-label/)
        formElement.formHelperTextProps (https://material-ui.com/api/form-helper-text/)
         */
        let out;
        let field;

        if (formElement.csp === undefined) {
            formElement["csp"] = {};
        }
        if (formElement.ip === undefined) {
            formElement["ip"] = {};
        }

        if (formElement["placement"] === undefined) {
            formElement["placement"] = {
                "View": {order: 100, xs: 1, sm: 1, md: 1, lg: 1, xl: 1},
                "Edit": {order: 100, xs: 1, sm: 1, md: 1, lg: 1, xl: 1},
                "New": {order: 100, xs: 1, sm: 1, md: 1, lg: 1, xl: 1},
            };
        }

        let attribute = this.applet.getEnterpriseComponent().getAttribute(formElement.enterpriseComponentAttribute);
        formElement.csp.required = formElement.csp.required || (attribute === undefined ? false : attribute.getRequired());

        if (formElement.showWarning === true) {
            formElement.csp["helperText"] = formElement.warningText;
        } else {
            formElement.csp["helperText"] = null;
        }
        if (this.state.showErrors === true) {
            let helperText = formElement.csp["helperText"];

            formElement.csp["helperText"] = null;
            let keys = Object.keys(this.state.errors);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (key.includes(formElement.id)) {
                    helperText = this.state.errors[key];
                    formElement.csp["helperText"] = helperText;
                }
            }
        }

        let inputProps = {
            ...formElement.ip,
        };

        if (formElement.type === "Checkbox") {
            delete formElement.csp["helperText"];
        }

        let inputLabelProps = {shrink: true};

        if (formElement.longLabel) {
            inputLabelProps = {
                shrink: true,
                classes: {
                    root: this.props.classes.longLabel,
                },
            };
        }

        let adornments = {};
        let helpButton = null;
        let helpContent = null;
        let elementIndex = 0;
        if (formElement.help !== undefined && this.state.helpAnchor !== null) {
            helpContent = (
                <ClickAwayListener onClickAway={this.hideHelp}>
                    <Popper id={1} style={{zIndex: "4000"}} open={formElement.id === this.state.helpFormElementID} placement="right" anchorEl={this.state.helpAnchor}>
                        <Alert severity="info">
                            <>{formElement.help.map(element => (<React.Fragment key={elementIndex++}><span key={elementIndex++}>{element.helpText}</span><br /></React.Fragment>))}</>
                        </Alert>
                    </Popper>
                </ClickAwayListener>
            );
        }

        if (formElement.help !== undefined) {
            helpButton = (
                <IconButton style={{paddingRight: "0px"}} onClick={(event) => this.showHelp(event, formElement)}><Help color="primary" /></IconButton>
            );
            adornments["endAdornment"] = (
                <InputAdornment position="end">
                    {helpButton}
                    {helpContent}
                </InputAdornment>
            );
        }
        if (formElement.csp.uom !== undefined) {
            adornments["endAdornment"] = (
                <InputAdornment position="end">
                    <span style={{color: "#bac3ce"}}>{formElement.csp.uom}</span>
                    {helpButton}
                    {helpContent}
                </InputAdornment>
            );
        }
        if (formElement.showSpinner === true) {
            adornments["endAdornment"] = (
                <InputAdornment position="end">
                    <img src={spinner} alt="Spinner" style={{width: "35px", height: "35px"}} />
                </InputAdornment>
            );
        }
        if (!formElement.valid && formElement.touched && this.state.showErrors) {
            adornments["startAdornment"] = (
                <InputAdornment position="start">
                    <Error color="error" />
                </InputAdornment>
            );
        }
        if (formElement.valid && formElement.touched && this.state.showErrors) {
            adornments["startAdornment"] = (
                <InputAdornment position="start">
                    <CheckCircle color="primary" />
                </InputAdornment>
            );
        }
        if (formElement.type === "TextField") {
            field = (
                <TextField
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    fullWidth={true}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    variant="outlined"
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "CulturePathogen") {
            if (this.state.controls["CultureResult"].value === "Positive") {
                field = (
                    <TextField
                        key={formElement.id}
                        {...formElement.csp}
                        inputProps={inputProps}
                        InputLabelProps={inputLabelProps}
                        error={!formElement.valid && formElement.touched && this.state.showErrors}
                        fullWidth={true}
                        id={formElement.id}
                        onBlur={(event) => this.blurHandler(event, formElement.id)}
                        onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                        value={formElement.value}
                        variant="outlined"
                        InputProps={adornments}
                    />
                );
            } else {
                field = null;
            }
        } else if (formElement.type === "CultureSourceOther") {
            if (this.state.controls["CultureSource"].value === "Other") {
                field = (
                    <TextField
                        key={formElement.id}
                        {...formElement.csp}
                        inputProps={inputProps}
                        InputLabelProps={inputLabelProps}
                        error={!formElement.valid && formElement.touched && this.state.showErrors}
                        fullWidth={true}
                        id={formElement.id}
                        onBlur={(event) => this.blurHandler(event, formElement.id)}
                        onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                        value={formElement.value}
                        variant="outlined"
                        InputProps={adornments}
                    />
                );
            } else {
                field = null;
            }
        } else if (formElement.type === "Placeholder") {
            field = null;
        } else if (formElement.type === "Phone") {
            field = (
                <CCInputPhone
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "Upload") {
            field = (
                <div>
                    <IconButton onClick={() => {document.getElementById("fileInput").click();}}><Publish color="primary" /></IconButton>
                    <input style={{display: "None"}} id="fileInput" type="file" />
                </div>
            );
        } else if (formElement.type === "Integer") {
            field = (
                <CCInputInteger
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "Select") {
            let attribute = this.applet.getEnterpriseComponent().getAttribute(formElement.enterpriseComponentAttribute);

            let picklist = attribute !== null ? attribute.getPicklist() : null;

            field = (
                <CloudcathSelect
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    fullWidth={true}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    variant="outlined"
                    InputProps={adornments}
                    picklist={picklist}
                    mode={this.applet.getMode()}
                    parent={this}
                />
            );
        } else if (formElement.type === "OptionalCharacteristics") {
            if (this.state.controls["UserRole"].value !== "Admin") {

                let attribute = this.applet.getEnterpriseComponent().getAttribute(formElement.enterpriseComponentAttribute);

                let picklist = attribute !== null ? attribute.getPicklist() : null;

                field = (
                    <CloudcathSelect
                        key={formElement.id}
                        {...formElement.csp}
                        inputProps={inputProps}
                        InputLabelProps={inputLabelProps}
                        error={!formElement.valid && formElement.touched && this.state.showErrors}
                        fullWidth={true}
                        id={formElement.id}
                        onBlur={(event) => this.blurHandler(event, formElement.id)}
                        onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                        value={formElement.value}
                        variant="outlined"
                        InputProps={adornments}
                        picklist={picklist}
                        mode={this.applet.getMode()}
                    />
                );
            }
        } else if (formElement.type === "Number") {
            field = (
                <CCInputFloat
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={{shrink: true}}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "Positive Float") {
            field = (
                <CCInputFloat
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={{shrink: true}}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "Password") {
            adornments["endAdornment"] = (
                <InputAdornment position="end">
                    <IconButton onClick={() => this.toggleVisibility(formElement)}>
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            );
            field = (
                <TextField
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    fullWidth={true}
                    id={formElement.id}
                    type={this.state.showPassword ? "text" : "password"}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    variant="outlined"
                    InputProps={adornments}
                />
            );
        } else if (formElement.type === "DateTime") {
            field = (
                <CCDateTimePicker
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    InputLabelProps={inputLabelProps}
                />
            );
        } else if (formElement.type === "Date") {
            field = (
                <CCDatePicker
                    key={formElement.id}
                    {...formElement.csp}
                    inputProps={inputProps}
                    error={!formElement.valid && formElement.touched && this.state.showErrors}
                    id={formElement.id}
                    onBlur={(event) => this.blurHandler(event, formElement.id)}
                    onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                    value={formElement.value}
                    InputLabelProps={inputLabelProps}
                />
            );
        } else if (formElement.type === "Checkbox") {
            field = (
                <FormControlLabel
                    key={formElement.id}
                    control={
                        <Checkbox
                            key={formElement.id}
                            inputProps={inputProps}
                            id={formElement.id}
                            onBlur={(event) => this.blurHandler(event, formElement.id)}
                            onChange={(event) => this.inputChangedHandler(event, formElement.id)}
                            checked={formElement.value === "true" || formElement.value === true}
                        />
                    }
                    {...formElement.csp}
                />
            );
        }

        let style = {};

        if (formElement.other?.marginBottom !== undefined) {
            style["marginBottom"] = formElement.other.marginBottom;
        } else {
            style["marginBottom"] = "1.0em";
        }

        if (formElement.other?.hidden === true) {
            style["display"] = "none";
        }

        let assistiveText = this.renderAssistiveText(formElement);
        if (formElement.csp?.disabled === true) {
            assistiveText = null;
        }
        if (formElement.other?.renderAssistiveText === false) {
            assistiveText = null;
        }
        if (field === null) {
            assistiveText = null;
        }

        if (renderGroupRemoveButton === true && this.applet.getMode() !== "View") {
            out = (
                <Grid key={formElement.id} item style={style}
                      xs={formElement.placement[this.applet.getMode()].xs}
                      sm={formElement.placement[this.applet.getMode()].sm}
                      md={formElement.placement[this.applet.getMode()].md}
                      lg={formElement.placement[this.applet.getMode()].lg}
                      xl={formElement.placement[this.applet.getMode()].xl}>

                    <div key={1} style={{display: "flex", flexDirection: "column"}}>
                        <div key={1} style={{display: "flex", flexDirection: "row"}}>
                            <div key={1} style={{width: "95%"}}>{field}</div>
                            <div key={2} style={{width: "40px"}}>
                                <Button
                                    style={{marginTop: "11px", width: "25px", color: "#E74C3C"}}
                                    startIcon={<Clear />}
                                    onClick={() => this.onDemandDecrementHandler(group)}>
                                </Button>
                            </div>
                        </div>
                        <div key={2}>
                            {assistiveText}
                        </div>
                    </div>
                </Grid>
            );
        } else {
            out = (
                <Grid key={formElement.id} item style={style}
                      xs={formElement.placement[this.applet.getMode()].xs}
                      sm={formElement.placement[this.applet.getMode()].sm}
                      md={formElement.placement[this.applet.getMode()].md}
                      lg={formElement.placement[this.applet.getMode()].lg}
                      xl={formElement.placement[this.applet.getMode()].xl}>

                    {field}
                    {assistiveText}
                </Grid>
            );
        }
        return out;
    };

    getGroups = () => {
        if (this.props.modes[this.applet.getMode()] !== undefined) {
            if (this.props.modes[this.applet.getMode()]["Groups"] === undefined) {
                return [];
            }
            return this.props.modes[this.applet.getMode()]["Groups"];
        }

        return [];
    };

    getName = () => {
        return this.applet.getName();
    };

    hideHelp = (event) => {
        event.stopPropagation();
        this.setState({helpAnchor: null});
    };

    initialize = () => {
        let buffer = this.applet.getEnterpriseComponent().getBuffer();

        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }

        if (this.applet.getMode() === "Edit") {
            this.applet.getEnterpriseComponent().setUpdateMode();
        }
        this.prepareLocalState(b);
    };

    inputChangedHandler = (event, inputIdentifier) => {
        if (this.applet.getMode() === "View") return;

        let _value = null;

        if (event !== null) {
            if (this.state.controls[inputIdentifier].type === "Checkbox") {
                _value = event.target.checked;
            } else if (this.state.controls[inputIdentifier].type === "Phone") {
                _value = event.target.value.replace(/\D+/g, "").substring(0, 10);
            } else if (this.state.controls[inputIdentifier].type === "DateTime") {
                _value = event;
            } else if (this.state.controls[inputIdentifier].type === "Date") {
                _value = event;
            } else {
                _value = event.target.value;
            }
        }

        if (_value === "") _value = null;

        let updatedFormElement;
        let updatedObjectForm;

        let results = this.checkValidity(inputIdentifier, _value ?? "");
        let keys = Object.keys(this.state.controls);
        let formResults = {};

        for (let i = 0; i < keys.length; i++) {

            let lengthWarningText = this.state.controls[keys[i]].lengthWarningText;

            if (_value !== null) {
                let attribute = this.applet.getEnterpriseComponent().getAttribute(this.state.controls[keys[i]].enterpriseComponentAttribute);
                if (attribute?.getName() === inputIdentifier) {
                    let length = attribute.getLength();
                    if (length !== null && _value.length === length) {
                        lengthWarningText = length + " characters allowed";
                    } else {
                        lengthWarningText = null;
                    }
                }
            }

            updatedFormElement = updateObject(this.state.controls[keys[i]], {
                value: results[keys[i]].value,
                valid: results[keys[i]].valid,
                touched: results[keys[i]].touched,
                hasBeenConfirmed: results[keys[i]].hasBeenConfirmed,
                lengthWarningText: lengthWarningText,
            });

            formResults[keys[i]] = updatedFormElement;
        }

        updatedObjectForm = updateObject(this.state.controls, formResults);

        let formIsValid = true;
        for (let inputIdentifier in updatedObjectForm) {
            formIsValid = updatedObjectForm[inputIdentifier].valid && formIsValid;
        }

        this.applet.getEnterpriseComponent().setAttributeValue(inputIdentifier, _value);

        let _state = {
            controls: updatedObjectForm,
            formIsValid: formIsValid,
            initialModification: true,
            showInitialModificationWarning: false,
        };

        if (this.isFieldInOnDemandGroup(inputIdentifier) === true && this.state.controls[inputIdentifier].value !== "") {
            _state["showAddAnother"] = true;
            this.setState(_state);
        } else {
            this.setState(_state);
        }
    };

    isFieldInOnDemandGroup = (inputIdentifier) => {
        for (let i = 0; i < this.props.modes[this.applet.getMode()].Groups.length; i++) {
            let group = this.props.modes[this.applet.getMode()].Groups[i];
            if (group["type"] === "ondemand") {
                for (let j = 0; j < group["increments"].length; j++) {
                    let increment = group["increments"][j];

                    for (let k = 0; k < increment.membership.length; k++) {
                        let member = increment.membership[k];
                        if (member.id === inputIdentifier) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    };

    isFormValid = () => {
        // eslint-disable-next-line no-unused-vars
        let formIsValid = true;
        for (let formElementIdentifier in this.state.controls) {

            if (this.state.controls[formElementIdentifier].other?.hidden === true) continue;

            formIsValid = this.state.controls[formElementIdentifier].valid && formIsValid;

            let originalValue = false;

            if (this.applet.getMode() === "Edit") {
                if (this.applet.getEnterpriseComponent().getAttributeValue(formElementIdentifier, "original") === this.state.controls[formElementIdentifier].value) {
                    originalValue = true;
                }
            }

            if (this.state.controls[formElementIdentifier].requiresConfirmation &&
                this.state.controls[formElementIdentifier].hasBeenConfirmed === false &&
                this.state.controls[formElementIdentifier].value !== "" &&
                originalValue === false) {
                formIsValid = formIsValid && false;
            } else {
                formIsValid = formIsValid && true;
            }

        }
        if (this.state.duplicateFileName !== null) {
            formIsValid = false;
        }
        return formIsValid;
    };

    isEmpty(obj) {
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    isFormTouched = () => {
        // eslint-disable-next-line no-unused-vars
        for (let formElementIdentifier in this.state.controls) {
            if (this.state.controls.hasOwnProperty(formElementIdentifier)) {
                if (this.state.controls[formElementIdentifier].touched === true) {
                    return true;
                }
            }
        }
        if (this.state.attachmentsTouched === true) return true;

        return false;
    };

    makeControls = () => {
        let controlsForMode = [];
        let groupCounter = 0;

        let groups = this.getGroups();
        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if (group["type"] === "ondemand") {
                if (this.applet.getMode() === "View") {
                    groupCounter = 0;
                } else if (this.applet.getMode() === "Edit") {
                    groupCounter = 0;
                    this.setState({showAddAnother: true});
                } else groupCounter = 1;


                for (let j = 0; j < group["increments"].length; j++) {
                    let increment = group["increments"][j];

                    controlsForMode.push(...increment.membership);

                    let memberPresent = false;
                    for (let t = 0; t < increment["membership"].length; t++) {
                        let member = increment["membership"][t];
                        let control = this.props.controls[member.id];
                        let value = this.applet.getEnterpriseComponent().getAttributeValue(control.enterpriseComponentAttribute);
                        if (value !== null && value !== "") memberPresent = true;
                    }
                    if (memberPresent === true) groupCounter++;
                }
                if (groupCounter === group["increments"].length + 1) groupCounter = group["increments"].length;
            } else {
                controlsForMode.push(...group.membership);
            }
        }

        let controls = {};
        for (let i = 0; i < controlsForMode.length; i++) {
            controls[controlsForMode[i].id] = this.props.controls[controlsForMode[i].id];
        }
        return {controls: controls, groupCounter: groupCounter};
    };

    modeChanged = () => {
    };

    newHandler = () => {
        this.applet.setMode("New");
    };

    nextRecordHandler = () => {
        this.applet.nextRecord();
    };

    onDemandDecrementHandler = (group) => {
        if (this.state.groupCounter !== 1) {
            let increments = group["increments"];
            let increment = increments[this.state.groupCounter - 1];

            let formResults = {};
            for (let j = 0; j < increment["membership"].length; j++) {
                let member = increment["membership"][j];

                let control = this.state.controls[member.id];
                let enterpriseComponentAttribute = control.enterpriseComponentAttribute;
                this.applet.getEnterpriseComponent().setAttributeValue(enterpriseComponentAttribute, null);

                let data = {
                    value: null,
                    touched: true,
                };
                formResults[member.id] = updateObject(this.state.controls[member.id], data);
            }
            let updatedObjectForm = updateObject(this.state.controls, formResults);

            this.setState({controls: updatedObjectForm, groupCounter: this.state.groupCounter - 1, showAddAnother: true, initialModification: true});
        }
    };

    onDemandIncrementHandler = (group) => {
        this.setState({groupCounter: this.state.groupCounter + 1, showAddAnother: false});
    };

    patientNotificationOptionalHandler = () => {

        const enabled = !this.state.patientNotificationOptionalChecked;

        let updatedFormElement;
        let updatedObjectForm;

        let formResults = {};

        updatedFormElement = updateObject(this.state.controls["EnableTurbidityNotifications"], {
            value: enabled,
            valid: true,
            touched: true,
        });

        formResults["EnableTurbidityNotifications"] = updatedFormElement;

        let cumulativeErrors = {...this.state.errors};

        if (enabled) {
            cumulativeErrors["TurbidityNotificationLevel_Required"] = "Field must contain a value to enable notifications";
            formResults["TurbidityNotificationLevelA"] = updateObject(this.state.controls["TurbidityNotificationLevelA"], {
                touched: true,
                valid: this.state.controls["TurbidityNotificationLevelA"].value !== "",
                errors: [{
                    validator: "Required",
                    value: null,
                    errorText: "Field must contain a value to enable notifications.",
                }],
            });

            cumulativeErrors["NotifyAfterInstances_Required"] = "Field must contain a value to enable notifications";
            formResults["NotifyAfterInstancesA"] = updateObject(this.state.controls["NotifyAfterInstancesA"], {
                touched: true,
                valid: this.state.controls["NotifyAfterInstancesA"].value !== "",
                errors: [{
                    validator: "Required",
                    value: null,
                    errorText: "Field must contain a value to enable notifications.",
                }],
            });
        } else {
            delete cumulativeErrors["TurbidityNotificationLevel_Required"];
            formResults["TurbidityNotificationLevelA"] = updateObject(this.state.controls["TurbidityNotificationLevelA"], {
                touched: false,
                valid: true,
                errors: [],
            });

            delete cumulativeErrors["NotifyAfterInstances_Required"];
            formResults["NotifyAfterInstancesA"] = updateObject(this.state.controls["NotifyAfterInstancesA"], {
                touched: false,
                valid: true,
                errors: [],
            });
        }

        updatedObjectForm = updateObject(this.state.controls, formResults);

        this.applet.getEnterpriseComponent().setAttributeValue("EnableTurbidityNotifications", enabled);

        this.setState({controls: updatedObjectForm, showInitialModificationWarning: false, initialModification: true, patientNotificationOptionalChecked: enabled, errors: cumulativeErrors});
    };

    prepareLocalState = (row) => {
        let c = this.makeControls();
        let controls = c.controls;
        let groupCounter = c.groupCounter;

        if (this.applet.getMode() === "Save") {
            this.setState({controls: {}, groupCounter: 0, insertBackgroundOperation: true});
        } else if (this.applet.getMode() === "View") {
            let keys = Object.keys(controls);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (controls[key]["csp"] === undefined) {
                    controls[key]["csp"] = {};
                }
                if (controls[key]["ip"] === undefined) {
                    controls[key]["ip"] = {};
                }

                let value = row[key] === null || row[key] === undefined ? "" : row[key];
                controls[key]["value"] = typeof value === "boolean" ? value : value.toString();
                controls[key]["valid"] = true;
                controls[key]["touched"] = false;
                controls[key]["showSpinner"] = false;
                controls[key]["passwordVisible"] = false;
                controls[key]["csp"]["disabled"] = true;
                controls[key]["ip"]["readOnly"] = true;
            }
            this.setState({controls: controls, groupCounter: groupCounter, insertBackgroundOperation: false});
        } else if (this.applet.getMode() === "Edit") {
            let keys = Object.keys(controls);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (controls[key]["csp"] === undefined) {
                    controls[key]["csp"] = {};
                }
                if (controls[key]["ip"] === undefined) {
                    controls[key]["ip"] = {};
                }

                let value = row[controls[key].enterpriseComponentAttribute] === null || row[controls[key].enterpriseComponentAttribute] === undefined ? "" : row[controls[key].enterpriseComponentAttribute];
                controls[key]["value"] = typeof value === "boolean" ? value : value.toString();
                controls[key]["valid"] = true;
                controls[key]["touched"] = false;
                controls[key]["showSpinner"] = false;
                controls[key]["passwordVisible"] = false;

                if (controls[key].enterpriseComponentAttribute !== null) {
                    let attribute = this.applet.getEnterpriseComponent().getAttribute(controls[key].enterpriseComponentAttribute);
                    if (attribute !== undefined) {
                        if (attribute.getLength() !== null) {
                            controls[key]["ip"]["maxLength"] = attribute.getLength();
                        }
                        if (attribute.getNoUpdate() !== null) {
                            if (controls[key]["csp"]["disabled"] === undefined) {
                                controls[key]["csp"]["disabled"] = attribute.getNoUpdate();
                            }
                            controls[key]["ip"]["readOnly"] = attribute.getNoUpdate();
                        }
                        if (attribute.getRequired() === true) {
                            let found = false;
                            for (let t = 0; t < controls[key]["errors"].length; t++) {
                                let err = controls[key]["errors"][t];
                                if (err.validator === "Required") found = true;
                            }
                            if (!found) {
                                controls[key]["errors"].push({
                                    validator: "Required",
                                    value: null,
                                    errorText: "Field must contain a value.",
                                });
                            }
                        }
                    }
                }

                if (controls[key]["requiresConfirmation"]) {
                    controls[key]["hasBeenConfirmed"] = true;
                    controls[key]["confirmedValue"] = value.toString();
                }
            }
            this.setState({controls: controls, formIsValid: true, groupCounter: groupCounter, insertBackgroundOperation: false});
        } else if (this.applet.getMode() === "New") {
            let keys = Object.keys(controls);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                controls[key]["showSpinner"] = false;

                if (controls[key]["csp"] === undefined) {
                    controls[key]["csp"] = {};
                }
                if (controls[key]["ip"] === undefined) {
                    controls[key]["ip"] = {};
                }

                let value = "";
                if (controls[key].type === "Date" || controls[key].type === "Datetime") {
                    value = null;
                }
                if (controls[key].enterpriseComponentAttribute !== null) {
                    let attribute = this.applet.getEnterpriseComponent().getAttribute(controls[key].enterpriseComponentAttribute);
                    if (attribute !== undefined) {
                        if (attribute.getDefaultValue() !== null) {
                            value = attribute.getDefaultValue();
                        }
                        if (attribute.getLength() !== null) {
                            controls[key]["ip"]["maxLength"] = attribute.getLength();
                        }
                    }
                }
                if (controls[key].default !== undefined) {
                    if (controls[key].default["type"] === "Literal") {
                        value = controls[key].default["value"];
                    } else if (controls[key].default["type"] === "Computed") {
                        if (controls[key].default["value"] === "Now") {
                            let moment = require("moment");

                            let stillUtc = moment.utc();
                            value = moment(stillUtc).local().format("YYYY-MM-DDTHH:mm");
                        } else if (controls[key].default["value"] === "User USID") {
                            value = this.props.appContext.sessionContext.userUSID;
                        } else if (controls[key].default["value"] === "Dynamic Lookup") {
                            controls[key]["showSpinner"] = true;

                            this.retrieveDefaultValueAsDynamicLookup(this.props.appContext.sessionContext.clinicUSID, controls, key);
                        }
                    }
                    this.applet.getEnterpriseComponent().setAttributeValue(controls[key].enterpriseComponentAttribute, value);
                }

                controls[key]["value"] = value;

                let valid = true;
                if (controls[key].enterpriseComponentAttribute !== null) {
                    let attribute = this.applet.getEnterpriseComponent().getAttribute(controls[key].enterpriseComponentAttribute);
                    if (attribute !== undefined) {
                        if (attribute.getRequired() === true) {
                            valid = false;

                            let found = false;
                            for (let t = 0; t < controls[key]["errors"].length; t++) {
                                let err = controls[key]["errors"][t];
                                if (err.validator === "Required") found = true;
                            }
                            if (!found) {
                                controls[key]["errors"].push({
                                    validator: "Required",
                                    value: null,
                                    errorText: "Field must contain a value.",
                                });
                            }
                        }
                    }
                }
                controls[key]["valid"] = valid;
                controls[key]["touched"] = false;
                controls[key]["passwordVisible"] = false;

                if (controls[key]["requiresConfirmation"]) {
                    controls[key]["hasBeenConfirmed"] = false;
                    controls[key]["confirmedValue"] = null;
                }
            }
            this.setState({controls: controls, formIsValid: true, groupCounter: groupCounter, insertBackgroundOperation: false});
        }
    };

    previousRecordHandler = () => {
        this.applet.previousRecord();
    };

    renderAssistiveText = (formElement) => {
        if (formElement.csp.disabled) {
            return <Typography className={this.props.classes.assistiveText}>Disabled</Typography>;
        }
        // Remove Optional all page
        // if (!formElement.csp.required && formElement.type !== "Checkbox" || false) {
        //     return <Typography className={this.props.classes.assistiveText}>Optional</span>;
        // }
        if (this.state.controls[formElement.id].lengthWarningText !== null && this.state.controls[formElement.id].lengthWarningText !== undefined) {
            return <Typography className={this.props.classes.assistiveText}>{this.state.controls[formElement.id].lengthWarningText}</Typography>;
        }
        return null;
    };

    renderPatientNotificationGroup = (group) => {
        let formElements = [];

        if (this.state.patientNotificationOptionalChecked === true) {
            let membership = group["membership"];
            for (let i = 0; i < membership.length; i++) {
                let f = this.state.controls[membership[i].id];
                if (f === undefined) console.log("Warning: Cannot find " + membership[i].id);
                else {
                    formElements.push(this.getField(this.state.controls[membership[i].id]));
                }
            }
        }

        let helpContent = null;
        if (this.state.helpAnchor !== null) {
            helpContent = (
                <ClickAwayListener onClickAway={this.hideHelp}>
                    <Popper id={1} style={{zIndex: "4000"}} open={this.state.controls["EnableTurbidityNotifications"].id === this.state.helpFormElementID} placement="right" anchorEl={this.state.helpAnchor}>
                        <Alert severity="info">
                            <>{this.state.controls["EnableTurbidityNotifications"].help.map(element => (<React.Fragment key={400}><span key={400}>{element.helpText}</span><br /></React.Fragment>))}</>
                        </Alert>
                    </Popper>
                </ClickAwayListener>
            );
        }

        return (
            <Grid key={group.identifier} item container spacing={2} style={{marginBottom: "20px", padding: "5px 5px 5px 5px", border: "1px solid #EEEEEE"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "86%"}}>
                    <div>
                        <FormControlLabel
                            label="Enable turbidity notifications"
                            control={
                                <>
                                    <Checkbox
                                        key="optional"
                                        id="optional"
                                        onChange={(event) => this.patientNotificationOptionalHandler(event, "optional")}
                                        checked={this.applet.getEnterpriseComponent().getAttributeValue("EnableTurbidityNotifications")}
                                    />
                                </>
                            }
                            style={{
                                marginBottom: this.state.patientNotificationOptionalChecked === true ? "1em" : undefined,
                            }}
                        />
                    </div>
                    <div style={{marginLeft: "-20px", marginTop: "-12px"}}>
                        <IconButton style={{paddingLeft: "-20px", paddingTop: "-10px"}} onClick={(event) => this.showHelp(event, this.state.controls["EnableTurbidityNotifications"])}><Help color="primary" /></IconButton>
                        {helpContent}
                    </div>
                </div>
                {formElements}
            </Grid>
        );
    };

    renderPreviousRecord = () => {
        if (this.applet.getSelectedRecordNumber() > 0) {
            return (
                <Button onClick={this.previousRecordHandler}><img src={LeftArrow} width="25px" height="25px" alt="Previous Record" title="Previous Record" /></Button>
            );
        } else {
            return null;
        }
    };

    renderStandardGroup = (group) => {
        let formElements = [];
        let membership = group["membership"];
        for (let i = 0; i < membership.length; i++) {
            let f = this.state.controls[membership[i].id];
            if (f === undefined) console.log("Warning: Cannot find " + membership[i].id);
            else {
                formElements.push(this.getField(this.state.controls[membership[i].id]));
            }
        }

        return (
            <>
                {group["title"] !== undefined ? <div style={{marginLeft: "-13px", marginTop: "-15px"}}><DialogTitle>{group["title"]}</DialogTitle></div> : null}
                <Grid key={group.identifier} style={{marginBottom: "5px"}} item container spacing={2}>
                    {formElements}
                </Grid>
            </>
        );
    };

    renderCheckboxGroup = (group) => {
        let formElements = [];
        let membership = group["membership"];
        let helperText = null;

        for (let i = 0; i < membership.length; i++) {
            let f = this.state.controls[membership[i].id];
            if (f === undefined) console.log("Warning: Cannot find " + membership[i].id);
            else {
                formElements.push(this.getField(this.state.controls[membership[i].id]));
            }

            let keys = Object.keys(this.state.errors);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (key.includes(membership[i].id)) {
                    helperText = this.state.errors[key];
                }
            }
        }

        let error = (
            <Grid key={group.identifier + "_error"} item style={{marginTop: "-10px", marginBottom: "10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography style={{fontSize: "13px", color: "#E74C3C", marginLeft: "5px", marginTop: "20px"}}>{helperText}</Typography>
            </Grid>
        );

        if (this.isFormTouched() === false || this.state.showErrors === false || helperText === null) {
            error = null;
        }

        return (
            <React.Fragment key={group.identifier}>
                <Grid key={group.identifier} item container spacing={2}>
                    {formElements}
                </Grid>
                {error}
            </React.Fragment>
        );
    };

    renderOnDemandGroup = (group) => {
        let formElements = [];
        let increments = group["increments"];
        for (let i = 0; i < this.state.groupCounter; i++) {
            let increment = increments[i];

            for (let j = 0; j < increment["membership"].length; j++) {
                let member = increment["membership"][j];

                let renderGroupRemoveButton = false;

                if (i === this.state.groupCounter - 1 && i !== 0 && j === increment["membership"].length - 1) {
                    renderGroupRemoveButton = true;
                }
                formElements.push(this.getField(this.state.controls[member.id], renderGroupRemoveButton, group));
            }
        }

        let action = (
            <CCButton
                variant="addAnother"
                startIcon={<AddCircleOutline />}
                onClick={() => this.onDemandIncrementHandler(group)}>
                Add Another
            </CCButton>
        );
        if (this.state.showAddAnother === false || this.state.groupCounter === increments.length) {
            action = (
                <CCButton
                    key="key1"
                    variant="addAnother"
                    disabled={true}
                    startIcon={<AddCircleOutline variant="secondary" />}
                    onClick={() => this.onDemandIncrementHandler(group)}>
                    Add Another
                </CCButton>
            );
        }
        if (this.applet.getMode() === "View") {
            action = null;
        }
        return (
            <React.Fragment key={group.identifier}>
                <Grid key={group.identifier} item container spacing={2}>
                    {formElements}
                </Grid>
                {action}
            </React.Fragment>
        );
    };

    renderAttachmentsGroup = (group) => {
        let buttonTitle = "Add Attachment";
        if (group.selector !== null) {
            buttonTitle = group.selector;
        }
        let action = (
            <CCButton
                variant="addAttachment"
                startIcon={this.state.showAddAttachments === true ? <HighlightOff /> : <AddCircleOutline />}
                onClick={() => {
                    this.setState({showAddAttachments: !this.state.showAddAttachments});
                }}>
                {this.state.showAddAttachments === true ? "Done Adding Attachments" : buttonTitle}
            </CCButton>
        );
        if (this.props.modes[this.applet.getMode()]["AllowAttachments"] !== true || this.applet.getMode() === "View") {
            action = null;
        }

        let attachment_ec;
        if (this.applet.getEnterpriseComponent().getName() === "EscalationLab") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("LabAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "EscalationTreatment") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("TreatmentAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "EscalationSymptom") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("SymptomAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "EscalationNote") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("NoteAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "PatientS0" || this.applet.getEnterpriseComponent().getName() === "Patient") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("PatientAttachment");
        }

        let attachments = [];
        for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
            if (attachment_ec.getBuffer()[i]["__operation"] === "new") continue;
            if (attachment_ec.getBuffer()[i]["ParentUSID"] !== this.applet.getEnterpriseComponent().getAttributeValue("USID")) continue;

            let icon = <Attachment style={{height: "25px", width: "25px"}} color="primary" />;
            attachments.push(
                <Grid key={i} item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "left"}}>
                            <IconButton onClick={() => this.setState({
                                attachmentToDownload: {
                                    "USID": attachment_ec.getBuffer()[i]["USID"],
                                    "FileName": this.scrubFileName(attachment_ec.getBuffer()[i]["FileName"]),
                                    "FileSize": attachment_ec.getBuffer()[i]["FileSize"],
                                    "FileType": attachment_ec.getBuffer()[i]["FileType"],
                                },
                            })}>{icon}</IconButton>
                            <Typography style={{marginTop: "15px", fontSize: "13px"}}>{attachment_ec.getBuffer()[i]["FileName"]}</Typography>
                        </div>
                    </div>
                </Grid>,
            );
        }

        if (attachments.length >= 10) {
            action = null;
        }

        let finalContent;
        let multipleAttachmentsWithSameNameError = (
            <Grid key={group.identifier + "_SameNameError"} item container spacing={2}>
                <Grid key={"multipleAttachmentsWithSameNameError"} container item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography style={{fontSize: "13px", color: "#E74C3C"}}>Multiple attachments with same name ({this.state.duplicateFileName}).</Typography>
                </Grid>
            </Grid>
        );
        if (this.state.duplicateFileName === null) multipleAttachmentsWithSameNameError = null;

        let multipleAttachmentsError = (
            <Grid key={group.identifier + "_MultipleAttachmentsError"} item container spacing={2}>
                <Grid key={"multipleAttachmentsError"} container item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography style={{fontSize: "13px", color: "#E74C3C"}}>Please upload one file at a time.</Typography>
                </Grid>
            </Grid>
        );
        if (this.state.multipleAttachments === false) multipleAttachmentsError = null;

        if (this.state.attachmentToDownload === null) {
            finalContent = (
                <React.Fragment key={group.identifier}>
                    {action}
                    {action !== null && this.state.showAddAttachments ? (
                        <Grid key={group.identifier + "_dropzone"} style={{marginBottom: "5px"}} item container spacing={2}>
                            <Grid key={"DropZone"} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div>
                                    <DropzoneArea
                                        onChange={(files) => this.addAttachmentsHandler(files)}
                                        acceptedFiles={["image/jpeg", "application/vnd.ms-excel", "application/msword", "image/png", "image/bmp", "image/tiff", "image/jpg", "image/gif", "application/pdf", "application/zip", "text/csv", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                        showPreviews={false}
                                        filesLimit={1}
                                        maxFileSize={100000000}
                                        showFileNames={true}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}
                    {multipleAttachmentsWithSameNameError}
                    {multipleAttachmentsError}
                    <Grid key={group.identifier + "_list"} item container spacing={2}>
                        <Grid key={"AttachmentList"} container item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {attachments}
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        } else {
            let url = process.env.REACT_APP_API_URL + "/cloudcath/v1/downloads?USID=" + this.state.attachmentToDownload.USID;

            finalContent = (
                <React.Fragment key={group.identifier}>
                    <Grid key={group.identifier} item container spacing={2}>
                        <Grid key={"Confirm"} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: "15px"}}>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                    <Typography style={{margin: "auto", display: "block"}} noWrap={true}>Do you want to download {this.state.attachmentToDownload.FileName}?</Typography>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                    <Attachment style={{height: "100px", width: "100px"}} color="primary" />
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                    <Typography style={{margin: "auto", fontSize: "10px", display: "block", maxWidth: "110px"}} noWrap={true}>{this.state.attachmentToDownload.FileName}</Typography>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", marginBottom: "25px"}}>
                                <CCButton variant="secondary" onClick={() => this.setState({attachmentToDownload: null})}>Cancel</CCButton>
                                <CCButton href={url} onClick={() => this.setState({attachmentToDownload: null})} target="_blank">Download</CCButton>
                            </div>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
        return finalContent;
    };

    render() {
        let buttonHandler = null;
        let objectDetailContent = null;

        if (this.state.fetchBackgroundOperation === false && this.state.controls !== null && this.state.insertBackgroundOperation === false && this.state.updateBackgroundOperation === false) {
            let content = [];

            let groups = this.getGroups();
            for (let i = 0; i < groups.length; i++) {
                let group = groups[i];

                if (group["type"] === "standard") {
                    content.push(this.renderStandardGroup(group));
                } else if (group["type"] === "ondemand") {
                    content.push(this.renderOnDemandGroup(group));
                } else if (group["type"] === "attachments") {
                    content.push(this.renderAttachmentsGroup(group));
                } else if (group["type"] === "patientNotification") {
                    content.push(this.renderPatientNotificationGroup(group));
                } else if (group["type"] === "checkbox") {
                    content.push(this.renderCheckboxGroup(group));
                }
            }

            let initialModificationWarning = null;
            if (this.state.showInitialModificationWarning === true) {
                initialModificationWarning = (
                    <Grid key="warning" item container spacing={2}>
                        <Typography style={{fontSize: "13px", color: "#E74C3C", marginLeft: "5px", marginTop: "20px"}}>No changes made.</Typography>
                    </Grid>
                );
            }

            objectDetailContent = (
                <React.Fragment>
                    <Grid key="id7" container justifyContent="center">
                        <form onSubmit={e => e.preventDefault()}>
                            {content}
                        </form>
                    </Grid>
                    {initialModificationWarning}
                </React.Fragment>
            );

            if (this.state.attachmentToDownload === null) {
                buttonHandler = (
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        {this.applet.getMode() === "New" || this.applet.getMode() === "Edit" ? (
                            <>
                                {this.props.saveAndNewAction && (
                                    <CCButton
                                        disabled={this.evaluateSaveDisabledStatus()}
                                        variant="secondary"
                                        animate={this.state.updateBackgroundOperation === true || this.state.insertBackgroundOperation === true}
                                        onClick={this.saveAndOpenNewHandler}
                                    >
                                        {this.applet.getMode() === "New" ? "+ Add Another" : "+ Add New"}
                                    </CCButton>
                                )}
                                <CCButton
                                    disabled={this.evaluateSaveDisabledStatus()}
                                    animate={this.state.updateBackgroundOperation === true || this.state.insertBackgroundOperation === true}
                                    onClick={() => this.saveHandler()}>
                                    Save
                                </CCButton>
                            </>
                        ) : null}
                    </DialogActions>
                );
            }
        }

        let title;
        if (this.props.title !== null && this.props.title !== undefined) {
            if (this.applet.getMode() === "New") {
                title = "Add " + this.props.title;
            } else if (this.applet.getMode() === "Edit") {
                title = "Edit " + this.props.title;
            } else if (this.applet.getMode() === "Save") {
                title = "Saving... One moment please.";
            } else {
                title = "View " + this.props.title;
            }
        }

        return (
            <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {objectDetailContent || (
                        <div style={{display: "flex", justifyContent: "center", marginBottom: "40px"}}>
                            <img src={spinner} alt="spinner" width={80} height={80} style={{margin: "auto"}} />
                        </div>
                    )}
                </DialogContent>
                {buttonHandler}
            </Dialog>
        );
    }

    saveAndOpenNewHandler = async (e) => {
        await this.setState({saveAndAddNew: true});
        this.saveHandler();
    };

    saveHandler = () => {
        if (this.useRealTimeReportingMethod() === true) {
            this.applet.save();
        } else {
            if (this.state.initialModification === false) {
                this.setState({showInitialModificationWarning: true});
            } else if (this.isFormValid() === true && this.isFormTouched() === false) {
                if (this.state.saveAndAddNew === true) {
                    this.applet.getView().initiateAction(this.props.saveAndNewAction);
                } else {
                    this.applet.cancel();
                }
            } else if (this.isFormValid() === false) {
                let keys = Object.keys(this.state.controls);
                let formResults = {};

                let updatedFormElement;
                let updatedObjectForm;

                for (let i = 0; i < keys.length; i++) {
                    let data = {
                        touched: true,
                    };
                    updatedFormElement = updateObject(this.state.controls[keys[i]], data);
                    formResults[keys[i]] = updatedFormElement;
                }
                updatedObjectForm = updateObject(this.state.controls, formResults);

                this.setState({controls: updatedObjectForm, showErrors: true});
            } else {
                this.applet.save();
            }
        }
    };

    saveDisabledHandler = () => {
        // eslint-disable-next-line no-unused-vars
        let formIsValid = this.isFormValid();
        let touched = false;
        for (let formElementIdentifier in this.state.controls) {
            if (this.state.controls[formElementIdentifier].touched === true) touched = true;
        }
        return !(formIsValid && touched);
    };

    scrubFileName = (fileName) => {
        let fileTypePart = fileName.substring(fileName.lastIndexOf("."));
        let fileNamePart = fileName.substring(0, fileName.lastIndexOf("."));
        fileNamePart = fileNamePart.split(".").join("_");
        fileNamePart = fileNamePart.substring(0, 99);
        return fileNamePart + fileTypePart;
    };

    showHelp = (event, formElement) => {
        this.setState({helpAnchor: event.currentTarget, helpFormElementID: formElement.id});
    };

    showSpinner = (formElement) => {
        if (formElement.showSpinner) {
            return <img style={{position: "relative", top: "20px", left: "0px"}} width="35" height="35" src={spinner} alt="verify" />;
        }
        return null;
    };

    toggleVisibility = (formElement) => {
        let visible = this.state.showPassword;
        let data = {
            passwordVisible: !visible,
        };
        this.setState({showPassword: !visible});
        let updatedFormElement = updateObject(this.state.controls[formElement.id], data);
        let updatedObjectForm = updateObject(this.state.controls, {
            [formElement.id]: updatedFormElement,
        });
        this.setState({controls: updatedObjectForm});
    };

    useRealTimeReportingMethod = () => {
        if (this.state.errorReportingMethod === undefined) {
            return true;
        }
        return this.state.errorReportingMethod === "RealTime";
    };

    validatorFetchInitiated = (formElementId, value) => {
        let updatedFormElement = updateObject(this.state.controls[formElementId], {
            showSpinner: true,
            hasBeenConfirmed: false,
            confirmedValue: value,
        });
        let updatedObjectForm = updateObject(this.state.controls, {
            [formElementId]: updatedFormElement,
        });

        this.setState({controls: updatedObjectForm});
    };

    validatorFetchCompleted = (formElementId, buffer) => {
        let check = false;

        let field = this.state.controls[formElementId];

        let err = field["errors"];

        let cumulativeErrors = {...this.state.errors};

        for (let i = 0; i < err.length; i++) {
            let testCase = err[i];

            if (testCase.validator === "Unique") {
                if (buffer.length > 0) {
                    check = true;
                }

                if (check === true) {
                    cumulativeErrors[field.id + "_" + testCase.validator] = testCase.errorText;
                } else {
                    delete cumulativeErrors[field.id + "_" + testCase.validator];
                }
            }
        }

        let updatedFormElement = updateObject(this.state.controls[formElementId], {
            showSpinner: false,
            valid: !check,
            hasBeenConfirmed: !check,
        });
        let updatedObjectForm = updateObject(this.state.controls, {
            [formElementId]: updatedFormElement,
        });

        this.setState({controls: updatedObjectForm, errors: cumulativeErrors});
    };

    validatorUnique = (value, formElementId) => {
        let validator = new UniqueValidator();

        let field = this.state.controls[formElementId];

        let attribute = this.applet.getEnterpriseComponent().getAttribute(field.enterpriseComponentAttribute);

        validator.validate(this, value, null, field, formElementId, attribute);

        return true;
    };

    defaultValueFetchCompleted = (formElementId, buffer) => {
        let field = this.state.controls[formElementId];

        let attribute = this.applet.getEnterpriseComponent().getAttribute(field.enterpriseComponentAttribute);

        let value = null;

        if (buffer.length > 0) {
            let updatedFormElements = [];
            for (let i = 0; i < attribute.getInboundPickMaps().length; i++) {
                let pickMap = attribute.getPickMaps()[i];
                value = buffer[0][pickMap.getPickListAttribute()];
                this.applet.getEnterpriseComponent().setAttributeValue(pickMap.getAttribute(), value);

                updatedFormElements.push(updateObject(this.state.controls[pickMap.getAttribute()], {
                    showSpinner: false,
                    value: value,
                }));
            }
            let payload = {};

            for (let i = 0; i < updatedFormElements.length; i++) {
                payload[updatedFormElements[i].id] = updatedFormElements[i];
            }

            let updatedObjectForm = updateObject(this.state.controls, payload);

            this.setState({controls: updatedObjectForm});
        }

    };

    retrieveDefaultValueAsDynamicLookup = (value, controls, formElementId) => {
        let dynamicDefaultValue = new DynamicLookupDefaultValue();

        let field = controls[formElementId];

        let attribute = this.applet.getEnterpriseComponent().getAttribute(field.enterpriseComponentAttribute);

        let picklist = attribute.getPicklist();
        picklist.setSearchSpecification({"USID": value});

        dynamicDefaultValue.fetch(this, value, null, field, formElementId, attribute);

        return true;
    };
}

export default withAppContext(withStyles(styles, {withTheme: true})(CloudcathDetail));