import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1PatientIDOCToArray} from "../../../util/utility";
import PatientAREnterpriseComponent from "./PatientAREnterpriseComponent";

class PatientS0EnterpriseComponent extends PatientAREnterpriseComponent {
    performQuery = (start, pageSize, foreignKey) => {
        let params = {
            "Limit": pageSize,
            "Offset": start,
            "Classification": "S0",
        };

        params["Sort"] = this.getFormattedSortSpecification();

        if (this.getSearchSpecification() !== null) {
            let keys = Object.keys(this.getSearchSpecification());
            for (let i = 0; i < keys.length; i++) {
                params[keys[i]] = this.getSearchSpecification()[keys[i]];
            }
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/patients",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.PatientThin",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1PatientIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };
}

export default PatientS0EnterpriseComponent;