import metadataClassFactory from "../../metadata/MetadataClassFactory";
import eoecClassFactory from "../EOECClassFactory";

class EnterpriseObject {
    constructor(name) {
        let metadataClass = metadataClassFactory("eo", name + "EnterpriseObjectMetadata");

        this.metadata = new metadataClass();

        this.enterpriseComponents = {};
        this.relationships = {};

        for (let key in this.metadata.enterpriseComponentMembers) {
            let enterpriseComponentName = this.metadata.enterpriseComponentMembers[key].getEnterpriseComponent();
            let ec = eoecClassFactory("ec", enterpriseComponentName);
            ec.setEnterpriseObject(this);
            this.enterpriseComponents[key] = ec;
        }
        for (let key in this.metadata.enterpriseComponentMembers) {
            if (this.metadata.enterpriseComponentMembers[key].getRelationship() !== null) {
                let relationshipName = this.metadata.enterpriseComponentMembers[key].getRelationship();
                let relationship = eoecClassFactory("rel", relationshipName);


                let r = relationship.getParentEnterpriseComponentName();
                let ec = this.getEnterpriseComponent(r);
                relationship.setParentEnterpriseComponent(ec);
                relationship.setChildEnterpriseComponent(this.getEnterpriseComponent(relationship.getChildEnterpriseComponentName()));
                this.relationships[relationship.getName()] = relationship;
            }
        }

        this.primaryEnterpriseComponent = this.getEnterpriseComponent(this.metadata.parentEnterpriseComponent);
    };

    getName = () => {
        return this.metadata.getName();
    };

    getEnterpriseComponents = () => {
        return this.enterpriseComponents;
    };

    getEnterpriseComponent = (name) => {
        for (let key in this.enterpriseComponents) {
            let ec = this.enterpriseComponents[key];
            if (key === name) {
                return ec;
            }
        }
        return null;
    };

    getChildEnterpriseComponents = (parentEnterpriseComponent) => {
        let relationships = this.getRelationships();

        let out = {};

        for (let key in this.relationships) {
            let relationship = relationships[key];

            let eom = this.getEnterpriseObjectMetadataForEnterpriseComponent(parentEnterpriseComponent.getName());

            if (relationship.getParentEnterpriseComponentName() === eom.getName()) {
                out[relationship.getChildEnterpriseComponent().getName()] = this.getEnterpriseComponent(relationship.getChildEnterpriseComponentName());
            }
        }
        return out;
    };

    getPrimaryEnterpriseComponent = () => {
        return this.primaryEnterpriseComponent;
    };

    isPrimaryEnterpriseComponent = (ec) => {
        return ec.getName() === this.primaryEnterpriseComponent.getName();
    };

    getRelationships = () => {
        return this.relationships;
    };

    getRelationshipForEnterpriseComponent = (ec) => {
        if (ec === null)
            return null;

        for (let key in this.metadata.enterpriseComponentMembers) {
            if (ec.getName() === this.metadata.enterpriseComponentMembers[key].getEnterpriseComponent().getName()) {
                return this.getRelationships()[this.metadata.enterpriseComponentMembers[key].getRelationship().getName()];
            }
        }
        return null;
    };

    getRelationship = (name) => {
        return this.relationships[name];
    };

    getRelationshipForChildEnterpriseComponent = (ec) => {
        for (let key in this.getRelationships()) {
            let relationship = this.getRelationships()[key];

            if (relationship.getChildEnterpriseComponent().getName() === ec.getName()) {
                return relationship;
            }
        }
        return null;
    };

    getRelationshipForParentEnterpriseComponent = () => {
    };

    hasEnterpriseComponent = (name) => {
        return this.getEnterpriseComponent(name) !== null;
    };

    getEnterpriseObjectMetadataForEnterpriseComponent(name) {
        for (let key in this.metadata.enterpriseComponentMembers) {
            let enterpriseComponentName = this.metadata.enterpriseComponentMembers[key].getEnterpriseComponent();

            if (name === enterpriseComponentName) return this.metadata.enterpriseComponentMembers[key];
        }
        return null;
    }
}

export default EnterpriseObject;