import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useAppContext} from "../../App";
import * as Analytics from "../../util/Analytics";

// aws iot-data publish \
//   --topic "cloudcath_web_s1/v1/global" \
//   --payload '{ "type": "Logout" }' \
//   --cli-binary-format raw-in-base64-out
export function MqttLogout() {

    const {mqttClient} = useAppContext();

    const history = useHistory();

    useEffect(() => {

        const callback = (topic, payload, packet) => {
            if (topic === "cloudcath_web_s1/v1/global" && payload?.length > 0) {
                payload = JSON.parse(payload.toString());
                if (payload.type === "Logout") {

                    Analytics.record("Logging Out", {
                        Reason: "MQTT",
                        Voluntary: false,
                    });

                    history.push("/logout");

                    // in case we are in maintenance mode, reload to see static page
                    window.location.reload();
                }
            }
        };

        if (mqttClient) {
            mqttClient.on("message", callback);
            return () => mqttClient.off("message", callback);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mqttClient]);

    return null;
}
