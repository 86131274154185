import {formatIncompletePhoneNumber, parsePhoneNumber} from "libphonenumber-js";

export const Phone = (value) => {
    if (!value) return null;
    try {
        const phoneNumber = parsePhoneNumber(value, "US");
        return phoneNumber.formatNational();
    } catch (e) {
        return value;
    }
};

export const PhoneIncomplete = (value) => {
    if (!value) return null;
    return formatIncompletePhoneNumber(value, "US");
};

export const EmailWithEllipsis = (value, maxLength) => {
    if (!value) return null;
    if (value.length <= maxLength) return value;

    const parts = value.split("@");
    const partLength = Math.floor(maxLength / 2);
    const leftLength = Math.min(parts[0].length, partLength);
    const rightLength = maxLength - leftLength;

    if (parts[0].length > leftLength) {
        parts[0] = `${parts[0].slice(0, leftLength)}\u2026`;
    }

    if (parts[1].length > rightLength) {
        parts[1] = `${parts[1].slice(0, rightLength)}\u2026`;
    }

    return parts[0] + "@" + parts[1];
};
