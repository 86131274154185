import {withRouter} from "react-router-dom";
import {withAppContext} from "../../../App";
import PatientListBaseApplet from "./PatientListBaseApplet";

class PatientListHealthyApplet extends PatientListBaseApplet {
    constructor(props) {
        super(props, {
            name: "PatientListHealthyApplet",
            title: "Monitoring",
        });
    }
}

export default withRouter(withAppContext(PatientListHealthyApplet));
