import {withAppContext} from "../../../App";
import PatientEscalationDeactivation from "../../../components/PatientEscalationDeactivation";

import Applet from "../Applet";

class PatientEscalationDeactivationApplet extends Applet {
    state = {
        Name: "PatientEscalationDeactivationApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <PatientEscalationDeactivation applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientEscalationDeactivationApplet);
