import {Button} from "@material-ui/core";
import React from "react";
import {useStyles} from "../../util/useStyles";
import CCPulse from "./CCPulse";
import css_self from "./css/CCButton.module.scss";
import "./css/CCButton.scss";

export default function CCButton({
    variant, // primary | secondary | red | yellow | green
    animate, // true | false
    margin, // none | normal
    className,
    children,
    ...props
}) {

    const classes = useStyles();

    let variantClass;
    if (variant === "primary") variantClass = classes.basicButton;
    else if (variant === "secondary") variantClass = classes.secondaryButton;
    else if (variant === "addAnother") variantClass = classes.addAnother;
    else if (variant === "addAttachment") variantClass = classes.addAttachment;
    else if (variant === "red") variantClass = classes.redButton;
    else if (variant === "red-outline") variantClass = classes.redOutlinedButton;
    else if (variant === "yellow") variantClass = classes.yellowButton;
    else if (variant === "green") variantClass = classes.greenButton;
    else variantClass = classes.basicButton;

    let {onClick, ...rest} = props;

    if (animate) onClick = null;

    return (
        <Button
            {...{"data-cc-component": "CCButton"}}
            {...{"data-animate": animate}}
            {...{"data-margin": margin ? margin : "normal"}}
            variant="contained"
            className={[
                variantClass,
                ...(className !== undefined ? [className] : []),
            ].join(" ")}
            onClick={onClick}
            {...rest}
        >
            <span style={animate ? {visibility: "hidden"} : undefined}>{children}</span>
            {animate ? <CCPulse className={css_self.Pulse} /> : undefined}
        </Button>
    );
}
