import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, InputAdornment, TextField, Typography, withStyles} from "@material-ui/core";
import {Error} from "@material-ui/icons";
import React, {Component} from "react";
import {withAppContext} from "../App";
import spinner from "../assets/Spinner-1s-200px.svg";
import Picklist from "../eoec/picklist/Picklist";
import UniqueValidator from "../eoec/util/UniqueValidator";
import styles from "../theme/styles";
import {getUTCDatePlusCurrentTime, updateObject} from "../util/utility";
import * as validators from "../util/Validators";
import CloudcathSelect from "./CloudcathSelect";
import CCButton from "./common/CCButton";

class AddUser extends Component {
    state = {
        disableEscapeKeyDown: true,
        disableBackdropClick: true,

        controls: {
            UserRole: {
                label: "User Role",
                value: "",
                touched: false,
            },
            Username: {
                label: "Username",
                value: "",
                touched: false,
                helperText: null,
                error: false,
                confirmedValue: "",
                invitationInProgress: false,
                userExistsInClinic: false,
                userExistsInProvider: false,
                mainEmailVerifiedState: null,
                userDoesNotExist: false,
                invitationSaved: false,
                invitationSaving: false,
                enterpriseComponentAttribute: "Username",
            },
        },
        usernamePicklist: new Picklist("Username"),
        userRolePicklist: new Picklist("UserRole"),
    };

    cancel1Handler = (event, _) => {
        const updatedFormElement = updateObject(this.state.controls["Username"], {
            userExistsInClinic: false,
            userExistsInProvider: false,
            invitationInProgress: false,
            userDoesNotExist: false,
            invitationSaved: false,
            invitationSaving: false,
        });
        const updatedForm = updateObject(this.state.controls, {
            "Username": updatedFormElement,
        });

        this.setState({controls: updatedForm});

        this.applet.cancel();
    };

    closeHandler = (event) => {
        event.preventDefault();

        this.setState({showConfirmation: false});
        this.applet.cancel();
    };

    componentDidMount() {
        this.applet.getEnterpriseComponent().addInsertStartedListener(this);
        this.applet.getEnterpriseComponent().addInsertCompletedListener(this);
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;
    };

    enterpriseComponentInsertStarted = () => {
        const updatedFormElement = updateObject(this.state.controls["Username"], {
            userExistsInClinic: false,
            userExistsInProvider: false,
            invitationSaved: false,
            invitationInProgress: false,
            userDoesNotExist: false,
            invitationSaving: true,
        });
        const updatedForm = updateObject(this.state.controls, {
            "Username": updatedFormElement,
        });

        this.setState({controls: updatedForm});
    };

    enterpriseComponentInsertCompleted = (updatedRow) => {
        const updatedFormElement = updateObject(this.state.controls["Username"], {
            userExistsInClinic: false,
            userExistsInProvider: false,
            invitationInProgress: false,
            userDoesNotExist: false,
            invitationSaved: true,
            invitationSaving: false,
        });
        const updatedForm = updateObject(this.state.controls, {
            "Username": updatedFormElement,
        });

        this.applet.getView().initiateAction("RefreshUser");

        this.setState({controls: updatedForm});
    };

    inviteHandler = () => {
        this.applet.setMode("New");
        this.applet.getEnterpriseComponent().setAttributeValue("InvitationStatus", "PENDING");
        this.applet.getEnterpriseComponent().setAttributeValue("InvitationDatetime", getUTCDatePlusCurrentTime());
        this.applet.getEnterpriseComponent().setAttributeValue("ClinicUSID", this.props.appContext.sessionContext.clinicUSID);
        this.applet.getEnterpriseComponent().setAttributeValue("ProviderUserUSID", this.state.controls["Username"].providerUserUSID);
        this.applet.getEnterpriseComponent().setAttributeValue("UserRole", this.state.controls["UserRole"].value);
        this.applet.getEnterpriseComponent().setAttributeValue("Realm", this.props.appContext.sessionContext.realm);
        this.applet.save();
    };

    getName = () => {
        return "AddUser";
    };

    proceedHandler = (event) => {
        event.preventDefault();

        this.applet.setState({Step: "2", Username: this.state.controls.Username.value});
    };

    searchHandler = () => {
        if (this.state.controls.Username.value.length < 8) {
            const updatedFormElement = updateObject(this.state.controls.Username, {
                valid: false,
                helperText: "Username must have at least 8 characters.",
            });
            const updatedForm = updateObject(this.state.controls, {
                "Username": updatedFormElement,
            });

            this.setState({confirmedValue: "", controls: updatedForm});
        } else if (!validators.NoSpecialCharacters(this.state.controls.Username.value)) {
            const updatedFormElement = updateObject(this.state.controls.Username, {
                valid: false,
                helperText: "Username must not have special characters.",
            });
            const updatedForm = updateObject(this.state.controls, {
                "Username": updatedFormElement,
            });

            this.setState({confirmedValue: "", controls: updatedForm});
        } else {
            this.validatorUnique(this.state.controls.Username.value);
        }
    };

    render() {
        let adornments = {};
        let content = null;
        let postInsertion = null;
        let cancelButton;
        let rightButton;

        if (this.state.controls.Username.showSpinner === true) {
            adornments = {
                endAdornment: (
                    <InputAdornment position="end">
                        <img src={spinner} alt="Spinner" style={{width: "35px", height: "35px"}} />
                    </InputAdornment>
                ),
            };
        }

        cancelButton = (
            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                Cancel
            </CCButton>
        );

        rightButton = (
            <CCButton
                variant="green"
                animate={this.state.controls.Username.showSpinner}
                onClick={this.searchHandler}>
                Search
            </CCButton>
        );

        if (this.state.controls.Username.invitationInProgress === true) {
            adornments = {
                startAdornment: (
                    <InputAdornment position="start">
                        <Error color="error" />
                    </InputAdornment>
                ),
            };

            content = (
                <React.Fragment>
                    <Grid key="20" item container style={{width: "71em"}}>
                        <Typography style={{fontSize: "16px", color: "#E74C3C"}}>Invitation already sent. Waiting for reply.</Typography>
                    </Grid>
                    <Grid key="21" item container style={{width: "71em"}}>
                        &nbsp;
                    </Grid>
                </React.Fragment>
            );

            rightButton = (
                <CCButton
                    variant="green"
                    animate={this.state.controls.Username.showSpinner}
                    onClick={this.searchHandler}>
                    Search
                </CCButton>
            );
        } else if (this.state.controls.Username.userExistsInClinic === true) {
            adornments = {
                startAdornment: (
                    <InputAdornment position="start">
                        <Error color="error" />
                    </InputAdornment>
                ),
            };

            content = (
                <React.Fragment>
                    <Grid key="20" item container style={{width: "71em"}}>
                        <Typography style={{fontSize: "16px", color: "#E74C3C"}}>Username already exists in this clinic.</Typography>
                    </Grid>
                    <Grid key="21" item container style={{width: "71em"}}>
                        &nbsp;
                    </Grid>
                </React.Fragment>
            );
        } else if (this.state.controls.Username.mainEmailVerifiedState === "VERIFYING") {
            adornments = {
                startAdornment: (
                    <InputAdornment position="start">
                        <Error color="error" />
                    </InputAdornment>
                ),
            };

            content = (
                <React.Fragment>
                    <Grid key="1" item container style={{width: "71em"}}>
                        <Typography style={{fontSize: "16px", color: "#E74C3C"}}>The indicated username is being used by {this.state.controls.Username.firstName} {this.state.controls.Username.lastName}.</Typography>
                    </Grid>
                    <Grid key="2" item container style={{width: "71em"}}>
                        &nbsp;
                    </Grid>
                    <Grid key="3" item container style={{width: "71em"}}>
                        <Typography style={{fontSize: "16px", color: "#E74C3C", marginTop: "10px"}}>To proceed, this user must have a verified main email address.</Typography>
                    </Grid>
                </React.Fragment>
            );
            rightButton = null;

        } else if (this.state.controls.Username.userExistsInProvider === true) {
            adornments = {
                startAdornment: (
                    <InputAdornment position="start">
                        <Error color="error" />
                    </InputAdornment>
                ),
            };

            content = (
                <React.Fragment>
                    <Grid key="1" item container style={{width: "71em"}}>
                        <Typography style={{fontSize: "16px", color: "#E74C3C"}}>The indicated username is being used by {this.state.controls.Username.firstName} {this.state.controls.Username.lastName}.</Typography>
                    </Grid>
                    <Grid key="2" item container style={{width: "71em"}}>
                        &nbsp;
                    </Grid>
                    <Grid key="3" item container style={{width: "71em"}}>
                        <Typography style={{fontSize: "16px", color: "#E74C3C", marginTop: "10px"}}>Cloudcath requires a username to be unique within a provider. <br />You may invite {this.state.controls.Username.firstName} {this.state.controls.Username.lastName} to join your clinic <br />or select a different username.</Typography>
                    </Grid>
                    <Grid key="4" item container style={{width: "71em"}}>
                        &nbsp;
                    </Grid>
                    <Grid key="5" item container style={{width: "71em"}}>
                        <Typography style={{fontSize: "16px", color: "#E74C3C", marginTop: "10px"}}>If you would like to extend an invitation, please select the role the <br />user will have in this clinic.</Typography>
                    </Grid>
                    <Grid key="6" item container style={{width: "10em"}}>
                        <CloudcathSelect
                            key={"UserRole"}
                            margin={"dense"}
                            fullWidth={true}
                            id={"UserRole"}
                            onChange={(event) => this.userRoleChangedHandler(event, "UserRole")}
                            value={this.state.controls.UserRole.value}
                            variant="outlined"
                            picklist={this.state.userRolePicklist}
                        />
                    </Grid>
                </React.Fragment>
            );

            rightButton = (
                <CCButton
                    variant="green"
                    animate={false}
                    onClick={this.inviteHandler}
                    disabled={this.state.controls.UserRole.value === ""}>
                    Invite
                </CCButton>
            );
        } else if (this.state.controls.Username.invitationSaved === true) {
            postInsertion = (
                <React.Fragment>
                    <Grid key="10" item container style={{width: "71em"}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">An invitation has been sent to {this.state.controls.Username.firstName} {this.state.controls.Username.lastName}.</FormLabel>
                        </FormControl>
                    </Grid>
                </React.Fragment>
            );
            rightButton = null;
            cancelButton = (
                <CCButton variant="secondary" onClick={this.cancel1Handler}>
                    Done
                </CCButton>
            );
        } else if (this.state.controls.Username.userDoesNotExist === true) {
            content = (
                <React.Fragment>
                    <Grid key="10" item container style={{width: "71em"}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Username available. Proceed?</FormLabel>
                        </FormControl>
                    </Grid>
                </React.Fragment>
            );
            rightButton = (
                <CCButton
                    variant="green"
                    animate={false}
                    onClick={this.proceedHandler}>
                    Proceed
                </CCButton>
            );
        } else if (this.state.controls.Username.invitationSaving === true) {
            content = (
                <React.Fragment>
                    <Grid key="10" item container style={{width: "71em"}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Saving...</FormLabel>
                        </FormControl>
                    </Grid>
                </React.Fragment>
            );
            cancelButton = null;
            rightButton = (
                <CCButton
                    variant="green"
                    animate={true}>
                    Proceed
                </CCButton>
            );
        }

        if (this.state.controls.Username.valid === false) {
            adornments = {
                startAdornment: (
                    <InputAdornment position="start">
                        <Error color="error" />
                    </InputAdornment>
                ),
            };
        }

        let username = (
            <Grid key={1} item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <TextField
                    key="Username"
                    margin="dense"
                    InputLabelProps={{shrink: true}}
                    fullWidth={true}
                    helperText={this.state.controls.Username.helperText}
                    error={!this.state.controls.Username.valid && this.state.controls.Username.touched}
                    id="Username"
                    label="Username"
                    onChange={(event) => this.usernameChangedHandler(event, "Username")}
                    value={this.state.controls.Username.value}
                    variant="outlined"
                    inputProps={{maxLength: 100}}
                    InputProps={adornments}
                />
            </Grid>
        );

        let modalContent = (
            <Grid key="30" container justifyContent="center">
                <form onSubmit={e => e.preventDefault()}>
                    <Grid key="31" item container style={{width: "71em"}}>
                        {username}
                    </Grid>
                    {content}
                    {postInsertion}
                </form>
            </Grid>
        );

        let finalContent = (
            <React.Fragment>
                {modalContent}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Add User</DialogTitle>
                    <DialogContent style={{padding: "8px 16px"}}>
                        {finalContent}
                    </DialogContent>
                    <DialogActions>
                        {cancelButton}
                        {rightButton}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    usernameChangedHandler = (event) => {
        let check = validators.NoSpecialCharacters(event.target.value);

        const updatedFormElement = updateObject(this.state.controls["Username"], {
            value: event.target.value,
            touched: true,
            valid: check,
            helperText: null,
            invitationInProgress: false,
            userExistsInClinic: false,
            userExistsInProvider: false,
            userDoesNotExist: null,
            mainEmailVerifiedState: null,
            invitationSaved: false,
        });
        const updatedForm = updateObject(this.state.controls, {
            "Username": updatedFormElement,
        });

        this.setState({confirmedValue: "", controls: updatedForm});
    };

    userRoleChangedHandler = (event) => {
        let displayValue = event.currentTarget.outerText;

        const updatedFormElement = updateObject(this.state.controls["UserRole"], {
            value: event.target.value,
            displayValue: displayValue,
            touched: true,
            valid: true,
        });
        const updatedForm = updateObject(this.state.controls, {
            "UserRole": updatedFormElement,
        });

        this.setState({controls: updatedForm});
    };

    validatorFetchInitiated = (formElementId, value) => {
        let updatedFormElement = updateObject(this.state.controls[formElementId], {
            showSpinner: true,
            invitationInProgress: false,
            userExistsInClinic: false,
            userExistsInProvider: false,
            userDoesNotExist: false,
            mainEmailVerifiedState: null,
            firstName: null,
            lastName: null,
            confirmedValue: value,
        });
        let updatedObjectForm = updateObject(this.state.controls, {
            [formElementId]: updatedFormElement,
        });

        this.setState({controls: updatedObjectForm});
    };

    validatorFetchCompleted = (formElementId, buffer) => {
        let check = false;

        let updatedFormElement;

        if (buffer.length > 0) {
            updatedFormElement = updateObject(this.state.controls[formElementId], {
                showSpinner: false,
                valid: true,
                providerUserUSID: buffer[0]["ProviderUserUSID"],
                invitationInProgress: buffer[0]["InvitationInProgress"],
                userExistsInClinic: buffer[0]["MemberOfClinic"],
                userExistsInProvider: buffer[0]["MemberOfProvider"],
                mainEmailVerifiedState: buffer[0]["MainEmailVerifiedState"],
                userDoesNotExist: buffer[0]["InvitationInProgress"] === false && buffer[0]["MemberOfClinic"] === false && buffer[0]["MemberOfProvider"] === false,
                firstName: buffer[0]["FirstName"],
                lastName: buffer[0]["LastName"],
            });
        } else {
            updatedFormElement = updateObject(this.state.controls[formElementId], {
                showSpinner: false,
                valid: !check,
                patientExistsInClinic: null,
                patientExistsInProvider: null,
                firstName: null,
                lastName: null,
            });
        }

        let updatedObjectForm = updateObject(this.state.controls, {
            [formElementId]: updatedFormElement,
        });

        this.setState({controls: updatedObjectForm});
    };

    validatorUnique = (value) => {
        let validator = new UniqueValidator();

        let field = this.state.controls["Username"];

        let attribute = this.applet.getEnterpriseComponent().getAttribute(field.enterpriseComponentAttribute);

        validator.validate(this, value, null, field, "Username", attribute);

        return true;
    };
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(AddUser),
);