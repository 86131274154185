import PicklistMetadata from "../PicklistMetadata";

class AdditionalEmailPicklistMetadata extends PicklistMetadata {
    constructor() {
        super();

        this.bounded = true;
        this.enterpriseComponent = "AdditionalEmail";
        this.longList = false;
        this.noDelete = true;
        this.noUpdate = true;
        this.noInsert = true;
        this.noMerge = true;
        this.useRealm = false;
        this.searchSpecification = null;
        this.sortSpecification = null;
        this.static = false;
        this.typeField = null;
        this.typeValue = "AdditionalEmail";
        this.typeValue2 = null;
        this.visibilityType = null;
    }
}

export default AdditionalEmailPicklistMetadata;
