import {v4 as uuidv4} from "uuid";
import CloudcathAxios from "../../../util/CloudcathAxios";
import {convertToUTC, V1PatientEscalationIDOCToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class EscalationEnterpriseComponent extends EnterpriseComponent {
    performSingleRecordRefresh = () => {
        if (this.getRecordPointer() === -1) {
            return;
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_escalations",
            json: true,
            params: {
                "USID": this.getBuffer()[this.getRecordPointer()].USID,
            },
            headers: {
                "idoc-request": "v1.PatientEscalation",
            },
        };
        this.notifySingleRecordRefreshStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.refreshEnterpriseComponentSuccess(V1PatientEscalationIDOCToArray(response.data));
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    performQuery = (start, pageSize, foreignKey) => {
        let params = {
            "Limit": pageSize,
            "Offset": start,
            "Sort": this.getFormattedSortSpecification(),
            "PatientUSID": foreignKey,
        };

        let keys = Object.keys(this.getSearchSpecification());
        for (let i = 0; i < keys.length; i++) {
            params[keys[i]] = this.getSearchSpecification()[keys[i]];
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_escalations",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.PatientEscalation",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1PatientEscalationIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientEscalation";
        payload.CloudcathMessage.PatientEscalation = this.preparePUTPayload();

        return payload;
    };

    performUpdate = () => {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "cloudcath/v1/patient_escalations",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientEscalation",
            },
        };
        this.notifyUpdateStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1PatientEscalationIDOCToArray(response.data));

                this.getEnterpriseObject().getEnterpriseComponent("EscalationNote").invalidate();
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let escalation = {};

        let moment = require("moment");

        let patientEnterpriseComponent = this.getEnterpriseObject().getEnterpriseComponent("Patient");

        escalation["USID"] = uuidv4();
        escalation["PatientUSID"] = patientEnterpriseComponent.getAttributeValue("USID");
        escalation["SpokenWithPatient"] = this.getAttributeValue("SpokenWithPatient");
        escalation["PatientHospitalized"] = this.getAttributeValue("PatientHospitalized");
        escalation["ScheduledClinicVisit"] = this.getAttributeValue("ScheduledClinicVisit");
        escalation["EscalationStatus"] = this.getAttributeValue("EscalationStatus");
        escalation["DeviceUSID"] = patientEnterpriseComponent.getAttributeValue("DeviceUSID");
        escalation["AcknowledgedDatetime"] = this.getAttributeValue("AcknowledgedDatetime");
        escalation["EscalationOrigin"] = this.getAttributeValue("EscalationOrigin");
        escalation["HospitalizationDate"] = this.getAttributeValue("HospitalizationDate");
        escalation["CatheterRemoved"] = this.getAttributeValue("CatheterRemoved");
        escalation["CatheterReplaced"] = this.getAttributeValue("CatheterReplaced");
        escalation["TurbidityNotificationLevel"] = parseFloat(this.getAttributeValue("TurbidityNotificationLevel"));
        escalation["StartDatetime"] = convertToUTC(moment.now());
        escalation["EscalationNote"] = this.getAttributeValue("EscalationNote") === "" ? null : this.getAttributeValue("EscalationNote");
        escalation["EscalationNoteType"] = this.getAttributeValue("EscalationNoteType");

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientEscalation";
        payload.CloudcathMessage.PatientEscalation = escalation;

        return payload;
    };

    performInsert = () => {
        let payload = this.enterpriseComponentPostIDOC();

        let options = {
            method: "POST",
            url: "cloudcath/v1/patient_escalations",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientEscalation",
                "idoc-response": "v1.PatientEscalation",
            },
        };
        this.notifyInsertStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1PatientEscalationIDOCToArray(response.data));

                this.getEnterpriseObject().getEnterpriseComponent("EscalationNote").invalidate();
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };
}

export default EscalationEnterpriseComponent;