import {useRef} from "react";

// NOTE: this may be a replacement for useHandler, as it is working for me in MaintenanceBar
// while useHandler is not.... but no time to test everything, so replace things later.

// some callbacks like with MQTT message listeners (backed by EventEmitter) will freeze state values
// and not use the latest unless we use this reducer/callback hack. an example
// would be if the sessionContext state changes, the callback would only ever see
// the values that were a part of the original state, and not any updates.
export function useStatic(f) {
    const callback = useRef(f);
    callback.current = f;
    const mem = useRef((...args) => callback.current(...args));
    return mem.current;
}
