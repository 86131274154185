import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1ProviderUserToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class UsernameEnterpriseComponent extends EnterpriseComponent {
    performQuery = (start, pageSize, foreignKey) => {
        let options = {
            method: "GET",
            url: "cloudcath/v1/check_user_emailphone",
            json: true,
            params: {
                "Username": this.getAttributeValue("Username"),
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1ProviderUserToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };
}

export default UsernameEnterpriseComponent;