import AttachmentEnterpriseComponentMetadata from "./AttachmentEnterpriseComponentMetadata";

class LabAttachmentEnterpriseComponentMetadata extends AttachmentEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "LabAttachment";
    }
}

export default LabAttachmentEnterpriseComponentMetadata;