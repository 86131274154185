import {Dialog, DialogContent, Grid, IconButton, Typography, withStyles} from "@material-ui/core";
import {Attachment} from "@material-ui/icons";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";

class PatientAttachment extends Component {
    state = {
        fetchBackgroundOperation: true,
        buffer: {},
        attachmentToDownload: null,
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;
    }

    componentDidMount() {
        this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("PatientAttachment").addUpdateCompletedListener(this);
        this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("PatientAttachment").addLoadCompletedListener(this);

        this.enterpriseComponentLoadCompleted(this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("PatientAttachment").getBuffer());
    }

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    }

    enterpriseComponentLoadCompleted = (buffer) => {
        let attachments = [];
        for (let i = 0; i < buffer.length; i++) {
            // if (buffer[i]["__operation"] === "new") continue;
            if (buffer[i]["ParentUSID"] !== this.applet.getEnterpriseComponent().getAttributeValue("USID")) continue;
            attachments.push(buffer[i]);
        }
        this.setState({buffer: attachments});
    };

    enterpriseComponentUpdateCompleted = (buffer) => {
        let attachments = [];
        for (let i = 0; i < buffer.length; i++) {
            // if (buffer[i]["__operation"] === "new") continue;
            if (buffer[i]["ParentUSID"] !== this.applet.getEnterpriseComponent().getAttributeValue("USID")) continue;
            attachments.push(buffer[i]);
        }
        this.setState({buffer: attachments, fetchBackgroundOperation: false});
    };

    getName = () => {
        return this.applet.getName();
    };

    render() {
        let attachments = [];

        for (let i = 0; i < this.state.buffer.length; i++) {
            let icon = <Attachment style={{height: "25px", width: "25px"}} color="primary" />;
            attachments.push(
                <Grid key={i} item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "left"}}>
                            <IconButton onClick={() => this.setState({
                                attachmentToDownload: {
                                    "USID": this.state.buffer[i]["USID"],
                                    "FileName": this.scrubFileName(this.state.buffer[i]["FileName"]),
                                    "FileSize": this.state.buffer[i]["FileSize"],
                                    "FileType": this.state.buffer[i]["FileType"],
                                },
                            })}>{icon}</IconButton>
                            <Typography style={{marginTop: "15px", fontSize: "13px"}}>{this.state.buffer[i]["FileName"]}</Typography>
                        </div>
                    </div>
                </Grid>,
            );
        }

        let executeDownloadContent = null;
        if (this.state.attachmentToDownload !== null) {
            let url = process.env.REACT_APP_API_URL + "/cloudcath/v1/downloads?USID=" + this.state.attachmentToDownload.USID;

            executeDownloadContent = (
                <Dialog onClose={(event, reason) => this.setState({attachmentToDownload: null})} open={true} scroll="body">
                    <DialogContent>
                        <Grid key={"identifier_1"} item container spacing={2}>
                            <Grid key={"Confirm"} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: "15px"}}>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                        <Typography style={{margin: "auto", display: "block"}} noWrap={true}>Do you want to download {this.state.attachmentToDownload.FileName}?</Typography>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                        <Attachment style={{height: "100px", width: "100px"}} color="primary" />
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                        <Typography style={{margin: "auto", fontSize: "10px", display: "block", maxWidth: "110px"}} noWrap={true}>{this.state.attachmentToDownload.FileName}</Typography>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", marginBottom: "25px"}}>
                                    <CCButton variant="secondary" onClick={() => this.setState({attachmentToDownload: null})}>Cancel</CCButton>
                                    <CCButton href={url} onClick={() => this.setState({attachmentToDownload: null})} target="_blank">Download</CCButton>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            );
        }

        return (
            <React.Fragment>
                <Grid key={this.props.key} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginBottom: "25px"}}>
                    <Typography className={this.props.classes.patientDetailLabel} style={{marginBottom: 3}}>PD Prescription(s)</Typography>
                    {attachments}
                </Grid>
                {executeDownloadContent}
            </React.Fragment>
        );
    }

    scrubFileName = (fileName) => {
        let fileTypePart = fileName.substring(fileName.lastIndexOf("."));
        let fileNamePart = fileName.substring(0, fileName.lastIndexOf("."));
        fileNamePart = fileNamePart.split(".").join("_");
        fileNamePart = fileNamePart.substring(0, 99);
        return fileNamePart + fileTypePart;
    };
}

export default withAppContext(withStyles(styles, {withTheme: true})(PatientAttachment));