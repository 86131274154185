import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class EscalationSymptomEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "EscalationSymptom";
        this.defaultCacheSize = 50;

        this.attributes["EscalationUSID"] = new EnterpriseComponentAttributeMetadata(
            "EscalationUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AbdominalPain"] = new EnterpriseComponentAttributeMetadata(
            "AbdominalPain", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            false,   // default value
            false,   // computed
        );
        this.attributes["Nausea"] = new EnterpriseComponentAttributeMetadata(
            "Nausea", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            false,   // default value
            false,   // computed
        );
        this.attributes["Fever"] = new EnterpriseComponentAttributeMetadata(
            "Fever", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            false,   // default value
            false,   // computed
        );
        this.attributes["CloudyFluid"] = new EnterpriseComponentAttributeMetadata(
            "CloudyFluid", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            false,   // default value
            false,   // computed
        );
        this.attributes["Vomiting"] = new EnterpriseComponentAttributeMetadata(
            "Vomiting", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            false,   // default value
            false,   // computed
        );
        this.attributes["Other1"] = new EnterpriseComponentAttributeMetadata(
            "Other1", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Other2"] = new EnterpriseComponentAttributeMetadata(
            "Other2", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Other3"] = new EnterpriseComponentAttributeMetadata(
            "Other3", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Other4"] = new EnterpriseComponentAttributeMetadata(
            "Other4", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Other5"] = new EnterpriseComponentAttributeMetadata(
            "Other5", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Note"] = new EnterpriseComponentAttributeMetadata(
            "Note", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1000,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AssociatedNote"] = new EnterpriseComponentAttributeMetadata(
            "AssociatedNote", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1000,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ObservationDate"] = new EnterpriseComponentAttributeMetadata(
            "ObservationDate", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            "^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$",  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ObservationDateDisplay"] = new EnterpriseComponentAttributeMetadata(
            "ObservationDateDisplay", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["EnteredByUSID"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByUSID", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            "AllActiveUsers",  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnteredByFirstName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByFirstName", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["EnteredByLastName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByLastName", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["EnteredByFullName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByFullName", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            200,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
    }
}

export default EscalationSymptomEnterpriseComponentMetadata;