import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1GenericExistenceToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class PatientIdentifierEnterpriseComponent extends EnterpriseComponent {
    performQuery = (start, pageSize, foreignKey) => {
        let options = {
            method: "GET",
            url: "cloudcath/v1/check_user_emailphone",
            json: true,
            params: {
                "PatientIdentifier": this.getAttributeValue("PatientIdentifier"),
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1GenericExistenceToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };
}

export default PatientIdentifierEnterpriseComponent;