import {withAppContext} from "../../../App";
import PatientEscalationHospitalized from "../../../components/PatientEscalationHospitalized";

import Applet from "../Applet";

class PatientEscalationHospitalizedApplet extends Applet {
    state = {
        Name: "PatientEscalationHospitalizedApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <PatientEscalationHospitalized applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientEscalationHospitalizedApplet);
