import {withRouter} from "react-router-dom";
import {withAppContext} from "../../../App";
import PatientListBaseApplet from "./PatientListBaseApplet";

class PatientListUnderWatchApplet extends PatientListBaseApplet {
    constructor(props) {
        super(props, {
            name: "PatientListUnderWatchApplet",
            title: "Under Watch",
            showAlertNotation: true,
        });
    }
}

export default withRouter(withAppContext(PatientListUnderWatchApplet));
