import {Button, IconButton, InputAdornment, Link, MenuItem, Typography} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import * as Analytics from "../../util/Analytics";
import {sleep} from "../../util/Sleep";
import CCInputLabel from "../common/CCInputLabel";
import CCPulse from "../common/CCPulse";
import CCSelect from "../common/CCSelect";
import CCTextField from "../common/CCTextField";
import css_shared from "./css/LoginCommon.module.scss";
import css_self from "./css/LoginMain.module.scss";
import "./css/LoginMain.scss";
import {useLoginContext} from "./Login";

export default function LoginMain() {

    const [submitting, setSubmitting] = useState(false);
    const [errorText, setErrorText] = useState(undefined);
    const [showPassword, setShowPassword] = useState(false);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [clinicIdentifier, setClinicIdentifier] = useState("");

    const [providerClinics, setProviderClinics] = useState(null);
    const [displayedProvider, setDisplayedProvider] = useState("");
    const [displayedProviders, setDisplayedProviders] = useState([]);
    const [displayedClinics, setDisplayedClinics] = useState([]);

    const {
        updatePreSessionState,
        login,
    } = useLoginContext();

    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== "/logout") {
            Analytics.record("Login Viewed");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updatePreSessionState(state => {
            return {
                ...state,
                username: username,
                password: password,
                clinicIdentifier: clinicIdentifier,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password, clinicIdentifier]);

    async function submit() {

        const startAt = Date.now();

        const waitVisualMin = async () => {
            const waitDiff = 2000 - (Date.now() - startAt);
            waitDiff > 0 && (await sleep(waitDiff));
        };

        Analytics.record("Login Submitting");

        setSubmitting(true);

        const response = await login();

        if (response instanceof Error && response.message === "Network Error") {
            setSubmitting(false);
            setErrorText("Connection not available");
            return;
        }

        // maintenance outage is in effect
        if (response.status === 503 && response.data.Code === "CC-CF78-4C2A") {
            window.location.reload();
            return;
        }

        if (response.status !== 200) {

            await waitVisualMin();

            setSubmitting(false);

            if (response.status === 429) {

                if (response.data.Code === "CC-7DD5-2031") {

                    setErrorText("Exceeded 10 failed login attempts in 1 hour.");

                    Analytics.record("Login Failed", {
                        Reason: "Lockout",
                        "Reason Code": response.data.Code,
                        "HTTP Code": response.status,
                    });

                    return;
                }
            }

            if (response.status === 400) {

                if (response.data.Code === "CC-7462-3285") {

                    if (response.data["FailCount"] === 8) {
                        setErrorText("Invalid username or password. 8th consecutive failure. 2 more attempts allowed.");
                    } else if (response.data["FailCount"] === 9) {
                        setErrorText("Invalid username or password. 9th consecutive failure. 1 more attempt allowed.");
                    } else if (response.data["FailCount"] === 10) {
                        setErrorText("Invalid username or password. Account locked.");
                    } else {
                        setErrorText("Invalid username or password.");
                    }

                    Analytics.record("Login Failed", {
                        Reason: "Invalid",
                        "Reason Code": response.data.Code,
                        "HTTP Code": response.status,
                    });

                    return;
                }

                if (response.data.Code === "CC-4301-9C22") {

                    setErrorText("User is deactivated. Please contact administrator.");

                    Analytics.record("Login Failed", {
                        Reason: "Inactive User",
                        "HTTP Code": response.status,
                    });

                    return;
                }
            }

            if (response.status === 401 || response.status === 403) {
                setErrorText("Invalid username or password");
            } else {
                setErrorText("Service temporarily unavailable");
            }

            Analytics.record("Login Failed", {
                Reason: "Unknown",
                "Reason Code": response.data?.Code,
                "HTTP Code": response.status,
            });

            return;
        }

        // multiple providers/clinics
        if (response.data.Code === "CC-172E-C457") {

            setSubmitting(false);

            const providerClinics = response.data["Providers"];

            Object.keys(providerClinics).forEach(k => {
                providerClinics[k] = _.sortBy(providerClinics[k], x => x["ClinicName"]);
            });

            const displayedProviders = Object.keys(providerClinics).sort();
            const displayedProvider = displayedProviders[0];
            const displayedClinics = providerClinics[displayedProvider];

            setProviderClinics(providerClinics);

            setDisplayedProvider(displayedProvider);
            setDisplayedProviders(displayedProviders);

            setClinicIdentifier(displayedClinics[0]["ClinicIdentifier"]);
            setDisplayedClinics(displayedClinics);

            setShowPassword(false);

            return;
        }

        // noinspection UnnecessaryReturnStatementJS
        return;
    }

    let loginFormToPresent = null;

    if (displayedProviders.length === 0) {
        loginFormToPresent = (
            <>
                <Typography data-test-id="Login/text:header" className={css_shared.Title}>Login to your account</Typography>
                <CCInputLabel>Username</CCInputLabel>
                <CCTextField
                    inputProps={{"data-test-id": "Login/input:username"}}
                    value={username}
                    placeholder="Enter your username"
                    onChange={async e => setUsername(e.target.value)}
                />
                <CCInputLabel>Password</CCInputLabel>
                <CCTextField
                    inputProps={{"data-test-id": "Login/input:password"}}
                    value={password}
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    helperText={<span data-test-id="Login/input:password/text:error" className="Mui-error" style={{visibility: errorText ? undefined : "hidden"}}>{errorText}</span>}
                    onChange={async e => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton data-test-id="Login/input:password/button:show" onClick={async () => setShowPassword(!showPassword)} onMouseDown={e => e.preventDefault()}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    data-test-id="Login/button:login"
                    {...{"data-cc-component": "CCLoginButton"}}
                    {...{"data-animate": submitting}}
                    fullWidth
                    disabled={
                        submitting ||
                        username.length === 0 ||
                        password.length === 0 ||
                        (displayedProviders.length > 0 && clinicIdentifier.length === 0)
                    }
                    onClick={() => {
                        Analytics.record("Login Button Click");
                        setErrorText(undefined);
                        submit();
                    }}
                >
                    <span style={submitting ? {visibility: "hidden"} : undefined}>Login</span>
                    {submitting ? <CCPulse data-test-id="Login/button:loginAnimation" className={css_self.Pulse} /> : undefined}
                </Button>
            </>
        );
    } else if (displayedProviders.length === 1) {
        loginFormToPresent = (
            <>
                <Typography data-test-id="Login/text:header" className={css_shared.Title}>Select your Clinic</Typography>
                <CCInputLabel>Clinic</CCInputLabel>
                <CCSelect
                    data-test-id="Login/select:clinic"
                    value={clinicIdentifier}
                    onChange={e => setClinicIdentifier(e.target.value)}
                    inputProps={{"data-test-id": "Login/selectValue:clinic"}}
                    MenuProps={{"data-test-id": "Login/menu:clinic"}}
                >
                    {displayedClinics.map(clinic => (
                        <MenuItem value={clinic.ClinicIdentifier} key={`select-clinic-${clinic.ClinicIdentifier}`}>{clinic.ClinicName}</MenuItem>
                    ))}
                </CCSelect>
                <Button
                    data-test-id="ClinicSelect/button:clinicSelect"
                    {...{"data-cc-component": "CCLoginButton"}}
                    {...{"data-animate": submitting}}
                    fullWidth
                    disabled={
                        submitting ||
                        (displayedProviders.length > 0 && clinicIdentifier.length === 0)
                    }
                    onClick={() => {
                        Analytics.record("Login Button Click");
                        setErrorText(undefined);
                        submit();
                    }}
                >
                    <span style={submitting ? {visibility: "hidden"} : undefined}>Select</span>
                    {submitting ? <CCPulse data-test-id="ClinicSelect/button:loginAnimation" className={css_self.Pulse} /> : undefined}
                </Button>
            </>
        );
    } else if (displayedProviders.length > 1) {
        loginFormToPresent = (
            <>
                <Typography data-test-id="Login/text:header" className={css_shared.Title}>Select your Provider and Clinic</Typography>
                <CCInputLabel>Provider</CCInputLabel>
                <CCSelect
                    data-test-id="Login/select:provider"
                    value={displayedProvider}
                    onChange={e => {
                        setDisplayedProvider(e.target.value);
                        setDisplayedClinics(providerClinics[e.target.value]);
                        setClinicIdentifier(providerClinics[e.target.value][0]["ClinicIdentifier"]);
                    }}
                    inputProps={{"data-test-id": "Login/selectValue:provider"}}
                    MenuProps={{"data-test-id": "Login/menu:provider"}}
                >
                    {displayedProviders.map(provider => (
                        <MenuItem value={provider} key={`select-provider-${provider}`}>{provider}</MenuItem>
                    ))}
                </CCSelect>
                <CCInputLabel>Clinic</CCInputLabel>
                <CCSelect
                    data-test-id="Login/select:clinic"
                    value={clinicIdentifier}
                    onChange={e => setClinicIdentifier(e.target.value)}
                    inputProps={{"data-test-id": "Login/selectValue:clinic"}}
                    MenuProps={{"data-test-id": "Login/menu:clinic"}}
                >
                    {displayedClinics.map(clinic => (
                        <MenuItem value={clinic.ClinicIdentifier} key={`select-clinic-${clinic.ClinicIdentifier}`}>{clinic.ClinicName}</MenuItem>
                    ))}
                </CCSelect>
                <Button
                    data-test-id="ClinicSelect/button:clinicSelect"
                    {...{"data-cc-component": "CCLoginButton"}}
                    {...{"data-animate": submitting}}
                    fullWidth
                    disabled={
                        submitting ||
                        (displayedProviders.length > 0 && clinicIdentifier.length === 0)
                    }
                    onClick={() => {
                        Analytics.record("Login Button Click");
                        setErrorText(undefined);
                        submit();
                    }}
                >
                    <span style={submitting ? {visibility: "hidden"} : undefined}>Select</span>
                    {submitting ? <CCPulse data-test-id="ClinicSelect/button:loginAnimation" className={css_self.Pulse} /> : undefined}
                </Button>
            </>
        );
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            {loginFormToPresent}
            <Typography className={css_self.HelpText}>
                <Link
                    data-test-id="Login/link:trouble-logging-in"
                    variant="inherit"
                    color="inherit"
                    component={RouterLink}
                    to="/help"
                    replace={true}
                    onClick={() => Analytics.record("Login Help Click")}
                >
                    Trouble logging in?
                </Link>
            </Typography>
            {displayedProviders.length > 0 ? (
                <>
                    <Typography className={css_self.HelpText}>
                        <Link
                            data-test-id="Login/link:start-over"
                            variant="inherit"
                            color="inherit"
                            component={RouterLink}
                            to="#"
                            replace={true}
                            onClick={() => window.location.reload()}
                        >
                            Start over
                        </Link>
                    </Typography>
                </>
            ) : null}
        </form>
    );
}
