import RelationshipMetadata from "../RelationshipMetadata";

class EscalationEncounterRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "EscalationEncounter";
        this.parentEnterpriseComponent = "Escalation";
        this.childEnterpriseComponent = "EscalationEncounter";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "Escalation USID";
    }
}

export default EscalationEncounterRelationshipMetadata;