import {Grid, IconButton, InputAdornment, MenuItem, TextField} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import React, {Component} from "react";
import {v4 as uuidv4} from "uuid";
import {withAppContext} from "../App";
import spinner from "../assets/Spinner-1s-200px.svg";
import eoecClassFactory from "../eoec/EOECClassFactory";

class CloudcathSelect extends Component {

    constructor(props) {
        super(props);
        this.pickValues = props.appContext.sessionContext.pickValues;
    }

    state = {
        id: uuidv4(),
        fetchBackgroundOperation: false,
        ec: null,
        buffer: [{
            [this.props.picklist.getTypeValue()]: this.props.value,
            [this.props.picklist.getTypeField()]: "temp",
        }],
        textOption: null,
    };

    componentDidMount() {
        if (this.props.picklist !== null && this.props.picklist.getStatic() === false) {
            let picklistEC = eoecClassFactory("ec", this.props.picklist.getEnterpriseComponent());

            this.setState({ec: picklistEC});

            picklistEC.addLoadStartedListener(this);
            picklistEC.addLoadCompletedListener(this);
            picklistEC.newQuery();
            picklistEC.setSearchSpecification(this.props.picklist.getSearchSpecification());
            picklistEC.setSortSpecification((this.props.picklist.getSortSpecification()));
            picklistEC.executeQuery();
        }

        if (this.props.picklist.getBounded() === false) {
            if (this.props.parent.applet.getMode() === "Edit") {
                let found = false;
                for (let i = 0; i < this.pickValues.length; i++) {
                    let pickInstance = this.pickValues[i];

                    let pickTypeField = this.props.picklist.getTypeField();

                    if (pickInstance[pickTypeField] !== this.props.picklist.getTypeValue()) continue;

                    if (this.props.value === pickInstance["Value"]) {
                        found = true;
                    }
                }
                if (found) {
                    this.setState({textOption: false});
                } else {
                    this.setState({textOption: true});
                }
            } else {
                this.setState({textOption: false});
            }
        } else {
            this.setState({textOption: false});
        }
    }

    enterpriseComponentLoadStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        this.setState({buffer: buffer, fetchBackgroundOperation: false});
    };

    getId() {
        if (this.props.id !== undefined && this.props.id !== null && this.props.id !== "") {
            return this.props.id;
        } else {
            return this.state.id;
        }
    }

    render() {
        let renderSpinner = false;

        let content;

        let selectOptions = [];

        if (this.props.picklist.getAllowNoSelection() === true) {
            let option = {
                value: this.props.picklist.getAllowNoSelectionValue(),
                label: this.props.picklist.getAllowNoSelectionDisplayValue(),
            };
            selectOptions.push(option);
        }

        if (this.props.picklist.getStatic() === true) {
            for (let i = 0; i < this.pickValues.length; i++) {
                let pickInstance = this.pickValues[i];

                let pickTypeField = this.props.picklist.getTypeField();
                if (pickInstance[pickTypeField] !== this.props.picklist.getTypeValue()) continue;

                let option = {
                    value: pickInstance["Value"],
                    label: pickInstance["Name"],
                };
                selectOptions.push(option);
            }
        } else if (this.props.picklist.getStatic() === false && this.state.fetchBackgroundOperation === true) {
            renderSpinner = true;
        } else if (this.props.picklist.getStatic() === false && this.state.fetchBackgroundOperation === false) {
            for (let i = 0; i < this.state.buffer.length; i++) {
                let pickTypeField = this.props.picklist.getTypeField();
                let pickValueField = this.props.picklist.getTypeValue();

                let option = {
                    value: this.state.buffer[i][pickValueField],
                    label: this.state.buffer[i][pickTypeField],
                };
                selectOptions.push(option);
            }
        }

        if (renderSpinner === true) {
            content = (
                <Grid item style={{marginBottom: "1.4em"}}>
                    <img style={{position: "relative", top: "10px", left: "0px"}} width="35" height="35" src={spinner} alt="spinner" />
                </Grid>
            );
        } else {
            const {appContext, defaultOptions, onChange, ...rest} = this.props;
            if (this.props.picklist.getBounded() === true) {
                content = (
                    <TextField
                        select={true}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    elevation: 2,
                                },
                            },
                        }}
                        onChange={onChange}
                        {...rest}
                    >
                        {selectOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            } else if (this.state.textOption !== null) {
                if (this.state.textOption === true) {
                    let cancelButton = (
                        <IconButton style={{marginRight: -12}} onClick={(event) => {
                            event.target.value = "";
                            this.setState({textOption: false});
                            this.props.onChange(event, this.props.id);
                        }}><Close color="primary" /></IconButton>
                    );
                    let adornments = {};
                    adornments["endAdornment"] = (
                        <InputAdornment position="end">
                            {cancelButton}
                        </InputAdornment>
                    );
                    delete rest["InputProps"];

                    content = (
                        <TextField
                            onChange={(event) => this.props.onChange(event, this.props.id)}
                            {...rest}
                            InputProps={adornments}
                        />
                    );
                } else {
                    content = (
                        <TextField
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        elevation: 2,
                                    },
                                },
                            }}
                            onChange={(event) => {
                                if (event.target.value === "Other") {
                                    this.setState({textOption: true});
                                    event.target.value = "";
                                }
                                onChange(event, this.props.id);
                            }}
                            {...rest}
                        >
                            {selectOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    );
                }
            }
        }

        return (
            <React.Fragment key={this.props.key}>
                {content}
            </React.Fragment>
        );
    }
}

export default withAppContext(CloudcathSelect);
