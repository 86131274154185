import React from "react";
import {withAppContext} from "../../../App";
import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class CellCountLabDetailApplet extends Applet {
    state = {
        Name: "CellCountLabDetailApplet",
        Modes: {
            View: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "SampleCollectionDateDisplay",
                            },
                            {
                                id: "Name",
                            },
                            {
                                id: "Leukocytes",
                            },
                            {
                                id: "Neutrophilis",
                            },
                            {
                                id: "Lymphocytes",
                            },
                            {
                                id: "Monocytes",
                            },
                            {
                                id: "Eosinophils",
                            },
                            {
                                id: "RBC",
                            },
                            {
                                id: "ColorAppearance",
                            },
                            {
                                id: "DwellTime",
                            },
                            {
                                id: "DwellVolume",
                            },
                            {
                                id: "EffluentCollectedBy",
                            },
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "AssociatedNote",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            New: {
                AllowAttachments: true,
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "SampleCollectionDate",
                            },
                            {
                                id: "Name",
                            },
                            {
                                id: "Leukocytes",
                            },
                            {
                                id: "Neutrophilis",
                            },
                            {
                                id: "Lymphocytes",
                            },
                            {
                                id: "Monocytes",
                            },
                            {
                                id: "Eosinophils",
                            },
                            {
                                id: "RBC",
                            },
                            {
                                id: "ColorAppearance",
                            },
                            {
                                id: "DwellTime",
                            },
                            {
                                id: "DwellVolume",
                            },
                            {
                                id: "EffluentCollectedBy",
                            },
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "Note",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            Edit: {
                AllowAttachments: true,
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "SampleCollectionDateDisplay",
                            },
                            {
                                id: "Name",
                            },
                            {
                                id: "Leukocytes",
                            },
                            {
                                id: "Neutrophilis",
                            },
                            {
                                id: "Lymphocytes",
                            },
                            {
                                id: "Monocytes",
                            },
                            {
                                id: "Eosinophils",
                            },
                            {
                                id: "RBC",
                            },
                            {
                                id: "ColorAppearance",
                            },
                            {
                                id: "DwellTime",
                            },
                            {
                                id: "DwellVolume",
                            },
                            {
                                id: "EffluentCollectedBy",
                            },
                            {
                                id: "AssociatedNote",
                            },
                            {
                                id: "EnteredByUSID",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
        },
        Layout: {
            totalWidth: "36em",
        },
        Controls: {
            Name: {
                id: "Name",
                enterpriseComponentAttribute: "Name",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                errors: [
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not contain all spaces.",
                    },
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Type",
                    margin: "dense",
                    helperText: null,
                    disabled: true,
                },
                ip: {
                    readOnly: true,
                },
                type: "TextField",
                requiresConfirmation: false,
                default: {
                    type: "Literal",
                    value: "Cell Count",
                },
                other: {
                    hidden: true,
                },
            },
            SampleCollectionDate: {
                id: "SampleCollectionDate",
                enterpriseComponentAttribute: "SampleCollectionDate",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "Date",
                requiresConfirmation: false,
                default: {},
                errors: [
                    {
                        validator: "Required Pattern",
                        value: null,
                        errorText: "Date improperly formatted.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Sample collection date (MM/DD/20YY)",
                    margin: "dense",
                    helperText: null,
                    minDate: this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("StartDatetime"),
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            SampleCollectionDateDisplay: {
                id: "SampleCollectionDateDisplay",
                enterpriseComponentAttribute: "SampleCollectionDateDisplay",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                default: {},
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Sample collection date (MM/DD/20YY)",
                    margin: "dense",
                    helperText: null,
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            Leukocytes: {
                id: "Leukocytes",
                enterpriseComponentAttribute: "Leukocytes",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Integer",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Maximum Value",
                        value: 99999,
                        errorText: "Field must be less than 99999.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Leukocytes",
                    margin: "dense",
                    helperText: null,
                    uom: "WBC/ul",
                },
                ip: {
                    min: 0,
                    max: 99999,
                    type: "number",
                },
                other: {
                    renderAssistiveText: false,
                },
            },
            Neutrophilis: {
                id: "Neutrophilis",
                enterpriseComponentAttribute: "Neutrophilis",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Integer",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Maximum Value",
                        value: 100,
                        errorText: "Field must be less than 100",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Neutrophils (PMN)",
                    margin: "dense",
                    helperText: null,
                    uom: "%",
                },
                ip: {
                    min: 0,
                    max: 100,
                    type: "number",
                },
                other: {
                    renderAssistiveText: false,
                },
            },
            Monocytes: {
                id: "Monocytes",
                enterpriseComponentAttribute: "Monocytes",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Integer",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Maximum Value",
                        value: 100,
                        errorText: "Field must be less than 100.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Monocytes",
                    margin: "dense",
                    helperText: null,
                    uom: "%",
                },
                ip: {
                    min: 0,
                    max: 100,
                },
                other: {
                    renderAssistiveText: false,
                },
            },
            Lymphocytes: {
                id: "Lymphocytes",
                enterpriseComponentAttribute: "Lymphocytes",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Integer",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Maximum Value",
                        value: 100,
                        errorText: "Field must be less than 100.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Lymphocytes",
                    margin: "dense",
                    helperText: null,
                    uom: "%",
                },
                ip: {
                    min: 0,
                    max: 100,
                },
                other: {
                    renderAssistiveText: false,
                },
            },
            Eosinophils: {
                id: "Eosinophils",
                enterpriseComponentAttribute: "Eosinophils",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Integer",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Maximum Value",
                        value: 100,
                        errorText: "Field must be less than 100.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Eosinophils",
                    margin: "dense",
                    helperText: null,
                    uom: "%",
                },
                ip: {
                    min: 0,
                    max: 100,
                },
                other: {
                    renderAssistiveText: false,
                },
            },
            RBC: {
                id: "RBC",
                enterpriseComponentAttribute: "RBC",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Integer",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Maximum Value",
                        value: 99999,
                        errorText: "Field must be less than 99999.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "RBC",
                    margin: "dense",
                    helperText: null,
                    uom: "RBC/ul",
                },
                ip: {
                    min: 0,
                    max: 99999,
                    type: "number",
                },
                other: {
                    renderAssistiveText: false,
                },
            },
            ColorAppearance: {
                id: "ColorAppearance",
                enterpriseComponentAttribute: "ColorAppearance",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Color/Appearance",
                    margin: "dense",
                    helperText: null,
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            DwellTime: {
                id: "DwellTime",
                enterpriseComponentAttribute: "DwellTime",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Optional Pattern",
                        value: null,
                        errorText: "Time improperly formatted.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: "00:00",
                    label: "Dwell Time",
                    margin: "dense",
                    helperText: null,
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            DwellVolume: {
                id: "DwellVolume",
                enterpriseComponentAttribute: "DwellVolume",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Integer",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Maximum Value",
                        value: 99999,
                        errorText: "Field must be less than 99999.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Dwell Volume",
                    margin: "dense",
                    helperText: null,
                    uom: "ml",
                },
                ip: {
                    min: 0,
                    max: 99999,
                },
                other: {
                    renderAssistiveText: false,
                },
            },
            EffluentCollectedBy: {
                id: "EffluentCollectedBy",
                enterpriseComponentAttribute: "EffluentCollectedBy",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Effluent Collected By",
                    margin: "dense",
                    helperText: null,
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            EnteredByUSID: {
                id: "EnteredByUSID",
                enterpriseComponentAttribute: "EnteredByUSID",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                default: {
                    type: "Computed",
                    value: "User USID",
                },
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Entered By:",
                    margin: "dense",
                    helperText: null,
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            EnteredByFullName: {
                id: "EnteredByFullName",
                enterpriseComponentAttribute: "EnteredByFullName",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Entered By:",
                    margin: "dense",
                    helperText: null,
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            Note: {
                id: "Note",
                enterpriseComponentAttribute: "Note",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: true,
                    placeholder: null,
                    label: "Note",
                    margin: "dense",
                    helperText: null,
                    rows: 2,
                },
                ip: {},
                other: {},
            },
            AssociatedNote: {
                id: "AssociatedNote",
                enterpriseComponentAttribute: "AssociatedNote",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: true,
                    placeholder: null,
                    label: "Note",
                    margin: "dense",
                    helperText: null,
                    rows: 2,
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            DropZone: {
                id: "DropZone",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "DropZone",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    margin: "dense",
                },
                ip: {},
            },
        },
    };

    render() {
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="Cell Count"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(CellCountLabDetailApplet);
