class EnterpriseObjectMemberMetadata {
    constructor() {
        this.name = null;
        this.enterpriseComponent = null;
        this.relationship = null;
    };

    getName = () => {
        return this.name;
    };

    getEnterpriseComponent = () => {
        return this.enterpriseComponent;
    };

    getRelationship = () => {
        return this.relationship;
    };

}

export default EnterpriseObjectMemberMetadata;
