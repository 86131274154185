import {withAppContext} from "../../../App";
import PatientEscalationInfectionConfirmed from "../../../components/PatientEscalationInfectionConfirmed";

import Applet from "../Applet";

class PatientEscalationInfectionConfirmedApplet extends Applet {
    state = {
        Name: "PatientEscalationInfectionConfirmedApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <PatientEscalationInfectionConfirmed applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientEscalationInfectionConfirmedApplet);
