import {Component} from "react";

class View extends Component {

    displayOptionsHandler = () => {
        this.startViewAction("showOptions");
    };

    getEnterpriseObject = () => {
        return this.state.enterpriseObject;
    };

    getActivePerspective = () => {
        return this.state.activePerspective;
    };

    initiateAction = (action) => {
        return null;
    };

    setActivePerspective = (perspective, parameters) => {
        return this.setState({activePerspective: perspective, viewActionInProgress: null, options: parameters});
    };

    startViewAction = (action) => {
        this.setState({viewActionInProgress: action});
    };

}

export default View;
