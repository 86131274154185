import {withAppContext} from "../../../App";
import PatientDetailMenu from "../../../components/PatientDetailMenu";

import Applet from "../Applet";

class PatientDetailMenuApplet extends Applet {
    state = {
        Name: "PatientDetailMenuApplet",
    };

    render() {
        return <PatientDetailMenu applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientDetailMenuApplet);
