import React from "react";
import {withAppContext} from "../../../App";

import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class NoteDetailApplet extends Applet {
    state = {
        Name: "NoteDetailApplet",
        Modes: {
            View: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Note",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            New: {
                AllowAttachments: true,
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Note",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            Edit: {
                AllowAttachments: true,
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Note",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
        },
        Layout: {
            totalWidth: "36em",
        },
        Controls: {
            Note: {
                id: "Note",
                enterpriseComponentAttribute: "Note",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                errors: [
                    {
                        validator: "Required",
                        value: null,
                        errorText: "Field must contain a value.",
                    },
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not contain all spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: true,
                    placeholder: null,
                    label: "Note",
                    margin: "dense",
                    rows: 10,
                },
                ip: {},
                type: "TextField",
                requiresConfirmation: false,
            },
            DropZone: {
                id: "DropZone",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "DropZone",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    margin: "dense",
                },
                ip: {},
            },
        },
    };

    render() {
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="Note"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(NoteDetailApplet);
