import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class PatientAlertEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "PatientAlert";

        this.attributes["ClinicUSID"] = new EnterpriseComponentAttributeMetadata(
            "ClinicUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PatientUSID"] = new EnterpriseComponentAttributeMetadata(
            "PatientUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AlertType"] = new EnterpriseComponentAttributeMetadata(
            "AlertType", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            40,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AlertStatus"] = new EnterpriseComponentAttributeMetadata(
            "AlertStatus", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            40,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Realm"] = new EnterpriseComponentAttributeMetadata(
            "Realm", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            15,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
    }
}

export default PatientAlertEnterpriseComponentMetadata;