import {Typography} from "@material-ui/core";
import axios from "axios";
import React, {createContext, useContext, useReducer} from "react";
import {useLocation} from "react-router-dom";
import {useAppContext} from "../../App";
import CloudCathBlueSvg from "../../assets/branding/cloudcath-blue.svg";
import CloudCathWhiteSvg from "../../assets/branding/cloudcath-white.svg";
import * as Analytics from "../../util/Analytics";
import {parseQueryParams} from "../../util/Location";
import {useCssPropertyWindowHeight} from "../../util/useCssProperty";
import * as userAgent from "../../util/UserAgent";
import {useThemeColor} from "../../util/useThemeColor";
import css_self from "./css/Login.module.scss";
import LoginChangePass from "./LoginChangePass";
import LoginHelp from "./LoginHelp";
import LoginMain from "./LoginMain";
import LoginSecure from "./LoginSecure";
import LoginVerifyComms from "./LoginVerifyComms";

export const LoginContext = createContext(null);

export default function Login() {

    const [preSessionState, updatePreSessionState] = useReducer((state, action) => action(state), {});

    const {sessionContext} = useAppContext();

    const location = useLocation();

    const showMobile = userAgent.isMobile();

    const showHelp = location.pathname === "/help" || location.pathname === "/forgotPassword";

    async function login() {

        let preauthToken = localStorage.getItem("preauth.v2");

        if (!preauthToken) {

            const response = await axios({
                method: "GET",
                baseURL: process.env.REACT_APP_API_URL,
                url: "cloudcath/v1/authenticate",
                json: true,
                params: {
                    Type: "preauth",
                },
            }).catch(e => e.response || e);

            if (response instanceof Error) {
                return response;
            }

            if (response.status !== 200) {
                return response;
            }

            preauthToken = response.data.Token;
            localStorage.setItem("preauth.v2", preauthToken);
        }

        const queryParams = parseQueryParams(location.search);

        const response = await axios({
            method: "POST",
            baseURL: process.env.REACT_APP_API_URL,
            url: "cloudcath/v1/authenticate",
            json: true,
            headers: {
                "X-Cloudcath-AccessToken": preauthToken,
            },
            data: {
                ...(preSessionState.clinicIdentifier ? {
                    ClinicIdentifier: preSessionState.clinicIdentifier,
                } : {}),
                Username: preSessionState.username,
                Password: preSessionState.password,
                ...(preSessionState.secureLogin?.secureCode ? {
                    SecureCode: preSessionState.secureLogin.secureCode,
                    RememberDevice: preSessionState.secureLogin.rememberDevice,
                } : {}),
                ...(queryParams.patientUSID ? {
                    PatientUSID: queryParams.patientUSID,
                } : {}),
            },
        }).catch(e => e.response || e);

        if (response instanceof Error || response.status !== 200) {
            return response;
        }

        // multiple clinics
        if (response.data.Code === "CC-172E-C457") {
            return response;
        }

        // change initial password
        if (response.data.Code === "CC-A91E-1B98") {

            Analytics.record("Login Incomplete", {
                "Change Password": true,
            });

            updatePreSessionState(state => {
                return {
                    ...state,
                    showChangePass: true,
                    showVerifyComms: false,
                    showSecureLogin: false,
                    accessToken: response.data.AccessToken,
                };
            });

            return response;
        }

        // verify email/phone
        if (response.data.Code === "CC-41E8-9122") {

            Analytics.record("Login Incomplete", {
                "Verify Comms": true,
            });

            updatePreSessionState(state => {
                return {
                    ...state,
                    showChangePass: false,
                    showVerifyComms: true,
                    showSecureLogin: false,
                    accessToken: response.data.AccessToken,
                    verifyComms: response.data.Identities,
                };
            });

            return response;
        }

        // secure login
        if (response.data.Code === "CC-BC06-384F") {

            Analytics.record("Login Incomplete", {
                "Secure Login": true,
            });

            updatePreSessionState(state => {
                return {
                    ...state,
                    showChangePass: false,
                    showVerifyComms: false,
                    showSecureLogin: true,
                    accessToken: response.data.AccessToken,
                    secureLogin: response.data.Identities,
                };
            });

            return response;
        }

        sessionContext.update({
            accessToken: response.data.AccessToken,
            identityToken: response.data.IdentityToken,
            refreshToken: response.data.RefreshToken,
            optionalCharacteristics: response.data.OptionalCharacteristics,
            pickValues: response.data.PickValues,
            patientAlerts: response.data.PatientAlerts,
            actStudy: response.data.ACTStudy,
            userConfig: response.data.UserConfig,
        });

        Analytics.record("Logged In");

        return response;
    }

    function abortLogin() {
        updatePreSessionState(() => ({}));
    }

    useCssPropertyWindowHeight([]);

    let themeColor = undefined;
    if (!(showMobile && (showHelp || preSessionState.showChangePass || preSessionState.showVerifyComms || preSessionState.showSecureLogin))) {
        themeColor = "#edeff3";
    }
    useThemeColor(themeColor, [showMobile, showHelp, preSessionState]);

    function branding({type, ...props}) {
        return (
            <div className={css_self.Branding}>
                <div style={type === "Panel" ? {margin: "auto"} : undefined}>
                    <img
                        className={css_self.CloudCathLogo}
                        src={type === "Panel" ? CloudCathWhiteSvg : CloudCathBlueSvg}
                        alt="cloudcath.svg"
                    />
                    <Typography className={css_self.CloudCathText}>CloudCath</Typography>
                </div>
            </div>
        );
    }

    const LoginContextExports = {
        preSessionState,
        updatePreSessionState,
        abortLogin,
        login,
    };

    return (
        <LoginContext.Provider value={LoginContextExports}>
            {showMobile && (showHelp || preSessionState.showChangePass || preSessionState.showVerifyComms || preSessionState.showSecureLogin) ? (
                <div className={css_self.MobileFullscreen}>
                    {branding({type: "Fullscreen"})}
                    {showHelp ? (
                        <LoginHelp />
                    ) : preSessionState.showChangePass ? (
                        <LoginChangePass />
                    ) : preSessionState.showVerifyComms ? (
                        <LoginVerifyComms />
                    ) : preSessionState.showSecureLogin ? (
                        <LoginSecure />
                    ) : undefined}
                </div>
            ) : (
                <div className={showMobile ? css_self.MobilePanels : css_self.Desktop}>
                    <div className={css_self.Panels}>
                        <div className={css_self.WavePanel}>
                            {!showMobile ? branding({type: "Panel"}) : undefined}
                            <div className={css_self.Wave} />
                        </div>
                        {showMobile ? branding({type: "Panel"}) : undefined}
                        <div className={css_self.MainPanel}>
                            {showHelp ? (
                                <LoginHelp />
                            ) : preSessionState.showChangePass ? (
                                <LoginChangePass />
                            ) : preSessionState.showVerifyComms ? (
                                <LoginVerifyComms />
                            ) : preSessionState.showSecureLogin ? (
                                <LoginSecure />
                            ) : (
                                <LoginMain />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </LoginContext.Provider>
    );
}

export function useLoginContext() {
    return useContext(LoginContext);
}
