import CloudcathTable from "../../../../src/components/CloudcathTable";
import {withAppContext} from "../../../App";
import Applet from "../Applet";

class PatientEscalationTreatmentListApplet extends Applet {

    createTreatment = (type) => {
        this.getView().initiateAction(type);
    };

    selectTreatmentHandler = (index) => {
        this.selectHandler(index);

        this.getView().initiateAction("ViewTreatment");
    };

    editTreatmentHandler = (event, index) => {
        event.stopPropagation();

        this.selectHandler(index);

        this.getView().initiateAction("EditTreatment");
    };

    render() {
        return <CloudcathTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Medications"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               headerRenderer={this.state.HeaderRenderer}
                               selectors={this.state.Selectors}
                               tableHeaderType="Type2"
                               sort={this.state.Sort} />;
    }

    state = {
        Name: "PatientEscalationTreatmentListApplet",
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        HeaderRenderer: "Treatment Header Renderer",
        Selectors: {
            "AddTreatment": this.createTreatment,
            "Refresh": this.refreshHandler,
            "Select": this.selectTreatmentHandler,
            "Edit": this.editTreatmentHandler,
        },
        Placement: {
            "xs": [
                {
                    id: "Treatment",
                },
                {
                    id: "Date",
                },
            ],
            "xl": [
                {
                    id: "Treatment",
                },
                {
                    id: "Date",
                },
                {
                    id: "IsSelfAdministered",
                },
                {
                    id: "Attachment",
                },
                {
                    id: "Edit",
                },
            ],
        },
        Columns: {
            "Treatment": {
                id: "Treatment",
                width: "250px",
                cellRenderer: "Treatment Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Treatment",
                align: "left",
            },
            "Date": {
                id: "Date",
                width: "220px",
                cellRenderer: "Start Date To End Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Date",
                align: "left",
            },
            "IsSelfAdministered": {
                id: "IsSelfAdministered",
                width: "140px",
                cellRenderer: "Boolean Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Self administered?",
                align: "left",
            },
            "Attachment": {
                id: "Attachment",
                width: "100px",
                cellRenderer: "Attachment Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Attachment(s)",
                align: "center",
            },
            "Edit": {
                id: "Edit",
                width: "20px",
                cellRenderer: "Edit Cell",
                header: "enabled",
                headerRenderer: "Blank Header",
                headerTitle: "",
                align: "left",
            },
        },
        Sort: [
            {
                "identifier": 1,
                "default": true,
                "visible": false,
                "options": {
                    "RowIdentifier": "DESC",
                },
            },
        ],
    };

}

export default withAppContext(PatientEscalationTreatmentListApplet);
