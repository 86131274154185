import RelationshipMetadata from "../RelationshipMetadata";

class EscalationTreatmentAttachmentRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "EscalationTreatmentAttachment";
        this.parentEnterpriseComponent = "EscalationTreatment";
        this.childEnterpriseComponent = "TreatmentAttachment";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "ParentUSID";
        this.invalidateChildren = false;
    }
}

export default EscalationTreatmentAttachmentRelationshipMetadata;