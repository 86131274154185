import {Grid, IconButton, Link, Typography, withStyles} from "@material-ui/core";
import {Edit, Error} from "@material-ui/icons";
import React from "react";
import {withAppContext} from "../../../App";
import spinner from "../../../assets/Spinner-1s-200px.svg";
import styles from "../../../theme/styles";
import * as userAgent from "../../../util/UserAgent";

import Applet from "../Applet";

class PatientEscalationDetailApplet extends Applet {
    state = {
        fetchBackgroundOperation: false,
        buffer: {},
        expandAlerts: false,
        featureFlagForEditing: true,
    };

    componentDidMount() {
        this.getEnterpriseComponent().addLoadStartedListener(this);
        this.getEnterpriseComponent().addLoadCompletedListener(this);
        this.getEnterpriseComponent().addSingleRecordRefreshStartedListener(this);
        this.getEnterpriseComponent().addSingleRecordRefreshCompletedListener(this);
        this.getEnterpriseComponent().addUpdateStartedListener(this);
        this.getEnterpriseComponent().addUpdateCompletedListener(this);

        this.enterpriseComponentLoadCompleted(this.getEnterpriseComponent().getBuffer());
    }

    componentWillUnmount() {
        this.unmountComponent();
    }

    enterpriseComponentLoadStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.getEnterpriseComponent().getRecordPointer(), this.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.getEnterpriseComponent().getRecordPointer(), this.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    enterpriseComponentSingleRecordRefreshStarted = () => {
        this.setState({buffer: {}, fetchBackgroundOperation: true});
    };

    enterpriseComponentSingleRecordRefreshCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.getEnterpriseComponent().getRecordPointer(), this.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    renderDateOfHospitalization = () => {
        let editHospitalizationButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={() => this.props.view.initiateAction("HospitalizePatient")} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );

        let editHospitalizationContent = (
            <React.Fragment>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Date of hospitalization</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["HospitalizationDate"]}</Typography>
            </React.Fragment>
        );

        let dateOfHospitalization;
        if (this.props.appContext.sessionContext.optionalCharacteristics === null) {
            if (this.state.featureFlagForEditing) {
                dateOfHospitalization = (
                    <Grid key="at" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3">
                            {editHospitalizationContent}
                        </div>
                    </Grid>
                );
            } else {
                dateOfHospitalization = (
                    <Grid key="at" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3" className={this.props.classes.episodeEditDiv}>
                            {editHospitalizationButton}
                            {editHospitalizationContent}
                        </div>
                    </Grid>
                );
            }
        } else {
            dateOfHospitalization = (
                <Grid key="at" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    {editHospitalizationContent}
                </Grid>
            );
        }
        if (this.state.buffer["HospitalizationDate"] === null) {
            dateOfHospitalization = null;
        }

        return dateOfHospitalization;
    };

    renderDateOfHemodialysis = () => {
        let editHemodialysisButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={() => this.props.view.initiateAction("HemodialysisPatient")} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );

        if (this.state.featureFlagForEditing) {
            editHemodialysisButton = null;
        }

        let editHemodialysisContent = (
            <React.Fragment>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Date of hemodialysis</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["HemodialysisDate"]}</Typography>
            </React.Fragment>
        );

        let dateOfHemodialysis;
        if (this.props.appContext.sessionContext.optionalCharacteristics === null) {
            if (this.state.featureFlagForEditing) {
                dateOfHemodialysis = (
                    <Grid key="au" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3">
                            {editHemodialysisContent}
                        </div>
                    </Grid>
                );
            } else {
                dateOfHemodialysis = (
                    <Grid key="au" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3" className={this.props.classes.episodeEditDiv}>
                            {editHemodialysisButton}
                            {editHemodialysisContent}
                        </div>
                    </Grid>
                );
            }
        } else {
            dateOfHemodialysis = (
                <Grid key="au" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    {editHemodialysisContent}
                </Grid>
            );
        }
        if (this.state.buffer["HemodialysisDate"] === null) {
            dateOfHemodialysis = null;
        }
        return dateOfHemodialysis;
    };

    renderDateOfDeactivation = () => {
        let editDeactivationButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={() => this.props.view.initiateAction("EscalationDeactivate")} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );

        let editDeactivationContent = (
            <React.Fragment>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Date of deactivation</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["DeactivationDate"]}</Typography>
            </React.Fragment>
        );

        let dateOfDeactivation;
        if (this.props.appContext.sessionContext.optionalCharacteristics === null) {
            if (this.state.featureFlagForEditing) {
                dateOfDeactivation = (
                    <Grid key="av" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3">
                            {editDeactivationContent}
                        </div>
                    </Grid>
                );
            } else {
                dateOfDeactivation = (
                    <Grid key="av" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3" className={this.props.classes.episodeEditDiv}>
                            {editDeactivationButton}
                            {editDeactivationContent}
                        </div>
                    </Grid>
                );
            }
        } else {
            dateOfDeactivation = (
                <Grid key="av" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    {editDeactivationContent}
                </Grid>
            );
        }

        if (this.state.buffer["DeactivationDate"] === null) {
            dateOfDeactivation = null;
        }
        return dateOfDeactivation;
    };

    renderDateOfExpiration = () => {
        let editExpirationButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={() => this.props.view.initiateAction("EscalationExpire")} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );

        let editExpirationContent = (
            <React.Fragment>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Date of expiration</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["ExpirationDate"]}</Typography>
            </React.Fragment>
        );

        let dateOfExpiration;
        if (this.props.appContext.sessionContext.optionalCharacteristics === null) {
            if (this.state.featureFlagForEditing) {
                dateOfExpiration = (
                    <Grid key="aw" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3">
                            {editExpirationContent}
                        </div>
                    </Grid>
                );
            } else {
                dateOfExpiration = (
                    <Grid key="aw" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3" className={this.props.classes.episodeEditDiv}>
                            {editExpirationButton}
                            {editExpirationContent}
                        </div>
                    </Grid>
                );
            }
        } else {
            dateOfExpiration = (
                <Grid key="aw" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    {editExpirationContent}
                </Grid>
            );
        }
        if (this.state.buffer["ExpirationDate"] === null) {
            dateOfExpiration = null;
        }
        return dateOfExpiration;
    };

    renderDateOfResolution = () => {
        let dateOfResolution = (
            <Grid key="ax" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Date of infection resolution</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["InfectionResolutionDate"]}</Typography>
            </Grid>
        );
        if (this.state.buffer["InfectionResolutionDate"] === null) {
            dateOfResolution = null;
        }
        return dateOfResolution;
    };

    renderDateOfDischarge = () => {
        let editDischargeButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={() => this.props.view.initiateAction("DischargeHospital")} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );

        let editDischargeContent = (
            <React.Fragment>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Date of discharge</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["DischargeDate"]}</Typography>
            </React.Fragment>
        );

        let dateOfDischarge;
        if (this.props.appContext.sessionContext.optionalCharacteristics === null) {
            if (this.state.featureFlagForEditing) {
                dateOfDischarge = (
                    <Grid key="ay" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3">
                            {editDischargeContent}
                        </div>
                    </Grid>
                );
            } else {
                dateOfDischarge = (
                    <Grid key="ay" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3" className={this.props.classes.episodeEditDiv}>
                            {editDischargeButton}
                            {editDischargeContent}
                        </div>
                    </Grid>
                );
            }
        } else {
            dateOfDischarge = (
                <Grid key="ay" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    {editDischargeContent}
                </Grid>
            );
        }
        if (this.state.buffer["DischargeDate"] === null) {
            dateOfDischarge = null;
        }
        return dateOfDischarge;
    };

    renderDateOfTransplant = () => {
        let editTransplantButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={() => this.props.view.initiateAction("EscalationTransplant")} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );

        let editTransplantContent = (
            <React.Fragment>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Date of transplant</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["TransplantDate"]}</Typography>
            </React.Fragment>
        );

        let dateOfTransplant;
        if (this.props.appContext.sessionContext.optionalCharacteristics === null) {
            if (this.state.featureFlagForEditing) {
                dateOfTransplant = (
                    <Grid key="az" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3">
                            {editTransplantContent}
                        </div>
                    </Grid>
                );
            } else {
                dateOfTransplant = (
                    <Grid key="az" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3" className={this.props.classes.episodeEditDiv}>
                            {editTransplantButton}
                            {editTransplantContent}
                        </div>
                    </Grid>
                );
            }
        } else {
            dateOfTransplant = (
                <Grid key="az" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    {editTransplantContent}
                </Grid>
            );
        }
        if (this.state.buffer["TransplantDate"] === null) {
            dateOfTransplant = null;
        }
        return dateOfTransplant;
    };

    renderWasCatheterRemoved = () => {
        let wasCatheterRemoved = null;

        if (this.state.buffer["CatheterRemovedReadOnly"] !== null) {
            wasCatheterRemoved = (
                <Grid key="bd" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography key="1" className={this.props.classes.patientDetailLabel}>Was catheter removed?</Typography>
                    <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["CatheterRemovedReadOnly"]}</Typography>
                </Grid>
            );
        }
        return wasCatheterRemoved;
    };

    renderWasCatheterReplaced = () => {
        let wasCatheterReplaced;
        if (this.state.buffer["CatheterReplacedReadOnly"] !== null) {
            wasCatheterReplaced = (
                <Grid key="be" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography key="1" className={this.props.classes.patientDetailLabel}>Was catheter replaced</Typography>
                    <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["CatheterReplacedReadOnly"]}</Typography>
                </Grid>
            );
        }
        return wasCatheterReplaced;
    };

    renderDateOfInfectionDiagnosis = () => {
        let editInfectionDiagnosisButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={() => this.props.view.initiateAction("InfectionConfirmed")} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );

        let editInfectionDiagnosisContent = (
            <React.Fragment>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Date of infection diagnosis</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["InfectionDiagnosisDate"]}</Typography>
            </React.Fragment>
        );

        let dateOfInfectionDiagnosis;
        if (this.props.appContext.sessionContext.optionalCharacteristics === null) {
            if (this.state.featureFlagForEditing) {
                dateOfInfectionDiagnosis = (
                    <Grid key="as" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3">
                            {editInfectionDiagnosisContent}
                        </div>
                    </Grid>
                );
            } else {
                dateOfInfectionDiagnosis = (
                    <Grid key="as" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3" className={this.props.classes.episodeEditDiv}>
                            {editInfectionDiagnosisButton}
                            {editInfectionDiagnosisContent}
                        </div>
                    </Grid>
                );
            }
        } else {
            dateOfInfectionDiagnosis = (
                <Grid key="as" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    {editInfectionDiagnosisContent}
                </Grid>
            );
        }
        if (this.state.buffer["InfectionDiagnosisDate"] === null) {
            dateOfInfectionDiagnosis = null;
        }
        return dateOfInfectionDiagnosis;
    };

    renderDateOfNonInfectionDiagnosis = () => {
        let editNonInfectionDiagnosisButton = (
            <div id="hover-edit-button">
                <IconButton aria-label="Options" size="small" onClick={() => this.props.view.initiateAction("NonInfectionDiagnosed")} style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                    <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                </IconButton>
            </div>
        );

        let editNonInfectionDiagnosisContent = (
            <React.Fragment>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Date of non-infection diagnosis</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["NonInfectionDiagnosisDate"]}</Typography>
            </React.Fragment>
        );

        let dateOfNonInfectionDiagnosis;
        if (this.props.appContext.sessionContext.optionalCharacteristics === null) {
            if (this.state.featureFlagForEditing) {
                dateOfNonInfectionDiagnosis = (
                    <Grid key="bf" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3">
                            {editNonInfectionDiagnosisContent}
                        </div>
                    </Grid>
                );
            } else {
                dateOfNonInfectionDiagnosis = (
                    <Grid key="bf" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div key="3" className={this.props.classes.episodeEditDiv}>
                            {editNonInfectionDiagnosisButton}
                            {editNonInfectionDiagnosisContent}
                        </div>
                    </Grid>
                );
            }
        } else {
            dateOfNonInfectionDiagnosis = (
                <Grid key="bf" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    {editNonInfectionDiagnosisContent}
                </Grid>
            );
        }
        if (this.state.buffer["NonInfectionDiagnosisDate"] === null) {
            dateOfNonInfectionDiagnosis = null;
        }

        return dateOfNonInfectionDiagnosis;
    };

    renderSpokenWithPatient = () => {
        let spokenWithPatient;
        if (this.state.buffer["SpokenWithPatientReadOnly"] !== null) {
            spokenWithPatient = (
                <Grid key="bb" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography key="1" className={this.props.classes.patientDetailLabel}>Spoken w/ patient?</Typography>
                    <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["SpokenWithPatientReadOnly"]}</Typography>
                </Grid>
            );
        }
        return spokenWithPatient;
    };

    renderScheduledClinicVisit = () => {
        let scheduledClinicVisit;
        if (this.state.buffer["ScheduledClinicVisitReadOnly"] !== null) {
            scheduledClinicVisit = (
                <Grid key="bc" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography key="1" className={this.props.classes.patientDetailLabel}>Scheduled clinic visit</Typography>
                    <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["ScheduledClinicVisitReadOnly"]}</Typography>
                </Grid>
            );
        }
        return scheduledClinicVisit;
    };

    renderIsPatientComingBackToCurrentClinic = () => {
        let isPatientComingBackToCurrentClinic = null;
        if (this.state.buffer["HospitalizationOutcome"] === null) {
            isPatientComingBackToCurrentClinic = null;
        } else if (this.state.buffer["HospitalizationOutcome"] === "Patient is returning to the current PD clinic") {
            isPatientComingBackToCurrentClinic = (
                <Grid key="ba" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography key="1" className={this.props.classes.patientDetailLabel}>Is patient coming back to current clinic?</Typography>
                    <Typography key="2" className={this.props.classes.patientDetailData}>Yes</Typography>
                </Grid>
            );
        } else {
            isPatientComingBackToCurrentClinic = (
                <Grid key="ba" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography key="1" className={this.props.classes.patientDetailLabel}>Is patient coming back to current clinic?</Typography>
                    <Typography key="2" className={this.props.classes.patientDetailData}>No</Typography>
                </Grid>
            );
        }
        return isPatientComingBackToCurrentClinic;
    };

    renderDateOfAcknowledgement = () => {
        let dateOfAcknowledgment = (
            <Grid key="aaa" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Notification acknowledgment</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["AcknowledgedDatetime"]}</Typography>
                {this.state.buffer["EscalationOrigin"] === "Manual" ? (
                    <Typography key="3" className={this.props.classes.patientDetailData}>Manual episode created</Typography>
                ) : null}
            </Grid>
        );
        if (this.state.buffer["AcknowledgedDatetime"] === null) {
            dateOfAcknowledgment = null;
        }

        return dateOfAcknowledgment;
    };

    renderTypeOfInfection = () => {
        let typeOfInfectionValues = [];
        if ((this.state.buffer["TypeOfInfectionExitSiteReadOnly"] ?? null) !== null) {
            typeOfInfectionValues.push(this.state.buffer["TypeOfInfectionExitSiteReadOnly"]);
        }
        if ((this.state.buffer["TypeOfInfectionTunnelReadOnly"] ?? null) !== null) {
            typeOfInfectionValues.push(this.state.buffer["TypeOfInfectionTunnelReadOnly"]);
        }
        if ((this.state.buffer["TypeOfInfectionPeritonitisReadOnly"] ?? null) !== null) {
            typeOfInfectionValues.push(this.state.buffer["TypeOfInfectionPeritonitisReadOnly"]);
        }
        let typeOfInfection = (
            <Grid key="ar" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Type of infection</Typography>
                {typeOfInfectionValues.map(value => (
                    <>
                        <Typography key={value} className={this.props.classes.patientDetailData}>{value}</Typography>
                    </>
                ))}
            </Grid>
        );
        if (typeOfInfectionValues.length === 0) {
            typeOfInfection = null;
        }

        return typeOfInfection;
    };

    renderInfectionStatus = () => {
        let infectionStatusValues = [];
        if ((this.state.buffer["BacterialInfectionConfirmedReadOnly"] ?? null) !== null) {
            infectionStatusValues.push(this.state.buffer["BacterialInfectionConfirmedReadOnly"]);
        }
        if ((this.state.buffer["FungalInfectionConfirmedReadOnly"] ?? null) !== null) {
            infectionStatusValues.push(this.state.buffer["FungalInfectionConfirmedReadOnly"]);
        }
        let infectionStatus = (
            <Grid key="aq" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Infection status</Typography>
                {infectionStatusValues.map(value => (
                    <Typography key={value} className={this.props.classes.patientDetailData}>{value}</Typography>
                ))}
            </Grid>
        );
        if (infectionStatusValues.length === 0) {
            infectionStatus = null;
        }
        return infectionStatus;
    };

    renderCellCountStatus = () => {
        let cellCountStatusValue = this.state.buffer["CellCountStatus"];
        let cellCountStatus = null;
        if (this.state.buffer["CellCountStatus"] !== null && this.state.buffer["CellCountStatus"] !== undefined) {
            let leukocytes;
            let neutrophilis;
            if (cellCountStatusValue.includes("|")) {
                leukocytes = cellCountStatusValue.substring(0, cellCountStatusValue.indexOf("|"));
                neutrophilis = cellCountStatusValue.substring(cellCountStatusValue.indexOf("|") + 1, cellCountStatusValue.length);
            } else {
                leukocytes = cellCountStatusValue;
                neutrophilis = "";
            }
            cellCountStatus = (
                <Grid key="ap" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Typography key="1" className={this.props.classes.patientDetailLabel}>Cell count & differential status</Typography>
                    <Typography key="2" className={this.props.classes.patientDetailData}>{leukocytes}<br />{neutrophilis}</Typography>
                </Grid>
            );
        }
        return cellCountStatus;
    };

    renderCellCultureStatus = () => {
        let cultureStatus = (
            <Grid key="ao" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Culture status</Typography>
                <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["CultureStatus"]}</Typography>
            </Grid>
        );
        if (this.state.buffer["CultureStatus"] === null) {
            cultureStatus = null;
        }
        return cultureStatus;
    };

    renderDateOfEscalationStart = () => {
        let dateOfEscalationStart = (
            <Grid key="bbb" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography key="1" className={this.props.classes.patientDetailLabel}>Initial turbidity notification</Typography>
                {this.state.buffer["EscalationOrigin"] === "Manual" ?
                    <Typography key="2" className={this.props.classes.patientDetailData}>N/A</Typography> :
                    <Typography key="2" className={this.props.classes.patientDetailData}>{this.state.buffer["StartDatetime"]}</Typography>}
            </Grid>
        );
        if (this.state.buffer["StartDatetime"] === null || this.state.buffer["EscalationOrigin"] === "Manual") {
            dateOfEscalationStart = null;
        }
        return dateOfEscalationStart;
    };

    renderAlerts = () => {
        const CDSE4731 = (<Typography className={this.props.classes.alertText}>We noticed that it has been <b>48</b> hours since the Cell Count labs have been ordered. Would you like to input the results?</Typography>);
        const CDSE4732 = (<Typography className={this.props.classes.alertText}>We noticed that it has been <b>48</b> hours since the Culture labs have been ordered. Would you like to input the results?</Typography>);
        const CDSE4733 = (<Typography className={this.props.classes.alertText}>We noticed that it has been <b>72</b> hours since [other lab name] has been ordered. Would you like to input the results?</Typography>);
        const CDSE4734 = (<Typography className={this.props.classes.alertText}>We noticed it has been more than <b>10</b> days since infection treatment monitoring began. Would you like to update the patient status?</Typography>);
        const CDSE4735 = (<Typography className={this.props.classes.alertText}>We noticed it has been more than <b>72</b> hours since the infection episode started. Would you like to update the infection diagnosis?</Typography>);
        const CDSE4736 = (<Typography className={this.props.classes.alertText}>We noticed it has been more than <b>48</b> hours since the infection episode started. Would you like to order any labs?</Typography>);
        const CDSE4737 = (<Typography className={this.props.classes.alertText}>We noticed it has been more than <b>48</b> hours since the infection episode started. Would you like to add patient symptoms?</Typography>);
        const CDSE4738 = (<Typography className={this.props.classes.alertText}>We noticed it has been more than <b>24</b> hours since the infection diagnosis was confirmed. Would you like to order any treatments?</Typography>);
        const CDSE4739a = (<Typography className={this.props.classes.alertText}>We noticed it has been more than <b>72</b> hours since the infection diagnosis was confirmed. Would you like to follow-up with any patient symptom updates?</Typography>);
        const CDSE4739b = (<Typography className={this.props.classes.alertText}>We noticed it has been more than <b>5</b> days since the last patient symptom follow-up. Would you like to follow-up with any patient symptom updates?</Typography>);
        const CDSE47310 = (<Typography className={this.props.classes.alertText}>We noticed it has been more than <b>14</b> days since the infection diagnosis was confirmed. Would you like to resolve the infection episode?</Typography>);
        const CDSE47311 = (<Typography className={this.props.classes.alertText}>We noticed it has been more than <b>5</b> days since the patient was hospitalized. Would you like to update patient hospitalization status?</Typography>);

        let alerts = [
            [this.state.buffer["CDSE4731"], CDSE4731],
            [this.state.buffer["CDSE4732"], CDSE4732],
            [this.state.buffer["CDSE4733"], CDSE4733],
            [this.state.buffer["CDSE4734"], CDSE4734],
            [this.state.buffer["CDSE4735"], CDSE4735],
            [this.state.buffer["CDSE4736"], CDSE4736],
            [this.state.buffer["CDSE4737"], CDSE4737],
            [this.state.buffer["CDSE4738"], CDSE4738],
            [this.state.buffer["CDSE4739a"], CDSE4739a],
            [this.state.buffer["CDSE4739b"], CDSE4739b],
            [this.state.buffer["CDSE47310"], CDSE47310],
            [this.state.buffer["CDSE47311"], CDSE47311],
        ].filter(x => !!x[0])
            .map(([_, element], index) => (
                <Grid key={index} item style={{marginTop: "5px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", padding: 7, minHeight: 40, backgroundColor: "rgba(253, 231, 118, 0.5)"}}>
                        <Error style={{width: "28px", height: "28px", fill: "#f1c40f", marginRight: 10}} />
                        {element}
                    </div>
                </Grid>
            ));

        if (userAgent.isMobile() === true) {
            alerts = null;
        }
        let alertContent = (<div style={{height: "10px"}} />);
        if (this.state.expandAlerts === true) {
            alertContent = (
                <div key="alerts" style={{marginBottom: "15px"}}>
                    <Grid key="0a" item style={{marginBottom: "5px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: "32px", backgroundColor: "#edeff3"}}>
                            <Typography className={this.props.classes.alertLink}>
                                <Link href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({expandAlerts: !this.state.expandAlerts});
                                }}>
                                    Click here to collapse
                                </Link>
                            </Typography>
                        </div>
                    </Grid>
                    {alerts}
                </div>
            );
        } else if (alerts !== null && alerts.length === 1) {
            alertContent = (
                <div key="alerts" style={{marginBottom: "15px"}}>
                    {alerts[0]}
                </div>
            );
        } else if (alerts !== null && alerts.length >= 1) {
            alertContent = (
                <Grid key="an" item style={{marginBottom: "15px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div key="1" style={{display: "flex", flexDirection: "row", alignItems: "center", padding: 10, minHeight: 40, backgroundColor: "rgba(253, 231, 118, 0.5)"}}>
                        <Error style={{width: "28px", height: "28px", fill: "#f1c40f", marginRight: 10}} />
                        <div>
                            <Typography className={this.props.classes.alertText}>You have <b>{alerts.length}</b> alerts</Typography>
                            <Typography className={this.props.classes.alertLink}>
                                <Link href="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({expandAlerts: !this.state.expandAlerts});
                                }}>
                                    Click here to expand
                                </Link>
                            </Typography>
                        </div>
                    </div>
                </Grid>
            );
        }

        return alertContent;
    };

    render() {
        let finalMarkup = (
            <div style={{height: "242px", margin: "auto", display: "flex", justifyContent: "center"}}>
                <img src={spinner} alt="Spinner" width="35px" height="35px" />
            </div>
        );

        if (this.state.fetchBackgroundOperation === false) {
            if (process.env.REACT_APP_VARIANT === "cds") {
                finalMarkup = (
                    <>
                        {this.renderAlerts()}
                        <Grid key="id7" container justifyContent="space-between">
                            {this.renderSpokenWithPatient()}
                            {this.renderScheduledClinicVisit()}
                            {this.renderDateOfAcknowledgement()}
                            {this.renderDateOfEscalationStart()}
                            {this.renderCellCultureStatus()}
                            {this.renderCellCountStatus()}
                            {this.renderInfectionStatus()}
                            {this.renderTypeOfInfection()}
                            {this.renderDateOfInfectionDiagnosis()}
                            {this.renderDateOfNonInfectionDiagnosis()}
                            {this.renderDateOfHospitalization()}
                            {this.renderDateOfHemodialysis()}
                            {this.renderDateOfDeactivation()}
                            {this.renderDateOfExpiration()}
                            {this.renderDateOfResolution()}
                            {this.renderDateOfDischarge()}
                            {this.renderDateOfTransplant()}
                            {this.renderIsPatientComingBackToCurrentClinic()}
                            {this.renderWasCatheterRemoved()}
                            {this.renderWasCatheterReplaced()}
                        </Grid>
                    </>
                );
            } else {
                finalMarkup = (
                    <Grid key="id7" container justifyContent="space-between">
                        {this.renderDateOfInfectionDiagnosis()}
                        {this.renderDateOfNonInfectionDiagnosis()}
                        {this.renderDateOfResolution()}
                    </Grid>
                );
            }
        }

        return (
            <React.Fragment key="PatientEscalationDetailApplet">
                {finalMarkup}
            </React.Fragment>
        );
    }
}

export default withAppContext(withStyles(styles, {withTheme: true})(PatientEscalationDetailApplet));