import AttachmentEnterpriseComponentMetadata from "./AttachmentEnterpriseComponentMetadata";

class PatientAttachmentEnterpriseComponentMetadata extends AttachmentEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "PatientAttachment";
    }
}

export default PatientAttachmentEnterpriseComponentMetadata;