import {withAppContext} from "../../../App";
import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class PatientDetailAttachmentApplet extends Applet {
    constructor(props) {
        super(props);
    };

    state = {
        Name: "PatientDetailCyclerApplet",
        Modes: {
            View: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "attachments",
                        "selector": "Add PD Prescription",
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            Edit: {
                AllowAttachments: true,
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "attachments",
                        "selector": "Add PD Prescription",
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
        },
        Layout: {
            totalWidth: "36em",
        },
        Controls: {
            DropZone: {
                id: "DropZone",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "DropZone",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    margin: "dense",
                },
                ip: {},
            },
        },
    };

    render() {
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="attachment"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(PatientDetailAttachmentApplet);
