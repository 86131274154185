import React from "react";
import {withAppContext} from "../../../App";
import PatientNotificationB from "../../../components/PatientNotificationB";

import Applet from "../Applet";

class PatientNotificationAppletB extends Applet {
    state = {
        Name: "PatientNotificationAppletB",
    };

    render() {
        return <PatientNotificationB applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientNotificationAppletB);
