import {withAppContext} from "../../../App";
import PatientIdentifier from "../../../components/PatientIdentifier";

import Applet from "../Applet";

class PatientIdentifierApplet extends Applet {
    state = {
        Name: "PatientIdentifierApplet",
    };

    render() {
        return <PatientIdentifier applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientIdentifierApplet);
