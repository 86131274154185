import {Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import spinner from "../assets/Spinner-1s-200px.svg";
import styles from "../theme/styles";
import CloudcathAxios from "../util/CloudcathAxios";
import {convertToUTC, updateObject} from "../util/utility";
import CCButton from "./common/CCButton";

class AssignDevice extends Component {
    state = {
        controls: {
            DeviceSerialNumber: {
                label: "Device Serial #",
                value: "",
                valid: false,
                touched: false,
            },
        },
        formIsValid: false,
        serialNumberIsValid: false,
        status: "IDLE",
        showConfirmation: false,
        deviceUSID: null,
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;
    }

    componentDidMount() {
        this.applet.setMode("Edit");
    }

    assignObjectSubmitHandler = (event) => {
        event.preventDefault();

        let moment = require("moment");

        this.applet.getEnterpriseComponent().setAttributeValue("DeviceSerialNumber", this.state.controls["DeviceSerialNumber"].value);
        this.applet.getEnterpriseComponent().setAttributeValue("DeviceAssignmentStatus", "Assigned");
        this.applet.getEnterpriseComponent().setAttributeValue("DeviceAssignmentDatetime", convertToUTC(moment.now()));
        this.applet.getEnterpriseComponent().setAttributeValue("DeviceUSID", this.state.deviceUSID);
        this.applet.save();
        this.applet.cancel();
    };

    cancel1Handler = (event) => {
        event.preventDefault();
        this.setState({showConfirmation: true});
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    confirmDeviceHandler = () => {
        if (this.state.controls.DeviceSerialNumber.value === "" || this.state.controls.DeviceSerialNumber.value.substring(0, 1) === " ") {
            this.setState({status: "IDLE"});
            return;
        }

        if (this.state.status !== "BACKGROUND") {
            this.setState({status: "BACKGROUND"});
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/check_device_assignment",
            params: {
                "SerialNumber": this.state.controls.DeviceSerialNumber.value,
                // Workaround for ie11 caching requests by default.
                "_ts": Date.now(),
            },
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            },
        };
        CloudcathAxios(options)
            .then(response => {
                if (response.data.Code === "CC-ACDB-A238") {
                    this.setState({formIsValid: false, status: "DEVICE NOT FOUND", serialNumberIsValid: false});
                } else if (response.data.Code === "CC-CC43-5AAE") {
                    this.setState({formIsValid: false, status: "DEVICE ASSIGNED", serialNumberIsValid: false});
                } else {
                    const updatedFormElement = updateObject(this.state.controls.DeviceSerialNumber, {
                        valid: true,
                    });
                    const updatedPatientForm = updateObject(this.state.controls, {
                        DeviceSerialNumber: updatedFormElement,
                    });
                    this.setState({
                        controls: updatedPatientForm,
                        formIsValid: true,
                        status: "DEVICE AVAILABLE",
                        deviceUSID: response.data.DeviceUSID,
                        serialNumberIsValid: true,
                    });

                }
            })
            .catch(error => {
                this.setState({formIsValid: false, status: "UNKNOWN ERROR", serialNumberIsValid: false});
            });

    };

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedFormElement = updateObject(this.state.controls[inputIdentifier], {
            value: event.target.value,
            valid: false,
            touched: true,
        });
        const updatedPatientForm = updateObject(this.state.controls, {
            [inputIdentifier]: updatedFormElement,
        });

        this.setState({controls: updatedPatientForm, formIsValid: false});

        if (event.target.value.length >= 100) {
            this.setState({status: "100 characters allowed"});
        } else {
            this.setState({status: "IDLE"});
        }

        let timer = this.state.timer;
        if (timer !== null) {
            clearTimeout(timer);
        }
        if (event.target.value !== "") {
            this.setState({timer: setTimeout(this.changeStateForStatus, 1500)});
        }
    };

    changeStateForStatus = () => {
        this.setState({status: "BACKGROUND"});
        this.confirmDeviceHandler();
    };

    render() {

        let statusMessage;

        let deviceSerialNumber = this.state.controls.DeviceSerialNumber.value;

        if (this.state.status === "BACKGROUND") {
            statusMessage = "Searching...";
        } else if (this.state.status === "DEVICE NOT FOUND") {
            statusMessage = "Device not found in clinic inventory";
        } else if (this.state.status === "DEVICE ASSIGNED") {
            statusMessage = "Device assigned to another patient";
        } else if (this.state.status === "DEVICE AVAILABLE") {
            statusMessage = "Device available";
        } else if (this.state.status === "100 characters allowed") {
            statusMessage = "100 characters allowed";
        } else if (this.state.status === "IDLE") {
            statusMessage = <>&nbsp;</>;
        } else /*if (this.state.status === "UNKNOWN ERROR")*/ {
            statusMessage = "Unknown error";
        }

        if (deviceSerialNumber === null) {
            deviceSerialNumber = this.applet.getEnterpriseComponent().getAttributeValue("DeviceSerialNumber");
            if (deviceSerialNumber === null) {
                deviceSerialNumber = "";
            }
        }

        let adornments;
        if (this.state.status === "BACKGROUND") {
            adornments = {
                endAdornment: (
                    <InputAdornment position="end">
                        <img src={spinner} alt="Spinner" style={{width: "35px", height: "35px"}} />
                    </InputAdornment>
                ),
            };
        }

        return (
            <React.Fragment>
                <Dialog open={true} scroll="body" PaperProps={{style: {minWidth: 500}}}>
                    <DialogTitle>Assign Device</DialogTitle>
                    <DialogContent style={{padding: "0 16px"}}>
                        <form onSubmit={e => e.preventDefault()}>
                            <TextField
                                key="DeviceSerialNumber"
                                margin="dense"
                                label="Enter serial number"
                                InputLabelProps={{shrink: true}}
                                fullWidth={true}
                                id="DeviceSerialNumber"
                                onChange={(event) => this.inputChangedHandler(event, "DeviceSerialNumber")}
                                value={deviceSerialNumber}
                                variant="outlined"
                                inputProps={{"maxLength": 100}}
                                InputProps={adornments}
                            />
                        </form>
                        <div style={{height: "30px"}}>
                            <Typography className={this.props.classes.assignDeviceStatusMessage}>{statusMessage}</Typography>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        <CCButton disabled={!(this.state.status === "DEVICE AVAILABLE")} onClick={this.assignObjectSubmitHandler}>
                            Assign device
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(AssignDevice),
);