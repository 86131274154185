import UserEnterpriseComponentMetadata from "./UserEnterpriseComponentMetadata";

class InactiveUserEnterpriseComponentMetadata extends UserEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "InactiveUser";
    }
}

export default InactiveUserEnterpriseComponentMetadata;