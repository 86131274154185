/* eslint-disable no-unused-vars */

export const Blue1 = "#8DCFF0";
export const Blue1b = "#5ADCED";
export const Blue2 = "#5ABCED";
export const Blue3 = "#1498D8";
export const Blue4 = "#0575AD";
export const Blue5 = "#004E75";

export const Grey1 = "#FFFFFF";
export const Grey1b = "#F7F9FC";
export const Grey2 = "#EDEFF3";
export const Grey3 = "#D8DDE4";
export const Grey4 = "#BAC3CE";
export const Grey5 = "#A8A9AF";
export const Grey6 = "#7B8291";
export const Grey6b = "#757575";
export const Grey7 = "#525968";
export const Grey8 = "#262837";

export const Red1 = "#F096Ad";
export const Red2 = "#EC6759";
export const Red3 = "#E74C3C";
export const Red4 = "#AD1505";
export const Red5 = "#730E03";

export const Yellow1 = "#FDE776";
export const Yellow2 = "#FDDC3F";
export const Yellow3 = "#F1C40F";
export const Yellow4 = "#BC932C";
export const Yellow5 = "#876D27";

export const Green1 = "#98EDBB";
export const Green2 = "#61E097";
export const Green3 = "#00D65B";
export const Green4 = "#00A044";
export const Green5 = "#006a2D";

export default function styles(theme) {
    return ({
        color1: {
            ...theme.palette.light,
        },
        toolbarMargin: {
            ...theme.mixins.toolbar,
            marginBottom: "1em",
        },
        logo: {
            height: "5em",
        },
        logoContainer: {
            padding: 0,
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        tabContainer: {
            marginLeft: "auto",
            marginRight: "auto",
        },
        tab: {
            ...theme.typography.tab,
            minWidth: 10,
            marginLeft: "25px",
        },
        greenButton: {
            color: "#FFFFFF",
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: Green3,
            height: "36px",
            minWidth: "136px",
            "&:hover:enabled": {
                backgroundColor: Green2,
            },
            "&:active": {
                backgroundColor: Green4,
            },
            "&:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                cursor: "not-allowed",
            },
        },
        yellowButton: {
            color: Grey8,
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: Yellow3,
            height: "36px",
            minWidth: "136px",
            "&:hover:enabled": {
                backgroundColor: Yellow2,
            },
            "&:active": {
                backgroundColor: Yellow4,
            },
            "&:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                cursor: "not-allowed",
            },
        },
        redButton: {
            color: "#FFFFFF",
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: Red3,
            height: "36px",
            minWidth: "136px",
            "&:hover:enabled": {
                backgroundColor: Red2,
            },
            "&:active": {
                backgroundColor: Red4,
            },
            "&:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                cursor: "not-allowed",
            },
        },
        basicButton: {
            color: "#FFFFFF",
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: Blue3,
            height: "36px",
            minWidth: "136px",
            "&:hover:enabled": {
                backgroundColor: Blue2,
            },
            "&:active": {
                backgroundColor: Blue5,
            },
            "&:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                cursor: "not-allowed",
            },
        },
        addAnother: {
            color: Grey8,
            margin: "0 10px 20px 0",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: "#FFFFFF",
            height: "36px",
            minWidth: "136px",
            borderStyle: "solid",
            borderColor: Grey4,
            borderWidth: "1px",
            "&:hover:enabled": {
                borderStyle: "solid",
                borderColor: Blue2,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                color: Blue2,
            },
            "&:active": {
                borderStyle: "solid",
                borderColor: Blue3,
                borderWidth: "1px",
                color: Blue4,
            },
            "&:disabled": {
                color: Grey3,
                borderStyle: "solid",
                borderColor: Grey3,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                borderStyle: "solid",
                borderColor: Grey3,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                cursor: "not-allowed",
            },
        },
        addAttachment: {
            color: Grey8,
            margin: "0 10px 20px 0",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: "#FFFFFF",
            height: "36px",
            minWidth: "136px",
            borderStyle: "solid",
            borderColor: Grey4,
            borderWidth: "1px",
            "&:hover:enabled": {
                borderStyle: "solid",
                borderColor: Blue2,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                color: Blue2,
            },
            "&:active": {
                borderStyle: "solid",
                borderColor: Blue3,
                borderWidth: "1px",
                color: Blue4,
            },
            "&:disabled": {
                color: Grey3,
                borderStyle: "solid",
                borderColor: Grey3,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                borderStyle: "solid",
                borderColor: Grey3,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                cursor: "not-allowed",
            },
        },
        secondaryButton: {
            color: Grey8,
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            backgroundColor: "#FFFFFF",
            height: "36px",
            minWidth: "136px",
            borderStyle: "solid",
            borderColor: Grey4,
            borderWidth: "1px",
            "&:hover:enabled": {
                borderStyle: "solid",
                borderColor: Blue2,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                color: Blue2,
            },
            "&:active": {
                borderStyle: "solid",
                borderColor: Blue3,
                borderWidth: "1px",
                color: Blue4,
            },
            "&:disabled": {
                color: Grey3,
                borderStyle: "solid",
                borderColor: Grey3,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                borderStyle: "solid",
                borderColor: Grey3,
                borderWidth: "1px",
                backgroundColor: "#FFFFFF",
                cursor: "not-allowed",
            },
        },
        redOutlinedButton: {
            color: Red3,
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            borderRadius: "100px",
            borderColor: Red3,
            backgroundColor: "#FFFFFF",
            height: "36px",
            minWidth: "136px",
            "&:hover:enabled": {
                backgroundColor: Red2,
                color: "#FFFFFF",
            },
            "&:active": {
                backgroundColor: Red4,
            },
            "&:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey3,
                backgroundColor: Grey2,
                cursor: "not-allowed",
            },
        },
        textButton: {
            color: Blue3,
            margin: "0 10px 10px 10px",
            textAlign: "center",
            fontWeight: 400,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            fontSize: "14px",
            backgroundColor: "#FFFFFF",
            height: "36px",
            minWidth: "136px",
            border: "none",
            "&:hover:enabled": {
                color: Blue2,
                backgroundColor: "#FFFFFF",
            },
            "&:active": {
                color: Blue4,
                backgroundColor: "#FFFFFF",
            },
            "&:disabled": {
                color: Grey2,
                backgroundColor: "#FFFFFF",
                pointerEvents: "all",
            },
            "&:hover:disabled": {
                color: Grey2,
                backgroundColor: "#FFFFFF",
                cursor: "not-allowed",
            },
        },
        patientListBadge: {
            minWidth: "15px",
            height: "16px",
            fontSize: "10px",
            fontWeight: "600",
            backgroundColor: Red3,
            marginLeft: 8,
            padding: "2px 2px 0 2px",
            borderRadius: "25px",
            color: "#FFFFFF",
            textAlign: "center",
        },
        patientBadge: {
            height: "16px",
            width: "65px",
            fontSize: "9px",
            fontWeight: 400,
            backgroundColor: Grey8,
            borderRadius: "25px",
            color: "#FFFFFF",
            paddingTop: "4px",
            textAlign: "center",
        },
        patientEscalationBadge: {
            fontSize: "9px",
            backgroundColor: Grey8,
            borderRadius: 24,
            padding: "4px 12px",
            color: "#FFFFFF",
            whiteSpace: "nowrap",
        },
        patientTurbidityBadge: {
            fontSize: "14px",
            fontWeight: 400,
            backgroundColor: Yellow3,
            borderRadius: 24,
            padding: "2px 10px",
            color: "#FFFFFF",
            whiteSpace: "nowrap",
        },
        patientEditBadge: {
            marginTop: "-15px",
            height: "20px",
            width: "20px",
            backgroundColor: Grey8,
            borderRadius: "25px",
            color: "#FFFFFF",
        },
        patientEditDiv: {
            margin: "25px 0 0 0",
            border: "1px solid #FFFFFF",
            borderRadius: "4px",
            padding: "7px",
            position: "relative",
            "&:hover": {
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: Blue1b,
                boxShadow: "0 1px 3px 0 rgba(17, 17, 17, 0.15)",
                "& [id=hover-edit-button]": {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    position: "relative",
                    top: "-16px",
                    right: "-16px",
                    height: "0px",
                },
            },
            "& [id=hover-edit-button]": {
                display: "none",
            },
        },
        patientEditDivNonEdit: {
            margin: "25px 0 0 0",
            border: "1px solid #FFFFFF",
            borderRadius: "4px",
            padding: "7px",
            position: "relative",
        },
        episodeEditDiv: {
            margin: "0 25px 0 -7px",
            border: "1px solid #FFFFFF",
            borderRadius: "4px",
            padding: "0px 7px 0px 6px",
            position: "relative",
            "&:hover": {
                padding: "7px 7px 7px -7px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: Blue1b,
                boxShadow: "0 1px 3px 0px rgba(17, 17, 17, 0.15)",
                "& [id=hover-edit-button]": {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    position: "relative",
                    top: "-16px",
                    right: "-16px",
                    height: "0px",
                },
            },
            "& [id=hover-edit-button]": {
                display: "none",
            },
        },
        patientIdentifierEditDiv: {
            margin: "0 0 0 0",
            borderRadius: "4px",
            padding: "1px 1px 1px 7px",
            position: "relative",
            maxWidth: "285px",
            width: "100%",
            "&:hover": {
                margin: "0 0 0 0",
                padding: "0 0 0 6px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: Blue1b,
                boxShadow: "0 1px 3px 0 rgba(17, 17, 17, 0.15)",
                "& [id=hover-edit-button]": {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    position: "relative",
                    top: "-16px",
                    right: "-16px",
                    height: "0px",
                },
            },
            "& [id=hover-edit-button]": {
                display: "none",
            },
        },
        patientIdentifierNonEditDiv: {
            margin: "0 0 0 0",
            borderRadius: "4px",
            padding: "1px 1px 1px 7px",
            position: "relative",
            maxWidth: "285px",
        },
        patientEditDivXS: {
            margin: "15px 0 0 0",
            padding: "7px",
        },
        assistiveText: {
            fontSize: "12px",
            color: Grey6,
        },
        patientDetailLabel: {
            fontSize: "13px",
            color: Grey5,
        },
        patientDetailData: {
            fontSize: "13px",
            color: Grey8,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "calc( 100% - 30px )",
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary,
            backgroundColor: "#FFFFFF",
        },
        assignDeviceStatus: {
            width: "100%",
            margin: "auto",
            display: "flex",
            alignItems: "flex-start",
            height: "100%",
        },
        assignDeviceStatusMessage: {
            padding: "4px 0 0 5px",
            fontSize: "13px",
            color: Red3,
        },
        userReassignPatientsEditDiv: {
            margin: "2px 16px 2px 0",
            borderRadius: "4px",
            padding: "7px",
            position: "relative",
            "&:hover": {
                padding: "6px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: Blue1b,
                boxShadow: "0 1px 3px 0 rgba(17, 17, 17, 0.15)",
                "& [id=hover-edit-button]": {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    position: "relative",
                    top: "-16px",
                    right: "-16px",
                    height: "0px",
                },
            },
            "& [id=hover-edit-button]": {
                display: "none",
            },
        },
        selectPage: {
            fontSize: 13,
            marginLeft: 2,
            color: Grey7,
            fontWeight: 600,
            textAlign: "center",
            "& svg": {
                width: 18,
                height: 18,
                marginTop: 3,
                marginRight: 2,
                color: Blue3,
            },
            "& .MuiInput-input": {
                padding: "8px 18px 8px 8px",
            },
        },
        multiSelect: {
            "& legend": {
                visibility: "visible",
                "& span": {
                    backgroundColor: "#FFFFFF",
                    transform: "translate(2px, -6px)",
                    position: "fixed",
                    color: Grey6b,
                },
            },
        },
        tableRow: {
            "& .edit-svg": {
                visibility: "hidden",
            },
            "&.MuiTableRow-hover:hover": {
                "& .edit-svg": {
                    visibility: "visible",
                },
            },
        },
        tableHeader: {
            fontSize: "14px",
            fontWeight: 500,
            color: Grey6,
            border: 0,
        },
        tableCell: {
            fontSize: "14px",
            fontWeight: 400,
            color: Grey8,
            border: 0,
        },
        episodeXSCellGrey6: {
            color: Grey6,
            fontSize: "14px",
            fontWeight: 400,
        },
        addEpisodeText: {
            fontSize: "14px",
            fontWeight: "500",
            color: Grey8,
        },
        episodeTitle: {
            fontSize: "18px",
            fontWeight: "600",
            color: Grey8,
        },
        alertText: {
            fontSize: "13px",
            fontWeight: 400,
            color: Grey8,
        },
        redAlertText: {
            fontSize: "13px",
            fontWeight: 400,
            color: "#000000",
        },
        alertLink: {
            fontSize: "13px",
            fontWeight: 400,
            color: Blue3,
        },
        errorText: {
            fontSize: "14px",
            fontWeight: "500",
            color: Red3,
        },
        containerMargin: {
            marginLeft: "auto",
            marginRight: "auto",
            paddingLeft: 30,
            paddingRight: 30,
        },
        longLabel: {
            width: 310,
        },
    });
}