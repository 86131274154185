import AbstractMetadata from "../AbstractMetadata";

class EnterpriseComponentAttributePickMapMetadata extends AbstractMetadata {
    constructor(fName, fConstrain, fAttribute, fNoClear, fPickListAttribute, fType) {
        super();

        this.fName = fName;
        this.fConstrain = fConstrain;
        this.fAttribute = fAttribute;
        this.fNoClear = fNoClear;
        this.fPickListAttribute = fPickListAttribute;
        this.fType = fPickListAttribute;
        this.fType = fType;
    };

    getName = () => {
        return this.fName;
    };

    getConstrain = () => {
        return this.fConstrain;
    };

    getAttribute = () => {
        return this.fAttribute;
    };

    getNoClear = () => {
        return this.fNoClear;
    };

    getPickListAttribute = () => {
        return this.fPickListAttribute;
    };

    getType = () => {
        return this.fType;
    };
}

export default EnterpriseComponentAttributePickMapMetadata;
