import {Tooltip} from "@material-ui/core";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import React, {useState} from "react";
import "./css/CCTextField.scss";

export default function CopyToClipboard({text, style, ...props}) {

    const [tooltip, setTooltip] = useState("Copy");
    const [tooltipReset, setTooltipReset] = useState(undefined);

    if (style === undefined) style = {};

    function updateTooltip(tooltip) {
        setTooltip(tooltip);
        setTooltipReset(setTimeout(() => {
            setTooltipReset(undefined);
            setTooltip("Copy");
        }, 3000));
    }

    return (
        <Tooltip arrow title={tooltip}>
            <FilterNoneIcon
                style={{verticalAlign: "text-top", transform: "rotate(180deg)", cursor: "pointer", ...style}}
                onClick={e => {
                    e.stopPropagation();
                    if (tooltipReset) return;
                    navigator.clipboard.writeText(text)
                        .then(r => updateTooltip("Copied!"))
                        .catch(e => updateTooltip("Failed to copy."));
                }}
                {...props}
            />
        </Tooltip>
    );
}
