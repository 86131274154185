import {IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import _ from "lodash";
import moment from "moment-timezone";
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {useAppContext} from "../../App";
import TurbidityGraph from "../../components/turbidity/TurbidityGraph";
import eoecClassFactory from "../../eoec/EOECClassFactory";
import styles, {Grey6, Grey7} from "../../theme/styles";
import {Condition} from "../../util/Condition";
import {parseQueryParams} from "../../util/Location";
import CCPulse from "../common/CCPulse";
import css_self from "./css/TurbidityDataReport.module.scss";

export default function TurbidityDataReport() {

    const [patientRecord, setPatientRecord] = useState(null);
    const [secondaryNurseRecords, setSecondaryNurseRecords] = useState(null);
    const [secondaryPhysicianRecords, setSecondaryPhysicianRecords] = useState(null);
    const [episodeRecords, setEpisodeRecords] = useState(null);
    const [turbidityEpisodes, setTurbidityEpisodes] = useState(null);
    const [turbidityDataPoints, setTurbidityDataPoints] = useState(null);
    const [xlogBoxes, setXlogBoxes] = useState(null);

    const [reportReady, setReportReady] = useState(false);

    const {sessionContext} = useAppContext();

    const location = useLocation();

    const queryParams = parseQueryParams(location.search);
    const patientUSID = queryParams.patientUSID;

    let timezone = sessionContext.clinicTimezone;
    if (queryParams.tz) timezone = queryParams.tz;

    const isMounted = useRef(true);

    const dateStart = moment(queryParams.dateStart, "YYYY-MM-DD").startOf("day");
    const dateEnd = moment(queryParams.dateEnd, "YYYY-MM-DD").endOf("day");

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);

    async function downloadPatient() {

        const enterpriseObject = eoecClassFactory("eo", "Patient");
        const enterpriseComponent = enterpriseObject.getEnterpriseComponent("Patient");

        const condition = Condition();

        const listener = {
            getName: () => `TurbidityDataReport-${uuidv4()}`,
            enterpriseComponentLoadCompleted: (buffer, ec) => {
                const record = buffer.find((record, i) => record.USID === patientUSID);
                condition.notify(_.cloneDeep(record));
            },
        };

        try {
            enterpriseComponent.addLoadCompletedListener(listener);
            enterpriseComponent.newQuery();
            enterpriseComponent.setSearchSpecification({"USID": patientUSID});
            enterpriseComponent.performQuery();
            const record = await condition.lock;
            if (isMounted.current) setPatientRecord(record);
            return record;
        } finally {
            enterpriseComponent.removeLoadCompletedListener(listener);
        }
    }

    async function downloadPatientSecondaryUsers(userRole) {

        const enterpriseObject = eoecClassFactory("eo", "Patient");
        const enterpriseComponent = enterpriseObject.getEnterpriseComponent(`Secondary${userRole}`);

        const condition = Condition();

        const listener = {
            getName: () => `TurbidityDataReport-${uuidv4()}`,
            enterpriseComponentLoadCompleted: (buffer, ec) => {
                condition.notify(_.cloneDeep(buffer));
            },
        };

        try {
            enterpriseComponent.addLoadCompletedListener(listener);
            enterpriseComponent.newQuery();
            enterpriseComponent.setSearchSpecification({"USID": patientUSID});
            enterpriseComponent.performQuery();
            const buffer = await condition.lock;
            if (isMounted.current) {
                if (userRole === "Nurse") setSecondaryNurseRecords(buffer);
                if (userRole === "Physician") setSecondaryPhysicianRecords(buffer);
            }
            return buffer;
        } finally {
            enterpriseComponent.removeLoadCompletedListener(listener);
        }
    }

    async function downloadPatientEpisodes() {

        if (!turbidityEpisodes) {
            setEpisodeRecords([]);
            return;
        }

        Promise.allSettled(
            turbidityEpisodes.map(o => new Promise(async resolve => {

                    const episodeUSID = o.USID;

                    const enterpriseObject = eoecClassFactory("eo", "Patient");
                    const enterpriseComponent = enterpriseObject.getEnterpriseComponent("Escalation");

                    const condition = Condition();

                    const listener = {
                        getName: () => `TurbidityDataReport-${uuidv4()}`,
                        enterpriseComponentLoadCompleted: (buffer, ec) => {
                            const record = buffer.find((record, i) => record.USID === episodeUSID);
                            condition.notify(_.cloneDeep(record));
                        },
                    };

                    try {
                        enterpriseComponent.addLoadCompletedListener(listener);
                        enterpriseComponent.newQuery();
                        enterpriseComponent.setSearchSpecification({"USID": episodeUSID});
                        enterpriseComponent.performQuery();
                        resolve(await condition.lock);
                    } finally {
                        enterpriseComponent.removeLoadCompletedListener(listener);
                    }

                }),
            ),
        ).then(responses => {

            if (!isMounted.current) return;

            let data = _.reduce(responses, (result, response) => {
                if (response.status === "fulfilled") {
                    result.push(response.value);
                }
                return result;
            }, []);

            data = _.sortBy(data, x => moment(x.StartDatetime, "MMM DD, YYYY"));

            if (!isMounted.current) return;

            setEpisodeRecords(data);
        });
    }

    useEffect(() => {
        downloadPatient();
        downloadPatientSecondaryUsers("Nurse");
        downloadPatientSecondaryUsers("Physician");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (turbidityEpisodes !== null) {
            downloadPatientEpisodes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [turbidityEpisodes]);


    useEffect(() => {

        if (turbidityDataPoints) {

            const turbidityTreatments = [];
            const turbidityTreatmentDates = [];

            turbidityDataPoints.reverse().forEach((o, i) => {
                if (i === 0) {
                    turbidityTreatments[i] = 1;
                    turbidityTreatmentDates.push(o.date);
                } else if (Math.abs(turbidityDataPoints[i].date - turbidityDataPoints[i - 1].date) < 21600000) {
                    turbidityTreatments[i] = turbidityTreatments[i - 1];
                    turbidityTreatmentDates.push(turbidityTreatmentDates[i - 1]);
                } else {
                    turbidityTreatments[i] = turbidityTreatments[i - 1] + 1;
                    turbidityTreatmentDates.push(o.date);
                }
            });

            const xlogDateFormat = d => d.format("MMM DD");
            const xlogDates = _.uniqBy(turbidityTreatmentDates.map(o => xlogDateFormat(moment(o).tz(timezone))));

            const dates = [];
            const adherence = [];

            let dateX = dateStart.startOf("day");
            while (dateX < dateEnd) {
                const strX = xlogDateFormat(dateX);
                const x = xlogDates.includes(strX);
                dates.push({date: strX, x: x});
                if (x) adherence.push(strX);
                dateX.add(1, "day");
            }

            setXlogBoxes({adherence: adherence, dates: dates});
        }

    }, [turbidityDataPoints]);

    useEffect(() => {

        setReportReady(![
            patientRecord,
            secondaryPhysicianRecords,
            secondaryNurseRecords,
            episodeRecords,
            turbidityDataPoints,
        ].includes(null));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        patientRecord,
        secondaryPhysicianRecords,
        secondaryNurseRecords,
        episodeRecords,
        turbidityDataPoints,
    ]);

    // useEffect(() => {
    //     if (reportReady) {
    //         window.print();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [reportReady]);

    const useLocalStyles = makeStyles((theme) => ({
        ...styles,
        userNameHeaderStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: Grey7,
        },
        userNameStyle: {
            fontSize: 13,
        },
        tableHeaderStyle: {
            fontSize: 14,
            fontWeight: 500,
            color: Grey7,
            border: 0,
            padding: "0 0 6px 0",
        },
        tableBodyStyle: {
            fontSize: 13,
            fontWeight: 400,
            border: 0,
            padding: "6px 0 6px 0",
        },
        xlogBox: {
            minWidth: "calc(10% - 1px)",
            height: 45,
            marginLeft: 1,
            marginTop: 1,
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            textAlign: "center",
            outline: "1px solid",
        },
        xlogTextStyle: {
            fontSize: 13,
            fontWeight: 400,
        },
    }));

    // const classes = useStyles();
    const classes = useLocalStyles();

    function renderEpisodeStartDateCell(episode) {
        return moment(episode.StartDatetime, "MMM DD, YYYY").tz(timezone).format("MMMM DD, YYYY");
    }

    function renderEpisodeHighestTurbidityScoreCell(episode) {

        if (episode.HighestTurbidityScoreNumeric === undefined) {
            return "--";
        }

        const turbidityThresholdHit = episode.TurbidityNotificationLevel !== null &&
            episode.HighestTurbidityScoreNumeric >= episode.TurbidityNotificationLevel;

        let turbidityStyle = {
            color: turbidityThresholdHit ? "#e74c3c" : "inherit",
            fontWeight: turbidityThresholdHit ? 600 : "inherit",
        };

        const highestTurbidityDatetime = moment(episode.HighestTurbidityScoreDatetime, "HH:mm MMM DD, YYYY").tz(timezone).format("HH:mm MMMM DD, YYYY");

        return (
            <div>
                <span style={turbidityStyle}>{episode.HighestTurbidityScore}</span>&nbsp;&nbsp;<span style={{color: Grey6}}>{highestTurbidityDatetime}</span>
            </div>
        );
    }

    const patientFullName = [
        patientRecord?.FirstName,
        ...(patientRecord?.MiddleInitial ? [`${patientRecord?.MiddleInitial}.`.toUpperCase()] : []),
        patientRecord?.LastName,
    ].filter(x => !!x).join(" ") || null;

    let patientDOB = null;
    if (patientRecord?.DateOfBirth) patientDOB = moment(patientRecord?.DateOfBirth, "MMM DD, YYYY").format("MMMM DD, YYYY");

    return (
        <div className={css_self.Paper} style={{display: "flex", width: "100%", justifyContent: "center"}}>
            <div className={css_self.ReportSection} style={{justifyContent: "center", alignItems: "center"}}>
                <div style={{position: "relative"}}>
                    <Typography variant="h1" style={{textAlign: "center"}}>Patient Turbidity Report</Typography>
                    <IconButton className={css_self.PrintButton} onClick={() => window.print()} style={{position: "absolute", top: 0, right: 0, visibility: !reportReady ? "hidden" : undefined}}>
                        <PrintIcon />
                    </IconButton>
                </div>
                {!reportReady ? (
                    <div style={{display: "flex", marginTop: 50, pointerEvents: "none"}}>
                        <CCPulse style={{margin: "auto"}} DotProps={{style: {backgroundColor: "#1498d8"}}} />
                    </div>
                ) : null}
                <div style={{marginTop: 25, visibility: !reportReady ? "hidden" : undefined}}>
                    <Typography variant="h2">{(sessionContext.actStudy ? patientRecord?.PatientIdentifier : patientFullName) ?? "--"}</Typography>
                    <div style={{display: "flex", marginTop: 15}}>
                        {sessionContext.actStudy ? (
                            <span style={{marginRight: 25}}>
                                <Typography className={classes.userNameHeaderStyle} style={{marginBottom: 3}}>Patient Name</Typography>
                                <Typography className={classes.userNameStyle}>{patientFullName ?? "--"}</Typography>
                            </span>
                        ) : (
                            <span style={{marginRight: 25}}>
                                <Typography className={classes.userNameHeaderStyle} style={{marginBottom: 3}}>CloudCath ID</Typography>
                                <Typography className={classes.userNameStyle}>{patientRecord?.CloudCathID ?? "--"}</Typography>
                            </span>
                        )}
                        <span style={{marginRight: 25}}>
                            <Typography className={classes.userNameHeaderStyle} style={{marginBottom: 3}}>Date of Birth</Typography>
                            <Typography className={classes.userNameStyle}>{patientDOB ?? "--"}</Typography>
                        </span>
                        <span style={{marginRight: 25}}>
                            <Typography className={classes.userNameHeaderStyle} style={{marginBottom: 3}}>Provider</Typography>
                            <Typography className={classes.userNameStyle}>{sessionContext.providerName ?? "--"}</Typography>
                        </span>
                        <span style={{marginRight: 25}}>
                            <Typography className={classes.userNameHeaderStyle} style={{marginBottom: 3}}>Clinic</Typography>
                            <Typography className={classes.userNameStyle}>{sessionContext.clinicName ?? "--"}</Typography>
                        </span>
                    </div>
                    <div style={{display: "flex", marginTop: 15}}>
                        <span style={{marginRight: 25}}>
                            <Typography className={classes.userNameHeaderStyle} style={{marginBottom: 3}}>Assigned Nurse</Typography>
                            <Typography className={classes.userNameStyle}>{patientRecord?.PrimaryNurseFullName ?? "--"}</Typography>
                        </span>
                        <span style={{marginRight: 25}}>
                            <Typography className={classes.userNameHeaderStyle} style={{marginBottom: 3}}>Secondary Nurse</Typography>
                            {secondaryNurseRecords?.length ? secondaryNurseRecords.map((o, i) => (
                                <Typography key={i} className={classes.userNameStyle}>{o.FullName}</Typography>
                            )) : (
                                <Typography className={classes.userNameStyle}>--</Typography>
                            )}
                            </span>
                        <span style={{marginRight: 25}}>
                            <Typography className={classes.userNameHeaderStyle} style={{marginBottom: 3}}>Assigned Physician</Typography>
                            <Typography className={classes.userNameStyle}>{patientRecord?.PrimaryDoctorFullName ?? "--"}</Typography>
                        </span>
                        <span style={{marginRight: 25}}>
                            <Typography className={classes.userNameHeaderStyle} style={{marginBottom: 3}}>Secondary Physician</Typography>
                            {secondaryPhysicianRecords?.length ? secondaryPhysicianRecords.map((o, i) => (
                                <Typography key={i} className={classes.userNameStyle}>{o.FullName}</Typography>
                            )) : (
                                <Typography className={classes.userNameStyle}>--</Typography>
                            )}
                        </span>
                    </div>
                    <div style={{marginTop: 15}}>
                        <Typography className={classes.userNameHeaderStyle} style={{marginBottom: 3}}>Cycler</Typography>
                        <Typography className={classes.userNameStyle}>{patientRecord?.CyclerName ?? "--"}</Typography>
                    </div>
                    <Typography variant="h2" style={{marginTop: 25}}>Turbidity Graph</Typography>
                    <Typography style={{fontSize: 13, fontWeight: 400, color: Grey7, marginTop: 15}}>{dateStart.format("MMMM DD, YYYY")}{queryParams.dateStart !== queryParams.dateEnd ? ` - ${dateEnd.format("MMMM DD, YYYY")}` : ""}</Typography>
                    <TurbidityGraph
                        patientUSID={patientUSID}
                        variant="svg-only"
                        dateStart={dateStart.format("YYYY-MM-DD")}
                        dateEnd={dateEnd.format("YYYY-MM-DD")}
                        interactive={false}
                        onLoadComplete={({notificationLevel, escalations: episodes, data}) => {
                            setTurbidityDataPoints(data ?? []);
                            setTurbidityEpisodes(episodes ?? []);
                        }}
                        timezone={timezone}
                        style={{marginTop: 15}}
                    />
                    {xlogBoxes !== null ? (
                        <div style={{breakInside: "avoid"}}>
                            <Typography variant="h2" style={{marginTop: 25}}>Adherence ({xlogBoxes.adherence.length}/{xlogBoxes.dates.length})</Typography>
                            <div style={{display: "flex", flexFlow: "wrap", marginTop: 15}}>
                                {xlogBoxes.dates.map((o, i) => (
                                    <div key={i} className={classes.xlogBox}>
                                        <Typography className={classes.xlogTextStyle}>{o.date}</Typography>
                                        <Typography className={classes.xlogTextStyle} style={{marginTop: 1}}>{o.x ? "X" : <>&nbsp;</>}</Typography>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : undefined}
                    <div style={{breakInside: "avoid"}}>
                        <Typography variant="h2" style={{marginTop: 25}}>Episodes</Typography>
                        <TableContainer style={{marginTop: 15}}>
                            <Table>
                                <TableHead>
                                    <TableRow style={{borderBottom: "2px solid #d8dde4", height: "48"}}>
                                        <TableCell className={classes.tableHeaderStyle} width="20%">Episode Notified</TableCell>
                                        <TableCell className={classes.tableHeaderStyle} width="15%">Duration</TableCell>
                                        <TableCell className={classes.tableHeaderStyle} width="35%">Last Status</TableCell>
                                        <TableCell className={classes.tableHeaderStyle} width="30%">Highest Turbidity Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {episodeRecords?.length ? episodeRecords.map((o, i) => (
                                        <TableRow key={i} style={{borderBottom: i + 1 < episodeRecords.length ? "1px solid #d8dde4" : undefined, height: "48"}}>
                                            <TableCell className={classes.tableBodyStyle} width="20%">{renderEpisodeStartDateCell(o)}</TableCell>
                                            <TableCell className={classes.tableBodyStyle} width="15%">{o.Duration}</TableCell>
                                            <TableCell className={classes.tableBodyStyle} width="35%">{o.EscalationStatus}</TableCell>
                                            <TableCell className={classes.tableBodyStyle} width="30%">{renderEpisodeHighestTurbidityScoreCell(o)}</TableCell>
                                        </TableRow>
                                    )) : (
                                        <TableRow style={{height: "48"}}>
                                            <TableCell className={classes.tableBodyStyle} width="20%">--</TableCell>
                                            <TableCell className={classes.tableBodyStyle} width="15%">--</TableCell>
                                            <TableCell className={classes.tableBodyStyle} width="35%">--</TableCell>
                                            <TableCell className={classes.tableBodyStyle} width="30%">--</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    {/*<div style={{pageBreakAfter: "always"}} />*/}
                    <div style={{paddingTop: 100, paddingBottom: 10, breakInside: "avoid"}}>
                        <Typography style={{fontStyle: "italic"}}>The above information has been reviewed and interpreted.</Typography>
                        <div style={{display: "flex", marginTop: 50}}>
                            <Typography variant="h3" style={{width: "calc(80% - 50px)", marginRight: 50, paddingTop: 10, borderTop: "2px solid"}}>Signature</Typography>
                            <Typography variant="h3" style={{width: "20%", paddingTop: 10, borderTop: "2px solid"}}>Date</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
