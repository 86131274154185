import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import * as Analytics from "../../util/Analytics";
import EventBus from "../../util/EventBus";
import {useHandler} from "../../util/useHandler";
import * as userAgent from "../../util/UserAgent";
import CCButton from "../common/CCButton";

export function SystemError(props) {

    const [visible, setVisible] = useState(false);

    const history = useHistory();

    const updateListener = useHandler(() => {
        Analytics.record("System Error Viewed");
        setVisible(true);
    }, []);

    useEffect(() => {
        EventBus.error.addListener("show-system-error", updateListener);
        return () => EventBus.session.removeListener("update", updateListener);
    }, [updateListener]);

    return visible ? (
        <Dialog open={true} scroll="body" fullScreen={userAgent.isMobile()} {...props}>
            <DialogTitle>System Error</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    An unexpected system error has occurred. To continue, please reload the page or logout.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CCButton
                    variant="secondary"
                    onClick={async () => {
                        Analytics.record("System Error Closing", {
                            Reason: "Logout",
                        });
                        Analytics.record("Logging Out", {
                            Reason: "System Error",
                            Voluntary: true,
                        });
                        history.push("/logout");
                    }}
                >
                    Logout
                </CCButton>
                <CCButton
                    onClick={async () => {
                        Analytics.record("System Error Closing", {
                            Reason: "Reload",
                        });
                        window.location.reload();
                    }}
                >
                    Reload
                </CCButton>
            </DialogActions>
        </Dialog>
    ) : null;
}
