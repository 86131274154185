import PatientAREnterpriseComponentMetadata from "./PatientAREnterpriseComponentMetadata";

class PatientS0EnterpriseComponentMetadata extends PatientAREnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "PatientS0";
    }
}

export default PatientS0EnterpriseComponentMetadata;