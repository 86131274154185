import PatientAREnterpriseComponentMetadata from "./PatientAREnterpriseComponentMetadata";

class PatientEnterpriseComponentMetadata extends PatientAREnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "Patient";
    }
}

export default PatientEnterpriseComponentMetadata;