import PatientAREnterpriseComponentMetadata from "./PatientAREnterpriseComponentMetadata";

class PatientArchiveEnterpriseComponentMetadata extends PatientAREnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "PatientArchive";
    }
}

export default PatientArchiveEnterpriseComponentMetadata;