import React from "react";
import {withAppContext} from "../../../App";
import PatientAttachment from "../../../components/PatientAttachment";

import Applet from "../Applet";

class PatientAttachmentApplet extends Applet {
    state = {
        Name: "PatientAttachmentApplet",
    };

    render() {
        return <PatientAttachment applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientAttachmentApplet);
