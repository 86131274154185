import {useEffect, useState} from "react";

export function useWindowSize() {

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    function onResize() {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return windowSize;
}


export default function withWindowSize(Component) {
    return (props) => {
        const windowSize = useWindowSize();
        return <Component windowSize={windowSize} {...props} />;
    };
};