import {withAppContext} from "../../../App";
import UserListBaseApplet from "./UserListBaseApplet";

class UserListInvitationApplet extends UserListBaseApplet {

    constructor(props) {
        super(props, {
            name: "UserListInvitationApplet",
            title: "Pending Invitations",
        });
    }
}

export default withAppContext(UserListInvitationApplet);
