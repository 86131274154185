import React from "react";
import {withAppContext} from "../../../App";
import DeviceStatus from "../../../components/DeviceStatus";

import Applet from "../Applet";

class DeviceStatusApplet extends Applet {
    state = {
        Name: "DeviceStatusApplet",
    };

    render() {
        return <DeviceStatus applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(DeviceStatusApplet);
