import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Typography} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import React, {useEffect, useRef, useState} from "react";
import * as Analytics from "../../util/Analytics";
import CloudcathAxios from "../../util/CloudcathAxios";
import {sleep} from "../../util/Sleep";
import * as validators from "../../util/Validators";
import CCButton from "../common/CCButton";
import CCTextField from "../common/CCTextField";
import css_self from "./css/ChangePassDialog.module.scss";

export default function ChangePassDialog(props) {

    const [open, setOpen] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [alertError, setAlertError] = useState(undefined);
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [passwordConfirmError, setPasswordConfirmError] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);

    useEffect(() => {
        Analytics.record("User Password Change Viewed");
    }, []);

    async function submit() {

        const startAt = Date.now();

        const waitVisualMin = async () => {
            const waitDiff = 2000 - (Date.now() - startAt);
            waitDiff > 0 && (await sleep(waitDiff));
        };

        Analytics.record("User Password Change Submitting");

        setSubmitting(true);

        const response = await CloudcathAxios({
            method: "PUT",
            url: "cloudcath/v1/user_change_password",
            json: true,
            data: {
                PreviousPassword: currentPassword,
                NewPassword: password,
            },
        }).catch(e => e.response || e);

        if (response instanceof Error && response.message === "Network Error") {
            setSubmitting(false);
            setAlertError("Connection not available");
            return;
        }

        if (response.status !== 204) {

            await waitVisualMin();

            setSubmitting(false);

            if (response.status === 429) {

                if (response.data.Code === "CC-E47C-D2FC") {

                    setCurrentPasswordError("Incorrect password");

                    setAlertError("Exceeded 5 failed attempts, please wait up to 15 minutes.");

                    Analytics.record("User Password Change Failed", {
                        Reason: "Lockout",
                        "Reason Code": response.data.Code,
                        "HTTP Code": response.status,
                    });

                    return;
                }
            }

            if (response.status === 400) {

                if (response.data.Code === "CC-AAC5-B5E8") {

                    setCurrentPasswordError("Incorrect password");

                    Analytics.record("User Password Change Failed", {
                        Reason: "Invalid",
                        "Reason Code": response.data.Code,
                        "HTTP Code": response.status,
                    });

                    return;
                }
            }

            setAlertError("Service temporarily unavailable");

            Analytics.record("User Password Change Failed", {
                Reason: "Unknown",
                "Reason Code": response.data.Code,
                "HTTP Code": response.status,
            });

            return;
        }

        await waitVisualMin();

        Analytics.record("User Password Changed");

        setOpen(false);
    }

    function validate() {

        setAlertError(undefined);
        setCurrentPasswordError(undefined);
        setPasswordConfirmError(undefined);

        if (!validators.Required(currentPassword)) {
            setCurrentPasswordError("Password must contain a value.");
        } else if (!validators.Minimum(currentPassword, 8)) {
            setCurrentPasswordError("Password must have at least 8 characters.");
        } else if (!validators.Required(password)) {
            setPasswordConfirmError("Password must contain a value.");
        } else if (!validators.Minimum(password, 8)) {
            setPasswordConfirmError("Password must have at least 8 characters.");
        } else if (!validators.NoSpaces(password)) {
            setPasswordConfirmError("Password must have no spaces.");
        } else if (!validators.MatchValues(password, passwordConfirm)) {
            setPasswordConfirmError("Passwords must match.");
        } else {
            return true;
        }

        return false;
    }

    return (
        <Dialog open={open} scroll="body" {...props} >
            <DialogTitle>Change password</DialogTitle>
            <DialogContent style={{padding: "0 16px"}}>
                <form onSubmit={e => e.preventDefault()}>
                    <Typography className={css_self.CurrentPassText} variant="h3">
                        Current
                    </Typography>
                    <CCTextField
                        required
                        value={currentPassword}
                        label="Current password"
                        type={showPassword ? "text" : "password"}
                        onChange={async e => setCurrentPassword(e.target.value)}
                        error={Boolean(currentPasswordError)}
                        helperText={currentPasswordError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={async () => setShowPassword(!showPassword)}
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography className={css_self.NewPassText} variant="h3">
                        New
                    </Typography>
                    <CCTextField
                        required
                        value={password}
                        label="New password"
                        type={showPassword ? "text" : "password"}
                        onChange={async e => setPassword(e.target.value)}
                        error={Boolean(passwordConfirmError)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={async () => setShowPassword(!showPassword)}
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CCTextField
                        required
                        value={passwordConfirm}
                        label="Confirm new password"
                        type={showPassword ? "text" : "password"}
                        onChange={async e => setPasswordConfirm(e.target.value)}
                        error={Boolean(passwordConfirmError)}
                        helperText={passwordConfirmError}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={async () => setShowPassword(!showPassword)}
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {alertError ? (
                        <Alert className={css_self.Alert} variant="filled" severity="error">
                            {alertError}
                        </Alert>
                    ) : undefined}
                </form>
            </DialogContent>
            <DialogActions>
                {showConfirmation ? (
                    <CCButton
                        variant="red"
                        disabled={submitting}
                        onClick={() => setOpen(false)}>
                        Don't Save
                    </CCButton>
                ) : (
                    <CCButton
                        variant="secondary"
                        disabled={submitting}
                        onClick={() => {
                            if (currentPassword || password || passwordConfirm) {
                                setShowConfirmation(true);
                            } else {
                                setOpen(false);
                            }
                        }}>
                        Cancel
                    </CCButton>
                )}
                <CCButton
                    animate={submitting}
                    disabled={submitting}
                    onClick={async () => {
                        if (validate()) {
                            submit();
                        }
                    }}
                >
                    Save
                </CCButton>
            </DialogActions>
        </Dialog>
    );
}
