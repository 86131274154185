import {Link, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import * as Analytics from "../../util/Analytics";
import css_shared from "./css/LoginCommon.module.scss";
import css_self from "./css/LoginSecure1.module.scss";
import {useLoginContext} from "./Login";
import LoginSecureBox from "./LoginSecureBox";

export default function LoginSecure1(props) {

    const [sending, setSending] = useState(false);
    const [errorText, setErrorText] = useState(undefined);

    const {
        preSessionState,
        updatePreSessionState,
        abortLogin,
    } = useLoginContext();

    useEffect(() => {
        Analytics.record("Secure Login Viewed");
    }, []);

    function loginSecureBox(id) {

        const type = id.endsWith("Phone") ? "Phone" : "Email";
        const main = id.startsWith("Main");

        return (
            <LoginSecureBox
                variant="simple"
                type={type}
                main={main}
                value={preSessionState.secureLogin[id]}
                status={preSessionState.secureLogin[`${id}VerifiedState`]}
                disabled={sending}
                onVerificationSending={sending => {
                    setSending(sending);
                    Analytics.record("Secure Login Sending", {
                        Type: type,
                        Main: main,
                    });
                }}
                onVerificationSendSuccess={async () => {
                    Analytics.record("Secure Login Sent", {
                        Type: type,
                        Main: main,
                    });
                    updatePreSessionState(state => {
                        return {...state, secureLogin: {...state.secureLogin, userIdentifierType: id}};
                    });
                }}
                onVerificationSendFailed={response => {
                    if (response instanceof Error && response.message === "Network Error") {
                        setErrorText("Connection not available");
                        return;
                    }
                    setErrorText("Service temporarily unavailable");
                    Analytics.record("Secure Login Failed", {
                        Type: type,
                        Main: main,
                        "HTTP Code": response.status,
                    });
                }}
            />
        );
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Typography data-test-id="LoginSecure1/text:header" className={css_shared.Title}>Secure login</Typography>
            <Typography data-test-id="LoginSecure1/text:instructions" className={css_self.InstructionText}>It looks like you are logging in from an unrecognized device. Please verify your identity.</Typography>
            {loginSecureBox("MainEmail")}
            {loginSecureBox("MainPhone")}
            {loginSecureBox("AdditionalEmail")}
            {loginSecureBox("AdditionalPhone")}
            <Typography data-test-id="LoginSecure1/text:error" className={css_self.ErrorText} style={{visibility: errorText ? undefined : "hidden"}}>{errorText}</Typography>
            <Typography className={css_self.LogoutInsteadText}>
                Having trouble?{" "}
                <Link data-test-id="LoginSecure1/link:logout" href="#" onClick={async e => {
                    e.preventDefault();
                    abortLogin();
                }}>
                    Logout
                </Link>
            </Typography>
        </form>
    );
}
