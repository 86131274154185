import {Link, Tooltip, Typography, useMediaQuery} from "@material-ui/core";
import {CheckCircle, Error as ErrorIcon} from "@material-ui/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import React, {useState} from "react";
import {Red3} from "../../theme/styles";
import * as formatters from "../../util/Formatters";
import CCPulse from "../common/CCPulse";
import css_self from "./css/VerifyCommsBox.module.scss";

export default function VerifyCommsBox({
    variant, // simple | normal
    type, // Phone | Email
    main, // true | false
    required, // true | false
    value,
    status, // UNVERIFIED | VERIFYING | VERIFIED
    timestamp,
    disabled,
    onVerificationSend,
    className,
    ...props
}) {

    variant = variant || "normal";

    const [sending, setSending] = useState(false);

    const smallerWidth = useMediaQuery("(max-width:320px)");

    const emailMaxLength = smallerWidth ? 15 : 20;
    const displayValue =
        type === "Phone"
            ? formatters.Phone(value)
            : formatters.EmailWithEllipsis(value, emailMaxLength);

    return (
        <div
            data-test-id={`VerifyCommsBox/type:${main ? "Main" : "Additional"}${type}`}
            className={[css_self.Box, ...(className !== undefined ? [className] : [])].join(" ")}
            {...{"data-variant": variant}}
        >
            <div className={css_self.Left}>
                <div className={css_self.TitleRow}>
                    {type === "Phone" ? (
                        <PhoneIcon className={css_self.Icon} />
                    ) : (
                        <MailOutlineIcon className={css_self.Icon} />
                    )}
                    <Typography data-test-id="VerifyCommsBox/text:header" className={css_self.Text}>{(main ? "" : "Additional ") + type}</Typography>
                    {required ? (
                        <Typography data-test-id="VerifyCommsBox/text:headerRequired" className={css_self.Text} style={{color: Red3}}>*</Typography>
                    ) : null}
                </div>
                {value && variant !== "simple" ? (
                    <div className={css_self.StatusRow} {...{"data-comm-status": status}}>
                        {status === "VERIFIED" ? (
                            <>
                                <CheckCircle className={css_self.Icon} />
                                <Typography data-test-id="VerifyCommsBox/text:status" className={css_self.Text}>Verified</Typography>
                            </>
                        ) : (
                            <>
                                <ErrorIcon className={css_self.Icon} />
                                <Typography data-test-id="VerifyCommsBox/text:status" className={css_self.Text}>Unverified</Typography>
                            </>
                        )}
                    </div>
                ) : undefined}
                {value ? (
                    displayValue && value !== displayValue ? (
                        <Tooltip arrow title={value}>
                            <Typography data-test-id="VerifyCommsBox/text:value" component="span" className={css_self.Value}>{displayValue}</Typography>
                        </Tooltip>
                    ) : (
                        <Typography data-test-id="VerifyCommsBox/text:value" className={css_self.Value}>{value}</Typography>
                    )
                ) : (
                    <>
                        {variant !== "simple" ? (<div style={{height: "9px"}} />) : undefined}
                        <Typography data-test-id="VerifyCommsBox/text:value" className={css_self.NotAddedText}>Not added</Typography>
                        {variant !== "simple" ? (<div style={{height: "9px"}} />) : undefined}
                    </>
                )}
            </div>
            {value ? (
                <div className={css_self.Right}>
                    {status === "VERIFYING" || status === "VERIFIED" ? (
                        <>
                            <Typography data-test-id="VerifyCommsBox/text:statusTimestampLabel" className={css_self.TimestampLabel}>{status === "VERIFYING" ? "Verification sent on" : "Verified on"}</Typography>
                            <Typography data-test-id="VerifyCommsBox/text:statusTimestamp" className={css_self.Timestamp}>{timestamp}</Typography>
                        </>
                    ) : undefined}
                    {status !== "VERIFIED" ? (
                        !sending && disabled ? (
                            <Typography data-test-id="VerifyCommsBox/link:actionDisabled" className={css_self.ActionTextDisabled}>{status === "VERIFYING" ? "Resend" : "Verify"}</Typography>
                        ) : (
                            <Typography component="span" className={css_self.ActionText}>
                                {sending ? (
                                    <CCPulse data-test-id="VerifyCommsBox/link:actionAnimation" className={css_self.Pulse} DotProps={{className: css_self.PulseDot}} />
                                ) : (
                                    <Link data-test-id="VerifyCommsBox/link:action" variant="inherit" color="inherit" href="#" onClick={e => {
                                        e.preventDefault();
                                        setSending(true);
                                        onVerificationSend();
                                        setSending(false);
                                    }}>{status === "VERIFYING" ? "Resend" : "Verify"}</Link>
                                )}
                            </Typography>
                        )
                    ) : undefined}
                </div>
            ) : variant !== "simple" ? (
                <div className={css_self.Right}>
                    <Typography data-test-id="VerifyCommsBox/text:goToEdit" className={css_self.GoToEdit}>
                        To add, please go to{" "}
                        <Typography className={css_self.Bolder} component="span" variant="inherit">edit</Typography>
                    </Typography>
                </div>
            ) : undefined}
        </div>
    );
}
