import {withAppContext} from "../../../App";
import PatientExpiration from "../../../components/PatientExpiration";

import Applet from "../Applet";

class PatientExpirationApplet extends Applet {
    state = {
        Name: "PatientApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <PatientExpiration applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientExpirationApplet);
