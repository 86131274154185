import {withAppContext} from "../../../App";
import UserListBaseApplet from "./UserListBaseApplet";

class UserListActiveApplet extends UserListBaseApplet {

    constructor(props) {
        super(props, {
            name: "UserListActiveApplet",
            title: "Active Users",
        });
    }
}

export default withAppContext(UserListActiveApplet);
