import mqtt from "mqtt";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {v4 as uuidv4} from "uuid";

export function useMqttClient(sessionContext) {

    const [client, setClient] = useState(undefined);

    const location = useLocation();

    useEffect(() => {

        const loggedIn = sessionContext.valid() &&
            sessionContext.ttl() <= 0 &&
            location.pathname !== "/logout";

        let c = undefined;

        if (client) client.end(true);
        setClient(undefined);

        if (loggedIn) {

            try {

                c = mqtt.connect(`wss://${process.env.REACT_APP_MQTT_ENDPOINT}/mqtt`, {
                    username: "cloudcath?x-amz-customauthorizer-name=cloudcath-s1",
                    password: sessionContext.accessToken,
                    clientId: `web-${uuidv4()}`,
                    protocolId: "MQTT",
                    protocolVersion: 3,
                    clean: true,
                });

                let globalTopic = "cloudcath_web_s1/v1/global";
                let providerTopic = `cloudcath_web_s1/v1/provider/${sessionContext.providerUSID}`;
                let clinicTopic = `cloudcath_web_s1/v1/clinic/${sessionContext.clinicUSID}`;
                let userTopic = `cloudcath_web_s1/v1/user/${sessionContext.userUSID}`;

                c.on("connect", function () {
                    const topics = [globalTopic, providerTopic, clinicTopic, userTopic];
                    c.subscribe(topics, function (err, result) {
                        if (err) {
                            console.error(err);
                        }
                    });
                });

                c.on("reconnect", function () {
                    if (!loggedIn) {
                        c.end();
                    }
                });

                // any container that cares about a message should have its own listener
                // c.on("message", function (topic, payload) {
                //   console.log(
                //     `received mqtt message on topic '${topic}' -- ${payload}`
                //   );
                // });

                // c.on("message", function (topic, payload) {
                //   if (topic === globalTopic) {
                //     console.log(JSON.parse(payload.toString()));
                //   } else if (topic === providerTopic) {
                //       console.log(JSON.parse(payload.toString()));
                //   } else if (topic === clinicTopic) {
                //       console.log(JSON.parse(payload.toString()));
                //   } else if (topic === userTopic) {
                //       console.log(JSON.parse(payload.toString()));
                //   }
                // });

                c.on("error", function (err) {
                    console.error(err);
                    c.end();
                });

            } catch (e) {
                if (c) c.end(true);
                c = undefined;
            }

            setClient(c);
        }

        return () => {
            if (client) client.end(true);
            setClient(undefined);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionContext, location]);

    return client;
}
