import PicklistMetadata from "../PicklistMetadata";

class BillingAccountPicklistMetadata extends PicklistMetadata {
    constructor() {
        super();

        this.bounded = true;
        this.enterpriseComponent = "BillingAccountLookup";
        this.longList = false;
        this.noDelete = true;
        this.noUpdate = true;
        this.noInsert = true;
        this.noMerge = true;
        this.useRealm = false;
        this.searchSpecification = {
            "Type": "Active",
        };
        this.sortSpecification = {
            "CustomerNo": "ASC",
            "SubCustomerNo": "ASC",
        };
        this.static = false;
        this.typeField = "DisplayName";
        this.typeValue = "USID";
        this.typeValue2 = null;
        this.visibilityType = null;
    }
}

export default BillingAccountPicklistMetadata;
