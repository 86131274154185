import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import React from "react";
import "./css/CCTextField.scss";

export default function CCDatePicker({
    fullWidth, // true | false
    margin, // none | dense | normal
    variant, // standard | outlined | filled
    format,
    minDate,
    onChange,
    value,
    children,
    ...props
}) {

    margin = margin !== undefined ? margin : props.label ? "dense" : "none";
    format = format !== undefined ? format : "MM/DD/YYYY";

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                {...{"data-cc-component": "CCDatePicker"}}
                format={format}
                minDate={minDate}
                margin={margin}
                disableToolbar
                autoOk
                variant="inline"
                fullWidth={fullWidth !== undefined ? fullWidth : true}
                onChange={onChange ? (date) => {
                    date = date ? date.format("YYYY-MM-DD") : null;
                    return onChange(date);
                } : undefined}
                value={value}
                inputVariant={variant !== undefined ? variant : "outlined"}
                {...props}
            >
                {children}
            </KeyboardDatePicker>
        </MuiPickersUtilsProvider>
    );
}
