import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, withStyles} from "@material-ui/core";
import moment from "moment";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import {convertToUTCDatePlusCurrentTime} from "../util/utility";
import CCButton from "./common/CCButton";
import CCDatePicker from "./common/CCDatePicker";

class PatientEscalationExpiration extends Component {
    state = {
        updateBackgroundOperation: false,
        Note: "",
        DateOfExpiration: "",
        DateOfExpirationError: false,
        DateOfExpirationHelper: null,

        showConfirmation: false,
        UnassignDevice: false,
        lengthWarningText: null,
        editMode: false,
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;

        if (this.applet.getEnterpriseComponent().getAttributeValue("ExpirationDate") !== null) this.state.editMode = true;

        this.state.DateOfExpiration = this.applet.getEnterpriseComponent().getAttributeValue("ExpirationDate");
        this.state.UnassignDevice = this.applet.getEnterpriseComponent().getAttributeValue("UnassignDevice");
    };

    componentDidMount() {
        this.applet.setMode("Edit");

        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.state.DateOfExpiration === null || this.state.DateOfExpiration === "") {
            this.setState({DateOfExpirationHelper: "Field must contain a value.", DateOfExpirationError: true});
        } else if (!this.state.DateOfExpirationError) {
            let patientEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Patient");
            patientEC.forceAttributeValue("PatientEscalationStatus", "Patient has expired");
            patientEC.forceAttributeValue("PatientState", "Archived");

            if (this.state.UnassignDevice === true) {
                patientEC.setAttributeValue("DeviceUSID", null);
                patientEC.setAttributeValue("DeviceSerialNumber", null);
                patientEC.setAttributeValue("DeviceAssignmentStatus", null);
                patientEC.setAttributeValue("DeviceAssignmentDatetime", null);
            }

            let moment = require("moment");
            this.applet.getEnterpriseComponent().setAttributeValue("EscalationNote", this.state.Note);
            this.applet.getEnterpriseComponent().setAttributeValue("EscalationNoteType", "Patient Expired");
            this.applet.getEnterpriseComponent().setAttributeValue("ExpirationDate", this.state.DateOfExpiration);
            if (!this.state.editMode) {
                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Patient has expired");
            }
            this.applet.getEnterpriseComponent().setAttributeValue("UnassignDevice", this.state.UnassignDevice);
            this.applet.getEnterpriseComponent().setAttributeValue("EndDatetime", convertToUTCDatePlusCurrentTime(moment(this.state.DateOfExpiration)));
            this.applet.save();

            this.applet.getView().setRefresh(true);
        }
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = () => {
        this.setState({updateBackgroundOperation: false});

        this.applet.setMode("View");

        this.applet.getView().initiateAction(null);
    };

    getName = () => {
        return "Expiration";
    };

    handleUnassignDevice = (event) => {
        this.setState({UnassignDevice: event.target.value === "true"});
    };

    inputChangedHandler = (event, formElementId) => {
        if (formElementId === "DateOfExpiration") {
            let isMatch = true;

            if (event?.length > 0) {
                isMatch = !!event.match("^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$");
            }

            if (!event) {
                this.setState({DateOfExpiration: event, DateOfExpirationHelper: "Field must contain a value.", DateOfExpirationError: true});
            } else if (isMatch === false) {
                this.setState({DateOfExpiration: event, DateOfExpirationHelper: "Date improperly formatted.", DateOfExpirationError: true});
            } else if (moment(event).isBefore(this.applet.getEnterpriseComponent().getAttributeValue("StartDatetime"), "day")) {
                this.setState({DateOfExpiration: event, DateOfExpirationHelper: "Invalid date.", DateOfExpirationError: true});
            } else {
                this.setState({DateOfExpiration: event, DateOfExpirationHelper: null, DateOfExpirationError: false});
            }
        } else if (formElementId === "Note") {
            let lengthWarningText = null;
            if (event.target.value.length === 1000) {
                lengthWarningText = "1000 characters allowed";
            }

            this.setState({Note: event.target.value, lengthWarningText: lengthWarningText});
        }
    };

    cancel1Handler = (event, _) => {
        event.preventDefault();
        this.setState({showConfirmation: true});
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    render() {
        let assistiveText = null;
        if (this.state.lengthWarningText !== null) {
            assistiveText = <Typography className={this.props.classes.assistiveText}>{this.state.lengthWarningText}</Typography>;
        }

        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form>
                    <Grid key="id6" item container spacing={2}>
                        <Grid key={1} item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={this.props.classes.addEpisodeText}>By answering and clicking the button below, you are acknowledging that the patient has expired.</Typography>
                            <br />
                            <Typography className={this.props.classes.addEpisodeText}>Upon save, this patient will move to the <b>Archived</b> section.</Typography>
                        </Grid>
                        <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CCDatePicker
                                minDate={this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("StartDatetime")}
                                required={true}
                                helperText={this.state.DateOfExpirationHelper}
                                error={this.state.DateOfExpirationError}
                                key="DateOfExpiration"
                                label="Date of expiration (MM/DD/20YY)"
                                id="DateOfExpiration"
                                onChange={(event) => this.inputChangedHandler(event, "DateOfExpiration")}
                                value={this.state.DateOfExpiration}
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        {!this.state.editMode && this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Patient").getAttributeValue("DeviceUSID") !== null ? (
                            <Grid key={4} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                                <FormControl component="fieldset" margin="dense" required={true}>
                                    <FormLabel component="legend">Would you like to un-assign the CloudCath device from the patient?</FormLabel>
                                    <RadioGroup aria-label="unassignDevice" name="ack1" value={this.state.UnassignDevice} onChange={this.handleUnassignDevice}>
                                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={false} control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>) : null}
                        <Grid key={5} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                key="Note"
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                                fullWidth={true}
                                id="Note"
                                label="Notes"
                                onChange={(event) => this.inputChangedHandler(event, "Note")}
                                value={this.state.Note}
                                variant="outlined"
                                multiline={true}
                                rows={5}
                                inputProps={{maxLength: 1000}}
                            />
                            {assistiveText}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        );

        let content = (
            <React.Fragment>
                {modalContent}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Patient has expired</DialogTitle>
                    <DialogContent>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        <CCButton
                            variant="green"
                            animate={this.state.updateBackgroundOperation === true}
                            onClick={this.submitHandler}>
                            Patient expired
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(PatientEscalationExpiration),
);