import metadataClassFactory from "../../metadata/MetadataClassFactory";

class Relationship {
    constructor(name) {
        let metadataClass = metadataClassFactory("rel", name + "RelationshipMetadata");

        this.metadata = new metadataClass();

        this.parentEnterpriseComponent = null;
        this.childEnterpriseComponent = null;
    };

    getName = () => {
        return this.metadata.getName();
    };

    getParentEnterpriseComponentName = () => {
        return this.metadata.parentEnterpriseComponent;
    };

    getChildEnterpriseComponentName = () => {
        return this.metadata.childEnterpriseComponent;
    };


    getParentEnterpriseComponent = () => {
        return this.parentEnterpriseComponent;
    };

    setParentEnterpriseComponent = (parent) => {
        this.parentEnterpriseComponent = parent;
    };

    getChildEnterpriseComponent = () => {
        return this.childEnterpriseComponent;
    };

    setChildEnterpriseComponent = (child) => {
        this.childEnterpriseComponent = child;
    };

    getSourceAttribute = () => {
        return this.metadata.getSourceAttribute();
    };

    getDestinationAttribute = () => {
        return this.metadata.getDestinationAttribute();
    };

    getInvalidateChildren = () => {
        return this.metadata.getInvalidateChildren();
    };
}

export default Relationship;