import AbstractMetadata from "../AbstractMetadata";

class PicklistMetadata extends AbstractMetadata {
    constructor() {
        super();

        this.bounded = false;
        this.enterpriseComponent = null;
        this.longList = false;
        this.noDelete = true;
        this.noUpdate = true;
        this.noInsert = true;
        this.noMerge = true;
        this.useRealm = true;
        this.searchSpecification = null;
        this.sortSpecification = null;
        this.static = true;
        this.typeField = "Pick Type";
        this.typeValue = null;
        this.typeValue2 = null;
        this.visibilityType = null;
        this.allowNoSelection = false;
        this.allowNoSelectionDisplayValue = "None";
        this.allowNoSelectionValue = null;
    }

    getBounded = () => {
        return this.bounded;
    };

    getEnterpriseComponent = () => {
        return this.enterpriseComponent;
    };

    getLongList = () => {
        return this.longList;
    };

    getNoDelete = () => {
        return this.noDelete;
    };

    getNoInsert = () => {
        return this.noInsert;
    };

    getNoUpdate = () => {
        return this.noUpdate;
    };

    getNoMerge = () => {
        return this.noMerge;
    };

    getUseRealm = () => {
        return this.useRealm;
    };

    getSearchSpecification = () => {
        return this.searchSpecification;
    };

    getSortSpecification = () => {
        return this.sortSpecification;
    };

    getStatic = () => {
        return this.static;
    };

    getTypeField = () => {
        return this.typeField;
    };

    getTypeValue = () => {
        return this.typeValue;
    };

    getTypeValue2 = () => {
        return this.typeValue2;
    };

    getVisibilityType = () => {
        return this.visibilityType;
    };

    getAllowNoSelection = () => {
        return this.allowNoSelection;
    };

    getAllowNoSelectionDisplayValue = () => {
        return this.allowNoSelectionDisplayValue;
    };

    getAllowNoSelectionValue = () => {
        return this.allowNoSelectionValue;
    };
}

export default PicklistMetadata;
