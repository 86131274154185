import React from "react";
import {withAppContext} from "../../../App";

import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class TreatmentDetailApplet extends Applet {
    state = {
        Name: "TreatmentDetailApplet",
        Modes: {
            View: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Medication",
                            },
                            {
                                id: "Dose",
                            },
                            {
                                id: "Unit",
                            },
                            {
                                id: "Formulation",
                            },
                            {
                                id: "Frequency",
                            },
                            {
                                id: "Route",
                            },
                            {
                                id: "StartDateDisplay",
                            },
                            {
                                id: "EndDateDisplay",
                            },
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "IsSelfAdministered",
                            },
                            {
                                id: "AssociatedNote",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            New: {
                AllowAttachments: true,
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Medication",
                            },
                            {
                                id: "Dose",
                            },
                            {
                                id: "Unit",
                            },
                            {
                                id: "Formulation",
                            },
                            {
                                id: "Frequency",
                            },
                            {
                                id: "Route",
                            },
                            {
                                id: "StartDate",
                            },
                            {
                                id: "EndDate",
                            },
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "IsSelfAdministered",
                            },
                            {
                                id: "Note",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            Edit: {
                AllowAttachments: true,
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Medication",
                            },
                            {
                                id: "Dose",
                            },
                            {
                                id: "Unit",
                            },
                            {
                                id: "Formulation",
                            },
                            {
                                id: "Frequency",
                            },
                            {
                                id: "Route",
                            },
                            {
                                id: "StartDate",
                            },
                            {
                                id: "EndDate",
                            },
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "IsSelfAdministered",
                            },
                            {
                                id: "AssociatedNote",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
        },
        Layout: {
            totalWidth: "36em",
        },
        Controls: {
            Medication: {
                id: "Medication",
                enterpriseComponentAttribute: "Medication",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                errors: [
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not contain all spaces.",
                    },
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Medication",
                    margin: "dense",
                },
                ip: {},
                type: "TextField",
                requiresConfirmation: false,
            },
            Dose: {
                id: "Dose",
                enterpriseComponentAttribute: "Dose",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 9,
                        md: 9,
                        lg: 9,
                        xl: 9,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 9,
                        md: 9,
                        lg: 9,
                        xl: 9,
                    },
                    "New": {
                        xs: 12,
                        sm: 9,
                        md: 9,
                        lg: 9,
                        xl: 9,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not contain all spaces.",
                    },
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Dose",
                    margin: "dense",
                },
                ip: {},
            },
            Unit: {
                id: "Unit",
                enterpriseComponentAttribute: "Unit",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3,
                    },
                    "New": {
                        xs: 12,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not contain all spaces.",
                    },
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: "Provide custom value",
                    label: "Unit",
                    margin: "dense",
                },
                ip: {},
            },
            Formulation: {
                id: "Formulation",
                enterpriseComponentAttribute: "Formulation",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not contain all spaces.",
                    },
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: "Provide custom value",
                    label: "Formulation",
                    margin: "dense",
                },
                ip: {},
            },
            Frequency: {
                id: "Frequency",
                enterpriseComponentAttribute: "Frequency",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not contain all spaces.",
                    },
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: "Provide custom value",
                    label: "Frequency",
                    margin: "dense",
                },
                ip: {},
            },
            Route: {
                id: "Route",
                enterpriseComponentAttribute: "Route",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not contain all spaces.",
                    },
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: "Provide custom value",
                    label: "Route",
                    margin: "dense",
                },
                ip: {},
            },
            StartDate: {
                id: "StartDate",
                enterpriseComponentAttribute: "StartDate",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Date",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Optional Pattern",
                        value: null,
                        errorText: "Date improperly formatted.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Start date (MM/DD/20YY)",
                    margin: "dense",
                    minDate: this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("StartDatetime"),
                },
                ip: {},
            },
            StartDateDisplay: {
                id: "StartDateDisplay",
                enterpriseComponentAttribute: "StartDateDisplay",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Start date",
                    margin: "dense",
                },
                ip: {},
            },
            EndDate: {
                id: "EndDate",
                enterpriseComponentAttribute: "EndDate",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Date",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Optional Pattern",
                        value: null,
                        errorText: "Date improperly formatted.",
                    },
                    {
                        validator: "Is Same Or After Day",
                        value: null,
                        errorText: "Value must be same or after Start Date",
                        field: "StartDate",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "End date (MM/DD/20YY)",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            EndDateDisplay: {
                id: "EndDateDisplay",
                enterpriseComponentAttribute: "EndDateDisplay",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "End date",
                    margin: "dense",
                },
                ip: {},
            },
            EnteredByUSID: {
                id: "EnteredByUSID",
                enterpriseComponentAttribute: "EnteredByUSID",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                default: {
                    type: "Computed",
                    value: "User USID",
                },
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Entered By",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            IsSelfAdministered: {
                id: "IsSelfAdministered",
                enterpriseComponentAttribute: "IsSelfAdministered",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "Checkbox",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "This treatment is self administered",
                    margin: "dense",
                },
                ip: {},
            },
            Note: {
                id: "Note",
                enterpriseComponentAttribute: "Note",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: true,
                    placeholder: null,
                    label: "Note",
                    margin: "dense",
                    rows: 2,
                },
                ip: {},
                other: {},
            },
            AssociatedNote: {
                id: "AssociatedNote",
                enterpriseComponentAttribute: "AssociatedNote",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: true,
                    placeholder: null,
                    label: "Note",
                    margin: "dense",
                    rows: 2,
                },
                ip: {},
            },
            DropZone: {
                id: "DropZone",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "DropZone",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    margin: "dense",
                },
                ip: {},
            },
        },
    };

    render() {
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="medication"
                                saveAndNewAction="AddTreatment"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(TreatmentDetailApplet);
