import {withAppContext} from "../../../App";
import RevokeInvitation from "../../../components/RevokeInvitation";
import Applet from "../Applet";

class RevokeInvitationApplet extends Applet {
    state = {
        Name: "RevokeInvitationApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <RevokeInvitation applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(RevokeInvitationApplet);
