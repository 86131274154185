import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1InvitationIDOCToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class InvitationEnterpriseComponent extends EnterpriseComponent {
    performSingleRecordRefresh = (primaryKey) => {
        let options = {
            method: "GET",
            url: "cloudcath/v1/invitations",
            json: true,
            params: {
                "USID": primaryKey,
            },
            headers: {
                "idoc-request": "v1.Invitation",
            },
        };
        this.notifySingleRecordRefreshStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.refreshEnterpriseComponentSuccess(V1InvitationIDOCToArray(response.data));
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    performQuery = (start, pageSize, foreignKey) => {
        let params = {};
        if (foreignKey === null) {
            params = {
                "Limit": pageSize,
                "Offset": start,
            };
        } else {
            params = {
                "Limit": pageSize,
                "Offset": start,
            };
        }

        params["Sort"] = this.getFormattedSortSpecification();

        let keys = Object.keys(this.getSearchSpecification());
        for (let i = 0; i < keys.length; i++) {
            params[keys[i]] = this.getSearchSpecification()[keys[i]];
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/invitations",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.Invitation",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1InvitationIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.Invitation";
        payload.CloudcathMessage.Invitation = this.preparePUTPayload();

        return payload;
    };

    performUpdate = () => {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "cloudcath/v1/invitations",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.Invitation",
                "idoc-response": "v1.Invitation",
            },
        };
        this.notifyUpdateStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1InvitationIDOCToArray(response.data));
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let invitation = {};

        let row = this.getBuffer()[this.getRecordPointer()];

        invitation["USID"] = row["USID"];
        invitation["ClinicUSID"] = row["ClinicUSID"];
        invitation["ProviderUserUSID"] = row["ProviderUserUSID"];
        invitation["InvitationStatus"] = row["InvitationStatus"];
        invitation["InvitationDatetime"] = row["InvitationDatetime"];
        invitation["UserRole"] = row["UserRole"];
        invitation["Realm"] = row["Realm"];

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.Invitation";
        payload.CloudcathMessage.Invitation = invitation;

        return payload;
    };

    performInsert = () => {
        let payload = this.enterpriseComponentPostIDOC();

        let options = {
            method: "POST",
            url: "cloudcath/v1/invitations",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.Invitation",
                "idoc-response": "v1.Invitation",
            },
        };
        this.notifyInsertStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1InvitationIDOCToArray(response.data));
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentDeleteIDOC = () => {
        let payload = {};

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.Invitation";
        payload.CloudcathMessage.Invitation = this.prepareDeletePayload();

        return payload;
    };

    performDelete = () => {
        let payload = this.enterpriseComponentDeleteIDOC();

        let options = {
            method: "DELETE",
            url: "cloudcath/v1/invitations",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.Invitation",
                "idoc-response": "v1.Invitation",
            },
        };
        this.notifyDeleteStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.deleteEnterpriseComponentSuccess(response.data);
            })
            .catch(error => {
                this.deleteEnterpriseComponentFailed(error);
            });
    };
}

export default InvitationEnterpriseComponent;