import {Checkbox, FormControlLabel, Link, Tooltip, Typography, useMediaQuery} from "@material-ui/core";
import axios from "axios";
import React, {useEffect, useState} from "react";
import Countdown, {zeroPad} from "react-countdown-now";
import * as Analytics from "../../util/Analytics";
import * as formatters from "../../util/Formatters";
import {sleep} from "../../util/Sleep";
import * as validators from "../../util/Validators";
import CCButton from "../common/CCButton";
import CCInputLabel from "../common/CCInputLabel";
import CCTextField from "../common/CCTextField";
import css_self from "./css/LoginSecure2.module.scss";
import {useLoginContext} from "./Login";

export default function LoginSecure2(props) {

    const [resentAt, setResentAt] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const [rememberDevice, setRememberDevice] = useState(false);
    const [errorText, setErrorText] = useState(undefined);
    const [secureCode, setSecureCode] = useState("");

    const {
        preSessionState,
        updatePreSessionState,
        abortLogin,
        login,
    } = useLoginContext();

    const smallerWidth = useMediaQuery("(max-width:320px)");

    const userIdentifierType = preSessionState.secureLogin.userIdentifierType;
    const userIdentifier = preSessionState.secureLogin[userIdentifierType];
    const usedEmail = validators.IsEmail(userIdentifier);

    useEffect(() => {
        Analytics.record("Secure Login Code Viewed", {
            Type: userIdentifierType.endsWith("Phone") ? "Phone" : "Email",
            Main: userIdentifierType.startsWith("Main"),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updatePreSessionState(state => {
            return {
                ...state,
                secureLogin: {
                    ...state.secureLogin,
                    rememberDevice: rememberDevice,
                    secureCode: secureCode,
                },
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rememberDevice, secureCode]);

    if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            sessionStorage.setItem("LoginSecure2/input:accessToken", preSessionState.accessToken);
            return () => sessionStorage.removeItem("LoginSecure2/input:accessToken");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    }

    async function submit() {

        const startAt = Date.now();

        Analytics.record("Secure Login Code Submitting", {
            Type: userIdentifierType.endsWith("Phone") ? "Phone" : "Email",
            Main: userIdentifierType.startsWith("Main"),
        });

        setSubmitting(true);

        const response = await login();

        if (response instanceof Error && response.message === "Network Error") {
            setSubmitting(false);
            setErrorText("Connection not available");
            return;
        }

        if (response.status === 200) {

            Analytics.record("Secure Login Code Accepted", {
                Type: userIdentifierType.endsWith("Phone") ? "Phone" : "Email",
                Main: userIdentifierType.startsWith("Main"),
            });

            return;
        }

        const waitDiff = 2000 - (Date.now() - startAt);
        waitDiff > 0 && (await sleep(waitDiff));

        setSubmitting(false);

        if (response.data.Code === "CC-7861-6849") {

            setErrorText("Expired verification code");

            Analytics.record("Secure Login Code Failed", {
                Type: userIdentifierType.endsWith("Phone") ? "Phone" : "Email",
                Main: userIdentifierType.startsWith("Main"),
                Reason: "Expired",
                "Reason Code": response.data.Code,
                "HTTP Code": response.status,
            });

            return;
        }

        if (response.data.Code === "CC-1799-790D") {

            setErrorText("Incorrect verification code");

            Analytics.record("Secure Login Code Failed", {
                Type: userIdentifierType.endsWith("Phone") ? "Phone" : "Email",
                Main: userIdentifierType.startsWith("Main"),
                Reason: "Invalid",
                "Reason Code": response.data.Code,
                "HTTP Code": response.status,
            });

            return;
        }

        setErrorText("Service temporarily unavailable");

        Analytics.record("Secure Login Code Failed", {
            Type: userIdentifierType.endsWith("Phone") ? "Phone" : "Email",
            Main: userIdentifierType.startsWith("Main"),
            Reason: "Unknown",
            "Reason Code": response.data.Code,
            "HTTP Code": response.status,
        });
    }

    function resend() {
        const type = userIdentifierType.endsWith("Phone") ? "Phone" : "Email";
        const main = userIdentifierType.startsWith("Main");
        return axios({
            method: "PUT",
            baseURL: process.env.REACT_APP_API_URL,
            url: "cloudcath/v1/authenticate_send_code",
            json: true,
            headers: {
                "X-Cloudcath-AccessToken": preSessionState.accessToken,
            },
            params: {
                Type: `${main ? "Main" : "Additional"}${type}`,
            },
        }).catch(e => e.response || e);
    }

    const emailMaxLength = smallerWidth ? 12 : 25;
    const displayValue = !usedEmail ? userIdentifier : formatters.EmailWithEllipsis(userIdentifier, emailMaxLength);

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Typography data-test-id="LoginSecure2/text:instructions" className={css_self.InstructionText}>
                We have sent the verification code to{" "}
                {usedEmail && displayValue !== userIdentifier ? (
                    <Tooltip arrow title={userIdentifier}>
                        <Typography data-test-id="LoginSecure2/text:userIdentifier" data-test-value={userIdentifier} component="span" className={css_self.Bolder}>{displayValue}</Typography>
                    </Tooltip>
                ) : (
                    <Typography data-test-id="LoginSecure2/text:userIdentifier" data-test-value={userIdentifier} component="span" className={css_self.Bolder}>{displayValue}</Typography>
                )}
                . Please check your {usedEmail ? "email" : "phone"} and enter the code below.
            </Typography>
            <Typography className={css_self.RedoText}>
                <Link data-test-id="LoginSecure2/link:redo" variant="inherit" color="inherit" href="#" onClick={e => {

                    e.preventDefault();

                    Analytics.record("Secure Login Select Another", {
                        Type: userIdentifierType.endsWith("Phone") ? "Phone" : "Email",
                        Main: userIdentifierType.startsWith("Main"),
                    });

                    updatePreSessionState(state => {
                        return {...state, secureLogin: {...state.secureLogin, userIdentifierType: undefined}};
                    });
                }}>
                    Select another option
                </Link>
            </Typography>
            {resentAt ? (
                <Typography data-test-id="LoginSecure2/link:resendCountdown" className={css_self.RedoDisabledText}>
                    Resend the code
                    <Countdown
                        date={resentAt + 60000}
                        onComplete={() => setResentAt(undefined)}
                        renderer={({hours, minutes, seconds, completed}) => {
                            return ` (${zeroPad(minutes * 60 + seconds)}s)`;
                        }}
                    />
                </Typography>
            ) : (
                <Typography className={css_self.RedoText}>
                    <Link data-test-id="LoginSecure2/link:resend" variant="inherit" color="inherit" href="#" onClick={async e => {

                        e.preventDefault();

                        Analytics.record("Secure Login Resending", {
                            Type: userIdentifierType.endsWith("Phone") ? "Phone" : "Email",
                            Main: userIdentifierType.startsWith("Main"),
                        });

                        resend();

                        setResentAt(Date.now());
                    }}>
                        Resend the code
                    </Link>
                </Typography>
            )}
            <CCInputLabel className={css_self.MuiInputLabel}>Verification code</CCInputLabel>
            <CCTextField
                placeholder="Enter your verification code"
                value={secureCode}
                onChange={async e => {
                    const pattern = /^([a-zA-Z]){0,8}$/i;
                    if (e.target.value === "" || pattern.test(e.target.value)) {
                        setSecureCode(e.target.value.toUpperCase());
                    }
                }}
                inputProps={{
                    "data-test-id": "LoginSecure2/input:code",
                    className: css_self["MuiInputBase-input"],
                    maxLength: 8,
                }}
                helperText={errorText ? <span data-test-id="LoginSecure2/text:error" className="Mui-error">{errorText}</span> : undefined}
            />
            <Typography className={css_self.VerificationNoticeText}>Your verification code may take a few moments to arrive. Do not share this code with anyone.</Typography>
            <br />
            <div>
                <FormControlLabel
                    control={<Checkbox data-test-id="LoginSecure2/checkbox:remember" size="small" color="primary" onChange={e => setRememberDevice(e.target.checked)} />}
                    label={<Typography className={css_self.RememberThisDeviceText}>Remember this device</Typography>}
                />
            </div>
            <CCButton
                data-test-id="LoginSecure2/button:continue"
                className={errorText ? css_self.ButtonWithErrorShown : css_self.Button}
                margin="none"
                animate={submitting}
                disabled={submitting || secureCode.length !== 8}
                onClick={() => {
                    setErrorText(undefined);
                    submit();
                }}
            >
                Continue
            </CCButton>
            <Typography className={css_self.LogoutInsteadText}>
                Having trouble?{" "}
                <Link data-test-id="LoginSecure2/link:logout" href="#" onClick={async e => {
                    e.preventDefault();
                    abortLogin();
                }}>
                    Logout
                </Link>
            </Typography>
        </form>
    );
}
