import {Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";

class UnassignDevice extends Component {
    state = {
        showConfirmation: false,
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;
    }

    componentDidMount() {
        this.applet.setMode("Edit");
    }

    unassignSubmitHandler = (event) => {
        event.preventDefault();

        this.applet.getEnterpriseComponent().setAttributeValue("DeviceAssignmentStatus", null);
        this.applet.getEnterpriseComponent().setAttributeValue("DeviceUSID", null);
        this.applet.getEnterpriseComponent().setAttributeValue("DeviceSerialNumber", null);
        this.applet.getEnterpriseComponent().setAttributeValue("DeviceAssignmentDatetime", null);
        this.applet.save();
        this.applet.cancel();
    };

    unassignHandler = (event) => {
        event.preventDefault();
        this.setState({showConfirmation: true});
    };

    cancelHandler = (event) => {
        event.preventDefault();
        this.applet.cancel();
    };

    render() {
        return (
            <React.Fragment>
                <Dialog open={true} scroll="body" PaperProps={{style: {minWidth: 500}}}>
                    <DialogTitle>Unassign Device</DialogTitle>
                    <DialogContent style={{padding: "0 16px"}}>
                        {this.state.showConfirmation ? (
                            <Typography component="p" style={{fontSize: 14}}>Are you sure you would like to unassign the device from this patient?</Typography>
                        ) : (
                            <form onSubmit={e => e.preventDefault()}>
                                <TextField
                                    key="DeviceSerialNumber"
                                    margin="dense"
                                    disabled={true}
                                    label="Serial number"
                                    InputLabelProps={{shrink: true}}
                                    fullWidth={true}
                                    id="DeviceSerialNumber"
                                    onChange={(event) => this.inputChangedHandler(event, "DeviceSerialNumber")}
                                    value={this.applet.getEnterpriseComponent().getAttributeValue("DeviceSerialNumber")}
                                    variant="outlined"
                                />
                            </form>
                        )}
                        <div style={{height: "30px"}} />
                    </DialogContent>
                    <DialogActions>
                        <CCButton variant="secondary" onClick={this.cancelHandler}>
                            Cancel
                        </CCButton>
                        <CCButton
                            variant={this.state.showConfirmation ? "red" : "primary"}
                            onClick={this.state.showConfirmation ? this.unassignSubmitHandler : this.unassignHandler}>
                            Unassign Device
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(UnassignDevice),
);