import React, {useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import DeviceView from "../src/eoec/view/impl/DeviceView";
import PatientDetailView from "../src/eoec/view/impl/PatientDetailView";
import PatientView from "../src/eoec/view/impl/PatientView";
import UserView from "../src/eoec/view/impl/UserView";
import {useAppContext} from "./App";
import Login from "./components/login/Login";
import Logout from "./components/logout/Logout";
import TurbidityDataReport from "./components/turbidity/TurbidityDataReport";
import * as Analytics from "./util/Analytics";
import {getPageTimings, NAV_TYPE_BACK_FORWARD, NAV_TYPE_NAVIGATE, NAV_TYPE_RELOAD, wasPageNavType, wasPageReloaded} from "./util/Location";
import * as userAgent from "./util/UserAgent";


export default function Routes({reload, ...props}) {

    const {sessionContext} = useAppContext();

    const location = useLocation();

    const loggedIn = sessionContext.valid() && location.pathname !== "/logout";

    useEffect(() => {

        const startAt = Date.now();

        if (wasPageReloaded()) {
            Analytics.record("Page Reloaded", {
                "HTTP URL": location.pathname,
            });
        }

        function sendPageTimings() {

            if (document.readyState !== "complete") return;

            document.removeEventListener("readystatechange", sendPageTimings);

            const pageTimings = getPageTimings();

            Analytics.record("Page Loaded", {
                "HTTP URL": location.pathname,
                "DNS Lookup Time": pageTimings.dns,
                "TCP Connect Time": pageTimings.tcp,
                "SSL Negotiation Time": pageTimings.ssl ? pageTimings.ssl : undefined,
                "Time To First Byte": pageTimings.ttfb,
                "Page Load Time": pageTimings.page,
                "Page Load Time Seconds": (Math.round((pageTimings.page / 100) / 5) * 5) / 10, // 0.5 step
                "DOM Load Time": pageTimings.dom,
                "Time to First Render": startAt - pageTimings.start,
                "Navigation Type":
                    wasPageNavType(NAV_TYPE_RELOAD) ? "reload" :
                        wasPageNavType(NAV_TYPE_BACK_FORWARD) ? "back_forward" :
                            wasPageNavType(NAV_TYPE_NAVIGATE) ? "navigate" :
                                undefined,
            });
        }

        if (document.readyState === "complete") {
            sendPageTimings();
        } else {
            document.addEventListener("readystatechange", sendPageTimings);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let routes = (
        <Switch>
            <Route exact path="/" component={PatientView} />
            {!userAgent.isMobile() && sessionContext.userRole === "Admin" ? (
                <Route exact path="/users" component={UserView} />
            ) : undefined}
            {!userAgent.isMobile() ? (
                <Route exact path="/devices" component={DeviceView} />
            ) : undefined}
            <Route exact path="/detail" component={PatientDetailView} />
            <Route exact path="/turbidityDataReport" component={TurbidityDataReport} />
            <Route exact path="/logout" component={Logout} />
            <Route path="/">
                <Redirect to={{
                    pathname: "/",
                    state: {
                        referrer: {
                            pathname: location.pathname,
                            search: location.search,
                        },
                    },
                }} />
            </Route>
        </Switch>
    );

    if (!loggedIn) {
        routes = (
            <Switch>
                <Route exact path="/logout" component={Logout} />
                <Route path="/" component={Login} />
            </Switch>
        );
    }

    return routes;
}
