import {Pagination} from "@material-ui/lab";
import React from "react";
import "./css/CCPagination.scss";

export default function CCPagination({children, ...props}) {
    return (
        <Pagination {...{"data-cc-component": "CCPagination"}} {...props}>
            {children}
        </Pagination>
    );
}
