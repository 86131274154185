import AttachmentEnterpriseComponentMetadata from "./AttachmentEnterpriseComponentMetadata";

class SymptomAttachmentEnterpriseComponentMetadata extends AttachmentEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "SymptomAttachment";
    }
}

export default SymptomAttachmentEnterpriseComponentMetadata;