import CloudcathTable from "../../../../src/components/CloudcathTable";
import {withAppContext} from "../../../App";
import Applet from "../Applet";

class PatientEscalationCommunicationListApplet extends Applet {

    selectCommunicationHandler = (index) => {
        this.selectHandler(index);

        this.getView().initiateAction("ViewCommunication");
    };

    render() {
        return <CloudcathTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Communications"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               headerRenderer={this.state.HeaderRenderer}
                               selectors={this.state.Selectors}
                               tableHeaderType="Type2"
                               sort={this.state.Sort} />;
    }

    state = {
        Name: "PatientEscalationCommunicationListApplet",
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        HeaderRenderer: "Communication Header Renderer",
        Selectors: {
            "Select": this.selectCommunicationHandler,
        },
        Placement: {
            "xl": [
                {
                    id: "CommunicationPurpose",
                },
                {
                    id: "CommunicationType",
                },
                {
                    id: "Destination",
                },
                {
                    id: "CommunicationDatetime",
                },
            ],
        },
        Columns: {
            "CommunicationPurpose": {
                id: "CommunicationPurpose",
                width: "250px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Purpose",
                align: "left",
            },
            "CommunicationType": {
                id: "CommunicationType",
                width: "100px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Type",
                align: "left",
            },
            "Destination": {
                id: "Destination",
                width: "250px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Destination",
                align: "left",
            },
            "CommunicationDatetime": {
                id: "CommunicationDatetime",
                width: "150px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Date",
                align: "left",
            },
        },
        Sort: [
            {
                "identifier": 1,
                "default": true,
                "visible": false,
                "options": {
                    "RowIdentifier": "DESC",
                },
            },
        ],
    };

}

export default withAppContext(PatientEscalationCommunicationListApplet);
