import {IconButton, Link, Typography} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import axios from "axios";
import moment from "moment";
import React, {useEffect, useState} from "react";
import * as Analytics from "../../util/Analytics";
import {sleep} from "../../util/Sleep";
import VerifyCommsBox from "../user/VerifyCommsBox";
import css_shared from "./css/LoginCommon.module.scss";
import css_self from "./css/LoginVerifyComms.module.scss";
import {useLoginContext} from "./Login";

export default function LoginVerifyComms(props) {

    const [errorText, setErrorText] = useState(undefined);
    const [reloading, setReloading] = useState(false);

    const {
        preSessionState,
        abortLogin,
        login,
    } = useLoginContext();

    useEffect(() => {
        Analytics.record("Initial Verify Comms Viewed");
    }, []);

    async function reload() {

        const startAt = Date.now();

        setReloading(true);

        const response = await login();

        if (response instanceof Error && response.message === "Network Error") {
            setReloading(false);
            setErrorText("Connection not available");
            return;
        }

        const waitDiff = 2000 - (Date.now() - startAt);
        waitDiff > 0 && (await sleep(waitDiff));

        if (response.status !== 200) {
            setReloading(false);
            setErrorText("Service temporarily unavailable");
            return;
        }

        if (response.data.Code === "CC-41E8-9122") {
            setReloading(false);
            return; // not verified yet
        }

        Analytics.record("Initial Verify Comms Complete");
    }

    async function resend(type, main) {

        const startAt = Date.now();

        setReloading(true);
        setErrorText(undefined);

        Analytics.record("Initial Verify Comms Sending", {
            Type: type,
            Main: main,
        });

        const response = await axios({
            method: "POST",
            baseURL: process.env.REACT_APP_API_URL,
            url: "cloudcath/v1/authenticate_verify_comms",
            json: true,
            headers: {
                "X-Cloudcath-AccessToken": preSessionState.accessToken,
            },
            params: {
                Type: `${main ? "Main" : "Additional"}${type}`,
            },
        }).catch(e => e.response || e);

        if (response instanceof Error && response.message === "Network Error") {
            setReloading(false);
            setErrorText("Connection not available");
            return;
        }
        if (response.status === 204) {

            Analytics.record("Initial Verify Comms Sent", {
                Type: type,
                Main: main,
            });

            await login();
        }

        const waitDiff = 2000 - (Date.now() - startAt);
        waitDiff > 0 && (await sleep(waitDiff));

        setReloading(false);

        if (response.status !== 204) {

            setErrorText("Service temporarily unavailable");

            Analytics.record("Initial Verify Comms Failed", {
                Type: type,
                Main: main,
                "HTTP Code": response.status,
            });
        }
    }

    function displayDate(value) {
        if (!value) return null;
        return moment.utc(value).local().format("MMMM Do YYYY");
    }

    function verifyCommsBox(id) {

        const type = id.endsWith("Phone") ? "Phone" : "Email";
        const main = id.startsWith("Main");

        return (
            <VerifyCommsBox
                variant="simple"
                type={type}
                main={main}
                required={id === "MainEmail"}
                value={preSessionState.verifyComms[id]}
                status={preSessionState.verifyComms[`${id}VerifiedState`]}
                timestamp={displayDate(preSessionState.verifyComms[`${id}VerifiedDatetime`])}
                disabled={reloading}
                onVerificationSend={() => resend(type, main)}
            />
        );
    }

    return (
        <>
            <Typography data-test-id="LoginVerifyComms/text:header" component="span" className={[css_self.Title, css_shared.Title].join(" ")}>
                Verify communications
                <div style={{flexGrow: 1}} />
                <IconButton
                    data-test-id="LoginVerifyComms/button:reload"
                    className={[css_self.RefreshIconButton, ...(reloading ? [css_self.RefreshIconSpin] : [])].join(" ")}
                    disabled={reloading}
                    onClick={() => {
                        Analytics.record("Initial Verify Comms Reload");
                        setErrorText(undefined);
                        reload();
                    }}
                >
                    <CachedIcon />
                </IconButton>
            </Typography>
            {verifyCommsBox("MainEmail")}
            {verifyCommsBox("MainPhone")}
            {verifyCommsBox("AdditionalEmail")}
            {verifyCommsBox("AdditionalPhone")}
            <Typography data-test-id="LoginVerifyComms/text:error" className={css_self.ErrorText}>{errorText ?? "Main email verification is required"}</Typography>
            <Typography className={css_self.LogoutInsteadText}>
                Having trouble?{" "}
                <Link data-test-id="LoginVerifyComms/link:logout" href="#" onClick={async e => {
                    e.preventDefault();
                    abortLogin();
                }}>Logout</Link>
            </Typography>
        </>
    );
}
