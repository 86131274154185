import {withAppContext} from "../../../App";

import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class SymptomDetailApplet extends Applet {
    state = {
        Name: "SymptomDetailApplet",
        Mode: "New",
        Modes: {
            View: {
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "ObservationDateDisplay",
                            },
                        ],
                    },
                    {
                        "identifier": "group2",
                        "type": "checkbox",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "AbdominalPain",
                            },
                            {
                                id: "Fever",
                            },
                            {
                                id: "Nausea",
                            },
                            {
                                id: "CloudyFluid",
                            },
                            {
                                id: "Vomiting",
                            },
                        ],
                    },
                    {
                        "identifier": "group3",
                        "type": "ondemand",
                        "selector": "button",
                        "characteristics": [],
                        "increments": [
                            {
                                "membership": [
                                    {
                                        id: "Other1",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other2",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other3",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other4",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other5",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        "identifier": "group4",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "AssociatedNote",
                            },
                        ],
                    },
                    {
                        "id": "group5",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            New: {
                AllowAttachments: true,
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "ObservationDate",
                            },
                        ],
                    },
                    {
                        "identifier": "group2",
                        "type": "checkbox",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "AbdominalPain",
                            },
                            {
                                id: "Fever",
                            },
                            {
                                id: "Nausea",
                            },
                            {
                                id: "CloudyFluid",
                            },
                            {
                                id: "Vomiting",
                            },
                        ],
                    },
                    {
                        "identifier": "group3",
                        "type": "ondemand",
                        "selector": "button",
                        "characteristics": [],
                        "increments": [
                            {
                                "membership": [
                                    {
                                        id: "Other1",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other2",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other3",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other4",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other5",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        "identifier": "group4",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "Note",
                            },
                        ],
                    },
                    {
                        "id": "group5",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            Edit: {
                AllowAttachments: true,
                Groups: [
                    {
                        "identifier": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "ObservationDate",
                            },
                        ],
                    },
                    {
                        "identifier": "group2",
                        "type": "checkbox",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "AbdominalPain",
                            },
                            {
                                id: "Fever",
                            },
                            {
                                id: "Nausea",
                            },
                            {
                                id: "CloudyFluid",
                            },
                            {
                                id: "Vomiting",
                            },
                        ],
                    },
                    {
                        "identifier": "group3",
                        "type": "ondemand",
                        "selector": "button",
                        "characteristics": [],
                        "increments": [
                            {
                                "membership": [
                                    {
                                        id: "Other1",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other2",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other3",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other4",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "Other5",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        "identifier": "group4",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "Note",
                            },
                        ],
                    },
                    {
                        "id": "group5",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
        },
        Layout: {
            totalWidth: "36em",
        },
        Controls: {
            ObservationDate: {
                id: "ObservationDate",
                enterpriseComponentAttribute: "ObservationDate",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "Date",
                requiresConfirmation: false,
                default: {},
                errors: [
                    {
                        validator: "Required Pattern",
                        value: null,
                        errorText: "Date improperly formatted.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Symptom observation date (MM/DD/20YY)",
                    margin: "dense",
                    minDate: this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("StartDatetime"),
                },
                ip: {},
            },
            ObservationDateDisplay: {
                id: "ObservationDateDisplay",
                enterpriseComponentAttribute: "ObservationDateDisplay",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                default: {},
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Symptom observation date (MM/DD/20YY)",
                    margin: "dense",
                },
                ip: {},
            },
            AbdominalPain: {
                id: "AbdominalPain",
                enterpriseComponentAttribute: "AbdominalPain",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Checkbox",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "Abdominal pain",
                    margin: "dense",
                },
                ip: {},
                other: {
                    marginBottom: 0,
                },
            },
            Fever: {
                id: "Fever",
                enterpriseComponentAttribute: "Fever",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Checkbox",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "Fever",
                    margin: "dense",
                },
                ip: {},
                other: {
                    marginBottom: 0,
                },
            },
            Nausea: {
                id: "Nausea",
                enterpriseComponentAttribute: "Nausea",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Checkbox",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "Nausea",
                    margin: "dense",
                },
                ip: {},
                other: {
                    marginBottom: 0,
                },
            },
            CloudyFluid: {
                id: "CloudyFluid",
                enterpriseComponentAttribute: "CloudyFluid",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Checkbox",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "Cloudy fluid",
                    margin: "dense",
                },
                ip: {},
                other: {
                    marginBottom: 0,
                },
            },
            Vomiting: {
                id: "Vomiting",
                enterpriseComponentAttribute: "Vomiting",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Checkbox",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "Vomiting",
                    margin: "dense",
                },
                ip: {},
                other: {},
            },
            Other1: {
                id: "Other1",
                enterpriseComponentAttribute: "Other1",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "One Field Must Be Provided",
                        value: null,
                        errorText: "At least one symptom must be provided.",
                        field: ["AbdominalPain", "Fever", "Nausea", "CloudyFluid", "Vomiting", "Other1"],
                    },
                ],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "Other",
                    margin: "dense",
                },
                ip: {},
            },
            Other2: {
                id: "Other2",
                enterpriseComponentAttribute: "Other2",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "Other",
                    margin: "dense",
                },
                ip: {},
            },
            Other3: {
                id: "Other3",
                enterpriseComponentAttribute: "Other3",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "Other",
                    margin: "dense",
                },
                ip: {},
            },
            Other4: {
                id: "Other4",
                enterpriseComponentAttribute: "Other4",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "Other",
                    margin: "dense",
                },
                ip: {},
            },
            Other5: {
                id: "Other5",
                enterpriseComponentAttribute: "Other5",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    label: "Other",
                    margin: "dense",
                },
                ip: {},
            },
            EnteredByUSID: {
                id: "EnteredByUSID",
                enterpriseComponentAttribute: "EnteredByUSID",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                default: {
                    type: "Computed",
                    value: "User USID",
                },
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Entered By:",
                    margin: "dense",
                },
                ip: {},
            },
            Note: {
                id: "Note",
                enterpriseComponentAttribute: "Note",
                placement: {
                    "View": {
                        order: 24,
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        order: 24,
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        order: 24,
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: true,
                    placeholder: null,
                    label: "Note",
                    margin: "dense",
                    rows: 2,
                },
                ip: {},
            },
            AssociatedNote: {
                id: "AssociatedNote",
                enterpriseComponentAttribute: "AssociatedNote",
                placement: {
                    "View": {
                        order: 24,
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        order: 24,
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        order: 24,
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: true,
                    placeholder: null,
                    label: "Note",
                    margin: "dense",
                    rows: 2,
                },
                ip: {},
            },
            DropZone: {
                id: "DropZone",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "DropZone",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    margin: "dense",
                },
                ip: {},
            },
        },
    };

    render() {
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="symptom"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode ? this.props.defaultMode : this.state.Mode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(SymptomDetailApplet);
