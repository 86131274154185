import {Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Tooltip, Typography} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TodayIcon from "@material-ui/icons/Today";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {v4 as uuidv4} from "uuid";
import * as Analytics from "../../util/Analytics";
import CloudcathAxios from "../../util/CloudcathAxios";
import CCButton from "../common/CCButton";
import CCDatePicker from "../common/CCDatePicker";
import CCPulse from "../common/CCPulse";
import css_self from "./css/TurbidityGraph.module.scss";
import FileOpenIcon from "./icons/FileOpen";
import TurbidityGraphSvg from "./TurbidityGraphSvg";

export default function TurbidityGraph({
    variant, // normal | mobile | simple
    patientUSID,
    escalation,
    dateStart: _dateStart,
    dateEnd: _dateEnd,
    interactive,
    timezone,
    onLoadComplete,
    ...props
}) {

    variant = variant || "normal";
    interactive = interactive ?? true;
    timezone = timezone || moment.tz.guess();

    const allowFlexibleDate = !escalation && !["simple", "svg-only"].includes(variant);

    const availableRanges = {
        "2D": 2,
        "1W": 7,
        "4W": 28,
        "8W": 56,
        "12W": 84,
    };

    const [manifestKey, setManifestKey] = useState(uuidv4);

    const [notificationLevel, setNotificationLevel] = useState(undefined);
    const [escalations, setEscalations] = useState(undefined);
    const [dataPoints, setDataPoints] = useState(undefined);
    const [dateAnchor, setDateAnchor] = useState(defaultDateAnchor());
    const [dateRange, setDateRange] = useState(defaultDateRange());
    const [dateFlexible, setDateFlexible] = useState(allowFlexibleDate);
    const [generateReportState, setGenerateReportState] = useState(null);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);

    useEffect(() => {
        downloadManifest(dateStart(), dateAnchor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manifestKey]);

    function dateTomorrow() {
        return moment().startOf("day").add(1, "day");
    }

    function dateStart() {
        return moment(dateAnchor).subtract(dateRange, "day");
    }

    function defaultDateRange() {
        const escalationStart = escalation?.getAttributeValue("StartDatetime");
        if (escalationStart) {
            const start = moment(escalationStart, "MMM DD, YYYY").startOf("day");
            const end = defaultDateAnchor();
            return end.diff(start, "day");
        }
        if (_dateStart && _dateEnd) {
            const start = moment(_dateStart, "YYYY-MM-DD").startOf("day");
            const end = moment(_dateEnd, "YYYY-MM-DD").startOf("day").add(1, "day");
            return end.diff(start, "day");
        }
        return 7;
    }

    function defaultDateAnchor() {
        const escalationEnd = escalation?.getAttributeValue("EndDatetime");
        if (escalationEnd) {
            return moment(escalationEnd, "MMM DD, YYYY").startOf("day").add(1, "day");
        }
        if (_dateEnd) {
            return moment(_dateEnd, "YYYY-MM-DD").startOf("day").add(1, "day");
        }
        return dateTomorrow();
    }

    async function downloadManifest(startDate, endDate) {

        // bug with patient detail screen where components are initially loaded with no patient usid
        if (!patientUSID) return;

        const response = await CloudcathAxios({
            method: "GET",
            url: "cloudcath/v1/patient_graph",
            json: true,
            params: {
                USID: patientUSID,
                ...(startDate ? {Start: moment(startDate).subtract(1, "day").utc().format("YYYY-MM-DD")} : {}),
                ...(endDate ? {End: moment(endDate).add(1, "day").endOf("day").utc().format("YYYY-MM-DD")} : {}),
            },
        }).catch(e => e.response || e);

        if (!isMounted.current) return;

        if (response instanceof Error) return;
        if (response.status !== 200) return;

        const manifest = response.data.Manifest || [];
        const notificationLevel = response.data.NotificationLevel;
        const escalations = response.data.Escalations;

        if (manifest.length === 0) {

            if (!dateFlexible || (startDate === undefined && endDate === undefined)) {

                setDataPoints([]);
                setEscalations(escalations);
                setNotificationLevel(notificationLevel);

                if (onLoadComplete) onLoadComplete({
                    notificationLevel: notificationLevel,
                    escalations: escalations,
                    data: [],
                });

                return;
            }

            setDateFlexible(false);

            // retry without dates and let the server tells us what to use
            await downloadManifest(undefined, undefined);

            return;
        }

        if (startDate === undefined && endDate === undefined) {

            startDate = moment(response.data.Start, "YYYY-MM-DD");
            endDate = moment(response.data.End, "YYYY-MM-DD");

            const days = endDate.diff(startDate, "day");
            const range = Object.values(availableRanges).find(x => days <= x);

            setDateRange(range);
            setDateAnchor(endDate);
        }

        Promise.allSettled(
            manifest.map(url => axios({
                    method: "GET",
                    url: url,
                    cache: "no-store",
                }).then(response => {
                    const lines = response.data.split("\n");
                    return lines.map(line => {
                        let [date, value] = line.split(",");
                        return {date: parseInt(date), value: parseFloat(value)};
                    });
                }),
            ),
        ).then(responses => {

            if (!isMounted.current) return;

            const now = moment().valueOf();

            let data = _.reduce(responses, (result, response) => {
                if (response.status === "fulfilled") {
                    response.value.forEach(d => {
                        if (d.date <= now) {
                            result.push(d);
                        }
                    });
                }
                return result;
            }, []);

            data = _.sortBy(data, x => x.date);

            if (!isMounted.current) return;

            setDataPoints(data);
            setEscalations(escalations);
            setNotificationLevel(notificationLevel);

            if (onLoadComplete) onLoadComplete({
                notificationLevel: notificationLevel,
                escalations: escalations,
                data: data,
            });
        });
    }

    function onRefresh() {
        setDateFlexible(false);
        setDataPoints(undefined);
        setEscalations(undefined);
        setManifestKey(uuidv4());
    }

    let dateSelector = null;
    if (!["simple", "svg-only"].includes(variant)) {
        dateSelector = (
            <>
                <IconButton
                    onClick={async () => {
                        setDataPoints(undefined);
                        setEscalations(undefined);
                        setDateAnchor(moment(dateAnchor).subtract(dateRange, "day"));
                        setDateFlexible(false);
                        setManifestKey(uuidv4());
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <div style={{width: 3}} />
                <Typography>{dateStart().format("MMM D")} - {dateAnchor.format("MMM D")}</Typography>
                <div style={{width: 3}} />
                <IconButton
                    disabled={dateAnchor >= dateTomorrow()}
                    onClick={async () => {
                        setDataPoints(undefined);
                        setEscalations(undefined);
                        setDateAnchor(moment(dateAnchor).add(dateRange, "day"));
                        setDateFlexible(false);
                        setManifestKey(uuidv4());
                    }}
                >
                    <ChevronRightIcon />
                </IconButton>
            </>
        );
    }

    let dateRangeSelector = null;
    if (!["simple", "svg-only"].includes(variant)) {
        dateRangeSelector = (
            <ButtonGroup color="primary" size="small" disableElevation={true} fullWidth={variant === "mobile"} style={{marginRight: "5px"}}>
                {Object.entries(availableRanges).map(([label, range]) => (
                    <Button
                        key={`turbidity-range-${label}`}
                        variant={dateRange === range ? "contained" : "outlined"}
                        onClick={async () => {
                            setDataPoints(undefined);
                            setEscalations(undefined);
                            setDateAnchor(dateTomorrow());
                            setDateRange(range);
                            setDateFlexible(false);
                            setManifestKey(uuidv4());
                        }}
                    >
                        {label}
                    </Button>
                ))}
            </ButtonGroup>
        );
    }

    let generateReportDialog = null;
    if (variant === "normal" && generateReportState) {
        generateReportDialog = (
            <Dialog open={true} scroll="body">
                <DialogTitle>Patient Turbidity Report</DialogTitle>
                <DialogContent style={{display: "flex", padding: "0 16px", minHeight: 60}}>
                    <CCDatePicker
                        label="Start Date (MM/DD/20YY)"
                        minDate="2020-01-01"
                        disableFuture={true}
                        onChange={(e) => {
                            const state = {...generateReportState};
                            state.dateStart = e !== "Invalid date" ? e : null;
                            state.dateStartInput = e;
                            validateGenerateReportDates(state);
                            setGenerateReportState(state);
                        }}
                        value={generateReportState.dateStartInput}
                        error={!!generateReportState.dateStartError}
                        helperText={generateReportState.dateStartError}
                        InputLabelProps={{shrink: true}}
                    />
                    <CCDatePicker
                        label="End Date (MM/DD/20YY)"
                        minDate="2020-01-01"
                        disableFuture={true}
                        onChange={(e) => {
                            const state = {...generateReportState};
                            state.dateEnd = e !== "Invalid date" ? e : null;
                            state.dateEndInput = e;
                            validateGenerateReportDates(state);
                            setGenerateReportState(state);
                        }}
                        value={generateReportState.dateEndInput}
                        error={!!generateReportState.dateEndError}
                        helperText={generateReportState.dateEndError}
                        InputLabelProps={{shrink: true}}
                        style={{marginLeft: 16}}
                    />
                </DialogContent>
                <DialogActions>
                    <CCButton variant="secondary" onClick={() => setGenerateReportState(null)}>Cancel</CCButton>
                    <CCButton
                        disabled={!generateReportState.ready}
                        onClick={() => {
                            const dateStart = generateReportState.dateStart;
                            const dateEnd = generateReportState.dateEnd;
                            window.open(`/turbidityDataReport?patientUSID=${patientUSID}&dateStart=${dateStart}&dateEnd=${dateEnd}`, "_blank");
                            setGenerateReportState(null);
                            const auditEvent = Analytics.audit("CC-3A5F-450C", "PATIENT TURBIDITY REPORT GENERATED", {
                                "patient_usid": patientUSID,
                                "report_start_date": dateStart,
                                "report_end_date": dateEnd,
                            });
                            Analytics.record("Patient Turbidity Report Generated", {
                                "Patient USID": patientUSID,
                                "Report Start Date": dateStart,
                                "Report End Date": dateEnd,
                                "Audit Web ID": auditEvent["web_id"],
                            });
                        }}
                    >Generate Report</CCButton>
                </DialogActions>
            </Dialog>
        );
    }

    function validateGenerateReportDates(state) {

        state.dateStartError = null;
        state.dateEndError = null;

        if (state.dateStartInput === "Invalid date") state.dateStartError = "Invalid date.";

        if (state.dateEndInput === "Invalid date") state.dateEndError = "Invalid date.";

        if (state.dateStart && state.dateEnd) {

            const now = moment();
            const dateStart = moment(state.dateStart, "YYYY-MM-DD");
            const dateEnd = moment(state.dateEnd, "YYYY-MM-DD");

            if (dateStart > dateEnd) state.dateStartError = "Start date must come before end date.";
            if (dateEnd.diff(dateStart, "week") > 12) state.dateEndError = "End date must be within 12 weeks of start date.";
            if (dateStart > now) state.dateStartError = "Date must not be in the future.";
            if (dateEnd > now) state.dateEndError = "Date must not be in the future.";
        }

        state.ready = state.dateStart && state.dateEnd && !state.dateStartError && !state.dateEndError;
    }

    const graphSvg = (
        <TurbidityGraphSvg
            variant="normal"
            patientUSID={patientUSID}
            dateStart={dateStart().valueOf()}
            dateEnd={dateAnchor.valueOf()}
            data={dataPoints}
            escalations={escalations}
            notificationLevel={notificationLevel}
            interactive={interactive}
            timezone={timezone}
            style={{height: "300px"}}
        />
    );

    if (variant === "svg-only") {
        return (
            <div {...props}>{graphSvg}</div>
        );
    }

    return (
        <>
            <div {...props}>
                {variant !== "simple" ? (
                    <div style={{display: "flex", alignItems: "center"}}>
                        {variant === "mobile" ? (
                            <div style={{display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    {dateSelector}
                                </div>
                                <Typography className={css_self.RefreshText}>
                                    <Link variant="inherit" color="inherit" href="#" onClick={async (e) => {
                                        e.preventDefault();
                                        onRefresh();
                                    }}> Refresh </Link>
                                </Typography>
                            </div>
                        ) : null}
                        {variant === "normal" ? (
                            <>
                                {dateSelector}
                                <div style={{flexGrow: 1}} />
                                <Tooltip arrow title="Generate Report">
                                    <IconButton onClick={() => {
                                        const state = {
                                            dateStart: dateStart().format("YYYY-MM-DD"),
                                            dateStartInput: dateStart().format("YYYY-MM-DD"),
                                            dateEnd: moment(dateAnchor).subtract(1, "day").format("YYYY-MM-DD"),
                                            dateEndInput: moment(dateAnchor).subtract(1, "day").format("YYYY-MM-DD"),
                                        };
                                        validateGenerateReportDates(state);
                                        setGenerateReportState(state);
                                    }}>
                                        <FileOpenIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow title="Reset">
                                    <IconButton
                                        onClick={async () => {
                                            setDataPoints(undefined);
                                            setEscalations(undefined);
                                            setDateAnchor(defaultDateAnchor());
                                            setDateRange(defaultDateRange());
                                            setDateFlexible(allowFlexibleDate);
                                            setManifestKey(uuidv4());
                                        }}
                                    >
                                        <TodayIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow title="Refresh">
                                    <IconButton onClick={async () => onRefresh()}>
                                        <CachedIcon />
                                    </IconButton>
                                </Tooltip>
                                <div style={{width: 10}} />
                                {dateRangeSelector}
                                <div style={{width: 20}} />
                            </>
                        ) : null}
                    </div>
                ) : undefined}
                <div style={{position: "relative"}}>
                    {graphSvg}
                    <div className={css_self.PulseStage}>
                        {dataPoints === undefined ? (
                            <CCPulse className={css_self.Pulse} DotProps={{className: css_self.PulseDot}} />
                        ) : undefined}
                    </div>
                </div>
                {variant === "mobile" ? (
                    <div style={{marginTop: 20}}>
                        {dateRangeSelector}
                    </div>
                ) : null}
            </div>
            {generateReportDialog}
        </>
    );
}
