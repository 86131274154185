import RelationshipMetadata from "../RelationshipMetadata";

class EscalationNoteRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "EscalationNote";
        this.parentEnterpriseComponent = "Escalation";
        this.childEnterpriseComponent = "EscalationNote";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "EscalationUSID";
    }
}

export default EscalationNoteRelationshipMetadata;