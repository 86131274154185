import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import * as Analytics from "../../util/Analytics";
import {wasPageReloaded} from "../../util/Location";

// REQUIRED USE-CASE:
// Navigating away from the website or restoring a browser session (crash, force close, etc)
// should log the user out and not return them back to their previous valid session.
export function AppUnloadLogout() {

    const history = useHistory();

    useEffect(() => {

        const appUnloadedAt = sessionStorage.getItem("appUnloadedAt");

        if (appUnloadedAt) {

            const duration = Date.now() - parseInt(appUnloadedAt);

            if (wasPageReloaded() || duration <= 10000) { // ignore slow page reloads
                sessionStorage.removeItem("appUnloadedAt");
            } else {

                Analytics.record("Logging Out", {
                    Reason: "App Unloaded Check",
                    Voluntary: false,
                });

                history.push("/logout");
            }
        }

        function eventListener(e) {
            sessionStorage.setItem("appUnloadedAt", Date.now());
        }

        window.addEventListener("unload", eventListener);

        return () => window.removeEventListener("unload", eventListener);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
