import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1PatientIDOCToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class PatientAREnterpriseComponent extends EnterpriseComponent {
    performQuery = (start, pageSize, foreignKey) => {
        let params = {
            "Limit": pageSize,
            "Offset": start,
            "Classification": "AR",
        };

        params["Sort"] = this.getFormattedSortSpecification();

        if (this.getSearchSpecification() !== null) {
            let keys = Object.keys(this.getSearchSpecification());
            for (let i = 0; i < keys.length; i++) {
                params[keys[i]] = this.getSearchSpecification()[keys[i]];
            }
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/patients",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.PatientThin",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1PatientIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientThin";

        let patient = this.preparePUTPayload();

        let attachment_ec = this.getEnterpriseObject().getEnterpriseComponent("PatientAttachment");
        if (attachment_ec.getBuffer().length > 0) {
            patient["ListOfAttachment"] = {};
            patient["ListOfAttachment"]["Attachment"] = [];

            for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
                if (attachment_ec.getBuffer()[i]["ParentUSID"] === this.getAttributeValue("USID") && attachment_ec.getBuffer()[i]["__operation"] === "new") {
                    let attachment = {
                        "USID": attachment_ec.getBuffer()[i]["USID"],
                        "ParentUSID": attachment_ec.getBuffer()[i]["ParentUSID"],
                        "ParentType": attachment_ec.getBuffer()[i]["ParentType"],
                        "FileName": attachment_ec.getBuffer()[i]["FileName"],
                        "FileType": attachment_ec.getBuffer()[i]["FileType"],
                        "FileSize": attachment_ec.getBuffer()[i]["FileSize"],
                        "Payload": attachment_ec.getBuffer()[i]["Payload"],
                    };
                    patient["ListOfAttachment"]["Attachment"].push(attachment);
                }
            }
        }

        payload.CloudcathMessage.Patient = patient;

        if (this.getAttribute("TurbidityNotificationLevelA").isModified()) {
            payload.CloudcathMessage.Patient["TurbidityNotificationLevelB"] = patient["TurbidityNotificationLevelA"];
        }
        if (this.getAttribute("NotifyAfterInstancesA").isModified()) {
            payload.CloudcathMessage.Patient["NotifyAfterInstancesB"] = 1;
        }

        return payload;
    };

    performUpdate = () => {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "cloudcath/v1/patients",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientThin",
                "idoc-response": "v1.PatientThin",
            },
        };
        this.notifyUpdateStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1PatientIDOCToArray(response.data));
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let patient = {};

        patient["USID"] = this.getBuffer()[this.getRecordPointer()]["USID"];
        patient["BillingAccountUSID"] = this.getBuffer()[this.getRecordPointer()]["BillingAccountUSID"];
        patient["PatientIdentifier"] = this.getBuffer()[this.getRecordPointer()]["PatientIdentifier"];
        patient["FirstName"] = this.getBuffer()[this.getRecordPointer()]["FirstName"];
        patient["MiddleInitial"] = this.getBuffer()[this.getRecordPointer()]["MiddleInitial"];
        patient["LastName"] = this.getBuffer()[this.getRecordPointer()]["LastName"];
        patient["DateOfBirth"] = this.getBuffer()[this.getRecordPointer()]["DateOfBirth"];
        patient["TurbidityNotificationLevelA"] = this.getBuffer()[this.getRecordPointer()]["TurbidityNotificationLevelA"];
        patient["NotifyAfterInstancesA"] = this.getBuffer()[this.getRecordPointer()]["NotifyAfterInstancesA"];
        patient["TurbidityNotificationLevelB"] = this.getBuffer()[this.getRecordPointer()]["TurbidityNotificationLevelA"];
        patient["NotifyAfterInstancesB"] = 1;
        patient["SendReminderAfterInstances"] = this.getBuffer()[this.getRecordPointer()]["SendReminderAfterInstances"];
        patient["PrimaryDoctorUSID"] = this.getBuffer()[this.getRecordPointer()]["PrimaryDoctorUSID"];
        patient["PrimaryNurseUSID"] = this.getBuffer()[this.getRecordPointer()]["PrimaryNurseUSID"];
        patient["CyclerID"] = this.getBuffer()[this.getRecordPointer()]["CyclerID"];
        if (this.getBuffer()[this.getRecordPointer()]["EnableTurbidityNotifications"] === null) {
            this.getBuffer()[this.getRecordPointer()]["EnableTurbidityNotifications"] = false;
        } else {
            patient["EnableTurbidityNotifications"] = this.getBuffer()[this.getRecordPointer()]["EnableTurbidityNotifications"];
        }
        patient["PatientState"] = "Healthy";

        let attachment_ec = this.getEnterpriseObject().getEnterpriseComponent("PatientAttachment");
        if (attachment_ec.getBuffer().length > 0) {
            patient["ListOfAttachment"] = {};
            patient["ListOfAttachment"]["Attachment"] = [];

            for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
                if (attachment_ec.getBuffer()[i]["ParentUSID"] === this.getAttributeValue("USID") && attachment_ec.getBuffer()[i]["__operation"] === "new") {
                    let attachment = {
                        "USID": attachment_ec.getBuffer()[i]["USID"],
                        "ParentUSID": attachment_ec.getBuffer()[i]["ParentUSID"],
                        "ParentType": attachment_ec.getBuffer()[i]["ParentType"],
                        "FileName": attachment_ec.getBuffer()[i]["FileName"],
                        "FileType": attachment_ec.getBuffer()[i]["FileType"],
                        "FileSize": attachment_ec.getBuffer()[i]["FileSize"],
                        "Payload": attachment_ec.getBuffer()[i]["Payload"],
                    };
                    patient["ListOfAttachment"]["Attachment"].push(attachment);
                }
            }
        }

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientThin";
        payload.CloudcathMessage.Patient = patient;

        return payload;
    };

    performInsert = () => {
        let payload = this.enterpriseComponentPostIDOC();

        let options = {
            method: "POST",
            url: "cloudcath/v1/patients",
            json: true,
            data: payload,
            headers: {
                "Content-Type": "application/json",
                "idoc-request": "v1.PatientThin",
                "idoc-response": "v1.PatientThin",
            },
        };
        this.notifyInsertStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1PatientIDOCToArray(response.data));
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };
}

export default PatientAREnterpriseComponent;