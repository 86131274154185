import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class MainEmailEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "MainEmail";

        this.attributes["Username"] = new EnterpriseComponentAttributeMetadata(
            "Username", // name
            false,  // NoCopy
            true,  // NoUpdate
            true,  // Required
            false,  // RequiredForQuery
            null,   // Picklist
            null,   // Scale
            null,   // Precision
            100,    // length
            null,   // minLength
            null,   // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
    }
}


export default MainEmailEnterpriseComponentMetadata;