import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import spinner from "../assets/Spinner-1s-200px.svg";
import eoecClassFactory from "../eoec/EOECClassFactory";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";

class UserDeactivate extends Component {
    state = {
        enterpriseObjects: {},
        enterpriseComponents: {},
        fetchBackgroundOperations: [],
        updateBackgroundOperations: [],
    };

    constructor(props) {

        super(props);

        this.applet = props.applet;

        let enterpriseObject = eoecClassFactory("eo", "Patient");
        this.state.enterpriseObjects["Patient"] = enterpriseObject;
        this.state.enterpriseComponents["Patient"] = enterpriseObject.getEnterpriseComponent("Patient");
        // this.state.enterpriseComponents[`Secondary${userRole}`] = enterpriseObject.getEnterpriseComponent(`Secondary${userRole}`);
    };

    componentDidMount() {

        const userUSID = this.applet.getEnterpriseComponent().getAttributeValue("USID");
        const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");

        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);

        if (["Nurse", "Physician"].includes(userRole)) {

            this.state.enterpriseComponents["Patient"].addLoadStartedListener(this);
            this.state.enterpriseComponents["Patient"].addLoadCompletedListener(this);
            this.state.enterpriseComponents["Patient"].addUpdateStartedListener(this);
            this.state.enterpriseComponents["Patient"].addUpdateCompletedListener(this);
            this.state.enterpriseComponents["Patient"].newQuery();
            this.state.enterpriseComponents["Patient"].setSearchSpecification(
                userRole === "Physician" ? {"PrimaryDoctorUSID": userUSID}
                    : userRole === "Nurse" ? {"PrimaryNurseUSID": userUSID}
                        : null,
            );
            this.state.enterpriseComponents["Patient"].performQuery();

            // this.state.enterpriseComponents[`Secondary${userRole}`].addLoadStartedListener(this);
            // this.state.enterpriseComponents[`Secondary${userRole}`].addLoadCompletedListener(this);
            // this.state.enterpriseComponents[`Secondary${userRole}`].newQuery();
            // this.state.enterpriseComponents[`Secondary${userRole}`].setSearchSpecification({"UserUSID": userUSID});
            // this.state.enterpriseComponents[`Secondary${userRole}`].performQuery();
        }
    };

    componentWillUnmount() {

        const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");

        if (["Nurse", "Physician"].includes(userRole)) {

            this.state.enterpriseComponents["Patient"].removeLoadStartedListener(this);
            this.state.enterpriseComponents["Patient"].removeLoadCompletedListener(this);

            // this.state.enterpriseComponents[`Secondary${userRole}`].removeLoadStartedListener(this);
            // this.state.enterpriseComponents[`Secondary${userRole}`].removeLoadCompletedListener(this);
        }

        this.applet.unmountComponent(this);
    };

    enterpriseComponentLoadStarted = (ec) => {
        let fetchBackgroundOperations = [...this.state.fetchBackgroundOperations];
        fetchBackgroundOperations.push(ec.getName());
        this.setState({"fetchBackgroundOperations": fetchBackgroundOperations});
    };

    enterpriseComponentLoadCompleted = (buffer, ec) => {
        let fetchBackgroundOperations = [...this.state.fetchBackgroundOperations];
        fetchBackgroundOperations = fetchBackgroundOperations.filter(x => x !== ec.getName());
        this.setState({"fetchBackgroundOperations": fetchBackgroundOperations});
    };

    enterpriseComponentUpdateStarted = (buffer, ec) => {
        let updateBackgroundOperations = [...this.state.updateBackgroundOperations];
        updateBackgroundOperations.push(ec.getName());
        this.setState({"updateBackgroundOperations": updateBackgroundOperations});
    };

    enterpriseComponentUpdateCompleted = (buffer, ec) => {
        if (ec.getName() === "User") {
            this.applet.cancel();
        }
    };

    getName = () => {
        return "UserDeactivate";
    };

    // getPrimaryPatients = () => {
    //     return this.state.enterpriseComponents["Patient"].getBuffer();
    // };
    //
    // getSecondaryPatients = () => {
    //     const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");
    //     return this.state.enterpriseComponents[`Secondary${userRole}`].getBuffer();
    // };

    getPrimaryPatients = () => {
        const userUSID = this.applet.getEnterpriseComponent().getAttributeValue("USID");
        const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");
        return this.state.enterpriseComponents["Patient"].getBuffer().filter(x =>
            (userRole === "Physician" && x["PrimaryDoctorUSID"] === userUSID) ||
            (userRole === "Nurse" && x["PrimaryNurseUSID"] === userUSID),
        );
    };

    getSecondaryPatients = () => {
        const userUSID = this.applet.getEnterpriseComponent().getAttributeValue("USID");
        const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");
        return this.state.enterpriseComponents["Patient"].getBuffer().filter(x =>
            (userRole === "Physician" && x["PrimaryDoctorUSID"] !== userUSID) ||
            (userRole === "Nurse" && x["PrimaryNurseUSID"] !== userUSID),
        );
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.getPrimaryPatients().length) {
            this.applet.getView().initiateAction("Re-Assign Patients");
            return;
        }

        this.applet.setMode("Edit");
        this.applet.getEnterpriseComponent().setAttributeValue("UserStatus", "Inactive");
        this.applet.getEnterpriseComponent().executeUpdate();
    };

    cancel1Handler = (event, reason) => {
        event.preventDefault();
        this.applet.cancel();
    };

    render() {

        const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");
        const userFullName = this.applet.getEnterpriseComponent().getAttributeValue("FullName");

        let disableAllButtons =
            Boolean(this.state.fetchBackgroundOperations.length) ||
            Boolean(this.state.updateBackgroundOperations.length);

        let patientContent = null;
        let primaryPatients = null;

        if (["Nurse", "Physician"].includes(userRole)) {

            if (this.state.fetchBackgroundOperations.length) {

                patientContent = (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{display: "flex", justifyContent: "center", marginBottom: 12}}>
                            <img src={spinner} alt="spinner" width={80} height={80} style={{margin: "auto"}} />
                        </div>
                    </Grid>
                );

            } else {

                primaryPatients = this.getPrimaryPatients();
                let secondaryPatients = this.getSecondaryPatients();

                if (primaryPatients.length || secondaryPatients.length) {
                    patientContent = (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ul style={{paddingLeft: 16, margin: 0}}>
                                {primaryPatients.length ? (
                                    <Typography component="li" className={this.props.classes.errorText} style={{marginBottom: 10}}>{`${primaryPatients.length} patients must be re-assigned before deactivating this user.`}</Typography>
                                ) : null}
                                {primaryPatients.length && secondaryPatients.length ? (
                                    <Typography component="li" className={this.props.classes.errorText} style={{marginBottom: 10}}>{`${secondaryPatients.length} secondary patients may optionally be re-assigned. All remaining secondary patients will be automatically un-assigned.`}</Typography>
                                ) : null}
                                {!primaryPatients.length && secondaryPatients.length ? (
                                    <Typography component="li" className={this.props.classes.errorText} style={{marginBottom: 10}}>{`${secondaryPatients.length} secondary patients will be automatically un-assigned.`}</Typography>
                                ) : null}
                            </ul>
                        </Grid>
                    );
                }
            }
        }

        let modalContent = (
            <Grid container justifyContent="center">
                <form onSubmit={e => e.preventDefault()}>
                    <Grid item container style={{width: "32em"}} spacing={4}>
                        <Grid item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={this.props.classes.addEpisodeText}>Are you sure you want to <b>deactivate</b> {userFullName}?</Typography>
                        </Grid>
                    </Grid>
                    {patientContent}
                </form>
            </Grid>
        );

        return (
            <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                <DialogTitle>Deactivate user</DialogTitle>
                <DialogContent style={{padding: "8px 16px"}}>
                    {modalContent}
                </DialogContent>
                <DialogActions>
                    <CCButton variant="secondary" onClick={this.cancel1Handler} disabled={Boolean(this.state.updateBackgroundOperations.length)}>
                        Cancel
                    </CCButton>
                    <CCButton
                        variant="green"
                        animate={Boolean(this.state.updateBackgroundOperations.length)}
                        onClick={this.submitHandler}
                        disabled={disableAllButtons && this.state.updateBackgroundOperations.length === 0}>
                        {primaryPatients?.length ? "Re-Assign Patients" : "Save"}
                    </CCButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(UserDeactivate),
);