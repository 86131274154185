import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, withStyles} from "@material-ui/core";
import {Error} from "@material-ui/icons";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";
import CCDatePicker from "./common/CCDatePicker";

class PatientEscalationHospitalized extends Component {
    state = {
        updateBackgroundOperation: false,
        Note: "",
        DateOfHospitalization: "",
        DateOfHospitalizationError: false,
        DateOfHospitalizationHelper: null,

        CatheterRemoved: false,
        CatheterReplaced: false,

        symptomAlert: false,
        medicationAlert: false,
        labAlert: false,
        labEmptyAlert: false,
        showConfirmation: false,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        renderAssistiveText: null,
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;

        this.state.DateOfHospitalization = this.applet.getEnterpriseComponent().getAttributeValue("HospitalizationDate");
        this.state.CatheterRemoved = this.applet.getEnterpriseComponent().getAttributeValue("CatheterRemoved");
        this.state.CatheterReplaced = this.applet.getEnterpriseComponent().getAttributeValue("CatheterReplaced");
    };

    componentDidMount() {
        this.applet.setMode("Edit");

        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);

        let symptomEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("EscalationSymptom");
        if (symptomEC.getBuffer().length === 0) {
            this.setState({symptomAlert: true});
        }
        let labEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("EscalationLab");
        if (labEC.getBuffer().length === 0) {
            this.setState({labAlert: true});
        } else {
            let found = false;
            for (let i = 0; i < labEC.getBuffer().length; i++) {
                let row = labEC.getBuffer()[i];

                if (row["Name"] === "Cell Count") {
                    if (row["Leukocytes"] !== null && row["Neutrophilis"] !== null && row["Monocytes"] !== null && row["Lymphocytes"] !== null) {
                        found = true;
                    }
                }
                if (found === false && row["Name"] === "Culture") {
                    if (row["GramStain"] !== null && row["CultureResult"] !== null && row["CulturePathogen"] !== null) {
                        found = true;
                    }
                }
                if (found === false && row["Name"] === "Other") {
                    if (row["Result"] !== null && row["Type"] !== null) {
                        found = true;
                    }
                }
            }
            if (found === false) {
                this.setState({labEmptyAlert: true});
            }
        }
        let medicationEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("EscalationTreatment");
        if (medicationEC.getBuffer().length === 0) {
            this.setState({medicationAlert: true});
        }
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.state.DateOfHospitalization === null || this.state.DateOfHospitalization === "") {
            this.setState({DateOfHospitalizationHelper: "Field must contain a value.", DateOfHospitalizationError: true});
        } else if (this.state.DateOfHospitalizationError === true) {
            this.setState({DateOfHospitalizationHelper: "Date improperly formatted."});
        } else {
            let patientEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Patient");
            patientEC.forceAttributeValue("PatientEscalationStatus", "Hospitalized");

            this.applet.getEnterpriseComponent().setAttributeValue("EscalationNote", this.state.Note);
            this.applet.getEnterpriseComponent().setAttributeValue("EscalationNoteType", "Patient Hospitalization");
            this.applet.getEnterpriseComponent().setAttributeValue("HospitalizationDate", this.state.DateOfHospitalization);
            this.applet.getEnterpriseComponent().setAttributeValue("CatheterRemoved", this.state.CatheterRemoved);
            this.applet.getEnterpriseComponent().setAttributeValue("CatheterReplaced", this.state.CatheterReplaced);
            if (!this.state.editMode) {
                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Hospitalized");
            }
            this.applet.save();

            this.applet.getView().setRefresh(true);
        }
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = () => {
        this.setState({updateBackgroundOperation: false});

        this.applet.setMode("View");

        this.applet.getView().initiateAction(null);
    };

    getName = () => {
        return "Hospitalized";
    };

    handleCathetherRemoved = (event) => {
        this.setState({CatheterRemoved: event.target.value === "true"});
    };

    handleCathetherReplaced = (event) => {
        this.setState({CatheterReplaced: event.target.value === "true"});
    };

    inputChangedHandler = (event, formElementId) => {
        if (formElementId === "DateOfHospitalization") {
            let isMatch = true;

            if (event?.length > 0) {
                isMatch = !!event.match("^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$");
            }

            if (!event) {
                this.setState({DateOfHospitalization: event, DateOfHospitalizationHelper: "Field must contain a value.", DateOfHospitalizationError: true});
            } else if (isMatch === false) {
                this.setState({DateOfHospitalization: event, DateOfHospitalizationHelper: "Date improperly formatted.", DateOfHospitalizationError: true});
            } else {
                this.setState({DateOfHospitalization: event, DateOfHospitalizationHelper: null, DateOfHospitalizationError: false});
            }
        } else if (formElementId === "Note") {
            let lengthWarningText = null;
            if (event.target.value.length === 1000) {
                lengthWarningText = "1000 characters allowed";
            }

            this.setState({Note: event.target.value, lengthWarningText: lengthWarningText});
        }
    };

    cancel1Handler = (event, reason) => {
        event.preventDefault();

        if (this.state.disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (this.state.disableEscapeKeyDown && reason === "escapeKeyDown") {
            return false;
        }

        this.setState({showConfirmation: true});
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    render() {
        const symptomAlert = (<Typography className={this.props.classes.alertText}>You are about to mark this patient as hospitalized where there are no symptoms logged for this episode.</Typography>);
        const labAlert = (<Typography className={this.props.classes.alertText}>You are about to mark this patient as hospitalized while there are no labs ordered for this episode.</Typography>);
        const labEmptyAlert = (<Typography className={this.props.classes.alertText}>We noticed that you have ordered labs for this patient but have not entered the results.</Typography>);
        const medicationAlert = (<Typography className={this.props.classes.alertText}>You are about to mark this patient as hospitalized while there is no medication logged for this episode.</Typography>);

        let alerts = [];
        if (process.env.REACT_APP_VARIANT === "cds") {
            if (this.state.symptomAlert === true) {
                alerts.push(
                    <Grid key="aa" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                            <div>
                                <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                            </div>
                            <div style={{marginTop: "0px"}}>
                                {symptomAlert}
                            </div>
                        </div>
                    </Grid>,
                );
            }
            if (this.state.labAlert === true) {
                alerts.push(
                    <Grid key="bb" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                            <div>
                                <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                            </div>
                            <div style={{marginTop: "0px"}}>
                                {labAlert}
                            </div>
                        </div>
                    </Grid>,
                );
            }
            if (this.state.labEmptyAlert === true) {
                alerts.push(
                    <Grid key="cc" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                            <div>
                                <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                            </div>
                            <div style={{marginTop: "0px"}}>
                                {labEmptyAlert}
                            </div>
                        </div>
                    </Grid>,
                );
            }
            if (this.state.medicationAlert === true) {
                alerts.push(
                    <Grid key="dd" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                            <div>
                                <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                            </div>
                            <div style={{marginTop: "0px"}}>
                                {medicationAlert}
                            </div>
                        </div>
                    </Grid>,
                );
            }
        }

        let assistiveText = null;
        if (this.state.lengthWarningText !== null) {
            assistiveText = <Typography className={this.props.classes.assistiveText}>{this.state.lengthWarningText}</Typography>;
        }

        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form>
                    <Grid key="id6" item container spacing={2}>
                        <Grid key={1} item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={this.props.classes.addEpisodeText}>By answering and clicking the button below, you are acknowledging that the patient is hospitalized.</Typography>
                            <br />
                            <Typography className={this.props.classes.addEpisodeText}>Upon save, this patient will move to the <b>Under watch</b> section.</Typography>
                        </Grid>
                        <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CCDatePicker
                                minDate={this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("StartDatetime")}
                                required={true}
                                helperText={this.state.DateOfHospitalizationHelper}
                                error={this.state.DateOfHospitalizationError}
                                key="DateOfHospitalization"
                                label="Date of hospitalization (MM/DD/20YY)"
                                id="DateOfHospitalization"
                                onChange={(event) => this.inputChangedHandler(event, "DateOfHospitalization")}
                                value={this.state.DateOfHospitalization}
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        <Grid key={3} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl component="fieldset" margin="dense">
                                <FormLabel component="legend">Was the catheter removed?</FormLabel>
                                <RadioGroup aria-label="acknowledge1" name="ack1" value={this.state.CatheterRemoved} onChange={this.handleCathetherRemoved}>
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid key={4} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl component="fieldset" margin="dense">
                                <FormLabel component="legend">Was the catheter replaced?</FormLabel>
                                <RadioGroup aria-label="acknowledge2" name="ack1" value={this.state.CatheterReplaced} onChange={this.handleCathetherReplaced}>
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid key={5} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                key="Note"
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                                fullWidth={true}
                                id="Note"
                                label="Notes"
                                onChange={(event) => this.inputChangedHandler(event, "Note")}
                                value={this.state.Note}
                                variant="outlined"
                                multiline={true}
                                rows={5}
                                inputProps={{maxLength: 1000}}
                            />
                            {assistiveText}
                        </Grid>
                        {alerts}
                    </Grid>
                </form>
            </Grid>
        );

        let content = (
            <React.Fragment>
                {modalContent}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Patient is hospitalized</DialogTitle>
                    <DialogContent>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        <CCButton
                            variant="green"
                            animate={this.state.updateBackgroundOperation === true}
                            onClick={this.submitHandler}>
                            Save
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(PatientEscalationHospitalized),
);