import {withAppContext} from "../../../App";

import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class ClinicDetailApplet extends Applet {
    constructor(props) {
        super(props);

        const actStudy = props.appContext.sessionContext.actStudy;

        this.state = {
            Name: "ClinicDetailApplet",
            Modes: {
                View: {
                    Groups: [
                        {
                            "identifier": "group1",
                            "type": "standard",
                            "selector": null,
                            "characteristics": [],
                            "membership": [],
                        }, {
                            "title": "Edit Notification Reminders",
                            "identifier": "group2",
                            "type": "standard",
                            "selector": null,
                            "characteristics": [],
                            "membership": [],
                        },
                    ],
                },
                Edit: {
                    Groups: [
                        {
                            "identifier": "group1",
                            "type": "standard",
                            "selector": null,
                            "characteristics": [],
                            "membership": [
                                {
                                    id: "Name",
                                },
                                {
                                    id: "Identifier",
                                },
                                {
                                    id: "ClinicAddress1",
                                },
                                {
                                    id: "ClinicAddress2",
                                },
                                {
                                    id: "ClinicCity",
                                },
                                {
                                    id: "ClinicState",
                                },
                                {
                                    id: "ClinicPostalCode",
                                },
                                {
                                    id: "ClinicCountry",
                                },
                                {
                                    id: "MainPhone",
                                },
                                {
                                    id: "MainEmail",
                                },
                                {
                                    id: "BackupPhone",
                                },
                                {
                                    id: "BackupEmail",
                                },
                            ],
                        },
                        {
                            "title": "Edit Notification Reminders",
                            "identifier": "group2",
                            "type": "standard",
                            "selector": null,
                            "characteristics": [],
                            "membership": [
                                {
                                    id: "SendReminderAfterInstances",
                                },
                            ],
                        },
                    ],
                },
            },
            Layout: {
                totalWidth: "36em",
            },
            Controls: {
                Name: {
                    id: "Name",
                    enterpriseComponentAttribute: "Name",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Required",
                            value: null,
                            errorText: "Field must contain a value.",
                        },
                        {
                            validator: "Minimum",
                            value: 8,
                            errorText: "Field must have at least 8 characters.",
                        },
                        {
                            validator: "Not All Spaces",
                            value: null,
                            errorText: "Field must have content other than spaces.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not have leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not have trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Name",
                        margin: "dense",
                    },
                    ip: {},
                },
                Identifier: {
                    id: "Identifier",
                    enterpriseComponentAttribute: "Identifier",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Required",
                            value: null,
                            errorText: "Field must contain a value.",
                        },
                        {
                            validator: "No Spaces",
                            value: null,
                            errorText: "Field must not contain spaces.",
                        },
                        {
                            validator: "No Special Characters",
                            value: null,
                            errorText: "Field must not contain special characters.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Identifier",
                        margin: "dense",
                    },
                    ip: {},
                },
                ClinicAddress1: {
                    id: "ClinicAddress1",
                    enterpriseComponentAttribute: "ClinicAddress1",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    errors: [
                        {
                            validator: "Required",
                            value: null,
                            errorText: "Field must contain a value.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not have leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not have trailing spaces.",
                        },
                    ],
                    type: "TextField",
                    requiresConfirmation: false,
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Address 1",
                        margin: "dense",
                    },
                    ip: {},
                },
                ClinicAddress2: {
                    id: "ClinicAddress2",
                    enterpriseComponentAttribute: "ClinicAddress2",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not have leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not have trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Address 2",
                        margin: "dense",
                    },
                    ip: {},
                    other: {
                        renderAssistiveText: false,
                    },
                },
                ClinicCity: {
                    id: "ClinicCity",
                    enterpriseComponentAttribute: "ClinicCity",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Required",
                            value: null,
                            errorText: "Field must contain a value.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not have leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not have trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "City",
                        margin: "dense",
                    },
                    ip: {},
                },
                ClinicState: {
                    id: "ClinicState",
                    enterpriseComponentAttribute: "ClinicState",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Required",
                            value: null,
                            errorText: "Field must contain a value.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not have leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not have trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "State",
                        margin: "dense",
                    },
                    ip: {},
                },
                ClinicPostalCode: {
                    id: "ClinicPostalCode",
                    enterpriseComponentAttribute: "ClinicPostalCode",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Required",
                            value: null,
                            errorText: "Field must contain a value.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not have leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not have trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Postal Code",
                        margin: "dense",
                    },
                    ip: {},
                },
                ClinicCountry: {
                    id: "ClinicCountry",
                    enterpriseComponentAttribute: "ClinicCountry",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Required",
                            value: null,
                            errorText: "Field must contain a value",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not have leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not have trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Country",
                        margin: "dense",
                    },
                    ip: {},
                },
                MainPhone: {
                    id: "MainPhone",
                    enterpriseComponentAttribute: "MainPhone",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "Phone",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Required",
                            value: null,
                            errorText: "Field is required.",
                        },
                        {
                            validator: "Optional Pattern",
                            value: null,
                            errorText: "Invalid Main Phone number.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Main Phone",
                        margin: "dense",
                    },
                    ip: {},
                },
                MainEmail: {
                    id: "MainEmail",
                    enterpriseComponentAttribute: "MainEmail",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Required Pattern",
                            value: null,
                            errorText: "Email improperly formatted.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not have leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not have trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Main Email",
                        margin: "dense",
                    },
                    ip: {},
                },
                BackupPhone: {
                    id: "BackupPhone",
                    enterpriseComponentAttribute: "BackupPhone",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "Phone",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Optional Pattern",
                            value: null,
                            errorText: "Invalid Backup Phone number.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Backup Phone",
                        margin: "dense",
                    },
                    ip: {},
                    other: {
                        renderAssistiveText: false,
                    },
                },
                BackupEmail: {
                    id: "BackupEmail",
                    enterpriseComponentAttribute: "BackupEmail",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Optional Pattern",
                            value: null,
                            errorText: "Field is optional but must be a properly formatted email address.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not have leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not have trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        placeholder: null,
                        label: "Backup Email",
                        margin: "dense",
                    },
                    ip: {},
                    other: {
                        renderAssistiveText: false,
                    },
                },
                SendReminderAfterInstances: {
                    id: "SendReminderAfterInstances",
                    enterpriseComponentAttribute: "SendReminderAfterInstances",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "Positive Float",
                    requiresConfirmation: false,
                    errors: [{
                        validator: "Required",
                        value: null,
                        errorText: "Field must contain a value.",
                    }],
                    help: [
                        {
                            helpText: "In the event of a turbidity notification, reminder notifications",
                        },
                        {
                            helpText: "are sent to all health care providers associated with the patient",
                        },
                        {
                            helpText: "until the notification is acknowledged.",
                        },
                    ],
                    csp: {
                        required: actStudy,
                        autoFocus: false,
                        label: "Send Reminder After X Hours",
                        margin: "dense",
                    },
                    ip: {
                        type: "number",
                        step: 0.5,
                        min: 1.0,
                        max: 24.0,
                    },
                },
            },
        };
    };

    getTitle = () => {
        return "Clinic Details";
    };

    render() {
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title={this.getTitle()}
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(ClinicDetailApplet);
