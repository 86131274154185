import React from "react";
import {withAppContext} from "../../../App";
import AssignedPhysician from "../../../components/AssignedPhysician";

import Applet from "../Applet";

class AssignedPhysicianApplet extends Applet {
    state = {
        Name: "AssignedPhysicianApplet",
    };

    render() {
        return <AssignedPhysician applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(AssignedPhysicianApplet);
