import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1PatientAlertIDOCToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class PatientAlertEnterpriseComponent extends EnterpriseComponent {
    performSingleRecordRefresh = (primaryKey) => {
        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_alerts",
            json: true,
            params: {
                "USID": primaryKey,
            },
            headers: {
                "idoc-request": "v1.PatientAlert",
            },
        };
        this.notifySingleRecordRefreshStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.refreshEnterpriseComponentSuccess(V1PatientAlertIDOCToArray(response.data));
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    performQuery = (start, pageSize, foreignKey) => {
        let params = {};
        if (foreignKey === null) {
            params = {
                "Limit": pageSize,
                "Offset": start,
            };
        } else {
            params = {
                "Limit": pageSize,
                "Offset": start,
                "ClinicUSID": foreignKey,
            };
        }

        let keys = Object.keys(this.getSearchSpecification());
        for (let i = 0; i < keys.length; i++) {
            let value = this.getSearchSpecification()[keys[i]];

            if (keys[i] === "USID") {
                params["USID"] = value;
            }
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_alerts",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.PatientAlert",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1PatientAlertIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentDeleteIDOC = () => {
        let payload = {};

        let object = {};

        object["USID"] = this.getBuffer()[this.getRecordPointer()]["USID"];

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientAlert";
        payload.CloudcathMessage.PatientAlert = object;

        return payload;
    };

    performDelete = () => {
        let payload = this.enterpriseComponentDeleteIDOC();

        let options = {
            method: "DELETE",
            url: "cloudcath/v1/patient_alerts",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientAlert",
            },
        };
        this.notifyUpdateStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.deleteEnterpriseComponentSuccess(response.data);
            })
            .catch(error => {
                this.deleteEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientAlert";
        payload.CloudcathMessage.PatientAlert = this.preparePUTPayload();

        return payload;
    };

    performUpdate = () => {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "cloudcath/v1/patient_alerts",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientAlert",
                "idoc-response": "v1.PatientAlert",
            },
        };
        this.notifyUpdateStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1PatientAlertIDOCToArray(response.data));
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

}

export default PatientAlertEnterpriseComponent;