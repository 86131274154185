import {useEffect} from "react";

// This hook sets <meta name="theme-color" content="#xxxxxx" />
export function useThemeColor(color, deps) {
    return useEffect(() => {
        if (color === undefined || color === null) return;
        const theme = document.querySelector("meta[name=\"theme-color\"]");
        const originalColor = theme.getAttribute("content");
        theme.setAttribute("content", color);
        return () => theme.setAttribute("content", originalColor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps !== undefined ? deps : []);
}
