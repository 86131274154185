import React from "react";

import CloudcathName from "../../../../src/components/CloudcathName";
import {withAppContext} from "../../../App";
import Applet from "../Applet";

class SecondaryPhysicianNameApplet extends Applet {
    state = {
        Name: "SecondaryPhysicianNameApplet",
    };

    render() {
        return <CloudcathName applet={this}
                              column="FullName"
                              className={this.props.className}
                              enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(SecondaryPhysicianNameApplet);
