import {withAppContext} from "../../../App";
import PatientEscalationResolveInfection from "../../../components/PatientEscalationResolveInfection";

import Applet from "../Applet";

class PatientEscalationResolveInfectionApplet extends Applet {
    state = {
        Name: "PatientEscalationDeactivationApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <PatientEscalationResolveInfection applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientEscalationResolveInfectionApplet);
