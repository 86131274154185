import {withAppContext} from "../../../App";
import PatientEscalationExpiration from "../../../components/PatientEscalationExpiration";

import Applet from "../Applet";

class PatientEscalationExpirationApplet extends Applet {
    state = {
        Name: "PatientEscalationExpirationApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <PatientEscalationExpiration applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientEscalationExpirationApplet);
