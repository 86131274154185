import React from "react";

import CloudcathTable from "../../../../src/components/CloudcathTable";
import Applet from "../Applet";

class UserListBaseApplet extends Applet {

    constructor(props, {name, title, autoUnfold}) {
        super(props);
        this.name = name;
        this.title = title;
        this.autoUnfold = autoUnfold ?? true;
        this.state = {
            Name: this.name,
            PageSize: {
                "default": 10,
                "xs": 3,
                "sm": 3,
                "md": 10,
                "lg": 10,
                "lx": 10,
            },
            Selectors: {
                "Refresh": this.refreshHandler,
                "Select": this.selectHandler,
            },
            Placement: {
                "xl": [
                    {
                        id: "Username",
                    },
                    {
                        id: "FirstName",
                    },
                    {
                        id: "LastName",
                    },
                    {
                        id: "MainContact",
                    },
                    {
                        id: "UserMenu",
                    },
                ],
            },
            Columns: {
                "Username": {
                    id: "Username",
                    width: 200,
                    cellRenderer: "Username Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Username",
                },
                "FirstName": {
                    id: "FirstName",
                    width: 260,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "First Name",
                },
                "LastName": {
                    id: "LastName",
                    width: 260,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Last Name",
                },
                "MainContact": {
                    id: "MainContact",
                    width: 280,
                    cellRenderer: "Main Contact Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Main Contact",
                },
                "AdditionalEmail": {
                    id: "AdditionalEmail",
                    width: 200,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Additional Email",
                },
                "MainPhone": {
                    id: "MainPhone",
                    width: 140,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Main Phone",
                },
                "AdditionalPhone": {
                    id: "AdditionalPhone",
                    width: 140,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Additional Phone",
                },
                "Salutation": {
                    id: "Salutation",
                    width: 180,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Salutation",
                },
                "Suffix": {
                    id: "Suffix",
                    width: 100,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Suffix",
                },
                "USID": {
                    id: "USID",
                    width: 330,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "USID",
                },
                "UserMenu": {
                    id: "UserMenu",
                    width: 75,
                    cellRenderer: "User Menu Cell",
                    header: "enabled",
                    headerRenderer: "User Menu Header",
                    headerTitle: "",
                },
            },
            Sort: [],
        };
    }

    render() {
        return <CloudcathTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title={this.title}
                               enterpriseComponent={this.getEnterpriseComponent()}
                               autoUnfold={this.autoUnfold}
                               sort={this.state.Sort}
                               selectors={this.state.Selectors} />;
    };
}

export default UserListBaseApplet;