import metadataClassFactory from "../../metadata/MetadataClassFactory";

class Picklist {
    constructor(name) {
        let metadataClass = metadataClassFactory("pl", name + "PicklistMetadata");

        this.metadata = new metadataClass();

        this.exclusionList = [];
    };

    getName = () => {
        return this.metadata.getName();
    };

    getBounded = () => {
        return this.metadata.getBounded();
    };

    getEnterpriseComponent = () => {
        return this.metadata.getEnterpriseComponent();
    };

    getLongList = () => {
        return this.metadata.getLongList();
    };

    getNoDelete = () => {
        return this.metadata.getNoDelete();
    };

    getNoUpdate = () => {
        return this.metadata.getNoUpdate();
    };

    getNoInsert = () => {
        return this.metadata.getNoInsert();
    };

    getNoMerge = () => {
        return this.metadata.getNoMerge();
    };

    getSearchSpecification = () => {
        return this.metadata.getSearchSpecification();
    };

    getSortSpecification = () => {
        return this.metadata.getSortSpecification();
    };

    getStatic = () => {
        return this.metadata.getStatic();
    };

    getTypeField = () => {
        return this.metadata.getTypeField();
    };

    getTypeValue = () => {
        return this.metadata.getTypeValue();
    };

    getVisibilityType = () => {
        return this.metadata.getVisibilityType();
    };

    getAllowNoSelection = () => {
        return this.metadata.getAllowNoSelection();
    };

    getAllowNoSelectionDisplayValue = () => {
        return this.metadata.getAllowNoSelectionDisplayValue();
    };

    getAllowNoSelectionValue = () => {
        return this.metadata.getAllowNoSelectionValue();
    };

    getExclusionList = () => {
        return this.exclusionList;
    };

    setExclusionList = (valuesToExclude) => {
        this.exclusionList = valuesToExclude;
    };

    setSearchSpecification = (searchSpecification) => {
        this.metadata.searchSpecification = searchSpecification;
    };
}

export default Picklist;