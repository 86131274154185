import {useMediaQuery, useTheme} from "@material-ui/core";
import React from "react";

export function useBreakpoint() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || undefined
    );
}


export default function withBreakpoint(Component) {
    return (props) => {
        const breakpoint = useBreakpoint();
        return <Component breakpoint={breakpoint} {...props} />;
    };
};