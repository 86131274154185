import RelationshipMetadata from "../RelationshipMetadata";

class PatientEscalationRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "PatientEscalation";
        this.parentEnterpriseComponent = "Patient";
        this.childEnterpriseComponent = "Escalation";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "PatientUSID";
    }
}

export default PatientEscalationRelationshipMetadata;