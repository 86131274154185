import EventEmitter from "events";

// USAGE:
//
// EventBus.<channel>.emit("event-type", ...args);
// EventBus.<channel>.addListener("event-type", (...args) => {...});
// EventBus.<channel>.removeListener("event-type", (...args) => {...});
//
// EXAMPLE:
//
// EventBus.session.emit("update", { "key": "value" });
//
// useEffect(() => {
//   const listener = (entries) => {...};
//   EventBus.session.addListener("update", listener);
//   return () => EventBus.session.removeListener("update", listener);
// }, []);
//
export default new Proxy(
    {},
    {
        isExtensible: (target) => false,
        enumerate: (target) => Reflect.enumerate(target),
        has: (target, key) => Reflect.has(target, key),
        get: (target, key) => {
            if (!(key in target)) target[key] = new EventEmitter();
            return target[key];
        },
        set: (target, key, value) => false,
    },
);
