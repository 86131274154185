import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {v4 as uuidv4} from "uuid";

import {withAppContext} from "../../../App";
import CloudcathMultiSelect from "../../../components/CloudcathMultiSelect";
import CloudcathSelect from "../../../components/CloudcathSelect";
import CCButton from "../../../components/common/CCButton";
import styles from "../../../theme/styles";
import {updateObject} from "../../../util/utility";
import Picklist from "../../picklist/Picklist";

class CareTeamApplet extends Component {
    state = {
        controls: {
            AssignedPhysician: {
                label: "Physician",
                value: "",
                touched: false,
                helperText: null,
            },
            AssignedNurse: {
                label: "Nurse",
                value: "",
                touched: false,
                helperText: null,
            },
            SecondaryPhysician: {
                label: "Secondary Physician",
                value: [],
                touched: false,
            },
            SecondaryNurse: {
                label: "Secondary Nurse",
                value: [],
                touched: false,
            },
        },
        primaryNursePicklist: new Picklist("PrimaryNurse"),
        primaryPhysicianPicklist: new Picklist("PrimaryPhysician"),
        secondaryNursePicklist: new Picklist("PrimaryNurse"),
        secondaryPhysicianPicklist: new Picklist("PrimaryPhysician"),
        secondaryNurseKey: "secondaryNurseKey",
        secondaryPhysicianKey: "secondaryPhysician",
        showConfirmation: false,
        showErrors: false,
    };

    cancel1Handler = (event) => {
        event.preventDefault();
        this.setState({showConfirmation: true});
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.props.patientEnterpriseComponent.executeUpdateRollback();
        this.props.view.initiateAction(null);
    };

    componentDidMount() {
        this.props.patientEnterpriseComponent.editRecord();

        const assignedPhysicianFormElement = updateObject(this.state.controls["AssignedPhysician"], {
            value: this.props.patientEnterpriseComponent.getAttributeValue("PrimaryDoctorUSID"),
            valid: true,
            touched: false,
        });
        const assignedNurseFormElement = updateObject(this.state.controls["AssignedNurse"], {
            value: this.props.patientEnterpriseComponent.getAttributeValue("PrimaryNurseUSID"),
            valid: true,
            touched: false,
        });

        let secondaryPhysicianValues = [];
        for (let i = 0; i < this.props.secondaryPhysicianEnterpriseComponent.getBuffer().length; i++) {
            secondaryPhysicianValues.push(this.props.secondaryPhysicianEnterpriseComponent.getBuffer()[i]["USID"]);
        }
        let secondaryNurseValues = [];
        for (let i = 0; i < this.props.secondaryNurseEnterpriseComponent.getBuffer().length; i++) {
            secondaryNurseValues.push(this.props.secondaryNurseEnterpriseComponent.getBuffer()[i]["USID"]);
        }

        const secondaryPhysicianFormElement = updateObject(this.state.controls["SecondaryPhysician"], {
            value: secondaryPhysicianValues,
            valid: true,
            touched: false,
        });
        const secondaryNurseFormElement = updateObject(this.state.controls["SecondaryNurse"], {
            value: secondaryNurseValues,
            valid: true,
            touched: false,
        });

        const updatedForm = updateObject(this.state.controls, {
            "AssignedPhysician": assignedPhysicianFormElement,
            "AssignedNurse": assignedNurseFormElement,
            "SecondaryPhysician": secondaryPhysicianFormElement,
            "SecondaryNurse": secondaryNurseFormElement,
        });

        this.setState({controls: updatedForm, formIsValid: false});

        this.props.patientEnterpriseComponent.editRecord();
    }

    inputChangedHandler = (event, inputIdentifier) => {
        let displayValue = event.currentTarget.outerText;

        const updatedFormElement = updateObject(this.state.controls[inputIdentifier], {
            value: event.target.value,
            displayValue: displayValue,
            touched: true,
        });
        const updatedForm = updateObject(this.state.controls, {
            [inputIdentifier]: updatedFormElement,
        });

        this.setState({controls: updatedForm});
    };

    nurseChangedHandler = (event) => {
        let displayValue = event.currentTarget.outerText;

        const updatedFormElement = updateObject(this.state.controls["AssignedNurse"], {
            value: event.target.value,
            displayValue: displayValue,
            touched: true,
        });
        const updatedForm = updateObject(this.state.controls, {
            "AssignedNurse": updatedFormElement,
        });

        this.props.patientEnterpriseComponent.setAttributeValue("PrimaryNurseUSID", event.target.value);
        this.props.patientEnterpriseComponent.setAttributeValue("PrimaryNurseFullName", displayValue);

        let picklist = new Picklist("PrimaryNurse");
        picklist.setExclusionList([event.target.value]);
        this.setState({controls: updatedForm, "secondaryNursePicklist": picklist, secondaryNurseKey: uuidv4()});
    };

    physicianChangedHandler = (event) => {
        let displayValue = event.currentTarget.outerText;

        const updatedFormElement = updateObject(this.state.controls["AssignedPhysician"], {
            value: event.target.value,
            displayValue: displayValue,
            touched: true,
        });
        const updatedForm = updateObject(this.state.controls, {
            "AssignedPhysician": updatedFormElement,
        });

        this.props.patientEnterpriseComponent.setAttributeValue("PrimaryDoctorUSID", event.target.value);
        this.props.patientEnterpriseComponent.setAttributeValue("PrimaryDoctorFullName", displayValue);

        let picklist = new Picklist("PrimaryPhysician");
        picklist.setExclusionList([event.target.value]);
        this.setState({controls: updatedForm, "secondaryPhysicianPicklist": picklist, secondaryPhysicianKey: uuidv4()});
    };

    isFormValid = () => {
        let formIsValid = true;

        if ((this.state.controls.AssignedPhysician.value === "NULL" || !this.state.controls.AssignedPhysician.value) &&
            (this.state.controls.AssignedNurse.value === "NULL" || !this.state.controls.AssignedNurse.value)) {
            formIsValid = false;
        }
        return formIsValid;
    };

    isFormTouched = () => {
        for (let formElementIdentifier in this.state.controls) {
            if (this.state.controls[formElementIdentifier].touched === true) {
                return true;
            }
        }
        return false;
    };

    render() {
        let secondaryNurse = (
            <Grid key={this.state.secondaryNurseKey} item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <CloudcathMultiSelect
                    margin="dense"
                    fullWidth={true}
                    label="Secondary Nurse"
                    id="SecondaryNurse"
                    onChange={(event) => this.inputChangedHandler(event, "SecondaryNurse")}
                    value={this.state.controls["SecondaryNurse"].value}
                    picklist={this.state.secondaryNursePicklist}
                    variant="outlined"
                    className={this.props.classes.multiSelect}
                />
            </Grid>
        );

        let secondaryPhysician = (
            <Grid key={this.state.secondaryPhysicianKey} item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <CloudcathMultiSelect
                    margin="dense"
                    fullWidth={true}
                    label="Secondary Physician"
                    id="SecondaryPhysician"
                    onChange={(event) => this.inputChangedHandler(event, "SecondaryPhysician")}
                    value={this.state.controls["SecondaryPhysician"].value}
                    picklist={this.state.secondaryPhysicianPicklist}
                    variant="outlined"
                    className={this.props.classes.multiSelect}
                />
            </Grid>
        );

        let interiorStructure = (
            <React.Fragment>
                <Grid key="AssignedNurse" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CloudcathSelect
                        margin="dense"
                        fullWidth={true}
                        label="Assigned Nurse"
                        helperText={!this.isFormValid() && this.state.controls["AssignedNurse"].helperText}
                        onChange={(event) => this.nurseChangedHandler(event)}
                        value={this.state.controls["AssignedNurse"].value || "NULL"}
                        picklist={this.state.primaryNursePicklist}
                        variant="outlined"
                        error={this.state.showErrors && this.isFormValid() === false}
                    />
                </Grid>
                <Grid key="AssignedPhysician" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CloudcathSelect
                        margin="dense"
                        fullWidth={true}
                        label="Assigned Physician"
                        helperText={!this.isFormValid() && this.state.controls["AssignedPhysician"].helperText}
                        onChange={(event) => this.physicianChangedHandler(event)}
                        value={this.state.controls["AssignedPhysician"].value || "NULL"}
                        picklist={this.state.primaryPhysicianPicklist}
                        variant="outlined"
                        error={this.state.showErrors && this.isFormValid() === false}
                    />
                </Grid>
                {secondaryNurse}
                {secondaryPhysician}
            </React.Fragment>
        );

        let content = (
            <Grid key="id7" container justifyContent="center">
                <form>
                    <Grid key="id6" item container spacing={2}>
                        {interiorStructure}
                    </Grid>
                </form>
            </Grid>
        );

        return (
            <React.Fragment>
                <Dialog onClose={this.cancel1Handler} open={true} scroll="body">
                    <DialogTitle>Edit care team</DialogTitle>
                    <DialogContent>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        <CCButton onClick={this.saveHandler}>
                            Save
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    saveHandler = (event) => {
        event.preventDefault();

        if (this.isFormValid() === false) {
            const updatedAssignedPhysicianFormElement = updateObject(this.state.controls["AssignedPhysician"], {
                helperText: "Either Assigned Physician or Assigned Nurse must contain a value.",
            });
            const updatedAssignedNurseFormElement = updateObject(this.state.controls["AssignedNurse"], {
                helperText: "Either Assigned Physician or Assigned Nurse must contain a value.",
            });
            const updatedForm = updateObject(this.state.controls, {
                "AssignedPhysician": updatedAssignedPhysicianFormElement,
                "AssignedNurse": updatedAssignedNurseFormElement,
            });

            this.setState({showErrors: true, controls: updatedForm});
        } else {
            const updatedAssignedPhysicianFormElement = updateObject(this.state.controls["AssignedPhysician"], {
                helperText: null,
            });
            const updatedAssignedNurseFormElement = updateObject(this.state.controls["AssignedNurse"], {
                helperText: null,
            });
            const updatedForm = updateObject(this.state.controls, {
                "AssignedPhysician": updatedAssignedPhysicianFormElement,
                "AssignedNurse": updatedAssignedNurseFormElement,
            });

            this.setState({showErrors: false, controls: updatedForm});

            let nursePayload = {
                patientUSID: this.props.patientEnterpriseComponent.getAttributeValue("USID"),
                users: [],
            };
            for (let i = 0; i < this.state.controls.SecondaryNurse.value.length; i++) {
                let user = {
                    usid: this.state.controls.SecondaryNurse.value[i],
                };
                nursePayload.users.push(user);
            }

            let physicianPayload = {
                patientUSID: this.props.patientEnterpriseComponent.getAttributeValue("USID"),
                users: [],
            };
            for (let i = 0; i < this.state.controls.SecondaryPhysician.value.length; i++) {
                let user = {
                    usid: this.state.controls.SecondaryPhysician.value[i],
                };
                physicianPayload.users.push(user);
            }

            this.props.patientEnterpriseComponent.executeUpdate();
            this.props.secondaryNurseEnterpriseComponent.performSupplementalUpdate(nursePayload);
            this.props.secondaryPhysicianEnterpriseComponent.performSupplementalUpdate(physicianPayload);
            this.props.view.initiateAction(null);
        }
    };

}

export default withAppContext(
    withStyles(styles, {withTheme: true})(CareTeamApplet),
);