import AdditionalEmailEnterpriseComponentMetadata from "./ec/impl/AdditionalEmailEnterpriseComponentMetadata";
import AdditionalPhoneEnterpriseComponentMetadata from "./ec/impl/AdditionalPhoneEnterpriseComponentMetadata";
import AllActiveUsersEnterpriseComponentMetadata from "./ec/impl/AllActiveUsersEnterpriseComponentMetadata";
import BillingAccountLookupEnterpriseComponentMetadata from "./ec/impl/BillingAccountLookupEnterpriseComponentMetadata";
import ClinicEnterpriseComponentMetadata from "./ec/impl/ClinicEnterpriseComponentMetadata";
import ClinicLookupEnterpriseComponentMetadata from "./ec/impl/ClinicLookupEnterpriseComponentMetadata";
import DeviceNRIEnterpriseComponentMetadata from "./ec/impl/DeviceNRIEnterpriseComponentMetadata";
import DeviceRIEnterpriseComponentMetadata from "./ec/impl/DeviceRIEnterpriseComponentMetadata";
import EscalationCommunicationEnterpriseComponentMetadata from "./ec/impl/EscalationCommunicationEnterpriseComponentMetadata";
import EscalationEncounterEnterpriseComponentMetadata from "./ec/impl/EscalationEncounterEnterpriseComponentMetadata";
import EscalationEnterpriseComponentMetadata from "./ec/impl/EscalationEnterpriseComponentMetadata";
import EscalationEventEnterpriseComponentMetadata from "./ec/impl/EscalationEventEnterpriseComponentMetadata";
import EscalationLabEnterpriseComponentMetadata from "./ec/impl/EscalationLabEnterpriseComponentMetadata";
import EscalationNoteEnterpriseComponentMetadata from "./ec/impl/EscalationNoteEnterpriseComponentMetadata";
import EscalationSymptomEnterpriseComponentMetadata from "./ec/impl/EscalationSymptomEnterpriseComponentMetadata";
import EscalationTreatmentEnterpriseComponentMetadata from "./ec/impl/EscalationTreatmentEnterpriseComponentMetadata";
import InactiveUserEnterpriseComponentMetadata from "./ec/impl/InactiveUserEnterpriseComponentMetadata";
import InvitationEnterpriseComponentMetadata from "./ec/impl/InvitationEnterpriseComponentMetadata";
import LabAttachmentEnterpriseComponentMetadata from "./ec/impl/LabAttachmentEnterpriseComponentMetadata";
import MainEmailEnterpriseComponentMetadata from "./ec/impl/MainEmailEnterpriseComponentMetadata";
import MainPhoneEnterpriseComponentMetadata from "./ec/impl/MainPhoneEnterpriseComponentMetadata";
import NoteAttachmentEnterpriseComponentMetadata from "./ec/impl/NoteAttachmentEnterpriseComponentMetadata";
import PatientAlertEnterpriseComponentMetadata from "./ec/impl/PatientAlertEnterpriseComponentMetadata";
import PatientArchiveEnterpriseComponentMetadata from "./ec/impl/PatientArchiveEnterpriseComponentMetadata";
import PatientAREnterpriseComponentMetadata from "./ec/impl/PatientAREnterpriseComponentMetadata";
import PatientAttachmentEnterpriseComponentMetadata from "./ec/impl/PatientAttachmentEnterpriseComponentMetadata";
import PatientDataEnterpriseComponentMetadata from "./ec/impl/PatientDataEnterpriseComponentMetadata";
import PatientEnterpriseComponentMetadata from "./ec/impl/PatientEnterpriseComponentMetadata";
import PatientIdentifierEnterpriseComponentMetadata from "./ec/impl/PatientIdentifierEnterpriseComponentMetadata";
import PatientINEnterpriseComponentMetadata from "./ec/impl/PatientINEnterpriseComponentMetadata";
import PatientS0EnterpriseComponentMetadata from "./ec/impl/PatientS0EnterpriseComponentMetadata";
import PatientUWEnterpriseComponentMetadata from "./ec/impl/PatientUWEnterpriseComponentMetadata";
import PrimaryNurseEnterpriseComponentMetadata from "./ec/impl/PrimaryNurseEnterpriseComponentMetadata";
import PrimaryPhysicianEnterpriseComponentMetadata from "./ec/impl/PrimaryPhysicianEnterpriseComponentMetadata";
import SecondaryNurseEnterpriseComponentMetadata from "./ec/impl/SecondaryNurseEnterpriseComponentMetadata";
import SecondaryPhysicianEnterpriseComponentMetadata from "./ec/impl/SecondaryPhysicianEnterpriseComponentMetadata";
import SymptomAttachmentEnterpriseComponentMetadata from "./ec/impl/SymptomAttachmentEnterpriseComponentMetadata";
import TreatmentAttachmentEnterpriseComponentMetadata from "./ec/impl/TreatmentAttachmentEnterpriseComponentMetadata";
import UserEnterpriseComponentMetadata from "./ec/impl/UserEnterpriseComponentMetadata";
import UsernameEnterpriseComponentMetadata from "./ec/impl/UsernameEnterpriseComponentMetadata";

import ClinicEnterpriseObjectMetadata from "./eo/impl/ClinicEnterpriseObjectMetadata";
import DeviceNRIEnterpriseObjectMetadata from "./eo/impl/DeviceNRIEnterpriseObjectMetadata";
import DeviceRIEnterpriseObjectMetadata from "./eo/impl/DeviceRIEnterpriseObjectMetadata";
import InactiveUserEnterpriseObjectMetadata from "./eo/impl/InactiveUserEnterpriseObjectMetadata";
import InvitationEnterpriseObjectMetadata from "./eo/impl/InvitationEnterpriseObjectMetadata";
import PatientAlertEnterpriseObjectMetadata from "./eo/impl/PatientAlertEnterpriseObjectMetadata";
import PatientArchiveEnterpriseObjectMetadata from "./eo/impl/PatientArchiveEnterpriseObjectMetadata";
import PatientAREnterpriseObjectMetadata from "./eo/impl/PatientAREnterpriseObjectMetadata";
import PatientEnterpriseObjectMetadata from "./eo/impl/PatientEnterpriseObjectMetadata";
import PatientINEnterpriseObjectMetadata from "./eo/impl/PatientINEnterpriseObjectMetadata";
import PatientS0EnterpriseObjectMetadata from "./eo/impl/PatientS0EnterpriseObjectMetadata";
import PatientUWEnterpriseObjectMetadata from "./eo/impl/PatientUWEnterpriseObjectMetadata";
import UserEnterpriseObjectMetadata from "./eo/impl/UserEnterpriseObjectMetadata";

import AdditionalEmailPicklistMetadata from "./picklist/impl/AdditionalEmailPicklistMetadata";
import AdditionalPhonePicklistMetadata from "./picklist/impl/AdditionalPhonePicklistMetadata";
import AllActiveUsersOptionalPicklistMetadata from "./picklist/impl/AllActiveUsersOptionalPicklistMetadata";
import AllActiveUsersPicklistMetadata from "./picklist/impl/AllActiveUsersPicklistMetadata";
import BillingAccountPicklistMetadata from "./picklist/impl/BillingAccountPicklistMetadata";
import ClinicPicklistMetadata from "./picklist/impl/ClinicPicklistMetadata";
import CultureResultPicklistMetadata from "./picklist/impl/CultureResultPicklistMetadata";
import CyclerActStudyPicklistMetadata from "./picklist/impl/CyclerActStudyPicklistMetadata";
import CyclerPicklistMetadata from "./picklist/impl/CyclerPicklistMetadata";
import DeviceLastUsedPicklistMetadata from "./picklist/impl/DeviceLastUsedPicklistMetadata";
import EffluentCollectedByPicklistMetadata from "./picklist/impl/EffluentCollectedByPicklistMetadata";
import FormulationPicklistMetadata from "./picklist/impl/FormulationPicklistMetadata";
import FrequencyPicklistMetadata from "./picklist/impl/FrequencyPicklistMetadata";
import GramStainPicklistMetadata from "./picklist/impl/GramStainPicklistMetadata";
import LabCultureSourcePicklistMetadata from "./picklist/impl/LabCultureSourcePicklistMetadata";
import MainEmailPicklistMetadata from "./picklist/impl/MainEmailPicklistMetadata";
import MainPhonePicklistMetadata from "./picklist/impl/MainPhonePicklistMetadata";
import OptionalCharacteristicsPicklistMetadata from "./picklist/impl/OptionalCharacteristicsPicklistMetadata";
import PatientIdentifierPicklistMetadata from "./picklist/impl/PatientIdentifierPicklistMetadata";
import PrimaryNursePicklistMetadata from "./picklist/impl/PrimaryNursePicklistMetadata";
import PrimaryPhysicianPicklistMetadata from "./picklist/impl/PrimaryPhysicianPicklistMetadata";
import RoutePicklistMetadata from "./picklist/impl/RoutePicklistMetadata";
import SalutationPicklistMetadata from "./picklist/impl/SalutationPicklistMetadata";
import SusceptibilityPicklistMetadata from "./picklist/impl/SusceptibilityPicklistMetadata";
import SymptomPicklistMetadata from "./picklist/impl/SymptomPicklistMetadata";
import UnitPicklistMetadata from "./picklist/impl/UnitPicklistMetadata";
import UsernamePicklistMetadata from "./picklist/impl/UsernamePicklistMetadata";
import UserRolePicklistMetadata from "./picklist/impl/UserRolePicklistMetadata";
import UserStatusPicklistMetadata from "./picklist/impl/UserStatusPicklistMetadata";

import EscalationCommunicationRelationshipMetadata from "./relationship/impl/EscalationCommunicationRelationshipMetadata";
import EscalationEncounterRelationshipMetadata from "./relationship/impl/EscalationEncounterRelationshipMetadata";
import EscalationEventRelationshipMetadata from "./relationship/impl/EscalationEventRelationshipMetadata";
import EscalationLabAttachmentRelationshipMetadata from "./relationship/impl/EscalationLabAttachmentRelationshipMetadata";
import EscalationLabRelationshipMetadata from "./relationship/impl/EscalationLabRelationshipMetadata";
import EscalationNoteAttachmentRelationshipMetadata from "./relationship/impl/EscalationNoteAttachmentRelationshipMetadata";
import EscalationNoteRelationshipMetadata from "./relationship/impl/EscalationNoteRelationshipMetadata";
import EscalationSymptomAttachmentRelationshipMetadata from "./relationship/impl/EscalationSymptomAttachmentRelationshipMetadata";
import EscalationSymptomRelationshipMetadata from "./relationship/impl/EscalationSymptomRelationshipMetadata";
import EscalationTreatmentAttachmentRelationshipMetadata from "./relationship/impl/EscalationTreatmentAttachmentRelationshipMetadata";
import EscalationTreatmentRelationshipMetadata from "./relationship/impl/EscalationTreatmentRelationshipMetadata";
import PatientAlertRelationshipMetadata from "./relationship/impl/PatientAlertRelationshipMetadata";
import PatientAttachmentRelationshipMetadata from "./relationship/impl/PatientAttachmentRelationshipMetadata";
import PatientDataRelationshipMetadata from "./relationship/impl/PatientDataRelationshipMetadata";

import PatientEscalationRelationshipMetadata from "./relationship/impl/PatientEscalationRelationshipMetadata";
import PatientSecondaryNurseRelationshipMetadata from "./relationship/impl/PatientSecondaryNurseRelationshipMetadata";
import PatientSecondaryPhysicianRelationshipMetadata from "./relationship/impl/PatientSecondaryPhysicianRelationshipMetadata";

const enterpriseObjectMetadataClasses = {
    PatientEnterpriseObjectMetadata,
    PatientAREnterpriseObjectMetadata,
    PatientUWEnterpriseObjectMetadata,
    PatientS0EnterpriseObjectMetadata,
    PatientINEnterpriseObjectMetadata,
    PatientArchiveEnterpriseObjectMetadata,
    UserEnterpriseObjectMetadata,
    InactiveUserEnterpriseObjectMetadata,
    InvitationEnterpriseObjectMetadata,
    DeviceNRIEnterpriseObjectMetadata,
    DeviceRIEnterpriseObjectMetadata,
    ClinicEnterpriseObjectMetadata,
    PatientAlertEnterpriseObjectMetadata,
};

const enterpriseComponentMetadataClasses = {
    PatientEnterpriseComponentMetadata,
    PatientAREnterpriseComponentMetadata,
    PatientUWEnterpriseComponentMetadata,
    PatientS0EnterpriseComponentMetadata,
    PatientINEnterpriseComponentMetadata,
    PatientArchiveEnterpriseComponentMetadata,
    DeviceRIEnterpriseComponentMetadata,
    DeviceNRIEnterpriseComponentMetadata,
    PrimaryNurseEnterpriseComponentMetadata,
    PrimaryPhysicianEnterpriseComponentMetadata,
    AllActiveUsersEnterpriseComponentMetadata,
    SecondaryNurseEnterpriseComponentMetadata,
    SecondaryPhysicianEnterpriseComponentMetadata,
    UserEnterpriseComponentMetadata,
    InactiveUserEnterpriseComponentMetadata,
    InvitationEnterpriseComponentMetadata,
    UsernameEnterpriseComponentMetadata,
    MainEmailEnterpriseComponentMetadata,
    AdditionalEmailEnterpriseComponentMetadata,
    MainPhoneEnterpriseComponentMetadata,
    AdditionalPhoneEnterpriseComponentMetadata,
    PatientIdentifierEnterpriseComponentMetadata,
    ClinicEnterpriseComponentMetadata,
    ClinicLookupEnterpriseComponentMetadata,
    PatientDataEnterpriseComponentMetadata,
    EscalationEnterpriseComponentMetadata,
    EscalationLabEnterpriseComponentMetadata,
    EscalationEventEnterpriseComponentMetadata,
    EscalationEncounterEnterpriseComponentMetadata,
    EscalationCommunicationEnterpriseComponentMetadata,
    EscalationTreatmentEnterpriseComponentMetadata,
    EscalationSymptomEnterpriseComponentMetadata,
    EscalationNoteEnterpriseComponentMetadata,
    PatientAlertEnterpriseComponentMetadata,
    LabAttachmentEnterpriseComponentMetadata,
    TreatmentAttachmentEnterpriseComponentMetadata,
    SymptomAttachmentEnterpriseComponentMetadata,
    NoteAttachmentEnterpriseComponentMetadata,
    PatientAttachmentEnterpriseComponentMetadata,
    BillingAccountLookupEnterpriseComponentMetadata,
};

const relationshipMetadataClasses = {
    PatientDataRelationshipMetadata,
    PatientEscalationRelationshipMetadata,
    PatientAttachmentRelationshipMetadata,
    EscalationLabRelationshipMetadata,
    EscalationLabAttachmentRelationshipMetadata,
    EscalationTreatmentAttachmentRelationshipMetadata,
    EscalationSymptomAttachmentRelationshipMetadata,
    EscalationNoteAttachmentRelationshipMetadata,
    EscalationEventRelationshipMetadata,
    EscalationEncounterRelationshipMetadata,
    EscalationNoteRelationshipMetadata,
    EscalationTreatmentRelationshipMetadata,
    EscalationSymptomRelationshipMetadata,
    EscalationCommunicationRelationshipMetadata,
    PatientSecondaryPhysicianRelationshipMetadata,
    PatientSecondaryNurseRelationshipMetadata,
    PatientAlertRelationshipMetadata,
};

const picklistMetadataClasses = {
    CyclerPicklistMetadata,
    CyclerActStudyPicklistMetadata,
    PrimaryNursePicklistMetadata,
    PrimaryPhysicianPicklistMetadata,
    UsernamePicklistMetadata,
    MainEmailPicklistMetadata,
    AdditionalEmailPicklistMetadata,
    MainPhonePicklistMetadata,
    AdditionalPhonePicklistMetadata,
    SalutationPicklistMetadata,
    UserStatusPicklistMetadata,
    OptionalCharacteristicsPicklistMetadata,
    UserRolePicklistMetadata,
    PatientIdentifierPicklistMetadata,
    DeviceLastUsedPicklistMetadata,
    // InactivePatientsPicklistMetadata,
    AllActiveUsersPicklistMetadata,
    AllActiveUsersOptionalPicklistMetadata,
    FrequencyPicklistMetadata,
    RoutePicklistMetadata,
    UnitPicklistMetadata,
    FormulationPicklistMetadata,
    GramStainPicklistMetadata,
    CultureResultPicklistMetadata,
    SusceptibilityPicklistMetadata,
    SymptomPicklistMetadata,
    ClinicPicklistMetadata,
    EffluentCollectedByPicklistMetadata,
    LabCultureSourcePicklistMetadata,
    BillingAccountPicklistMetadata,
};

export default function metadataClassFactory(type, name) {
    if (type === "eo") {
        return enterpriseObjectMetadataClasses[name];
    } else if (type === "ec") {
        return enterpriseComponentMetadataClasses[name];
    } else if (type === "rel") {
        return relationshipMetadataClasses[name];
    } else if (type === "pl") {
        return picklistMetadataClasses[name];
    }
}