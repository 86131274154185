import UserEnterpriseComponentMetadata from "./UserEnterpriseComponentMetadata";

class SecondaryPhysicianEnterpriseComponentMetadata extends UserEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "SecondaryPhysician";
        this.defaultCacheSize = 1000;
    }
}

export default SecondaryPhysicianEnterpriseComponentMetadata;