import * as jwt from "jsonwebtoken";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import EventBus from "./EventBus";
import {useHandler} from "./useHandler";

const SessionSchema = {
    identityToken: String,
    accessToken: String,
    refreshToken: String,
    optionalCharacteristics: String,
    pickValues: Object,
    patientAlerts: Object,
    actStudy: Boolean,
    userConfig: Object,
};

const IdentityTokenSchema = {
    providerUSID: {type: String, attribute: "provider_usid"},
    providerName: {type: String, attribute: "provider_name"},
    clinicUSID: {type: String, attribute: "clinic_usid"},
    clinicName: {type: String, attribute: "clinic_name"},
    clinicIdentifier: {type: String, attribute: "clinic_identifier"},
    clinicTimezone: {type: String, attribute: "clinic_timezone"},
    userUSID: {type: String, attribute: "user_usid"},
    firstName: {type: String, attribute: "first_name"},
    lastName: {type: String, attribute: "last_name"},
    suffix: {type: String, attribute: "suffix"},
    username: {type: String, attribute: "username"},
    salutation: {type: String, attribute: "salutation"},
    userRole: {type: String, attribute: "user_role"},
    realm: {type: String, attribute: "realm"},
};

export class SessionContext {

    constructor(data) {

        this.data = data;

        this._identityToken = null;
        this._identityTokenClaims = null;

        this._accessToken = null;
        this._accessTokenClaims = null;

        Object.keys({...SessionSchema, ...IdentityTokenSchema}).forEach((key) =>
            Object.defineProperty(this, key, {
                get: () => this.__getattr__(key),
                set: (value) => this.__setattr__(key, value),
                enumerable: true,
                configurable: true,
            }),
        );

        this.sessionId = SessionContext.loadSessionId();
        this.instanceId = uuidv4();
    }

    static loadSessionId() {
        let sessionId = sessionStorage.getItem("sessionId");
        if (sessionId === null) {
            sessionId = uuidv4();
            sessionStorage.setItem("sessionId", sessionId);
        }
        return sessionId;
    }

    __hasattr__(key) {
        const value = this.__getattr__(key);
        return value !== null && value !== undefined;
    }

    __getattr__(key) {

        if (key in SessionSchema) {
            const value = this.data ? this.data[key] : sessionStorage.getItem(key);
            return this.convert(SessionSchema[key], key, value);
        }

        if (key in IdentityTokenSchema) {
            const identityTokenClaims = this.identityTokenClaims();
            const attribute = IdentityTokenSchema[key].attribute;
            const value = identityTokenClaims ? identityTokenClaims[attribute] : undefined;
            return this.convert(IdentityTokenSchema[key].type, key, value);
        }

        return undefined;
    }

    __setattr__(key, value) {

        if (key in SessionSchema) {
            this.update(Object.fromEntries([[key, value]]));
            return true;
        }

        return false;
    }

    update(items) {

        let updated = {};

        Object.entries(items).forEach(([key, value]) => {

            if (key in SessionSchema) {

                if (value === null) {

                    this.data
                        ? (this.data[key] = undefined)
                        : sessionStorage.removeItem(key);

                } else {

                    if (SessionSchema[key] === Object) {
                        value = JSON.stringify(value);
                    }

                    this.data
                        ? (this.data[key] = value)
                        : sessionStorage.setItem(key, value);
                }

                updated[key] = value;
            }
        });

        if (!this.data && Object.keys(updated).length > 0) {
            EventBus.session.emit("update", updated);
        }

        return updated;
    }

    ttl() {
        const accessTokenClaims = this.accessTokenClaims();
        if (!accessTokenClaims) return 0;
        return Date.now() / 1000 - accessTokenClaims.exp;
    }

    valid() {
        return Boolean(
            this.identityTokenClaims() &&
            this.accessTokenClaims(),
        );
    }

    dump() {
        return _.reduce(
            SessionSchema,
            (result, value, key) => {
                value = this.__getattr__(key);
                if (value !== null && value !== undefined) {
                    result[key] = value;
                }
                return result;
            },
            {},
        );
    }

    size() {
        return _.size(this.dump());
    }

    reset() {

        const updated = this.update(
            Object.fromEntries(Object.keys(SessionSchema).map((k) => [k, null])),
        );

        if (!this.data) {
            sessionStorage.removeItem("sessionId");
        }

        EventBus.session.emit("update");

        return updated;
    }

    convert(type, key, value) {
        if (value === "null") value = null;
        if (value === null) return null;
        if (value === undefined) return undefined;
        if (typeof value === "string") {
            if (type === Boolean) return value === "true";
            if (type === Object) return JSON.parse(value);
        }
        return value;
    }

    jwtDecode(token) {
        try {
            return jwt.decode(token, {complete: false, ignoreExpiration: true});
        } catch (e) {
            return null;
        }
    }

    identityTokenClaims() {
        const identityToken = this.__getattr__("identityToken");
        if (this._identityToken !== identityToken) {
            this._identityTokenClaims = this.jwtDecode(identityToken);
            this._identityToken = identityToken;
        }
        return this._identityTokenClaims;
    }

    accessTokenClaims() {
        const accessToken = this.__getattr__("accessToken");
        if (this._accessToken !== accessToken) {
            this._accessTokenClaims = this.jwtDecode(accessToken);
            this._accessToken = accessToken;
        }
        return this._accessTokenClaims;
    }
}

export function useSessionContext() {

    const [sessionContext, setSessionContext] = useState(new SessionContext());

    const updateListener = useHandler(
        () => setSessionContext(new SessionContext()),
        [sessionContext],
    );

    useEffect(() => {
        EventBus.session.addListener("update", updateListener);
        return () => EventBus.session.removeListener("update", updateListener);
    }, [updateListener]);

    return sessionContext;
}

export function withSessionContext(Component) {
    return (props) => {
        const sessionContext = useSessionContext();
        return <Component sessionContext={sessionContext} {...props} />;
    };
}
