import {InputLabel} from "@material-ui/core";
import React from "react";
import "./css/CCInputLabel.scss";

export default function CCInputLabel({children, ...props}) {
    return (
        <InputLabel {...{"data-cc-component": "CCInputLabel"}} {...props}>
            {children}
        </InputLabel>
    );
}
