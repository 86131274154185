import EnterpriseObjectMemberMetadata from "../../../metadata/eo/EnterpriseObjectMemberMetadata";
import EnterpriseObjectMetadata from "../../../metadata/eo/EnterpriseObjectMetadata";

class PatientAREnterpriseObjectMetadata extends EnterpriseObjectMetadata {
    constructor() {
        super();

        this.name = "PatientAR";
        this.primaryEnterpriseComponent = "Patient";

        let eom1 = new EnterpriseObjectMemberMetadata();
        eom1.name = "Patient";
        eom1.enterpriseComponent = "PatientAR";
        eom1.relationship = null;
        this.enterpriseComponentMembers[eom1.name] = eom1;

        let eom1b = new EnterpriseObjectMemberMetadata();
        eom1b.name = "PatientAttachment";
        eom1b.enterpriseComponent = "PatientAttachment";
        eom1b.relationship = "PatientAttachment";
        this.enterpriseComponentMembers[eom1b.name] = eom1b;

        let eom2 = new EnterpriseObjectMemberMetadata();
        eom2.name = "PatientData";
        eom2.enterpriseComponent = "PatientData";
        eom2.relationship = "PatientData";
        this.enterpriseComponentMembers[eom2.name] = eom2;

        let eom3 = new EnterpriseObjectMemberMetadata();
        eom3.name = "Escalation";
        eom3.enterpriseComponent = "Escalation";
        eom3.relationship = "PatientEscalation";
        this.enterpriseComponentMembers[eom3.name] = eom3;

        let eom4 = new EnterpriseObjectMemberMetadata();
        eom4.name = "EscalationLab";
        eom4.enterpriseComponent = "EscalationLab";
        eom4.relationship = "EscalationLab";
        this.enterpriseComponentMembers[eom4.name] = eom4;

        let eom4b = new EnterpriseObjectMemberMetadata();
        eom4b.name = "LabAttachment";
        eom4b.enterpriseComponent = "LabAttachment";
        eom4b.relationship = "EscalationLabAttachment";
        this.enterpriseComponentMembers[eom4b.name] = eom4b;

        let eom5 = new EnterpriseObjectMemberMetadata();
        eom5.name = "EscalationEncounter";
        eom5.enterpriseComponent = "EscalationEncounter";
        eom5.relationship = "EscalationEncounter";
        this.enterpriseComponentMembers[eom5.name] = eom5;

        let eom6 = new EnterpriseObjectMemberMetadata();
        eom6.name = "EscalationTreatment";
        eom6.enterpriseComponent = "EscalationTreatment";
        eom6.relationship = "EscalationTreatment";
        this.enterpriseComponentMembers[eom6.name] = eom6;

        let eom6b = new EnterpriseObjectMemberMetadata();
        eom6b.name = "TreatmentAttachment";
        eom6b.enterpriseComponent = "TreatmentAttachment";
        eom6b.relationship = "EscalationTreatmentAttachment";
        this.enterpriseComponentMembers[eom6b.name] = eom6b;

        let eom7 = new EnterpriseObjectMemberMetadata();
        eom7.name = "EscalationNote";
        eom7.enterpriseComponent = "EscalationNote";
        eom7.relationship = "EscalationNote";
        this.enterpriseComponentMembers[eom7.name] = eom7;

        let eom7b = new EnterpriseObjectMemberMetadata();
        eom7b.name = "NoteAttachment";
        eom7b.enterpriseComponent = "NoteAttachment";
        eom7b.relationship = "EscalationNoteAttachment";
        this.enterpriseComponentMembers[eom7b.name] = eom7b;

        let eom8 = new EnterpriseObjectMemberMetadata();
        eom8.name = "EscalationEvent";
        eom8.enterpriseComponent = "EscalationEvent";
        eom8.relationship = "EscalationEvent";
        this.enterpriseComponentMembers[eom8.name] = eom8;

        let eom10 = new EnterpriseObjectMemberMetadata();
        eom10.name = "EscalationCommunication";
        eom10.enterpriseComponent = "EscalationCommunication";
        eom10.relationship = "EscalationCommunication";
        this.enterpriseComponentMembers[eom10.name] = eom10;

        let eom11 = new EnterpriseObjectMemberMetadata();
        eom11.name = "SecondaryPhysician";
        eom11.enterpriseComponent = "SecondaryPhysician";
        eom11.relationship = "PatientSecondaryPhysician";
        this.enterpriseComponentMembers[eom11.name] = eom11;

        let eom12 = new EnterpriseObjectMemberMetadata();
        eom12.name = "SecondaryNurse";
        eom12.enterpriseComponent = "SecondaryNurse";
        eom12.relationship = "PatientSecondaryNurse";
        this.enterpriseComponentMembers[eom12.name] = eom12;

        let eom13 = new EnterpriseObjectMemberMetadata();
        eom13.name = "EscalationSymptom";
        eom13.enterpriseComponent = "EscalationSymptom";
        eom13.relationship = "EscalationSymptom";
        this.enterpriseComponentMembers[eom13.name] = eom13;

        let eom13b = new EnterpriseObjectMemberMetadata();
        eom13b.name = "SymptomAttachment";
        eom13b.enterpriseComponent = "SymptomAttachment";
        eom13b.relationship = "EscalationSymptomAttachment";
        this.enterpriseComponentMembers[eom13b.name] = eom13b;

        let eom14 = new EnterpriseObjectMemberMetadata();
        eom14.name = "PatientAlert";
        eom14.enterpriseComponent = "PatientAlert";
        eom14.relationship = "PatientAlert";
        this.enterpriseComponentMembers[eom14.name] = eom14;
    }
}

export default PatientAREnterpriseObjectMetadata;