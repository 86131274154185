import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class InvitationEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "Invitation";

        this.attributes["ProviderUserUSID"] = new EnterpriseComponentAttributeMetadata(
            "ProviderUserUSID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,   // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicUSID"] = new EnterpriseComponentAttributeMetadata(
            "ClinicUSID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,   // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicIdentifier"] = new EnterpriseComponentAttributeMetadata(
            "ClinicIdentifier", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            8,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicName"] = new EnterpriseComponentAttributeMetadata(
            "ClinicName", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Username"] = new EnterpriseComponentAttributeMetadata(
            "Username", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            "Username",  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            8,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["FirstName"] = new EnterpriseComponentAttributeMetadata(
            "FirstName", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["LastName"] = new EnterpriseComponentAttributeMetadata(
            "LastName", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["UserRole"] = new EnterpriseComponentAttributeMetadata(
            "UserRole", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["InvitationStatus"] = new EnterpriseComponentAttributeMetadata(
            "InvitationStatus", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["InvitationDatetime"] = new EnterpriseComponentAttributeMetadata(
            "InvitationDatetime", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,   // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
    }
}

export default InvitationEnterpriseComponentMetadata;