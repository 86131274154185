import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1PatientEscalationLabIDOCToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class EscalationLabEnterpriseComponent extends EnterpriseComponent {
    performSingleRecordRefresh = (primaryKey) => {
        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_escalation_labs",
            json: true,
            params: {
                "USID": primaryKey,
            },
            headers: {
                "idoc-request": "v1.PatientEscalationLab",
            },
        };
        this.notifySingleRecordRefreshStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.refreshEnterpriseComponentSuccess(V1PatientEscalationLabIDOCToArray(response.data));
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    performQuery = (start, pageSize, foreignKey) => {
        let params = {};
        if (foreignKey === null) {
            params = {
                "Limit": pageSize,
                "Offset": start,
            };
        } else {
            params = {
                "Limit": pageSize,
                "Offset": start,
                "PatientEscalationUSID": foreignKey,
            };
        }

        params["Sort"] = this.getFormattedSortSpecification();

        let keys = Object.keys(this.getSearchSpecification());
        for (let i = 0; i < keys.length; i++) {
            params[keys[i]] = this.getSearchSpecification()[keys[i]];
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_escalation_labs",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.PatientEscalationLab",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1PatientEscalationLabIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientEscalationLab";

        let lab = this.preparePUTPayload();

        let attachment_ec = this.getEnterpriseObject().getEnterpriseComponent("LabAttachment");
        if (attachment_ec.getBuffer().length > 0) {
            lab["ListOfAttachment"] = {};
            lab["ListOfAttachment"]["Attachment"] = [];

            for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
                if (attachment_ec.getBuffer()[i]["ParentUSID"] === this.getAttributeValue("USID") && attachment_ec.getBuffer()[i]["__operation"] === "new") {
                    let attachment = {
                        "USID": attachment_ec.getBuffer()[i]["USID"],
                        "ParentUSID": attachment_ec.getBuffer()[i]["ParentUSID"],
                        "ParentType": attachment_ec.getBuffer()[i]["ParentType"],
                        "FileName": attachment_ec.getBuffer()[i]["FileName"],
                        "FileType": attachment_ec.getBuffer()[i]["FileType"],
                        "FileSize": attachment_ec.getBuffer()[i]["FileSize"],
                        "Payload": attachment_ec.getBuffer()[i]["Payload"],
                    };
                    lab["ListOfAttachment"]["Attachment"].push(attachment);
                }
            }
        }

        payload.CloudcathMessage.PatientEscalationLab = lab;

        return payload;
    };

    performUpdate = () => {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "cloudcath/v1/patient_escalation_labs",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientEscalationLab",
                "idoc-response": "v1.PatientEscalationLab",
            },
        };
        this.notifyUpdateStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1PatientEscalationLabIDOCToArray(response.data));
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let lab = {};

        let row = this.getBuffer()[this.getRecordPointer()];

        let patientEscalationEnterpriseComponent = this.getEnterpriseObject().getEnterpriseComponent("Escalation");

        let moment = require("moment");

        lab["USID"] = row["USID"];
        lab["PatientEscalationUSID"] = patientEscalationEnterpriseComponent.getBuffer()[patientEscalationEnterpriseComponent.getRecordPointer()]["USID"];
        lab["Name"] = row["Name"];
        lab["Result"] = row["Result"];
        lab["Type"] = row["Type"];
        lab["Leukocytes"] = (row["Leukocytes"] === "") ? null : parseInt(row["Leukocytes"]);
        lab["Neutrophilis"] = (row["Neutrophilis"] === "") ? null : parseInt(row["Neutrophilis"]);
        lab["Monocytes"] = (row["Monocytes"] === "") ? null : parseInt(row["Monocytes"]);
        lab["Lymphocytes"] = (row["Lymphocytes"] === "") ? null : parseInt(row["Lymphocytes"]);
        lab["Eosinophils"] = (row["Eosinophils"] === "") ? null : parseInt(row["Eosinophils"]);
        lab["RBC"] = (row["RBC"] === "") ? null : parseInt(row["RBC"]);
        lab["ColorAppearance"] = row["ColorAppearance"];
        lab["DwellTime"] = row["DwellTime"];
        lab["DwellVolume"] = row["DwellVolume"];
        lab["EffluentCollectedBy"] = row["EffluentCollectedBy"];
        lab["CultureSource"] = row["CultureSource"];
        lab["CultureOther"] = row["CultureOther"];
        lab["CultureResult"] = row["CultureResult"];
        lab["CulturePathogen"] = row["CulturePathogen"];
        lab["GramStain"] = row["GramStain"];
        lab["SusceptibilityAntimicrobial1"] = row["SusceptibilityAntimicrobial1"];
        lab["SusceptibilityPathogen1"] = row["SusceptibilityPathogen1"];
        lab["SusceptibilityAntimicrobial2"] = row["SusceptibilityAntimicrobial2"];
        lab["SusceptibilityPathogen2"] = row["SusceptibilityPathogen2"];
        lab["SusceptibilityAntimicrobial3"] = row["SusceptibilityAntimicrobial3"];
        lab["SusceptibilityPathogen3"] = row["SusceptibilityPathogen3"];
        lab["SusceptibilityAntimicrobial4"] = row["SusceptibilityAntimicrobial4"];
        lab["SusceptibilityPathogen4"] = row["SusceptibilityPathogen4"];
        lab["SusceptibilityAntimicrobial5"] = row["SusceptibilityAntimicrobial5"];
        lab["SusceptibilityPathogen5"] = row["SusceptibilityPathogen5"];
        lab["SusceptibilityAntimicrobial6"] = row["SusceptibilityAntimicrobial6"];
        lab["SusceptibilityPathogen6"] = row["SusceptibilityPathogen6"];
        lab["SusceptibilityAntimicrobial7"] = row["SusceptibilityAntimicrobial7"];
        lab["SusceptibilityPathogen7"] = row["SusceptibilityPathogen7"];
        lab["SusceptibilityAntimicrobial8"] = row["SusceptibilityAntimicrobial8"];
        lab["SusceptibilityPathogen8"] = row["SusceptibilityPathogen8"];
        lab["SusceptibilityAntimicrobial9"] = row["SusceptibilityAntimicrobial9"];
        lab["SusceptibilityPathogen9"] = row["SusceptibilityPathogen9"];
        lab["SusceptibilityAntimicrobial10"] = row["SusceptibilityAntimicrobial10"];
        lab["SusceptibilityPathogen10"] = row["SusceptibilityPathogen10"];
        lab["SampleCollectionDate"] = row["SampleCollectionDate"];
        this.setAttributeValue("SampleCollectionDateDisplay", moment(row["SampleCollectionDate"]).format("MMM DD, YYYY"));

        lab["Note"] = row["Note"];
        lab["EnteredByUSID"] = row["EnteredByUSID"];

        let attachment_ec = this.getEnterpriseObject().getEnterpriseComponent("LabAttachment");
        if (attachment_ec.getBuffer().length > 0) {
            lab["ListOfAttachment"] = {};
            lab["ListOfAttachment"]["Attachment"] = [];

            for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
                if (attachment_ec.getBuffer()[i]["ParentUSID"] === this.getAttributeValue("USID") && attachment_ec.getBuffer()[i]["__operation"] === "new") {
                    let attachment = {
                        "USID": attachment_ec.getBuffer()[i]["USID"],
                        "ParentUSID": attachment_ec.getBuffer()[i]["ParentUSID"],
                        "ParentType": attachment_ec.getBuffer()[i]["ParentType"],
                        "FileName": attachment_ec.getBuffer()[i]["FileName"],
                        "FileType": attachment_ec.getBuffer()[i]["FileType"],
                        "FileSize": attachment_ec.getBuffer()[i]["FileSize"],
                        "Payload": attachment_ec.getBuffer()[i]["Payload"],
                    };
                    lab["ListOfAttachment"]["Attachment"].push(attachment);
                }
            }
        }
        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientEscalationLab";
        payload.CloudcathMessage.PatientEscalationLab = lab;

        return payload;
    };

    performInsert = () => {
        let payload = this.enterpriseComponentPostIDOC();

        //let idocResponse = "v1.PatientEscalationLab";
        //if (payload["CloudcathMessage"]["PatientEscalationLab"]["ListOfAttachment"] !== undefined) {
        //    idocResponse = "v1.PatientEscalationLabAttachmentResponse";
        //}
        let options = {
            method: "POST",
            url: "cloudcath/v1/patient_escalation_labs",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientEscalationLab",
                "idoc-response": "v1.PatientEscalationLab",
            },
        };
        this.notifyInsertStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1PatientEscalationLabIDOCToArray(response.data));
                this.getEnterpriseObject().getEnterpriseComponent("EscalationNote").invalidate();
                this.getEnterpriseObject().getEnterpriseComponent("Escalation").performSingleRecordRefresh();
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };
}

export default EscalationLabEnterpriseComponent;