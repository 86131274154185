import {Container, Grid, Hidden, Typography, withStyles} from "@material-ui/core";
import {Refresh} from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React from "react";
import {withAppContext} from "../../../App";
import CCButton from "../../../components/common/CCButton";
import VerifyCommsDialog from "../../../components/user/VerifyCommsDialog";
import styles from "../../../theme/styles";
import AddUserApplet from "../../applet/impl/AddUserApplet";
import ResendInvitationApplet from "../../applet/impl/ResendInvitationApplet";
import RevokeInvitationApplet from "../../applet/impl/RevokeInvitationApplet";
import UserDeactivateApplet from "../../applet/impl/UserDeactivateApplet";
import UserDetailApplet from "../../applet/impl/UserDetailApplet";
import UserListActiveApplet from "../../applet/impl/UserListActiveApplet";
import UserListInactiveApplet from "../../applet/impl/UserListInactiveApplet";
import UserListInvitationApplet from "../../applet/impl/UserListInvitationApplet";
import UserReactivateApplet from "../../applet/impl/UserReactivateApplet";
import UserReassignPatientsApplet from "../../applet/impl/UserReassignPatientsApplet";
import eoecClassFactory from "../../EOECClassFactory";

import View from "../View";

class UserView extends View {
    state = {
        showAction: null,
        perspectives: {
            default: {},
        },
        activePerspective: "default",
        viewActionInProgress: null,
        orientation: "other",
        createObjectEnabled: false,
    };

    constructor(props) {
        super(props);
        this.state.enterpriseObjectInvitation = eoecClassFactory("eo", "Invitation");
        this.state.enterpriseObjectActive = eoecClassFactory("eo", "User");
        this.state.enterpriseObjectInactive = eoecClassFactory("eo", "InactiveUser");

        let userEnterpriseComponent = this.state.enterpriseObjectActive.getEnterpriseComponent("User");
        userEnterpriseComponent.addInvalidListener(this);
        userEnterpriseComponent.addLoadCompletedListener(this);

        this.defaultPageSize = 100;
    };

    enterpriseComponentInvalidated = (buffer) => {
        this.setState({createObjectEnabled: false});
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        this.setState({createObjectEnabled: true});
    };

    getName = () => {
        return "UserView";
    };

    initiateAction = (action) => {
        if (action === "RefreshUser") {
            this.state.enterpriseObjectInvitation.getEnterpriseComponent("Invitation").invalidate();
            this.state.enterpriseObjectActive.getEnterpriseComponent("User").invalidate();
            this.state.enterpriseObjectInactive.getEnterpriseComponent("InactiveUser").invalidate();
        } else {
            this.setState({showAction: action});
        }
    };

    render() {
        let content = null;

        if (this.getActivePerspective() === "default") {
            content = (
                <React.Fragment>
                    {this.renderCreateUser()}
                    {this.renderEditUser()}
                    {this.renderEditInactiveUser()}
                    {this.renderDeactivateUser()}
                    {this.renderReactivateUser()}
                    {this.renderReassignPatients()}
                    {this.renderVerifyCommunications()}
                    {this.renderVerifyInactiveCommunications()}
                    {this.renderRevokeInvitation()}
                    {this.renderResendInvitation()}
                    <React.Fragment>
                        {this.renderHeader()}
                        <Grid container direction="column" spacing={10}>
                            {this.renderDefaultPerspective()}
                        </Grid>
                    </React.Fragment>
                </React.Fragment>
            );
        }

        return (
            <Container className={this.props.classes.containerMargin} maxWidth="lg">
                {content}
            </Container>
        );
    }

    renderCreateUser = () => {
        if (this.state.showAction === "Add") {
            return <AddUserApplet enterpriseComponent={this.state.enterpriseObjectInvitation.getEnterpriseComponent("Invitation")}
                                  userEnterpriseComponent={this.state.enterpriseObjectActive.getEnterpriseComponent("User")}
                                  view={this}
                                  availableModes={["Edit", "New", "View"]} defaultMode="New" />;
        }
        return null;
    };

    renderDefaultPerspective = () => {
        if (this.state.enterpriseObject !== null) {
            return [
                <div key={1} style={{backgroundColor: "#2CA4DF", opacity: 0.3, height: "3px"}} />,
                <UserListActiveApplet key={4} enterpriseComponent={this.state.enterpriseObjectActive.getEnterpriseComponent("User")} view={this} />,
                <div key={3} style={{backgroundColor: "#2CA4DF", opacity: 0.3, height: "3px"}} />,
                <UserListInactiveApplet key={6} enterpriseComponent={this.state.enterpriseObjectInactive.getEnterpriseComponent("InactiveUser")} view={this} />,
                <div key={5} style={{backgroundColor: "#2CA4DF", opacity: 0.3, height: "3px"}} />,
                <UserListInvitationApplet key={2} enterpriseComponent={this.state.enterpriseObjectInvitation.getEnterpriseComponent("Invitation")} view={this} />,
            ];
        }
        return [];
    };

    renderEditUser = () => {
        if (this.state.showAction === "Edit Active User") {
            return <UserDetailApplet enterpriseComponent={this.state.enterpriseObjectActive.getEnterpriseComponent("User")} view={this} availableModes={["Edit", "New", "View"]} defaultMode="Edit" />;
        }

        return null;
    };

    renderEditInactiveUser = () => {
        if (this.state.showAction === "Edit Inactive User") {
            return <UserDetailApplet enterpriseComponent={this.state.enterpriseObjectInactive.getEnterpriseComponent("InactiveUser")} view={this} availableModes={["Edit", "New", "View"]} defaultMode="Edit" />;
        }

        return null;
    };

    renderDeactivateUser = () => {
        if (this.state.showAction === "Deactivate User") {
            return <UserDeactivateApplet enterpriseComponent={this.state.enterpriseObjectActive.getEnterpriseComponent("User")} view={this} />;
        }
        return null;
    };

    renderReactivateUser = () => {
        if (this.state.showAction === "Reactivate User") {
            return <UserReactivateApplet enterpriseComponent={this.state.enterpriseObjectInactive.getEnterpriseComponent("InactiveUser")} view={this} />;
        }
        return null;
    };

    renderReassignPatients = () => {
        if (this.state.showAction === "Re-Assign Patients") {
            return <UserReassignPatientsApplet enterpriseComponent={this.state.enterpriseObjectActive.getEnterpriseComponent("User")} view={this} />;
        }
        return null;
    };

    renderHeader = () => {
        return (
            <Grid container direction="column" style={{margin: "16px 0 33px 0"}}>
                <Grid container item direction="row" justifyContent="space-between">
                    <Typography variant="h1" style={{marginTop: "15px"}}>{this.props.appContext.sessionContext.providerName}/{this.props.appContext.sessionContext.clinicName}</Typography>
                    <div>
                        <Hidden only="xs">
                            <CCButton
                                style={{marginTop: "15px", marginRight: "0", width: "170px"}}
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => this.initiateAction("Add")}
                                disabled={!this.state.createObjectEnabled}>
                                Add User
                            </CCButton>
                        </Hidden>
                        <CCButton
                            variant="secondary"
                            style={{marginTop: "15px", marginRight: "0", width: "170px"}}
                            startIcon={<Refresh />}
                            onClick={() => this.initiateAction("RefreshUser")}
                            disabled={!this.state.createObjectEnabled}>
                            Refresh
                        </CCButton>
                    </div>
                </Grid>
            </Grid>
        );
    };

    renderRevokeInvitation = () => {
        if (this.state.showAction === "Revoke Invitation") {
            return <RevokeInvitationApplet enterpriseComponent={this.state.enterpriseObjectInvitation.getEnterpriseComponent("Invitation")} view={this} />;
        }
        return null;
    };

    renderResendInvitation = () => {
        if (this.state.showAction === "Resend Invitation") {
            return <ResendInvitationApplet enterpriseComponent={this.state.enterpriseObjectInvitation.getEnterpriseComponent("Invitation")} view={this} />;
        }
        return null;
    };

    renderVerifyCommunications = () => {
        if (this.state.showAction === "Verify Communications Active User") {
            return <VerifyCommsDialog enterpriseComponent={this.state.enterpriseObjectActive.getEnterpriseComponent("User")} view={this} />;
        }
        return null;
    };

    renderVerifyInactiveCommunications = () => {
        if (this.state.showAction === "Verify Communications Inactive User") {
            return <VerifyCommsDialog enterpriseComponent={this.state.enterpriseObjectInactive.getEnterpriseComponent("InactiveUser")} view={this} />;
        }

        return null;
    };

}

export default withAppContext(withStyles(styles, {withTheme: true})(UserView));