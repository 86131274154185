import React from "react";
import {withAppContext} from "../../../App";
import PatientAlert from "../../../components/PatientAlert";

import Applet from "../Applet";

class PatientAlertInactiveApplet extends Applet {

    state = {
        Name: "PatientAlertInactiveApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <PatientAlert applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientAlertInactiveApplet);
