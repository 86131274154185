import {ClickAwayListener, IconButton, Menu, MenuItem, Popper} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {MoreVert} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import NestedMenuItem from "./common/CCNestedMenuItem";

class PatientDetailMenu extends Component {
    state = {
        fetchBackgroundOperation: true,
        buffer: {},
        patientMenu: null,
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;
    }

    componentDidMount() {
        this.applet.getEnterpriseComponent().addValueChangedListener(this);
        this.applet.getEnterpriseComponent().addLoadCompletedListener(this);

        this.enterpriseComponentLoadCompleted(this.applet.getEnterpriseComponent().getBuffer(), this);
    }

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    }

    enterpriseComponentValueChanged = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    enterpriseComponentLoadCompleted = (buffer, ec) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    getName = () => {
        return this.applet.getName();
    };

    handlePatientOptions = (event, option) => {
        event.stopPropagation();

        if (option === "Update patient status") {
            this.setState({patientSubmenu: event.currentTarget});
        } else {
            if (option === "Archive - expired") {
                this.applet.getView().initiateAction("PatientExpire");
            } else if (option === "Archive - transplant") {
                this.applet.getView().initiateAction("PatientTransplant");
            } else if (option === "Deactivate") {
                this.applet.getView().initiateAction("PatientDeactivate");
            } else if (option === "Reactivate") {
                this.applet.getView().initiateAction("PatientReactivate");
            } else if (option === "Edit Turbidity Notification Settings") {
                this.applet.getView().initiateAction("Edit turbidity notification full");
            }
            this.setState({patientMenu: null, patientSubmenu: null});
        }
    };

    handlePatientMenuClick = (event) => {
        this.setState({patientMenu: event.currentTarget});
    };

    handlePatientSubmenuClick = (event) => {
        this.setState({patientSubmenu: event.currentTarget});
    };

    handlePatientMenuClose = (event) => {
        event.stopPropagation();
        this.setState({patientMenu: null});
    };

    render() {
        let deactivate = (
            <MenuItem key="1" onClick={(event) => this.handlePatientOptions(event, "Deactivate")}>
                Deactivate
            </MenuItem>
        );
        if (this.state.buffer["PatientState"] === "Inactive" || this.state.buffer["PatientState"] === "Archived") {
            deactivate = null;
        }

        let reactivate = (
            <MenuItem key="2" onClick={(event) => this.handlePatientOptions(event, "Reactivate")}>
                Reactivate
            </MenuItem>
        );
        if (this.state.buffer["PatientState"] !== "Inactive" && this.state.buffer["PatientState"] !== "Archived") {
            reactivate = null;
        }

        let expired = (
            <MenuItem key="3" onClick={(event) => this.handlePatientOptions(event, "Archive - expired")}>
                Archive - expired
            </MenuItem>
        );
        if (this.state.buffer["PatientState"] === "Archived") {
            expired = null;
        }

        let transplant = (
            <MenuItem key="4" onClick={(event) => this.handlePatientOptions(event, "Archive - transplant")}>
                Archive - transplant
            </MenuItem>
        );
        if (this.state.buffer["PatientState"] === "Archived") {
            transplant = null;
        }

        let patientDetailSubmenu = (
            <>
                {deactivate}
                {reactivate}
                {expired}
                {transplant}
            </>
        );

        let editTurbidityMenuItem = null;
        if (this.applet.getEnterpriseComponent().getAttributeValue("PrimaryDoctorUSID") === this.props.appContext.sessionContext.userUSID) {
            editTurbidityMenuItem = (
                <MenuItem key="2" onClick={(event) => this.handlePatientOptions(event, "Edit Turbidity Notification Settings")}>
                    Edit Turbidity Notifications
                </MenuItem>
            );
        }
        let ellipsisMenu = (
            <Menu id="usermenu" anchorEl={this.state.patientMenu} keepMounted open={this.state.patientMenu !== null} onClose={this.handlePatientMenuClose} elevation={2}>
                <NestedMenuItem label="Update patient status" parentMenuOpen={this.state.patientMenu !== null}>
                    {patientDetailSubmenu}
                </NestedMenuItem>
                {editTurbidityMenuItem}
            </Menu>
        );
        if (this.state.buffer["PatientState"] !== "Archived" && this.state.buffer["PatientState"] !== "Inactive" && this.state.buffer["PatientState"] !== "Healthy") {
            if (this.state.patientMenu !== null) {
                ellipsisMenu = (
                    <ClickAwayListener onClickAway={this.handlePatientMenuClose}>
                        <Popper id={1} open={true} placement="right-start" anchorEl={this.state.patientMenu}>
                            <Alert severity="info">
                                Please update patient status in the open Infection Episode
                            </Alert>
                        </Popper>
                    </ClickAwayListener>
                );
            } else {
                ellipsisMenu = null;
            }
        }

        return (
            <span>
                <IconButton aria-label="Options" size="small" onClick={this.handlePatientMenuClick}>
                    <MoreVert />
                </IconButton>
                {ellipsisMenu}
            </span>
        );
    }
}

export default withAppContext(withStyles(styles, {withTheme: true})(PatientDetailMenu));