import RelationshipMetadata from "../RelationshipMetadata";

class EscalationTreatmentRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "EscalationTreatment";
        this.parentEnterpriseComponent = "Escalation";
        this.childEnterpriseComponent = "EscalationTreatment";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "EscalationUSID";
    }
}

export default EscalationTreatmentRelationshipMetadata;