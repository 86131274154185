import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";
import CCDatePicker from "./common/CCDatePicker";

class PatientExpiration extends Component {
    state = {
        updateBackgroundOperation: false,
        Note: "",
        DateOfExpiration: "",
        DateOfExpirationError: false,
        DateOfExpirationHelper: null,

        CatheterRemoved: false,
        CatheterReplaced: false,
        showConfirmation: false,
        UnassignDevice: false,
        lengthWarningText: null,
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;
    };

    componentDidMount() {
        this.applet.setMode("Edit");

        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.state.DateOfExpiration === "" || this.state.DateOfExpiration === null) {
            this.setState({DateOfExpirationHelper: "Field must contain a value.", DateOfExpirationError: true});
        } else if (this.state.DateOfExpirationError === true) {
            this.setState({DateOfExpirationHelper: "Date improperly formatted."});
        } else {
            if (this.state.UnassignDevice === true) {
                this.applet.getEnterpriseComponent().setAttributeValue("DeviceUSID", null);
                this.applet.getEnterpriseComponent().setAttributeValue("DeviceSerialNumber", null);
                this.applet.getEnterpriseComponent().setAttributeValue("DeviceAssignmentStatus", null);
                this.applet.getEnterpriseComponent().setAttributeValue("DeviceAssignmentDatetime", null);
            }

            this.applet.getEnterpriseComponent().setAttributeValue("PatientState", "Archived");
            this.applet.getEnterpriseComponent().setAttributeValue("PatientEscalationStatus", "Patient has expired");
            this.applet.save();
        }
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = () => {
        this.setState({updateBackgroundOperation: false});

        this.applet.setMode("View");

        this.applet.getView().initiateAction(null);
    };

    getName = () => {
        return "PatientExpiration";
    };

    handleCathetherRemoved = (event) => {
        this.setState({CatheterRemoved: event.target.value === "true"});
    };

    handleCathetherReplaced = (event) => {
        this.setState({CatheterReplaced: event.target.value === "true"});
    };

    handleUnassignDevice = (event) => {
        this.setState({UnassignDevice: event.target.value === "true"});
    };

    inputChangedHandler = (event, formElementId) => {
        if (formElementId === "DateOfExpiration") {
            let isMatch = true;

            if (event?.length > 0) {
                isMatch = !!event.match("^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$");
            }

            if (!event) {
                this.setState({DateOfExpiration: event, DateOfExpirationHelper: "Field must contain a value.", DateOfExpirationError: true});
            } else if (isMatch === false) {
                this.setState({DateOfExpiration: event, DateOfExpirationHelper: "Date improperly formatted.", DateOfExpirationError: true});
            } else {
                this.setState({DateOfExpiration: event, DateOfExpirationHelper: null, DateOfExpirationError: false});
            }
        } else if (formElementId === "Note") {
            let lengthWarningText = null;
            if (event.target.value.length === 1000) {
                lengthWarningText = "1000 characters allowed";
            }

            this.setState({Note: event.target.value, lengthWarningText: lengthWarningText});
        }
    };

    cancel1Handler = (event) => {
        event.preventDefault();
        this.setState({showConfirmation: true});
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    render() {
        let assistiveText = null;
        if (this.state.lengthWarningText !== null) {
            assistiveText = <Typography className={this.props.classes.assistiveText}>{this.state.lengthWarningText}</Typography>;
        }

        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form>
                    <Grid key="id6" item container spacing={2}>
                        <Grid key={1} item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={this.props.classes.addEpisodeText}>By answering and clicking the button below, you are acknowledging that the patient has expired.</Typography>
                            <br />
                            <Typography className={this.props.classes.addEpisodeText}>Upon save, this patient will move to the <b>Archived</b> section.</Typography>
                        </Grid>
                        <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CCDatePicker
                                required={true}
                                helperText={this.state.DateOfExpirationHelper}
                                error={this.state.DateOfExpirationError}
                                key="DateOfExpiration"
                                label="Date of expiration (MM/DD/20YY)"
                                id="DateOfExpiration"
                                onChange={(event) => this.inputChangedHandler(event, "DateOfExpiration")}
                                value={this.state.DateOfExpiration}
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        <Grid key={3} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl component="fieldset" margin="dense">
                                <FormLabel component="legend">Was the catheter removed?</FormLabel>
                                <RadioGroup aria-label="acknowledge1" name="ack1" value={this.state.CatheterRemoved} onChange={this.handleCathetherRemoved}>
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid key={4} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl component="fieldset" margin="dense">
                                <FormLabel component="legend">Was the catheter replaced?</FormLabel>
                                <RadioGroup aria-label="acknowledge2" name="ack1" value={this.state.CatheterReplaced} onChange={this.handleCathetherReplaced}>
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {this.applet.getEnterpriseComponent().getAttributeValue("DeviceUSID") !== null ? (
                            <Grid key={5} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                                <FormControl component="fieldset" margin="dense" required={true}>
                                    <FormLabel component="legend">Would you like to un-assign the CloudCath device from the patient?</FormLabel>
                                    <RadioGroup aria-label="unassignDevice" name="ack1" value={this.state.UnassignDevice} onChange={this.handleUnassignDevice}>
                                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={false} control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>) : null}
                        <Grid key={6} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                key="Note"
                                margin="dense"
                                InputLabelProps={{shrink: true}}
                                fullWidth={true}
                                id="Note"
                                label="Notes"
                                onChange={(event) => this.inputChangedHandler(event, "Note")}
                                value={this.state.Note}
                                variant="outlined"
                                multiline={true}
                                rows={5}
                                inputProps={{maxLength: 1000}}
                            />
                            {assistiveText}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        );

        let content = (
            <React.Fragment>
                {modalContent}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Dialog onClose={this.cancel1Handler} open={true} scroll="body">
                    <DialogTitle>Patient has expired</DialogTitle>
                    <DialogContent>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        <CCButton
                            variant="green"
                            animate={this.state.updateBackgroundOperation === true}
                            onClick={this.submitHandler}>
                            Save
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(PatientExpiration),
);