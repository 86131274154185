import RelationshipMetadata from "../RelationshipMetadata";

class EscalationEventRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "EscalationEvent";
        this.parentEnterpriseComponent = "Escalation";
        this.childEnterpriseComponent = "EscalationEvent";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "EscalationUSID";
    }
}

export default EscalationEventRelationshipMetadata;