import RelationshipMetadata from "../RelationshipMetadata";

class EscalationSymptomAttachmentRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "EscalationSymptomAttachment";
        this.parentEnterpriseComponent = "EscalationSymptom";
        this.childEnterpriseComponent = "SymptomAttachment";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "ParentUSID";
        this.invalidateChildren = false;
    }
}

export default EscalationSymptomAttachmentRelationshipMetadata;