import eoecClassFactory from "../EOECClassFactory";

class UniqueValidator {
    constructor() {
        this.picklistEC = null;
        this.listener = null;
        this.field = null;
        this.formElementId = null;
    }

    enterpriseComponentLoadCompleted = (buffer) => {
        this.listener.validatorFetchCompleted(this.formElementId, buffer);
    };

    validate = (detail, value, value2, field, formElementId, attribute) => {
        let picklist = attribute.getPicklist();

        if (picklist !== null && picklist.getStatic() === false) {
            this.field = field;
            this.listener = detail;
            this.formElementId = formElementId;
            this.picklistEC = eoecClassFactory("ec", picklist.getEnterpriseComponent());

            this.listener.validatorFetchInitiated(this.formElementId, value);
            this.picklistEC.addLoadCompletedListener(this);
            this.picklistEC.newQuery();
            this.picklistEC.setSearchSpecification(picklist.getSearchSpecification());

            if (attribute.getOutboundPickMaps().length > 0) {
                for (let i = 0; i < attribute.getPickMaps().length; i++) {
                    let pickMap = attribute.getPickMaps()[i];
                    value = attribute.getEnterpriseComponent().getAttributeValue(pickMap.getAttribute());
                    this.picklistEC.setAttributeValue(pickMap.getPickListAttribute(), value);
                }
            } else {
                this.picklistEC.setAttributeValue(picklist.getTypeValue(), value);
            }
            this.picklistEC.executeQuery();
        }
    };
}

export default UniqueValidator;