import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1PatientEscalationSymptomIDOCToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class EscalationSymptomEnterpriseComponent extends EnterpriseComponent {
    performSingleRecordRefresh = (primaryKey) => {
        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_escalation_symptoms",
            json: true,
            params: {
                "USID": primaryKey,
            },
            headers: {
                "idoc-request": "v1.PatientEscalationSymptom",
            },
        };
        this.notifySingleRecordRefreshStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.refreshEnterpriseComponentSuccess(V1PatientEscalationSymptomIDOCToArray(response.data));
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    performQuery = (start, pageSize, foreignKey) => {
        let params = {};
        if (foreignKey === null) {
            params = {
                "Limit": pageSize,
                "Offset": start,
            };
        } else {
            params = {
                "Limit": pageSize,
                "Offset": start,
                "PatientEscalationUSID": foreignKey,
            };
        }

        params["Sort"] = this.getFormattedSortSpecification();

        let keys = Object.keys(this.getSearchSpecification());
        for (let i = 0; i < keys.length; i++) {
            params[keys[i]] = this.getSearchSpecification()[keys[i]];
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_escalation_symptoms",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.PatientEscalationSymptom",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1PatientEscalationSymptomIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let symptom = {};

        let row = this.getBuffer()[this.getRecordPointer()];

        let patientEscalationEnterpriseComponent = this.getEnterpriseObject().getEnterpriseComponent("Escalation");

        let moment = require("moment");

        symptom["USID"] = row["USID"];
        symptom["PatientEscalationUSID"] = patientEscalationEnterpriseComponent.getBuffer()[patientEscalationEnterpriseComponent.getRecordPointer()]["USID"];
        symptom["ObservationDate"] = row["ObservationDate"];
        this.setAttributeValue("ObservationDateDisplay", moment(symptom.ObservationDate).format("MMM DD, YYYY"));
        symptom["AbdominalPain"] = row["AbdominalPain"];
        symptom["Fever"] = row["Fever"];
        symptom["Nausea"] = row["Nausea"];
        symptom["CloudyFluid"] = row["CloudyFluid"];
        symptom["Vomiting"] = row["Vomiting"];
        symptom["Other1"] = row["Other1"];
        symptom["Other2"] = row["Other2"];
        symptom["Other3"] = row["Other3"];
        symptom["Other4"] = row["Other4"];
        symptom["Other5"] = row["Other5"];
        symptom["Note"] = row["Note"];
        symptom["EnteredByUSID"] = row["EnteredByUSID"];

        let attachment_ec = this.getEnterpriseObject().getEnterpriseComponent("SymptomAttachment");
        if (attachment_ec.getBuffer().length > 0) {
            symptom["ListOfAttachment"] = {};
            symptom["ListOfAttachment"]["Attachment"] = [];

            for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
                if (attachment_ec.getBuffer()[i]["ParentUSID"] === this.getAttributeValue("USID") && attachment_ec.getBuffer()[i]["__operation"] === "new") {
                    let attachment = {
                        "USID": attachment_ec.getBuffer()[i]["USID"],
                        "ParentUSID": attachment_ec.getBuffer()[i]["ParentUSID"],
                        "ParentType": attachment_ec.getBuffer()[i]["ParentType"],
                        "FileName": attachment_ec.getBuffer()[i]["FileName"],
                        "FileType": attachment_ec.getBuffer()[i]["FileType"],
                        "FileSize": attachment_ec.getBuffer()[i]["FileSize"],
                        "Payload": attachment_ec.getBuffer()[i]["Payload"],
                    };
                    symptom["ListOfAttachment"]["Attachment"].push(attachment);
                }
            }
        }

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientEscalationSymptom";
        payload.CloudcathMessage.PatientEscalationSymptom = symptom;

        return payload;
    };

    performInsert = () => {
        let payload = this.enterpriseComponentPostIDOC();

        let options = {
            method: "POST",
            url: "cloudcath/v1/patient_escalation_symptoms",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientEscalationSymptom",
                "idoc-response": "v1.PatientEscalationSymptom",
            },
        };
        this.notifyInsertStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1PatientEscalationSymptomIDOCToArray(response.data));
                this.getEnterpriseObject().getEnterpriseComponent("Escalation").performSingleRecordRefresh();
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };
}

export default EscalationSymptomEnterpriseComponent;