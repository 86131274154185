import * as validators from "../../../util/Validators";
import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class UserEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "User";
        this.defaultCacheSize = 150;

        this.attributes["ClinicUSID"] = new EnterpriseComponentAttributeMetadata(
            "ClinicUSID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,   // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicIdentifier"] = new EnterpriseComponentAttributeMetadata(
            "ClinicIdentifier", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            8,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Username"] = new EnterpriseComponentAttributeMetadata(
            "Username", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            "Username",  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            8,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["FirstName"] = new EnterpriseComponentAttributeMetadata(
            "FirstName", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["LastName"] = new EnterpriseComponentAttributeMetadata(
            "LastName", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Salutation"] = new EnterpriseComponentAttributeMetadata(
            "Salutation", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            "Salutation",  // Picklist
            null,  // Scale
            null,  // Precision
            10,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Suffix"] = new EnterpriseComponentAttributeMetadata(
            "Suffix", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["UserStatus"] = new EnterpriseComponentAttributeMetadata(
            "UserStatus", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            "UserStatus",  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Password"] = new EnterpriseComponentAttributeMetadata(
            "Password", // name
            false, // NoCopy
            false, // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,   // length
            8,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ConfirmPassword"] = new EnterpriseComponentAttributeMetadata(
            "ConfirmPassword", // name
            false, // NoCopy
            false, // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,   // length
            8,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["MainEmail"] = new EnterpriseComponentAttributeMetadata(
            "MainEmail", // name
            false, // NoCopy
            false, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            "MainEmail",  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            validators.EmailRegex, // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["MainEmailVerifiedState"] = new EnterpriseComponentAttributeMetadata(
            "MainEmailVerifiedState", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            20,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["MainEmailVerifiedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "MainEmailVerifiedDatetime", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["MainPhone"] = new EnterpriseComponentAttributeMetadata(
            "MainPhone", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            "MainPhone",  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            validators.PhoneRegex, // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["MainPhoneVerifiedState"] = new EnterpriseComponentAttributeMetadata(
            "MainPhoneVerifiedState", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            20,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["MainPhoneVerifiedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "MainPhoneVerifiedDatetime", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AdditionalEmail"] = new EnterpriseComponentAttributeMetadata(
            "AdditionalEmail", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            "AdditionalEmail",  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            validators.EmailRegex, // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AdditionalEmailVerifiedState"] = new EnterpriseComponentAttributeMetadata(
            "AdditionalEmailVerifiedState", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            20,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AdditionalEmailVerifiedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "AdditionalEmailVerifiedDatetime", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AdditionalPhone"] = new EnterpriseComponentAttributeMetadata(
            "AdditionalPhone", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            "AdditionalPhone",  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            validators.PhoneRegex, // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AdditionalPhoneVerifiedState"] = new EnterpriseComponentAttributeMetadata(
            "AdditionalPhoneVerifiedState", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            20,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AdditionalPhoneVerifiedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "AdditionalPhoneVerifiedDatetime", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["OptionalCharacteristics"] = new EnterpriseComponentAttributeMetadata(
            "OptionalCharacteristics", // name
            false, // NoCopy
            false, // NoUpdate
            false, // Required
            false, // RequiredForQuery
            "OptionalCharacteristics",  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null, // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,   // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["UserRole"] = new EnterpriseComponentAttributeMetadata(
            "UserRole", // name
            false, // NoCopy
            true, // NoUpdate
            true, // Required
            false, // RequiredForQuery
            "UserRole",  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
    }
}

export default UserEnterpriseComponentMetadata;