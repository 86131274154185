import UserEnterpriseComponentMetadata from "./UserEnterpriseComponentMetadata";

class SecondaryNurseEnterpriseComponentMetadata extends UserEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "SecondaryNurse";
        this.defaultCacheSize = 1000;
    }
}

export default SecondaryNurseEnterpriseComponentMetadata;