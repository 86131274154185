import {Container, Grid, Typography, withStyles} from "@material-ui/core";
import {Refresh} from "@material-ui/icons";
import React from "react";
import {withAppContext} from "../../../App";
import CCButton from "../../../components/common/CCButton";
import styles from "../../../theme/styles";
import DeviceListReturnInitiatedApplet from "../../applet/impl/DeviceListReturnInitiatedApplet";
import DeviceListStandardApplet from "../../applet/impl/DeviceListStandardApplet";
import eoecClassFactory from "../../EOECClassFactory";

import View from "../View";

class DeviceView extends View {
    state = {
        perspectives: {
            default: {},
        },
        activePerspective: "default",
        viewActionInProgress: null,
        orientation: "other",
        refreshObjectEnabled: false,
    };

    constructor(props) {
        super(props);
        this.state.enterpriseObjectNRI = eoecClassFactory("eo", "DeviceNRI");
        this.state.enterpriseObjectRI = eoecClassFactory("eo", "DeviceRI");

        let deviceNRIenterpriseComponent = this.state.enterpriseObjectNRI.getEnterpriseComponent("Device");
        deviceNRIenterpriseComponent.addInvalidListener(this);
        deviceNRIenterpriseComponent.addLoadCompletedListener(this);
    };

    enterpriseComponentInvalidated = () => {
        this.setState({refreshObjectEnabled: false});
    };

    enterpriseComponentLoadCompleted = () => {
        this.setState({refreshObjectEnabled: true});
    };

    getName = () => {
        return "DeviceView";
    };

    initiateAction = (action) => {
        if (action === "RefreshDevice") {
            this.state.enterpriseObjectNRI.getEnterpriseComponent("Device").invalidate();
            this.state.enterpriseObjectRI.getEnterpriseComponent("Device").invalidate();
        }
        this.setState({showAction: action});
    };

    renderHeader = () => {
        return (
            <Grid container direction="column" style={{margin: "16px 0 33px 0"}}>
                <Grid container item direction="row" justifyContent="space-between">
                    <Typography variant="h1" style={{marginTop: "15px"}}>{this.props.appContext.sessionContext.providerName}/{this.props.appContext.sessionContext.clinicName}</Typography>
                    <div>
                        <CCButton
                            variant="secondary"
                            style={{marginTop: "15px", marginRight: "0", width: "170px"}}
                            startIcon={<Refresh />}
                            onClick={() => this.initiateAction("RefreshDevice")}
                            disabled={!this.state.refreshObjectEnabled}>
                            Refresh
                        </CCButton>
                    </div>
                </Grid>
            </Grid>
        );
    };

    renderDefaultPerspective = () => {
        if (this.state.enterpriseObject !== null) {
            return [
                <div key={1} style={{backgroundColor: "#2CA4DF", opacity: 0.3, height: "3px"}} />,
                <DeviceListStandardApplet key={2} enterpriseComponent={this.state.enterpriseObjectNRI.getEnterpriseComponent("Device")} view={this} />,
                <div key={3} style={{backgroundColor: "#2CA4DF", opacity: 0.3, height: "3px"}} />,
                <DeviceListReturnInitiatedApplet key={4} enterpriseComponent={this.state.enterpriseObjectRI.getEnterpriseComponent("Device")} view={this} />,
            ];
        }
        return [];
    };

    render() {
        let content = null;

        if (this.getActivePerspective() === "default") {
            content = (
                <React.Fragment>
                    {this.renderHeader()}
                    <Grid container direction="column" spacing={10}>
                        {this.renderDefaultPerspective()}
                    </Grid>
                </React.Fragment>
            );
        }

        return (
            <Container className={this.props.classes.containerMargin} maxWidth="lg">
                {content}
            </Container>
        );
    }
}

export default withAppContext(withStyles(styles, {withTheme: true})(DeviceView));