import {IconButton, InputAdornment, Link, Typography} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import axios from "axios";
import React, {useEffect, useState} from "react";
import * as Analytics from "../../util/Analytics";
import {sleep} from "../../util/Sleep";
import * as validators from "../../util/Validators";
import CCButton from "../common/CCButton";
import CCInputLabel from "../common/CCInputLabel";
import CCTextField from "../common/CCTextField";
import css_self from "./css/LoginChangePass.module.scss";
import css_shared from "./css/LoginCommon.module.scss";
import "./css/LoginMain.scss";
import {useLoginContext} from "./Login";

export default function LoginChangePass(props) {

    const [submitting, setSubmitting] = useState(false);
    const [errorText, setErrorText] = useState(undefined);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const {
        preSessionState,
        updatePreSessionState,
        abortLogin,
        login,
    } = useLoginContext();

    useEffect(() => {
        Analytics.record("Initial Password Change Viewed");
    }, []);

    useEffect(() => {
        updatePreSessionState(state => {
            return {...state, password: password};
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password]);

    async function submit() {

        const startAt = Date.now();

        const waitVisualMin = async () => {
            const waitDiff = 2000 - (Date.now() - startAt);
            waitDiff > 0 && (await sleep(waitDiff));
        };

        Analytics.record("Initial Password Change Submitting");

        setSubmitting(true);

        const response = await axios({
            method: "POST",
            baseURL: process.env.REACT_APP_API_URL,
            url: "cloudcath/v1/authenticate_change_password",
            json: true,
            headers: {
                "X-Cloudcath-AccessToken": preSessionState.accessToken,
            },
            data: {
                Password: password,
            },
        }).catch(e => e.response || e);

        if (response instanceof Error && response.message === "Network Error") {
            setSubmitting(false);
            setErrorText("Connection not available");
            return;
        }

        await waitVisualMin();

        if (response.status !== 204) {

            setSubmitting(false);

            setErrorText("Service temporarily unavailable");

            Analytics.record("Initial Password Change Failed", {
                Reason: "Unknown",
                "Reason Code": response.data.Code,
                "HTTP Code": response.status,
            });

            return;
        }

        Analytics.record("Initial Password Changed");

        await login();
    }

    function validate() {

        setErrorText(undefined);

        if (!validators.Required(password)) {
            setErrorText("Password must contain a value.");
        } else if (!validators.Minimum(password, 8)) {
            setErrorText("Password must have at least 8 characters.");
        } else if (!validators.NoSpaces(password)) {
            setErrorText("Password must have no spaces.");
        } else if (!validators.MatchValues(password, passwordConfirm)) {
            setErrorText("Passwords must match.");
        } else {
            return true;
        }

        return false;
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Typography data-test-id="LoginChangePass/text:header" className={css_shared.Title}>Change your initial password</Typography>
            <CCInputLabel>New password</CCInputLabel>
            <CCTextField
                value={password}
                placeholder="Enter new password"
                type={showPassword ? "text" : "password"}
                onChange={async e => setPassword(e.target.value)}
                InputProps={{
                    inputProps: {"data-test-id": "LoginChangePass/input:password"},
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton data-test-id="LoginChangePass/input:password/button:show" onClick={async () => setShowPassword(!showPassword)} onMouseDown={e => e.preventDefault()}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <CCInputLabel>Confirm new password</CCInputLabel>
            <CCTextField
                value={passwordConfirm}
                placeholder="Reenter new password"
                type={showPassword ? "text" : "password"}
                onChange={async e => setPasswordConfirm(e.target.value)}
                InputProps={{
                    inputProps: {"data-test-id": "LoginChangePass/input:passwordConfirm"},
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton data-test-id="LoginChangePass/input:passwordConfirm/button:show" onClick={async () => setShowPassword(!showPassword)} onMouseDown={e => e.preventDefault()}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                helperText={<span data-test-id="LoginChangePass/text:error" className="Mui-error" style={{visibility: errorText ? undefined : "hidden"}}>{errorText}</span>}
            />
            <CCButton
                data-test-id="LoginChangePass/button:continue"
                className={css_self.Button}
                margin="none"
                animate={submitting}
                disabled={submitting}
                onClick={async () => {
                    if (validate()) {
                        submit();
                    }
                }}
            >
                Continue
            </CCButton>
            <Typography className={css_self.LogoutInsteadText}>
                Having trouble?{" "}
                <Link data-test-id="LoginChangePass/link:logout" href="#" onClick={async e => {
                    e.preventDefault();
                    abortLogin();
                }}>Logout</Link>
            </Typography>
        </form>
    );
}
