import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useAppContext} from "../../App";
import * as Analytics from "../../util/Analytics";
import EventBus from "../../util/EventBus";
import Login from "../login/Login";

export default function Logout(props) {

    const {sessionContext} = useAppContext();

    const history = useHistory();

    useEffect(() => {

        if (sessionContext.valid()) {
            Analytics.record("Logged Out");
        }

        sessionStorage.clear();

        EventBus.session.emit("update");

        history.replace("/");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Login />;
}
