import EnterpriseComponentMetadata from "../../metadata/ec/EnterpriseComponentMetadata";

class EnterpriseComponentVisibilityMode extends EnterpriseComponentMetadata {
    constructor(parent, enterpriseComponentVisibilityModeMetadata) {
        super();

        this.fEnterpriseComponent = parent;
        this.name = enterpriseComponentVisibilityModeMetadata.getName();
        this.fVisibilityAttribute = enterpriseComponentVisibilityModeMetadata.getVisibilityAttribute();
    }

    getVisibilityAttribute = () => {
        return this.fVisibilityAttribute;
    };

}

export default EnterpriseComponentVisibilityMode;


