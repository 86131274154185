import * as userAgent from "../util/UserAgent";
import {Breakpoints} from "./breakpoints";

export const overrides = {
    MuiDialogTitle: {
        root: {
            padding: "20px 8px 10px 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& h2": {
                fontSize: "1.125rem",
                fontWeight: 600,
            },
        },
    },
    MuiDialogContent: {
        root: {
            [Breakpoints.up("sm")]: {
                minWidth: !userAgent.isMobile() ? 410 : undefined,
            },
            padding: "8px 16px",
            "& form": {
                width: "100%",
            },
        },
    },
    MuiDialog: {
        paper: {
            [Breakpoints.up("sm")]: {
                marginLeft: 60,
                marginRight: 60,
                minWidth: !userAgent.isMobile() ? 600 : undefined,
                maxWidth: "calc(100% - 120px)",
            },
        },
    },
    MuiInputLabel: {
        outlined: {
            backgroundColor: "#FFFFFF",
            paddingRight: 4,
            width: "max-content",
            letterSpacing: "-0.6px",
        },
        asterisk: {
            fontWeight: "bold",
        },
    },
    MuiInputBase: {
        root: {
            "& legend": {
                maxWidth: 0,
            },

        },
    },
    MuiFormGroup: {
        root: {
            marginTop: 12,
        },
    },
    MuiRadio: {
        root: {
            padding: 4,
            paddingLeft: 10,
        },
    },
    MuiCheckbox: {
        root: {
            padding: 0,
            paddingLeft: 10,
        },
    },
    MuiMenu: {
        paper: {
            minWidth: userAgent.isMobile() ? "calc(100% - 64px) !important" : undefined,
            width: userAgent.isMobile() ? "calc(100% - 64px) !important" : undefined,
            left: userAgent.isMobile() ? "36px !important" : undefined,
        },
    },
    MuiMenuItem: {
        root: {
            minHeight: "36px !important",
        },
    },
};

export default overrides;