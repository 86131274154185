import PicklistMetadata from "../PicklistMetadata";

class UserStatusPicklistMetadata extends PicklistMetadata {
    constructor() {
        super();

        this.bounded = true;
        this.enterpriseComponent = "PickList Generic";
        this.longList = false;
        this.noDelete = true;
        this.noUpdate = true;
        this.noInsert = true;
        this.noMerge = true;
        this.searchSpecification = null;
        this.sortSpecification = null;
        this.static = true;
        this.typeField = "Type";
        this.typeValue = "User Status";
        this.typeValue2 = null;
        this.visibilityType = null;
    }
}

export default UserStatusPicklistMetadata;
