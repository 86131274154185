import {responsiveFontSizes} from "@material-ui/core";
import {createTheme} from "@material-ui/core/styles";
import {Breakpoints} from "./breakpoints";
import overrides from "./overrides";
import props from "./props";
import {Blue3, Blue4, Grey1b} from "./styles";
import typography from "./typography";

export function createDefaultTheme() {

    let theme = createTheme({
        spacing: 4,
        breakpoints: Breakpoints.values,
        overrides: overrides,
        props: props,
        typography: typography,
        palette: {
            type: "light",
            primary: {
                main: Blue4,
                contrastText: "#FFFFFF",
            },
            secondary: {
                main: Blue3,
                contrastText: "#FFFFFF",
            },
            background: {
                default: Grey1b,
                paper: "#FFFFFF",
            },
        },
    });

    theme = responsiveFontSizes(theme);

    return theme;
}