import CloudcathTable from "../../../../src/components/CloudcathTable";
import {withAppContext} from "../../../App";
import Applet from "../Applet";

class PatientEscalationNoteListApplet extends Applet {

    createNote = (type) => {
        this.getView().initiateAction(type);
    };

    selectNoteHandler = (index) => {
        this.selectHandler(index);

        this.getView().initiateAction("ViewNote");
    };

    render() {
        return <CloudcathTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Notes"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               headerRenderer={this.state.HeaderRenderer}
                               selectors={this.state.Selectors}
                               tableHeaderType="Type2"
                               showColumnHeader="false"
                               sort={this.state.Sort} />;
    }

    state = {
        Name: "PatientEscalationNoteListApplet",
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        HeaderRenderer: "Note Header Renderer",
        Selectors: {
            "AddNote": this.createNote,
            "Refresh": this.refreshHandler,
            "Select": this.selectNoteHandler,
        },
        Placement: {
            "xl": [
                {
                    id: "Note",
                },
                {
                    id: "Attachment",
                },
            ],
        },
        Columns: {
            "Note": {
                id: "Note",
                width: "300px",
                cellRenderer: "Note Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Note",
                align: "left",
            },
            "Attachment": {
                id: "Attachment",
                width: "100px",
                cellRenderer: "Attachment Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Attachment(s)",
                align: "center",
            },
        },
        Sort: [
            {
                "identifier": 1,
                "default": true,
                "visible": false,
                "options": {
                    "RowIdentifier": "DESC",
                },
            },
        ],
    };

}

export default withAppContext(PatientEscalationNoteListApplet);
