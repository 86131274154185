import EnterpriseObjectMemberMetadata from "../../../metadata/eo/EnterpriseObjectMemberMetadata";
import EnterpriseObjectMetadata from "../../../metadata/eo/EnterpriseObjectMetadata";

class DeviceNRIEnterpriseObjectMetadata extends EnterpriseObjectMetadata {
    constructor() {
        super();

        this.name = "DeviceNRI";
        this.primaryEnterpriseComponent = "Device";

        let eom1 = new EnterpriseObjectMemberMetadata();
        eom1.name = "Device";
        eom1.enterpriseComponent = "DeviceNRI";
        eom1.relationship = null;
        this.enterpriseComponentMembers[eom1.name] = eom1;
    }
}

export default DeviceNRIEnterpriseObjectMetadata;