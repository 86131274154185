import React from "react";
import {withAppContext} from "../../../App";
import PatientEscalationStatus from "../../../components/PatientEscalationStatus";

import Applet from "../Applet";

class PatientEscalationStatusApplet extends Applet {
    state = {
        Name: "PatientEscalationStatusApplet",
    };

    render() {
        return <PatientEscalationStatus applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientEscalationStatusApplet);
