import React from "react";
import {withAppContext} from "../../../App";
import PatientNotificationA from "../../../components/PatientNotificationA";

import Applet from "../Applet";

class PatientNotificationAppletA extends Applet {
    state = {
        Name: "PatientNotificationApplet",
    };

    render() {
        return <PatientNotificationA applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientNotificationAppletA);
