import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class EscalationTreatmentEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "EscalationTreatment";
        this.defaultCacheSize = 50;

        this.attributes["EscalationUSID"] = new EnterpriseComponentAttributeMetadata(
            "EscalationUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Note"] = new EnterpriseComponentAttributeMetadata(
            "Note", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1000,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AssociatedNote"] = new EnterpriseComponentAttributeMetadata(
            "AssociatedNote", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1000,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Medication"] = new EnterpriseComponentAttributeMetadata(
            "Medication", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            50,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Dose"] = new EnterpriseComponentAttributeMetadata(
            "Dose", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Unit"] = new EnterpriseComponentAttributeMetadata(
            "Unit", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            "Unit",  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Formulation"] = new EnterpriseComponentAttributeMetadata(
            "Formulation", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            "Formulation",  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Frequency"] = new EnterpriseComponentAttributeMetadata(
            "Frequency", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            "Frequency",  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["IsSelfAdministered"] = new EnterpriseComponentAttributeMetadata(
            "IsSelfAdministered", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Route"] = new EnterpriseComponentAttributeMetadata(
            "Route", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            "Route",  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["IsSelfAdministered"] = new EnterpriseComponentAttributeMetadata(
            "IsSelfAdministered", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnteredByUSID"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByUSID", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "AllActiveUsersOptional",  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnteredByFirstName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByFirstName", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["EnteredByLastName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByLastName", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["EnteredByFullName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByFullName", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            200,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["StartDate"] = new EnterpriseComponentAttributeMetadata(
            "StartDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            "^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$",  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["StartDateDisplay"] = new EnterpriseComponentAttributeMetadata(
            "StartDateDisplay", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["EndDate"] = new EnterpriseComponentAttributeMetadata(
            "EndDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            "^[2]\\d{3}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$",  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EndDateDisplay"] = new EnterpriseComponentAttributeMetadata(
            "EndDateDisplay", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
    }
}

export default EscalationTreatmentEnterpriseComponentMetadata;