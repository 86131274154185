import MomentUtils from "@date-io/moment";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import "./css/CCTextField.scss";

export default function CCDateTimePicker({
    fullWidth, // true | false
    margin, // none | dense | normal
    variant, // standard | outlined | filled
    format,
    minDate,
    onChange,
    value,
    children,
    ...props
}) {

    margin = margin !== undefined ? margin : props.label ? "dense" : "none";
    format = format !== undefined ? format : "MM/DD/YYYY HH:mm";

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
                {...{"data-cc-component": "CCDateTimePicker"}}
                format={format}
                minDate={minDate}
                margin={margin}
                disableToolbar
                autoOk
                ampm={false}
                variant="inline"
                fullWidth={fullWidth !== undefined ? fullWidth : true}
                onChange={onChange ? (date) => {
                    if (date) {
                        date = date.format(format); // date will be correct time, but wrong timezone (utc instead of local)
                        date = moment(date, format); // convert the parsed time above minus utc back into a moment to force local
                        date = date.utc().format(); // convert the corrected moment above into utc string (iso 8601)
                    }
                    onChange(date);
                } : undefined}
                value={value}
                inputVariant={variant !== undefined ? variant : "outlined"}
                {...props}
            >
                {children}
            </KeyboardDateTimePicker>
        </MuiPickersUtilsProvider>
    );
}
