import {Grid, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import spinner from "../assets/Spinner-1s-200px.svg";
import styles from "../theme/styles";

class AssignedNurse extends Component {
    state = {
        fetchBackgroundOperation: true,
        buffer: {},
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;
    }

    componentDidMount() {
        this.applet.getEnterpriseComponent().addValueChangedListener(this);
        this.applet.getEnterpriseComponent().addLoadStartedListener(this);
        this.applet.getEnterpriseComponent().addLoadCompletedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);

        this.enterpriseComponentLoadCompleted(this.applet.getEnterpriseComponent().getBuffer());
    }

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    }

    enterpriseComponentValueChanged = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    enterpriseComponentLoadStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    getName = () => {
        return this.applet.getName();
    };

    render() {
        return this.state.fetchBackgroundOperation === false && this.applet.getEnterpriseComponent().isValid() === true ? (
            <Grid key={this.props.key} item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography className={this.props.classes.patientDetailLabel} style={{marginBottom: 3}}>Assigned Nurse</Typography>
                <Typography className={this.props.classes.patientDetailData}>{this.state.buffer["PrimaryNurseFullName"] ?? "--"}</Typography>
            </Grid>
        ) : (
            <img src={spinner} alt="spinner" width="35" height="35" />
        );
    }
}

export default withAppContext(withStyles(styles, {withTheme: true})(AssignedNurse));