import AbstractMetadata from "../AbstractMetadata";

class EnterpriseObjectMetadata extends AbstractMetadata {
    constructor() {
        super();

        this.name = null;
        this.primaryEnterpriseComponent = null;
        this.enterpriseComponentMembers = {};
    };

    getName = () => {
        return this.name;
    };

    getPrimaryEnterpriseComponent = () => {
        return this.primaryEnterpriseComponent;
    };

    getEnterpriseComponentMembers = () => {
        return this.enterpriseComponentMembers;
    };

}

export default EnterpriseObjectMetadata;