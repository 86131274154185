import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import Iframe from "react-iframe";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";

class PatientEscalationCommunication extends Component {
    state = {};

    doneHandler = () => {
        this.props.view.initiateAction(null);
    };

    inputChangedHandler = (event) => {
        this.setState({note: event.target.value});
    };

    render() {
        let contextView = (
            <div style={{height: "680px", overflow: "scroll"}}>
                <Iframe url={this.props.enterpriseComponent.getAttributeValue("MessageURL")}
                        width="100%"
                        height="100%"
                        className="myClassname"
                        display="initial"
                        position="relative" />
            </div>
        );
        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form onSubmit={e => e.preventDefault()}>
                    <Grid key="id6" item container style={{width: "45em"}}>
                        <Grid key={1} item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            {contextView}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        );

        let content = (
            <React.Fragment>
                {modalContent}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Dialog onClose={this.cancel1Handler} open={true} scroll="body">
                    <DialogTitle>Communications</DialogTitle>
                    <DialogContent>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        <CCButton
                            onClick={this.doneHandler}>
                            Done
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(PatientEscalationCommunication),
);