import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1PatientEscalationTreatmentIDOCToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class EscalationTreatmentEnterpriseComponent extends EnterpriseComponent {
    performSingleRecordRefresh = (primaryKey) => {
        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_escalation_treatments",
            json: true,
            params: {
                "USID": primaryKey,
            },
            headers: {
                "idoc-request": "v1.PatientEscalationTreatment",
            },
        };
        this.notifySingleRecordRefreshStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.refreshEnterpriseComponentSuccess(V1PatientEscalationTreatmentIDOCToArray(response.data));
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    performQuery = (start, pageSize, foreignKey) => {
        let params = {};
        if (foreignKey === null) {
            params = {
                "Limit": pageSize,
                "Offset": start,
            };
        } else {
            params = {
                "Limit": pageSize,
                "Offset": start,
                "PatientEscalationUSID": foreignKey,
            };
        }

        params["Sort"] = this.getFormattedSortSpecification();

        let keys = Object.keys(this.getSearchSpecification());
        for (let i = 0; i < keys.length; i++) {
            params[keys[i]] = this.getSearchSpecification()[keys[i]];
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_escalation_treatments",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.PatientEscalationTreatment",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1PatientEscalationTreatmentIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientEscalationTreatment";

        let treatment = this.preparePUTPayload();

        let attachment_ec = this.getEnterpriseObject().getEnterpriseComponent("TreatmentAttachment");
        if (attachment_ec.getBuffer().length > 0) {
            treatment["ListOfAttachment"] = {};
            treatment["ListOfAttachment"]["Attachment"] = [];

            for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
                if (attachment_ec.getBuffer()[i]["ParentUSID"] === this.getAttributeValue("USID") && attachment_ec.getBuffer()[i]["__operation"] === "new") {
                    let attachment = {
                        "USID": attachment_ec.getBuffer()[i]["USID"],
                        "ParentUSID": attachment_ec.getBuffer()[i]["ParentUSID"],
                        "ParentType": attachment_ec.getBuffer()[i]["ParentType"],
                        "FileName": attachment_ec.getBuffer()[i]["FileName"],
                        "FileType": attachment_ec.getBuffer()[i]["FileType"],
                        "FileSize": attachment_ec.getBuffer()[i]["FileSize"],
                        "Payload": attachment_ec.getBuffer()[i]["Payload"],
                    };
                    treatment["ListOfAttachment"]["Attachment"].push(attachment);
                }
            }
        }

        payload.CloudcathMessage.PatientEscalationTreatment = treatment;

        return payload;
    };

    performUpdate = () => {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "cloudcath/v1/patient_escalation_treatments",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientEscalationTreatment",
                "idoc-response": "v1.PatientEscalationTreatment",
            },
        };
        this.notifyUpdateStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1PatientEscalationTreatmentIDOCToArray(response.data));
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let treatment = {};

        let row = this.getBuffer()[this.getRecordPointer()];

        let patientEscalationEnterpriseComponent = this.getEnterpriseObject().getEnterpriseComponent("Escalation");

        let moment = require("moment");

        treatment["USID"] = row["USID"];
        treatment["PatientEscalationUSID"] = patientEscalationEnterpriseComponent.getBuffer()[patientEscalationEnterpriseComponent.getRecordPointer()]["USID"];

        treatment["StartDate"] = row["StartDate"];
        this.setAttributeValue("StartDateDisplay", moment(treatment.StartDate).format("MMM DD, YYYY"));

        treatment["EndDate"] = row["EndDate"];
        this.setAttributeValue("EndDateDisplay", null);
        if (row["EndDate"] !== null) {
            this.setAttributeValue("EndDateDisplay", moment(treatment.EndDate).format("MMM DD, YYYY"));
        }

        treatment["Medication"] = row["Medication"];
        treatment["Dose"] = row["Dose"];
        treatment["Unit"] = row["Unit"];
        treatment["Route"] = row["Route"];
        treatment["Frequency"] = row["Frequency"];
        treatment["IsSelfAdministered"] = row["IsSelfAdministered"];
        treatment["Formulation"] = row["Formulation"];
        treatment["Note"] = row["Note"];
        treatment["EnteredByUSID"] = row["EnteredByUSID"];

        let attachment_ec = this.getEnterpriseObject().getEnterpriseComponent("TreatmentAttachment");
        if (attachment_ec.getBuffer().length > 0) {
            treatment["ListOfAttachment"] = {};
            treatment["ListOfAttachment"]["Attachment"] = [];

            for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
                if (attachment_ec.getBuffer()[i]["ParentUSID"] === this.getAttributeValue("USID") && attachment_ec.getBuffer()[i]["__operation"] === "new") {
                    let attachment = {
                        "USID": attachment_ec.getBuffer()[i]["USID"],
                        "ParentUSID": attachment_ec.getBuffer()[i]["ParentUSID"],
                        "ParentType": attachment_ec.getBuffer()[i]["ParentType"],
                        "FileName": attachment_ec.getBuffer()[i]["FileName"],
                        "FileType": attachment_ec.getBuffer()[i]["FileType"],
                        "FileSize": attachment_ec.getBuffer()[i]["FileSize"],
                        "Payload": attachment_ec.getBuffer()[i]["Payload"],
                    };
                    treatment["ListOfAttachment"]["Attachment"].push(attachment);
                }
            }
        }

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientEscalationTreatment";
        payload.CloudcathMessage.PatientEscalationTreatment = treatment;

        return payload;
    };

    performInsert = () => {
        let payload = this.enterpriseComponentPostIDOC();

        let options = {
            method: "POST",
            url: "cloudcath/v1/patient_escalation_treatments",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientEscalationTreatment",
                "idoc-response": "v1.PatientEscalationTreatment",
            },
        };
        this.notifyInsertStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1PatientEscalationTreatmentIDOCToArray(response.data));

                this.getEnterpriseObject().getEnterpriseComponent("EscalationNote").invalidate();
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };
}

export default EscalationTreatmentEnterpriseComponent;