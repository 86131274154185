import {withAppContext} from "../../../App";
import UserListBaseApplet from "./UserListBaseApplet";

class UserListInactiveApplet extends UserListBaseApplet {

    constructor(props) {
        super(props, {
            name: "UserListInactiveApplet",
            title: "Deactivated Users",
            autoUnfold: false,
        });
    }
}

export default withAppContext(UserListInactiveApplet);
