import {Container, Grid, withStyles} from "@material-ui/core";
import React from "react";
import {withAppContext} from "../../../App";
import PatientEscalationCommunication from "../../../components/PatientEscalationCommunication";
import styles from "../../../theme/styles";
import {parseQueryParams} from "../../../util/Location";
import {updateObject} from "../../../util/utility";
import AssignDeviceApplet from "../../applet/impl/AssignDeviceApplet";
import CareTeamApplet from "../../applet/impl/CareTeamApplet";
import CellCountDetailApplet from "../../applet/impl/CellCountLabDetailApplet";
import CultureDetailApplet from "../../applet/impl/CultureLabDetailApplet";
import NoteDetailApplet from "../../applet/impl/NoteDetailApplet";
import BloodworkDetailApplet from "../../applet/impl/OtherLabDetailApplet";
import OtherLabDetailApplet from "../../applet/impl/OtherLabDetailApplet";
import PatientApplet from "../../applet/impl/PatientApplet";
import PatientDeactivateApplet from "../../applet/impl/PatientDeactivateApplet";
import PatientDetailAttachmentApplet from "../../applet/impl/PatientDetailAttachmentApplet";
import PatientDetailCyclerApplet from "../../applet/impl/PatientDetailCyclerApplet";
import PatientDetailFullTurbidityNotificationApplet from "../../applet/impl/PatientDetailFullTurbidityNotificationApplet";
import PatientDetailIdentifierApplet from "../../applet/impl/PatientDetailIdentifierApplet";
import PatientDetailReducedTurbidityNotificationApplet from "../../applet/impl/PatientDetailReducedTurbidityNotificationApplet";
import PatientEscalationAcknowledgeApplet from "../../applet/impl/PatientEscalationAcknowledgeApplet";
import PatientEscalationAddEpisodeApplet from "../../applet/impl/PatientEscalationAddEpisodeApplet";
import PatientEscalationDeactivationApplet from "../../applet/impl/PatientEscalationDeactivationApplet";
import PatientEscalationDischargedApplet from "../../applet/impl/PatientEscalationDischargedApplet";
import PatientEscalationExpirationApplet from "../../applet/impl/PatientEscalationExpirationApplet";
import PatientEscalationHemodialysisApplet from "../../applet/impl/PatientEscalationHemodialysisApplet";
import PatientEscalationHospitalizedApplet from "../../applet/impl/PatientEscalationHospitalizedApplet";
import PatientEscalationInfectionConfirmedApplet from "../../applet/impl/PatientEscalationInfectionConfirmedApplet";
import PatientEscalationNotAnInfectionApplet from "../../applet/impl/PatientEscalationNotAnInfectionApplet";
import PatientEscalationResolveInfectionApplet from "../../applet/impl/PatientEscalationResolveInfectionApplet";
import PatientEscalationTransplantApplet from "../../applet/impl/PatientEscalationTransplantApplet";
import PatientExpirationApplet from "../../applet/impl/PatientExpirationApplet";
import PatientReactivateApplet from "../../applet/impl/PatientReactivateApplet";
import PatientTransplantApplet from "../../applet/impl/PatientTransplantApplet";
import SymptomDetailApplet from "../../applet/impl/SymptomDetailApplet";
import TreatmentDetailApplet from "../../applet/impl/TreatmentDetailApplet";
import UnassignDeviceApplet from "../../applet/impl/UnassignDeviceApplet";
import eoecClassFactory from "../../EOECClassFactory";

import View from "../View";

class PatientDetailView extends View {
    state = {
        showAction: null,
        controls: {},
        perspectives: {
            default: {},
        },
        activePerspective: "default",
        patientUSID: null,
    };

    constructor(props) {
        super(props);
        this.state.enterpriseObject = eoecClassFactory("eo", "Patient");

        let patientEnterpriseComponent = this.state.enterpriseObject.getEnterpriseComponent("Patient");
        patientEnterpriseComponent.addInvalidListener(this);
        patientEnterpriseComponent.addLoadCompletedListener(this);
        patientEnterpriseComponent.addInsertCompletedListener(this);

        const queryParams = parseQueryParams(this.props.location?.search);
        this.state.patientUSID = queryParams.patientUSID;
    };

    componentDidMount() {
        if (this.state.patientUSID) {
            let enterpriseComponent = this.state.enterpriseObject.getEnterpriseComponent("Patient");
            enterpriseComponent.setSearchSpecification({"USID": this.state.patientUSID});
            enterpriseComponent.performQuery();
        } else {
            this.props.history.push({pathname: "/"});
        }
    }

    enterpriseComponentInvalidated = (buffer) => {
        this.setState({createObjectEnabled: false});
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        if (buffer.length === 0) {
            this.props.history.push({pathname: "/"});
            return;
        }
        this.setState({createObjectEnabled: true});
    };

    enterpriseComponentInsertCompleted = (buffer) => {
        let patientS0enterpriseComponent = this.state.enterpriseObject.getEnterpriseComponent("Patient");
        this.setActivePerspective("detail", patientS0enterpriseComponent);
    };

    getName = () => {
        return "PatientDetailView";
    };

    initiateAction = (action) => {
        if (action === "RefreshPatient") {
            this.state.enterpriseObject.getEnterpriseComponent("Patient").invalidate();
        } else {
            this.setState({showAction: action});
        }
    };

    filterHandler = (event, inputIdentifier) => {
        let displayValue = event.target.outerText;

        let updatedFormElement;
        let updatedForm;

        let showInactivePatients = this.state.showInactivePatients;

        if (inputIdentifier === "InactivePatients") {
            if (event.target.value === "1") {
                showInactivePatients = "1";
            } else {
                showInactivePatients = "2";
            }
        }

        updatedFormElement = updateObject(this.state.controls[inputIdentifier], {
            value: event.target.value,
            displayValue: displayValue,
        });
        updatedForm = updateObject(this.state.controls, {
            [inputIdentifier]: updatedFormElement,
        });

        this.setState({controls: updatedForm, showInactivePatients: showInactivePatients});
    };

    render() {
        let content = null;

        if (this.getActivePerspective() === "default") {
            content = (
                <React.Fragment>
                    <Grid container direction="column" spacing={10}>
                        {this.renderDefaultPerspective()}
                    </Grid>
                </React.Fragment>
            );
        }

        return (
            <Container className={this.props.classes.containerMargin} maxWidth="lg">
                {this.renderPatientDeactivate()}
                {this.renderPatientReactivate()}
                {this.renderPatientTransplant()}
                {this.renderEscalationTransplant()}
                {this.renderDischargeHospital()}
                {this.renderResolveInfection()}
                {this.renderDeactivatePatient()}
                {this.renderDeactivateEscalation()}
                {this.renderExpirePatient()}
                {this.renderExpireEscalation()}
                {this.renderInfectionConfirmed()}
                {this.renderHospitalizePatient()}
                {this.renderHemodialysisPatient()}
                {this.renderCommunications()}
                {this.renderAddEpisode()}
                {this.renderNonInfectionDiagnosed()}
                {this.renderEpisodeAcknowledgment()}

                {this.renderCreateNote()}
                {this.renderViewNote()}

                {this.renderCreateSymptom()}
                {this.renderViewSymptom()}

                {this.renderViewTreatment()}
                {this.renderEditTreatment()}
                {this.renderAddTreatment()}

                {this.renderViewCellCountLab()}
                {this.renderViewCultureLab()}
                {this.renderViewOtherLab()}

                {this.renderEditCellCountLab()}
                {this.renderEditCultureLab()}
                {this.renderEditOtherLab()}

                {this.renderAddCellCountLab()}
                {this.renderAddCultureLab()}
                {this.renderAddOtherLab()}

                {this.renderEditPatientIdentifier()}
                {this.renderEditPatientAttachment()}
                {this.renderEditReducedPatientTurbidityNotification()}
                {this.renderEditFullPatientTurbidityNotification()}
                {this.renderEditPatientCareTeam()}
                {this.renderEditCycler()}
                {this.renderAssignDevice()}
                {this.renderUnassignDevice()}
                {content}
            </Container>
        );
    }

    renderEditPatientAttachment = () => {
        if (this.state.showAction === "EditPatientAttachment") {
            return <PatientDetailAttachmentApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} defaultMode="Edit" />;
        }
        return null;
    };

    renderEscalationTransplant = () => {
        if (this.state.showAction === "EscalationTransplant") {
            return <PatientEscalationTransplantApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderPatientReactivate = () => {
        if (this.state.showAction === "PatientReactivate") {
            return <PatientReactivateApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} />;
        }
        return null;
    };

    renderPatientDeactivate = () => {
        if (this.state.showAction === "PatientDeactivate") {
            return <PatientDeactivateApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} />;
        }
        return null;
    };

    renderPatientTransplant = () => {
        if (this.state.showAction === "PatientTransplant") {
            return <PatientTransplantApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} />;
        }
        return null;
    };

    renderDischargeHospital = () => {
        if (this.state.showAction === "DischargeHospital") {
            return <PatientEscalationDischargedApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderResolveInfection = () => {
        if (this.state.showAction === "ResolveInfection") {
            return <PatientEscalationResolveInfectionApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderDeactivatePatient = () => {
        if (this.state.showAction === "PatientDeactivate") {
            return <PatientDeactivateApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} />;
        }
        return null;
    };

    renderDeactivateEscalation = () => {
        if (this.state.showAction === "EscalationDeactivate") {
            return <PatientEscalationDeactivationApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderExpireEscalation = () => {
        if (this.state.showAction === "EscalationExpire") {
            return <PatientEscalationExpirationApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderExpirePatient = () => {
        if (this.state.showAction === "PatientExpire") {
            return <PatientExpirationApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} />;
        }
        return null;
    };

    renderHemodialysisPatient = () => {
        if (this.state.showAction === "HemodialysisPatient") {
            return <PatientEscalationHemodialysisApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderInfectionConfirmed = () => {
        if (this.state.showAction === "InfectionConfirmed") {
            return <PatientEscalationInfectionConfirmedApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderHospitalizePatient = () => {
        if (this.state.showAction === "HospitalizePatient") {
            return <PatientEscalationHospitalizedApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderCommunications = () => {
        if (this.state.showAction === "ViewCommunication") {
            return <PatientEscalationCommunication enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationCommunication")} view={this} />;
        }
        return null;
    };

    renderNonInfectionDiagnosed = () => {
        if (this.state.showAction === "NonInfectionDiagnosed") {
            return <PatientEscalationNotAnInfectionApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderAddEpisode = () => {
        if (this.state.showAction === "AddEpisode") {
            return <PatientEscalationAddEpisodeApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderEpisodeAcknowledgment = () => {
        if (this.state.showAction === "EpisodeAcknowledgment") {
            return <PatientEscalationAcknowledgeApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Escalation")} view={this} />;
        }
        return null;
    };

    renderCreateNote = () => {
        if (this.state.showAction === "AddNote") {
            return <NoteDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationNote")} view={this} availableModes={["New"]} defaultMode="New" />;
        }
        return null;
    };

    renderViewNote = () => {
        if (this.state.showAction === "ViewNote") {
            return <NoteDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationNote")} view={this} availableModes={["View"]} defaultMode="View" />;
        }
        return null;
    };

    renderCreateSymptom = () => {
        if (this.state.showAction === "AddSymptom") {
            return <SymptomDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationSymptom")} view={this} availableModes={["New"]} defaultMode="New" />;
        }
        return null;
    };

    renderViewSymptom = () => {
        if (this.state.showAction === "ViewSymptom") {
            return <SymptomDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationSymptom")} view={this} availableModes={["View"]} defaultMode="View" />;
        }
        return null;
    };

    renderEditTreatment = () => {
        if (this.state.showAction === "EditTreatment") {
            return <TreatmentDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationTreatment")} view={this} availableModes={["Edit"]} defaultMode="Edit" />;
        }
        return null;
    };

    renderViewTreatment = () => {
        if (this.state.showAction === "ViewTreatment") {
            return <TreatmentDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationTreatment")} view={this} availableModes={["View"]} defaultMode="View" />;
        }
        return null;
    };

    renderEditCellCountLab = () => {
        if (this.state.showAction === "EditCellCountLab") {
            return <CellCountDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationLab")} view={this} availableModes={["Edit"]} defaultMode="Edit" />;
        }
        return null;
    };

    renderEditCultureLab = () => {
        if (this.state.showAction === "EditCultureLab") {
            return <CultureDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationLab")} view={this} availableModes={["Edit"]} defaultMode="Edit" />;
        }
        return null;
    };

    renderEditOtherLab = () => {
        if (this.state.showAction === "EditOtherLab") {
            return <BloodworkDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationLab")} view={this} availableModes={["Edit"]} defaultMode="Edit" />;
        }
        return null;
    };

    renderViewCellCountLab = () => {
        if (this.state.showAction === "ViewCellCountLab") {
            return <CellCountDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationLab")} view={this} availableModes={["View"]} defaultMode="View" />;
        }
        return null;
    };

    renderViewCultureLab = () => {
        if (this.state.showAction === "ViewCultureLab") {
            return <CultureDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationLab")} view={this} availableModes={["View"]} defaultMode="View" />;
        }
        return null;
    };

    renderViewOtherLab = () => {
        if (this.state.showAction === "ViewOtherLab") {
            return <BloodworkDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationLab")} view={this} availableModes={["View"]} defaultMode="View" />;
        }
        return null;
    };

    renderAddCellCountLab = () => {
        if (this.state.showAction === "AddCellCountLab") {
            return <CellCountDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationLab")} view={this} availableModes={["New"]} defaultMode="New" />;
        }
        return null;
    };

    renderAddCultureLab = () => {
        if (this.state.showAction === "AddCultureLab") {
            return <CultureDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationLab")} view={this} availableModes={["New"]} defaultMode="New" />;
        }
        return null;
    };

    renderAddOtherLab = () => {
        if (this.state.showAction === "AddOtherLab") {
            return <OtherLabDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationLab")} view={this} availableModes={["New"]} defaultMode="New" />;
        }
        return null;
    };

    renderAddTreatment = () => {
        if (this.state.showAction === "AddTreatment") {
            return <TreatmentDetailApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("EscalationTreatment")} view={this} availableModes={["New"]} defaultMode="New" />;
        }
        return null;
    };

    renderAssignDevice = () => {
        if (this.state.showAction === "AssignDevice") {
            return <AssignDeviceApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} availableModes={["Edit"]} defaultMode="Edit" />;
        }
    };

    renderUnassignDevice = () => {
        if (this.state.showAction === "UnassignDevice") {
            return <UnassignDeviceApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} availableModes={["Edit"]} defaultMode="Edit" />;
        }
    };

    renderEditPatientCareTeam = () => {
        if (this.state.showAction === "Edit care team") {
            return <CareTeamApplet patientEnterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")}
                                   secondaryNurseEnterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("SecondaryNurse")}
                                   secondaryPhysicianEnterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("SecondaryPhysician")}
                                   view={this} />;
        }
    };

    renderEditReducedPatientTurbidityNotification = () => {
        if (this.state.showAction === "Edit turbidity notification reduced") {
            return <PatientDetailReducedTurbidityNotificationApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} availableModes={["Edit"]} defaultMode="Edit" />;
        }
    };

    renderEditFullPatientTurbidityNotification = () => {
        if (this.state.showAction === "Edit turbidity notification full") {
            return <PatientDetailFullTurbidityNotificationApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} availableModes={["Edit"]} defaultMode="Edit" />;
        }
    };

    renderEditPatientIdentifier = () => {
        if (this.state.showAction === "Edit identifier") {
            return <PatientDetailIdentifierApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} availableModes={["Edit"]} defaultMode="Edit" />;
        }
        return null;
    };

    renderEditCycler = () => {
        if (this.state.showAction === "Edit cycler") {
            return <PatientDetailCyclerApplet enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} availableModes={["Edit"]} defaultMode="Edit" />;
        }
        return null;
    };

    renderDefaultPerspective = () => {
        let positions = [];

        positions.push(
            <PatientApplet key={1} enterpriseComponent={this.state.enterpriseObject.getEnterpriseComponent("Patient")} view={this} />,
        );

        return positions;
    };

    setRefresh = (value) => {
        this.refresh = value;
    };
}

export default withAppContext(withStyles(styles, {withTheme: true})(PatientDetailView));