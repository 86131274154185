import {FormControl, Select} from "@material-ui/core";
import React from "react";
import "./css/CCSelect.scss";

export default function CCSelect({
    fullWidth, // true | false
    size, // small | medium
    variant, // standard | outlined | filled
    elevation, // 0-24
    MenuProps,
    children,
    ...props
}) {
    return (
        <FormControl
            {...{"data-cc-component": "CCSelect"}}
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            size={size !== undefined ? size : "small"}
        >
            <Select
                variant={variant !== undefined ? variant : "outlined"}
                MenuProps={{
                    PaperProps: {
                        elevation: elevation !== undefined ? elevation : 2,
                    },
                    ...MenuProps,
                }}
                {...props}
            >
                {children}
            </Select>
        </FormControl>
    );
}
