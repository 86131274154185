import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import {convertToUTC} from "../util/utility";
import CCButton from "./common/CCButton";
import CCDatePicker from "./common/CCDatePicker";

class PatientEscalationAddEpisode extends Component {
    state = {
        SpokenWithPatient: false,
        PatientHospitalized: false,
        ScheduledClinicVisit: false,
        UpdateBackgroundOperation: false,
        Note: "",
        DateOfHospitalization: "",
        DateOfHospitalizationError: false,
        DateOfHospitalizationHelper: null,
        CatheterRemoved: false,
        showConfirmation: false,
        CatheterReplaced: false,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;
    };

    componentDidMount() {
        this.applet.setMode("New");

        this.applet.getEnterpriseComponent().addInsertStartedListener(this);
        this.applet.getEnterpriseComponent().addInsertCompletedListener(this);
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.state.PatientHospitalized === true && this.state.DateOfHospitalization === "") {
            this.setState({DateOfHospitalizationHelper: "Field must contain a value.", DateOfHospitalizationError: true});
        } else if (this.state.DateOfHospitalizationError === true) {
            this.setState({DateOfHospitalizationHelper: "Date improperly formatted."});
        } else {
            let moment = require("moment");

            this.applet.getEnterpriseComponent().setAttributeValue("EscalationNote", this.state.Note);
            this.applet.getEnterpriseComponent().setAttributeValue("EscalationNoteType", "Manual infection episode creation");
            this.applet.getEnterpriseComponent().setAttributeValue("PatientHospitalized", this.state.PatientHospitalized);
            this.applet.getEnterpriseComponent().setAttributeValue("StartDatetime", convertToUTC(moment.now()));
            this.applet.getEnterpriseComponent().setAttributeValue("AcknowledgedDatetime", convertToUTC(moment.now()));
            this.applet.getEnterpriseComponent().setAttributeValue("EscalationOrigin", "Manual");
            this.applet.getEnterpriseComponent().setAttributeValue("TurbidityNotificationLevel", this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Patient").getAttributeValue("TurbidityNotificationLevelA"));

            if (this.state.PatientHospitalized === true) {
                this.applet.getEnterpriseComponent().setAttributeValue("HospitalizationDate", this.state.DateOfHospitalization);
                this.applet.getEnterpriseComponent().setAttributeValue("CatheterRemoved", this.state.CatheterRemoved);
                this.applet.getEnterpriseComponent().setAttributeValue("CatheterReplaced", this.state.CatheterReplaced);
            } else {
                this.applet.getEnterpriseComponent().setAttributeValue("CatheterRemoved", null);
                this.applet.getEnterpriseComponent().setAttributeValue("CatheterReplaced", null);
            }
            this.applet.getEnterpriseComponent().setAttributeValue("SpokenWithPatient", this.state.SpokenWithPatient);
            this.applet.getEnterpriseComponent().setAttributeValue("ScheduledClinicVisit", this.state.ScheduledClinicVisit);

            let patientEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Patient");
            if (this.state.PatientHospitalized === true) {
                patientEC.forceAttributeValue("PatientState", "Under watch");
                patientEC.forceAttributeValue("PatientEscalationStatus", "Hospitalized");

                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Hospitalized");
            } else {
                patientEC.forceAttributeValue("PatientEscalationStatus", "Awaiting infection diagnosis");
                patientEC.forceAttributeValue("PatientState", "Action required");

                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Awaiting infection diagnosis");
            }
            this.applet.save();
        }
    };

    enterpriseComponentInsertStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentInsertCompleted = (updatedRow) => {
        this.setState({updateBackgroundOperation: false});

        this.applet.getView().initiateAction(null);
    };

    getName = () => {
        return "Add Escalation";
    };

    handleSpokenWithPatientChange = (event) => {
        this.setState({SpokenWithPatient: event.target.value === "true"});
    };

    handleScheduledClinicVisit = (event) => {
        this.setState({ScheduledClinicVisit: event.target.value === "true"});
    };

    handlePatientHospitalized = (event) => {
        this.setState({PatientHospitalized: event.target.value === "true"});
    };

    handleCathetherRemoved = (event) => {
        this.setState({CatheterRemoved: event.target.value === "true"});
    };

    handleCathetherReplaced = (event) => {
        this.setState({CatheterReplaced: event.target.value === "true"});
    };

    inputChangedHandler = (event, formElementId) => {
        if (formElementId === "DateOfHospitalization") {
            let isMatch = true;

            if (event?.length > 0) {
                isMatch = !!event.match("^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$");
            }

            if (!event) {
                this.setState({DateOfHospitalization: event, DateOfHospitalizationHelper: "Field must contain a value.", DateOfHospitalizationError: true});
            } else if (isMatch === false) {
                this.setState({DateOfHospitalization: event, DateOfHospitalizationHelper: "Date improperly formatted.", DateOfHospitalizationError: true});
            } else {
                this.setState({DateOfHospitalization: event, DateOfHospitalizationHelper: null, DateOfHospitalizationError: false});
            }
        } else if (formElementId === "Note") {
            this.setState({Note: event.target.value});
        }
    };

    cancel1Handler = (event, reason) => {
        event.preventDefault();

        if (this.state.disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (this.state.disableEscapeKeyDown && reason === "escapeKeyDown") {
            return false;
        }

        this.setState({showConfirmation: true});
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    render() {
        let content = (
            <Grid key="id7" container justifyContent="center">
                <form>
                    <Grid key="id6" item container spacing={2}>
                        <Grid key={1} item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={this.props.classes.addEpisodeText}>By answering and clicking the button below, you are manually adding a new infection episode.</Typography>
                            <br />
                            {process.env.REACT_APP_VARIANT === "cds" ? (
                                <>
                                    <Typography className={this.props.classes.addEpisodeText}>Upon escalation, if applicable, on the next page, please update these 3 items below:</Typography>
                                    <ul>
                                        <Typography component="li" className={this.props.classes.addEpisodeText}>Symptoms that the patient has reported</Typography>
                                        <Typography component="li" className={this.props.classes.addEpisodeText}>Treatments that were administered</Typography>
                                        <Typography component="li" className={this.props.classes.addEpisodeText}>Any lab orders related to the escalation</Typography>
                                    </ul>
                                </>
                            ) : null}
                            <Typography style={{fontSize: "14px", fontWeight: "500", color: "#262837"}}>Patients will be placed in the <b>Actions Required</b> list with an active infection episode.</Typography>
                        </Grid>
                        {process.env.REACT_APP_VARIANT === "cds" ? (
                            <>
                                <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl component="fieldset" margin="dense">
                                        <FormLabel component="legend">Is patient hospitalized?</FormLabel>
                                        <RadioGroup aria-label="acknowledge1" name="ack1" value={this.state.PatientHospitalized} onChange={this.handlePatientHospitalized}>
                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {this.state.PatientHospitalized === true ? (
                                    <>
                                        <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <CCDatePicker
                                                required={true}
                                                helperText={this.state.DateOfHospitalizationHelper}
                                                error={this.state.DateOfHospitalizationError}
                                                key="DateOfHospitalization"
                                                label="Date of hospitalization (MM/DD/20YY)"
                                                id="DateOfHospitalization"
                                                onChange={(event) => this.inputChangedHandler(event, "DateOfHospitalization", "DateOfHospitalization")}
                                                value={this.state.DateOfHospitalization}
                                                InputLabelProps={{shrink: true}}
                                            />
                                        </Grid>
                                        <Grid key={3} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormControl component="fieldset" margin="dense">
                                                <FormLabel component="legend">Was the catheter removed?</FormLabel>
                                                <RadioGroup aria-label="acknowledge1" name="ack1" value={this.state.CatheterRemoved} onChange={this.handleCathetherRemoved}>
                                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid key={4} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormControl component="fieldset" margin="dense">
                                                <FormLabel component="legend">Was the catheter replaced?</FormLabel>
                                                <RadioGroup aria-label="acknowledge2" name="ack1" value={this.state.CatheterReplaced} onChange={this.handleCathetherReplaced}>
                                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </>
                                ) : null}
                                <Grid key={3} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl component="fieldset" margin="dense">
                                        <FormLabel component="legend">Have you spoken with the patient?</FormLabel>
                                        <RadioGroup aria-label="acknowledge2" name="ack1" value={this.state.SpokenWithPatient} onChange={this.handleSpokenWithPatientChange}>
                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid key={4} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl component="fieldset" margin="dense">
                                        <FormLabel component="legend">Have you scheduled a clinic visit?</FormLabel>
                                        <RadioGroup aria-label="acknowledge3" name="ack2" value={this.state.ScheduledClinicVisit} onChange={this.handleScheduledClinicVisit}>
                                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={false} control={<Radio />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid key={5} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        key="Note"
                                        margin="dense"
                                        InputLabelProps={{shrink: true}}
                                        fullWidth={true}
                                        id="Note"
                                        label="Initial call summary"
                                        onChange={(event) => this.inputChangedHandler(event, "Note")}
                                        value={this.state.Note}
                                        variant="outlined"
                                        multiline={true}
                                        rows={5}
                                        inputProps={{maxLength: 1000}}
                                    />
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </form>
            </Grid>
        );

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Add Infection Episode</DialogTitle>
                    <DialogContent>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        <CCButton
                            animate={this.state.updateBackgroundOperation === true}
                            onClick={this.submitHandler}>
                            Save
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(PatientEscalationAddEpisode),
);