import EnterpriseObjectMemberMetadata from "../../../metadata/eo/EnterpriseObjectMemberMetadata";
import EnterpriseObjectMetadata from "../../../metadata/eo/EnterpriseObjectMetadata";

class InactiveUserEnterpriseObjectMetadata extends EnterpriseObjectMetadata {
    constructor() {
        super();

        this.name = "InactiveUser";
        this.primaryEnterpriseComponent = "InactiveUser";

        let eom1 = new EnterpriseObjectMemberMetadata();
        eom1.name = "InactiveUser";
        eom1.enterpriseComponent = "InactiveUser";
        eom1.relationship = null;
        this.enterpriseComponentMembers[eom1.name] = eom1;
    }
}

export default InactiveUserEnterpriseObjectMetadata;