import EnterpriseObjectMemberMetadata from "../../../metadata/eo/EnterpriseObjectMemberMetadata";
import EnterpriseObjectMetadata from "../../../metadata/eo/EnterpriseObjectMetadata";

class PatientAlertEnterpriseObjectMetadata extends EnterpriseObjectMetadata {
    constructor() {
        super();

        this.name = "PatientAlert";
        this.primaryEnterpriseComponent = "PatientAlert";

        let eom1 = new EnterpriseObjectMemberMetadata();
        eom1.name = "PatientAlert";
        eom1.enterpriseComponent = "PatientAlert";
        eom1.relationship = null;
        this.enterpriseComponentMembers[eom1.name] = eom1;
    }
}

export default PatientAlertEnterpriseObjectMetadata;