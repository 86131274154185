import {Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles} from "@material-ui/core";
import React from "react";
import {withAppContext} from "../../../App";

import CloudcathDetail from "../../../components/CloudcathDetail";
import CCButton from "../../../components/common/CCButton";
import styles from "../../../theme/styles";
import Applet from "../Applet";

class PatientDetailFullTurbidityNotificationApplet extends Applet {
    constructor(props) {
        super(props);

        this.state = {
            Step: "step1",
            Name: "PatientDetailTurbidityNotificationApplet",
            Mode: "Edit",
            Modes: {
                Edit: {
                    Groups: [
                        {
                            "identifier": "group2",
                            "type": "patientNotification",
                            "selector": null,
                            "characteristics": [],
                            "membership": [
                                {
                                    id: "TurbidityNotificationLevelA",
                                },
                                {
                                    id: "NotifyAfterInstancesA",
                                },
                                {
                                    id: "EnableTurbidityNotifications",
                                },
                            ],
                        },
                    ],
                },
            },
            Layout: {
                totalWidth: "36em",
            },
            Controls: {
                TurbidityNotificationLevelA: {
                    id: "TurbidityNotificationLevelA",
                    enterpriseComponentAttribute: "TurbidityNotificationLevelA",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "Number",
                    requiresConfirmation: false,
                    help: [
                        {
                            helpText: "The Turbidity Notification Level is the minimum threshold for triggering",
                        },
                        {
                            helpText: "device notifications. Patient effluent turbidity has a Reference Interval",
                        },
                        {
                            helpText: "Range of 0-6.9. Refer to the software Instructions for Use for more",
                        },
                        {
                            helpText: "details.",
                        },
                    ],
                    errors: [
                        {
                            validator: "Required",
                            value: null,
                            errorText: "Field must contain a value to enable notifications.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        placeholder: null,
                        label: "Turbidity Notification Level",
                        margin: "dense",
                    },
                    ip: {
                        type: "number",
                        step: .1,
                        min: 0.6,
                        max: 100.0,
                    },
                },
                NotifyAfterInstancesA: {
                    id: "NotifyAfterInstancesA",
                    enterpriseComponentAttribute: "NotifyAfterInstancesA",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "Integer",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Required",
                            value: null,
                            errorText: "Field must contain a value to enable notifications.",
                        },
                    ],
                    help: [
                        {
                            helpText: "The Notify after X instances parameter defines the number of effluent",
                        },
                        {
                            helpText: "measurements that must be greater than or equal to the Turbidity",
                        },
                        {
                            helpText: "Notification Level in order to trigger a Turbidity Notification.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        placeholder: null,
                        label: "Notify After X Instances",
                        margin: "dense",
                    },
                    ip: {
                        type: "number",
                        step: 1,
                        min: 1,
                        max: 10,
                    },
                },
                EnableTurbidityNotifications: {
                    id: "EnableTurbidityNotifications",
                    enterpriseComponentAttribute: "EnableTurbidityNotifications",
                    placement: {
                        "View": {
                            xs: 6,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 6,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 6,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "Checkbox",
                    requiresConfirmation: false,
                    errors: [],
                    help: [
                        {
                            helpText: "In cases of changes in effluent turbidity, Turbidity Notifications trigger",
                        },
                        {
                            helpText: "text/email notifications to physicians and nurses associated with",
                        },
                        {
                            helpText: "patients, and changes the display screen on the patients' devices.",
                        },
                    ],
                    csp: {
                        required: false,
                        autoFocus: false,
                        label: "Enable Turbidity Notifications",
                        margin: "dense",
                    },
                    other: {
                        hidden: true,
                    },
                },
            },
        };
    };

    save = () => {
        this.setState({Step: "step3"});
    };

    cancelHandler = (event, _) => {
        this.cancel();
    };

    proceedHandler = (event, _) => {
        this.setState({Step: "step2"});
    };

    render() {
        if (this.state.Step === "step1") {
            return (
                <Dialog onClose={(event, reason) => this.cancelHandler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Edit turbidity notification</DialogTitle>
                    <DialogContent>
                        <Typography className={this.props.classes.addEpisodeText}>Are you sure you want to edit turbidity notifications from the default<br />set by your admin? <span style={{backgroundColor: "#FFFF00"}}>You risk invalidating the turbidity notification.</span></Typography>
                    </DialogContent>
                    <DialogActions>
                        <CCButton variant="secondary" onClick={this.cancelHandler}>
                            Cancel
                        </CCButton>
                        <CCButton
                            onClick={this.proceedHandler}>
                            Proceed
                        </CCButton>
                    </DialogActions>
                </Dialog>
            );
        } else if (this.state.Step === "step2") {
            return <CloudcathDetail applet={this}
                                    layout={this.state.Layout}
                                    controls={this.state.Controls}
                                    modes={this.state.Modes}
                                    title="turbidity notification"
                                    enterpriseComponent={this.getEnterpriseComponent()}
                                    mode={this.state.Mode}
                                    errorReportingMethod="SubmitTime" />;
        } else if (this.state.Step === "step3") {
            return (
                <Dialog onClose={(event, reason) => this.cancelHandler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Edit turbidity notification</DialogTitle>
                    <DialogContent>
                        <Typography className={this.props.classes.addEpisodeText}>You are about to change notification settings. <span style={{backgroundColor: "#FFFF00"}}>Are you sure?</span></Typography>
                    </DialogContent>
                    <DialogActions>
                        <CCButton variant="secondary" onClick={this.cancelHandler}>
                            Cancel
                        </CCButton>
                        <CCButton
                            onClick={() => {
                                this.getEnterpriseComponent().executeUpdate();
                                this.setMode("Save");
                                this.getView().initiateAction(null);
                            }}>
                            Save
                        </CCButton>
                    </DialogActions>
                </Dialog>
            );
        }
    }
}

export default withAppContext(withStyles(styles, {withTheme: true})(PatientDetailFullTurbidityNotificationApplet));