import AttachmentEnterpriseComponentMetadata from "./AttachmentEnterpriseComponentMetadata";

class TreatmentAttachmentEnterpriseComponentMetadata extends AttachmentEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "TreatmentAttachment";
    }
}

export default TreatmentAttachmentEnterpriseComponentMetadata;