import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import {convertToUTC} from "../util/utility";
import CCButton from "./common/CCButton";

class PatientEscalationAcknowledge extends Component {
    state = {
        spokenWithPatient: false,
        scheduledClinicVisit: false,
        updateBackgroundOperation: false,
        Note: "",
        showConfirmation: false,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        lengthWarningText: null,
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;
    };

    componentDidMount() {
        this.applet.setMode("Edit");

        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    acknowledgeEscalationSubmitHandler = (event) => {
        event.preventDefault();

        let patientEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Patient");
        patientEC.forceAttributeValue("PatientEscalationStatus", "Awaiting infection diagnosis");

        let moment = require("moment");

        this.applet.getEnterpriseComponent().setAttributeValue("EscalationNote", this.state.Note);
        this.applet.getEnterpriseComponent().setAttributeValue("EscalationNoteType", "Turbidity Notification Acknowledgment");
        this.applet.getEnterpriseComponent().setAttributeValue("SpokenWithPatient", this.state.spokenWithPatient);
        this.applet.getEnterpriseComponent().setAttributeValue("ScheduledClinicVisit", this.state.scheduledClinicVisit);
        this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Awaiting infection diagnosis");
        this.applet.getEnterpriseComponent().setAttributeValue("AcknowledgedDatetime", convertToUTC(moment.now()));
        this.applet.getEnterpriseComponent().setAttributeValue("AcknowledgedByUSID", this.props.appContext.sessionContext.userUSID);

        this.applet.save();

        this.applet.getView().setRefresh(true);
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = () => {
        this.setState({updateBackgroundOperation: false});

        this.applet.setMode("View");

        this.applet.getView().initiateAction(null);
    };

    getName = () => {
        return "Acknowledge Escalation";
    };

    handleSpokenWithPatientChange = (event) => {
        this.setState({spokenWithPatient: event.target.value === "true"});
    };

    handleScheduledClinicVisit = (event) => {
        this.setState({scheduledClinicVisit: event.target.value === "true"});
    };

    inputChangedHandler = (event) => {
        let lengthWarningText = null;
        if (event.target.value.length === 1000) {
            lengthWarningText = "1000 characters allowed";
        }

        this.setState({Note: event.target.value, lengthWarningText: lengthWarningText});
    };

    cancel1Handler = (event, reason) => {
        event.preventDefault();

        if (this.state.disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (this.state.disableEscapeKeyDown && reason === "escapeKeyDown") {
            return false;
        }

        this.setState({showConfirmation: true});
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    render() {
        let escalationContent = null;
        if (process.env.REACT_APP_VARIANT === "cds") {
            let assistiveText = null;
            if (this.state.lengthWarningText !== null) {
                assistiveText = <Typography className={this.props.classes.assistiveText}>{this.state.lengthWarningText}</Typography>;
            }

            escalationContent = (
                <React.Fragment>
                    <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl component="fieldset" margin="dense">
                            <FormLabel component="legend">Have you spoken with the patient?</FormLabel>
                            <RadioGroup aria-label="acknowledge1" name="ack1" value={this.state.spokenWithPatient} onChange={this.handleSpokenWithPatientChange}>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid key={3} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl component="fieldset" margin="dense">
                            <FormLabel component="legend">Have you scheduled a clinic visit?</FormLabel>
                            <RadioGroup aria-label="acknowledge2" name="ack2" value={this.state.scheduledClinicVisit} onChange={this.handleScheduledClinicVisit}>
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid key={4} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            key="Note"
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                            fullWidth={true}
                            id="Note"
                            label="Initial call summary"
                            onChange={(event) => this.inputChangedHandler(event)}
                            value={this.state.Note}
                            variant="outlined"
                            multiline={true}
                            rows={5}
                            inputProps={{maxLength: 1000}}
                        />
                        {assistiveText}
                    </Grid>
                </React.Fragment>
            );
        }
        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form onSubmit={e => e.preventDefault()}>
                    <Grid key="id6" item container spacing={2}>
                        <Grid key={1} item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h4">By answering and clicking the button below, you are acknowledging the patient's escalation</Typography>
                        </Grid>
                        {escalationContent}
                    </Grid>
                </form>
            </Grid>
        );

        let content = (
            <React.Fragment>
                {modalContent}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Acknowledge Turbidity Notification</DialogTitle>
                    <DialogContent>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        <CCButton
                            animate={this.state.updateBackgroundOperation === true}
                            onClick={this.acknowledgeEscalationSubmitHandler}>
                            Save
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(PatientEscalationAcknowledge),
);