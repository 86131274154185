import PatientAREnterpriseComponentMetadata from "./PatientAREnterpriseComponentMetadata";

class PatientUWEnterpriseComponentMetadata extends PatientAREnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "PatientUW";
    }
}

export default PatientUWEnterpriseComponentMetadata;