import RelationshipMetadata from "../RelationshipMetadata";

class PatientSecondaryPhysicianRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "PatientSecondaryPhysician";
        this.parentEnterpriseComponent = "Patient";
        this.childEnterpriseComponent = "SecondaryPhysician";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "PatientUSID";
    }
}

export default PatientSecondaryPhysicianRelationshipMetadata;