import React from "react";

import CloudcathTable from "../../../../src/components/CloudcathTable";
import {withAppContext} from "../../../App";
import Applet from "../Applet";

class PatientEscalationSymptomListApplet extends Applet {

    createSymptom = (type) => {
        this.getView().initiateAction(type);
    };

    selectSymptomHandler = (index) => {
        this.selectHandler(index);

        this.getView().initiateAction("ViewSymptom");
    };

    render() {
        return <CloudcathTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Symptoms"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               headerRenderer={this.state.HeaderRenderer}
                               selectors={this.state.Selectors}
                               tableHeaderType="Type2"
                               sort={this.state.Sort} />;
    }

    state = {
        Name: "PatientEscalationSymptomListApplet",
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        HeaderRenderer: "Symptom Header Renderer",
        Selectors: {
            "AddSymptom": this.createSymptom,
            "Refresh": this.refreshHandler,
            "Select": this.selectSymptomHandler,
        },
        Placement: {
            "xs": [
                {
                    id: "ObservationDateDisplay",
                },
                {
                    id: "Symptoms",
                },
            ],
            "xl": [
                {
                    id: "ObservationDateDisplay",
                },
                {
                    id: "Symptoms",
                },
                {
                    id: "EnteredByFullName",
                },
                {
                    id: "Attachment",
                },
            ],
        },
        Columns: {
            "ObservationDateDisplay": {
                id: "ObservationDateDisplay",
                width: "80px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Observation date",
                align: "left",
            },
            "Symptoms": {
                id: "Symptoms",
                width: "200px",
                cellRenderer: "Symptom Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Symptoms",
                align: "left",
            },
            "EnteredByFullName": {
                id: "EnteredByFullName",
                width: "80px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Entered by",
                align: "left",
            },
            "Attachment": {
                id: "Attachment",
                width: "100px",
                cellRenderer: "Attachment Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Attachment(s)",
                align: "center",
            },
        },
        Sort: [
            {
                "identifier": 1,
                "default": true,
                "visible": false,
                "options": {
                    "RowIdentifier": "DESC",
                },
            },
        ],
    };

}

export default withAppContext(PatientEscalationSymptomListApplet);
