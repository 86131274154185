import axios from "axios";
import * as Analytics from "./Analytics";
import {SessionContext} from "./SessionContext";

export function makeCloudcathAxios(sessionContext) {

    // may be a dict, null or a SessionContext object depending on login completion status
    if (!sessionContext) sessionContext = new SessionContext();

    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        metadata: {},
    });

    instance.interceptors.request.use(
        (config) => {

            config.metadata.startTime = new Date();

            if (config.url !== "cloudcath/v1/log") {
                Analytics.record("API Request", {
                    "HTTP URL": config.url,
                    "HTTP Method": config.method,
                });
            }

            const token = sessionContext.accessToken;
            if (token) config.headers.common["X-Cloudcath-AccessToken"] = token;

            return config;
        },
        (error) => Promise.reject(error),
    );

    instance.interceptors.response.use(
        (response) => {

            // Sometime response.data is string instead of object #1723
            // https://github.com/axios/axios/issues/1723
            if (response.headers["content-type"]?.includes("application/json")) {
                if (typeof response.data === "string") {
                    try {
                        response.data = JSON.parse(
                            response.data
                                .replace(/\\/g, "\\\\")
                                .replace(/\n/g, "\\n")
                                .replace(/\r/g, "\\r")
                                .replace(/\\"/g, "\""),
                        );
                    } catch (e) {
                        console.error(e);
                    }
                }
            }

            const config = response.config;

            config.metadata.endTime = new Date();

            if (config.url !== "cloudcath/v1/log") {
                Analytics.record("API Response", {
                    "HTTP URL": config.url,
                    "HTTP Method": config.method,
                    "HTTP Code": response.status,
                    "HTTP Duration": (config.metadata.endTime - config.metadata.startTime) / 1000,
                    "Reason Code": response.data ? response.data.Code : undefined,
                });
            }

            return response;
        },
        (error) => {

            const config = error.config;
            const response = error.response;

            config.metadata.endTime = new Date();

            // response can be undefined when there is a network issue / no connection
            if (response === undefined) {
                return Promise.reject(error);
            }

            if (config.url !== "cloudcath/v1/log") {
                Analytics.record("API Response", {
                    "HTTP URL": config.url,
                    "HTTP Method": config.method,
                    "HTTP Code": response.status,
                    "HTTP Duration": (config.metadata.endTime - config.metadata.startTime) / 1000,
                    "Reason Code": response.data ? response.data.Code : undefined,
                });
            }

            if (config.metadata.disableRefresh !== true) {
                if (config.metadata.cogntioRefreshed === undefined) {

                    config.metadata.cogntioRefreshed = true;

                    if (response.status === 401) {
                        return axios({
                            method: "PUT",
                            url: `${process.env.REACT_APP_API_URL}/cloudcath/v1/authenticate`,
                            json: true,
                            data: {
                                RefreshToken: sessionContext.refreshToken,
                            },
                        }).then((response) => {
                            const token = response.data.AccessToken;
                            sessionContext.accessToken = token;
                            config.headers["X-Cloudcath-AccessToken"] = token;
                            return axios(config);
                        });
                    }
                }
            }

            return Promise.reject(error);
        },
    );

    return instance;
}

const CloudcathAxios = makeCloudcathAxios();

export default CloudcathAxios;
