import {AppBar, Tab, Tabs, Toolbar, Typography, useScrollTrigger} from "@material-ui/core";
import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useAppContext} from "../../App";
import CloudCathWhiteSvg from "../../assets/branding/cloudcath-navbar-white.svg";
import * as userAgent from "../../util/UserAgent";
import css_self from "./css/NavBar.module.scss";
import "./css/NavBar.scss";
import MaintenanceBar from "./MaintenanceBar";
import ProfileMenu from "./ProfileMenu";

export default function NavBar() {

    const {sessionContext} = useAppContext();

    const location = useLocation();

    const tabs = [
        {pathname: "/", label: "Patients"},
        ...(!userAgent.isMobile()
                ? [{pathname: "/devices", label: "Devices"}]
                : []
        ),
        ...(!userAgent.isMobile() && sessionContext.userRole === "Admin"
                ? [{pathname: "/users", label: "Users"}]
                : []
        ),
    ];

    return (
        <>
            <ElevationScroll>
                <AppBar
                    {...{"data-cc-component": "NavBar"}}
                    position="sticky"
                    color="transparent"
                >
                    <MaintenanceBar />
                    <Toolbar disableGutters={true}>
                        <img
                            className={css_self.CloudCathLogo}
                            src={CloudCathWhiteSvg}
                            alt="cloudcath-white.svg"
                        />
                        <Typography className={css_self.CloudCathText}>
                            CloudCath
                        </Typography>
                        <ProfileMenu />
                        <Tabs
                            centered
                            value={Math.max(
                                0, // default to first tab at position 0
                                tabs.findIndex((t, i) => t.pathname === location.pathname),
                            )}
                        >
                            {tabs.map((t, i) => (
                                <Tab
                                    key={`tab${t.pathname}`}
                                    component={Link}
                                    to={t.pathname}
                                    label={t.label}
                                    tabIndex={i}
                                />
                            ))}
                        </Tabs>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <div className={css_self.ToolbarSpacer} />
        </>
    );
}

function ElevationScroll({children, ...props}) {

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}
