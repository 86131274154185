import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles} from "@material-ui/core";
import {Edit} from "@material-ui/icons";
import _ from "lodash";
import React, {Component} from "react";
import {withAppContext} from "../App";
import spinner from "../assets/Spinner-1s-200px.svg";
import eoecClassFactory from "../eoec/EOECClassFactory";
import Picklist from "../eoec/picklist/Picklist";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";

class UserReassignPatients extends Component {
    state = {
        enterpriseObjects: {},
        enterpriseComponents: {},
        fetchBackgroundOperations: [],
        updateBackgroundOperations: [],
        patients: {primary: [], secondary: []},
        scratchpad: {primary: {}, secondary: {}},
        submissions: null,
        primaryPatients: [],
        secondaryPatients: [],
        picklist: [],
        showSecondaryPatients: false,
        showConfirmation: false,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        menuTarget: null,
    };

    constructor(props) {

        super(props);

        this.applet = props.applet;

        const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");

        let enterpriseObject = eoecClassFactory("eo", "Patient");
        this.state.enterpriseObjects["Patient"] = enterpriseObject;
        this.state.enterpriseComponents["Patient"] = enterpriseObject.getEnterpriseComponent("Patient");
        this.state.enterpriseComponents[`Secondary${userRole}`] = enterpriseObject.getEnterpriseComponent(`Secondary${userRole}`);

        this.primaryPicklist = new Picklist(`Primary${userRole}`);
        this.state.enterpriseComponents["UserPicklist"] = eoecClassFactory("ec", this.primaryPicklist.getEnterpriseComponent());
    };

    componentDidMount() {

        const userUSID = this.applet.getEnterpriseComponent().getAttributeValue("USID");
        const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");

        this.state.enterpriseComponents["Patient"].addLoadStartedListener(this);
        this.state.enterpriseComponents["Patient"].addLoadCompletedListener(this);
        this.state.enterpriseComponents["Patient"].addUpdateStartedListener(this);
        this.state.enterpriseComponents["Patient"].addUpdateCompletedListener(this);
        this.state.enterpriseComponents["Patient"].newQuery();
        this.state.enterpriseComponents["Patient"].setSearchSpecification(
            userRole === "Physician" ? {"PrimaryDoctorUSID": userUSID}
                : userRole === "Nurse" ? {"PrimaryNurseUSID": userUSID}
                    : null,
        );
        this.state.enterpriseComponents["Patient"].setSortSpecification({"PatientIdentifier": "ASC"});
        this.state.enterpriseComponents["Patient"].performQuery();

        this.state.enterpriseComponents[`Secondary${userRole}`].addUpdateStartedListener(this);
        this.state.enterpriseComponents[`Secondary${userRole}`].addUpdateCompletedListener(this);

        this.state.enterpriseComponents["UserPicklist"].addLoadStartedListener(this);
        this.state.enterpriseComponents["UserPicklist"].addLoadCompletedListener(this);
        this.state.enterpriseComponents["UserPicklist"].newQuery();
        this.state.enterpriseComponents["UserPicklist"].setSearchSpecification(this.primaryPicklist.getSearchSpecification());
        this.state.enterpriseComponents["UserPicklist"].setSortSpecification(this.primaryPicklist.getSortSpecification());
        this.state.enterpriseComponents["UserPicklist"].executeQuery();
    };

    componentWillUnmount() {

        const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");

        this.state.enterpriseComponents["Patient"].removeLoadStartedListener(this);
        this.state.enterpriseComponents["Patient"].removeLoadCompletedListener(this);
        this.state.enterpriseComponents["Patient"].removeUpdateStartedListener(this);
        this.state.enterpriseComponents["Patient"].removeUpdateCompletedListener(this);

        this.state.enterpriseComponents[`Secondary${userRole}`].removeUpdateStartedListener(this);
        this.state.enterpriseComponents[`Secondary${userRole}`].removeUpdateCompletedListener(this);

        this.state.enterpriseComponents["UserPicklist"].removeLoadStartedListener(this);
        this.state.enterpriseComponents["UserPicklist"].removeLoadCompletedListener(this);

        this.applet.unmountComponent(this);
    };

    enterpriseComponentLoadStarted = (ec) => {
        let fetchBackgroundOperations = [...this.state.fetchBackgroundOperations];
        fetchBackgroundOperations.push(ec.getName());
        this.setState({"fetchBackgroundOperations": fetchBackgroundOperations});
    };

    enterpriseComponentLoadCompleted = (buffer, ec) => {

        const userUSID = this.applet.getEnterpriseComponent().getAttributeValue("USID");
        const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");

        let state = {};

        if (ec.getName() === "Patient") {

            state.patients = this.state.patients;

            state.patients.primary = _.sortBy(buffer.filter(x =>
                (userRole === "Physician" && x["PrimaryDoctorUSID"] === userUSID) ||
                (userRole === "Nurse" && x["PrimaryNurseUSID"] === userUSID),
            ), x => x["PatientIdentifier"] ?? [
                x["LastName"],
                x["FirstName"],
                ...(x["MiddleInitial"] ? [`${x["MiddleInitial"]}.`.toUpperCase()] : []),
            ].join(" "));

            state.patients.secondary = _.sortBy(buffer.filter(x =>
                (userRole === "Physician" && x["PrimaryDoctorUSID"] !== userUSID) ||
                (userRole === "Nurse" && x["PrimaryNurseUSID"] !== userUSID),
            ), x => x["PatientIdentifier"] ?? [
                x["LastName"],
                x["FirstName"],
                ...(x["MiddleInitial"] ? [`${x["MiddleInitial"]}.`.toUpperCase()] : []),
            ].join(" "));
        }

        if (ec.getName() === this.state.enterpriseComponents["UserPicklist"].getName()) {
            state.picklist = buffer;
        }

        let fetchBackgroundOperations = [...this.state.fetchBackgroundOperations];
        fetchBackgroundOperations = fetchBackgroundOperations.filter(x => x !== ec.getName());
        this.setState({...state, "fetchBackgroundOperations": fetchBackgroundOperations});
    };

    enterpriseComponentUpdateStarted = (buffer, ec) => {
        let updateBackgroundOperations = [...this.state.updateBackgroundOperations];
        updateBackgroundOperations.push(ec.getName());
        this.setState({"updateBackgroundOperations": updateBackgroundOperations});
    };

    enterpriseComponentUpdateCompleted = (buffer, ec) => {
        let continued = this.submitNextPatientRecord();
        if (!continued) this.applet.cancel();
    };

    getName = () => {
        return "UserReassignPatients";
    };

    selectPatientRecord = (usid) => {
        let enterpriseComponent = this.state.enterpriseComponents["Patient"];
        enterpriseComponent.selectRecord(0);
        do {
            if (usid === enterpriseComponent.getAttributeValue("USID")) {
                return enterpriseComponent.getRecordPointer();
            }
        } while (enterpriseComponent.nextRecord());
        return -1;
    };

    submitNextPatientRecord = () => {

        let nextPatientUSID;

        let submissions = this.state.submissions;
        if (submissions === null) submissions = _.cloneDeep(this.state.scratchpad);

        [nextPatientUSID] = Object.keys(submissions.primary);
        if (nextPatientUSID) {

            this.selectPatientRecord(nextPatientUSID);

            for (const [name, value] of Object.entries(submissions.primary[nextPatientUSID])) {
                if (!["PrimaryDoctorUSID", "PrimaryNurseUSID"].includes(name)) continue;
                this.state.enterpriseComponents["Patient"].setAttributeValue(name, value);
            }

            this.state.enterpriseComponents["Patient"].executeUpdate();

            delete submissions.primary[nextPatientUSID];

            this.setState({"submissions": submissions});

            return true;
        }

        [nextPatientUSID] = Object.keys(submissions.secondary);
        if (nextPatientUSID) {

            this.selectPatientRecord(nextPatientUSID);

            const userUSID = this.applet.getEnterpriseComponent().getAttributeValue("USID");
            const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");

            let replacementUSID = this.state.scratchpad.secondary[nextPatientUSID]["UserUSID"];

            this.state.enterpriseComponents[`Secondary${userRole}`].performSupplementalUpdate({
                PatientUSID: nextPatientUSID,
                ...(replacementUSID ? {Add: [{"USID": replacementUSID}]} : {}),
                Remove: [{"USID": userUSID}],
            });

            delete submissions.secondary[nextPatientUSID];

            this.setState({"submissions": submissions});

            return true;
        }

        return false;
    };

    submitHandler = (event) => {

        event.preventDefault();

        if (Object.keys(this.state.scratchpad.primary).length === 0 &&
            Object.keys(this.state.scratchpad.secondary).length === 0) return;

        if (this.state.updateBackgroundOperations.length) return;

        this.submitNextPatientRecord();
    };

    cancel1Handler = (event, reason) => {

        event.preventDefault();

        if (this.state.disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (this.state.disableEscapeKeyDown && reason === "escapeKeyDown") {
            return false;
        }

        if (Object.keys(this.state.scratchpad.primary).length &&
            Object.keys(this.state.scratchpad.secondary).length) {
            this.setState({showConfirmation: true});
            return;
        }

        this.applet.cancel();
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.applet.cancel();
    };

    render() {

        const userUSID = this.applet.getEnterpriseComponent().getAttributeValue("USID");
        const userRole = this.applet.getEnterpriseComponent().getAttributeValue("UserRole");
        const userFullName = this.applet.getEnterpriseComponent().getAttributeValue("FullName");

        let disableAllButtons =
            Boolean(this.state.fetchBackgroundOperations.length) ||
            Boolean(this.state.updateBackgroundOperations.length);

        let disableSaveButton =
            Object.keys(this.state.scratchpad.primary).length === 0 &&
            Object.keys(this.state.scratchpad.secondary).length === 0;

        let primaryPatientContent;
        let secondaryPatientContent = null;
        let menuContent = null;

        if (this.state.fetchBackgroundOperations.length) {
            primaryPatientContent = (
                <div style={{display: "flex", justifyContent: "center", marginBottom: 2}}>
                    <img src={spinner} alt="spinner" width={80} height={80} style={{margin: "auto"}} />
                </div>
            );
        } else {

            let primaryPatients = this.state.patients.primary;
            let secondaryPatients = this.state.patients.secondary;

            if (primaryPatients.length === 0) {

                primaryPatientContent = (
                    <Typography className={this.props.classes.errorText}>This user is not an <i>Assigned {userRole}</i> for any patients.</Typography>
                );

            } else {

                primaryPatientContent = (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow style={{borderBottom: "2px solid #d8dde4", height: "48"}}>
                                    <TableCell className={this.props.classes.tableHeader} width={200} align="left">Patient ID</TableCell>
                                    <TableCell className={this.props.classes.tableHeader} width={200} align="center">
                                        <div style={{margin: userRole === "Physician" ? "0 16px 0 0" : "0 3px 0 -8px"}}>Assigned Physician</div>
                                    </TableCell>
                                    <TableCell className={this.props.classes.tableHeader} width={200} align="center">
                                        <div style={{margin: userRole === "Nurse" ? "0 16px 0 0" : "0 3px 0 -8px"}}>Assigned Nurse</div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {primaryPatients.map((patient, row) => {

                                    let assignmentCell = (column) => {
                                        let editable =
                                            (userRole === "Physician" && column === "PrimaryDoctor") ||
                                            (userRole === "Nurse" && column === "PrimaryNurse");
                                        let primaryFullName = patient["USID"] in this.state.scratchpad.primary && `${column}FullName` in this.state.scratchpad.primary[patient["USID"]] ?
                                            this.state.scratchpad.primary[patient["USID"]][`${column}FullName`] : patient[`${column}FullName`];
                                        let primaryUSID = patient["USID"] in this.state.scratchpad.primary && `${column}USID` in this.state.scratchpad.primary[patient["USID"]] ?
                                            this.state.scratchpad.primary[patient["USID"]][`${column}USID`] : patient[`${column}USID`];
                                        return (
                                            <TableCell
                                                className={this.props.classes.tableCell}
                                                onClick={e => {
                                                    if (!editable || disableAllButtons) return;
                                                    this.setState({menuTarget: {element: e.currentTarget, row: row, type: "Primary"}});
                                                }}
                                                width={200}
                                                align="center"
                                                style={{padding: "unset"}}
                                            >
                                                {editable && !disableAllButtons ? (
                                                    <div className={this.props.classes.userReassignPatientsEditDiv} style={{backgroundColor: primaryUSID === userUSID ? "#FAC68B" : "inherit"}}>
                                                        <div id="hover-edit-button">
                                                            <IconButton size="small" style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                                                                <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                                                            </IconButton>
                                                        </div>
                                                        <div style={{display: "flex", height: 32, justifyContent: "center", alignItems: "center", margin: 3}}>
                                                            {primaryFullName ?? "--"}
                                                        </div>
                                                    </div>
                                                ) : editable ? (
                                                    <div style={{padding: "7px", margin: "2px 16px 2px 0"}}>
                                                        <div style={{display: "flex", height: 32, justifyContent: "center", alignItems: "center", margin: 3}}>
                                                            {primaryFullName ?? "--"}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{display: "flex", height: 32, justifyContent: "center", alignItems: "center", padding: 7, margin: "5px 19px 5px 8px"}}>
                                                        {primaryFullName ?? "--"}
                                                    </div>
                                                )}
                                            </TableCell>
                                        );
                                    };

                                    const patientFullName = [
                                        patient["FirstName"],
                                        ...(patient["MiddleInitial"] ? [`${patient["MiddleInitial"]}.`.toUpperCase()] : []),
                                        patient["LastName"],
                                    ].filter(x => !!x).join(" ") || null;

                                    return (
                                        <TableRow key={`A0EC71E8-${row}`} className={this.props.classes.tableRow} hover style={{height: "52", padding: "4px 0 4px 0", borderBottom: "1px solid #d8dde4"}}>
                                            <TableCell className={this.props.classes.tableCell} width={200} align="left">{patient["PatientIdentifier"] ?? patientFullName ?? "--"}</TableCell>
                                            {assignmentCell("PrimaryDoctor")}
                                            {assignmentCell("PrimaryNurse")}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
            }

            if (secondaryPatients.length) {
                secondaryPatientContent = (
                    <>
                        <FormControlLabel
                            style={{marginTop: 20, marginBottom: 15}}
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    checked={this.state.showSecondaryPatients}
                                    disabled={disableAllButtons}
                                    onChange={e => this.setState({showSecondaryPatients: e.target.checked})}
                                />
                            }
                            label={<Typography variant="h4">Show secondary assignments.</Typography>}
                        />
                        {this.state.showSecondaryPatients ? (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow style={{borderBottom: "2px solid #d8dde4", height: "48"}}>
                                            <TableCell className={this.props.classes.tableHeader} width={200} align="left">Patient ID</TableCell>
                                            <TableCell className={this.props.classes.tableHeader} width={400} align="center">
                                                <div style={{marginRight: 16}}>Replacement</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {secondaryPatients.map((patient, row) => {
                                            let assignmentCell = () => {
                                                let secondaryFullName = patient["USID"] in this.state.scratchpad.secondary ?
                                                    this.state.scratchpad.secondary[patient["USID"]]["UserFullName"] : userFullName;
                                                let secondaryUSID = patient["USID"] in this.state.scratchpad.secondary ?
                                                    this.state.scratchpad.secondary[patient["USID"]]["UserUSID"] : userUSID;
                                                return (
                                                    <TableCell
                                                        className={this.props.classes.tableCell}
                                                        onClick={e => {
                                                            if (disableAllButtons) return;
                                                            this.setState({menuTarget: {element: e.currentTarget, row: row, type: "Secondary"}});
                                                        }}
                                                        width={400}
                                                        align="center"
                                                        style={{padding: "unset"}}
                                                    >
                                                        {!disableAllButtons ? (
                                                            <div className={this.props.classes.userReassignPatientsEditDiv} style={{backgroundColor: secondaryUSID === userUSID ? "#FAC68B" : "inherit"}}>
                                                                <div id="hover-edit-button">
                                                                    <IconButton size="small" style={{backgroundColor: "#1498d8", width: 32, height: 32}}>
                                                                        <Edit style={{width: 22, height: 22, color: "#FFFFFF"}} />
                                                                    </IconButton>
                                                                </div>
                                                                <div style={{display: "flex", height: 32, justifyContent: "center", alignItems: "center", margin: 3}}>
                                                                    {secondaryFullName ?? "--"}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div style={{padding: "7px", margin: "2px 16px 2px 0"}}>
                                                                <div style={{display: "flex", height: 32, justifyContent: "center", alignItems: "center", margin: 3}}>
                                                                    {secondaryFullName ?? "--"}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                );
                                            };
                                            return (
                                                <TableRow key={`CC1831AA-${row}`} className={this.props.classes.tableRow} hover style={{height: "52", padding: "4px 0 4px 0", borderBottom: "1px solid #d8dde4"}}>
                                                    <TableCell className={this.props.classes.tableCell} width={200} align="left">{patient["PatientIdentifier"] ?? "--"}</TableCell>
                                                    {assignmentCell()}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : null}
                    </>
                );
            }

            if (this.state.menuTarget?.element) {

                let unassignmentMenuItem = () => {

                    if (this.state.menuTarget.type === "Primary") {

                        let patient = primaryPatients[this.state.menuTarget.row];

                        let columnType;
                        if (userRole === "Nurse") columnType = "Doctor";
                        if (userRole === "Physician") columnType = "Nurse";

                        if (!patient[`Primary${columnType}USID`]) {
                            return null;
                        }
                    }

                    return (
                        <MenuItem key="E7C14983" onClick={e => {

                            e.stopPropagation();

                            let scratchpad = {...this.state.scratchpad};

                            if (this.state.menuTarget.type === "Primary") {

                                let patient = primaryPatients[this.state.menuTarget.row];

                                let columnType = userRole;
                                if (userRole === "Physician") columnType = "Doctor";

                                scratchpad.primary[patient["USID"]] = {};
                                scratchpad.primary[patient["USID"]][`Primary${columnType}USID`] = null;
                                scratchpad.primary[patient["USID"]][`Primary${columnType}FullName`] = <i>Un-Assign Patient</i>;

                            } else {

                                let patient = secondaryPatients[this.state.menuTarget.row];

                                scratchpad.secondary[patient["USID"]] = {};
                                scratchpad.secondary[patient["USID"]]["UserUSID"] = null;
                                scratchpad.secondary[patient["USID"]]["UserFullName"] = <i>Un-Assign Patient</i>;
                            }

                            this.setState({"scratchpad": scratchpad, menuTarget: null});

                        }}><i>Un-Assign Patient</i></MenuItem>
                    );
                };

                menuContent = (
                    <Menu
                        anchorEl={this.state.menuTarget.element}
                        getContentAnchorEl={null}
                        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                        transformOrigin={{vertical: -3, horizontal: "right"}}
                        open={true}
                        onClose={async () => this.setState({menuTarget: null})}
                        TransitionProps={{onExited: async () => this.setState({menuTarget: null})}}
                        PaperProps={{elevation: 2}}
                    >

                        {unassignmentMenuItem()}

                        {this.state.picklist.map((o, row) => (
                            <MenuItem key={`DF10653B-${row}`} onClick={e => {

                                e.stopPropagation();

                                let scratchpad = {...this.state.scratchpad};

                                if (this.state.menuTarget.type === "Primary") {

                                    let patient = primaryPatients[this.state.menuTarget.row];

                                    if (o["USID"] === userUSID) {

                                        if (patient["USID"] in scratchpad.primary) {
                                            delete scratchpad.primary[patient["USID"]];
                                        }

                                    } else {

                                        let columnType = userRole;
                                        if (userRole === "Physician") columnType = "Doctor";

                                        scratchpad.primary[patient["USID"]] = {};
                                        scratchpad.primary[patient["USID"]][`Primary${columnType}USID`] = o["USID"];
                                        scratchpad.primary[patient["USID"]][`Primary${columnType}FullName`] = o["FullName"];
                                    }

                                } else {

                                    let patient = secondaryPatients[this.state.menuTarget.row];

                                    if (o["USID"] === userUSID) {

                                        if (patient["USID"] in scratchpad.secondary) {
                                            delete scratchpad.secondary[patient["USID"]];
                                        }

                                    } else {

                                        scratchpad.secondary[patient["USID"]] = {};
                                        scratchpad.secondary[patient["USID"]]["UserUSID"] = o["USID"];
                                        scratchpad.secondary[patient["USID"]]["UserFullName"] = o["FullName"];
                                    }
                                }

                                this.setState({"scratchpad": scratchpad, menuTarget: null});

                            }}>{o["FullName"]}</MenuItem>
                        ))}
                    </Menu>
                );
            }
        }

        let modalContent = (
            <Grid container justifyContent="center">
                <form onSubmit={e => e.preventDefault()}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginBottom: "10px"}}>
                        {primaryPatientContent}
                        {secondaryPatientContent}
                        {menuContent}
                    </Grid>
                </form>
            </Grid>
        );

        return (
            <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                <DialogTitle>Re-Assign Patients</DialogTitle>
                <DialogContent style={{padding: "8px 16px"}}>
                    {modalContent}
                </DialogContent>
                <DialogActions>
                    {this.state.showConfirmation ? (
                        <CCButton variant="red" onClick={this.cancel2Handler}>
                            Don't Save
                        </CCButton>
                    ) : (
                        <CCButton variant="secondary" onClick={this.cancel1Handler} disabled={Boolean(this.state.updateBackgroundOperations.length)}>
                            Cancel
                        </CCButton>
                    )}
                    <CCButton
                        variant="green"
                        animate={Boolean(this.state.updateBackgroundOperations.length)}
                        onClick={this.submitHandler}
                        disabled={disableSaveButton || (disableAllButtons && this.state.updateBackgroundOperations.length === 0)}>
                        Save
                    </CCButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(UserReassignPatients),
);