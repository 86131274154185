import {Collapse, Dialog, DialogContent, Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, withStyles} from "@material-ui/core";
import {ArrowDownward, ArrowUpward, Attachment, CheckCircle, Error, ExpandLess, ExpandMore, MoreVert} from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import moment from "moment";
import React, {Component} from "react";
import {withAppContext} from "../App";

import editSVG from "../assets/icon-edit.svg";
import spinner from "../assets/Spinner-1s-200px.svg";
import styles, {Grey4, Grey6, Grey8} from "../theme/styles";
import * as formatters from "../util/Formatters";
import withBreakpoint from "../util/useBreakpoint";
import * as userAgent from "../util/UserAgent";
import CCButton from "./common/CCButton";
import CCPagination from "./common/CCPagination";
import CopyToClipboard from "./common/CopyToClipboard";
import TurbidityGraph from "./turbidity/TurbidityGraph";

class CloudcathTable extends Component {
    state = {
        fetchBackgroundOperation: false,
        attachmentToDownload: null,
        buffer: [],
        showRowsToDisplayButtons: false,
        showColumnSelection: false,
        columnSelection: {},
        showMenu: false,
        folded: true,
        menuTarget: null,
        menuTargetRow: null,
        pageToDisplay: 1,
    };

    changeSort = (name, direction) => {
        let sortSpec = this.getSortSpecification();

        let keys = Object.keys(sortSpec.Options);
        for (let key in keys) {
            if (name === keys[key]) {
                sortSpec[keys[key]].Options = direction;
                this.setSortSpecification(sortSpec);
                this.applet.getEnterpriseComponent().executeQuery(this.pageSize * (this.pageToDisplay - 1), this.pageSize);
            }
        }
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;

        this.sortSpecification = {};
        this.sortSelections = null;
        this.searchSpecification = {};

        this.doubleSelectHandler = () => void 0;
        this.selectHandler = () => void 0;
        this.editHandler = () => void 0;

        if (props.selectors !== undefined) {
            if (props.selectors["DoubleSelect"] !== undefined) {
                this.doubleSelectHandler = props.selectors["DoubleSelect"];
            }
            if (props.selectors["Select"] !== undefined) {
                this.selectHandler = props.selectors["Select"];
            }
            if (props.selectors["Edit"] !== undefined) {
                this.editHandler = props.selectors["Edit"];
            }
        }

        this.pageSize = 5;
        this.pageToDisplay = 1;
        this.numberOfPages = 0;

        if (props.folded !== undefined) {
            this.state.folded = props.folded;
        }
    }

    componentDidMount() {
        this.applet.getEnterpriseComponent().addRecordChangedListener(this);
        this.applet.getEnterpriseComponent().addNewQueryListener(this);
        this.applet.getEnterpriseComponent().addLoadStartedListener(this);
        this.applet.getEnterpriseComponent().addInsertRollbackCompletedListener(this);
        this.applet.getEnterpriseComponent().addUpdateRollbackCompletedListener(this);
        this.applet.getEnterpriseComponent().addLoadCompletedListener(this);
        this.applet.getEnterpriseComponent().addValueChangedListener(this);
        this.applet.getEnterpriseComponent().addInvalidListener(this);
        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
        this.applet.getEnterpriseComponent().addInsertCompletedListener(this);
        this.applet.getEnterpriseComponent().addStartNewRecordModeCompletedListener(this);
        this.applet.getEnterpriseComponent().addSingleRecordRefreshStartedListener(this);
        this.applet.getEnterpriseComponent().addSingleRecordRefreshCompletedListener(this);

        let sortSelections = [];
        let sortSpecification = {};
        let sortSelection = {};
        sortSelection["Identifier"] = 0;
        sortSelection["Options"] = {
            "RowIdentity": "DESC",
        };
        sortSelection["Default"] = false;
        sortSelections.push(sortSelection);

        if (this.props.sort !== undefined) {
            for (let i = 0; i < this.props.sort.length; i++) {
                sortSelection = {};
                sortSelection["Identifier"] = this.props.sort[i].identifier;
                sortSelection["Visible"] = this.props.sort[i].visible;
                sortSelection["Options"] = this.props.sort[i].options;
                sortSelection["Default"] = this.props.sort[i].default === true;
                sortSelections.push(sortSelection);
            }
        }
        let defaultFound = false;
        for (let i = 0; i < sortSelections.length; i++) {
            if (sortSelections[i].Default === true) {
                defaultFound = true;
            }
        }
        if (defaultFound === false) {
            sortSelections[0].Default = true;
        }

        for (let i = 0; i < sortSelections.length; i++) {
            if (sortSelections[i].Default === true) {
                sortSpecification["Identifier"] = sortSelections[i].Identifier;
                sortSpecification["Visible"] = sortSelections[i].Visible;
                sortSpecification["Options"] = sortSelections[i].Options;
            }
        }

        this.searchSpecification = this.props.searchSpecification;
        if (this.searchSpecification === undefined) {
            this.searchSpecification = {};
        }
        this.pageSize = this.props.pageSize.default;

        if (this.mode === "New") {
            this.applet.getEnterpriseComponent().newRecord();
        } else {
            // this.getEnterpriseComponent().newQuery();
            this.setSortSelections(sortSelections);
            this.setSortSpecification(sortSpecification);
            this.applet.getEnterpriseComponent().setSearchSpecification(this.getSearchSpecification());
            this.applet.getEnterpriseComponent().executeQuery(0, this.pageSize);
        }
    }

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    }

    doneColumnSelectionHandler = () => {
        this.setState({showColumnSelection: false});
    };

    doneSortSelectionHandler = () => {
        this.setState({showSortSelection: false});
    };

    getSearchSpecification = () => {
        return this.searchSpecification;
    };

    getSelectedRecordNumber = () => {
        return this.getEnterpriseComponent().getSelectedRecordNumber();
    };

    getSortSelections = () => {
        return this.sortSelections;
    };

    getSortSpecification = () => {
        return this.sortSpecification;
    };

    enterpriseComponentInsertCompleted = (buffer) => {
        let row = {};

        let newBuffer = [...this.state.buffer];

        for (let i = 0; i < newBuffer.length; i++) {
            if (newBuffer[i]["USID"] === buffer["USID"]) {
                row = newBuffer[i];
                break;
            }
        }

        let keys = Object.keys(this.applet.getEnterpriseComponent().getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.applet.getEnterpriseComponent().getAttributes()[keys[i]];
            row[attribute.getName()] = buffer[0][attribute.getName()];
        }

        if (this.applet.getEnterpriseComponent().getTotalRowcount() === null) {
            this.numberOfPages = 0;
        } else {
            let remainder = this.applet.getEnterpriseComponent().getTotalRowcount() % this.pageSize;
            this.numberOfPages = Math.trunc(this.applet.getEnterpriseComponent().getTotalRowcount() / this.pageSize);

            if (remainder > 0) {
                this.numberOfPages += 1;
            }
        }
    };

    enterpriseComponentStartNewRecordModeCompleted = (buffer) => {
        if (this.applet.getEnterpriseComponent().getTotalRowcount() === null) {
            this.numberOfPages = 0;
        } else {
            let remainder = this.applet.getEnterpriseComponent().getTotalRowcount() % this.pageSize;
            let count = Math.trunc(this.applet.getEnterpriseComponent().getTotalRowcount() / this.pageSize);

            if (remainder > 0) {
                count += 1;
            }
            this.numberOfPages = count;
        }

        let localBuffer = buffer.slice((this.pageToDisplay - 1) * this.pageSize, (this.pageToDisplay - 1) * this.pageSize + this.pageSize);

        this.setState({buffer: localBuffer});
    };

    enterpriseComponentInvalidated = (buffer) => {
        this.pageToDisplay = 1;

        this.setState({buffer: buffer});

        if (this.getSortSpecification() !== null) {
            this.applet.getEnterpriseComponent().setSortSpecification(this.getSortSpecification());
        }
        this.applet.getEnterpriseComponent().executeQuery(0, this.pageSize);
    };

    enterpriseComponentInsertRollbackCompleted = (buffer) => {
        if (this.applet.getEnterpriseComponent().getTotalRowcount() === null) {
            this.numberOfPages = 0;
        } else {
            let remainder = this.applet.getEnterpriseComponent().getTotalRowcount() % this.pageSize;
            this.numberOfPages = Math.trunc(this.applet.getEnterpriseComponent().getTotalRowcount() / this.pageSize);

            if (remainder > 0) {
                this.numberOfPages += 1;
            }
        }

        let localBuffer = buffer.slice((this.pageToDisplay - 1) * this.pageSize, (this.pageToDisplay - 1) * this.pageSize + this.pageSize);
        this.setState({buffer: localBuffer, fetchBackgroundOperation: false});
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        let folded = this.state.folded;

        if ((this.props.autoUnfold ?? true) === true && this.applet.getEnterpriseComponent().getTotalRowcount() > 0) {
            folded = false;
        }

        if (this.applet.getEnterpriseComponent().getTotalRowcount() === null) {
            this.numberOfPages = 0;
        } else {
            let remainder = this.applet.getEnterpriseComponent().getTotalRowcount() % this.pageSize;
            this.numberOfPages = Math.trunc(this.applet.getEnterpriseComponent().getTotalRowcount() / this.pageSize);

            if (remainder > 0) {
                this.numberOfPages += 1;
            }
        }

        let localBuffer = buffer.slice((this.pageToDisplay - 1) * this.pageSize, (this.pageToDisplay - 1) * this.pageSize + this.pageSize);
        this.setState({buffer: localBuffer, fetchBackgroundOperation: false, folded: folded});
    };

    enterpriseComponentLoadStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentValueChanged = (buffer) => {
        if (this.applet.getEnterpriseComponent().getTotalRowcount() === null) {
            this.numberOfPages = 0;
        } else {
            let remainder = this.applet.getEnterpriseComponent().getTotalRowcount() % this.pageSize;
            this.numberOfPages = Math.trunc(this.applet.getEnterpriseComponent().getTotalRowcount() / this.pageSize);

            if (remainder > 0) {
                this.numberOfPages += 1;
            }
        }

        let localBuffer = buffer.slice((this.pageToDisplay - 1) * this.pageSize, (this.pageToDisplay - 1) * this.pageSize + this.pageSize);

        this.setState({buffer: localBuffer, fetchBackgroundOperation: false});
    };

    enterpriseComponentNewQueryStarted = () => {
        this.pageToDisplay = 0;
        if (this.applet.getEnterpriseComponent().getTotalRowcount() === null) {
            this.numberOfPages = 0;
        } else {
            let remainder = this.applet.getEnterpriseComponent().getTotalRowcount() % this.pageSize;
            this.numberOfPages = Math.trunc(this.applet.getEnterpriseComponent().getTotalRowcount() / this.pageSize);

            if (remainder > 0) {
                this.numberOfPages += 1;
            }
        }

        let localBuffer = [];

        this.setState({buffer: localBuffer, fetchBackgroundOperation: false});
    };

    enterpriseComponentRecordChanged = (buffer) => {
        let selectedRowNumber = this.applet.getEnterpriseComponent().getSelectedRecordNumber();

        let remainder = Math.trunc(selectedRowNumber / this.pageSize);

        if ((this.pageToDisplay - 1) !== remainder) {
            this.pageToDisplay = remainder + 1;
        } else {
            let localBuffer = buffer.slice((this.pageToDisplay - 1) * this.pageSize, (this.pageToDisplay - 1) * this.pageSize + this.pageSize);
            this.setState({buffer: localBuffer});
        }
    };

    enterpriseComponentUpdateCompleted = (buffer) => {
        if (this.applet.getEnterpriseComponent().getTotalRowcount() === null) {
            this.numberOfPages = 0;
        } else {
            let remainder = this.applet.getEnterpriseComponent().getTotalRowcount() % this.pageSize;
            this.numberOfPages = Math.trunc(this.applet.getEnterpriseComponent().getTotalRowcount() / this.pageSize);

            if (remainder > 0) {
                this.numberOfPages += 1;
            }
        }

        let localBuffer = buffer.slice((this.pageToDisplay - 1) * this.pageSize, (this.pageToDisplay - 1) * this.pageSize + this.pageSize);

        this.setState({buffer: localBuffer, fetchBackgroundOperation: false});
    };

    enterpriseComponentUpdateRollbackCompleted = (buffer) => {
        if (this.applet.getEnterpriseComponent().getTotalRowcount() === null) {
            this.numberOfPages = 0;
        } else {
            let remainder = this.applet.getEnterpriseComponent().getTotalRowcount() % this.pageSize;
            this.numberOfPages = Math.trunc(this.applet.getEnterpriseComponent().getTotalRowcount() / this.pageSize);

            if (remainder > 0) {
                this.numberOfPages += 1;
            }
        }

        let localBuffer = buffer.slice((this.pageToDisplay - 1) * this.pageSize, (this.pageToDisplay - 1) * this.pageSize + this.pageSize);

        this.setState({buffer: localBuffer, fetchBackgroundOperation: false});
    };

    enterpriseComponentUpdateStarted = () => {
    };

    enterpriseComponentSingleRecordRefreshStarted = () => {
    };

    enterpriseComponentSingleRecordRefreshCompleted = (buffer) => {
        if (this.applet.getEnterpriseComponent().getTotalRowcount() === null) {
            this.numberOfPages = 0;
        } else {
            let remainder = this.applet.getEnterpriseComponent().getTotalRowcount() % this.pageSize;
            this.numberOfPages = Math.trunc(this.applet.getEnterpriseComponent().getTotalRowcount() / this.pageSize);

            if (remainder > 0) {
                this.numberOfPages += 1;
            }
        }

        let localBuffer = buffer.slice((this.pageToDisplay - 1) * this.pageSize, (this.pageToDisplay - 1) * this.pageSize + this.pageSize);

        this.setState({buffer: localBuffer});
    };

    handleMenuClick = (event, row) => {
        this.setState({menuTarget: event.currentTarget, menuTargetRow: row});
    };

    handleMenuClose = (event) => {
        event.stopPropagation();
        this.setState({menuTarget: null, menuTargetRow: null});
    };

    handleClickUserMenu = (event, option) => {
        event.stopPropagation();
        if (option === "Verify Communications") {
            if (this.props.title === "Active Users") {
                this.applet.getView().initiateAction("Verify Communications Active User");
            } else if (this.props.title === "Deactivated Users") {
                this.applet.getView().initiateAction("Verify Communications Inactive User");
            }
        } else if (option === "Revoke Invitation") {
            this.applet.getView().initiateAction("Revoke Invitation");
        } else if (option === "Resend Invitation") {
            this.applet.getView().initiateAction("Resend Invitation");
        } else if (option === "Edit") {
            if (this.props.title === "Active Users") {
                this.applet.getView().initiateAction("Edit Active User");
            } else if (this.props.title === "Deactivated Users") {
                this.applet.getView().initiateAction("Edit Inactive User");
            }
        } else if (option === "Deactivate") {
            this.applet.getView().initiateAction("Deactivate User");
        } else if (option === "Reactivate") {
            this.applet.getView().initiateAction("Reactivate User");
        } else if (option === "Re-Assign Patients") {
            this.applet.getView().initiateAction("Re-Assign Patients");
        }
        this.handleMenuClose(event);
    };

    getCellRenderer = (name) => {
        if (name === "Standard Single Cell") {
            return this.renderStandardSingleCell;
        } else if (name === "Last Turbidity Value Cell") {
            return this.renderLastTurbidityValueCell;
        } else if (name === "Device Serial Number Cell") {
            return this.renderDeviceSerialNumberCell;
        } else if (name === "Symptom Cell") {
            return this.renderSymptomCell;
        } else if (name === "Username Cell") {
            return this.renderUsernameCell;
        } else if (name === "User Status Cell") {
            return this.renderUserStatusCell;
        } else if (name === "Main Contact Cell") {
            return this.renderMainContactCell;
        } else if (name === "User Menu Cell") {
            return this.renderUserMenuCell;
        } else if (name === "Amount Cell") {
            return this.renderAmountCell;
        } else if (name === "Lab Result Cell") {
            return this.renderLabResultCell;
        } else if (name === "Row Collapse Cell") {
            return this.renderRowCollapseCell;
        } else if (name === "Edit Cell") {
            return this.renderEditCell;
        } else if (name === "Note Cell") {
            return this.renderNoteCell;
        } else if (name === "Attachment Cell") {
            return this.renderAttachmentCell;
        } else if (name === "Device Patient Name Cell") {
            return this.renderDevicePatientNameCell;
        } else if (name === "Device Last Turbidity Reading Cell") {
            return this.renderDeviceLastTurbidityReadingCell;
        } else if (name === "Highest Turbidity Score Cell") {
            return this.renderHighestTurbidityScoreCell;
        } else if (name === "Boolean Cell") {
            return this.renderBooleanCell;
        } else if (name === "Start Date To End Cell") {
            return this.renderStartDateToEndCell;
        } else if (name === "Treatment Cell") {
            return this.renderTreatmentCell;
        } else if (name === "Patient Identifier Cell") {
            return this.renderPatientIdentifierCell;
        } else if (name === "Escalation List XS Cell") {
            return this.renderEscalationListXSCell;
        }

        return null;
    };

    getHeaderRenderer = (name) => {
        if (name === "Blank Header") {
            return this.renderBlankHeader;
        } else if (name === "Device Serial Number Header") {
            return this.renderDeviceSerialNumberHeader;
        } else if (name === "Standard Single Header") {
            return this.renderStandardSingleHeader;
        } else if (name === "User Menu Header") {
            return this.renderUserMenuHeader;
        }
        return null;
    };

    _getHeaderCells = (breakpoint) => {
        let cells = [];

        for (let columnIndex = 0; columnIndex < this.props.placement[breakpoint].length; columnIndex++) {
            let headerRenderer = this.props.columns[this.props.placement[breakpoint][columnIndex].id].headerRenderer;

            if (headerRenderer === null) console.log("Header renderer not found");
            else {
                cells.push(this.getHeaderRenderer(headerRenderer)(this.props.placement[breakpoint][columnIndex].id));
            }
        }

        return cells;
    };

    getHeaderCells = (breakpoint) => {
        let cells = [];

        if (breakpoint === "xs") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getHeaderCells("sm");
            } else {
                cells = this._getHeaderCells(breakpoint);
            }
        } else if (breakpoint === "sm") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getHeaderCells("md");
            } else {
                cells = this._getHeaderCells(breakpoint);
            }
        } else if (breakpoint === "md") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getHeaderCells("lg");
            } else {
                cells = this._getHeaderCells(breakpoint);
            }
        } else if (breakpoint === "lg") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getHeaderCells("xl");
            } else {
                cells = this._getHeaderCells(breakpoint);
            }
        } else if (breakpoint === "xl") {
            cells = this._getHeaderCells(breakpoint);
        }

        return cells;
    };

    getHeader = () => {
        return (
            <React.Fragment key="HeaderFragment">
                <TableRow key="HeaderRow1" style={{borderBottom: "2px solid #d8dde4", height: "48"}}>
                    {this.getHeaderCells(this.props.breakpoint)}
                </TableRow>
            </React.Fragment>
        );
    };

    getName = () => {
        return this.applet.getName();
    };

    _getRowCells = (rowIndex, breakpoint) => {
        let cells = [];

        for (let columnIndex = 0; columnIndex < this.props.placement[breakpoint].length; columnIndex++) {
            let cellRenderer = this.props.columns[this.props.placement[breakpoint][columnIndex].id].cellRenderer;
            cells.push(this.getCellRenderer(cellRenderer)(this, rowIndex, this.props.placement[breakpoint][columnIndex].id, breakpoint));
        }

        return cells;
    };

    getRowCells = (rowIndex, breakpoint) => {
        let cells = [];

        if (breakpoint === "xs") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getRowCells(rowIndex, "sm");
            } else {
                cells = this._getRowCells(rowIndex, breakpoint);
            }
        } else if (breakpoint === "sm") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getRowCells(rowIndex, "md");
            } else {
                cells = this._getRowCells(rowIndex, breakpoint);
            }
        } else if (breakpoint === "md") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getRowCells(rowIndex, "lg");
            } else {
                cells = this._getRowCells(rowIndex, breakpoint);
            }
        } else if (breakpoint === "lg") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getRowCells(rowIndex, "xl");
            } else {
                cells = this._getRowCells(rowIndex, breakpoint);
            }
        } else if (breakpoint === "xl") {
            cells = this._getRowCells(rowIndex, breakpoint);
        }

        return cells;
    };

    getColumnCount = (breakpoint) => {
        let columnCount = 0;

        if (breakpoint === "xs") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumnCount("sm");
            } else {
                columnCount = this.props.placement["xs"].length;
            }
        } else if (breakpoint === "sm") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumnCount("md");
            } else {
                columnCount = this.props.placement["sm"].length;
            }
        } else if (breakpoint === "md") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumnCount("lg");
            } else {
                columnCount = this.props.placement["md"].length;
            }
        } else if (breakpoint === "lg") {
            if (this.props.placement[breakpoint] === undefined) {
                return this.getColumnCount("xl");
            } else {
                columnCount = this.props.placement["lg"].length;
            }
        } else if (breakpoint === "xl") {
            columnCount = this.props.placement["xl"].length;
        }

        return columnCount;
    };

    getRow = (rowIndex) => {
        return (
            <React.Fragment key={rowIndex + "Fragment"}>
                <TableRow key={"idr" + rowIndex}
                          className={this.props.classes.tableRow}
                          hover
                          onClick={() => this.selectHandler(this.state.buffer[rowIndex].RowNum)}
                          onDoubleClick={() => this.doubleSelectHandler(this.state.buffer[rowIndex].RowNum, this.applet.getEnterpriseComponent())}
                          style={{height: "52"}}>
                    {this.getRowCells(rowIndex, this.props.breakpoint)}
                </TableRow>
                {this.props.breakpoint !== "xs" ? (
                    <TableRow style={{borderBottom: "1px solid #d8dde4"}}>
                        <TableCell
                            className={this.props.classes.tableCell}
                            style={{paddingBottom: 0, paddingTop: 0}}
                            colSpan={this.getColumnCount(this.props.breakpoint)}
                        >
                            <Collapse in={this.state.buffer[rowIndex].openRow !== undefined && this.state.buffer[rowIndex].openRow === true} timeout="auto" unmountOnExit>
                                <TurbidityGraph variant="simple" patientUSID={this.state.buffer[rowIndex]["USID"]} style={{marginTop: "15px", marginBottom: "15px"}} />
                            </Collapse>
                        </TableCell>
                    </TableRow>) : null}
            </React.Fragment>
        );
    };

    _getWidth = (columnId, breakpoint) => {
        let width = this.props.columns[columnId].width[breakpoint];
        if (!width) {
            width = this.props.columns[columnId].width["default"];
        }

        return width;
    };

    handlePageChange = (event, page) => {
        event.preventDefault();

        this.pageToDisplay = page;
        this.setState({pageToDisplay: page});
        this.applet.getEnterpriseComponent().executeQuery(page, this.pageSize);
    };

    openRow = (event, rowIndex) => {
        event.preventDefault();
        event.stopPropagation();

        let buffer = this.state.buffer;
        let status = buffer[rowIndex].openRow;
        if (status === undefined) status = false;

        buffer[rowIndex].openRow = !status;

        this.setState({openRow: !this.state.openRow});
    };

    pageSizeHandler = (event) => {
        event.preventDefault();

        this.pageSize = event.target.value;
        this.pageToDisplay = 1;
        this.setState({pageToDisplay: 1});

        this.applet.getEnterpriseComponent().executeQuery(this.pageSize * (this.pageToDisplay - 1), this.pageSize);
    };

    render() {
        let objectHeaderContainer = null;
        let objectTableContent = null;
        let paginator = null;
        let pageSizeSelector = null;

        let finalMarkup = (
            <img src={spinner} alt="spinner" width={80} height={80} style={{margin: "auto"}} />
        );

        if (this.props.tableHeaderType === undefined || this.props.tableHeaderType === "Standard") {
            objectHeaderContainer = this.renderStandardTableHeaderStructure();
        }

        if (this.state.fetchBackgroundOperation === false) {

            let rows = [];
            for (let i = 0; i < this.state.buffer.length; i++) {
                rows.push(this.getRow(i));
            }

            let columnHeaders = null;
            if (this.props.showColumnHeader === undefined || this.props.showColumnHeader === true) {
                columnHeaders = (
                    <TableHead>
                        {this.getHeader()}
                    </TableHead>
                );
            }
            objectTableContent = (
                <Table>
                    {columnHeaders}
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            );

            if (this.props.tableHeaderType === "Type1" || this.props.tableHeaderType === undefined || this.props.tableHeaderType === null) {
                if (this.state.folded === false) {
                    finalMarkup = (
                        <TableContainer>
                            {objectTableContent}
                        </TableContainer>
                    );
                } else {
                    finalMarkup = null;
                }
            } else if (this.props.tableHeaderType === "Type2") {

                let headerContainer;
                if (this.props.headerRenderer === "Lab Header Renderer") {
                    headerContainer = this.renderLabHeaderStructure();
                } else if (this.props.headerRenderer === "Treatment Header Renderer") {
                    headerContainer = this.renderTreatmentHeaderStructure();
                } else if (this.props.headerRenderer === "Symptom Header Renderer") {
                    headerContainer = this.renderSymptomHeaderStructure();
                } else if (this.props.headerRenderer === "Note Header Renderer") {
                    headerContainer = this.renderNoteHeaderStructure();
                } else /*if (this.props.headerRenderer === "Escalation Header Renderer")*/ {
                    headerContainer = this.renderEscalationHeaderStructure();
                }

                finalMarkup = (
                    <div style={{backgroundColor: "#edeff3", margin: "0 -9px 0 -9px"}}>
                        {headerContainer}
                        {this.state.folded !== true ? (
                            <div style={{margin: "0 1px 1px 1px", backgroundColor: "#FFFFFF"}}>
                                <div style={{margin: "0 10px 0px 10px", paddingBottom: "10px"}}>
                                    <TableContainer>
                                        {objectTableContent}
                                    </TableContainer>
                                </div>
                            </div>
                        ) : null}
                    </div>
                );
            } else if (this.props.tableHeaderType === "Type3") {
                finalMarkup = (
                    <TableContainer>
                        {objectTableContent}
                    </TableContainer>
                );
            }

            if (this.state.folded === false) {
                pageSizeSelector = (
                    <div style={{display: "flex", alignItems: "baseline"}}>
                        <Typography style={{fontSize: "13px", color: "#7b8291"}}>Results per page:</Typography>
                        <TextField
                            select={true}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        elevation: 2,
                                    },
                                },
                                className: this.props.classes.selectPage,
                                IconComponent: ExpandMore,
                            }}
                            InputProps={{disableUnderline: true}}
                            key="1"
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                            id="PageSizeSelector"
                            onChange={(event) => this.pageSizeHandler(event)}
                            value={this.pageSize}
                        >
                            <MenuItem key={1} value={5}>{5}</MenuItem>
                            <MenuItem key={2} value={10}>{10}</MenuItem>
                            <MenuItem key={3} value={20}>{20}</MenuItem>
                            <MenuItem key={4} value={40}>{40}</MenuItem>
                        </TextField>
                    </div>
                );
            }

            if (this.applet.getEnterpriseComponent().getTotalRowcount() > this.pageSize && this.state.folded === false) {
                paginator = (
                    <div style={{display: "flex", alignItems: "center"}}>
                        <CCPagination color="primary"
                                      count={this.numberOfPages}
                                      defaultPage={1}
                                      page={this.state.pageToDisplay}
                                      size="medium"
                                      siblingCount={2}
                                      boundaryCount={2}
                                      onChange={(object, page) => this.handlePageChange(object, page)} />
                    </div>
                );
            }
        }

        return (
            <Grid container item direction="column" style={{flexWrap: "inherit"}}>
                {objectHeaderContainer}
                {finalMarkup}
                <Grid container item direction="row" justifyContent="space-between">
                    {pageSizeSelector}
                    {paginator}
                </Grid>
            </Grid>
        );
    }

    renderBlankHeader = (columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
            padding: "16px 0 16px 0",
        };

        return (
            <TableCell className={this.props.classes.tableHeader} width={this.props.columns[columnId].width} key={"header" + columnId} style={style} align={this.props.columns[columnId].align}>
                &nbsp;
            </TableCell>
        );
    };

    renderBooleanHeader = (columnId) => {
        let upButtonOrImage;
        let downButtonOrImage;

        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        if (this.getSortSpecification() !== null && this.getSortSpecification().Options !== undefined) {
            let keys = Object.keys(this.getSortSpecification().Options);
            for (let key in keys) {
                if (columnId === keys[key]) {
                    let direction = this.getSortSpecification().Options[keys[key]];
                    if (direction === "ASC" && this.getSortSpecification().Visible === true) {
                        upButtonOrImage = (
                            <Tooltip title="" placement="bottom-start">
                                <IconButton size="small" onClick={() => this.changeSort(columnId, "DESC")}>
                                    <ArrowUpward style={{width: "20px", height: "20px"}} />
                                </IconButton>
                            </Tooltip>
                        );
                    }
                    if (direction === "DESC" && this.getSortSpecification().Visible === true) {
                        downButtonOrImage = (
                            <Tooltip title="" placement="bottom-start">
                                <IconButton size="small" onClick={() => this.changeSort(columnId, "ASC")}>
                                    <ArrowDownward style={{width: "20px", height: "20px"}} />
                                </IconButton>
                            </Tooltip>
                        );
                    }
                }
            }
        }

        return (
            <TableCell className={this.props.classes.tableCell} key={"header" + columnId} style={style} width={this.props.columns[columnId].width} align={this.props.columns[columnId].align}>
                <span style={{color: "#7b8291"}}>{this.props.columns[columnId].headerTitle}</span>&nbsp;&nbsp;{upButtonOrImage}&nbsp;{downButtonOrImage}
            </TableCell>
        );
    };

    renderDeviceSerialNumberHeader = (columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        return (
            <TableCell className={this.props.classes.tableHeader} width={this.props.columns[columnId].width} key={"header" + columnId} style={style} align={this.props.columns[columnId].align}>
                {this.props.columns[columnId].headerTitle}
            </TableCell>
        );
    };

    renderStandardSingleHeader = (columnId) => {
        let upButtonOrImage;
        let downButtonOrImage;

        let style = {
            maxWidth: this.props.columns[columnId].width,
        };
        if (this.getSortSpecification() !== null && this.getSortSpecification().Options !== undefined) {
            let keys = Object.keys(this.getSortSpecification().Options);
            for (let key in keys) {
                if (columnId === keys[key]) {
                    let direction = this.getSortSpecification().Options[keys[key]];

                    if (direction === "ASC" && this.getSortSpecification().Visible === true) {
                        upButtonOrImage = (
                            <Tooltip title="" placement="bottom-start">
                                <IconButton size="small" onClick={() => this.changeSort(columnId, "DESC")}>
                                    <ArrowUpward style={{width: "20px", height: "20px"}} />
                                </IconButton>
                            </Tooltip>
                        );
                    }
                    if (direction === "DESC" && this.getSortSpecification().Visible === true) {
                        downButtonOrImage = (
                            <Tooltip title="" placement="bottom-start">
                                <IconButton size="small" onClick={() => this.changeSort(columnId, "ASC")}>
                                    <ArrowDownward style={{width: "20px", height: "20px"}} />
                                </IconButton>
                            </Tooltip>
                        );
                    }
                }
            }
        }

        return (
            <TableCell className={this.props.classes.tableHeader} key={"header" + columnId} style={style} width={this.props.columns[columnId].width} align={this.props.columns[columnId].align}>
                {this.props.columns[columnId].headerTitle}&nbsp;&nbsp;{upButtonOrImage}&nbsp;{downButtonOrImage}
            </TableCell>
        );
    };

    renderUserMenuHeader = (columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };
        return (
            <TableCell className={this.props.classes.tableHeader} width={this.props.columns[columnId].width} key={"header" + columnId} style={style} align={this.props.columns[columnId].align} />
        );
    };

    renderEscalationHeaderStructure = () => {
        return (
            <Grid container item direction="row" justifyContent="flex-start" style={{margin: "22px 10px 22px 10px"}} onClick={() => this.toggleFolded()}>
                <div style={{display: "flex", direction: "row", alignItems: "center"}}>
                    <Typography variant="h2">{this.props.title}</Typography>
                    <IconButton style={{marginLeft: 2, padding: 3}}>
                        {this.state.folded ? (
                            <ExpandMore color="primary" style={{width: 24, height: 24}} />
                        ) : (
                            <ExpandLess color="primary" style={{width: 24, height: 24}} />
                        )}
                    </IconButton>
                </div>
            </Grid>
        );
    };

    renderLabHeaderStructure = () => {

        let showAddLab = true;
        if (showAddLab && (this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("Locked") === true || this.props.appContext.sessionContext.optionalCharacteristics !== null)) {
            showAddLab = false;
        }
        if (showAddLab && userAgent.isMobile()) {
            showAddLab = false;
        }

        return (
            <Grid container item direction="row" justifyContent="space-between" style={{height: "68px", padding: "10px 10px 10px 10px"}} onClick={() => this.toggleFolded()}>
                <div style={{display: "flex", direction: "row", alignItems: "center"}}>
                    <Typography variant="h2">{this.props.title}</Typography>
                    <IconButton style={{marginLeft: 2, padding: 3}}>
                        {this.state.folded ? (
                            <ExpandMore color="primary" style={{width: 24, height: 24}} />
                        ) : (
                            <ExpandLess color="primary" style={{width: 24, height: 24}} />
                        )}
                    </IconButton>
                </div>
                {showAddLab ? (
                    <div style={{padding: "5px 0 0 0"}}>
                        <CCButton
                            style={{width: "200px"}}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={(event) => {
                                event.stopPropagation();
                                this.handleMenuClick(event, true);
                            }}>
                            Add Lab
                        </CCButton>
                        {true === this.state.menuTargetRow ? (
                            <Menu anchorEl={this.state.menuTarget} open={true} onClose={this.handleMenuClose} elevation={2}>
                                <MenuItem key="cellCount" onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.selectors["AddLab"]("AddCellCountLab");
                                    this.setState({menuTargetRow: false});
                                }}>
                                    Cell Count
                                </MenuItem>
                                <MenuItem key="culture" onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.selectors["AddLab"]("AddCultureLab");
                                    this.setState({menuTargetRow: false});
                                }}>
                                    Culture
                                </MenuItem>
                                <MenuItem key="other" onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.selectors["AddLab"]("AddOtherLab");
                                    this.setState({menuTargetRow: false});
                                }}>
                                    Other
                                </MenuItem>
                            </Menu>
                        ) : undefined}
                    </div>
                ) : null}
            </Grid>
        );
    };

    renderStandardTableHeaderStructure = () => {
        let recordCount = this.applet.getEnterpriseComponent().getTotalRowcount();
        return (
            <div style={{marginBottom: this.state.folded ? "0px" : "12px"}}>
                <Grid container item direction="row" justifyContent="flex-start" style={{alignItems: "center"}} xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
                    <div style={{display: "flex", direction: "row", alignItems: "center"}} onClick={() => {
                        if (this.state.fetchBackgroundOperation === false) {
                            this.toggleFolded();
                        }
                    }}>
                        <Typography variant="h2">{this.props.title}</Typography>
                        {this.props.showAlertNotation ? (
                            <Typography className={this.props.classes.patientListBadge}>{this.applet.getEnterpriseComponent().getTotalRowcount()}</Typography>
                        ) : null}
                        <IconButton style={{marginLeft: 2, padding: 3}}>
                            {this.state.fetchBackgroundOperation === false && this.state.folded ? (
                                <ExpandMore color="primary" style={{width: 24, height: 24}} />
                            ) : (
                                <ExpandLess color="primary" style={{width: 24, height: 24}} />
                            )}
                        </IconButton>
                    </div>
                </Grid>
                {this.state.fetchBackgroundOperation === false && !this.props.showAlertNotation ? (
                    <Typography style={{color: "#7b8291", marginTop: "7px"}}>{`${recordCount} Record${recordCount !== 1 ? "s" : ""}`}</Typography>
                ) : null}
            </div>
        );
    };

    renderSymptomHeaderStructure = () => {

        let showAddSymptom = true;
        if (showAddSymptom && (
            this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("Locked") === true ||
            this.props.appContext.sessionContext.optionalCharacteristics !== null)) {
            showAddSymptom = false;
        }
        if (showAddSymptom && userAgent.isMobile()) {
            showAddSymptom = false;
        }

        return (
            <Grid container item direction="row" justifyContent="space-between" style={{height: "68px", padding: "10px 10px 10px 10px"}} onClick={() => this.toggleFolded()}>
                <div style={{display: "flex", direction: "row", alignItems: "center"}}>
                    <Typography variant="h2">{this.props.title}</Typography>
                    <IconButton style={{marginLeft: 2, padding: 3}}>
                        {this.state.folded ? (
                            <ExpandMore color="primary" style={{width: 24, height: 24}} />
                        ) : (
                            <ExpandLess color="primary" style={{width: 24, height: 24}} />
                        )}
                    </IconButton>
                </div>
                {showAddSymptom ? (
                    <div style={{padding: "5px 0 0 0"}}>
                        <CCButton
                            style={{width: "200px"}}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.selectors["AddSymptom"]("AddSymptom");
                            }}>
                            Add Symptom
                        </CCButton>
                    </div>
                ) : null}
            </Grid>
        );
    };

    renderCommunicationHeaderStructure = () => {
        return (
            <Grid container item direction="row" justifyContent="space-between" style={{height: "68px", padding: "10px 10px 10px 10px"}}>
                <div style={{display: "flex", direction: "row", alignItems: "center"}}>
                    <Typography variant="h2">{this.props.title}</Typography>
                    <IconButton style={{marginLeft: 2, padding: 3}}>
                        {this.state.folded ? (
                            <ExpandMore color="primary" style={{width: 24, height: 24}} />
                        ) : (
                            <ExpandLess color="primary" style={{width: 24, height: 24}} />
                        )}
                    </IconButton>
                </div>
            </Grid>
        );
    };

    renderTreatmentHeaderStructure = () => {

        let showAddMedication = true;
        if (showAddMedication && (
            this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("Locked") === true ||
            this.props.appContext.sessionContext.optionalCharacteristics !== null)) {
            showAddMedication = false;
        }
        if (showAddMedication && userAgent.isMobile()) {
            showAddMedication = false;
        }

        return (
            <Grid container item direction="row" justifyContent="space-between" style={{height: "68px", padding: "10px 10px 10px 10px"}} onClick={() => this.toggleFolded()}>
                <div style={{display: "flex", direction: "row", alignItems: "center"}}>
                    <Typography variant="h2">{this.props.title}</Typography>
                    <IconButton style={{marginLeft: 2, padding: 3}}>
                        {this.state.folded ? (
                            <ExpandMore color="primary" style={{width: 24, height: 24}} />
                        ) : (
                            <ExpandLess color="primary" style={{width: 24, height: 24}} />
                        )}
                    </IconButton>
                </div>
                {showAddMedication ? (
                    <div style={{padding: "5px 0 0 0"}}>
                        <CCButton
                            style={{width: "200px"}}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.selectors["AddTreatment"]("AddTreatment");
                            }}>
                            Add Medication
                        </CCButton>
                    </div>
                ) : null}
            </Grid>
        );
    };

    renderNoteHeaderStructure = () => {

        let showAddNote = true;
        if (showAddNote && (
            this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("Locked") === true ||
            this.props.appContext.sessionContext.optionalCharacteristics !== null)) {
            showAddNote = false;
        }
        if (showAddNote && userAgent.isMobile()) {
            showAddNote = false;
        }

        return (
            <Grid container item direction="row" justifyContent="space-between" style={{height: "68px", padding: "10px 10px 10px 10px"}} onClick={() => this.toggleFolded()}>
                <div style={{display: "flex", direction: "row", alignItems: "center"}}>
                    <Typography variant="h2">{this.props.title}</Typography>
                    <IconButton style={{marginLeft: 2, padding: 3}}>
                        {this.state.folded ? (
                            <ExpandMore color="primary" style={{width: 24, height: 24}} />
                        ) : (
                            <ExpandLess color="primary" style={{width: 24, height: 24}} />
                        )}
                    </IconButton>
                </div>
                {showAddNote ? (
                    <div style={{padding: "5px 0 0 0"}}>
                        <CCButton
                            style={{width: "200px"}}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.selectors["AddNote"]("AddNote");
                            }}>
                            Add Note
                        </CCButton>
                    </div>
                ) : null}
            </Grid>
        );
    };

    renderEscalationListXSCell = (instance, rowIndex, columnId, breakpoint) => {
        let width = this._getWidth(columnId, breakpoint);
        let style = {
            width: width,
        };

        const highestTurbidityScore = this.state.buffer[rowIndex]["HighestTurbidityScore"] ?? "--";
        const turbidityThresholdHit = this.state.buffer[rowIndex]["HighestTurbidityScoreNumeric"] !== undefined &&
            this.state.buffer[rowIndex]["TurbidityNotificationLevel"] !== null &&
            this.state.buffer[rowIndex]["HighestTurbidityScoreNumeric"] >= this.state.buffer[rowIndex]["TurbidityNotificationLevel"];

        let turbidityStyle = {
            color: turbidityThresholdHit ? "#e74c3c" : "inherit",
            fontWeight: turbidityThresholdHit ? 600 : "inherit",
        };

        return (
            <TableCell className={this.props.classes.tableCell} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <div style={{display: "flex", direction: "row", alignContent: "space-between", flexDirection: "column"}}>
                    <span className={this.props.classes.episodeXSCellGrey6}>{this.state.buffer[rowIndex]["StartDatetime"]}</span>
                    <span className={this.props.classes.tableCell}>{this.state.buffer[rowIndex]["EscalationStatus"]}</span>
                    <div><span style={turbidityStyle}>{highestTurbidityScore}</span>&nbsp;&nbsp;<span style={{color: Grey6}}>{this.state.buffer[rowIndex]["HighestTurbidityScoreDatetime"]}</span></div>
                </div>
            </TableCell>
        );
    };

    renderPatientIdentifierCell = (instance, rowIndex, columnId, breakpoint) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        const renderPatientName = (rowIndex) => {
            const middleInitial = this.state.buffer[rowIndex]["MiddleInitial"];
            return [
                this.state.buffer[rowIndex]["FirstName"],
                ...(middleInitial ? [`${middleInitial}.`.toUpperCase()] : []),
                this.state.buffer[rowIndex]["LastName"],
            ].join(" ");
        };

        return (
            <TableCell className={this.props.classes.tableCell} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <div style={{display: "flex", direction: "row", alignContent: "space-between", flexDirection: "column"}}>
                    {this.props.appContext.sessionContext.actStudy ? (
                        <>
                            <Typography style={{display: "block", width: "200px", fontSize: 14, fontWeight: 400, color: Grey8}} noWrap={true}>{this.state.buffer[rowIndex]["PatientIdentifier"]}</Typography>
                            <Typography style={{display: "block", width: "200px", fontSize: 10, fontWeight: 400, color: Grey4}} noWrap={true}>CloudCath ID: {this.state.buffer[rowIndex]["CloudCathID"]}</Typography>
                        </>
                    ) : (
                        <>
                            <Typography style={{display: "block", width: "200px", fontSize: 14, fontWeight: 400, color: Grey8}} noWrap={true}>{renderPatientName(rowIndex)}</Typography>
                            <Typography style={{display: "block", width: "200px", fontSize: 10, fontWeight: 400, color: Grey4}} noWrap={true}>CloudCath ID: {this.state.buffer[rowIndex]["CloudCathID"]}</Typography>
                        </>
                    )}
                    {this.state.buffer[rowIndex]["PatientEscalationStatus"] ? (
                        <div style={{marginTop: 5}}>
                            <span className={this.props.classes.patientEscalationBadge}>{this.state.buffer[rowIndex]["PatientEscalationStatus"]}</span>
                        </div>
                    ) : null}
                </div>
            </TableCell>
        );
    };

    renderDeviceSerialNumberCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
            wordBreak: "break-all",
        };

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                {this.state.buffer[rowIndex][this.props.columns[columnId].id] ? (
                    <>
                        {this.state.buffer[rowIndex][this.props.columns[columnId].id]}
                        <CopyToClipboard text={this.state.buffer[rowIndex][this.props.columns[columnId].id]} style={{marginLeft: 5, height: 16, width: 16}} />
                    </>
                ) : "--"}
            </TableCell>
        );
    };

    renderHighestTurbidityScoreCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        const highestTurbidityScore = this.state.buffer[rowIndex][this.props.columns[columnId].id] ?? "--";
        const turbidityThresholdHit = this.state.buffer[rowIndex]["HighestTurbidityScoreNumeric"] !== undefined &&
            this.state.buffer[rowIndex]["TurbidityNotificationLevel"] !== null &&
            this.state.buffer[rowIndex]["HighestTurbidityScoreNumeric"] >= this.state.buffer[rowIndex]["TurbidityNotificationLevel"];

        let turbidityStyle = {
            color: turbidityThresholdHit ? "#e74c3c" : "inherit",
            fontWeight: turbidityThresholdHit ? 600 : "inherit",
        };

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <span style={turbidityStyle}>{highestTurbidityScore}</span>&nbsp;&nbsp;<span style={{color: Grey6}}>{this.state.buffer[rowIndex]["HighestTurbidityScoreDatetime"]}</span>
            </TableCell>
        );
    };

    renderLastTurbidityValueCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        const lastTurbidityValue = this.state.buffer[rowIndex][this.props.columns[columnId].id] ?? "--";
        const turbidityThresholdHit = this.state.buffer[rowIndex]["EnableTurbidityNotifications"] === true &&
            this.state.buffer[rowIndex]["LastTurbidityValueNumeric"] !== undefined &&
            this.state.buffer[rowIndex]["TurbidityNotificationLevelA"] !== null &&
            this.state.buffer[rowIndex]["LastTurbidityValueNumeric"] >= this.state.buffer[rowIndex]["TurbidityNotificationLevelA"];

        let turbidityStyle = {
            color: turbidityThresholdHit ? "#e74c3c" : "inherit",
            fontWeight: turbidityThresholdHit ? 600 : "inherit",
        };

        return (
            <TableCell className={this.props.classes.tableCell} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <div style={turbidityStyle}>{lastTurbidityValue}</div>
                {this.state.buffer[rowIndex][this.props.columns[columnId].id] ? (
                    moment().diff(moment(this.state.buffer[rowIndex]["LastTurbidityValueDatetime"], "HH:mm MMM DD, YYYY"), "hour") <= 48 ? (
                        <span style={{color: Grey6, paddingTop: 4, paddingBottom: 4, marginTop: 2}}>{this.state.buffer[rowIndex]["LastTurbidityValueDatetime"]}</span>
                    ) : (
                        <Tooltip arrow title="No Turbidity Data Received in Over 48 Hours">
                            <span className={this.props.classes.patientTurbidityBadge} style={{marginTop: 2, marginLeft: -10}}>{this.state.buffer[rowIndex]["LastTurbidityValueDatetime"]}</span>
                        </Tooltip>
                    )
                ) : null}
            </TableCell>
        );
    };

    renderMainContactCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        };

        let greenCheck = <CheckCircle style={{color: "#00a044", marginRight: 4, height: 16, width: 16}} />;
        let redCheck = <Error style={{color: "#ad1505", marginRight: 4, height: 16, width: 16}} />;

        return (
            <TableCell className={this.props.classes.tableCell} key={rowIndex + columnId} width={this.props.columns[columnId].width} style={style} align={this.props.columns[columnId].align}>
                <div style={{display: "flex", alignItems: "center"}}>
                    {this.state.buffer[rowIndex]["MainEmail"] ? (
                        <>
                            {this.state.buffer[rowIndex]["MainEmailVerifiedState"] === "VERIFIED" ? greenCheck : redCheck}
                            {this.state.buffer[rowIndex]["MainEmail"]}
                        </>
                    ) : null}
                </div>
                <div style={{height: this.state.buffer[rowIndex]["MainEmail"] && this.state.buffer[rowIndex]["MainPhone"] ? 5 : 0}} />
                <div style={{display: "flex", alignItems: "center"}}>
                    {this.state.buffer[rowIndex]["MainPhone"] ? (
                        <>
                            {this.state.buffer[rowIndex]["MainPhoneVerifiedState"] === "VERIFIED" ? greenCheck : redCheck}
                            {formatters.Phone(this.state.buffer[rowIndex]["MainPhone"])}
                        </>
                    ) : null}
                </div>
            </TableCell>
        );
    };

    renderRowCollapseCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        return (
            <TableCell className={this.props.classes.tableCell} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <IconButton aria-label="expand row" size="small" onClick={(event) => this.openRow(event, rowIndex)}>
                    {this.state.buffer[rowIndex].openRow !== undefined && this.state.buffer[rowIndex].openRow === true ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </TableCell>
        );
    };

    renderStandardSingleCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                {this.state.buffer[rowIndex][this.props.columns[columnId].id] ?? "--"}
            </TableCell>
        );
    };

    renderBooleanCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                {this.state.buffer[rowIndex][this.props.columns[columnId].id] ? "Yes" : "No"}
            </TableCell>
        );
    };

    renderStartDateToEndCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        let content = this.state.buffer[rowIndex]["StartDateDisplay"] === null ? "--" : `${this.state.buffer[rowIndex]["StartDateDisplay"]} - ${this.state.buffer[rowIndex]["EndDateDisplay"] ?? "Current"}`;

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                {content}
            </TableCell>
        );
    };

    renderSymptomCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        let abdominalPain = this.state.buffer[rowIndex]["AbdominalPain"] === true ? "Yes" : "No";
        let nausea = this.state.buffer[rowIndex]["Nausea"] === true ? "Yes" : "No";
        let fever = this.state.buffer[rowIndex]["Fever"] === true ? "Yes" : "No";
        let cloudyFluid = this.state.buffer[rowIndex]["CloudyFluid"] === true ? "Yes" : "No";
        let vomiting = this.state.buffer[rowIndex]["Vomiting"] === true ? "Yes" : "No";

        let content1 = [];
        let content2 = [];

        if (abdominalPain === "Yes") {
            content1.push({
                "Name": "Abdominal pain: ",
                "Value": abdominalPain,
            });
        }
        if (nausea === "Yes") {
            content1.push({
                "Name": "Nausea: ",
                "Value": nausea,
            });
        }
        if (fever === "Yes") {
            content1.push({
                "Name": "Fever: ",
                "Value": fever,
            });
        }
        if (cloudyFluid === "Yes") {
            content2.push({
                "Name": "Cloudy fluid: ",
                "Value": cloudyFluid,
            });
        }
        if (vomiting === "Yes") {
            content2.push({
                "Name": "Vomiting: ",
                "Value": vomiting,
            });
        }

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                {content1.map((element, i) => (
                    <span key={element.Name}>
                        {i > 0 ? <span className={this.props.classes.patientDetailData}>, </span> : undefined}
                        <span className={this.props.classes.patientDetailLabel}>{element.Name}</span>
                        <span className={this.props.classes.patientDetailData}>{element.Value}</span>
                    </span>
                ))}
                {content1.length && content2.length ? <br /> : null}
                {content2.map((element, i) => (
                    <span key={element.Name}>
                        {i > 0 ? <span className={this.props.classes.patientDetailData}>, </span> : undefined}
                        <span className={this.props.classes.patientDetailLabel}>{element.Name}</span>
                        <span className={this.props.classes.patientDetailData}>{element.Value}</span>
                    </span>
                ))}
                {this.state.buffer[rowIndex].Other1 !== "" && this.state.buffer[rowIndex].Other1 !== null ? (
                    <>
                        {content1.length || content2.length ? <br /> : null}
                        <span key={1} className={this.props.classes.patientDetailLabel}>Other: </span>
                        <span key={2} className={this.props.classes.patientDetailData}>{this.state.buffer[rowIndex].Other1}</span>;
                    </>
                ) : null}
            </TableCell>
        );
    };

    renderTreatmentCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <div style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>{this.state.buffer[rowIndex]["Medication"]}</div>
                <div style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}><span style={{color: "#bac3ce", marginRight: 20}}>{this.state.buffer[rowIndex]["Dose"]} {this.state.buffer[rowIndex]["Unit"]} - {this.state.buffer[rowIndex]["Formulation"]}</span></div>
                <div style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}><span style={{color: "#bac3ce"}}>{this.state.buffer[rowIndex]["Frequency"]} - {this.state.buffer[rowIndex]["Route"]}</span></div>
            </TableCell>
        );
    };

    renderUserMenuCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        const userOptions = [];

        if (this.applet.getEnterpriseComponent().getName() === "Invitation") {
            userOptions.push("Revoke Invitation");
            userOptions.push("Resend Invitation");
        } else {
            userOptions.push("Edit");
            if (this.props.title === "Active Users") {
                userOptions.push("Deactivate");
                if (["Nurse", "Physician"].includes(this.state.buffer[rowIndex]["UserRole"])) {
                    userOptions.push("Re-Assign Patients");
                }
            } else if (this.props.title === "Deactivated Users") {
                userOptions.push("Reactivate");
            }
            userOptions.push("Verify Communications");
        }

        return (
            <TableCell className={this.props.classes.tableCell} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <IconButton aria-label="Options" onClick={(e) => this.handleMenuClick(e, rowIndex)}>
                    <MoreVert />
                </IconButton>
                {rowIndex === this.state.menuTargetRow ? (
                    <Menu anchorEl={this.state.menuTarget} open={true} onClose={this.handleMenuClose} elevation={2}>
                        {userOptions.map((option) => (
                            <MenuItem key={option} selected={option === "Refresh"} onClick={(event) => this.handleClickUserMenu(event, option)}>
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                ) : undefined}
            </TableCell>
        );
    };

    renderUsernameCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        let userRole;
        if (this.state.buffer[rowIndex]["UserRole"] === "Nurse") {
            userRole = "Nurse";
        } else if (this.state.buffer[rowIndex]["UserRole"] === "Physician") {
            userRole = "Physician";
        } else {
            userRole = "Admin";
        }

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <div style={{marginBottom: "5px"}}>{this.state.buffer[rowIndex]["Username"]}</div>
                <div style={{color: "#bac3ce"}}>{userRole}</div>
            </TableCell>
        );
    };

    renderUserStatusCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        let statusColor;
        if (this.state.buffer[rowIndex]["UserStatus"] === "Active") {
            statusColor = "#00a044";
        } else {
            statusColor = "#ad1505";
        }

        return (
            <TableCell className={this.props.classes.tableCell} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <span style={{color: statusColor, fontWeight: "bold"}}>{this.state.buffer[rowIndex]["UserStatus"]}</span>
            </TableCell>
        );
    };

    renderAmountCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <div>{this.state.buffer[rowIndex]["Dose"]}&nbsp;{this.state.buffer[rowIndex]["Unit"]}</div>
                <div style={{fontWeight: "normal", color: "#bac3ce"}}>{this.state.buffer[rowIndex]["Frequency"]}</div>
            </TableCell>
        );
    };

    renderLabResultCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        };

        let value = null;
        let color = null;
        let content = null;

        if (this.state.buffer[rowIndex]["Name"] === "Culture") {

            /* 2.4.1.2 */
            if (this.state.buffer[rowIndex]["CultureResult"] === "Inconclusive" && this.state.buffer[rowIndex]["GramStain"] === "No organism") {
                value = "Inconclusive";
                color = "#262837";
            }

            /* 2.4.1.3 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Positive" && this.state.buffer[rowIndex]["GramStain"] === "No organism") {
                value = "Positive";
                color = "#e74c3c";
            }

            /* 2.4.1.4 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Negative" && this.state.buffer[rowIndex]["GramStain"] === "No organism") {
                value = "Negative";
                color = "#00d65b";
            }

            /* 2.4.1.5 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Inconclusive" && this.state.buffer[rowIndex]["GramStain"] === "Gram-positive") {
                value = "Positive";
                color = "#e74c3c";
            }

            /* 2.4.1.6 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Positive" && this.state.buffer[rowIndex]["GramStain"] === "Gram-positive") {
                value = "Positive";
                color = "#e74c3c";
            }

            /* 2.4.1.7 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Negative" && this.state.buffer[rowIndex]["GramStain"] === "Gram-positive") {
                value = "Inconclusive";
                color = "#262837";
            }

            /* 2.4.1.8 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Inconclusive" && this.state.buffer[rowIndex]["GramStain"] === "Gram-negative") {
                value = "Inconclusive";
                color = "#262837";
            }

            /* 2.4.1.9 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Positive" && this.state.buffer[rowIndex]["GramStain"] === "Gram-negative") {
                value = "Positive";
                color = "#e74c3c";
            }

            /* 2.4.1.10 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Negative" && (this.state.buffer[rowIndex]["GramStain"] === "Gram-negative")) {
                value = "Inconclusive";
                color = "#262837";
            }

            /* 2.4.1.11 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Inconclusive" && (this.state.buffer[rowIndex]["GramStain"] === null)) {
                value = "Inconclusive";
                color = "#262837";
            }

            /* 2.4.1.12 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Positive" && (this.state.buffer[rowIndex]["GramStain"] === null)) {
                value = "Positive";
                color = "#e74c3c";
            }

            /* 2.4.1 13 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === "Negative" && (this.state.buffer[rowIndex]["GramStain"] === null)) {
                value = "Negative";
                color = "#00d65b";
            }

            /* 2.4.1 14 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === null && (this.state.buffer[rowIndex]["GramStain"] === "No organism")) {
                value = "Negative";
                color = "#00d65b";
            }

            /* 2.4.1 15 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === null && (this.state.buffer[rowIndex]["GramStain"] === "Gram-positive")) {
                value = "Positive";
                color = "#e74c3c";
            }

            /* 2.4.1 16 */
            else if (this.state.buffer[rowIndex]["CultureResult"] === null && (this.state.buffer[rowIndex]["GramStain"] === "Gram-negative")) {
                value = "Positive";
                color = "#e74c3c";
            }

            /* 2.4.1.1 */
            else {
                value = "Awaiting result";
                color = "#876d27";
            }

            if (this.state.buffer[rowIndex]["CulturePathogen"] === null) {
                content = <span style={{color: color, fontWeight: "bold"}}>{value}</span>;
            } else {
                content = <span style={{color: color, fontWeight: "bold"}}>{value}(
                    <span style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", display: "inline-block", maxWidth: "calc( 100% - 80px )", verticalAlign: "middle"}}>{this.state.buffer[rowIndex]["CulturePathogen"]}</span>
                )</span>;
            }

        } else if (this.state.buffer[rowIndex]["Name"] === "Cell Count") {
            if (this.state.buffer[rowIndex]["Leukocytes"] === null && this.state.buffer[rowIndex]["Neutrophilis"] === null && this.state.buffer[rowIndex]["Monocytes"] === null && this.state.buffer[rowIndex]["Lymphocytes"] === null) {
                value = "Awaiting result";
                color = "#876d27";
            }
            if (this.state.buffer[rowIndex]["Leukocytes"] === null && this.state.buffer[rowIndex]["Neutrophilis"] === null && this.state.buffer[rowIndex]["Monocytes"] === null && this.state.buffer[rowIndex]["Lymphocytes"] === null) {
                content = <span style={{color: color, fontWeight: "bold"}}>{value}</span>;
            } else {
                content = (
                    <>
                        <div>{this.state.buffer[rowIndex]["Leukocytes"]} Cell per ul</div>
                        <div>PMN Count: {this.state.buffer[rowIndex]["Neutrophilis"]}%</div>
                        <div>Monocytes: {this.state.buffer[rowIndex]["Monocytes"]}%</div>
                        <div>Lymphocytes: {this.state.buffer[rowIndex]["Lymphocytes"]}%</div>
                    </>
                );
            }
        } else if (this.state.buffer[rowIndex]["Name"] === "Other") {
            if (this.state.buffer[rowIndex]["Result"] === null) {
                value = "Awaiting result";
                color = "#876d27";
            }
            if (this.state.buffer[rowIndex]["Result"] === null) {
                content = <span style={{color: color, fontWeight: "bold"}}>{value}</span>;
            } else {
                content = <span>{this.state.buffer[rowIndex]["Result"]}</span>;
            }
        }
        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                {content}
            </TableCell>
        );
    };

    renderNoteCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };
        let note = <Typography variant="h4" component="pre" style={{whiteSpace: "unset", overflow: "hidden", wordWrap: "break-word"}}>{this.state.buffer[rowIndex]["Note"]}</Typography>;
        if (this.state.buffer[rowIndex]["CrossReferenceType"] !== null) {
            note = (
                <React.Fragment>
                    <Typography variant="h5">{this.state.buffer[rowIndex]["CrossReferenceType"]}</Typography>&nbsp;-&nbsp;{note}
                </React.Fragment>
            );
        }
        let dataToDisplay;
        if (this.state.buffer[rowIndex]["EventDateDisplay"] !== null) {
            dataToDisplay = this.state.buffer[rowIndex]["EventDateDisplay"];
        } else if (this.state.buffer[rowIndex]["EventDatetimeDisplay"] !== null) {
            dataToDisplay = this.state.buffer[rowIndex]["EventDatetimeDisplay"];
        }
        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                <>
                    <div style={{display: "flex", direction: "row", justifyContent: "flex-start", alignItems: "baseline"}}>
                        <Typography variant="h5">{this.state.buffer[rowIndex]["EnteredByFullName"]}</Typography>
                        <Typography variant="h6">&nbsp;&nbsp;&nbsp;{dataToDisplay}</Typography>
                    </div>
                    <div style={{display: "flex", direction: "row", justifyContent: "flex-start", alignItems: "baseline"}}>
                        {note}
                    </div>
                </>
            </TableCell>
        );
    };

    renderEditCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
            textAlign: "right",
        };

        let showEdit = true;
        if (showEdit &&
            this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("Locked") === true) {
            showEdit = false;
        }
        if (showEdit && userAgent.isMobile()) {
            showEdit = false;
        }

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                {showEdit ? (
                    <IconButton style={{width: "25px", height: "25px"}} size="small" aria-label="Options" onClick={(event) => this.editHandler(event, this.state.buffer[rowIndex].RowNum)}>
                        <img src={editSVG} alt="editSVG" className="edit-svg" width={18} height={18} style={{margin: "auto", filter: "invert(49%) sepia(22%) saturate(3726%) hue-rotate(168deg) brightness(92%) contrast(85%)"}} />
                    </IconButton>
                ) : null}
            </TableCell>
        );
    };

    scrubFileName = (fileName) => {
        let fileTypePart = fileName.substring(fileName.lastIndexOf("."));
        let fileNamePart = fileName.substring(0, fileName.lastIndexOf("."));
        fileNamePart = fileNamePart.split(".").join("_");
        fileNamePart = fileNamePart.substring(0, 99);
        return fileNamePart + fileTypePart;
    };

    renderAttachmentCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        let usid = this.state.buffer[rowIndex]["USID"];

        let attachment_ec;
        if (this.applet.getEnterpriseComponent().getName() === "EscalationLab") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("LabAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "EscalationTreatment") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("TreatmentAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "EscalationSymptom") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("SymptomAttachment");
        } else if (this.applet.getEnterpriseComponent().getName() === "EscalationNote") {
            attachment_ec = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("NoteAttachment");
        }

        let attachments = [];

        for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
            if (attachment_ec.getBuffer()[i]["ParentUSID"] === usid) {
                attachments.push(
                    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "left"}}>
                            <IconButton onClick={(event) => {
                                event.stopPropagation();
                                this.setState({
                                    attachmentToDownload: {
                                        "USID": attachment_ec.getBuffer()[i]["USID"],
                                        "FileName": this.scrubFileName(attachment_ec.getBuffer()[i]["FileName"]),
                                        "FileSize": attachment_ec.getBuffer()[i]["FileSize"],
                                        "FileType": attachment_ec.getBuffer()[i]["FileType"],
                                    },
                                });
                            }}><Attachment style={{height: "25px", width: "25px"}} color="primary" /></IconButton>
                            <Typography style={{marginTop: "15px", fontSize: "13px"}}>{attachment_ec.getBuffer()[i]["FileName"]}</Typography>
                        </div>
                    </div>,
                );
            }
        }

        let executeDownloadContent = null;

        if (this.state.attachmentToDownload !== null) {
            let url = process.env.REACT_APP_API_URL + "/cloudcath/v1/downloads?USID=" + this.state.attachmentToDownload.USID;

            executeDownloadContent = (
                <Dialog onClose={(event, reason) => this.setState({attachmentToDownload: null})} open={true} scroll="body">
                    <DialogContent>
                        <Grid key={"identifier_1"} item container spacing={2}>
                            <Grid key={"Confirm"} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{display: "flex", flexDirection: "column", width: "100%", marginBottom: "15px"}}>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                        <Typography style={{margin: "auto", display: "block"}} noWrap={true}>Do you want to download {this.state.attachmentToDownload.FileName}?</Typography>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                        <Attachment style={{height: "100px", width: "100px"}} color="primary" />
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                        <Typography style={{margin: "auto", fontSize: "10px", display: "block", maxWidth: "110px"}} noWrap={true}>{this.state.attachmentToDownload.FileName}</Typography>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", marginBottom: "25px"}}>
                                    <CCButton variant="secondary" onClick={() => this.setState({attachmentToDownload: null})}>Cancel</CCButton>
                                    <CCButton href={url} onClick={() => this.setState({attachmentToDownload: null})} target="_blank">Download</CCButton>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            );
        }

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                {attachments}
                {executeDownloadContent}
            </TableCell>
        );
    };

    renderDevicePatientNameCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        const renderPatientName = (rowIndex) => {
            const firstName = this.state.buffer[rowIndex]["FirstName"];
            let result = [
                ...(firstName ? [`${firstName.substring(0, 1)}.`.toUpperCase()] : []),
                this.state.buffer[rowIndex]["LastName"],
            ].join(" ");

            if (result === "") return "--";
            return result;
        };

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                {renderPatientName(rowIndex)}
            </TableCell>
        );
    };

    renderDeviceLastTurbidityReadingCell = (instance, rowIndex, columnId) => {
        let style = {
            maxWidth: this.props.columns[columnId].width,
        };

        return (
            <TableCell className={this.props.classes.tableCell} width={this.props.columns[columnId].width} key={rowIndex + columnId} style={style} align={this.props.columns[columnId].align}>
                {this.state.buffer[rowIndex][this.props.columns[columnId].id] ?? "--"}
            </TableCell>
        );
    };

    rowsToDisplayHandler = () => {
        this.setState({showRowsToDisplayButtons: true});
    };

    rowsToDisplaySelectionHandler = (rows) => {
        this.applet.setPageSize(rows);
        this.setState({showRowsToDisplayButtons: false});
    };

    selectSortHandler = () => {
        this.setState({showSortSelection: true});
    };

    setSortSelections = (sortSelections) => {
        this.sortSelections = sortSelections;
    };

    setSortSpecification = (sortSpecification) => {
        this.sortSpecification = sortSpecification;
        this.applet.getEnterpriseComponent().setSortSpecification(sortSpecification.Options);
    };

    toggleFolded = () => {
        this.setState({folded: !this.state.folded});
    };
}

export default withAppContext(withStyles(styles, {withTheme: true})(withBreakpoint(CloudcathTable)));