import React from "react";
import {withAppContext} from "../../../App";

import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class CultureLabDetailApplet extends Applet {
    state = {
        Name: "CultureLabDetailApplet",
        Mode: "New",
        Modes: {
            View: {
                Groups: [
                    {
                        "id": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Name",
                            },
                            {
                                id: "SampleCollectionDateDisplay",
                            },
                            {
                                id: "GramStain",
                            },
                            {
                                id: "EffluentCollectedBy",
                            },
                            {
                                id: "CultureSource",
                            },
                            {
                                id: "CultureSourceOther",
                            },
                            {
                                id: "CultureResult",
                            },
                            {
                                id: "CulturePathogen",
                            },
                            {
                                id: "ColorAppearance",
                            },
                            {
                                id: "Placeholder1",
                            },
                            {
                                id: "DwellTime",
                            },
                            {
                                id: "DwellVolume",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "ondemand",
                        "selector": "button",
                        "characteristics": [],
                        "increments": [
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial1",
                                    },
                                    {
                                        id: "SusceptibilityPathogen1",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial2",
                                    },
                                    {
                                        id: "SusceptibilityPathogen2",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial3",
                                    },
                                    {
                                        id: "SusceptibilityPathogen3",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial4",
                                    },
                                    {
                                        id: "SusceptibilityPathogen4",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial5",
                                    },
                                    {
                                        id: "SusceptibilityPathogen5",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial6",
                                    },
                                    {
                                        id: "SusceptibilityPathogen6",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial7",
                                    },
                                    {
                                        id: "SusceptibilityPathogen7",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial8",
                                    },
                                    {
                                        id: "SusceptibilityPathogen8",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial9",
                                    },
                                    {
                                        id: "SusceptibilityPathogen9",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial10",
                                    },
                                    {
                                        id: "SusceptibilityPathogen10",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        "id": "group3",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "AssociatedNote",
                            },
                        ],
                    },
                    {
                        "id": "group4",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            New: {
                AllowAttachments: true,
                Groups: [
                    {
                        "id": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Name",
                            },
                            {
                                id: "SampleCollectionDate",
                            },
                            {
                                id: "GramStain",
                            },
                            {
                                id: "EffluentCollectedBy",
                            },
                            {
                                id: "CultureSource",
                            },
                            {
                                id: "CultureSourceOther",
                            },
                            {
                                id: "CultureResult",
                            },
                            {
                                id: "CulturePathogen",
                            },
                            {
                                id: "ColorAppearance",
                            },
                            {
                                id: "Placeholder1",
                            },
                            {
                                id: "DwellTime",
                            },
                            {
                                id: "DwellVolume",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "ondemand",
                        "selector": "button",
                        "characteristics": [],
                        "increments": [
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial1",
                                    },
                                    {
                                        id: "SusceptibilityPathogen1",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial2",
                                    },
                                    {
                                        id: "SusceptibilityPathogen2",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial3",
                                    },
                                    {
                                        id: "SusceptibilityPathogen3",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial4",
                                    },
                                    {
                                        id: "SusceptibilityPathogen4",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial5",
                                    },
                                    {
                                        id: "SusceptibilityPathogen5",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial6",
                                    },
                                    {
                                        id: "SusceptibilityPathogen6",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial7",
                                    },
                                    {
                                        id: "SusceptibilityPathogen7",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial8",
                                    },
                                    {
                                        id: "SusceptibilityPathogen8",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial9",
                                    },
                                    {
                                        id: "SusceptibilityPathogen9",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial10",
                                    },
                                    {
                                        id: "SusceptibilityPathogen10",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        "id": "group3",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "Note",
                            },
                        ],
                    },
                    {
                        "id": "group4",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
            Edit: {
                AllowAttachments: true,
                Groups: [
                    {
                        "id": "group1",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "Name",
                            },
                            {
                                id: "SampleCollectionDateDisplay",
                            },
                            {
                                id: "GramStain",
                            },
                            {
                                id: "EffluentCollectedBy",
                            },
                            {
                                id: "CultureSource",
                            },
                            {
                                id: "CultureSourceOther",
                            },
                            {
                                id: "CultureResult",
                            },
                            {
                                id: "CulturePathogen",
                            },
                            {
                                id: "ColorAppearance",
                            },
                            {
                                id: "Placeholder1",
                            },
                            {
                                id: "DwellTime",
                            },
                            {
                                id: "DwellVolume",
                            },
                        ],
                    },
                    {
                        "id": "group2",
                        "type": "ondemand",
                        "selector": "button",
                        "characteristics": [],
                        "increments": [
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial1",
                                    },
                                    {
                                        id: "SusceptibilityPathogen1",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial2",
                                    },
                                    {
                                        id: "SusceptibilityPathogen2",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial3",
                                    },
                                    {
                                        id: "SusceptibilityPathogen3",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial4",
                                    },
                                    {
                                        id: "SusceptibilityPathogen4",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial5",
                                    },
                                    {
                                        id: "SusceptibilityPathogen5",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial6",
                                    },
                                    {
                                        id: "SusceptibilityPathogen6",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial7",
                                    },
                                    {
                                        id: "SusceptibilityPathogen7",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial8",
                                    },
                                    {
                                        id: "SusceptibilityPathogen8",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial9",
                                    },
                                    {
                                        id: "SusceptibilityPathogen9",
                                    },
                                ],
                            },
                            {
                                "membership": [
                                    {
                                        id: "SusceptibilityAntimicrobial10",
                                    },
                                    {
                                        id: "SusceptibilityPathogen10",
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        "id": "group3",
                        "type": "standard",
                        "selector": null,
                        "characteristics": [],
                        "membership": [
                            {
                                id: "EnteredByUSID",
                            },
                            {
                                id: "AssociatedNote",
                            },
                        ],
                    },
                    {
                        "id": "group4",
                        "type": "attachments",
                        "selector": null,
                        "characteristics": [],
                        "membership": [],
                    },
                ],
            },
        },
        Layout: {
            totalWidth: "36em",
        },
        Controls: {
            Name: {
                id: "Name",
                enterpriseComponentAttribute: "Name",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                errors: [
                    {
                        validator: "Not All Spaces",
                        value: null,
                        errorText: "Field must not contain all spaces.",
                    },
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    }],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Type",
                    margin: "dense",
                    disabled: true,
                },
                ip: {
                    readOnly: true,
                },
                type: "TextField",
                requiresConfirmation: false,
                default: {
                    type: "Literal",
                    value: "Culture",
                },
                other: {
                    hidden: true,
                },
            },
            SampleCollectionDate: {
                id: "SampleCollectionDate",
                enterpriseComponentAttribute: "SampleCollectionDate",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "Date",
                requiresConfirmation: false,
                default: {},
                errors: [
                    {
                        validator: "Required Pattern",
                        value: null,
                        errorText: "Date improperly formatted.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Sample collection date (MM/DD/20YY)",
                    margin: "dense",
                    minDate: this.props.enterpriseComponent.getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("StartDatetime"),
                },
                ip: {},
            },
            SampleCollectionDateDisplay: {
                id: "SampleCollectionDateDisplay",
                enterpriseComponentAttribute: "SampleCollectionDateDisplay",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                default: {},
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Sample collection date (MM/DD/20YY)",
                    margin: "dense",
                },
                ip: {},
            },
            GramStain: {
                id: "GramStain",
                enterpriseComponentAttribute: "GramStain",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: "Provide custom value",
                    label: "Gram stain",
                    margin: "dense",
                },
                ip: {},
            },
            Placeholder1: {
                id: "Placeholder1",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Placeholder",
            },
            CultureResult: {
                id: "CultureResult",
                enterpriseComponentAttribute: "CultureResult",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Culture result",
                    margin: "dense",
                },
                ip: {},
            },
            CultureSource: {
                id: "CultureSource",
                enterpriseComponentAttribute: "CultureSource",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Lab Culture Source",
                    margin: "dense",
                },
                ip: {},
            },
            CultureSourceOther: {
                id: "CultureSourceOther",
                enterpriseComponentAttribute: "CultureSourceOther",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "CultureSourceOther",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Culture Source (Other)",
                    margin: "dense",
                },
                ip: {},
            },
            CulturePathogen: {
                id: "CulturePathogen",
                enterpriseComponentAttribute: "CulturePathogen",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "CulturePathogen",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Pathogen(s)",
                    margin: "dense",
                },
                ip: {},
            },
            ColorAppearance: {
                id: "ColorAppearance",
                enterpriseComponentAttribute: "ColorAppearance",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Color/Appearance",
                    margin: "dense",
                    helperText: null,
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            DwellTime: {
                id: "DwellTime",
                enterpriseComponentAttribute: "DwellTime",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Optional Pattern",
                        value: null,
                        errorText: "Time improperly formatted.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: "00:00",
                    label: "Dwell Time",
                    margin: "dense",
                    helperText: null,
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            DwellVolume: {
                id: "DwellVolume",
                enterpriseComponentAttribute: "DwellVolume",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Integer",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "Maximum Value",
                        value: 99999,
                        errorText: "Field must be less than 99999.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Dwell Volume",
                    margin: "dense",
                    helperText: null,
                    uom: "ml",
                },
                ip: {
                    min: 0,
                    max: 99999,
                },
                other: {
                    renderAssistiveText: false,
                },
            },
            EffluentCollectedBy: {
                id: "EffluentCollectedBy",
                enterpriseComponentAttribute: "EffluentCollectedBy",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Effluent Collected By",
                    margin: "dense",
                    helperText: null,
                },
                ip: {},
                other: {
                    renderAssistiveText: false,
                },
            },
            SusceptibilityAntimicrobial1: {
                id: "SusceptibilityAntimicrobial1",
                enterpriseComponentAttribute: "SusceptibilityAntimicrobial1",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Antimicrobial",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            SusceptibilityPathogen1: {
                id: "SusceptibilityPathogen1",
                enterpriseComponentAttribute: "SusceptibilityPathogen1",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Susceptibility",
                    margin: "dense",
                },
                ip: {},
            },
            SusceptibilityAntimicrobial2: {
                id: "SusceptibilityAntimicrobial2",
                enterpriseComponentAttribute: "SusceptibilityAntimicrobial2",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Antimicrobial",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            SusceptibilityPathogen2: {
                id: "SusceptibilityPathogen2",
                enterpriseComponentAttribute: "SusceptibilityPathogen2",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Susceptibility",
                    margin: "dense",
                },
                ip: {},
            },
            SusceptibilityAntimicrobial3: {
                id: "SusceptibilityAntimicrobial3",
                enterpriseComponentAttribute: "SusceptibilityAntimicrobial3",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Antimicrobial",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            SusceptibilityPathogen3: {
                id: "SusceptibilityPathogen3",
                enterpriseComponentAttribute: "SusceptibilityPathogen3",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Susceptibility",
                    margin: "dense",
                },
                ip: {},
            },
            SusceptibilityAntimicrobial4: {
                id: "SusceptibilityAntimicrobial4",
                enterpriseComponentAttribute: "SusceptibilityAntimicrobial4",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Antimicrobial",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            SusceptibilityPathogen4: {
                id: "SusceptibilityPathogen4",
                enterpriseComponentAttribute: "SusceptibilityPathogen4",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Susceptibility",
                    margin: "dense",
                },
                ip: {},
            },
            SusceptibilityAntimicrobial5: {
                id: "SusceptibilityAntimicrobial5",
                enterpriseComponentAttribute: "SusceptibilityAntimicrobial5",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Antimicrobial",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            SusceptibilityPathogen5: {
                id: "SusceptibilityPathogen5",
                enterpriseComponentAttribute: "SusceptibilityPathogen5",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Susceptibility",
                    margin: "dense",
                },
                ip: {},
            },
            SusceptibilityAntimicrobial6: {
                id: "SusceptibilityAntimicrobial6",
                enterpriseComponentAttribute: "SusceptibilityAntimicrobial6",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Antimicrobial",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            SusceptibilityPathogen6: {
                id: "SusceptibilityPathogen6",
                enterpriseComponentAttribute: "SusceptibilityPathogen6",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Susceptibility",
                    margin: "dense",
                },
                ip: {},
            },
            SusceptibilityAntimicrobial7: {
                id: "SusceptibilityAntimicrobial7",
                enterpriseComponentAttribute: "SusceptibilityAntimicrobial7",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Antimicrobial",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            SusceptibilityPathogen7: {
                id: "SusceptibilityPathogen7",
                enterpriseComponentAttribute: "SusceptibilityPathogen7",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Susceptibility",
                    margin: "dense",
                },
                ip: {},
            },
            SusceptibilityAntimicrobial8: {
                id: "SusceptibilityAntimicrobial8",
                enterpriseComponentAttribute: "SusceptibilityAntimicrobial8",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Antimicrobial",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            SusceptibilityPathogen8: {
                id: "SusceptibilityPathogen8",
                enterpriseComponentAttribute: "SusceptibilityPathogen8",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Susceptibility",
                    margin: "dense",
                },
                ip: {},
            },
            SusceptibilityAntimicrobial9: {
                id: "SusceptibilityAntimicrobial9",
                enterpriseComponentAttribute: "SusceptibilityAntimicrobial9",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Antimicrobial",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            SusceptibilityPathogen9: {
                id: "SusceptibilityPathogen9",
                enterpriseComponentAttribute: "SusceptibilityPathogen9",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Susceptibility",
                    margin: "dense",
                },
                ip: {},
            },
            SusceptibilityAntimicrobial10: {
                id: "SusceptibilityAntimicrobial10",
                enterpriseComponentAttribute: "SusceptibilityAntimicrobial10",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Antimicrobial",
                    margin: "dense",
                },
                ip: {},
                other: {
                    renderAssistiveText: true,
                },
            },
            SusceptibilityPathogen10: {
                id: "SusceptibilityPathogen10",
                enterpriseComponentAttribute: "SusceptibilityPathogen10",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                    "New": {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        xl: 6,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Susceptibility",
                    margin: "dense",
                },
                ip: {},
            },
            EnteredByUSID: {
                id: "EnteredByUSID",
                enterpriseComponentAttribute: "EnteredByUSID",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "Select",
                requiresConfirmation: false,
                default: {
                    type: "Computed",
                    value: "User USID",
                },
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: false,
                    placeholder: null,
                    label: "Entered By:",
                    margin: "dense",
                },
                ip: {},
            },
            Note: {
                id: "Note",
                enterpriseComponentAttribute: "Note",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [
                    {
                        validator: "No Leading Spaces",
                        value: null,
                        errorText: "Field must not contain leading spaces.",
                    },
                    {
                        validator: "No Trailing Spaces",
                        value: null,
                        errorText: "Field must not contain trailing spaces.",
                    },
                ],
                csp: {
                    autoFocus: false,
                    multiline: true,
                    placeholder: null,
                    label: "Note",
                    margin: "dense",
                    rows: 2,
                },
                ip: {},
            },
            AssociatedNote: {
                id: "AssociatedNote",
                enterpriseComponentAttribute: "AssociatedNote",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "TextField",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    multiline: true,
                    placeholder: null,
                    label: "Note",
                    margin: "dense",
                    rows: 2,
                },
                ip: {},
            },
            DropZone: {
                id: "DropZone",
                placement: {
                    "View": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "Edit": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                    "New": {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12,
                        xl: 12,
                    },
                },
                type: "DropZone",
                requiresConfirmation: false,
                errors: [],
                csp: {
                    autoFocus: false,
                    placeholder: null,
                    margin: "dense",
                },
                ip: {},
            },
        },
    };

    render() {
        if (this.getEnterpriseComponent().getAttributeValue("CultureSource") === "Other") {

        }
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="Culture / Gram stain / Susceptibility"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode ? this.props.defaultMode : this.state.Mode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(CultureLabDetailApplet);
