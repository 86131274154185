import React from "react";

import CloudcathTable from "../../../../src/components/CloudcathTable";
import * as userAgent from "../../../util/UserAgent";
import Applet from "../Applet";

class PatientListBaseApplet extends Applet {

    constructor(props, {name, title, autoUnfold, showAlertNotation}) {
        super(props);
        this.name = name;
        this.title = title;
        this.autoUnfold = autoUnfold ?? true;
        this.showAlertNotation = showAlertNotation ?? false;
        this.state = {
            Name: this.name,
            PageSize: {
                "default": 5,
                "xs": 3,
                "sm": 3,
                "md": 10,
                "lg": 10,
                "lx": 10,
            },
            Selectors: {
                "Refresh": this.refreshHandler,
                "Select": this.patientSelectHandler,
            },
            Placement: {
                "xs": [
                    {
                        id: "PatientIdentifier",
                    },
                    {
                        id: "LastTurbidityValue",
                    },
                ],
                "xl": [
                    {
                        id: "PatientIdentifier",
                    },
                    {
                        id: "LastTurbidityValue",
                    },
                    {
                        id: "PrimaryDoctorFullName",
                    },
                    {
                        id: "PrimaryNurseFullName",
                    },
                    {
                        id: "DeviceSerialNumber",
                    },
                    ...(!userAgent.isMobile() ? [{id: "RowCollapse"}] : []),
                ],
            },
            Columns: {
                "PatientIdentifier": {
                    id: "PatientIdentifier",
                    width: 150,
                    cellRenderer: "Patient Identifier Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Patient",
                    align: "left",
                },
                "LastTurbidityValue": {
                    id: "LastTurbidityValue",
                    width: 150,
                    cellRenderer: "Last Turbidity Value Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Last Turbidity Score",
                    align: "left",
                },
                "PrimaryDoctorFullName": {
                    id: "PrimaryDoctorFullName",
                    width: 150,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Assigned Physician",
                    align: "left",
                },
                "PrimaryNurseFullName": {
                    id: "PrimaryNurseFullName",
                    width: 150,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Assigned Nurse",
                    align: "left",
                },
                "DeviceSerialNumber": {
                    id: "DeviceSerialNumber",
                    width: 150,
                    cellRenderer: "Device Serial Number Cell",
                    header: "enabled",
                    headerRenderer: "Device Serial Number Header",
                    headerTitle: "Device Serial #",
                    align: "left",
                },
                "RowCollapse": {
                    id: "RowCollapse",
                    width: 75,
                    cellRenderer: "Row Collapse Cell",
                    header: "disabled",
                    headerRenderer: "Blank Header",
                    headerTitle: null,
                    align: "left",
                },
            },
            Sort: [
                {
                    "identifier": 1,
                    "default": true,
                    "visible": false,
                    "options": {
                        "LastTurbidityScore": "DESC",
                    },
                },
            ],
        };
    }

    patientSelectHandler = (index, parameters) => {
        this.selectHandler(index);

        this.props.history.push({
            pathname: "/detail",
            search: "?patientUSID=" + this.getEnterpriseComponent().getAttributeValue("USID"),
        });
    };

    render() {

        let searchSpecification = null;
        if (this.props.appContext.sessionContext.userRole === "Nurse") {
            searchSpecification = {
                "PrimaryNurseUSID": this.props.appContext.sessionContext.userUSID,
            };
        } else if (this.props.appContext.sessionContext.userRole === "Physician") {
            searchSpecification = {
                "PrimaryDoctorUSID": this.props.appContext.sessionContext.userUSID,
            };
        }

        return <CloudcathTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title={this.title}
                               enterpriseComponent={this.getEnterpriseComponent()}
                               selectors={this.state.Selectors}
                               showAlertNotation={this.showAlertNotation}
                               autoUnfold={this.autoUnfold}
                               sort={this.state.Sort}
                               searchSpecification={searchSpecification} />;
    };
}

export default PatientListBaseApplet;
