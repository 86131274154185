import {Dialog, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import React, {useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useAppContext} from "../../App";
import CloudCathWhiteSvg from "../../assets/branding/cloudcath-white.svg";
import * as Analytics from "../../util/Analytics";
import * as userAgent from "../../util/UserAgent";
import css_self from "./css/MobileNavBar.module.scss";
import "./css/MobileNavBar.scss";

export default function MobileNavBar() {

    const [menuOpen, setMenuOpen] = useState(false);

    const {sessionContext} = useAppContext();

    const location = useLocation();
    const history = useHistory();

    return (
        <>
            <div>
                <MenuIcon
                    className={css_self.MenuOpenIcon}
                    onClick={async () => {
                        setMenuOpen(true);
                        Analytics.record("Mobile Menu Opened");
                    }}
                />
            </div>
            {menuOpen ? (
                <Dialog {...{"data-cc-component": "MobileNavBar"}} fullScreen open={true}>
                    <CloseIcon
                        className={css_self.MenuCloseIcon}
                        onClick={async () => setMenuOpen(false)}
                    />
                    <List>
                        <ListItem
                            onClick={async () => {
                                setMenuOpen(false);
                                if (location.pathname !== "/") {
                                    Analytics.record("Mobile Menu Option", {
                                        Option: "Patients",
                                    });
                                    history.push("/");
                                }
                            }}
                        >
                            <ListItemText {...{"data-bolder": location.pathname === "/"}}>
                                Patients
                            </ListItemText>
                        </ListItem>
                        {!userAgent.isMobile() ? (
                            <ListItem
                                divider={sessionContext.userRole !== "Admin"}
                                onClick={async () => {
                                    setMenuOpen(false);
                                    if (location.pathname !== "/devices") {
                                        Analytics.record("Mobile Menu Option", {
                                            Option: "Devices",
                                        });
                                        history.push("/devices");
                                    }
                                }}
                            >
                                <ListItemText {...{"data-bolder": location.pathname === "/devices"}}>
                                    Devices
                                </ListItemText>
                            </ListItem>
                        ) : null}
                        {!userAgent.isMobile() && sessionContext.userRole === "Admin" ? (
                            <ListItem
                                divider
                                onClick={async () => {
                                    setMenuOpen(false);
                                    if (location.pathname !== "/users") {
                                        Analytics.record("Mobile Menu Option", {
                                            Option: "Users",
                                        });
                                        history.push("/users");
                                    }
                                }}
                            >
                                <ListItemText {...{"data-bolder": location.pathname === "/users"}}>
                                    Users
                                </ListItemText>
                            </ListItem>
                        ) : undefined}
                        <ListItem
                            onClick={async () => {
                                setMenuOpen(false);
                                if (location.pathname !== "/logout") {
                                    Analytics.record("Mobile Menu Option", {
                                        Option: "Logout",
                                    });
                                    Analytics.record("Logging Out", {
                                        Reason: "Mobile Menu",
                                        Voluntary: true,
                                    });
                                    history.push("/logout");
                                }
                            }}
                        >
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Logout
                            </ListItemText>
                        </ListItem>
                        <ListItem disabled>
                            <ListItemText>
                                <div>Model Number: 04PD001</div>
                                <div>Part Number: SWS-00572</div>
                                <div>Version: {process.env.REACT_APP_VERSION}</div>
                                <div>Unique Device Identifier:<br />00860006810238-{process.env.REACT_APP_VERSION}</div>
                            </ListItemText>
                        </ListItem>
                    </List>
                    <div className={css_self.Branding}>
                        <img
                            className={css_self.CloudCathLogo}
                            src={CloudCathWhiteSvg}
                            alt="cloudcath-white.svg"
                        />
                        <Typography className={css_self.CloudCathText}>
                            CloudCath
                        </Typography>
                    </div>
                    <div className={css_self.WaveStage}>
                        <div className={css_self.Wave} />
                    </div>
                </Dialog>
            ) : undefined}
        </>
    );
}
