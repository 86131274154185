import CloudcathTable from "../../../../src/components/CloudcathTable";
import {withAppContext} from "../../../App";
import Applet from "../Applet";

class PatientEscalationListApplet extends Applet {

    patientEscalationSelectHandler = (index) => {
        this.selectHandler(index);

        if (this.getEnterpriseComponent().getAttributeValue("EscalationStatus") === "Awaiting acknowledgment") {
            if (this.props.appContext.sessionContext.optionalCharacteristics === null) {
                this.getView().initiateAction("EpisodeAcknowledgment");
            }
        } else {
            this.props.patientDetail.showEscalationDetail();
        }
    };

    render() {
        return <CloudcathTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Episodes"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               showColumnHeader={true}
                               tableHeaderType="Type3"
                               selectors={this.state.Selectors}
                               headerRenderer={this.state.HeaderRenderer}
                               sort={this.state.Sort} />;
    };

    state = {
        Name: "PatientEscalationListApplet",
        PageSize: {
            "default": 5,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 10,
            "lx": 10,
        },
        Selectors: {
            "Refresh": this.refreshHandler,
            "Select": this.patientEscalationSelectHandler,
        },
        HeaderRenderer: "Escalation Header Renderer",
        Placement: {
            "xs": [
                {
                    id: "EscalationXS",
                },
            ],
            "xl": [
                {
                    id: "StartDatetime",
                },
                {
                    id: "Duration",
                },
                {
                    id: "EscalationStatus",
                },
                {
                    id: "HighestTurbidityScore",
                },
            ],
        },
        Columns: {
            "EscalationXS": {
                id: "EscalationXS",
                width: {
                    default: "400px",
                    "xs": "400px",
                },
                cellRenderer: "Escalation List XS Cell",
                header: "enabled",
                headerRenderer: null,
                headerTitle: null,
                align: "left",
            },
            "EscalationStatus": {
                id: "EscalationStatus",
                width: "200px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Last Status",
                align: "left",
            },
            "Duration": {
                id: "Duration",
                width: "75px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Duration",
                align: "left",
            },
            "StartDatetime": {
                id: "StartDatetime",
                width: "100px",
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Episode Notified",
                align: "left",
            },
            "HighestTurbidityScore": {
                id: "HighestTurbidityScore",
                width: "200px",
                cellRenderer: "Highest Turbidity Score Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Highest turbidity score",
                align: "left",
            },
        },
        Sort: [
            {
                "identifier": 1,
                "default": true,
                "visible": false,
                "options": {
                    "RowIdentifier": "DESC",
                },
            },
        ],
    };

}

export default withAppContext(PatientEscalationListApplet);
