import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";

class RevokeInvitation extends Component {
    state = {
        deleteBackgroundOperation: false,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        confirmDeleteInitiation: false,
        deleteInitiated: false,
        deleteCompleted: false,
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;
    };

    componentDidMount() {
        this.applet.getEnterpriseComponent().addDeleteStartedListener(this);
        this.applet.getEnterpriseComponent().addDeleteCompletedListener(this);
    }

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    enterpriseComponentDeleteStarted = () => {
        this.setState({deleteBackgroundOperation: true});
    };

    enterpriseComponentDeleteCompleted = () => {
        this.applet.getView().initiateAction("RefreshUser");

        this.setState({deleteBackgroundOperation: false, deleteCompleted: true});
    };

    cancel1Handler = (event, _) => {
        this.applet.cancel();
    };

    deleteHandler = (event) => {
        event.preventDefault();

        this.setState({confirmDeleteInitiation: true});
    };

    confirmHandler = (event) => {
        event.preventDefault();

        this.applet.setMode("Delete");

        this.applet.getEnterpriseComponent().executeDelete();
    };

    getName = () => {
        return "RevokeInvitation";
    };

    render() {
        let status = null;

        let invitationDatetime = this.applet.getEnterpriseComponent().getAttributeValue("InvitationDatetime");

        let assistiveText = (
            <FormControl component="fieldset">
                <FormLabel component="legend">Invitation previously sent on <b>{invitationDatetime}</b></FormLabel>
            </FormControl>
        );
        if (this.state.confirmDeleteInitiation) {
            assistiveText = (
                <FormControl component="fieldset">
                    <FormLabel component="legend">Delete invitation. Confirm?</FormLabel>
                </FormControl>
            );
        }
        if (this.state.deleteCompleted) {
            assistiveText = (
                <FormControl component="fieldset">
                    <FormLabel component="legend">Invitation deleted.</FormLabel>
                </FormControl>
            );
        }
        if (this.state.deleteBackgroundOperation) {
            assistiveText = (
                <FormControl component="fieldset">
                    <FormLabel component="legend">Deleting invitation.</FormLabel>
                </FormControl>
            );
        }

        status = (
            <Grid key="id6" item container style={{width: "36em"}}>
                {assistiveText}
            </Grid>
        );

        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form onSubmit={e => e.preventDefault()}>
                    <Grid key="1" item container style={{width: "36em"}}>
                        {status}
                    </Grid>
                </form>
            </Grid>
        );

        let closeButton = (
            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                Close
            </CCButton>
        );

        let mainButton = (
            <CCButton variant="primary" onClick={this.deleteHandler}>
                Delete
            </CCButton>
        );

        if (this.state.confirmDeleteInitiation) {
            mainButton = (
                <CCButton variant="primary" onClick={this.confirmHandler} animate={this.state.deleteBackgroundOperation}>
                    Confirm
                </CCButton>
            );
        }

        if (this.state.deleteBackgroundOperation) {
            closeButton = null;
        }

        if (this.state.deleteCompleted) {
            mainButton = (
                <CCButton animate={false} variant="primary" onClick={this.cancel1Handler}>
                    Done
                </CCButton>
            );
            closeButton = null;
        }

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Revoke Invitation</DialogTitle>
                    <DialogContent style={{padding: "8px 16px"}}>
                        {modalContent}
                    </DialogContent>
                    <DialogActions>
                        {closeButton}
                        {mainButton}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(RevokeInvitation),
);