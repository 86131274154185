import EnterpriseObject from "../eoec/eo/EnterpriseObject";
import AdditionalEmailEnterpriseComponent from "./ec/impl/AdditionalEmailEnterpriseComponent";
import AdditionalPhoneEnterpriseComponent from "./ec/impl/AdditionalPhoneEnterpriseComponent";
import AllActiveUsersEnterpriseComponent from "./ec/impl/AllActiveUsersEnterpriseComponent";
import BillingAccountLookupEnterpriseComponent from "./ec/impl/BillingAccountLookupEnterpriseComponent";
import ClinicEnterpriseComponent from "./ec/impl/ClinicEnterpriseComponent";
import ClinicLookupEnterpriseComponent from "./ec/impl/ClinicLookupEnterpriseComponent";
import DeviceNRIEnterpriseComponent from "./ec/impl/DeviceNRIEnterpriseComponent";
import DeviceRIEnterpriseComponent from "./ec/impl/DeviceRIEnterpriseComponent";
import EscalationCommunicationEnterpriseComponent from "./ec/impl/EscalationCommunicationEnterpriseComponent";
import EscalationEncounterEnterpriseComponent from "./ec/impl/EscalationEncounterEnterpriseComponent";
import EscalationEnterpriseComponent from "./ec/impl/EscalationEnterpriseComponent";
import EscalationEventEnterpriseComponent from "./ec/impl/EscalationEventEnterpriseComponent";
import EscalationLabEnterpriseComponent from "./ec/impl/EscalationLabEnterpriseComponent";
import EscalationNoteEnterpriseComponent from "./ec/impl/EscalationNoteEnterpriseComponent";
import EscalationSymptomEnterpriseComponent from "./ec/impl/EscalationSymptomEnterpriseComponent";
import EscalationTreatmentEnterpriseComponent from "./ec/impl/EscalationTreatmentEnterpriseComponent";
import InactiveUserEnterpriseComponent from "./ec/impl/InactiveUserEnterpriseComponent";
import InvitationEnterpriseComponent from "./ec/impl/InvitationEnterpriseComponent";
import LabAttachmentEnterpriseComponent from "./ec/impl/LabAttachmentEnterpriseComponent";
import MainEmailEnterpriseComponent from "./ec/impl/MainEmailEnterpriseComponent";
import MainPhoneEnterpriseComponent from "./ec/impl/MainPhoneEnterpriseComponent";
import NoteAttachmentEnterpriseComponent from "./ec/impl/NoteAttachmentEnterpriseComponent";
import PatientAlertEnterpriseComponent from "./ec/impl/PatientAlertEnterpriseComponent";
import PatientArchiveEnterpriseComponent from "./ec/impl/PatientArchiveEnterpriseComponent";
import PatientAREnterpriseComponent from "./ec/impl/PatientAREnterpriseComponent";
import PatientAttachmentEnterpriseComponent from "./ec/impl/PatientAttachmentEnterpriseComponent";
import PatientDataEnterpriseComponent from "./ec/impl/PatientDataEnterpriseComponent";

import PatientEnterpriseComponent from "./ec/impl/PatientEnterpriseComponent";
import PatientIdentifierEnterpriseComponent from "./ec/impl/PatientIdentifierEnterpriseComponent";
import PatientINEnterpriseComponent from "./ec/impl/PatientINEnterpriseComponent";
import PatientS0EnterpriseComponent from "./ec/impl/PatientS0EnterpriseComponent";
import PatientUWEnterpriseComponent from "./ec/impl/PatientUWEnterpriseComponent";
import PrimaryNurseEnterpriseComponent from "./ec/impl/PrimaryNurseEnterpriseComponent";
import PrimaryPhysicianEnterpriseComponent from "./ec/impl/PrimaryPhysicianEnterpriseComponent";
import SecondaryNurseEnterpriseComponent from "./ec/impl/SecondaryNurseEnterpriseComponent";
import SecondaryPhysicianEnterpriseComponent from "./ec/impl/SecondaryPhysicianEnterpriseComponent";
import SymptomAttachmentEnterpriseComponent from "./ec/impl/SymptomAttachmentEnterpriseComponent";
import TreatmentAttachmentEnterpriseComponent from "./ec/impl/TreatmentAttachmentEnterpriseComponent";
import UserEnterpriseComponent from "./ec/impl/UserEnterpriseComponent";
import UsernameEnterpriseComponent from "./ec/impl/UsernameEnterpriseComponent";
import Picklist from "./picklist/Picklist";
import Relationship from "./relationship/Relationship";

const enterpriseComponentClasses = {
    DeviceRI: DeviceRIEnterpriseComponent,
    DeviceNRI: DeviceNRIEnterpriseComponent,
    Clinic: ClinicEnterpriseComponent,
    Patient: PatientEnterpriseComponent,
    PatientAR: PatientAREnterpriseComponent,
    PatientUW: PatientUWEnterpriseComponent,
    PatientS0: PatientS0EnterpriseComponent,
    PatientIN: PatientINEnterpriseComponent,
    PatientArchive: PatientArchiveEnterpriseComponent,
    PrimaryPhysician: PrimaryPhysicianEnterpriseComponent,
    PrimaryNurse: PrimaryNurseEnterpriseComponent,
    SecondaryPhysician: SecondaryPhysicianEnterpriseComponent,
    SecondaryNurse: SecondaryNurseEnterpriseComponent,
    User: UserEnterpriseComponent,
    InactiveUser: InactiveUserEnterpriseComponent,
    Invitation: InvitationEnterpriseComponent,
    Username: UsernameEnterpriseComponent,
    PatientIdentifier: PatientIdentifierEnterpriseComponent,
    MainEmail: MainEmailEnterpriseComponent,
    MainPhone: MainPhoneEnterpriseComponent,
    AdditionalEmail: AdditionalEmailEnterpriseComponent,
    AdditionalPhone: AdditionalPhoneEnterpriseComponent,
    Escalation: EscalationEnterpriseComponent,
    EscalationLab: EscalationLabEnterpriseComponent,
    EscalationTreatment: EscalationTreatmentEnterpriseComponent,
    EscalationSymptom: EscalationSymptomEnterpriseComponent,
    EscalationEvent: EscalationEventEnterpriseComponent,
    EscalationEncounter: EscalationEncounterEnterpriseComponent,
    EscalationCommunication: EscalationCommunicationEnterpriseComponent,
    EscalationNote: EscalationNoteEnterpriseComponent,
    PatientData: PatientDataEnterpriseComponent,
    AllActiveUsers: AllActiveUsersEnterpriseComponent,
    PatientAlert: PatientAlertEnterpriseComponent,
    ClinicLookup: ClinicLookupEnterpriseComponent,
    LabAttachment: LabAttachmentEnterpriseComponent,
    SymptomAttachment: SymptomAttachmentEnterpriseComponent,
    TreatmentAttachment: TreatmentAttachmentEnterpriseComponent,
    NoteAttachment: NoteAttachmentEnterpriseComponent,
    PatientAttachment: PatientAttachmentEnterpriseComponent,
    BillingAccountLookup: BillingAccountLookupEnterpriseComponent,
};

export default function eoecClassFactory(type, name) {
    if (type === "eo") {
        let clazz = EnterpriseObject;
        return new (clazz)(name);
    } else if (type === "ec") {
        let clazz = enterpriseComponentClasses[name];
        return new (clazz)(name);
    } else if (type === "rel") {
        let clazz = Relationship;
        return new (clazz)(name);
    } else if (type === "pl") {
        let clazz = Picklist;
        return new (clazz)(name);
    }
}