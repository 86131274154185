import {Link, Tooltip, Typography, useMediaQuery} from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import axios from "axios";
import React, {useState} from "react";
import * as formatters from "../../util/Formatters";
import {sleep} from "../../util/Sleep";
import CCPulse from "../common/CCPulse";
import css_self from "./css/LoginSecureBox.module.scss";
import {useLoginContext} from "./Login";

export default function LoginSecureBox({
    type, // Phone | Email
    main, // true | false
    value,
    status, // UNVERIFIED | VERIFYING | VERIFIED
    disabled,
    onVerificationSending,
    onVerificationSendSuccess,
    onVerificationSendFailed,
    className,
    ...props
}) {

    const [sending, setSending] = useState(false);

    const {preSessionState} = useLoginContext();

    const smallerWidth = useMediaQuery("(max-width:320px)");

    async function sendVerification() {

        const startAt = Date.now();

        setSending(true);

        onVerificationSending(true);

        const response = await axios({
            method: "PUT",
            baseURL: process.env.REACT_APP_API_URL,
            url: "cloudcath/v1/authenticate_send_code",
            json: true,
            headers: {
                "X-Cloudcath-AccessToken": preSessionState.accessToken,
            },
            params: {
                Type: `${main ? "Main" : "Additional"}${type}`,
            },
        }).catch(e => e.response || e);

        if (response instanceof Error && response.message === "Network Error") {
            setSending(false);
            onVerificationSending(false);
            onVerificationSendFailed(response);
            return;
        }

        if (response.status === 204) {
            await onVerificationSendSuccess();
            return; // parent will move to new page
        }

        const waitDiff = 2000 - (Date.now() - startAt);
        waitDiff > 0 && (await sleep(waitDiff));

        setSending(false);

        onVerificationSending(false);

        if (response.status !== 204) {
            onVerificationSendFailed(response);
        }
    }

    const emailMaxLength = smallerWidth ? 15 : 20;
    const displayValue = type === "Phone" ? value : formatters.EmailWithEllipsis(value, emailMaxLength);

    return (
        <div data-test-id={`LoginSecureBox/type:${main ? "Main" : "Additional"}${type}`} className={[css_self.Box, ...(className !== undefined ? [className] : [])].join(" ")}>
            <div className={css_self.Left}>
                <div className={css_self.TitleRow}>
                    {type === "Phone" ? (
                        <PhoneIcon className={css_self.Icon} />
                    ) : (
                        <MailOutlineIcon className={css_self.Icon} />
                    )}
                    <Typography data-test-id="LoginSecureBox/text:header" className={css_self.Text}>{(main ? "" : "Additional ") + type}</Typography>
                </div>
                {value ? (
                    displayValue && value !== displayValue ? (
                        <Tooltip arrow title={value}>
                            <Typography data-test-id="LoginSecureBox/text:value" component="span" className={css_self.Value}>{displayValue}</Typography>
                        </Tooltip>
                    ) : (
                        <Typography data-test-id="LoginSecureBox/text:value" className={css_self.Value}>{value}</Typography>
                    )
                ) : (
                    <Typography data-test-id="LoginSecureBox/text:value" className={css_self.NotAddedText}>Not available</Typography>
                )}
            </div>
            {value ? (
                <div className={css_self.Right}>
                    {!sending && disabled ? (
                        <Typography data-test-id="LoginSecureBox/link:actionDisabled" className={css_self.ActionTextDisabled}>Verify</Typography>
                    ) : (
                        <Typography component="span" className={css_self.ActionText}>
                            {sending ? (
                                <CCPulse data-test-id="LoginSecureBox/link:actionAnimation" className={css_self.Pulse} DotProps={{className: css_self.PulseDot}} />
                            ) : (
                                <Link data-test-id="LoginSecureBox/link:action" variant="inherit" color="inherit" href="#" onClick={e => {
                                    e.preventDefault();
                                    sendVerification();
                                }}>
                                    Verify
                                </Link>
                            )}
                        </Typography>
                    )}
                </div>
            ) : undefined}
        </div>
    );
}
