import {IconButton, InputAdornment, Link, Typography} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import axios from "axios";
import React, {useState} from "react";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {sleep} from "../../util/Sleep";
import * as validators from "../../util/Validators";
import CCButton from "../common/CCButton";
import CCInputLabel from "../common/CCInputLabel";
import CCTextField from "../common/CCTextField";
import css_shared from "./css/LoginCommon.module.scss";
import css_self from "./css/LoginHelp3.module.scss";
import {useLoginHelpContext} from "./LoginHelp";

export default function LoginHelp3() {

    const [submitting, setSubmitting] = useState(false);
    const [errorText, setErrorText] = useState(undefined);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const {helpState} = useLoginHelpContext();

    const history = useHistory();

    async function submit() {

        const startAt = Date.now();

        setSubmitting(true);

        const response = await axios({
            method: "POST",
            baseURL: process.env.REACT_APP_API_URL,
            url: "cloudcath/v1/forgot_password",
            json: true,
            data: {
                NewPassword: password,
                Key: helpState.key,
                Code: helpState.code,
            },
        }).catch(e => e.response || e);

        if (response instanceof Error && response.message === "Network Error") {
            setSubmitting(false);
            setErrorText("Connection not available");
            return;
        }

        const waitDiff = 2000 - (Date.now() - startAt);
        waitDiff > 0 && (await sleep(waitDiff));

        if (response.status !== 204) {
            setSubmitting(false);
            setErrorText("Service temporarily unavailable");
            return;
        }

        history.replace("/");
    }

    function validate() {

        setErrorText(undefined);

        if (!validators.Required(password)) {
            setErrorText("Password must contain a value.");
        } else if (!validators.Minimum(password, 8)) {
            setErrorText("Password must have at least 8 characters.");
        } else if (!validators.NoSpaces(password)) {
            setErrorText("Password must have no spaces.");
        } else if (!validators.MatchValues(password, passwordConfirm)) {
            setErrorText("Passwords must match.");
        } else {
            return true;
        }

        return false;
    }

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Typography data-test-id="LoginHelp3/text:header" className={css_shared.Title}>Reset your password</Typography>
            <CCInputLabel>New password</CCInputLabel>
            <CCTextField
                value={password}
                placeholder="Enter new password"
                type={showPassword ? "text" : "password"}
                onChange={async e => setPassword(e.target.value)}
                InputProps={{
                    inputProps: {"data-test-id": "LoginHelp3/input:password"},
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton data-test-id="LoginHelp3/input:password/button:show" onClick={async () => setShowPassword(!showPassword)} onMouseDown={e => e.preventDefault()}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <CCInputLabel>Confirm new password</CCInputLabel>
            <CCTextField
                value={passwordConfirm}
                placeholder="Reenter new password"
                type={showPassword ? "text" : "password"}
                onChange={async e => setPasswordConfirm(e.target.value)}
                InputProps={{
                    inputProps: {"data-test-id": "LoginHelp3/input:passwordConfirm"},
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton data-test-id="LoginHelp3/input:passwordConfirm/button:show" onClick={async () => setShowPassword(!showPassword)} onMouseDown={e => e.preventDefault()}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                helperText={<span data-test-id="LoginHelp3/text:error" className="Mui-error" style={{visibility: errorText ? undefined : "hidden"}}>{errorText}</span>}
            />
            <CCButton
                data-test-id="LoginHelp3/button:reset"
                className={css_self.Button}
                margin="none"
                animate={submitting}
                disabled={submitting}
                onClick={() => {
                    if (validate()) {
                        submit();
                    }
                }}
            >
                Reset Password
            </CCButton>
            <Typography className={css_shared.LoginInsteadText}>
                Already have an account?{" "}
                <Link data-test-id="LoginHelp3/link:login" component={RouterLink} to="/" replace={true}>Login</Link>
            </Typography>
        </form>
    );
}
