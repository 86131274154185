import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, TextField, Typography, withStyles} from "@material-ui/core";
import {Error} from "@material-ui/icons";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";
import CCDatePicker from "./common/CCDatePicker";

class PatientEscalationInfectionConfirmed extends Component {
    state = {
        updateBackgroundOperation: false,
        Note: "",
        DateOfDiagnosis: "",
        DateOfDiagnosisError: false,
        DateOfDiagnosisHelper: null,

        BacterialInfectionConfirmed: false,
        FungalInfectionConfirmed: false,
        TypeOfInfectionExitSite: false,
        TypeOfInfectionTunnel: false,
        TypeOfInfectionPeritonitis: false,
        symptomAlert: false,
        medicationAlert: false,
        labAlert: false,
        labEmptyAlert: false,
        showConfirmation: false,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        lengthWarningText: null,
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;

        this.state.DateOfDiagnosis = this.applet.getEnterpriseComponent().getAttributeValue("InfectionDiagnosisDate");
        this.state.BacterialInfectionConfirmed = this.applet.getEnterpriseComponent().getAttributeValue("BacterialInfectionConfirmed");
        this.state.FungalInfectionConfirmed = this.applet.getEnterpriseComponent().getAttributeValue("FungalInfectionConfirmed");

        this.state.TypeOfInfectionExitSite = this.applet.getEnterpriseComponent().getAttributeValue("TypeOfInfectionExitSite");
        this.state.TypeOfInfectionTunnel = this.applet.getEnterpriseComponent().getAttributeValue("TypeOfInfectionTunnel");
        this.state.TypeOfInfectionPeritonitis = this.applet.getEnterpriseComponent().getAttributeValue("TypeOfInfectionPeritonitis");
    };

    componentDidMount() {
        this.applet.setMode("Edit");

        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);

        let symptomEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("EscalationSymptom");
        if (symptomEC.getBuffer().length === 0) {
            this.setState({symptomAlert: true});
        }
        let labEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("EscalationLab");
        if (labEC.getBuffer().length === 0) {
            this.setState({labAlert: true});
        } else {
            let found = false;
            for (let i = 0; i < labEC.getBuffer().length; i++) {
                let row = labEC.getBuffer()[i];

                if (row["Name"] === "Cell Count") {
                    if (row["Leukocytes"] !== null && row["Neutrophilis"] !== null && row["Monocytes"] !== null && row["Lymphocytes"] !== null) {
                        found = true;
                    }
                }
                if (found === false && row["Name"] === "Culture") {
                    if (row["GramStain"] !== null && row["CultureResult"] !== null && row["CulturePathogen"] !== null) {
                        found = true;
                    }
                }
                if (found === false && row["Name"] === "Other") {
                    if (row["Result"] !== null && row["Type"] !== null) {
                        found = true;
                    }
                }
            }
            if (found === false) {
                this.setState({labEmptyAlert: true});
            }
        }
        let medicationEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("EscalationTreatment");
        if (medicationEC.getBuffer().length === 0) {
            this.setState({medicationAlert: true});
        }
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.state.DateOfDiagnosis === null || this.state.DateOfDiagnosis === "") {
            this.setState({DateOfDiagnosisHelper: "Field must contain a value.", DateOfDiagnosisError: true});
        } else if (this.state.DateOfDiagnosisError === true) {
            this.setState({DateOfDiagnosisHelper: "Date improperly formatted."});
        } else {
            let patientEC = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Patient");
            patientEC.forceAttributeValue("PatientEscalationStatus", "Infection treatment monitoring");

            this.applet.getEnterpriseComponent().setAttributeValue("BacterialInfectionConfirmed", this.state.BacterialInfectionConfirmed);
            this.applet.getEnterpriseComponent().setAttributeValue("FungalInfectionConfirmed", this.state.FungalInfectionConfirmed);
            this.applet.getEnterpriseComponent().setAttributeValue("TypeOfInfectionExitSite", this.state.TypeOfInfectionExitSite);
            this.applet.getEnterpriseComponent().setAttributeValue("TypeOfInfectionTunnel", this.state.TypeOfInfectionTunnel);
            this.applet.getEnterpriseComponent().setAttributeValue("TypeOfInfectionPeritonitis", this.state.TypeOfInfectionPeritonitis);
            this.applet.getEnterpriseComponent().setAttributeValue("EscalationNote", this.state.Note);
            this.applet.getEnterpriseComponent().setAttributeValue("EscalationNoteType", "Diagnosed Infection");
            if (!this.state.editMode) {
                this.applet.getEnterpriseComponent().setAttributeValue("EscalationStatus", "Infection treatment monitoring");
            }
            this.applet.getEnterpriseComponent().setAttributeValue("InfectionDiagnosisDate", this.state.DateOfDiagnosis);
            this.applet.getEnterpriseComponent().setAttributeValue("DiagnosedByUSID", this.props.appContext.sessionContext.userUSID);
            this.applet.save();

            this.applet.getView().setRefresh(true);
        }
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = () => {
        this.setState({updateBackgroundOperation: false});

        this.applet.setMode("View");

        this.applet.getView().initiateAction(null);
    };

    getName = () => {
        return "InfectionTreatmentMonitoring";
    };

    inputChangedHandler = (event, formElementId) => {
        if (formElementId === "DateOfDiagnosis") {
            let isMatch = true;

            if (event?.length > 0) {
                isMatch = !!event.match("^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$");
            }

            if (!event) {
                this.setState({DateOfDiagnosis: event, DateOfDiagnosisHelper: "Field must contain a value.", DateOfDiagnosisError: true});
            } else if (isMatch === false) {
                this.setState({DateOfDiagnosis: event, DateOfDiagnosisHelper: "Date improperly formatted.", DateOfDiagnosisError: true});
            } else {
                this.setState({DateOfDiagnosis: event, DateOfDiagnosisHelper: null, DateOfDiagnosisError: false});
            }
        } else if (formElementId === "BacterialInfectionConfirmed") {
            this.setState({BacterialInfectionConfirmed: !this.state.BacterialInfectionConfirmed});
        } else if (formElementId === "FungalInfectionConfirmed") {
            this.setState({FungalInfectionConfirmed: !this.state.FungalInfectionConfirmed});
        } else if (formElementId === "TypeOfInfectionExitSite") {
            this.setState({TypeOfInfectionExitSite: !this.state.TypeOfInfectionExitSite});
        } else if (formElementId === "TypeOfInfectionTunnel") {
            this.setState({TypeOfInfectionTunnel: !this.state.TypeOfInfectionTunnel});
        } else if (formElementId === "TypeOfInfectionPeritonitis") {
            this.setState({TypeOfInfectionPeritonitis: !this.state.TypeOfInfectionPeritonitis});
        } else if (formElementId === "Note") {
            let lengthWarningText = null;
            if (event.target.value.length === 1000) {
                lengthWarningText = "1000 characters allowed";
            }

            this.setState({Note: event.target.value, lengthWarningText: lengthWarningText});
        }
    };

    cancel1Handler = (event, reason) => {
        event.preventDefault();

        if (this.state.disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (this.state.disableEscapeKeyDown && reason === "escapeKeyDown") {
            return false;
        }

        this.setState({showConfirmation: true});
    };

    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    render() {
        const symptomAlert = (<Typography className={this.props.classes.alertText}>You are about to diagnose this patient while there are no symptoms logged for this episode.</Typography>);
        const labAlert = (<Typography className={this.props.classes.alertText}>You are about to diagnose this patient while there are no labs ordered for this episode.</Typography>);
        const labEmptyAlert = (<Typography className={this.props.classes.alertText}>We noticed that you have ordered labs for this patient but have not entered the results.</Typography>);
        const medicationAlert = (<Typography className={this.props.classes.alertText}>You are about to diagnose this patient while there is no medication logged for this episode.</Typography>);

        let alerts = [];
        if (process.env.REACT_APP_VARIANT === "cds") {
            if (this.state.symptomAlert === true) {
                alerts.push(
                    <Grid key="aa" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                            <div>
                                <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                            </div>
                            <div style={{marginTop: "0px"}}>
                                {symptomAlert}
                            </div>
                        </div>
                    </Grid>,
                );
            }
            if (this.state.labAlert === true) {
                alerts.push(
                    <Grid key="bb" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                            <div>
                                <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                            </div>
                            <div style={{marginTop: "0px"}}>
                                {labAlert}
                            </div>
                        </div>
                    </Grid>,
                );
            }
            if (this.state.labEmptyAlert === true) {
                alerts.push(
                    <Grid key="cc" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                            <div>
                                <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                            </div>
                            <div style={{marginTop: "0px"}}>
                                {labEmptyAlert}
                            </div>
                        </div>
                    </Grid>,
                );
            }
            if (this.state.medicationAlert === true) {
                alerts.push(
                    <Grid key="dd" item style={{margin: "0 8px 5px 7px", height: "auto", backgroundColor: "rgba(253, 231, 118, 0.5)"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                            <div>
                                <Error style={{width: "28px", height: "28px", fill: "#f1c40f", margin: "0px 10px 0 5px"}} />
                            </div>
                            <div style={{marginTop: "0px"}}>
                                {medicationAlert}
                            </div>
                        </div>
                    </Grid>,
                );
            }
        }

        let escalationContent = null;
        if (process.env.REACT_APP_VARIANT === "cds") {
            let assistiveText = null;
            if (this.state.lengthWarningText !== null) {
                assistiveText = <Typography className={this.props.classes.assistiveText}>{this.state.lengthWarningText}</Typography>;
            }

            escalationContent = (
                <React.Fragment>
                    <Grid key={3} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Does this patient have a confirmed infection?</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.BacterialInfectionConfirmed} onChange={(event) => this.inputChangedHandler(event, "BacterialInfectionConfirmed")} name="bacterialInfectionConfirmed" />}
                                    label="Yes - Bacterial"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.FungalInfectionConfirmed} onChange={(event) => this.inputChangedHandler(event, "FungalInfectionConfirmed")} name="fungalInfectionConfirmed" />}
                                    label="Yes - Fungal"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid key={4} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Type of infection?</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.TypeOfInfectionExitSite} onChange={(event) => this.inputChangedHandler(event, "TypeOfInfectionExitSite")} name="bacterialInfectionConfirmed" />}
                                    label="Exit site infection"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.TypeOfInfectionTunnel} onChange={(event) => this.inputChangedHandler(event, "TypeOfInfectionTunnel")} name="typeOfInfectionTunnel" />}
                                    label="Tunnel infection"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.TypeOfInfectionPeritonitis} onChange={(event) => this.inputChangedHandler(event, "TypeOfInfectionPeritonitis")} name="typeOfInfectionPeritonitis" />}
                                    label="Peritonitis"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid key={5} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            key="Note"
                            margin="dense"
                            InputLabelProps={{shrink: true}}
                            fullWidth={true}
                            id="Note"
                            label="Notes"
                            onChange={(event) => this.inputChangedHandler(event, "Note")}
                            value={this.state.note}
                            variant="outlined"
                            multiline={true}
                            rows={5}
                            inputProps={{maxLength: 1000}}
                        />
                        {assistiveText}
                    </Grid>
                    {alerts}
                </React.Fragment>
            );
        }

        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form onSubmit={e => e.preventDefault()} style={{width: "100%"}}>
                    <Grid key="id6" item container spacing={2}>
                        <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CCDatePicker
                                minDate={this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation").getAttributeValue("StartDatetime")}
                                required={true}
                                helperText={this.state.DateOfDiagnosisHelper}
                                error={this.state.DateOfDiagnosisError}
                                key="DateOfDiagnosis"
                                label="Date of diagnosis (MM/DD/20YY)"
                                id="DateOfDiagnosis"
                                onChange={(event) => this.inputChangedHandler(event, "DateOfDiagnosis")}
                                value={this.state.DateOfDiagnosis}
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        {escalationContent}
                    </Grid>
                </form>
            </Grid>
        );

        let content = (
            <React.Fragment>
                {modalContent}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Infection diagnosis</DialogTitle>
                    <DialogContent>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        <CCButton
                            variant="green"
                            animate={this.state.updateBackgroundOperation === true}
                            onClick={this.submitHandler}>
                            Save
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(PatientEscalationInfectionConfirmed),
);