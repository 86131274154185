import {withAppContext} from "../../../App";
import DeviceListBaseApplet from "./DeviceListBaseApplet";

class DeviceListStandardApplet extends DeviceListBaseApplet {

    constructor(props) {
        super(props, {
            name: "DeviceListStandardApplet",
            title: "Devices",
        });
    }
}

export default withAppContext(DeviceListStandardApplet);
