import RelationshipMetadata from "../RelationshipMetadata";

class PatientAlertRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "PatientAlert";
        this.parentEnterpriseComponent = "Patient";
        this.childEnterpriseComponent = "PatientAlert";
        this.sourceAttribute = "ClinicUSID";
        this.destinationAttribute = "ClinicUSID";
    }
}

export default PatientAlertRelationshipMetadata;