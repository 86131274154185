import RelationshipMetadata from "../RelationshipMetadata";

class EscalationSymptomRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "EscalationSymptom";
        this.parentEnterpriseComponent = "Escalation";
        this.childEnterpriseComponent = "EscalationSymptom";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "EscalationUSID";
    }
}

export default EscalationSymptomRelationshipMetadata;