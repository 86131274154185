import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class PatientEscalationCommunicationEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "EscalationCommunication";

        this.attributes["PatientEscalationUSID"] = new EnterpriseComponentAttributeMetadata(
            "PatientEscalationUSID", // name
            false,         // NoCopy
            true,          // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            36,            // length
            null,          // minLength
            null,          // pattern
            "UUID",        // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["CommunicationPurpose"] = new EnterpriseComponentAttributeMetadata(
            "CommunicationPurpose", // name
            false,         // NoCopy
            true,          // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            30,            // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["CommunicationType"] = new EnterpriseComponentAttributeMetadata(
            "CommunicationType", // name
            false,         // NoCopy
            true,          // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            20,            // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["Destination"] = new EnterpriseComponentAttributeMetadata(
            "Destination", // name
            false,         // NoCopy
            true,          // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            100,           // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["UserUSID"] = new EnterpriseComponentAttributeMetadata(
            "UserUSID", // name
            false,         // NoCopy
            true,          // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            36,            // length
            null,          // minLength
            null,          // pattern
            "UUID",        // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["RecipientFirstName"] = new EnterpriseComponentAttributeMetadata(
            "RecipientFirstName", // name
            false,         // NoCopy
            true,          // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            100,            // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["RecipientLastName"] = new EnterpriseComponentAttributeMetadata(
            "RecipientLastName", // name
            false,         // NoCopy
            true,          // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            100,            // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["MessageURL"] = new EnterpriseComponentAttributeMetadata(
            "MessageURL", // name
            false,         // NoCopy
            true,          // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            100,            // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["RecipientFullName"] = new EnterpriseComponentAttributeMetadata(
            "RecipientFullName", // name
            false,         // NoCopy
            true,          // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            100,           // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["Acknowledged"] = new EnterpriseComponentAttributeMetadata(
            "Acknowledged", // name
            false,         // NoCopy
            false,         // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            null,          // length
            null,          // minLength
            null,          // pattern
            "Boolean",     // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["AcknowledgedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "AcknowledgedDatetime", // name
            false,         // NoCopy
            true,          // NoUpdate
            false,         // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            100,           // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["CommunicationDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CommunicationDatetime", // name
            false,         // NoCopy
            true,          // NoUpdate
            false,         // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            100,           // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["MessageID"] = new EnterpriseComponentAttributeMetadata(
            "MessageID", // name
            false,         // NoCopy
            true,          // NoUpdate
            false,         // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            100,           // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false,         // NoCopy
            true,          // NoUpdate
            false,         // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            100,           // length
            null,          // minLength
            null,          // pattern
            "String",      // Type
            null,          // default value
            false,          // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID",        // name
            false,         // NoCopy
            true,          // NoUpdate
            true,          // Required
            false,         // RequiredForQuery
            null,          // Picklist
            null,          // Scale
            null,          // Precision
            36,            // length
            null,          // minLength
            null,          // pattern
            "UUID",        // Type
            null,          // default value
            false,          // computed
        );
    }
}

export default PatientEscalationCommunicationEnterpriseComponentMetadata;