import {withAppContext} from "../../../App";
import PatientEscalationHemodialysis from "../../../components/PatientEscalationHemodialysis";

import Applet from "../Applet";

class PatientEscalationHemodialysisApplet extends Applet {
    state = {
        Name: "PatientEscalationHemodialysisApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <PatientEscalationHemodialysis applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
    }
}

export default withAppContext(PatientEscalationHemodialysisApplet);
