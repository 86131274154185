import AbstractMetadata from "../AbstractMetadata";

class EnterpriseComponentVisibilityModeMetadata extends AbstractMetadata {
    constructor(fName, fVisibilityAttribute) {
        super();

        this.fName = fName;
        this.fVisibilityAttribute = fVisibilityAttribute;
    };

    getName = () => {
        return this.fName;
    };

    getVisibilityAttribute = () => {
        return this.fVisibilityAttribute;
    };

}

export default EnterpriseComponentVisibilityModeMetadata;
