import React from "react";
import CloudcathTable from "../../../../src/components/CloudcathTable";
import Applet from "../Applet";

class DeviceListBaseApplet extends Applet {

    constructor(props, {name, title}) {
        super(props);
        this.name = name;
        this.title = title;
        this.state = {
            Name: this.name,
            PageSize: {
                "default": 10,
                "xs": 3,
                "sm": 3,
                "md": 10,
                "lg": 10,
                "lx": 10,
            },
            Selectors: {
                "Refresh": this.refreshHandler,
                "Select": this.selectHandler,
            },
            Placement: {
                "xl": [
                    {
                        id: "SerialNumber",
                    },
                    {
                        id: "CloudCathID",
                    },
                    {
                        id: "PatientName",
                    },
                    {
                        id: "LatestFirmwareVersion",
                    },
                    {
                        id: "LastTurbidityValueDatetime",
                    },
                    {
                        id: "ReturnInitiated",
                    },
                ],
            },
            Columns: {
                "SerialNumber": {
                    id: "SerialNumber",
                    width: 200,
                    cellRenderer: "Device Serial Number Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Serial #",
                },
                "CloudCathID": {
                    id: "CloudCathID",
                    width: 175,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "CloudCath ID",
                },
                "PatientName": {
                    id: "PatientName",
                    width: 175,
                    cellRenderer: "Device Patient Name Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Patient Name",
                },
                "LatestFirmwareVersion": {
                    id: "LatestFirmwareVersion",
                    width: 150,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Firmware Version",
                },
                "LastTurbidityValueDatetime": {
                    id: "LastTurbidityValueDatetime",
                    width: 200,
                    cellRenderer: "Device Last Turbidity Reading Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Last Turbidity Recording",
                },
                "ReturnInitiated": {
                    id: "ReturnInitiated",
                    width: 100,
                    cellRenderer: "Standard Single Cell",
                    header: "enabled",
                    headerRenderer: "Standard Single Header",
                    headerTitle: "Return Initiated",
                },
            },
            Sort: [],
        };

        if (this.view.props.appContext.sessionContext.actStudy === true) {
            this.state.Columns["PatientIdentifier"] = {
                id: "PatientIdentifier",
                width: 135,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "MRN/Patient ID",
            };

            this.state.Placement.xl.splice(1, 0, {
                id: "PatientIdentifier",
            });
            let a = 1;
        }
    }

    render() {
        return <CloudcathTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title={this.title}
                               enterpriseComponent={this.getEnterpriseComponent()}
                               selectors={this.state.Selectors}
                               sort={this.state.Sort} />;
    }
}

export default DeviceListBaseApplet;
