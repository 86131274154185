import moment from "moment";
import CloudcathAxios from "../../../util/CloudcathAxios";
import {convertToUTC, V1PatientEscalationNoteIDOCToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class EscalationNoteEnterpriseComponent extends EnterpriseComponent {
    performSingleRecordRefresh = (primaryKey) => {
        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_escalation_notes",
            json: true,
            params: {
                "USID": primaryKey,
            },
            headers: {
                "idoc-request": "v1.PatientEscalationNote",
            },
        };
        this.notifySingleRecordRefreshStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.refreshEnterpriseComponentSuccess(V1PatientEscalationNoteIDOCToArray(response.data));
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    performQuery = (start, pageSize, foreignKey) => {
        let params = {};
        if (foreignKey === null) {
            params = {
                "Limit": pageSize,
                "Offset": start,
            };
        } else {
            params = {
                "Limit": pageSize,
                "Offset": start,
                "PatientEscalationUSID": foreignKey,
            };
        }

        params["Sort"] = this.getFormattedSortSpecification();

        let keys = Object.keys(this.getSearchSpecification());
        for (let i = 0; i < keys.length; i++) {
            let value = this.getSearchSpecification()[keys[i]];

            // These are the only searchable parameters supported by the API
            if (keys[i] === "USID") {
                params["USID"] = value;
            }
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/patient_escalation_notes",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.PatientEscalationNote",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1PatientEscalationNoteIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let note = {};

        let row = this.getBuffer()[this.getRecordPointer()];

        let patientEscalationEnterpriseComponent = this.getEnterpriseObject().getEnterpriseComponent("Escalation");

        note["USID"] = row["USID"];
        note["Note"] = row["Note"];
        note["PatientEscalationUSID"] = patientEscalationEnterpriseComponent.getBuffer()[patientEscalationEnterpriseComponent.getRecordPointer()]["USID"];
        note["EventDatetime"] = convertToUTC(moment.now());
        this.setAttributeValue("EventDatetimeDisplay", moment(row["EventDatetime"]).local().format("HH:mm MMM DD, YYYY"));

        let attachment_ec = this.getEnterpriseObject().getEnterpriseComponent("NoteAttachment");
        if (attachment_ec.getBuffer().length > 0) {
            note["ListOfAttachment"] = {};
            note["ListOfAttachment"]["Attachment"] = [];

            for (let i = 0; i < attachment_ec.getBuffer().length; i++) {
                if (attachment_ec.getBuffer()[i]["ParentUSID"] === this.getAttributeValue("USID") && attachment_ec.getBuffer()[i]["__operation"] === "new") {
                    let attachment = {
                        "USID": attachment_ec.getBuffer()[i]["USID"],
                        "ParentUSID": attachment_ec.getBuffer()[i]["ParentUSID"],
                        "ParentType": attachment_ec.getBuffer()[i]["ParentType"],
                        "FileName": attachment_ec.getBuffer()[i]["FileName"],
                        "FileType": attachment_ec.getBuffer()[i]["FileType"],
                        "FileSize": attachment_ec.getBuffer()[i]["FileSize"],
                        "Payload": attachment_ec.getBuffer()[i]["Payload"],
                    };
                    note["ListOfAttachment"]["Attachment"].push(attachment);
                }
            }
        }

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.PatientEscalationNote";
        payload.CloudcathMessage.PatientEscalationNote = note;

        return payload;
    };

    performInsert = () => {
        let payload = this.enterpriseComponentPostIDOC();

        let options = {
            method: "POST",
            url: "cloudcath/v1/patient_escalation_notes",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.PatientEscalationNote",
                "idoc-response": "v1.PatientEscalationNote",
            },
        };
        this.notifyInsertStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1PatientEscalationNoteIDOCToArray(response.data));
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };
}

export default EscalationNoteEnterpriseComponent;