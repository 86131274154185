import React from "react";
import {withAppContext} from "../../../App";
import AddUser from "../../../components/AddUser";
import Applet from "../Applet";
import UserDetailApplet from "./UserDetailApplet";

class AddUserApplet extends Applet {
    state = {
        Name: "AddUserApplet",
        Step: "1",
        Username: "",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        if (this.state.Step === "1") {
            return <AddUser applet={this} enterpriseComponent={this.getEnterpriseComponent()} />;
        } else {
            return <UserDetailApplet enterpriseComponent={this.props.userEnterpriseComponent} view={this.getView()} availableModes={["Edit", "New", "View"]} defaultMode="New" Username={this.state.Username} />;
        }
    }
}

export default withAppContext(AddUserApplet);
