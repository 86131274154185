import {withAppContext} from "../../../App";
import UserReactivate from "../../../components/UserReactivate";

import Applet from "../Applet";

class UserReactivateApplet extends Applet {
    state = {
        Name: "UserReactivateApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <UserReactivate applet={this}
                               enterpriseComponent={this.getEnterpriseComponent()}
                               patientEnterpriseComponent={this.props.patientEnterpriseComponent} />;
    }
}

export default withAppContext(UserReactivateApplet);
