import AbstractMetadata from "../AbstractMetadata";

class RelationshipMetadata extends AbstractMetadata {
    constructor() {
        super();

        this.name = null;
        this.parentEnterpriseComponent = null;
        this.childEnterpriseComponent = null;
        this.sourceAttribute = null;
        this.destinationAttribute = null;
        this.invalidateChildren = null;
    }

    getParentEnterpriseComponent = () => {
        return this.parentEnterpriseComponent;
    };

    getChildEnterpriseComponent = () => {
        return this.childEnterpriseComponent;
    };

    getSourceAttribute = () => {
        return this.sourceAttribute;
    };

    getDestinationAttribute = () => {
        return this.destinationAttribute;
    };

    getInvalidateChildren = () => {
        return this.invalidateChildren;
    };

}

export default RelationshipMetadata;
