import {Button, Menu, MenuItem, Typography} from "@material-ui/core";
import {LocalHospital} from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import SettingsIcon from "@material-ui/icons/Settings";
import React, {useRef, useState} from "react";
import ReactDOM from "react-dom";
import {useHistory} from "react-router-dom";
import {useAppContext} from "../../App";
import LogoutSvg from "../../assets/icon-logout-gray.svg";
import ClinicView from "../../eoec/view/impl/ClinicView";
import * as Analytics from "../../util/Analytics";
import * as userAgent from "../../util/UserAgent";
import ExtraSettingsDialog from "../debug/ExtraSettingsDialog";
import ChangePassDialog from "../user/ChangePassDialog";
import css_self from "./css/ProfileMenu.module.scss";
import "./css/ProfileMenu.scss";

export default function ProfileMenu() {

    const userMenuAnchor = useRef(undefined);
    const [userMenuOpen, setUserMenuOpen] = useState(false);

    const history = useHistory();

    const {
        sessionContext,
        showDialogComponent,
        showViewComponent,
    } = useAppContext();

    function displayLastName(value) {
        return value.length <= 16 ? value : `${value.slice(0, 16)}\u2026`;
    }

    return (
        <>
            <Button
                {...{"data-cc-component": "UserMenu"}}
                {...{"data-active": userMenuOpen}}
                ref={userMenuAnchor}
                onClick={async () => {
                    setUserMenuOpen(true);
                    Analytics.record("Profile Menu Opened");
                }}
            >
                {sessionContext.firstName[0]}.{" "}
                {displayLastName(sessionContext.lastName)}
                <AccountCircleIcon className={css_self.ProfileIcon} />
                <ExpandMoreIcon className={css_self.CaretIcon} />
            </Button>
            <Menu
                anchorEl={userMenuAnchor.current}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: -3,
                    horizontal: "right",
                }}
                open={userMenuOpen}
                onClose={async () => setUserMenuOpen(false)}
                TransitionProps={{
                    onExited: async () => setUserMenuOpen(false),
                }}
                MenuListProps={{
                    ...{"data-cc-component": "UserMenu"},
                    disablePadding: true,
                    style: userMenuAnchor.current
                        ? {
                            minWidth: ReactDOM.findDOMNode(userMenuAnchor.current)
                                .clientWidth,
                        }
                        : undefined,
                }}
                PaperProps={{
                    elevation: 2,
                }}
            >
                {!userAgent.isMobile() ? (
                    <MenuItem
                        divider
                        onClick={async () => {
                            Analytics.record("Profile Menu Option", {
                                Option: "Change Password",
                            });
                            setUserMenuOpen(false);
                            showDialogComponent(ChangePassDialog);
                        }}
                    >
                        <EditIcon />
                        <Typography variant="inherit">Change Password</Typography>
                    </MenuItem>
                ) : undefined}
                {!userAgent.isMobile() && sessionContext.userRole === "Admin" ? (
                    <MenuItem
                        divider={true}
                        onClick={async () => {
                            Analytics.record("Profile Menu Option", {
                                Option: "Edit Clinic",
                            });
                            setUserMenuOpen(false);
                            showViewComponent(ClinicView);
                        }}
                    >
                        <LocalHospital />
                        <Typography variant="inherit">Settings</Typography>
                    </MenuItem>
                ) : undefined}
                {!userAgent.isMobile() ? (
                    <MenuItem
                        divider
                        onClick={async () => {
                            Analytics.record("Profile Menu Option", {
                                Option: "Instruction Download",
                            });
                        }}
                    >
                        <SaveAltIcon />
                        <a style={{height: "26px", position: "relative", top: "-11px", textDecoration: "none"}} href={process.env.REACT_APP_API_URL + "/cloudcath/v1/downloads?USID=" + "363350ac-c578-490e-a3ff-8bfdeacd23df"} target="_blank"><Typography variant="inherit">Healthcare Provider <br />Instructions for Use</Typography></a>
                    </MenuItem>
                ) : undefined}
                {!userAgent.isMobile() && ["master", "sp2", "hf2"].includes(process.env.REACT_APP_CLOUDCATH_ENV) ? (
                    <MenuItem
                        divider={true}
                        onClick={async () => {
                            Analytics.record("Profile Menu Option", {
                                Option: "Extra Settings",
                            });
                            setUserMenuOpen(false);
                            showDialogComponent(ExtraSettingsDialog);
                        }}
                    >
                        <SettingsIcon />
                        <Typography variant="inherit">Extra Settings</Typography>
                    </MenuItem>
                ) : undefined}
                <MenuItem
                    onClick={async () => {
                        Analytics.record("Profile Menu Option", {
                            Option: "Logout",
                        });
                        Analytics.record("Logging Out", {
                            Reason: "Profile Menu",
                            Voluntary: true,
                        });
                        history.push("/logout");
                    }}
                >
                    <img src={LogoutSvg} style={{marginRight: 8}} alt="icon-logout-gray.svg" />
                    <Typography variant="inherit">Logout</Typography>
                </MenuItem>
                <MenuItem disabled className={css_self.VersionSection}>
                    <Typography component="div" variant="inherit">Model Number: 04PD001</Typography>
                    <Typography component="div" variant="inherit" style={{marginTop: 5}}>Part Number: SWS-00572</Typography>
                    <Typography component="div" variant="inherit" style={{marginTop: 5}}>Version: {process.env.REACT_APP_VERSION}</Typography>
                    <Typography component="div" variant="inherit" style={{marginTop: 5}}>Unique Device Identifier:<br />00860006810238-{process.env.REACT_APP_VERSION}</Typography>
                </MenuItem>
            </Menu>
        </>
    );
}
