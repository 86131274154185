import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class EscalationLabEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "EscalationLab";
        this.defaultCacheSize = 50;

        this.attributes["EscalationUSID"] = new EnterpriseComponentAttributeMetadata(
            "EscalationUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Result"] = new EnterpriseComponentAttributeMetadata(
            "Result", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1000,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Type"] = new EnterpriseComponentAttributeMetadata(
            "Type", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            40,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Note"] = new EnterpriseComponentAttributeMetadata(
            "Note", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1000,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AssociatedNote"] = new EnterpriseComponentAttributeMetadata(
            "AssociatedNote", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1000,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Name"] = new EnterpriseComponentAttributeMetadata(
            "Name", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Leukocytes"] = new EnterpriseComponentAttributeMetadata(
            "Leukocytes", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,  // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Neutrophilis"] = new EnterpriseComponentAttributeMetadata(
            "Neutrophilis", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,  // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Monocytes"] = new EnterpriseComponentAttributeMetadata(
            "Monocytes", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,  // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Lymphocytes"] = new EnterpriseComponentAttributeMetadata(
            "Lymphocytes", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,  // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Eosinophils"] = new EnterpriseComponentAttributeMetadata(
            "Eosinophils", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,  // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["RBC"] = new EnterpriseComponentAttributeMetadata(
            "RBC", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,  // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ColorAppearance"] = new EnterpriseComponentAttributeMetadata(
            "ColorAppearance", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,  // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DwellVolume"] = new EnterpriseComponentAttributeMetadata(
            "DwellVolume", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            6,  // Scale
            0,  // Precision
            null,  // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DwellTime"] = new EnterpriseComponentAttributeMetadata(
            "DwellTime", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,  // length
            null,  // minLength
            "^([0-9]{2}):([0-9]{2})$",  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EffluentCollectedBy"] = new EnterpriseComponentAttributeMetadata(
            "EffluentCollectedBy", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "EffluentCollectedBy",  // Picklist
            null,  // Scale
            null,  // Precision
            10,  // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CultureSource"] = new EnterpriseComponentAttributeMetadata(
            "CultureSource", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "LabCultureSource",  // Picklist
            null,  // Scale
            null,  // Precision
            30,  // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CultureSourceOther"] = new EnterpriseComponentAttributeMetadata(
            "CultureSourceOther", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,  // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnteredByUSID"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByUSID", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "AllActiveUsersOptional",  // Picklist
            null,  // Scale
            null,  // Precision
            36,  // length
            null,  // minLength
            null,  // pattern
            "USID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnteredByFirstName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByFirstName", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,  // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EnteredByLastName"] = new EnterpriseComponentAttributeMetadata(
            "EnteredByLastName", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,  // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SampleCollectionDate"] = new EnterpriseComponentAttributeMetadata(
            "SampleCollectionDate", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            "^[2][0]\\d{2}[\\-](0?[1-9]|1[012])[\\-](0?[1-9]|[12][0-9]|3[01])$",  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SampleCollectionDateDisplay"] = new EnterpriseComponentAttributeMetadata(
            "SampleCollectionDateDisplay", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["GramStain"] = new EnterpriseComponentAttributeMetadata(
            "GramStain", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "GramStain",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CultureResult"] = new EnterpriseComponentAttributeMetadata(
            "CultureResult", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "CultureResult",  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CulturePathogen"] = new EnterpriseComponentAttributeMetadata(
            "CulturePathogen", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityAntimicrobial1"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityAntimicrobial1", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityPathogen1"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityPathogen1", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Susceptibility",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityAntimicrobial2"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityAntimicrobial2", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityPathogen2"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityPathogen2", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Susceptibility",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityAntimicrobial3"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityAntimicrobial3", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityPathogen3"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityPathogen3", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Susceptibility",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityAntimicrobial4"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityAntimicrobial4", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityPathogen4"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityPathogen4", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Susceptibility",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityAntimicrobial5"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityAntimicrobial5", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityPathogen5"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityPathogen5", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Susceptibility",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityAntimicrobial6"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityAntimicrobial6", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityPathogen6"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityPathogen6", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Susceptibility",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityAntimicrobial7"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityAntimicrobial7", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityPathogen7"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityPathogen7", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Susceptibility",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityAntimicrobial8"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityAntimicrobial8", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityPathogen8"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityPathogen8", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Susceptibility",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityAntimicrobial9"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityAntimicrobial9", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityPathogen9"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityPathogen9", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Susceptibility",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityAntimicrobial10"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityAntimicrobial10", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            60,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SusceptibilityPathogen10"] = new EnterpriseComponentAttributeMetadata(
            "SusceptibilityPathogen10", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            "Susceptibility",  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
    }
}

export default EscalationLabEnterpriseComponentMetadata;