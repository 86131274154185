import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class EscalationEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "Escalation";
        this.defaultCacheSize = 50;

        this.attributes["PatientUSID"] = new EnterpriseComponentAttributeMetadata(
            "PatientUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EscalationStatus"] = new EnterpriseComponentAttributeMetadata(
            "EscalationStatus", // name
            false, // NoCopy
            false, // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            40,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PreviousEscalationStatus"] = new EnterpriseComponentAttributeMetadata(
            "PreviousEscalationStatus", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            40,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EscalationOrigin"] = new EnterpriseComponentAttributeMetadata(
            "EscalationOrigin", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["HighestTurbidityScore"] = new EnterpriseComponentAttributeMetadata(
            "HighestTurbidityScore", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["HighestTurbidityScoreDatetime"] = new EnterpriseComponentAttributeMetadata(
            "HighestTurbidityScoreDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["TurbidityNotificationLevel"] = new EnterpriseComponentAttributeMetadata(
            "TurbidityNotificationLevel", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["TurbidityNotificationLevelDatetime"] = new EnterpriseComponentAttributeMetadata(
            "TurbidityNotificationLevelDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AcknowledgedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "AcknowledgedDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["AcknowledgedByUSID"] = new EnterpriseComponentAttributeMetadata(
            "AcknowledgedByUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DiagnosedByUSID"] = new EnterpriseComponentAttributeMetadata(
            "DiagnosedByUSID", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["StateDatetime"] = new EnterpriseComponentAttributeMetadata(
            "StateDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EndDatetime"] = new EnterpriseComponentAttributeMetadata(
            "EndDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DeviceUSID"] = new EnterpriseComponentAttributeMetadata(
            "DeviceUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DeviceSerialNumber"] = new EnterpriseComponentAttributeMetadata(
            "DeviceSerialNumber", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Duration"] = new EnterpriseComponentAttributeMetadata(
            "Duration", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Number", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SpokenWithPatient"] = new EnterpriseComponentAttributeMetadata(
            "SpokenWithPatient", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SpokenWithPatientReadOnly"] = new EnterpriseComponentAttributeMetadata(
            "SpokenWithPatientReadOnly", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["PatientHospitalized"] = new EnterpriseComponentAttributeMetadata(
            "PatientHospitalized", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["PatientHospitalizedReadOnly"] = new EnterpriseComponentAttributeMetadata(
            "PatientHospitalizedReadOnly", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["ScheduledClinicVisit"] = new EnterpriseComponentAttributeMetadata(
            "ScheduledClinicVisit", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ScheduledClinicVisitReadOnly"] = new EnterpriseComponentAttributeMetadata(
            "ScheduledClinicVisitReadOnly", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["EscalationNote"] = new EnterpriseComponentAttributeMetadata(
            "EscalationNote", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            1000,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["EscalationNoteType"] = new EnterpriseComponentAttributeMetadata(
            "EscalationNoteType", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["BacterialInfectionConfirmed"] = new EnterpriseComponentAttributeMetadata(
            "BacterialInfectionConfirmed", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["BacterialInfectionConfirmedReadOnly"] = new EnterpriseComponentAttributeMetadata(
            "BacterialInfectionConfirmedReadOnly", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["FungalInfectionConfirmed"] = new EnterpriseComponentAttributeMetadata(
            "FungalInfectionConfirmed", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["FungalInfectionConfirmedReadOnly"] = new EnterpriseComponentAttributeMetadata(
            "FungalInfectionConfirmedReadOnly", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["TypeOfInfectionExitSite"] = new EnterpriseComponentAttributeMetadata(
            "TypeOfInfectionExitSite", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["TypeOfInfectionExitSiteReadOnly"] = new EnterpriseComponentAttributeMetadata(
            "TypeOfInfectionExitSiteReadOnly", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["TypeOfInfectionTunnel"] = new EnterpriseComponentAttributeMetadata(
            "TypeOfInfectionTunnel", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["TypeOfInfectionTunnelReadOnly"] = new EnterpriseComponentAttributeMetadata(
            "TypeOfInfectionTunnelReadOnly", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["TypeOfInfectionPeritonitis"] = new EnterpriseComponentAttributeMetadata(
            "TypeOfInfectionPeritonitis", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["TypeOfInfectionPeritonitisReadOnly"] = new EnterpriseComponentAttributeMetadata(
            "TypeOfInfectionPeritonitisReadOnly", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["CatheterRemoved"] = new EnterpriseComponentAttributeMetadata(
            "CatheterRemoved", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CatheterRemovedReadOnly"] = new EnterpriseComponentAttributeMetadata(
            "CatheterRemovedReadOnly", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["CatheterReplaced"] = new EnterpriseComponentAttributeMetadata(
            "CatheterReplaced", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CatheterReplacedReadOnly"] = new EnterpriseComponentAttributeMetadata(
            "CatheterReplacedReadOnly", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["HospitalizationDate"] = new EnterpriseComponentAttributeMetadata(
            "HospitalizationDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["HemodialysisDate"] = new EnterpriseComponentAttributeMetadata(
            "HemodialysisDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["TransplantDate"] = new EnterpriseComponentAttributeMetadata(
            "TransplantDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DeactivationDate"] = new EnterpriseComponentAttributeMetadata(
            "DeactivationDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ExpirationDate"] = new EnterpriseComponentAttributeMetadata(
            "ExpirationDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CellCountStatus"] = new EnterpriseComponentAttributeMetadata(
            "CellCountStatus", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["CultureStatus"] = new EnterpriseComponentAttributeMetadata(
            "CultureStatus", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            true,   // computed
        );
        this.attributes["InfectionDiagnosisDate"] = new EnterpriseComponentAttributeMetadata(
            "InfectionDiagnosisDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["NonInfectionDiagnosisDate"] = new EnterpriseComponentAttributeMetadata(
            "NonInfectionDiagnosisDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["InfectionResolutionDate"] = new EnterpriseComponentAttributeMetadata(
            "InfectionResolutionDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["DischargeDate"] = new EnterpriseComponentAttributeMetadata(
            "DischargeDate", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            10,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["HospitalizationOutcome"] = new EnterpriseComponentAttributeMetadata(
            "HospitalizationOutcome", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["HospitalizationDiagnosis"] = new EnterpriseComponentAttributeMetadata(
            "HospitalizationDiagnosis", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            30,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["UnassignDevice"] = new EnterpriseComponentAttributeMetadata(
            "UnassignDevice", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CreatedDatetime"] = new EnterpriseComponentAttributeMetadata(
            "CreatedDatetime", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Datetime", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE4731"] = new EnterpriseComponentAttributeMetadata(
            "CDSE4731", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE4732"] = new EnterpriseComponentAttributeMetadata(
            "CDSE4732", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE4733"] = new EnterpriseComponentAttributeMetadata(
            "CDSE4733", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE4734"] = new EnterpriseComponentAttributeMetadata(
            "CDSE4734", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE4735"] = new EnterpriseComponentAttributeMetadata(
            "CDSE4735", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE4736"] = new EnterpriseComponentAttributeMetadata(
            "CDSE4736", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE4737"] = new EnterpriseComponentAttributeMetadata(
            "CDSE4737", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE4738"] = new EnterpriseComponentAttributeMetadata(
            "CDSE4738", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE4739a"] = new EnterpriseComponentAttributeMetadata(
            "CDSE4739a", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE4739b"] = new EnterpriseComponentAttributeMetadata(
            "CDSE4739b", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE47310"] = new EnterpriseComponentAttributeMetadata(
            "CDSE47310", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["CDSE47311"] = new EnterpriseComponentAttributeMetadata(
            "CDSE47311", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            2,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Locked"] = new EnterpriseComponentAttributeMetadata(
            "Locked", // name
            false, // NoCopy
            true,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            null,    // length
            null,  // minLength
            null,  // pattern
            "Boolean", // Type
            null,   // default value
            true,   // computed
        );
    }
}

export default EscalationEnterpriseComponentMetadata;