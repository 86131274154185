import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import styles from "../theme/styles";
import CCButton from "./common/CCButton";

class PatientDeactivate extends Component {
    state = {
        updateBackgroundOperation: false,
        Note: "",
        showConfirmation: false,
        UnassignDevice: false,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;
    };

    componentDidMount() {
        this.applet.setMode("Edit");

        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.state.UnassignDevice === true) {
            this.applet.getEnterpriseComponent().setAttributeValue("DeviceUSID", null);
            this.applet.getEnterpriseComponent().setAttributeValue("DeviceSerialNumber", null);
            this.applet.getEnterpriseComponent().setAttributeValue("DeviceAssignmentStatus", null);
            this.applet.getEnterpriseComponent().setAttributeValue("DeviceAssignmentDatetime", null);
        }

        this.applet.getEnterpriseComponent().setAttributeValue("PatientState", "Inactive");
        this.applet.save();
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = () => {
        this.setState({updateBackgroundOperation: false});

        this.applet.setMode("View");

        this.applet.getView().initiateAction(null);
    };

    handleUnassignDevice = (event) => {
        this.setState({UnassignDevice: event.target.value === "true"});
    };

    getName = () => {
        return "PatientDeactivate";
    };

    inputChangedHandler = (event, formElementId) => {
        if (formElementId === "PatientDeactivate") {
        } else {
            this.setState({Note: event.target.value});
        }
    };

    cancel1Handler = (event, reason) => {
        event.preventDefault();

        if (this.state.disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (this.state.disableEscapeKeyDown && reason === "escapeKeyDown") {
            return false;
        }

        this.setState({showConfirmation: true});
    };


    cancel2Handler = (event) => {
        event.preventDefault();
        this.setState({errors: {}, showConfirmation: false});
        this.applet.cancel();
    };

    render() {
        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form onSubmit={e => e.preventDefault()}>
                    <Grid key="id6" item container style={{width: "32em"}} spacing={4}>
                        <Grid key={1} item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={this.props.classes.addEpisodeText}>Are you sure you want to <b>deactivate</b> this patient?</Typography>
                        </Grid>
                    </Grid>
                    {this.applet.getEnterpriseComponent().getAttributeValue("DeviceUSID") !== null ? (
                        <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop: "10px"}}>
                            <FormControl component="fieldset" margin="dense" required={true}>
                                <FormLabel component="legend">Would you like to un-assign the CloudCath device from the patient?</FormLabel>
                                <RadioGroup aria-label="unassignDevice" name="ack1" value={this.state.UnassignDevice} onChange={this.handleUnassignDevice}>
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>) : null}
                </form>
            </Grid>
        );

        let content = (
            <React.Fragment>
                {modalContent}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Deactivate patient</DialogTitle>
                    <DialogContent style={{padding: "8px 16px"}}>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        {this.state.showConfirmation ? (
                            <CCButton variant="red" onClick={this.cancel2Handler}>
                                Don't Save
                            </CCButton>
                        ) : (
                            <CCButton variant="secondary" onClick={this.cancel1Handler}>
                                Cancel
                            </CCButton>
                        )}
                        <CCButton
                            variant="green"
                            animate={this.state.updateBackgroundOperation === true}
                            onClick={this.submitHandler}>
                            Save
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(PatientDeactivate),
);