import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {v4 as uuidv4} from "uuid";
import {withAppContext} from "../App";
import Picklist from "../eoec/picklist/Picklist";
import styles from "../theme/styles";
import {updateObject} from "../util/utility";
import CloudcathMultiSelect from "./CloudcathMultiSelect";
import CloudcathSelect from "./CloudcathSelect";
import CCButton from "./common/CCButton";

class PatientReactivate extends Component {
    state = {
        updateBackgroundOperation: false,
        Note: "",
        reactivateOption: false,

        controls: {
            AssignedPhysician: {
                label: "Physician",
                value: "",
                touched: false,
                helperText: null,
            },
            AssignedNurse: {
                label: "Nurse",
                value: "",
                touched: false,
                helperText: null,
            },
            SecondaryPhysician: {
                label: "Secondary Physician",
                value: [],
                touched: false,
            },
            SecondaryNurse: {
                label: "Secondary Nurse",
                value: [],
                touched: false,
            },
        },
        primaryNursePicklist: new Picklist("PrimaryNurse"),
        primaryPhysicianPicklist: new Picklist("PrimaryPhysician"),
        secondaryNursePicklist: new Picklist("PrimaryNurse"),
        secondaryPhysicianPicklist: new Picklist("PrimaryPhysician"),
        secondaryNurseKey: "secondaryNurseKey",
        secondaryPhysicianKey: "secondaryPhysician",
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
    };

    cancel1Handler = (event, reason) => {
        event.preventDefault();

        if (this.state.disableBackdropClick && reason === "backdropClick") {
            return false;
        }

        if (this.state.disableEscapeKeyDown && reason === "escapeKeyDown") {
            return false;
        }

        this.applet.cancel();
    };

    constructor(props) {
        super(props);
        this.applet = props.applet;
    };

    componentDidMount() {
        this.applet.setMode("Edit");

        this.applet.getEnterpriseComponent().addUpdateStartedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);

        const assignedPhysicianFormElement = updateObject(this.state.controls["AssignedPhysician"], {
            value: this.applet.getEnterpriseComponent().getAttributeValue("PrimaryDoctorUSID"),
            valid: true,
            touched: false,
        });
        const assignedNurseFormElement = updateObject(this.state.controls["AssignedNurse"], {
            value: this.applet.getEnterpriseComponent().getAttributeValue("PrimaryNurseUSID"),
            valid: true,
            touched: false,
        });

        let secondaryPhysicianValues = [];
        for (let i = 0; i < this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("SecondaryPhysician").getBuffer().length; i++) {
            secondaryPhysicianValues.push(this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("SecondaryPhysician").getBuffer()[i]["USID"]);
        }
        let secondaryNurseValues = [];
        for (let i = 0; i < this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("SecondaryNurse").getBuffer().length; i++) {
            secondaryNurseValues.push(this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("SecondaryNurse").getBuffer()[i]["USID"]);
        }

        const secondaryPhysicianFormElement = updateObject(this.state.controls["SecondaryPhysician"], {
            value: secondaryPhysicianValues,
            valid: true,
            touched: false,
        });
        const secondaryNurseFormElement = updateObject(this.state.controls["SecondaryNurse"], {
            value: secondaryNurseValues,
            valid: true,
            touched: false,
        });

        const updatedForm = updateObject(this.state.controls, {
            "AssignedPhysician": assignedPhysicianFormElement,
            "AssignedNurse": assignedNurseFormElement,
            "SecondaryPhysician": secondaryPhysicianFormElement,
            "SecondaryNurse": secondaryNurseFormElement,
        });

        this.setState({controls: updatedForm, formIsValid: false});
    };

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    };

    enterpriseComponentUpdateStarted = () => {
        this.setState({updateBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = () => {
        this.setState({updateBackgroundOperation: false});

        this.applet.setMode("View");

        this.applet.getView().initiateAction(null);
    };

    handleReactivateOption = (event) => {
        this.setState({reactivateOption: event.target.value === "true"});
    };

    getName = () => {
        return "PatientReactivate";
    };

    inputChangedHandler = (event, inputIdentifier) => {
        let displayValue = event.currentTarget.outerText;

        const updatedFormElement = updateObject(this.state.controls[inputIdentifier], {
            value: event.target.value,
            displayValue: displayValue,
            touched: true,
        });
        const updatedForm = updateObject(this.state.controls, {
            [inputIdentifier]: updatedFormElement,
        });

        this.setState({controls: updatedForm});
    };

    isFormValid = () => {
        let formIsValid = true;

        if ((this.state.controls.AssignedPhysician.value === "NULL" || !this.state.controls.AssignedPhysician.value) &&
            (this.state.controls.AssignedNurse.value === "NULL" || !this.state.controls.AssignedNurse.value)) {
            formIsValid = false;
        }
        return formIsValid;
    };

    nurseChangedHandler = (event) => {
        let displayValue = event.currentTarget.outerText;

        const updatedFormElement = updateObject(this.state.controls["AssignedNurse"], {
            value: event.target.value,
            displayValue: displayValue,
            touched: true,
        });
        const updatedForm = updateObject(this.state.controls, {
            "AssignedNurse": updatedFormElement,
        });

        this.applet.getEnterpriseComponent().setAttributeValue("PrimaryNurseUSID", event.target.value);
        this.applet.getEnterpriseComponent().setAttributeValue("PrimaryNurseFullName", displayValue);

        let picklist = new Picklist("PrimaryNurse");
        picklist.setExclusionList([event.target.value]);
        this.setState({controls: updatedForm, "secondaryNursePicklist": picklist, secondaryNurseKey: uuidv4()});
    };

    physicianChangedHandler = (event) => {
        let displayValue = event.currentTarget.outerText;

        const updatedFormElement = updateObject(this.state.controls["AssignedPhysician"], {
            value: event.target.value,
            displayValue: displayValue,
            touched: true,
        });
        const updatedForm = updateObject(this.state.controls, {
            "AssignedPhysician": updatedFormElement,
        });

        this.applet.getEnterpriseComponent().setAttributeValue("PrimaryDoctorUSID", event.target.value);
        this.applet.getEnterpriseComponent().setAttributeValue("PrimaryDoctorFullName", displayValue);

        let picklist = new Picklist("PrimaryPhysician");
        picklist.setExclusionList([event.target.value]);
        this.setState({controls: updatedForm, "secondaryPhysicianPicklist": picklist, secondaryPhysicianKey: uuidv4()});
    };

    render() {
        let secondaryNurse = (
            <Grid key={this.state.secondaryNurseKey} item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <CloudcathMultiSelect
                    margin="dense"
                    fullWidth={true}
                    label="Secondary Nurse"
                    id="SecondaryNurse"
                    onChange={(event) => this.inputChangedHandler(event, "SecondaryNurse")}
                    value={this.state.controls["SecondaryNurse"].value}
                    picklist={this.state.secondaryNursePicklist}
                    variant="outlined"
                    className={this.props.classes.multiSelect}
                />
            </Grid>
        );

        let secondaryPhysician = (
            <Grid key={this.state.secondaryPhysicianKey} item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <CloudcathMultiSelect
                    margin="dense"
                    fullWidth={true}
                    label="Secondary Physician"
                    id="SecondaryPhysician"
                    onChange={(event) => this.inputChangedHandler(event, "SecondaryPhysician")}
                    value={this.state.controls["SecondaryPhysician"].value}
                    picklist={this.state.secondaryPhysicianPicklist}
                    variant="outlined"
                    className={this.props.classes.multiSelect}
                />
            </Grid>
        );

        let interiorStructure = (
            <React.Fragment>
                <Grid key="AssignedNurse" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CloudcathSelect
                        margin="dense"
                        fullWidth={true}
                        label="Assigned Nurse"
                        helperText={!this.isFormValid() && this.state.controls["AssignedNurse"].helperText}
                        onChange={(event) => this.nurseChangedHandler(event)}
                        value={this.state.controls["AssignedNurse"].value || "NULL"}
                        picklist={this.state.primaryNursePicklist}
                        variant="outlined"
                        error={this.state.showErrors && this.isFormValid() === false}
                    />
                </Grid>
                <Grid key="AssignedPhysician" item style={{marginBottom: "1.0em"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CloudcathSelect
                        margin="dense"
                        fullWidth={true}
                        label="Assigned Physician"
                        helperText={!this.isFormValid() && this.state.controls["AssignedPhysician"].helperText}
                        onChange={(event) => this.physicianChangedHandler(event)}
                        value={this.state.controls["AssignedPhysician"].value || "NULL"}
                        picklist={this.state.primaryPhysicianPicklist}
                        variant="outlined"
                        error={this.state.showErrors && this.isFormValid() === false}
                    />
                </Grid>
                {secondaryNurse}
                {secondaryPhysician}
            </React.Fragment>
        );

        let episodeSelection;

        let escalationEnterpriseComponent = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation");

        if (escalationEnterpriseComponent.getBuffer().length === 0) {
            episodeSelection = null;
        } else if (this.applet.getEnterpriseComponent().getAttributeValue("PatientEscalationStatus") === null) {
            episodeSelection = null;
        } else {
            episodeSelection = (
                <React.Fragment>
                    <Grid key={4} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl component="fieldset" margin="dense">
                            <FormLabel><Typography className={this.props.classes.addEpisodeText}>Would you like to return the patient back to the infection episode workflow that started on {escalationEnterpriseComponent.getBuffer()[0]["StartDatetime"]}?</Typography></FormLabel>
                            <RadioGroup aria-label="acknowledge2" name="ack2" value={this.state.reactivateOption} onChange={this.handleReactivateOption}>
                                <FormControlLabel
                                    style={{marginBottom: 10}}
                                    value={true}
                                    control={<Radio />}
                                    label={<Typography variant="h4">Yes (patient will return to infection episode workflow and either Actions Required or Under Watch)</Typography>}
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label={<Typography variant="h4">No (patient will return to Monitoring list)</Typography>}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </React.Fragment>
            );
        }
        let modalContent = (
            <Grid key="id7" container justifyContent="center">
                <form onSubmit={e => e.preventDefault()}>
                    <Grid key="id6" item container style={{width: "32em"}} spacing={4}>
                        <Grid key={1} item style={{marginBottom: "1.0em"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={this.props.classes.addEpisodeText}>Are you sure you want to <b>reactivate</b> the patient?</Typography>
                        </Grid>
                        {episodeSelection}
                        <Grid key="text1" item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography className={this.props.classes.addEpisodeText}>Would you like to edit the care team associated to this patient?</Typography>
                        </Grid>
                        <Grid key="id6" item container spacing={2}>
                            {interiorStructure}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        );

        let content = (
            <React.Fragment>
                {modalContent}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Dialog onClose={(event, reason) => this.cancel1Handler(event, reason)} open={true} scroll="body">
                    <DialogTitle>Reactivate Patient</DialogTitle>
                    <DialogContent style={{padding: "8px 16px"}}>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        <CCButton variant="secondary" onClick={this.cancel1Handler}>
                            Cancel
                        </CCButton>
                        <CCButton
                            variant="green"
                            animate={this.state.updateBackgroundOperation === true}
                            onClick={this.submitHandler}>
                            Save
                        </CCButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    submitHandler = (event) => {
        event.preventDefault();

        if (this.isFormValid() === false) {
            const updatedAssignedPhysicianFormElement = updateObject(this.state.controls["AssignedPhysician"], {
                helperText: "Either Assigned Physician or Assigned Nurse must contain a value.",
            });
            const updatedAssignedNurseFormElement = updateObject(this.state.controls["AssignedNurse"], {
                helperText: "Either Assigned Physician or Assigned Nurse must contain a value.",
            });
            const updatedForm = updateObject(this.state.controls, {
                "AssignedPhysician": updatedAssignedPhysicianFormElement,
                "AssignedNurse": updatedAssignedNurseFormElement,
            });

            this.setState({showErrors: true, controls: updatedForm});
            return;
        }

        let nursePayload = {
            patientUSID: this.applet.getEnterpriseComponent().getAttributeValue("USID"),
            users: [],
        };
        for (let i = 0; i < this.state.controls.SecondaryNurse.value.length; i++) {
            let user = {
                usid: this.state.controls.SecondaryNurse.value[i],
            };
            nursePayload.users.push(user);
        }

        let physicianPayload = {
            patientUSID: this.applet.getEnterpriseComponent().getAttributeValue("USID"),
            users: [],
        };
        for (let i = 0; i < this.state.controls.SecondaryPhysician.value.length; i++) {
            let user = {
                usid: this.state.controls.SecondaryPhysician.value[i],
            };
            physicianPayload.users.push(user);
        }

        this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("SecondaryNurse").performSupplementalUpdate(nursePayload);
        this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("SecondaryPhysician").performSupplementalUpdate(physicianPayload);

        if (this.state.reactivateOption === false) {
            this.applet.getEnterpriseComponent().setAttributeValue("PatientState", "Healthy");
            this.applet.getEnterpriseComponent().setAttributeValue("PatientEscalationStatus", null);
            this.applet.getEnterpriseComponent().setAttributeValue("PrimaryDoctorUSID", this.state.controls.AssignedPhysician.value);
            this.applet.getEnterpriseComponent().setAttributeValue("PrimaryNurseUSID", this.state.controls.AssignedNurse.value);
            this.applet.save();

            let escalationEnterpriseComponent = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation");
            if (escalationEnterpriseComponent.getAttributeValue("EscalationStatus") === "Patient has expired") {
                escalationEnterpriseComponent.setAttributeValue("EscalationStatus", "Closed");
                escalationEnterpriseComponent.executeUpdate();
            }
        } else {
            let escalationEnterpriseComponent = this.applet.getEnterpriseComponent().getEnterpriseObject().getEnterpriseComponent("Escalation");
            let previousEscalationStatus = escalationEnterpriseComponent.getAttributeValue("PreviousEscalationStatus");
            if (previousEscalationStatus === null) previousEscalationStatus = "Awaiting acknowledgement";

            this.applet.getEnterpriseComponent().setAttributeValue("PrimaryDoctorUSID", this.state.controls.AssignedPhysician.value);
            this.applet.getEnterpriseComponent().setAttributeValue("PrimaryNurseUSID", this.state.controls.AssignedNurse.value);
            this.applet.getEnterpriseComponent().setAttributeValue("PatientEscalationStatus", previousEscalationStatus);
            if (previousEscalationStatus === "Awaiting acknowledgement") {
                this.applet.getEnterpriseComponent().setAttributeValue("PatientState", "Action required");
            } else {
                this.applet.getEnterpriseComponent().setAttributeValue("PatientState", "Under watch");
            }
            this.applet.getEnterpriseComponent().executeUpdate();

            escalationEnterpriseComponent.setAttributeValue("DeactivationDate", null);
            escalationEnterpriseComponent.setAttributeValue("ExpirationDate", null);
            escalationEnterpriseComponent.setAttributeValue("HemodialysisDate", null);
            escalationEnterpriseComponent.setAttributeValue("TransplantDate", null);
            escalationEnterpriseComponent.setAttributeValue("DateOfResolution", null);
            escalationEnterpriseComponent.setAttributeValue("EscalationStatus", previousEscalationStatus);
            escalationEnterpriseComponent.setAttributeValue("EndDatetime", null);
            escalationEnterpriseComponent.executeUpdate();

            this.applet.setMode("View");
            this.applet.getView().initiateAction(null);
        }
    };
}

export default withAppContext(
    withStyles(styles, {withTheme: true})(PatientReactivate),
);