import {Checkbox, Grid, IconButton, ListItemText, MenuItem, Select, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, {Component} from "react";
import {v4 as uuidv4} from "uuid";
import {withAppContext} from "../App";
import spinner from "../assets/Spinner-1s-200px.svg";
import eoecClassFactory from "../eoec/EOECClassFactory";

class CloudcathMultiSelect extends Component {

    constructor(props) {
        super(props);
        this.pickValues = props.appContext.sessionContext.pickValues;
    }

    state = {
        id: uuidv4(),
        fetchBackgroundOperation: false,
        ec: null,
        buffer: [],
        values: {},
        menuOpen: false,
    };

    componentDidMount() {
        if (this.props.picklist !== null && this.props.picklist.getStatic() === false) {
            let picklistEC = eoecClassFactory("ec", this.props.picklist.getEnterpriseComponent());

            this.setState({ec: picklistEC});

            if (this.props.picklist.getStatic() !== true) {
                picklistEC.addLoadStartedListener(this);
                picklistEC.addLoadCompletedListener(this);
                picklistEC.newQuery();
                picklistEC.setSearchSpecification(this.props.picklist.getSearchSpecification());
                picklistEC.executeQuery();
            }
        }
    }

    enterpriseComponentLoadStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        if (this.props.defaultOptions !== null && this.props.defaultOptions !== undefined) {
            let entry = {};
            entry[this.props.picklist.getTypeField()] = this.props.defaultOptions.displayValue;
            entry[this.props.picklist.getTypeValue()] = this.props.defaultOptions.value;
            buffer.unshift(entry);
        }

        let copy = [];
        let values = {};

        for (let i = 0; i < buffer.length; i++) {
            let found = false;
            for (let j = 0; j < this.props.picklist.getExclusionList().length; j++) {
                if (this.props.picklist.getExclusionList()[j] === buffer[i][this.props.picklist.getTypeValue()]) {
                    found = true;
                }
            }
            if (found === false) {
                copy.push(buffer[i]);
            }
        }

        if (this.props.picklist.getStatic() === true) {

            if (this.props.defaultOptions !== undefined) {
                values[this.props.defaultOptions.value] = {
                    value: this.props.defaultOptions.value,
                    displayValue: this.props.defaultOptions.displayValue,
                    checked: this.props.value.includes(this.props.defaultOptions.value),
                };
            }

            for (let i = 0; i < this.pickValues.length; i++) {
                let pickInstance = this.pickValues[i];
                let pickTypeField = this.props.picklist.getTypeField();
                if (pickInstance[pickTypeField] !== this.props.picklist.getTypeValue()) continue;
                values[pickInstance["Value"]] = {
                    value: pickInstance["Value"],
                    displayValue: pickInstance["Name"],
                    checked: this.props.value.includes(pickInstance["Value"]),
                };
            }

        } else if (this.props.picklist.getStatic() === false) {

            for (let i = 0; i < copy.length; i++) {
                let pickTypeField = this.props.picklist.getTypeField();
                let pickValueField = this.props.picklist.getTypeValue();
                values[copy[i][pickValueField]] = {
                    value: copy[i][pickValueField],
                    displayValue: copy[i][pickTypeField],
                    checked: this.props.value.includes(copy[i][pickValueField]),
                };
            }
        }

        this.setState({buffer: copy, values: values, fetchBackgroundOperation: false});
    };

    getId() {
        if (this.props.id !== undefined && this.props.id !== null && this.props.id !== "") {
            return this.props.id;
        } else {
            return this.state.id;
        }
    }

    render() {

        if (this.props.picklist.getStatic() === false && this.state.fetchBackgroundOperation === true) {
            return (
                <React.Fragment>
                    <Grid item style={{marginBottom: "1.4em"}}>
                        <img style={{position: "relative", top: "10px", left: "0px"}} width="35" height="35" src={spinner} alt="spinner" />
                    </Grid>
                </React.Fragment>
            );
        }

        const {appContext, defaultOptions, onChange, ...rest} = this.props;

        return (
            <React.Fragment>
                <Select
                    multiple
                    MenuProps={{
                        autoFocus: false,
                        getContentAnchorEl: () => null, // bug fixed in mui5 https://stackoverflow.com/a/61388096
                        PaperProps: {
                            elevation: 2,
                        },
                        MenuListProps: {
                            dense: true,
                            subheader: (
                                <span style={{display: "flex"}}>
                                    <Typography variant="h4" color="primary" style={{textAlign: "center", margin: "auto"}}>{this.props.label}</Typography>
                                    <IconButton style={{padding: "8px"}} onClick={() => this.setState({menuOpen: false})}><CloseIcon /></IconButton>
                                </span>
                            ),
                        },
                    }}
                    open={this.state.menuOpen}
                    onOpen={() => this.setState({menuOpen: true})}
                    onClose={() => this.setState({menuOpen: false})}
                    onChange={e => {
                        this.setState({
                            values: {
                                ...Object.fromEntries(Object.keys(this.state.values).map((k) => [k, {
                                    ...this.state.values[k],
                                    checked: false,
                                }])),
                                ...Object.fromEntries(e.target.value.map((k) => [k, {
                                    ...this.state.values[k],
                                    checked: true,
                                }])),
                            },
                        });
                        if (onChange) onChange(e);
                    }}
                    renderValue={values => values.map(k => this.state.values[k]?.displayValue).join(", ")}
                    {...rest}
                >
                    {Object.values(this.state.values).map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            <Checkbox key={1} checked={Boolean(this.state.values[option.value].checked)} style={{marginRight: 5}} />
                            <ListItemText key={2} primary={option.displayValue} />
                        </MenuItem>
                    ))}
                </Select>
            </React.Fragment>
        );
    }
}

export default withAppContext(CloudcathMultiSelect);
