import {Component} from "react";

class Applet extends Component {
    constructor(props) {
        super(props);

        this.numberOfPages = null;
        this.titleAttribute = null;

        this.view = this.props.view;
        this.defaultMode = "View";
        if (this.props.defaultMode !== "" && this.props.defaultMode !== undefined) {
            this.defaultMode = this.props.defaultMode;
        }
        this.mode = this.defaultMode;

        if (this.props.availableModes === "" || this.props.availableModes === undefined) {
            this.modes = ["View"];
        } else {
            this.modes = this.props.availableModes;
            let hasViewMode = false;
            for (let i = 0; i < this.modes.length; i++) {
                if (this.modes[i] === "View") {
                    hasViewMode = true;
                }
            }
            if (hasViewMode === false) {
                this.modes.push("View");
            }
        }
    };

    cancel = () => {
        if (this.getEnterpriseComponent().isNewMode()) {
            this.setMode("View");
            this.getEnterpriseComponent().executeInsertRollback();
            this.getView().initiateAction(null);
        } else if (this.getEnterpriseComponent().isUpdateMode()) {
            this.setMode("View");
            this.getEnterpriseComponent().executeUpdateRollback();
            this.getView().initiateAction(null);
        } else if (this.getEnterpriseComponent().isQueryMode()) {
        } else if (this.getEnterpriseComponent().isViewMode()) {
            this.getView().initiateAction(null);
        }
    };

    getDefaultMode = () => {
        return this.defaultMode;
    };

    getEnterpriseComponent = () => {
        return this.props.enterpriseComponent;
    };

    getMode = () => {
        return this.mode;
    };

    getName = () => {
        if (this.state === null) {
            return "";
        }
        return this.state.Name;
    };

    getSelectedRecordNumber = () => {
        return this.getEnterpriseComponent().getSelectedRecordNumber();
    };

    getTitle = () => {
        if (this.getEnterpriseComponent().getSelectedRecordNumber() !== -1) {
            return this.getEnterpriseComponent().getBuffer()[this.getSelectedRecordNumber()][this.getTitleAttribute()];
        }
        return null;
    };

    getTitleAttribute = () => {
        return this.titleAttribute;
    };

    getView = () => {
        return this.view;
    };

    hasToggle = () => {
        return this.getView().hasToggle();
    };

    isModePossible = (mode, modes, assignedPrivileges) => {
        for (let i = 0; i < this.modes.length; i++) {
            if (this.modes[i] === mode) {
                let m = modes[mode];
                let requiredPrivileges = m.Privileges;

                if (requiredPrivileges !== undefined) {
                    for (let i = 0; i < requiredPrivileges.length; i++) {
                        for (let j = 0; j < assignedPrivileges.length; j++) {
                            if (requiredPrivileges[i].name === assignedPrivileges[j].Name) return true;
                        }
                    }
                }
                return false;
            }
        }
        return false;
    };

    isModeEnabled = (mode) => {
        return this.mode === mode;
    };

    isRowcountKnown = () => {
        return this.getEnterpriseComponent().getTotalRowcount() !== null;
    };

    refreshRecordHandler = () => {
        this.getEnterpriseComponent().executeSingleRecordRefresh();
    };

    refreshHandler = () => {
        this.getEnterpriseComponent().setSearchSpecification(this.getSearchSpecification());
        this.getEnterpriseComponent().executeQuery(0, 5);
    };

    save = () => {
        if (this.getEnterpriseComponent().isNewMode()) {
            this.getEnterpriseComponent().executeInsert();
            this.setMode("Save");
        } else if (this.getEnterpriseComponent().isUpdateMode()) {
            this.getEnterpriseComponent().executeUpdate();
            this.setMode("Save");
        } else if (this.getEnterpriseComponent().isQueryMode()) {
            this.getEnterpriseComponent().executeQuery(0, 5);
        }
    };

    selectHandler = (index) => {
        this.getEnterpriseComponent().selectRecord(index);
    };

    setMode = (mode) => {
        this.mode = mode;

        if (mode === "New") {
            this.getEnterpriseComponent().newRecord();
        } else if (mode === "Edit") {
            this.getEnterpriseComponent().editRecord();
        }
    };

    unmountComponent = (obj) => {
        this.getEnterpriseComponent().removeRecordChangedListener(obj);
        this.getEnterpriseComponent().removeLoadStartedListener(obj);
        this.getEnterpriseComponent().removeNewQueryListener(obj);
        this.getEnterpriseComponent().removeLoadCompletedListener(obj);
        this.getEnterpriseComponent().removeValueChangedListener(obj);
        this.getEnterpriseComponent().removeInvalidListener(obj);
        this.getEnterpriseComponent().removeUpdateStartedListener(obj);
        this.getEnterpriseComponent().removeUpdateCompletedListener(obj);
        this.getEnterpriseComponent().removeInsertStartedListener(obj);
        this.getEnterpriseComponent().removeInsertCompletedListener(obj);
        this.getEnterpriseComponent().removeInsertRollbackCompletedListener(obj);
        this.getEnterpriseComponent().removeStartNewRecordModeCompletedListener(obj);
        this.getEnterpriseComponent().removeSingleRecordRefreshStartedListener(obj);
        this.getEnterpriseComponent().removeSingleRecordRefreshCompletedListener(obj);
        this.getEnterpriseComponent().removeDeleteStartedListener(obj);
        this.getEnterpriseComponent().removeDeleteCompletedListener(obj);
    };
}

export default Applet;
