import CloudcathAxios from "../../../util/CloudcathAxios";
import {V1UserIDOCToArray} from "../../../util/utility";
import EnterpriseComponent from "../EnterpriseComponent";

class EscalationEventEnterpriseComponent extends EnterpriseComponent {
    performSingleRecordRefresh = (primaryKey) => {
        let options = {
            method: "GET",
            url: "cloudcath/v1/users",
            json: true,
            params: {
                "USID": primaryKey,
            },
            headers: {
                "idoc-request": "v1.User",
            },
        };
        this.notifySingleRecordRefreshStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.refreshEnterpriseComponentSuccess(V1UserIDOCToArray(response.data));
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    performQuery = (start, pageSize, foreignKey) => {
        let params = {};
        if (foreignKey === null) {
            params = {
                "Limit": pageSize,
                "Offset": start,
            };
        } else {
            params = {
                "Limit": pageSize,
                "Offset": start,
                "ClinicUSID": foreignKey,
            };
        }

        let keys = Object.keys(this.getSearchSpecification());
        for (let i = 0; i < keys.length; i++) {
            let value = this.getSearchSpecification()[keys[i]];

            if (keys[i] === "USID") {
                params["USID"] = value;
            }
        }

        let options = {
            method: "GET",
            url: "cloudcath/v1/users",
            json: true,
            params: params,
            headers: {
                "idoc-request": "v1.User",
            },
        };
        this.notifyLoadStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.fetchEnterpriseComponentSuccess(V1UserIDOCToArray(response.data), start, pageSize);
            })
            .catch(error => {
                this.fetchEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        let user = {};

        user["USID"] = this.getBuffer()[this.getRecordPointer()]["USID"];

        let keys = Object.keys(this.getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.getAttributes()[keys[i]];

            if (this.getBuffer()[this.getRecordPointer()][attribute.getName() + "_original"] !== undefined && attribute.getName() !== "USID") {
                user[attribute.getName()] = this.getBuffer()[this.getRecordPointer()][attribute.getName()];
            }
        }

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.User";
        payload.CloudcathMessage.User = user;

        return payload;
    };

    performUpdate = () => {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "cloudcath/v1/users",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.User",
            },
        };
        this.notifyUpdateStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(response.data);
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let user = {};

        let row = this.getBuffer()[this.getRecordPointer()];

        user["USID"] = row["USID"];
        user["Username"] = row["Username"];
        user["FirstName"] = row["FirstName"];
        user["LastName"] = row["LastName"];
        user["Password"] = row["Password"];
        user["MainEmail"] = row["MainEmail"];
        user["MainPhone"] = row["MainPhone"];
        user["AdditionalEmail"] = row["AdditionalEmail"];
        user["AdditionalPhone"] = row["AdditionalPhone"];
        user["Salutation"] = row["Salutation"];
        user["Suffix"] = row["Suffix"];
        user["UserStatus"] = "Active";
        user["UserRole"] = row["UserRole"];
        user["Realm"] = row["Realm"];

        payload.CloudcathMessage = {};
        payload.CloudcathMessage["-idoc"] = "v1.User";
        payload.CloudcathMessage.User = user;

        return payload;
    };

    performInsert = () => {
        let payload = this.enterpriseComponentPostIDOC();

        let options = {
            method: "POST",
            url: "cloudcath/v1/users",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.User",
                "idoc-response": "v1.User",
            },
        };
        this.notifyInsertStartListeners();
        CloudcathAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1UserIDOCToArray(response.data));
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };
}

export default EscalationEventEnterpriseComponent;