import {withAppContext} from "../../../App";
import UserDeactivate from "../../../components/UserDeactivate";

import Applet from "../Applet";

class UserDeactivateApplet extends Applet {
    state = {
        Name: "UserDeactivateApplet",
    };

    modeChanged = (mode) => {
        this.setState({Mode: mode});
    };

    render() {
        return <UserDeactivate applet={this}
                               enterpriseComponent={this.getEnterpriseComponent()}
                               patientEnterpriseComponent={this.props.patientEnterpriseComponent} />;
    }
}

export default withAppContext(UserDeactivateApplet);
