import * as d3 from "d3";
import _ from "lodash";
import moment from "moment-timezone";
import React, {useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import {useWindowSize} from "../../util/useWindowSize";
import "./css/TurbidityGraph.scss";

export default function TurbidityGraphSvg({
    variant, // normal | simple
    patientUSID,
    dateStart,
    dateEnd,
    data,
    escalations,
    notificationLevel,
    interactive,
    timezone,
    ...props
}) {

    variant = variant || "normal";
    timezone = timezone || moment.tz.guess();

    dateStart = moment(dateStart).startOf("day").tz(timezone, true).valueOf();
    dateEnd = moment(dateEnd).startOf("day").tz(timezone, true).valueOf();

    const dataComplete = data !== undefined;

    notificationLevel = notificationLevel || 0;

    const [lastYRange, setLastYRange] = useState(undefined);
    const [svg, setSvg] = useState(undefined);

    const windowSize = useWindowSize();

    const divRef = useRef(undefined);

    useEffect(() => {
        render(data, escalations);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        variant,
        dateStart,
        dateEnd,
        data,
        escalations,
        notificationLevel,
        windowSize,
    ]);

    useEffect(() => {
        const div = divRef.current;
        if (div.firstChild !== svg) {
            while (div.firstChild) div.removeChild(div.firstChild);
            if (svg) div.appendChild(svg);
        }
    }, [divRef, svg]);

    async function render(data, escalations) {

        data = _.cloneDeep(data || []);

        if (escalations === undefined || escalations === null) escalations = [];
        escalations = escalations.map(escalation => ({
            usid: escalation.usid,
            start: moment.utc(escalation.Start).valueOf(),
            end: escalation.End === null ? null : moment.utc(escalation.End).valueOf(),
        }));
        escalations = _.sortBy(escalations, x => x.start);

        // clamp all values at min-max
        const turbidityClamp = {min: 0.549999, max: 100.05000};
        data.forEach(d => d.value = Math.max(turbidityClamp.min, Math.min(turbidityClamp.max, d.value)));

        insertGapIdentifiers(data);

        const div = divRef.current;

        const now = moment().valueOf();

        const svgWidth = ReactDOM.findDOMNode(div).clientWidth;
        const svgHeight = ReactDOM.findDOMNode(div).clientHeight;

        const margin = {top: 10, right: 29, bottom: 40, left: 29};
        const width = svgWidth - (margin.left + margin.right);
        const height = svgHeight - (margin.top + margin.bottom);

        const svgRoot = d3.create("svg");

        const svg = svgRoot
            .attr("width", svgWidth)
            .attr("height", svgHeight)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const defs = svg.append("defs");

        svg
            .append("clipPath")
            .attr("id", "clip")
            .append("rect")
            .attr("width", width + 1)
            .attr("height", height + 1); // +1 because line stroke is 2px

        const xAxisDomain = [dateStart, dateEnd];

        const xAxis = d3
            .scaleTime()
            .domain(xAxisDomain)
            .range([0, width]);

        const yAxis = d3
            .scaleLinear()
            .domain([0, yMaxValue(data, notificationLevel)])
            .range([height, 5]);// top padding so lines/diamond aren't cut off

        const notificationLevelY = yAxis(notificationLevel);
        const breachesNotificationLevel = notificationLevel > 0 && data.find(d => d.value >= notificationLevel);

        if (breachesNotificationLevel) {

            svg
                .append("clipPath")
                .attr("id", "clip-upperNotificationZone")
                .append("rect")
                .attr("width", width + 1)
                .attr("height", notificationLevelY);

            svg
                .append("clipPath")
                .attr("id", "clip-lowerNotificationZone")
                .append("rect")
                .attr("width", width + 1)
                .attr("height", height - notificationLevelY)
                .attr("transform", `translate(0, ${notificationLevelY})`);
        }

        let [xAxisTickSections, xAxisTickLabels] = xTickValues(xAxisDomain, width);

        svg
            .append("g")
            .append("g")
            .attr("class", "x-tick")
            .attr("transform", `translate(0, ${height + 15})`)
            .call(d3
                .axisBottom(xAxis)
                .tickFormat(d => moment(d).tz(timezone).format("MMM DD"))
                .tickValues(xAxisTickLabels),
            )
            .call(g => g.select(".domain").remove());

        xAxisTickSections.forEach((v, i, values) => {
            if (i > 0) {
                svg
                    .append("rect")
                    .attr("fill", i % 2 !== 0 ? "#edeff3" : "#8dcff0")
                    .attr("width", xAxis(values[i]) - xAxis(values[i - 1]))
                    .attr("height", 7)
                    .attr("transform", `translate(${xAxis(values[i - 1])}, ${height + 7})`);
            }
        });

        escalations.forEach(escalation => {

            const clipStart = Math.max(dateStart, escalation.start);
            const clipEnd = Math.min(dateEnd, escalation.end || dateEnd);

            if (clipStart >= clipEnd) return;

            const xStart = xAxis(clipStart);
            const xEnd = xAxis(clipEnd);

            svg
                .append("rect")
                .attr("class", "escalation-highlight")
                .attr("width", xEnd - xStart)
                .attr("height", height)
                .attr("transform", `translate(${xStart}, 0)`);
        });

        function appendEscalationGradient(id, colorNormal, colorEscalated) {

            const escalationGradient = defs
                .append("linearGradient")
                .attr("id", id)
                .attr("gradientUnits", "userSpaceOnUse")
                .attr("x1", 0)
                .attr("x2", svgWidth);

            escalationGradient
                .append("stop")
                .attr("offset", 0)
                .attr("stop-color", colorNormal);

            escalations.forEach(escalation => {

                const clipStart = Math.max(dateStart, escalation.start);
                const clipEnd = Math.min(dateEnd, escalation.end || dateEnd);

                if (clipStart >= clipEnd) return;

                const xStart = xAxis(clipStart);
                const xEnd = xAxis(clipEnd);

                escalationGradient
                    .append("stop")
                    .attr("offset", xStart / svgWidth)
                    .attr("stop-color", colorNormal);

                escalationGradient
                    .append("stop")
                    .attr("offset", xStart / svgWidth)
                    .attr("stop-color", colorEscalated);

                escalationGradient
                    .append("stop")
                    .attr("offset", xEnd / svgWidth)
                    .attr("stop-color", colorEscalated);

                escalationGradient
                    .append("stop")
                    .attr("offset", xEnd / svgWidth)
                    .attr("stop-color", colorNormal);
            });

            escalationGradient
                .append("stop")
                .attr("offset", 100)
                .attr("stop-color", colorNormal);
        }

        appendEscalationGradient("gradient-solid", "#525968", "#e74c3c");
        appendEscalationGradient("gradient-dotted", "#bac3ce", "#f2988f");

        svg
            .append("g")
            .attr("class", "y-tick")
            .call(d3
                .axisLeft(yAxis)
                .tickValues(yTickValues(yAxis.domain())),
            )
            .call(g => g.select(".domain").remove());

        svg
            .append("g")
            .attr("class", "y-grid")
            .call(d3
                .axisLeft(yAxis)
                .tickSize(-width)
                .tickFormat("")
                .tickValues(yTickValues(yAxis.domain())),
            )
            .call(g => g.select(".domain").remove());

        if (xAxisDomain[0] <= now && now <= xAxisDomain[1]) {

            svg
                .append("path")
                .attr("class", "today-line")
                .attr("d", `M0,0 0,${height}`)
                .attr("transform", `translate(${xAxis(now)}, 0)`);

            svg
                .append("path")
                .attr("class", "today-marker")
                .attr("d", d3.symbol()
                    .type(d3.symbolCircle)
                    .size(60),
                )
                .attr("transform", `translate(${xAxis(now)}, ${height})`);
        }

        if (notificationLevel > 0) {

            svg
                .append("path")
                .attr("class", "notification-level-line")
                .attr("d", `M0,0 ${width},0`)
                .attr("transform", `translate(0, ${notificationLevelY})`);

            svg
                .append("text")
                .attr("class", "notification-level-text")
                .text(notificationLevel.toFixed(1))
                .attr("transform", `translate(${width + 5}, ${notificationLevelY + 3})`);
        }

        const linesGroup = svg
            .append("g")
            .attr("clip-path", "url(#clip)");

        if (data.length > 0) {

            linesGroup
                .append("path")
                .datum(data.filter(d => !d.gap || d.gapBig))
                .attr("clip-path", "url(#clip-lowerNotificationZone)")
                .attr("class", "line-dotted")
                .attr("d", d3.line()
                    .defined(d => d.dotted && !d.gapBig)
                    .x(d => xAxis(d.date))
                    .y(d => yAxis(d.value)),
                );

            linesGroup
                .append("path")
                .datum(data)
                .attr("clip-path", "url(#clip-lowerNotificationZone)")
                .attr("class", "line-solid")
                .attr("d", d3.line()
                    .defined(d => !d.gap)
                    .x(d => xAxis(d.date))
                    .y(d => yAxis(d.value)),
                );

            if (breachesNotificationLevel) {

                linesGroup
                    .append("path")
                    .datum(data.filter(d => !d.gap || d.gapBig))
                    .attr("clip-path", "url(#clip-upperNotificationZone)")
                    .attr("class", "line-dotted-notification-zone")
                    .attr("d", d3.line()
                        .defined(d => d.dotted && !d.gapBig)
                        .x(d => xAxis(d.date))
                        .y(d => yAxis(d.value)),
                    );

                linesGroup
                    .append("path")
                    .datum(data)
                    .attr("clip-path", "url(#clip-upperNotificationZone)")
                    .attr("class", "line-solid-notification-zone")
                    .attr("d", d3.line()
                        .defined(d => !d.gap)
                        .x(d => xAxis(d.date))
                        .y(d => yAxis(d.value)),
                    );
            }

            if (interactive) {

                const focusLine = svg
                    .append("path")
                    .attr("class", "focus-line")
                    .attr("d", `M0,0 0,${height}`)
                    .style("display", "none");

                const focusMarker = svg
                    .append("g")
                    .style("display", "none");

                focusMarker
                    .append("path")
                    .attr("class", "focus-marker")
                    .attr("d", d3.symbol()
                        .type(d3.symbolSquare)
                        .size(50),
                    );

                const focusTextContainer = svg
                    .append("g")
                    .style("display", "none")
                    .style("display", "none");

                const focusTextBox = focusTextContainer
                    .append("rect")
                    .attr("class", "focus-text-box")
                    .attr("rx", "4px")
                    .style("filter", "url(#drop-shadow)");

                const focusTextValue = focusTextContainer
                    .append("text")
                    .attr("class", "focus-text-value");

                const focusTextTime = focusTextContainer
                    .append("text")
                    .attr("class", "focus-text-time");

                svg
                    .append("rect")
                    .attr("width", svgWidth) // not reliable to have this in the css, so keep it here
                    .attr("height", svgHeight)
                    .attr("class", "pointer-capture")
                    .on("mouseout", () => {
                        focusLine.style("display", "none");
                        focusMarker.style("display", "none");
                        focusTextContainer.style("display", "none");
                    })
                    .on("mousemove", e => {

                        const bisectDate = d3.bisector(d => d.date).left;
                        const x = xAxis.invert(d3.pointer(e)[0]);
                        const i = bisectDate(data, x, 1);

                        if (data.length === 1 || (i <= data.length - 1 && i > 0)) {

                            const d = data.length === 1 ? data[0] : x - data[i - 1].date > data[i].date - x ? data[i] : data[i - 1];

                            if (d && !d.gap) {

                                const focusX = xAxis(d.date);
                                if (focusX >= 0 && focusX <= width) {

                                    const focusMarkerY = yAxis(d.value);

                                    let highlighted =
                                        (notificationLevel > 0 && d.value >= notificationLevel) ||
                                        Boolean(escalations.find(e => e.start <= d.date && d.date <= (e.end || dateEnd)));

                                    focusLine.attr("transform", `translate(${focusX}, 0)`);
                                    focusLine.style("display", undefined);

                                    focusMarker.attr("fill", highlighted ? "#e74c3c" : "#262837");
                                    focusMarker.attr("transform", `translate(${focusX}, ${focusMarkerY})`);
                                    focusMarker.style("display", undefined);

                                    focusTextValue.attr("fill", highlighted ? "#e74c3c" : "#262837");
                                    focusTextValue.text(
                                        d.value === turbidityClamp.max
                                            ? `${turbidityClamp.max.toFixed(0)}+`
                                            : d.value === turbidityClamp.min
                                                ? `\u2264 ${turbidityClamp.min.toFixed(1)}`
                                                : d.value.toFixed(1),
                                    );

                                    focusTextTime.text(moment(d.date).tz(timezone).format("MMM D, HH:mm"));

                                    const focusTextBoxWidth = 17 +
                                        focusTextValue._groups[0][0].getComputedTextLength() +
                                        focusTextTime._groups[0][0].getComputedTextLength();

                                    focusTextBox.attr("width", focusTextBoxWidth);

                                    focusTextTime.attr("transform", `translate(${focusTextBoxWidth - 5}, 15)`);

                                    const focusTextMinX = -5;
                                    const focusTextMaxX = (width + 5) - focusTextBoxWidth;
                                    const focusTextX = Math.min(focusTextMaxX, Math.max(focusTextMinX, focusX - (focusTextBoxWidth / 2.0)));
                                    const focusTextY = focusMarkerY >= 75 ? 5 : height - 25;

                                    focusTextContainer.attr("transform", `translate(${focusTextX}, ${focusTextY})`);
                                    focusTextContainer.style("display", undefined);

                                    return;
                                }
                            }
                        }

                        focusLine.style("display", "none");
                        focusMarker.style("display", "none");
                        focusTextContainer.style("display", "none");
                    });

                appendBoxDropShadow(defs);
            }
        }

        setSvg(svgRoot.node());
    }

    function insertGapIdentifiers(data) {

        for (let i = data.length - 1; i >= 0; i--) {
            if (i > 0) {

                const diff = data[i].date - data[i - 1].date;
                if (diff > 18000000) {

                    if (diff < 259200000) {
                        data[i - 1].dotted = true;
                        data[i].dotted = true;
                    }

                    data.splice(i, 0, {
                        date: Math.floor(data[i - 1].date + (diff / 2)),
                        value: 0,
                        gap: true,
                        gapBig: diff >= 259200000,
                    });
                }
            }
        }
    }

    function xTickValues(domain, width) {

        let start = moment(domain[0]);
        const end = moment(domain[1]);

        let tickSectionInterval;
        let tickLabelInterval;

        if (end.diff(start, "day") >= 15) tickSectionInterval = 7 * 86400;
        else tickSectionInterval = 86400;

        const labelWidth = 45;
        const adjustedLabelCount = Math.floor(Math.min(8, width / labelWidth));
        const adjustedLabelInterval = end.diff(start, "seconds") / adjustedLabelCount;

        tickLabelInterval = tickSectionInterval;

        if (width / (Math.ceil(end.diff(start, "seconds") / tickLabelInterval) + 1) < labelWidth) {
            tickLabelInterval = adjustedLabelInterval;
        }

        const tickSectionX = moment(start);
        const tickSections = [];

        tickSections.push(tickSectionX.valueOf());
        while (tickSectionX.add(tickSectionInterval, "seconds") < end) {
            tickSections.push(tickSectionX.valueOf());
        }
        tickSections.push(end.valueOf());

        const tickLabelX = moment(start);
        const tickLabels = [];

        tickLabels.push(tickLabelX.valueOf());
        while (tickLabelX.add(tickLabelInterval, "seconds") < end) {
            tickLabels.push(tickLabelX.valueOf());
        }
        if (tickLabelX.diff(end, "seconds") === 0) {
            // the last tick section should be the full amount, if not then cut it off to prevent label overlap
            tickLabels.push(end.valueOf());
        }

        return [tickSections, tickLabels];
    }

    function yMaxValue(data, notificationLevel) {

        if (!dataComplete && data.length === 0 && lastYRange) {
            return lastYRange;
        }

        let max = notificationLevel;
        data.forEach(d => {
            if (dateStart <= d.date && d.date <= dateEnd) {
                max = Math.max(d.value, max);
            }
        });

        let result;
        if (max < 10) result = 10;
        else if (max < 20) result = 20;
        else if (max < 50) result = 50;
        else result = 100;

        setLastYRange(result);

        return result;
    }

    function yTickValues(domain) {

        let start = domain[0];
        const end = domain[1];

        const tickAmount = (end - start) / 5;

        const values = [];
        values.push(start);
        while ((start += tickAmount) < end) {
            values.push(start);
        }
        values.push(end);
        return values;
    }

    function appendBoxDropShadow(defs) {

        const dropShadow = defs
            .append("filter")
            .attr("id", "drop-shadow")
            .attr("height", "150%");

        dropShadow
            .append("feGaussianBlur")
            .attr("in", "SourceAlpha")
            .attr("stdDeviation", 1)
            .attr("result", "blur");

        dropShadow
            .append("feOffset")
            .attr("in", "blur")
            .attr("dx", 0)
            .attr("dy", 0)
            .attr("result", "offsetBlur");

        dropShadow
            .append("feFlood")
            .attr("in", "offsetBlur")
            .attr("flood-color", "#525968")
            .attr("result", "offsetColor");

        dropShadow
            .append("feComposite")
            .attr("in", "offsetColor")
            .attr("in2", "offsetBlur")
            .attr("operator", "in")
            .attr("result", "offsetBlur");

        const feMerge = dropShadow
            .append("feMerge");

        feMerge
            .append("feMergeNode")
            .attr("in", "offsetBlur");

        feMerge
            .append("feMergeNode")
            .attr("in", "SourceGraphic");
    }

    return <div {...{"data-cc-component": "TurbidityGraphSvg"}} ref={divRef} {...props} />;
}
