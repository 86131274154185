import {Typography, withStyles} from "@material-ui/core";
import React, {Component} from "react";
import {withAppContext} from "../App";
import spinner from "../assets/Spinner-1s-200px.svg";
import styles, {Grey4, Grey8} from "../theme/styles";

class PatientIdentifier extends Component {
    state = {
        fetchBackgroundOperation: true,
        buffer: {},
    };

    constructor(props) {
        super(props);

        this.applet = props.applet;
    }

    componentDidMount() {
        this.applet.getEnterpriseComponent().addValueChangedListener(this);
        this.applet.getEnterpriseComponent().addLoadStartedListener(this);
        this.applet.getEnterpriseComponent().addLoadCompletedListener(this);
        this.applet.getEnterpriseComponent().addUpdateCompletedListener(this);

        this.enterpriseComponentLoadCompleted(this.applet.getEnterpriseComponent().getBuffer());
    }

    componentWillUnmount() {
        this.applet.unmountComponent(this);
    }

    enterpriseComponentValueChanged = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    enterpriseComponentLoadStarted = () => {
        this.setState({fetchBackgroundOperation: true});
    };

    enterpriseComponentUpdateCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    enterpriseComponentLoadCompleted = (buffer) => {
        let localBuffer = buffer.slice(this.applet.getEnterpriseComponent().getRecordPointer(), this.applet.getEnterpriseComponent().getRecordPointer() + 1);

        let b = {};

        if (localBuffer.length !== 0) {
            b = localBuffer[0];
            this.setState({buffer: b, fetchBackgroundOperation: false});
        } else {
            this.setState({buffer: {}, fetchBackgroundOperation: false});
        }
    };

    getName = () => {
        return this.applet.getName();
    };

    render() {
        const middleInitial = this.state.buffer["MiddleInitial"];
        const patientFullName = [
            this.state.buffer["FirstName"],
            ...(middleInitial ? [`${middleInitial}.`.toUpperCase()] : []),
            this.state.buffer["LastName"],
        ].join(" ");
        return this.state.fetchBackgroundOperation === false && this.applet.getEnterpriseComponent().isValid() === true ? (
            <React.Fragment>
                <div style={{textOverflow: "ellipsis", overflow: "hidden"}}><Typography style={{fontSize: 22, color: Grey8}} noWrap={true}>{patientFullName}</Typography></div>
                <Typography style={{width: "100%", fontSize: 16, color: Grey8}} noWrap={true}>DOB: {this.state.buffer["DateOfBirth"]}</Typography>
                <div style={{textOverflow: "ellipsis", overflow: "hidden"}}><Typography style={{fontSize: 16, color: Grey4}} noWrap={true}>MRN/Patient ID: {this.state.buffer["PatientIdentifier"]}</Typography></div>
                <div style={{textOverflow: "ellipsis", overflow: "hidden"}}><Typography style={{fontSize: 16, color: Grey4}} noWrap={true}>CloudCath ID: {this.state.buffer["CloudCathID"]}</Typography></div>
            </React.Fragment>
        ) : (
            <img src={spinner} alt="spinner" width="35" height="35" />
        );
    }
}

export default withAppContext(withStyles(styles, {withTheme: true})(PatientIdentifier));