import moment from "moment";
import {withAppContext} from "../../../App";

import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class PatientDetailIdentifierApplet extends Applet {
    constructor(props) {
        super(props);

        const actStudy = this.view.props.appContext.sessionContext.actStudy;

        let enterpriseComponent = this.getEnterpriseComponent();
        if (actStudy) {
            enterpriseComponent.getAttributes()["CyclerID"].setPicklist("CyclerActStudy");
            enterpriseComponent.getAttributes()["PatientIdentifier"].setRequired(true);
            enterpriseComponent.getAttributes()["FirstName"].setRequired(false);
            enterpriseComponent.getAttributes()["LastName"].setRequired(false);
            enterpriseComponent.getAttributes()["DateOfBirth"].setRequired(false);
        } else {
            enterpriseComponent.getAttributes()["CyclerID"].setPicklist("Cycler");
            enterpriseComponent.getAttributes()["PatientIdentifier"].setRequired(false);
            enterpriseComponent.getAttributes()["FirstName"].setRequired(true);
            enterpriseComponent.getAttributes()["LastName"].setRequired(true);
            enterpriseComponent.getAttributes()["DateOfBirth"].setRequired(true);
        }

        this.state = {
            Name: "PatientDetailApplet",
            Modes: {
                View: {
                    Groups: [
                        {
                            "identifier": "group1",
                            "type": "standard",
                            "selector": null,
                            "characteristics": [],
                            "membership": [
                                {
                                    id: "PatientIdentifier",
                                },
                                {
                                    id: "CloudCathID",
                                },
                                {
                                    id: "FirstName",
                                },
                                {
                                    id: "MiddleInitial",
                                },
                                {
                                    id: "LastName",
                                },
                                {
                                    id: "DateOfBirth",
                                },
                                ...(this.props.appContext.sessionContext.userRole === "Admin" ? [
                                    {
                                        id: "Placeholder1",
                                    },
                                    {
                                        id: "BillingAccountUSID",
                                    },
                                ] : []),
                            ],
                        },
                    ],
                },
                Edit: {
                    Groups: [
                        {
                            "identifier": "group1",
                            "type": "standard",
                            "selector": null,
                            "characteristics": [],
                            "membership": [
                                {
                                    id: "PatientIdentifier",
                                },
                                {
                                    id: "CloudCathID",
                                },
                                {
                                    id: "FirstName",
                                },
                                {
                                    id: "MiddleInitial",
                                },
                                {
                                    id: "LastName",
                                },
                                {
                                    id: "DateOfBirth",
                                },
                                ...(this.props.appContext.sessionContext.userRole === "Admin" ? [
                                    {
                                        id: "Placeholder1",
                                    },
                                    {
                                        id: "BillingAccountUSID",
                                    },
                                ] : []),
                            ],
                        },
                    ],
                },
            },
            Layout: {
                totalWidth: "36em",
            },
            Controls: {
                PatientIdentifier: {
                    id: "PatientIdentifier",
                    enterpriseComponentAttribute: "PatientIdentifier",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        "New": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: true,
                    errors: [
                        {
                            validator: "Unique",
                            value: null,
                            errorText: "Field must be unique within a clinic.",
                        },
                        {
                            validator: "Not All Spaces",
                            value: null,
                            errorText: "Field must not contain all spaces.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not contain leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not contain trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        label: "MRN/Patient ID",
                        margin: "dense",
                    },
                    ip: {},
                },
                CloudCathID: {
                    id: "CloudCathID",
                    enterpriseComponentAttribute: "CloudCathID",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        "New": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                    },
                    type: "TextField",
                    errors: [],
                    csp: {
                        autoFocus: false,
                        multiline: false,
                        label: "CloudCath ID",
                        margin: "dense",
                    },
                    ip: {},
                },
                FirstName: {
                    id: "FirstName",
                    enterpriseComponentAttribute: "FirstName",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4,
                        },
                        "New": {
                            xs: 12,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Not All Spaces",
                            value: null,
                            errorText: "Field must not contain all spaces.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not contain leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not contain trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        placeholder: null,
                        label: "First name",
                        margin: "dense",
                    },
                    ip: {},
                },
                MiddleInitial: {
                    id: "MiddleInitial",
                    enterpriseComponentAttribute: "MiddleInitial",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4,
                        },
                        "New": {
                            xs: 12,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Not All Spaces",
                            value: null,
                            errorText: "Field must not contain all spaces.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not contain leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not contain trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        placeholder: null,
                        label: "Middle initial",
                        margin: "dense",
                    },
                    ip: {},
                },
                LastName: {
                    id: "LastName",
                    enterpriseComponentAttribute: "LastName",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4,
                        },
                        "New": {
                            xs: 12,
                            sm: 4,
                            md: 4,
                            lg: 4,
                            xl: 4,
                        },
                    },
                    type: "TextField",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Not All Spaces",
                            value: null,
                            errorText: "Field must not contain all spaces.",
                        },
                        {
                            validator: "No Leading Spaces",
                            value: null,
                            errorText: "Field must not contain leading spaces.",
                        },
                        {
                            validator: "No Trailing Spaces",
                            value: null,
                            errorText: "Field must not contain trailing spaces.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        placeholder: null,
                        label: "Last name",
                        margin: "dense",
                    },
                    ip: {},
                },
                DateOfBirth: {
                    id: "DateOfBirth",
                    enterpriseComponentAttribute: "DateOfBirth",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "Date",
                    requiresConfirmation: false,
                    errors: [
                        {
                            validator: "Is Same Or Before Day",
                            value: moment(),
                            errorText: "Date must not be in the future.",
                        },
                    ],
                    csp: {
                        autoFocus: false,
                        placeholder: null,
                        label: "Date of birth",
                        margin: "dense",
                        openTo: "year",
                        views: ["year", "month", "date"],
                        disableFuture: true,
                    },
                    ip: {},
                },
                Placeholder1: {
                    id: "Placeholder1",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "Placeholder",
                },
                BillingAccountUSID: {
                    id: "BillingAccountUSID",
                    enterpriseComponentAttribute: "BillingAccountUSID",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                        "New": {
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 6,
                            xl: 6,
                        },
                    },
                    type: "Select",
                    requiresConfirmation: false,
                    errors: [],
                    csp: {
                        autoFocus: false,
                        placeholder: null,
                        label: "Billing Account",
                        margin: "dense",
                    },
                    ip: {},
                    other: {
                        renderAssistiveText: false,
                    },
                },
            },
        };

        if (actStudy) {
            this.state.Controls["PatientIdentifier"]["errors"].push({
                validator: "Required",
                value: null,
                errorText: "Field must contain a value.",
            });
        } else {
            this.state.Controls["FirstName"]["errors"].push({
                validator: "Required",
                value: null,
                errorText: "Field must contain a value.",
            });
            this.state.Controls["LastName"]["errors"].push({
                validator: "Required",
                value: null,
                errorText: "Field must contain a value.",
            });
            this.state.Controls["DateOfBirth"]["errors"].push({
                validator: "Required",
                value: null,
                errorText: "Field must contain a value.",
            });
        }
    };

    render() {
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="Patient"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.props.defaultMode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(PatientDetailIdentifierApplet);
