import {withAppContext} from "../../../App";

import CloudcathDetail from "../../../components/CloudcathDetail";
import Applet from "../Applet";

class PatientDetailReducedTurbidityNotificationApplet extends Applet {
    constructor(props) {
        super(props);

        const actStudy = props.appContext.sessionContext.actStudy;

        this.state = {
            Name: "PatientDetailTurbidityNotificationApplet",
            Mode: "Edit",
            Modes: {
                Edit: {
                    Groups: [
                        {
                            "identifier": "group2",
                            "type": "standard",
                            "selector": null,
                            "characteristics": [],
                            "membership": [
                                {
                                    id: "SendReminderAfterInstances",
                                },
                            ],
                        },
                    ],
                },
            },
            Layout: {
                totalWidth: "18em",
            },
            Controls: {
                SendReminderAfterInstances: {
                    id: "SendReminderAfterInstances",
                    enterpriseComponentAttribute: "SendReminderAfterInstances",
                    placement: {
                        "View": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        "Edit": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                        "New": {
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12,
                        },
                    },
                    type: "Positive Float",
                    requiresConfirmation: false,
                    errors: [{
                        validator: "Required",
                        value: null,
                        errorText: "Field must contain a value.",
                    }],
                    help: [
                        {
                            helpText: "After a Turbidity Notification, Reminder Notifications will continue to be",
                        },
                        {
                            helpText: "sent until a user acknowledges receipt of the notification. This",
                        },
                        {
                            helpText: "parameter defines the frequency that the Reminder Notification is sent.",
                        },
                    ],
                    csp: {
                        required: actStudy,
                        autoFocus: false,
                        label: "Send Reminder After X Hours",
                        margin: "dense",
                    },
                    ip: {
                        type: "number",
                        step: 0.5,
                        min: 1.0,
                        max: 24.0,
                    },
                },
            },
        };
    };

    render() {
        return <CloudcathDetail applet={this}
                                layout={this.state.Layout}
                                controls={this.state.Controls}
                                modes={this.state.Modes}
                                title="turbidity notification"
                                enterpriseComponent={this.getEnterpriseComponent()}
                                mode={this.state.Mode}
                                errorReportingMethod="SubmitTime" />;
    }
}

export default withAppContext(PatientDetailReducedTurbidityNotificationApplet);
