import {v4 as uuidv4} from "uuid";
import metadataClassFactory from "../../metadata/MetadataClassFactory";
import EventBus from "../../util/EventBus";
import EnterpriseComponentAttribute from "./EnterpriseComponentAttribute";
import EnterpriseComponentVisibilityMode from "./EnterpriseComponentVisibilityMode";

class EnterpriseComponent {

    constructor(name) {
        this.MODE_NEW = 100;
        this.MODE_QUERY = 101;
        this.MODE_IDLE = 102;
        this.MODE_RESULTS = 103;
        this.MODE_DELETE = 104;
        this.MODE_UPDATE = 105;

        this.ALL_VISIBILITY_MODE = 0;
        this.PROVIDER_VISIBILITY_MODE = 1;
        this.CLINIC_VISIBILITY_MODE = 2;

        let metadataClass = metadataClassFactory("ec", name + "EnterpriseComponentMetadata");

        this.metadata = new metadataClass();

        let metadataAttributes = this.metadata.getAttributes();
        this.attributes = {};

        for (const key of Object.keys(metadataAttributes)) {
            let md = metadataAttributes[key];

            this.attributes[md.getName()] = new EnterpriseComponentAttribute(this, md);
        }

        let metadataVisibilityModes = this.metadata.getVisibilityModes();
        this.visibilityModes = {};

        for (const key of Object.keys(metadataVisibilityModes)) {
            let md = metadataVisibilityModes[key];

            this.visibilityModes[md.getName()] = new EnterpriseComponentVisibilityMode(this, md);
        }

        this.resultsBuffer = [];
        this.queryBuffer = [{}];
        this.recordPointer = -1;
        this.enterpriseObject = null;
        this.isComponentValid = false;

        this.recordWillChangeListeners = [];
        this.recordChangedListeners = [];
        this.invalidListeners = [];
        this.startNewRecordModeListeners = [];
        this.startEditRecordModeListeners = [];
        this.singleRecordRefreshStartedListeners = [];
        this.singleRecordRefreshCompletedListeners = [];
        this.insertRollbackCompletedListeners = [];
        this.loadStartedListeners = [];
        this.updateRollbackListeners = [];
        this.loadCompletedListeners = [];
        this.valueChangedListeners = [];
        this.updateStartedListeners = [];
        this.updateCompletedListeners = [];
        this.insertStartedListeners = [];
        this.insertCompletedListeners = [];
        this.deleteStartedListeners = [];
        this.deleteCompletedListeners = [];

        this.searchSpecification = {};
        this.sortSpecification = null;
        this.newQueryListeners = [];
        this.totalRowcount = null;
        this.defaultCacheSize = 20;
        if (this.metadata.defaultCacheSize !== undefined) {
            this.defaultCacheSize = this.metadata.getDefaultCacheSize();
        }
        this.waiting = false;
        this.activeTimeout = null;
        this.mode = this.MODE_IDLE;
        this.supportingData = {};
        this.visibilityMode = this.ALL_VISIBILITY_MODE;
    };

    addRecordWillChangeListener = (newListener) => {
        if (this.recordWillChangeListeners.some(listener => listener.applet.getName() === newListener.applet.getName()) === false) {
            this.recordWillChangeListeners.push(newListener);
        }
    };

    addRecordChangedListener = (newListener) => {
        if (this.recordChangedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.recordChangedListeners.push(newListener);
        }
    };

    addDeleteCompletedListener = (newListener) => {
        if (this.deleteCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.deleteCompletedListeners.push(newListener);
        }
    };

    addDeleteStartedListener = (newListener) => {
        if (this.deleteStartedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.deleteStartedListeners.push(newListener);
        }
    };

    addInvalidListener = (newListener) => {
        if (this.invalidListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.invalidListeners.push(newListener);
        }
    };

    addInsertRollbackCompletedListener = (newListener) => {
        if (this.insertRollbackCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.insertRollbackCompletedListeners.push(newListener);
        }
    };

    addLoadCompletedListener = (newListener) => {
        if (this.loadCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.loadCompletedListeners.push(newListener);
        }
    };

    addValueChangedListener = (newListener) => {
        if (this.valueChangedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.valueChangedListeners.push(newListener);
        }
    };

    addLoadStartedListener = (newListener) => {
        if (this.loadStartedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.loadStartedListeners.push(newListener);
        }
    };

    addNewQueryListener = (newListener) => {
        if (this.newQueryListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.newQueryListeners.push(newListener);
        }
    };

    addStartNewRecordModeCompletedListener = (newListener) => {
        if (this.startNewRecordModeListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.startNewRecordModeListeners.push(newListener);
        }
    };

    addStartEditRecordModeCompletedListener = (newListener) => {
        if (this.startEditRecordModeListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.startEditRecordModeListeners.push(newListener);
        }
    };

    addSingleRecordRefreshStartedListener = (newListener) => {
        if (this.singleRecordRefreshStartedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.singleRecordRefreshStartedListeners.push(newListener);
        }
    };

    addSingleRecordRefreshCompletedListener = (newListener) => {
        if (this.singleRecordRefreshCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.singleRecordRefreshCompletedListeners.push(newListener);
        }
    };

    addUpdateCompletedListener = (newListener) => {
        if (this.updateCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.updateCompletedListeners.push(newListener);
        }
    };

    addUpdateRollbackCompletedListener = (newListener) => {
        if (this.updateRollbackListeners.some(listener => listener.applet.getName() === newListener.applet.getName()) === false) {
            this.updateRollbackListeners.push(newListener);
        }
    };

    addUpdateStartedListener = (newListener) => {
        if (this.updateStartedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.updateStartedListeners.push(newListener);
        }
    };

    addInsertCompletedListener = (newListener) => {
        if (this.insertCompletedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.insertCompletedListeners.push(newListener);
        }
    };

    addInsertStartedListener = (newListener) => {
        if (this.insertStartedListeners.some(listener => listener.getName() === newListener.getName()) === false) {
            this.insertStartedListeners.push(newListener);
        }
    };

    canChangeAttributeValue = (attributeName) => {
        if (this.mode === this.MODE_IDLE) {
            return false;
        }

        let attribute = this.getAttributes()[attributeName];

        if (attribute === null) {
            return false;
        }

        return !(this.mode === this.MODE_RESULTS && this.getBuffer().length === 0);
    };

    clearAttribute = (attributeName) => {
        if (this.canChangeAttributeValue(attributeName) === false) {
            return false;
        }

        let row = this.getBuffer()[this.getRecordPointer()];

        row["__operation"] = "query results";

        row[attributeName] = null;

        if (row[attributeName + "_original"] !== undefined) {
            delete row[attributeName + "_original"];
        }
        if (row[attributeName + "_old"] !== undefined) {
            delete row[attributeName + "_old"];
        }

        this.notifyValueChangedListeners(row);
    };

    deleteEnterpriseComponentFailed = () => {
        EventBus.error.emit("show-system-error");
    };

    deleteEnterpriseComponentSuccess = (data) => {
        if (this.getBuffer().length > 0) {
            this.resultsBuffer = this.getBuffer().splice(this.getRecordPointer(), 1);
            this.getBuffer()[this.getRecordPointer()].RowSelected = true;
        }
        this.setMode(this.MODE_RESULTS);

        this.notifyDeleteCompletedListeners();
    };

    editRecord = () => {
        this.setMode(this.MODE_UPDATE);

        this.notifyStartEditRecordModeListeners();
    };

    executeDelete = () => {
        this.performDelete();
    };

    executeQuery = (start, pageSize) => {
        if (this.waiting) {
            if (this.activeTimeout !== null) {
                clearTimeout(this.activeTimeout);
            }
            this.activeTimeout = setTimeout(() => {
                this.executeQuery(start, pageSize);
            }, 1000);
            return;
        }
        this.waiting = true;

        let foreignKey = null;
        if (this.getEnterpriseObject() === null) {
            this._executeQuery(start, pageSize, foreignKey);
        } else {
            let relationship = this.getEnterpriseObject().getRelationshipForChildEnterpriseComponent(this);
            if (relationship === null) {
                this._executeQuery(start, pageSize, foreignKey);
            } else {
                let parentEnterpriseComponent = relationship.getParentEnterpriseComponent();

                if (parentEnterpriseComponent.isValid() === false) {
                    this.waiting = false;
                    return;
                }

                if (parentEnterpriseComponent.getBuffer().length === 0) {
                    this.waiting = false;
                    this.isComponentValid = true;
                    this.invalidateChildren();
                    this.notifyLoadCompletedListeners();
                } else {
                    if (parentEnterpriseComponent.getRecordPointer() !== -1) {
                        foreignKey = parentEnterpriseComponent.getBuffer()[parentEnterpriseComponent.getRecordPointer()][relationship.getSourceAttribute()];
                    }
                }

                if (foreignKey !== null) {
                    this._executeQuery(start, pageSize, foreignKey);
                }
            }
        }
    };

    _executeQuery = (start, pageSize, foreignKey) => {
        let actualPageSize = pageSize;
        if (pageSize === undefined || pageSize < this.defaultCacheSize) {
            actualPageSize = this.defaultCacheSize;
        }
        if (start === undefined || pageSize === undefined) {
            this.resultsBuffer = [];
            this.performQuery(0, actualPageSize, foreignKey);
        } else {
            if (this.resultsBuffer.length === 0 && this.totalRowcount === 0) {
                this.isComponentValid = true;

                this.waiting = false;
                this.notifyLoadCompletedListeners();
            } else if (this.resultsBuffer.length > start) {
                this.isComponentValid = true;

                this.waiting = false;
                this.notifyLoadCompletedListeners();
            } else if (this.resultsBuffer.length < start) {
                this.mode = this.MODE_QUERY;
                this.performQuery(this.resultsBuffer.length, this.totalRowcount, foreignKey);
                this.mode = this.MODE_RESULTS;
            } else {
                this.mode = this.MODE_QUERY;
                this.performQuery(start, actualPageSize, foreignKey);
                this.mode = this.MODE_RESULTS;
            }
        }
    };

    executeInsert = () => {
        this.performInsert();
    };

    executeInsertRollback = () => {
        this.setMode(this.MODE_RESULTS);

        this.resultsBuffer.splice(this.getRecordPointer(), 1);

        if (this.getBuffer().length > 0) {
            for (let i = 0; i < this.getBuffer().length; i++) {
                this.getBuffer()[i].RowSelected = false;
                this.getBuffer()[i].RowNum = i;
            }

            this.getBuffer()[this.getRecordPointer()].RowSelected = true;
        } else {
            this.setRecordPointer(-1);
        }
        this.notifyInsertRollbackCompletedListeners();
    };

    executeSingleRecordRefresh = () => {
        if (this.singleRecordRefreshCompletedListeners.length === 0) {
            return;
        }

        if (this.waiting) {
            if (this.activeTimeout !== null) {
                clearTimeout(this.activeTimeout);
            }
            this.activeTimeout = setTimeout(() => {
                this.performSingleRecordRefresh(this.getBuffer()[this.getRecordPointer()]["USID"]);
            }, 1000);
            return;
        }
        this.waiting = true;

        this.performSingleRecordRefresh(this.getBuffer()[this.getRecordPointer()]["USID"]);

    };

    executeUpdate = () => {
        this.performUpdate();

        let row = this.getBuffer()[this.getRecordPointer()];
        if (row === undefined) return;

        for (const key of Object.keys(this.getAttributes())) {
            let attribute = this.getAttributes()[key];

            if (row[attribute.getName() + "_original"] !== undefined) {
                delete row[attribute.getName() + "_original"];
                delete row[attribute.getName() + "_old"];
            }
        }
        row["__operation"] = "query results";
    };

    executeUpdateRollback = () => {
        this.setMode(this.MODE_RESULTS);

        let row = this.getBuffer()[this.getRecordPointer()];

        for (const key of Object.keys(this.getAttributes())) {
            let attribute = this.getAttributes()[key];

            if (row[attribute.getName() + "_original"] !== undefined) {
                row[attribute.getName()] = row[attribute.getName() + "_original"];
                delete row[attribute.getName() + "_original"];
                delete row[attribute.getName() + "_old"];
            }
        }
        row["__operation"] = "query results";

        this.notifyUpdateRollbackListeners();
    };

    fetchEnterpriseComponentFailed = () => {
        EventBus.error.emit("show-system-error");
    };

    firstRecord = () => {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        if (this.getBuffer().length === 0) return false;

        if (this.getRecordPointer() && this.getBuffer()[this.getRecordPointer()].RowSelected === true) {
            this.getBuffer()[this.getRecordPointer()].RowSelected = false;
        }

        this.recordPointer = 0;
        this.getBuffer()[this.recordPointer].RowSelected = true;
        this.notifyRecordChangedListeners();
        this.invalidateChildren();

        return true;
    };

    fetchEnterpriseComponentSuccess = (data, start, pageSize) => {
        this.mode = this.MODE_RESULTS;

        this.resultsBuffer = this.resultsBuffer.concat(data["Results"]);
        for (let i = 0; i < this.resultsBuffer.length; i++) {
            this.resultsBuffer[i].RowNum = i;
        }

        if (data["Count"] === undefined) {
            if (data["Results"].length < pageSize) {
                this.totalRowcount = this.resultsBuffer.length;
            } else {
                this.totalRowcount = null;
            }
        } else {
            this.totalRowcount = data["Count"];
            if (this.totalRowcount) {
                this.totalRowcount = parseInt(this.totalRowcount);
            }
        }

        this.isComponentValid = true;

        if (this.recordPointer < 1) {
            if (this.resultsBuffer.length > 0) {
                this.recordPointer = 0;
                this.resultsBuffer[0].RowSelected = true;
            }
            if (this.getEnterpriseObject() !== null) {
                let childEnterpriseComponents = this.getEnterpriseObject().getChildEnterpriseComponents(this);
                let keys = Object.keys(childEnterpriseComponents);

                for (let i = 0; i < keys.length; i++) {
                    let childEnterpriseComponent = childEnterpriseComponents[keys[i]];

                    for (let j = 0; j < data["Results"].length; j++) {
                        let childDataResults = data["Results"][j]["ListOf" + keys[i]];

                        if (childDataResults !== undefined) {
                            childEnterpriseComponent.fetchEnterpriseComponentSuccess(childDataResults, 0, 1000);
                        } else {
                            childEnterpriseComponent.invalidate();
                        }
                    }
                }
            }
            this.notifyLoadCompletedListeners();
        } else {
            this.notifyLoadCompletedListeners();
            this.nextRecord();
        }
        this.waiting = false;
    };

    forceAttributeValue = (attributeName, attributeValue) => {
        if (this.canChangeAttributeValue(attributeName) === false) {
            return false;
        }

        let row = this.getBuffer()[this.getRecordPointer()];

        if (attributeValue === "NULL") {
            attributeValue = null;
        }
        this.getBuffer()[this.getRecordPointer()][attributeName] = attributeValue;

        row["__operation"] = "update";
        row[attributeName + "_original"] = attributeValue;
        row[attributeName + "_old"] = attributeValue;

        this.notifyValueChangedListeners(row);
    };

    getAttributes = () => {
        return this.attributes;
    };

    getAttribute = (name) => {
        return this.getAttributes()[name];
    };

    getAttributeValue = (attributeName, type) => {
        if (this.getRecordPointer() === -1) return null;

        let value;
        let row;

        let suffix = "";

        if (type === "original") {
            suffix = "_original";
        }

        if (this.mode === this.MODE_QUERY) {
            row = this.getBuffer()[0];
            value = row[attributeName + suffix];
        } else {
            row = this.getBuffer()[this.getRecordPointer()];
            value = row[attributeName + suffix];
        }

        return value;
    };

    getAttributeValueAsString = (attributeName, type) => {
        let value = this.getAttributeValue(attributeName, type);
        let out;

        if (value === null || value === undefined) {
            out = null;
        } else {
            out = value.toString();
        }
        return out;
    };

    getBuffer = () => {
        if (this.mode === this.MODE_QUERY) {
            return this.queryBuffer;
        } else if (this.mode === this.MODE_RESULTS) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_NEW) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_IDLE) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_UPDATE) {
            return this.resultsBuffer;
        } else if (this.mode === this.MODE_DELETE) {
            return this.resultsBuffer;
        }
        return {};
    };

    getEnterpriseObject = () => {
        return this.enterpriseObject;
    };

    getName = () => {
        return this.metadata.getName();
    };

    getParentEnterpriseComponent = () => {
        if (this.getEnterpriseObject() === null) {
            return null;
        }
        let relationship = this.getEnterpriseObject().getRelationshipForChildEnterpriseComponent(this);

        if (relationship) {
            return relationship.getParentEnterpriseComponent();
        }
        return null;
    };

    getRecordPointer = () => {
        return this.recordPointer;
    };

    getSearchSpecification = () => {
        return this.searchSpecification;
    };

    getSelectedRecordNumber = () => {
        for (let i = 0; i < this.getBuffer().length; i++) {
            if (this.getBuffer()[i].RowSelected === true) {
                return i;
            }
        }
        return -1;
    };

    getSortSpecification = () => {
        return this.sortSpecification;
    };

    getFormattedSortSpecification = () => {
        if (this.getSortSpecification() === null) {
            return null;
        }

        let out = "";
        let i = 0;
        for (let key of Object.keys(this.getSortSpecification())) {
            if (i !== 0) {
                out = out + ",";
            }
            out = out + key + ":" + this.getSortSpecification()[key];
            i++;
        }

        return out;
    };

    getTotalRowcount = () => {
        return this.totalRowcount;
    };

    getVisibilityMode = () => {
        return this.visibilityMode;
    };

    hasEnterpriseComponentBeenModified = () => {
        if (this.getBuffer().length === 0) {
            return false;
        }

        for (let i = 0; i < this.getBuffer().length; i++) {
            let row = this.getBuffer()[i];

            if (row["__operation"] !== "query results" && row["__operation"] !== "delete") {
                return true;
            }

            /*
            for child in self.get_enterprise_object().get_child_enterprise_components(self).values() {
                if child.has_enterprise_component_been_modified() {
                    return true;
                }
                return false;
            }
            */
        }
        return false;
    };

    insertEnterpriseComponentFailed = () => {
        EventBus.error.emit("show-system-error");
    };

    insertEnterpriseComponentSuccess = (data) => {
        let dataset = data?.Results ?? data;

        for (let rowIndex = 0; rowIndex < dataset.length; rowIndex++) {
            this.totalRowcount += 1;

            let keys = Object.keys(dataset[rowIndex]);
            for (let keysIndex = 0; keysIndex < keys.length; keysIndex++) {
                let key = keys[keysIndex];

                if (this.getEnterpriseObject() !== null) {
                    let childEnterpriseComponents = this.getEnterpriseObject().getChildEnterpriseComponents(this);
                    let childEnterpriseComponentKeys = Object.keys(childEnterpriseComponents);

                    for (let i = 0; i < childEnterpriseComponentKeys.length; i++) {
                        let childEnterpriseComponent = childEnterpriseComponents[childEnterpriseComponentKeys[i]];

                        if (key === childEnterpriseComponent.getName()) {
                            // process a single enterprise component child - code this another time
                        } else if (key === "ListOf" + childEnterpriseComponent.getName()) {
                            childEnterpriseComponent.insertEnterpriseComponentSuccess(dataset[rowIndex][key]["Results"]);
                        }
                    }
                }
                this.getBuffer()[this.getRecordPointer()][key] = dataset[rowIndex][key];
            }
        }

        for (let i = 0; i < this.getBuffer().length; i++) {
            let row = this.getBuffer()[i];

            row["RowNum"] = i;
            row["__operation"] = "query results";
        }

        let row = this.getBuffer()[this.getRecordPointer()];

        if (dataset.length > 0) {
            let keys = Object.keys(this.getAttributes());
            for (let i = 0; i < keys.length; i++) {
                let attribute = this.getAttributes()[keys[i]];
                row[attribute.getName()] = dataset[0][attribute.getName()];
                delete row[attribute.getName() + "_old"];
                delete row[attribute.getName() + "_original"];
            }
            this.getBuffer()[this.getRecordPointer()].RowSelected = true;
        }

        this.setMode(this.MODE_RESULTS);

        this.notifyInsertCompletedListeners();
    };

    invalidate = () => {
        this.recordPointer = -1;
        this.resultsBuffer = [];
        this.queryBuffer = [{}];
        this.isComponentValid = false;
        this.totalRowcount = -1;

        this.invalidateChildren();

        this.notifyInvalidListeners();
    };

    invalidateChildren = () => {
        if (this.enterpriseObject !== null) {
            let children = this.enterpriseObject.getChildEnterpriseComponents(this);
            for (let key in children) {
                let childEnterpriseComponent = children[key];
                let relationship = this.getEnterpriseObject().getRelationshipForChildEnterpriseComponent(childEnterpriseComponent);
                if (relationship !== null) {
                    if (relationship.getInvalidateChildren() === null || relationship.getInvalidateChildren() === undefined || relationship.getInvalidateChildren() === true) {
                        childEnterpriseComponent.invalidate();
                    }
                }
            }
        }
    };

    isQueryMode = () => {
        return this.mode === this.MODE_QUERY;
    };

    isNewMode = () => {
        return this.mode === this.MODE_NEW;
    };

    isViewMode = () => {
        return this.mode === this.MODE_RESULTS;
    };

    isPrimaryEnterpriseComponent = () => {
        return this.getParentEnterpriseComponent() === null;
    };

    isUpdateMode = () => {
        return this.mode === this.MODE_UPDATE;
    };

    isValid = () => {
        return this.isComponentValid;
    };

    lastRecord = () => {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        let out;

        if (this.getBuffer().length === 0 || this.getRecordPointer() === this.getBuffer().length - 1) {
            out = false;
        } else {
            if (this.getRecordPointer() && this.getBuffer()[this.getRecordPointer()].RowSelected === true) {
                this.getBuffer()[this.getRecordPointer()].RowSelected = false;
            }

            this.recordPointer = this.getBuffer().length - 1;
            this.getBuffer()[this.recordPointer].RowSelected = true;
            this.notifyRecordChangedListeners();
            out = true;
        }

        if (out) this.invalidateChildren();

        return out;
    };

    newQuery = () => {
        this.mode = this.MODE_QUERY;
        this.resultsBuffer = [];
        this.queryBuffer = [{"__operation": "query"}];
        this.recordPointer = 0;
        this.isComponentValid = false;
        this.searchSpecification = {};
        this.sortSpecification = null;
        this.notifyNewQueryListeners();
    };

    newDelete = () => {
        this.mode = this.MODE_DELETE;
        this.resultsBuffer = [{"__operation": "delete"}];
        this.queryBuffer = [];
        this.recordPointer = 0;
        this.isComponentValid = false;
        this.searchSpecification = {};
        this.sortSpecification = null;
//        this.notifyNewDeleteListeners();
    };

    newRecord = () => {
        this.setMode(this.MODE_NEW);

        let row = {};

        let keys = Object.keys(this.getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.getAttributes()[keys[i]];
            row[attribute.getName()] = attribute.getDefaultValue();
        }

        row["USID"] = uuidv4();
        row["RowSelected"] = true;
        row["RowNum"] = 0;
        row["__operation"] = "new";

        let foreignKey = null;
        let destinationAttribute = null;
        let realm = null;
        if (this.getEnterpriseObject() !== null) {
            let relationship = this.getEnterpriseObject().getRelationshipForChildEnterpriseComponent(this);
            if (relationship !== null) {
                destinationAttribute = relationship.getDestinationAttribute();

                let parentEnterpriseComponent = relationship.getParentEnterpriseComponent();

                if (parentEnterpriseComponent.isValid() === false) {
                    return;
                }

                if (parentEnterpriseComponent.getBuffer().length !== 0) {
                    if (parentEnterpriseComponent.getRecordPointer() !== -1) {
                        foreignKey = parentEnterpriseComponent.getBuffer()[parentEnterpriseComponent.getRecordPointer()][relationship.getSourceAttribute()];
                        realm = parentEnterpriseComponent.getBuffer()[parentEnterpriseComponent.getRecordPointer()]["Realm"];
                    }
                }
            } else {
                foreignKey = null;
            }
        } else {
            foreignKey = null;
        }

        if (foreignKey !== null) {
            row[destinationAttribute] = foreignKey;
            row["Realm"] = realm;
        }

        for (let i = 0; i < this.getBuffer().length; i++) {
            this.getBuffer()[i].RowSelected = false;
        }

        if (this.getRecordPointer() === -1) {
            this.setRecordPointer(0);
        }
        this.resultsBuffer.splice(this.getRecordPointer(), 0, row);

        for (let i = 0; i < this.getBuffer().length; i++) {
            this.getBuffer()[i].RowNum = i;
        }

        this.invalidateChildren();
        this.notifyStartNewRecordModeListeners();
    };

    nextRecord = () => {
        return this.stepRecord(1);
    };

    notifyRecordWillChangeListeners = () => {
        let okToChange = true;
        for (let i = 0; i < this.recordWillChangeListeners.length; i++) {
            let listener = this.recordWillChangeListeners[i];
            okToChange = okToChange && listener.enterpriseComponentRecordWillChange(this.getBuffer());
        }
        return okToChange;
    };

    notifyRecordChangedListeners = () => {
        for (let i = 0; i < this.recordChangedListeners.length; i++) {
            let listener = this.recordChangedListeners[i];
            listener.enterpriseComponentRecordChanged(this.getBuffer());
        }
    };

    notifyDeleteStartListeners = () => {
        for (let i = 0; i < this.deleteStartedListeners.length; i++) {
            let listener = this.deleteStartedListeners[i];
            listener.enterpriseComponentDeleteStarted();
        }
    };

    notifyDeleteCompletedListeners = () => {
        for (let i = 0; i < this.deleteCompletedListeners.length; i++) {
            let listener = this.deleteCompletedListeners[i];
            listener.enterpriseComponentDeleteCompleted(this.getBuffer());
        }
    };

    notifyInvalidListeners = () => {
        for (let i = 0; i < this.invalidListeners.length; i++) {
            let listener = this.invalidListeners[i];
            listener.enterpriseComponentInvalidated(this.getBuffer());
        }
    };

    notifyLoadCompletedListeners = () => {
        for (let i = 0; i < this.loadCompletedListeners.length; i++) {
            let listener = this.loadCompletedListeners[i];
            listener.enterpriseComponentLoadCompleted(this.getBuffer(), this);
        }
    };

    notifyInsertRollbackCompletedListeners = () => {
        for (let i = 0; i < this.insertRollbackCompletedListeners.length; i++) {
            let listener = this.insertRollbackCompletedListeners[i];
            listener.enterpriseComponentInsertRollbackCompleted(this.getBuffer());
        }
    };

    notifyValueChangedListeners = (row) => {
        for (let i = 0; i < this.valueChangedListeners.length; i++) {
            let listener = this.valueChangedListeners[i];
            listener.enterpriseComponentValueChanged(this.getBuffer(), row);
        }
    };

    notifyStartNewRecordModeListeners = () => {
        for (let i = 0; i < this.startNewRecordModeListeners.length; i++) {
            let listener = this.startNewRecordModeListeners[i];
            listener.enterpriseComponentStartNewRecordModeCompleted(this.getBuffer());
        }
    };

    notifyStartEditRecordModeListeners = () => {
        for (let i = 0; i < this.startEditRecordModeListeners.length; i++) {
            let listener = this.startEditRecordModeListeners[i];
            listener.enterpriseComponentStartEditRecordModeCompleted(this.getBuffer());
        }
    };

    notifyLoadStartListeners = () => {
        this.isComponentValid = false;

        for (let i = 0; i < this.loadStartedListeners.length; i++) {
            let listener = this.loadStartedListeners[i];
            listener.enterpriseComponentLoadStarted(this);
        }
    };

    notifyNewQueryListeners = () => {
        for (let i = 0; i < this.newQueryListeners.length; i++) {
            let listener = this.newQueryListeners[i];
            listener.enterpriseComponentNewQueryStarted();
        }
    };

    notifySingleRecordRefreshStartListeners = () => {
        for (let i = 0; i < this.singleRecordRefreshStartedListeners.length; i++) {
            let listener = this.singleRecordRefreshStartedListeners[i];
            listener.enterpriseComponentSingleRecordRefreshStarted();
        }
    };

    notifySingleRecordRefreshCompletedListeners = () => {
        for (let i = 0; i < this.singleRecordRefreshCompletedListeners.length; i++) {
            let listener = this.singleRecordRefreshCompletedListeners[i];
            listener.enterpriseComponentSingleRecordRefreshCompleted(this.getBuffer());
        }
    };

    notifyUpdateStartListeners = () => {
        for (let i = 0; i < this.updateStartedListeners.length; i++) {
            let listener = this.updateStartedListeners[i];
            listener.enterpriseComponentUpdateStarted(this.getBuffer(), this);
        }
    };

    notifyUpdateCompletedListeners = () => {
        for (let i = 0; i < this.updateCompletedListeners.length; i++) {
            let listener = this.updateCompletedListeners[i];
            listener.enterpriseComponentUpdateCompleted(this.getBuffer(), this);
        }
    };

    notifyUpdateRollbackListeners = () => {
        for (let i = 0; i < this.updateRollbackListeners.length; i++) {
            let updateRollbackListener = this.updateRollbackListeners[i];
            updateRollbackListener.enterpriseComponentUpdateRollbackCompleted(this.getBuffer());
        }
    };

    notifyInsertStartListeners() {
        for (let i = 0; i < this.insertStartedListeners.length; i++) {
            let listener = this.insertStartedListeners[i];
            listener.enterpriseComponentInsertStarted(this.getBuffer()[this.getRecordPointer()]);
        }
    };

    notifyInsertCompletedListeners() {
        for (let i = 0; i < this.insertCompletedListeners.length; i++) {
            let listener = this.insertCompletedListeners[i];
            listener.enterpriseComponentInsertCompleted(this.getBuffer(), this);
        }
    };

    performDelete = () => {
    };

    performInsert = () => {
    };

    performQuery = () => {
    };

    performSingleRecordRefresh = () => {
    };

    performUpdate = () => {
    };

    prepareDeletePayload = () => {
        let out = {};

        out["USID"] = this.getBuffer()[this.getRecordPointer()]["USID"];

        return out;
    };

    preparePUTPayload = () => {
        let out = {};

        out["USID"] = this.getBuffer()[this.getRecordPointer()]["USID"];

        let keys = Object.keys(this.getAttributes());
        for (let i = 0; i < keys.length; i++) {
            let attribute = this.getAttributes()[keys[i]];

            if (attribute.getComputed() === false) {
                let originalValue = this.getBuffer()[this.getRecordPointer()][attribute.getName() + "_original"];
                if (originalValue !== undefined && attribute.getName() !== "USID") {
                    if (originalValue === this.getBuffer()[this.getRecordPointer()][attribute.getName()]) {
                        continue;
                    }
                    let value = this.getBuffer()[this.getRecordPointer()][attribute.getName()];
                    if (attribute.getType() === "Number") {
                        if (value === "") {
                            out[attribute.getName()] = null;
                        } else if (value !== null) {
                            if (value.includes(".")) out[attribute.getName()] = parseFloat(value);
                            else out[attribute.getName()] = parseInt(value);
                        }
                    } else if (attribute.getType() === "String") {
                        if (value === "") {
                            out[attribute.getName()] = null;
                        } else {
                            out[attribute.getName()] = value;
                        }
                    } else {
                        out[attribute.getName()] = this.getBuffer()[this.getRecordPointer()][attribute.getName()];
                    }
                }
            }
        }
        return out;
    };

    previousRecord = () => {
        return this.stepRecord(-1);
    };

    refreshEnterpriseComponentSuccess = (data) => {
        this.mode = this.MODE_RESULTS;

        let results = data["Results"][0];

        for (let i = 0; i < this.getBuffer().length; i++) {
            let row = this.getBuffer()[i];

            if (row["USID"] === results["USID"]) {
                let attributes = this.getAttributes();

                for (const key of Object.keys(attributes)) {
                    row[key] = results[key];
                }
            }
        }

        this.notifySingleRecordRefreshCompletedListeners();
        this.waiting = false;
    };

    removeRecordChangedListener = (obj) => {
        let arr = this.recordChangedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeLoadStartedListener = (obj) => {
        let arr = this.loadStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeNewQueryListener = (obj) => {
        let arr = this.newQueryListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeLoadCompletedListener = (obj) => {
        let arr = this.loadCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeValueChangedListener = (obj) => {
        let arr = this.valueChangedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInvalidListener = (obj) => {
        let arr = this.invalidListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeUpdateStartedListener = (obj) => {
        let arr = this.updateStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeUpdateCompletedListener = (obj) => {
        let arr = this.updateCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInsertStartedListener = (obj) => {
        let arr = this.insertStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInsertCompletedListener = (obj) => {
        let arr = this.insertCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeInsertRollbackCompletedListener = (obj) => {
        let arr = this.insertRollbackCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeStartNewRecordModeCompletedListener = (obj) => {
        let arr = this.startNewRecordModeListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeSingleRecordRefreshStartedListener = (obj) => {
        let arr = this.singleRecordRefreshStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeSingleRecordRefreshCompletedListener = (obj) => {
        let arr = this.singleRecordRefreshCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeDeleteStartedListener = (obj) => {
        let arr = this.deleteStartedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    removeDeleteCompletedListener = (obj) => {
        let arr = this.deleteCompletedListeners;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                arr.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === obj) {
                console.error(arr[i]);
            }
        }
    };

    selectRecord = (rowNum) => {
        if (rowNum === this.getRecordPointer() && this.getBuffer()[rowNum].RowSelected === true) return;

        if (this.getRecordPointer() !== -1) {
            this.getBuffer()[this.getRecordPointer()].RowSelected = false;
        }

        this.setRecordPointer(rowNum);
        this.getBuffer()[rowNum].RowSelected = true;
        this.notifyRecordChangedListeners();
        this.invalidateChildren();
    };

    setAttributeValue = (attributeName, attributeValue) => {
        if (this.canChangeAttributeValue(attributeName) === false) {
            return false;
        }

        let row = this.getBuffer()[this.getRecordPointer()];

        let operation = row["__operation"];

        if (operation === "query results" || operation === "update") {
            let previousValue = row[attributeName];

            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            this.getBuffer()[this.getRecordPointer()][attributeName] = attributeValue;

            row["__operation"] = "update";

            if (row[attributeName + "_original"] === undefined && row[attributeName] !== previousValue) {
                row[attributeName + "_original"] = previousValue;
                row[attributeName + "_old"] = previousValue;
            } else {
                row[attributeName + "_old"] = previousValue;
            }
        } else if ("new" === operation) {
            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            this.getBuffer()[this.getRecordPointer()][attributeName] = attributeValue;
        } else if ("delete" === operation) {
            this.getBuffer()[this.getRecordPointer()][attributeName] = attributeValue;
        } else if ("query" === operation) {
            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            this.getBuffer()[this.getRecordPointer()][attributeName] = attributeValue;
        } else if (undefined === operation) {
            if (attributeValue === "NULL") {
                attributeValue = null;
            }
            this.getBuffer()[this.getRecordPointer()][attributeName] = attributeValue;
        }

        this.notifyValueChangedListeners(row);
    };

    setBuffer = (buffer) => {
        this.resultsBuffer = buffer;
    };

    setEnterpriseObject = (eo) => {
        this.enterpriseObject = eo;
    };

    setMode = (mode) => {
        this.mode = mode;
    };

    setUpdateMode = () => {
        this.mode = this.MODE_UPDATE;
    };

    setRecordPointer = (position) => {
        this.recordPointer = position;
    };

    setSearchSpecification(searchSpecification) {
        this.searchSpecification = searchSpecification;
    };

    setSortSpecification(sortSpecification) {
        this.sortSpecification = sortSpecification;
    }

    setSupportingData = (data) => {
        this.supportingData = data;
    };

    setVisibilityMode = (mode) => {
        this.visibilityMode = this.visibilityModes[mode];
    };

    stepRecord = (increment) => {
        if (this.hasEnterpriseComponentBeenModified()) {
            return false;
        }

        if (!this.isValid()) {
            return false;
        }

        if (!this.notifyRecordWillChangeListeners()) {
            return false;
        }

        let out;

        if (this.getBuffer().length === 0 || this.getRecordPointer() === this.getBuffer().length - increment) {
            out = false;
        } else if ((this.getBuffer().length === 0 || this.getRecordPointer() === 0) && increment < 0) {
            out = false;
        } else {
            if (this.getRecordPointer() !== -1 && this.getBuffer()[this.getRecordPointer()].RowSelected === true) {
                this.getBuffer()[this.getRecordPointer()].RowSelected = false;
            }

            this.recordPointer += increment;
            this.getBuffer()[this.recordPointer].RowSelected = true;
            this.notifyRecordChangedListeners();
            out = true;
        }

        if (out) this.invalidateChildren();

        return out;
    };

    updateEnterpriseComponentFailed = () => {
        EventBus.error.emit("show-system-error");
    };

    updateEnterpriseComponentSuccess = (data) => {
        let dataset = data?.Results ?? data;

        for (let rowIndex = 0; rowIndex < dataset.length; rowIndex++) {
            let _recordPointer = this._alignBuffer(dataset[rowIndex]["USID"]);
            let keys = Object.keys(dataset[rowIndex]);
            for (let keysIndex = 0; keysIndex < keys.length; keysIndex++) {
                let key = keys[keysIndex];

                if (this.getEnterpriseObject() !== null) {
                    let childEnterpriseComponents = this.getEnterpriseObject().getChildEnterpriseComponents(this);
                    let childEnterpriseComponentKeys = Object.keys(childEnterpriseComponents);

                    for (let i = 0; i < childEnterpriseComponentKeys.length; i++) {
                        let childEnterpriseComponent = childEnterpriseComponents[childEnterpriseComponentKeys[i]];

                        if (key === childEnterpriseComponent.getName()) {
                            // process a single enterprise component child - code this another time
                        } else if (key === "ListOf" + childEnterpriseComponent.getName()) {
                            childEnterpriseComponent.updateEnterpriseComponentSuccess(dataset[rowIndex][key]["Results"]);
                        }
                    }
                }
                this.getBuffer()[_recordPointer][key] = dataset[rowIndex][key];
                this.getBuffer()[_recordPointer]["RowNum"] = rowIndex;
                this.getBuffer()[_recordPointer]["__operation"] = "query results";
            }
        }

        if (dataset.length > 0) {
            this.getBuffer()[this.getRecordPointer()].RowSelected = true;
        }

        this.setMode(this.MODE_RESULTS);

        this.notifyUpdateCompletedListeners();
    };

    _alignBuffer = (usidToFind) => {
        for (let i = 0; i < this.getBuffer().length; i++) {
            if (this.getBuffer()[i]["USID"] === usidToFind) return i;
        }
        return -1;
    };
}

export default EnterpriseComponent;