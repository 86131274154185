import * as validators from "../../../util/Validators";
import EnterpriseComponentAttributeMetadata from "../EnterpriseComponentAttributeMetadata";
import EnterpriseComponentMetadata from "../EnterpriseComponentMetadata";

class ClinicEnterpriseComponentMetadata extends EnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "Clinic";

        this.attributes["ProviderUSID"] = new EnterpriseComponentAttributeMetadata(
            "ProviderUSID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Name"] = new EnterpriseComponentAttributeMetadata(
            "Name", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            8,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Identifier"] = new EnterpriseComponentAttributeMetadata(
            "Identifier", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicAddress1"] = new EnterpriseComponentAttributeMetadata(
            "ClinicAddress1", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicAddress2"] = new EnterpriseComponentAttributeMetadata(
            "ClinicAddress2", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicCity"] = new EnterpriseComponentAttributeMetadata(
            "ClinicCity", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicState"] = new EnterpriseComponentAttributeMetadata(
            "ClinicState", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicPostalCode"] = new EnterpriseComponentAttributeMetadata(
            "ClinicPostalCode", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["ClinicCountry"] = new EnterpriseComponentAttributeMetadata(
            "ClinicCountry", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["MainPhone"] = new EnterpriseComponentAttributeMetadata(
            "MainPhone", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            validators.PhoneRegex, // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["MainEmail"] = new EnterpriseComponentAttributeMetadata(
            "MainEmail", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            validators.EmailRegex,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["BackupEmail"] = new EnterpriseComponentAttributeMetadata(
            "BackupEmail", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            validators.EmailRegex,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["BackupPhone"] = new EnterpriseComponentAttributeMetadata(
            "BackupPhone", // name
            false, // NoCopy
            false,  // NoUpdate
            false,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            100,   // length
            null,     // minLength
            validators.PhoneRegex, // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["SendReminderAfterInstances"] = new EnterpriseComponentAttributeMetadata(
            "SendReminderAfterInstances", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            1,  // Scale
            3,  // Precision
            null,   // length
            null,   // minLength
            "^([1-9]|[1-9][0-9])(\.([0]|[5]))?$",  // pattern
            "Number", // Type
            6,   // default value
            false,   // computed
        );
        this.attributes["TurbidityNotificationLevel"] = new EnterpriseComponentAttributeMetadata(
            "TurbidityNotificationLevel", // name
            false, // NoCopy
            false,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            1,  // Scale
            4,  // Precision
            null,   // length
            null,   // minLength
            "^([1-9]|[1-9][0-9]|[1-9][1-9][0-9])(\.([0-9]))?$",  // pattern
            "Number", // Type
            6.9,   // default value
            false,   // computed
        );
        this.attributes["NotifyAfterInstances"] = new EnterpriseComponentAttributeMetadata(
            "NotifyAfterInstances", // name
            false,  // NoCopy
            false,  // NoUpdate
            true,   // Required
            false,  // RequiredForQuery
            null,   // Picklist
            null,   // Scale
            null,   // Precision
            null,   // length
            null,   // minLength
            "^([1-9])?$",  // pattern
            "Number", // Type
            3,   // default value
            false,   // computed
        );
        this.attributes["USID"] = new EnterpriseComponentAttributeMetadata(
            "USID", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            36,    // length
            null,  // minLength
            null,  // pattern
            "UUID", // Type
            null,   // default value
            false,   // computed
        );
        this.attributes["Realm"] = new EnterpriseComponentAttributeMetadata(
            "Realm", // name
            false, // NoCopy
            true,  // NoUpdate
            true,  // Required
            false, // RequiredForQuery
            null,  // Picklist
            null,  // Scale
            null,  // Precision
            15,    // length
            null,  // minLength
            null,  // pattern
            "String", // Type
            null,   // default value
            false,   // computed
        );
    }
}


export default ClinicEnterpriseComponentMetadata;