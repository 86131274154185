import RelationshipMetadata from "../RelationshipMetadata";

class PatientDataRelationshipMetadata extends RelationshipMetadata {
    constructor() {
        super();

        this.name = "Patient";
        this.parentEnterpriseComponent = "Patient";
        this.childEnterpriseComponent = "PatientData";
        this.sourceAttribute = "USID";
        this.destinationAttribute = "PatientUSID";
    }
}

export default PatientDataRelationshipMetadata;