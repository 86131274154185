import UserEnterpriseComponentMetadata from "./UserEnterpriseComponentMetadata";

class PrimaryPhysicianEnterpriseComponentMetadata extends UserEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "PrimaryPhysician";
        this.defaultCacheSize = 1000;
    }
}

export default PrimaryPhysicianEnterpriseComponentMetadata;