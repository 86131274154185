import AttachmentEnterpriseComponentMetadata from "./AttachmentEnterpriseComponentMetadata";

class NoteAttachmentEnterpriseComponentMetadata extends AttachmentEnterpriseComponentMetadata {
    constructor() {
        super();

        this.name = "NoteAttachment";
    }
}

export default NoteAttachmentEnterpriseComponentMetadata;